import React from "react";

export default function MenuItem({ data }) {
  return (
    <section className="menu-section">
      <h1 className="menu-section-title">{data.title}</h1>
      {data.items.map((item, index) => (
        <article className="menu-item" key={index}>
          <h3 className="item-name">{item.name}</h3>
          <p className="item-description">{item.description}</p>
          <strong className="item-price">{item.price}</strong>
        </article>
      ))}
    </section>
  );
}
