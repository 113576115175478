import React from "react";

const logos = [
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M21365 37231 c-266 -55 -462 -160 -715 -384 -36 -32 -102 -83 -147
-113 -199 -134 -399 -307 -567 -492 l-108 -119 -127 142 c-69 78 -169 190
-221 249 -176 200 -357 334 -615 457 -137 65 -268 109 -411 138 -101 20 -352
47 -360 38 -3 -3 45 -22 105 -42 151 -50 150 -65 -4 -65 -113 0 -116 0 -127
-25 -6 -14 -14 -25 -17 -25 -10 0 -355 60 -366 64 -5 2 7 11 28 21 41 21 49
45 15 45 -13 0 -79 -7 -148 -15 -69 -8 -128 -15 -131 -15 -3 0 -4 -11 -2 -24
4 -20 0 -24 -34 -28 -21 -3 -63 -2 -93 2 l-55 6 40 11 c22 7 57 15 77 18 22 4
35 11 31 17 -12 21 -204 1 -401 -41 -319 -69 -414 -100 -572 -191 -69 -39
-145 -72 -220 -96 -294 -92 -438 -169 -705 -375 -44 -34 -114 -77 -155 -96
-41 -19 -95 -44 -120 -56 -25 -11 -79 -56 -120 -98 -77 -78 -165 -143 -182
-133 -6 4 7 24 28 45 30 31 34 39 21 44 -19 8 -285 -132 -529 -279 -95 -57
-213 -124 -263 -149 -114 -57 -194 -116 -313 -231 -105 -101 -212 -238 -287
-366 -60 -101 -113 -160 -145 -160 -13 0 -56 16 -97 35 -166 80 -367 109 -708
102 -251 -5 -344 -16 -530 -63 -170 -43 -612 -181 -654 -204 -21 -12 -48 -39
-60 -60 -24 -42 -29 -45 -96 -56 -49 -7 -158 -76 -545 -345 -186 -130 -249
-182 -312 -261 -53 -66 -57 -73 -29 -58 24 12 31 13 31 1 0 -9 -113 -122 -117
-117 -2 1 2 17 7 36 5 19 8 35 7 37 -2 1 -30 -25 -63 -58 l-59 -60 44 16 c55
19 54 19 41 -6 -25 -47 -82 -99 -115 -105 -27 -5 -36 -13 -45 -39 -6 -19 -42
-68 -80 -110 -37 -43 -88 -113 -112 -157 -23 -44 -63 -103 -87 -131 -98 -114
-140 -198 -188 -377 -34 -126 -62 -181 -210 -398 -69 -101 -153 -231 -187
-290 -75 -131 -115 -185 -276 -377 -156 -187 -206 -250 -310 -395 l-82 -115 6
-56 c6 -62 -4 -84 -59 -129 -73 -59 -115 -108 -115 -133 0 -16 -19 -47 -52
-86 -29 -33 -102 -138 -162 -233 -241 -382 -427 -681 -517 -832 -101 -170
-178 -264 -259 -316 -28 -18 -115 -57 -193 -87 -275 -104 -499 -233 -692 -398
-49 -42 -138 -109 -196 -150 -321 -223 -568 -468 -904 -900 -112 -143 -286
-402 -360 -535 -129 -233 -422 -716 -670 -1105 -194 -304 -362 -632 -645
-1255 -187 -412 -271 -747 -300 -1201 -22 -338 23 -679 143 -1089 64 -219 68
-289 20 -417 -82 -218 -91 -416 -26 -597 34 -96 79 -168 195 -316 127 -160
160 -211 300 -460 378 -673 418 -769 418 -989 0 -214 29 -362 121 -608 158
-423 323 -742 594 -1143 73 -108 147 -227 165 -264 32 -64 32 -68 18 -104 -36
-92 -363 -482 -458 -546 -23 -16 -24 -18 -8 -30 22 -16 22 -26 1 -93 -10 -32
-22 -53 -31 -53 -15 0 -14 36 3 117 12 59 -26 -42 -51 -132 -56 -207 -37 -418
53 -598 21 -42 23 -53 13 -83 -6 -18 -8 -44 -4 -56 13 -42 235 -421 329 -563
55 -82 160 -218 257 -330 806 -936 806 -936 1008 -1220 191 -268 293 -423 512
-780 219 -357 275 -454 329 -572 23 -48 93 -193 156 -322 102 -206 137 -265
301 -505 376 -552 541 -751 1144 -1382 421 -441 551 -550 1063 -896 271 -183
483 -312 654 -397 68 -35 170 -91 227 -127 135 -83 206 -118 280 -139 32 -10
72 -30 89 -45 72 -67 181 -87 303 -55 65 17 122 15 122 -4 0 -5 -34 -21 -75
-36 -41 -15 -75 -29 -75 -31 0 -2 15 -13 33 -24 41 -25 75 -25 157 1 86 27
215 27 335 1 50 -11 104 -23 121 -26 l32 -5 -16 50 c-20 62 -17 65 89 100 l69
23 117 -28 c65 -15 120 -29 122 -31 9 -10 -11 -56 -29 -65 -17 -9 -26 -5 -55
25 -32 31 -42 35 -87 35 -43 0 -55 -4 -79 -30 -33 -34 -35 -47 -13 -78 19 -27
52 -28 101 -2 21 11 52 22 70 26 l31 6 0 -48 c-1 -51 13 -69 79 -105 16 -9 68
-61 115 -116 47 -54 108 -119 136 -144 50 -44 550 -335 737 -427 238 -118 436
-180 736 -231 232 -40 989 -230 1058 -266 15 -7 36 -24 49 -37 25 -28 227
-467 417 -908 165 -385 247 -554 382 -785 60 -104 158 -284 218 -400 172 -335
244 -456 521 -870 419 -627 514 -776 654 -1030 209 -378 281 -477 430 -595 55
-42 104 -92 131 -131 24 -35 62 -76 84 -91 54 -38 188 -60 349 -56 117 3 124
2 206 -31 82 -32 89 -33 177 -27 226 16 486 77 723 172 l145 57 390 0 390 0
135 -42 c215 -66 277 -74 555 -68 306 6 413 23 740 117 94 26 213 60 265 75
52 14 126 43 164 63 87 47 202 154 259 240 602 915 877 1366 1697 2787 261
452 343 572 660 955 237 286 464 607 608 858 39 68 76 130 83 138 24 29 84 52
244 93 309 81 751 203 895 248 195 60 633 236 800 320 74 38 176 83 225 101
189 66 548 175 750 228 478 125 564 165 730 334 111 113 171 141 350 165 57 8
105 30 105 49 0 7 11 23 24 35 21 19 40 24 148 31 149 11 229 34 508 150 398
165 544 266 946 659 132 130 285 277 340 327 64 59 147 153 234 263 183 234
183 232 188 298 l5 57 -46 -38 c-25 -20 -63 -58 -83 -84 -72 -90 -639 -657
-674 -674 -19 -10 -45 -18 -57 -18 -19 0 -22 4 -18 28 3 15 12 79 20 142 18
130 35 166 88 195 20 11 42 33 49 50 7 16 42 62 79 103 37 40 123 153 191 249
68 97 136 189 151 205 33 32 77 37 132 14 31 -13 35 -19 35 -50 0 -20 5 -36
11 -36 11 0 119 219 119 241 0 20 -33 38 -68 39 -26 0 -41 -9 -70 -40 -20 -21
-50 -42 -66 -46 l-29 -7 38 69 c103 185 144 234 197 234 39 0 42 -15 19 -83
l-20 -57 30 0 c41 0 75 25 105 76 26 44 86 104 105 104 15 0 3 -48 -47 -183
-27 -70 -47 -142 -50 -180 l-5 -62 21 25 c12 14 33 46 46 72 26 52 113 394
249 976 73 314 94 392 136 489 85 198 353 659 496 853 78 105 231 344 433 676
107 177 141 206 325 291 17 7 105 87 196 176 260 255 448 484 581 711 95 160
141 229 200 299 27 31 48 65 46 74 -2 10 -18 21 -38 26 -19 5 -39 12 -43 16
-4 4 2 48 13 97 29 125 28 123 51 85 l20 -34 50 49 c45 44 55 49 106 54 l56 5
64 100 c141 219 155 271 134 507 -18 195 -46 290 -128 429 -48 84 -84 119
-120 119 -42 0 -70 24 -102 91 -61 122 -128 178 -268 224 -45 14 -117 48 -160
74 -169 103 -258 163 -255 172 1 5 35 87 74 181 l72 171 -33 28 -34 28 204
315 c201 312 204 315 221 293 17 -22 17 -22 32 -2 22 28 96 171 96 184 0 6
-16 11 -36 11 -32 0 -35 2 -29 23 17 55 90 178 136 230 27 31 96 92 153 135
138 107 179 156 205 242 12 38 33 82 47 99 23 28 25 37 19 81 -7 48 -4 55 49
149 69 123 113 223 164 382 92 284 119 450 142 874 17 308 24 355 80 520 27
82 68 229 90 329 59 259 84 353 130 470 94 239 116 418 100 789 -11 240 -12
249 -74 394 -18 43 -34 115 -52 235 -49 338 -107 585 -179 767 -21 52 -83 184
-140 295 -149 293 -155 315 -165 571 -9 222 -21 294 -76 445 -100 279 -319
571 -989 1320 -179 201 -358 413 -453 538 l-72 94 21 39 c15 30 16 40 7 43 -7
3 -31 10 -53 17 -121 36 -202 105 -348 294 -55 71 -153 182 -216 246 -105 105
-413 358 -716 589 -60 46 -135 105 -165 132 -77 70 -117 88 -190 88 -67 0
-112 19 -235 98 -102 66 -172 143 -235 262 -42 78 -67 113 -105 144 -131 108
-195 189 -291 371 -65 123 -66 142 -3 120 23 -9 44 -13 47 -10 3 2 -9 33 -26
67 -30 60 -71 113 -260 335 -84 98 -155 221 -168 289 -5 28 -20 46 -73 88
-109 87 -151 147 -151 219 0 9 -7 17 -15 17 -24 0 -142 118 -164 164 -12 23
-21 61 -21 84 l0 43 -30 -16 c-43 -22 -82 -18 -123 13 -204 157 -212 164 -222
203 -9 38 6 43 71 22 42 -13 59 -24 66 -43 14 -37 38 -60 63 -60 19 0 13 9
-34 59 -31 32 -85 89 -121 127 -36 38 -95 91 -133 118 -66 47 -68 48 -93 31
-25 -16 -26 -16 -42 7 -9 12 -36 41 -60 63 -40 36 -43 43 -38 74 5 33 0 40
-62 106 -38 38 -108 126 -157 195 -48 69 -136 184 -195 255 -59 72 -169 209
-246 305 -169 213 -239 287 -464 493 -223 204 -255 228 -389 287 -135 59 -238
114 -348 186 -160 104 -238 135 -403 159 -73 10 -124 24 -166 45 -34 16 -68
30 -76 30 -9 0 -42 -12 -74 -26 -99 -44 -199 -64 -365 -74 -89 -6 -174 -16
-199 -25 -63 -22 -76 -19 -128 29 -90 83 -125 90 -250 45 -61 -21 -65 -22
-121 -6 -54 15 -328 157 -526 272 -49 28 -128 72 -175 96 -55 28 -104 63 -139
98 -32 32 -101 83 -169 123 -216 129 -374 248 -574 434 -128 119 -194 164
-293 200 -43 16 -88 43 -136 83 -39 33 -117 84 -172 112 -56 29 -122 67 -147
84 -125 89 -304 150 -635 216 -152 30 -200 50 -520 213 -389 200 -570 273
-945 382 -267 78 -313 86 -525 90 -156 3 -206 0 -280 -15z m225 -120 c-17 -32
-29 -34 -61 -7 l-31 26 52 0 c47 0 50 -1 40 -19z m-400 -21 c-20 -13 -53 -13
-45 0 3 6 18 10 33 10 21 0 24 -2 12 -10z m-3335 -135 c0 -5 -19 -11 -42 -13
-34 -3 -43 0 -43 13 0 13 9 16 43 13 23 -2 42 -7 42 -13z m5478 -850 c58 -7
63 -10 49 -33 -7 -10 -23 -9 -82 3 -41 9 -76 17 -78 20 -10 10 12 25 31 21 12
-3 48 -8 80 -11z m-169 -86 c3 -34 6 -91 6 -125 0 -75 1 -74 -120 -49 -90 19
-126 38 -146 76 -18 35 -11 49 14 31 77 -56 88 -59 117 -38 18 13 17 16 -18
64 l-37 50 72 25 c106 37 104 37 112 -34z m-1440 -9 c19 -21 40 -54 47 -74 13
-35 14 -36 69 -36 30 0 89 7 130 16 130 27 181 17 489 -100 284 -109 610 -200
1031 -291 204 -44 353 -106 610 -255 172 -100 302 -190 466 -322 76 -62 216
-167 309 -233 94 -67 228 -165 299 -219 170 -128 316 -213 604 -353 407 -196
465 -227 503 -263 45 -43 55 -76 70 -247 l12 -132 -32 -36 c-51 -59 -44 -109
94 -595 76 -270 100 -379 115 -525 13 -127 11 -176 -36 -770 -20 -242 -33
-460 -31 -483 3 -31 14 -53 35 -75 43 -44 49 -68 57 -198 6 -94 5 -121 -9
-153 -9 -21 -16 -53 -16 -70 0 -38 93 -300 160 -452 66 -149 82 -223 78 -351
-2 -56 -6 -104 -10 -108 -18 -18 -49 15 -114 122 -129 212 -166 299 -332 778
-93 268 -128 412 -143 591 -5 71 -21 203 -35 294 -14 91 -32 212 -40 270 -11
72 -18 239 -24 530 -7 371 -10 437 -28 518 -41 187 -105 346 -189 469 -25 37
-61 90 -80 118 l-33 50 0 -138 c0 -136 -8 -177 -34 -177 -7 0 -19 12 -27 26
-9 18 -52 45 -142 90 l-128 65 -47 -34 c-75 -53 -116 -123 -167 -279 -94 -287
-143 -548 -151 -808 -3 -91 -7 -184 -10 -207 l-5 -42 -35 16 c-40 19 -138 138
-166 201 -11 23 -25 73 -32 112 -8 38 -28 94 -45 125 -17 30 -34 75 -38 100
-9 66 -35 102 -83 115 -31 8 -40 15 -36 28 3 9 10 51 16 93 13 91 4 139 -45
245 -32 71 -35 74 -79 85 -25 6 -46 14 -46 18 0 4 14 15 31 25 21 13 28 23 24
34 -3 10 -13 42 -22 73 -12 44 -21 58 -45 68 -42 19 -54 50 -34 89 9 17 16 36
16 41 0 6 -40 31 -90 56 -128 64 -119 75 38 48 31 -5 32 -4 32 30 0 20 -4 47
-10 62 -10 25 -137 167 -156 173 -5 2 -11 -7 -15 -20 -5 -22 -7 -20 -37 21
-18 24 -32 51 -32 60 0 9 18 32 41 52 l42 36 -18 72 c-9 39 -16 73 -14 75 2 2
24 10 49 18 l45 15 -84 42 c-45 23 -85 40 -87 38 -2 -2 7 -52 21 -112 14 -59
23 -110 20 -113 -2 -3 -21 12 -40 32 -28 30 -43 64 -80 180 l-46 143 46 45
c55 55 58 87 16 145 -23 32 -32 59 -41 124 -15 107 -25 135 -64 161 -17 12
-33 23 -35 24 -1 2 4 13 12 25 15 21 14 24 -33 66 -66 59 -93 130 -88 229 l3
71 -68 51 -69 51 -49 -36 c-39 -27 -48 -39 -44 -56 26 -108 42 -197 35 -197
-4 0 -27 9 -51 19 -24 11 -47 17 -51 15 -5 -3 -18 -24 -29 -46 -28 -54 -27
-134 6 -578 30 -408 29 -394 9 -356 -22 40 -34 20 -24 -39 12 -71 12 -303 0
-326 -6 -10 -24 -26 -40 -34 l-30 -15 -155 156 c-171 173 -209 224 -266 355
-22 50 -58 126 -81 170 -35 68 -40 83 -30 104 6 13 23 27 37 31 29 7 30 15 11
52 -18 34 -51 65 -93 86 -29 15 -33 21 -33 57 0 52 -25 99 -95 179 -65 75
-220 278 -351 460 -131 182 -199 252 -298 300 -79 40 -189 69 -254 70 l-33 0
38 -32 c74 -62 146 -134 203 -204 l59 -71 -34 -33 -33 -32 -61 91 c-64 95 -82
111 -127 111 -19 0 -41 12 -65 36 l-36 35 -57 -63 c-52 -57 -57 -66 -51 -96 3
-18 8 -39 11 -46 3 -7 -6 -27 -20 -44 -15 -17 -26 -42 -26 -59 0 -39 35 -290
60 -430 34 -191 190 -862 222 -952 109 -317 468 -892 755 -1214 103 -114 191
-190 426 -366 279 -208 465 -370 728 -631 373 -372 634 -653 751 -810 l107
-145 -36 -3 c-25 -2 -54 5 -91 24 -47 24 -68 28 -151 29 -78 2 -113 8 -186 33
-166 56 -182 55 -224 -19 l-28 -50 -37 42 -37 43 -112 -37 c-62 -20 -115 -37
-118 -37 -3 0 -25 27 -49 60 -42 58 -44 60 -80 54 -28 -5 -54 1 -108 25 -39
17 -86 34 -104 38 -18 3 -51 18 -73 33 -132 89 -215 108 -525 121 -135 6 -274
8 -310 5 -112 -10 -414 -89 -459 -120 -55 -38 -107 -44 -159 -17 -46 24 -90
26 -142 7 l-35 -13 63 -9 c62 -9 63 -9 47 -32 -21 -30 -90 -64 -131 -65 -22
-1 -42 7 -57 21 -22 21 -25 21 -37 7 -11 -13 -11 -27 -3 -68 6 -29 9 -55 7
-59 -2 -3 -47 -21 -99 -38 -52 -17 -95 -37 -95 -44 0 -28 69 -123 115 -158 27
-20 58 -54 68 -75 46 -90 104 -124 229 -131 49 -3 88 -9 88 -13 0 -5 18 -20
39 -35 59 -38 209 -50 374 -28 180 23 284 6 384 -66 23 -17 62 -40 86 -50 46
-20 554 -136 917 -210 347 -70 476 -106 805 -226 110 -40 290 -98 400 -129
109 -32 222 -67 250 -79 54 -25 341 -200 484 -296 49 -32 98 -72 109 -87 11
-15 36 -71 57 -123 85 -215 198 -426 310 -582 25 -35 57 -79 70 -98 23 -33
264 -716 408 -1155 58 -178 133 -474 125 -496 -2 -4 -45 61 -96 145 -255 415
-550 732 -907 971 -139 93 -250 146 -401 189 -117 33 -123 33 -379 40 -143 4
-269 8 -280 9 -11 1 -20 -2 -20 -8 -1 -29 -16 -35 -48 -18 -61 33 -379 19
-501 -23 -87 -30 -262 -48 -388 -40 -60 4 -148 19 -235 42 -76 19 -214 55
-308 79 -252 64 -310 90 -421 185 -60 50 -121 86 -131 76 -3 -3 25 -37 62 -75
46 -48 63 -72 54 -75 -51 -18 -350 -56 -479 -60 -189 -8 -187 -9 -294 133 -42
55 -122 153 -179 218 -56 65 -116 136 -133 158 -29 37 -99 165 -91 165 3 0 35
-14 72 -31 54 -24 70 -28 76 -17 34 59 34 59 112 -24 70 -75 87 -84 138 -80
15 2 15 6 -2 39 -10 21 -19 44 -19 53 0 25 -37 78 -59 85 -17 5 -21 15 -21 46
0 37 -2 39 -31 39 -47 0 -85 44 -94 107 -5 38 -19 69 -50 115 -24 34 -42 64
-40 66 2 2 20 -7 42 -21 21 -13 71 -41 111 -62 48 -25 90 -57 123 -94 46 -50
112 -101 131 -101 16 0 -28 99 -66 149 -66 88 -245 269 -312 317 -34 25 -96
60 -137 79 -91 42 -117 71 -175 195 -24 52 -65 127 -90 165 -89 138 -117 235
-127 445 -11 220 -34 399 -104 806 -20 112 -34 238 -41 370 -6 109 -17 261
-25 336 -21 189 -19 429 5 729 11 140 27 369 35 509 18 308 34 439 81 658 130
614 155 753 164 932 5 96 10 243 10 327 0 83 4 154 9 158 5 3 20 -9 32 -26
l22 -32 19 36 c30 60 47 153 57 327 6 91 16 181 22 200 7 19 48 73 92 118 l79
84 -6 52 -7 53 41 12 c23 7 45 10 48 7 3 -4 8 -25 12 -49 l6 -42 18 33 c20 39
15 62 -13 62 -18 0 -41 26 -41 46 0 4 39 4 86 0 85 -7 86 -7 82 15 -2 13 -22
36 -48 54 -25 17 -46 39 -48 48 -5 24 72 59 110 49 10 -2 33 -21 52 -42z
m-3576 -164 c40 -21 78 -99 111 -228 60 -231 148 -476 242 -668 157 -323 410
-791 494 -915 47 -70 103 -170 134 -240 30 -66 79 -174 109 -239 49 -105 56
-129 60 -195 4 -89 -43 -1056 -59 -1181 -15 -124 -75 -445 -110 -595 -63 -262
-257 -867 -467 -1450 -121 -336 -169 -488 -177 -560 -10 -78 -35 -127 -136
-260 -45 -61 -102 -145 -126 -187 -100 -176 -214 -307 -472 -537 -271 -241
-361 -311 -477 -369 -115 -58 -229 -79 -339 -62 -296 45 -550 50 -820 16 -66
-8 -181 -20 -255 -26 -208 -17 -282 -28 -451 -70 -561 -141 -824 -269 -1062
-520 -165 -173 -346 -439 -484 -710 -61 -119 -76 -162 -112 -314 -15 -65 -48
-146 -125 -305 -249 -516 -330 -715 -401 -983 -24 -92 -67 -236 -94 -320 -127
-390 -276 -1050 -350 -1548 -22 -146 -63 -420 -91 -610 -28 -190 -69 -449 -90
-577 -47 -277 -65 -524 -56 -768 6 -161 8 -177 40 -270 80 -230 135 -374 164
-431 41 -80 138 -225 202 -299 93 -109 101 -132 116 -357 15 -221 32 -346 70
-516 76 -341 163 -514 353 -702 125 -125 130 -134 346 -605 155 -339 192 -407
250 -451 43 -33 45 -31 75 67 24 78 54 127 95 150 l30 18 73 -55 c41 -31 75
-54 77 -52 2 1 15 55 30 118 44 190 44 256 -3 540 -22 135 -59 361 -82 503
-23 141 -61 336 -86 433 -25 97 -43 178 -42 180 2 1 45 9 95 18 112 17 174 49
244 123 96 102 133 222 145 473 7 152 -5 276 -37 391 -16 53 -23 118 -29 234
-7 167 -12 190 -61 266 -21 33 -36 80 -53 160 -13 63 -40 170 -60 239 -21 69
-40 133 -42 143 -8 25 -21 13 -50 -45 -43 -86 -54 -162 -47 -352 6 -203 13
-235 60 -287 l35 -39 -47 0 c-46 0 -51 3 -117 70 -38 38 -78 72 -89 75 -15 5
-19 16 -19 55 0 64 13 95 62 148 39 43 40 46 34 96 -26 199 -19 263 37 345
l30 43 -44 37 c-26 23 -38 40 -32 44 6 4 54 16 107 27 377 77 562 160 703 314
114 124 161 250 170 456 3 71 1 170 -6 230 -13 118 -70 454 -107 634 -53 261
-20 380 153 547 128 124 276 210 468 272 283 92 479 101 694 30 80 -26 378
-160 387 -174 10 -16 -19 -54 -105 -139 -170 -170 -320 -362 -570 -735 -190
-282 -268 -408 -426 -685 -215 -376 -258 -482 -335 -820 -124 -545 -200 -807
-392 -1359 -229 -657 -270 -874 -248 -1318 24 -489 61 -733 147 -963 99 -265
287 -559 501 -782 110 -114 109 -100 14 -218 -79 -98 -152 -239 -192 -370 -43
-143 -60 -177 -118 -240 -27 -30 -75 -95 -107 -145 -32 -49 -74 -109 -93 -133
-32 -40 -33 -46 -27 -92 4 -28 5 -50 2 -50 -10 0 -77 109 -96 156 -19 48 -19
48 -26 21 -11 -41 2 -101 39 -186 18 -42 36 -89 40 -106 6 -28 3 -26 -43 25
-28 30 -50 53 -50 50 -1 -15 35 -115 97 -270 123 -305 116 -291 313 -570 53
-74 119 -166 148 -204 88 -116 175 -308 249 -548 l39 -128 -28 0 c-16 0 -29
-1 -29 -3 0 -2 13 -41 29 -88 104 -304 244 -557 415 -749 56 -62 75 -90 71
-105 -18 -73 -25 -258 -15 -398 33 -449 71 -608 246 -1016 60 -142 88 -194
132 -248 30 -37 72 -104 92 -148 98 -205 352 -1160 445 -1670 49 -270 141
-578 252 -845 133 -317 187 -481 323 -980 86 -314 128 -459 244 -827 29 -95
50 -173 45 -173 -12 0 -283 259 -367 350 -82 89 -91 106 -127 230 -69 243
-178 471 -576 1205 -61 112 -158 207 -274 268 -85 45 -102 60 -316 280 -307
316 -494 531 -529 608 -11 25 -20 66 -20 95 0 65 -25 91 -101 103 -125 21
-202 56 -280 130 -57 54 -88 65 -143 50 l-36 -10 0 -58 c0 -47 -4 -60 -17 -66
-42 -18 -231 -16 -463 5 -154 13 -397 24 -655 30 -225 5 -590 14 -810 21 -417
12 -576 9 -860 -17 -93 -9 -216 -17 -272 -17 l-102 -2 -183 94 c-562 289 -948
526 -1253 769 -136 108 -711 678 -902 893 -181 205 -366 443 -389 503 -4 9 26
30 93 66 215 116 284 179 299 277 7 48 5 67 -25 268 -11 72 -21 155 -22 185
l-3 55 59 3 c109 6 346 -17 670 -63 176 -26 385 -50 465 -54 233 -13 340 -58
565 -236 123 -98 218 -161 388 -258 137 -79 253 -172 407 -326 107 -107 164
-174 215 -251 122 -185 318 -365 610 -560 172 -115 243 -170 344 -268 159
-153 316 -253 531 -338 52 -20 161 -70 242 -110 81 -40 168 -76 193 -80 55 -8
114 12 164 56 28 25 84 50 113 50 4 0 5 -13 2 -29 -5 -26 -2 -33 27 -50 40
-25 108 -27 148 -7 35 19 93 84 86 96 -3 6 -26 24 -51 40 -38 27 -43 34 -34
51 12 22 45 24 80 4 38 -22 42 -18 35 24 l-6 38 153 -1 c149 -1 155 0 210 27
107 52 158 146 158 289 0 72 -18 133 -55 190 -34 51 -151 137 -260 191 -148
74 -185 130 -210 314 -30 227 -90 354 -242 515 -48 50 -84 100 -103 140 -35
76 -54 189 -47 278 8 101 -7 149 -81 258 -67 98 -127 217 -202 403 -39 98 -46
108 -80 123 -30 13 -44 29 -68 79 -17 34 -75 119 -129 189 -114 147 -165 226
-208 323 -61 138 -138 221 -291 316 -48 30 -115 80 -148 112 -80 77 -135 115
-202 138 -198 70 -577 35 -867 -80 -54 -22 -106 -33 -184 -41 -307 -31 -535
-90 -830 -216 -170 -72 -307 -111 -527 -149 -171 -30 -291 -34 -499 -15 -149
14 -181 9 -195 -30 -7 -19 -11 -17 -40 23 -55 77 -169 135 -306 157 -40 6 -67
16 -77 30 -25 29 -50 36 -255 70 -206 34 -238 44 -298 94 -81 66 -98 71 -241
71 -71 0 -191 -6 -265 -13 -171 -16 -180 -14 -220 70 -20 42 -46 77 -79 106
-38 32 -52 51 -56 80 -11 63 -15 70 -49 90 -23 13 -46 43 -70 89 -54 101 -204
272 -345 393 -68 58 -141 130 -163 160 -52 72 -138 248 -138 281 0 65 11 84
61 112 53 30 69 51 69 90 0 18 7 27 24 31 24 6 35 17 180 176 54 59 101 101
124 110 100 41 167 101 209 189 27 53 30 56 62 53 58 -6 89 16 163 115 38 51
110 130 159 175 49 46 96 97 104 114 24 51 19 132 -11 192 -30 60 -56 82 -173
147 -419 232 -1143 846 -1473 1250 -36 44 -133 171 -216 283 -83 111 -180 239
-215 285 -62 79 -113 164 -104 173 2 3 80 -18 173 -45 93 -27 273 -79 399
-116 267 -77 340 -104 459 -169 225 -123 404 -296 756 -731 505 -624 582 -709
770 -852 109 -83 157 -103 246 -103 50 0 82 8 151 36 133 55 178 80 208 115
25 30 27 39 23 103 -4 85 4 90 88 52 31 -14 74 -26 95 -26 54 0 69 25 69 116
0 114 12 112 115 -21 48 -63 89 -115 90 -115 1 0 12 28 25 61 l22 62 -46 40
c-58 48 -58 76 -4 120 l37 30 39 -32 c22 -17 42 -31 45 -31 3 0 8 17 12 39 4
26 15 45 36 60 21 15 29 30 29 51 0 27 -3 30 -33 30 -18 0 -42 6 -53 14 -19
14 -18 16 11 128 33 125 33 117 7 281 -11 64 -15 117 -10 117 4 0 17 -9 28
-20 16 -16 20 -33 20 -85 0 -61 1 -65 23 -65 12 0 39 -3 60 -6 46 -8 48 5 12
76 -25 50 -23 87 5 105 6 4 63 -43 133 -112 134 -129 196 -169 311 -197 84
-21 135 -46 186 -93 45 -40 51 -63 18 -63 -19 0 -17 -4 15 -25 41 -28 63 -26
47 5 -5 10 -10 23 -10 28 0 10 215 -45 303 -78 47 -18 66 -32 103 -78 24 -32
44 -60 44 -64 0 -4 -29 -5 -65 -1 -42 4 -65 2 -65 -4 0 -16 19 -28 95 -57 190
-74 296 -176 331 -319 16 -63 16 -77 0 -215 -10 -81 -24 -185 -32 -231 -19
-110 -18 -307 2 -396 28 -129 29 -140 4 -165 -21 -21 -28 -22 -114 -17 -111 8
-131 3 -147 -35 -22 -55 -35 -60 -80 -36 -36 19 -48 20 -122 12 -130 -14 -352
-9 -430 10 -38 9 -81 16 -96 16 -37 0 -59 24 -41 45 14 17 14 17 -14 32 -14 8
-32 7 -65 -3 -25 -7 -47 -11 -50 -9 -10 11 21 56 54 77 19 13 35 25 35 28 0 3
-30 19 -67 35 -73 31 -153 42 -218 28 -22 -5 -68 -5 -101 -1 -98 13 -296 65
-305 79 -14 23 20 49 62 49 76 0 665 -140 907 -215 119 -37 182 -42 242 -21
l35 13 -80 17 c-44 10 -218 56 -387 102 -333 90 -408 107 -538 123 -93 12
-246 13 -272 3 -9 -4 -32 -29 -50 -57 -59 -85 -152 -115 -262 -84 -46 13 -36
23 30 34 23 3 52 16 64 27 l23 21 -24 10 c-13 4 -60 7 -104 5 -71 -3 -92 -8
-180 -50 -84 -39 -112 -47 -175 -51 -49 -4 -104 -16 -157 -35 -131 -48 -260
-83 -345 -92 -67 -8 -85 -14 -114 -40 -33 -29 -38 -30 -129 -30 l-95 0 0 -32
c0 -17 11 -59 24 -92 13 -34 27 -81 31 -106 9 -59 32 -94 103 -162 99 -94 178
-125 350 -134 103 -5 148 -20 228 -75 l46 -31 -55 -37 c-62 -40 -61 -32 -26
-149 22 -75 18 -88 -45 -144 l-41 -37 40 10 c53 12 125 11 125 -1 0 -5 -60
-28 -132 -51 -172 -53 -232 -76 -292 -113 l-49 -29 22 -18 c12 -10 27 -19 33
-21 7 -3 -21 -41 -65 -90 -62 -68 -95 -95 -159 -129 -93 -50 -148 -97 -148
-126 0 -11 14 -39 30 -61 17 -23 30 -46 30 -51 0 -4 -10 -32 -21 -60 -12 -28
-20 -54 -17 -57 3 -3 50 2 104 10 174 27 162 24 157 50 -3 16 2 31 16 45 18
18 33 21 120 21 91 0 103 -2 156 -31 66 -35 105 -34 209 5 l60 22 -61 13 c-33
7 -66 17 -74 21 -21 13 5 37 49 44 21 3 135 35 253 70 118 35 306 85 419 110
277 62 645 173 712 216 l53 32 138 -12 137 -12 29 31 29 31 184 0 c223 0 396
12 717 47 225 24 254 26 355 14 61 -6 137 -19 169 -27 32 -8 62 -12 67 -9 23
14 183 5 285 -15 183 -38 288 -13 424 100 55 45 72 54 108 56 58 2 89 32 109
104 19 65 35 86 80 100 18 6 45 25 60 43 23 28 26 37 22 95 l-4 64 53 39 c29
22 53 43 53 47 0 4 -23 41 -50 82 -75 112 -85 153 -69 305 l13 124 28 -17 c19
-11 44 -47 73 -105 24 -49 66 -121 92 -160 l48 -72 3 33 c3 25 -57 184 -232
620 -129 323 -243 596 -253 608 -19 21 -120 61 -130 50 -3 -3 10 -27 29 -53
19 -26 37 -53 41 -60 5 -7 -7 -22 -30 -37 l-38 -26 -28 55 c-42 83 -85 222
-93 297 l-7 67 37 6 c20 4 38 11 41 15 9 14 -51 175 -66 175 -9 0 -17 -21 -24
-60 -10 -61 -24 -75 -54 -56 -8 5 -65 161 -126 348 -107 322 -289 812 -318
857 -8 12 -32 27 -53 35 -21 7 -129 55 -239 106 -235 108 -305 135 -440 171
-55 14 -116 35 -136 45 -47 23 -104 90 -123 143 -61 165 -245 682 -278 776
-36 104 -39 123 -36 197 2 45 8 87 15 94 6 6 49 15 95 20 168 16 490 148 703
289 230 152 289 204 354 315 85 143 91 194 37 314 -45 99 -57 178 -50 326 4
79 1 192 -11 330 -12 147 -17 325 -18 590 -1 416 -2 400 67 615 l24 74 19 -64
19 -65 42 89 c23 49 42 93 42 98 0 5 -15 28 -32 50 -23 29 -29 42 -20 45 23 8
94 67 115 94 10 15 22 49 25 75 3 31 23 82 53 139 33 63 78 185 149 405 322
1000 319 990 373 1047 l49 52 -20 21 c-11 12 -23 33 -27 47 -4 16 27 139 90
358 52 183 109 399 125 479 57 276 114 406 380 871 390 682 513 913 640 1202
80 182 118 222 290 299 107 48 144 58 520 139 645 138 781 176 1145 317 347
135 422 161 678 234 142 41 324 97 405 125 513 178 613 209 782 244 371 76
430 117 464 325 l6 35 -75 24 c-86 26 -175 81 -222 135 -51 57 -51 56 80 75
49 7 76 7 93 -1 20 -9 36 -8 77 5 29 9 57 17 63 19 7 2 2 27 -14 71 l-25 68
-39 -39 -39 -39 -95 26 c-105 28 -125 51 -46 51 26 0 71 3 100 7 51 6 52 6 35
25 -47 52 -135 62 -244 28 -103 -32 -121 -47 -159 -136 -18 -44 -43 -92 -53
-108 -22 -31 -79 -60 -142 -71 l-40 -7 50 34 c47 31 50 36 53 82 2 26 0 46 -5
44 -4 -2 -36 -18 -70 -35 l-62 -33 -20 27 c-25 30 -27 80 -6 110 41 59 268
180 399 213 67 17 99 19 200 14 144 -6 158 -1 134 49 -15 32 -21 35 -75 41
-72 8 -88 5 -463 -89 -249 -62 -328 -78 -430 -86 -189 -14 -473 -54 -743 -104
-922 -171 -916 -169 -1135 -244 -152 -51 -228 -71 -285 -76 l-79 -7 35 68 c19
38 47 80 62 94 96 88 267 310 475 615 184 270 618 851 674 903 24 22 57 47 73
55 16 9 135 45 264 81 601 168 881 287 1036 442 59 58 78 109 88 234 6 69 4
77 -18 104 l-24 29 -32 -19 c-18 -10 -69 -67 -112 -126 l-80 -108 -40 27 c-21
14 -41 24 -43 22 -2 -2 -18 -32 -36 -67 -19 -35 -53 -81 -77 -101 -51 -45
-124 -71 -148 -51 -21 17 -19 42 5 73 25 32 25 45 -2 74 l-22 23 35 29 c19 16
61 43 95 60 33 17 71 41 83 52 26 25 51 78 51 110 0 14 18 39 46 65 42 38 44
43 26 48 -25 8 -58 1 -149 -36 -39 -15 -79 -28 -88 -28 -35 0 25 60 89 89 70
32 112 76 122 127 9 49 45 63 94 37 l35 -20 190 58 c175 52 188 58 162 68 -38
15 -52 14 -122 -8 -33 -10 -108 -21 -167 -25 l-107 -7 -17 32 c-21 42 -13 87
25 144 16 24 115 152 219 282 176 222 222 286 222 313 0 5 -18 10 -40 10 -22
0 -40 2 -40 5 0 3 25 54 55 114 30 60 60 131 66 158 15 62 37 99 64 111 16 8
24 22 27 49 3 21 3 40 1 43 -2 2 -24 -4 -49 -13 -26 -9 -50 -14 -55 -11 -17
10 -9 55 16 92 14 20 25 40 25 43 0 11 -129 89 -134 81 -4 -6 -90 -219 -117
-290 -9 -23 -9 -23 -38 10 -22 24 -27 37 -20 50 18 33 8 73 -30 126 l-39 54
-11 -35 c-17 -50 -80 -103 -143 -121 -71 -20 -148 -30 -148 -20 0 17 67 120
179 274 100 139 209 325 197 337 -7 8 -110 -42 -163 -78 -118 -81 -220 -185
-622 -638 -124 -140 -293 -329 -374 -421 -177 -197 -215 -262 -223 -380 -9
-122 -9 -122 -52 -115 -20 4 -45 9 -54 11 -14 4 -17 -4 -20 -48 l-3 -53 -53 3
c-58 4 -82 -9 -108 -59 -12 -25 -11 -30 21 -72 19 -25 35 -49 35 -54 0 -7 -12
-18 -93 -84 l-37 -31 2 51 c2 41 -2 55 -22 76 -23 24 -23 27 -11 88 25 120 25
119 5 134 -38 28 -49 17 -78 -79 l-28 -92 -39 62 c-21 33 -39 63 -39 66 0 3
15 8 34 11 28 6 35 12 40 39 8 41 -25 156 -44 156 -5 0 -14 -16 -20 -35 -16
-48 -34 -45 -27 4 4 30 15 50 46 79 l41 39 1 79 c0 43 6 94 13 113 l12 34 38
-52 c21 -28 41 -51 46 -51 4 0 24 18 44 40 32 36 36 46 36 95 0 66 -29 148
-69 195 l-30 35 15 -49 c8 -27 14 -58 14 -69 0 -28 -50 -111 -61 -100 -13 13
-23 116 -15 157 4 20 21 53 38 72 30 34 31 37 13 47 -38 21 -66 57 -63 80 3
23 8 24 74 13 38 -7 42 -5 48 16 3 13 6 48 6 78 1 121 33 175 98 168 32 -4 33
-3 26 26 -4 17 -10 40 -14 52 l-8 21 -96 -26 c-76 -21 -95 -29 -90 -41 28 -65
33 -89 23 -123 -5 -19 -16 -40 -24 -47 -12 -10 -18 -4 -35 33 -32 68 -57 86
-127 93 l-60 7 -13 -54 c-20 -82 -94 -294 -134 -381 -19 -43 -60 -122 -90
-175 -47 -85 -61 -122 -100 -276 -35 -135 -46 -197 -46 -254 0 -42 -7 -112
-16 -156 -13 -62 -15 -109 -10 -201 19 -356 18 -387 -2 -523 l-20 -133 -108
-137 c-124 -158 -205 -246 -268 -291 l-43 -31 -19 20 c-15 17 -19 35 -17 83 2
55 1 59 -12 42 -11 -14 -15 -48 -15 -122 0 -84 -3 -106 -18 -124 l-18 -23 -21
27 c-19 23 -22 46 -28 201 -4 97 -10 231 -14 298 l-6 122 -97 40 c-54 22 -101
45 -106 50 -5 6 -17 40 -27 78 -14 53 -20 65 -30 57 -8 -6 -16 -30 -20 -53
-10 -68 -25 -106 -45 -117 -42 -22 -48 -14 -55 74 -9 107 -17 120 -41 64 -13
-32 -19 -77 -22 -175 l-4 -133 -28 15 c-38 20 -57 19 -68 -2 -5 -10 -12 -117
-15 -238 -5 -168 -12 -253 -31 -357 -26 -148 -29 -158 -52 -158 -12 0 -14 9
-10 43 9 70 19 743 11 776 -6 30 -35 45 -35 19 -6 -274 -36 -664 -65 -835 l-6
-31 41 10 c22 6 42 9 44 7 10 -11 33 -189 29 -222 -7 -48 -15 -52 -42 -23 -16
17 -25 44 -31 91 -5 37 -10 68 -12 70 -2 3 -20 -3 -39 -13 -23 -12 -38 -28
-45 -52 -7 -21 -17 -187 -23 -405 -7 -204 -14 -385 -18 -403 -6 -34 -11 -36
-54 -18 -12 5 -22 -7 -43 -53 -37 -83 -49 -160 -42 -263 6 -92 0 -104 -36 -75
-18 14 -43 134 -59 284 -11 100 -9 356 2 367 3 4 41 -1 83 -10 43 -9 79 -14
81 -12 2 1 -3 17 -10 35 -12 29 -19 33 -65 39 -35 5 -55 13 -65 28 -21 29 -19
39 20 90 41 54 48 101 14 91 -14 -5 -25 -2 -30 6 -15 25 -10 412 7 528 16 112
138 696 206 991 38 167 58 235 178 625 56 185 61 245 25 315 -25 48 -14 88 51
191 70 112 139 188 197 219 38 20 44 28 43 53 0 17 5 32 12 34 7 3 12 -3 12
-13 0 -13 14 -3 46 34 29 33 104 91 207 160 344 229 448 304 478 348 47 69 73
85 139 82 47 -2 65 3 112 29 30 17 80 36 111 42 73 13 104 27 217 99 136 87
139 90 146 120 14 67 31 87 138 155 59 38 128 77 154 88 93 40 649 103 1052
119 230 9 310 19 560 69 116 23 192 26 228 7z m409 -609 c45 -100 80 -184 77
-186 -2 -3 -23 13 -45 35 -47 46 -83 115 -103 199 -17 71 -27 149 -18 140 4
-3 43 -88 89 -188z m3343 104 c6 -13 6 -22 1 -26 -9 -5 -31 24 -31 39 0 14 20
4 30 -13z m259 -212 c30 -11 62 -19 72 -19 18 0 56 -67 44 -78 -4 -4 -137 28
-226 54 -13 4 -18 -5 -24 -41 -4 -29 -11 -45 -18 -42 -21 6 -57 69 -57 97 0
23 7 31 38 44 42 18 86 14 171 -15z m2316 1 c11 -13 14 -20 6 -20 -21 0 -52
21 -46 31 9 14 20 11 40 -11z m-2334 -153 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8
6 -3 11 -8 11 -12z m-4045 -63 c-3 -9 -6 -24 -6 -35 0 -10 -4 -19 -10 -19 -5
0 -10 16 -10 35 0 25 4 35 16 35 11 0 14 -5 10 -16z m7106 -46 c-12 -12 -62
12 -62 28 0 11 8 10 35 -3 20 -9 31 -21 27 -25z m-3161 5 c7 -20 7 -28 -3 -34
-14 -9 -26 11 -27 44 -1 26 18 20 30 -10z m-9821 -12 c0 -5 -26 -15 -57 -21
-32 -6 -69 -14 -82 -17 -17 -4 -22 -2 -19 8 7 22 158 51 158 30z m5315 -85
c32 -15 32 -15 -38 -36 -22 -7 -65 -37 -103 -72 -35 -33 -64 -57 -64 -54 0 24
57 120 86 145 38 34 71 38 119 17z m8835 -131 c0 -25 -4 -45 -10 -45 -5 0 -10
20 -10 45 0 25 5 45 10 45 6 0 10 -20 10 -45z m-9058 -152 c4 -45 -11 -51 -46
-21 l-26 23 27 45 27 44 7 -27 c4 -16 9 -44 11 -64z m5508 50 c0 -7 -4 -13
-10 -13 -5 0 -10 12 -10 28 0 21 2 24 10 12 5 -8 10 -21 10 -27z m1210 -78 c0
-60 -15 -71 -52 -38 -25 22 -23 59 4 77 39 28 48 21 48 -39z m1325 35 c3 -5
-2 -10 -12 -10 -10 0 -29 -3 -43 -7 -22 -6 -24 -5 -11 10 15 19 56 23 66 7z
m75 -52 c0 -17 -20 -17 -29 1 -16 29 -14 33 9 19 11 -7 20 -16 20 -20z m201
-129 c17 -12 28 -23 26 -26 -6 -6 -137 48 -137 56 0 26 52 12 111 -30z m-1651
11 c9 -18 8 -18 -20 -9 -39 14 -48 29 -16 29 15 0 30 -8 36 -20z m2268 -8 c2
-8 -3 -9 -19 -4 -13 5 -25 14 -27 20 -2 8 3 9 19 4 13 -5 25 -14 27 -20z
m-415 -39 c70 -26 76 -35 52 -68 -15 -18 -9 -21 157 -78 95 -32 177 -63 183
-68 17 -17 -119 -9 -193 11 -92 26 -220 89 -296 148 l-59 45 28 24 c17 15 34
22 44 18 9 -4 46 -18 84 -32z m-8973 -220 c0 -7 -14 -28 -31 -48 -47 -53 -59
-29 -17 33 30 47 47 52 48 15z m-4853 -321 c-15 -15 -27 -33 -27 -39 0 -6 33
-19 73 -28 39 -10 76 -21 80 -25 7 -6 -46 -159 -78 -220 -21 -41 -82 -108
-120 -133 l-30 -19 68 6 c49 4 67 2 67 -7 -1 -61 -25 -285 -46 -427 -46 -315
-24 -627 75 -1025 79 -317 157 -472 318 -633 137 -138 142 -154 119 -361 -69
-603 -111 -1030 -126 -1281 -18 -300 -30 -440 -51 -575 -15 -93 -19 -151 -14
-203 6 -68 5 -75 -15 -89 -18 -12 -21 -23 -17 -52 5 -41 20 -88 49 -148 16
-34 18 -46 9 -57 -8 -9 -10 -32 -6 -63 5 -36 1 -65 -18 -118 -21 -62 -22 -74
-11 -105 19 -53 17 -58 -27 -67 -40 -8 -40 -9 -81 -108 -37 -89 -42 -111 -46
-200 -4 -103 -12 -125 -42 -125 -34 0 -39 -42 -10 -104 19 -43 19 -45 1 -152
-49 -284 -82 -349 -282 -559 -80 -84 -120 -145 -155 -236 -20 -55 -37 -78 -77
-114 -28 -25 -57 -45 -64 -45 -19 0 -72 78 -79 116 -3 18 2 109 12 201 10 93
19 234 21 315 l4 147 -25 7 c-36 9 -65 -12 -87 -61 -25 -55 -34 -61 -53 -33
-26 37 -20 77 16 107 78 65 124 118 136 157 8 27 12 115 12 257 l0 216 -30 16
c-43 22 -77 19 -147 -15 -35 -16 -63 -24 -63 -19 0 6 37 82 83 169 l82 159
-18 33 c-41 76 -43 76 -86 34 -36 -35 -39 -37 -45 -18 -12 37 5 70 66 123 52
45 59 55 53 78 -11 43 -34 71 -65 77 -36 8 -42 22 -29 69 7 25 21 43 44 57 32
18 33 21 21 41 -8 12 -16 56 -19 96 -5 53 -12 83 -27 103 -19 26 -20 40 -18
165 l3 138 43 3 43 3 -35 39 c-23 26 -42 62 -56 111 l-22 73 33 27 c32 26 36
59 8 59 -28 0 -49 33 -49 78 l0 45 23 -19 c62 -55 61 -55 53 12 -7 65 -2 76
41 87 30 7 31 -1 38 -313 5 -270 9 -310 30 -310 19 0 22 19 54 435 61 775 74
983 75 1215 1 239 -7 304 -55 474 -21 77 -135 162 -266 200 l-53 15 -59 -82
c-149 -204 -271 -532 -330 -887 -52 -313 -55 -370 -57 -1125 -1 -803 -4 -839
-78 -1260 -82 -464 -97 -635 -95 -1093 1 -227 -2 -347 -9 -354 -17 -17 -49 12
-313 279 -228 230 -255 261 -371 428 -141 203 -156 230 -189 335 -21 65 -24
95 -24 225 0 83 8 229 18 325 30 304 39 482 47 990 7 383 12 513 24 575 9 44
23 134 32 200 8 66 25 156 36 200 25 94 123 290 232 463 99 158 189 331 321
617 218 474 295 597 779 1243 159 213 302 415 327 462 24 46 62 102 84 124 35
37 185 134 296 193 l37 19 -84 -4 -84 -4 -11 28 c-6 17 -9 61 -6 102 3 58 11
87 42 149 60 122 175 222 254 223 l25 0 -27 -28z m4513 8 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m7710 -106 c0 -13 -6
-24 -13 -24 -9 0 -12 10 -9 30 5 35 22 30 22 -6z m-8171 -79 c0 -16 -8 -48
-18 -70 l-18 -40 -11 40 c-17 57 -15 79 10 110 20 26 22 26 29 9 4 -10 8 -32
8 -49z m10844 -66 c7 -11 7 -28 1 -50 l-10 -34 -32 62 c-18 34 -32 65 -32 69
0 10 62 -30 73 -47z m-3763 -18 c0 -12 -28 -22 -34 -12 -3 5 -3 11 1 15 9 9
33 7 33 -3z m5574 -218 c76 -104 109 -174 113 -241 l4 -57 -36 40 c-20 22 -55
59 -76 83 -45 50 -91 72 -148 72 -32 0 -39 3 -35 15 5 11 21 15 60 15 48 0 54
2 54 20 0 12 -7 34 -15 51 -12 22 -13 34 -5 49 17 32 32 24 84 -47z m-454 -3
c20 0 84 -67 101 -105 30 -66 22 -66 -39 -2 -96 103 -110 121 -89 114 10 -4
23 -7 27 -7z m-207 -54 c70 -44 226 -186 312 -283 121 -135 168 -193 314 -387
273 -362 415 -518 994 -1093 495 -491 468 -455 447 -597 -45 -305 -27 -624 61
-1056 44 -216 115 -601 149 -809 57 -348 58 -614 5 -875 -79 -392 -239 -672
-700 -1232 -123 -149 -175 -201 -189 -187 -21 22 5 458 39 633 32 165 40 324
26 485 -15 161 -43 302 -122 610 l-62 240 -12 380 c-10 278 -20 442 -39 610
-23 197 -27 263 -24 455 3 211 4 227 25 262 l22 38 -20 57 c-11 32 -24 98 -30
147 -9 84 -22 120 -55 153 -9 9 -11 31 -7 68 8 78 -11 133 -83 241 -70 104
-96 152 -154 286 -22 50 -47 100 -57 110 -36 40 -118 10 -129 -47 -13 -69 -9
-508 5 -628 8 -64 11 -120 7 -124 -3 -4 -22 1 -42 10 -58 28 -67 64 -84 332
-10 157 -24 237 -55 312 -15 36 -15 36 -36 17 -12 -10 -30 -37 -42 -59 -18
-35 -21 -69 -30 -275 -10 -252 -4 -380 32 -690 36 -314 43 -419 51 -860 7
-373 12 -466 34 -655 52 -434 57 -527 63 -1188 l5 -629 -20 11 c-12 6 -37 39
-55 74 -312 569 -480 903 -560 1107 -68 172 -182 519 -216 655 -39 156 -58
272 -70 421 -5 66 -19 156 -30 200 -11 43 -47 201 -81 349 -81 358 -80 355
-96 355 -16 0 -17 -17 -3 -80 5 -25 11 -88 13 -140 2 -61 15 -140 34 -220 41
-167 48 -239 29 -320 -15 -63 -15 -64 8 -92 26 -31 31 -51 40 -168 8 -96 75
-361 130 -515 24 -66 113 -293 198 -505 85 -212 171 -434 192 -495 25 -71 50
-123 70 -145 59 -68 96 -130 194 -332 54 -112 119 -237 144 -278 46 -75 46
-75 24 -87 -34 -19 -73 -15 -101 10 -14 12 -48 60 -76 106 -41 69 -59 89 -99
113 -54 30 -89 75 -144 183 -19 39 -57 98 -85 133 l-50 62 -7 -29 c-8 -38 7
-93 50 -183 35 -72 44 -121 25 -133 -20 -12 -57 43 -100 150 -24 61 -92 211
-152 334 -59 123 -108 232 -108 243 0 24 14 23 77 -7 51 -23 53 -23 63 -5 16
30 12 48 -23 99 -19 27 -47 89 -62 137 -15 49 -66 187 -112 307 -62 159 -82
222 -74 230 16 16 13 53 -5 68 -25 21 -72 93 -80 123 -3 14 -1 40 6 56 11 25
7 45 -33 172 -25 79 -48 159 -52 178 -8 38 -31 70 -51 70 -9 0 -7 13 8 47 18
41 20 59 14 142 -6 86 -9 98 -32 119 -14 12 -38 22 -53 22 -16 0 -45 7 -66 16
l-37 15 58 53 59 53 3 81 c3 64 0 92 -17 137 -31 82 -29 191 5 287 14 40 34
102 45 137 20 70 33 89 65 102 27 10 27 8 54 349 41 502 71 816 86 899 8 46
38 154 65 240 63 196 104 365 173 703 30 147 64 284 74 305 42 82 56 146 57
245 0 54 4 98 8 98 4 0 31 -15 60 -34z m-4404 -17 c14 -40 14 -39 -3 -39 -13
0 -26 28 -26 58 0 24 18 12 29 -19z m2044 -75 c3 -3 -1 -23 -8 -45 l-14 -39
32 -16 c18 -9 33 -18 35 -19 2 -2 -9 -22 -25 -46 -27 -42 -28 -46 -27 -176 0
-73 4 -136 7 -140 4 -3 15 1 25 10 10 10 21 17 24 17 8 0 32 -150 25 -157 -4
-3 -15 1 -24 9 -37 31 -40 29 -73 -52 -18 -44 -36 -80 -41 -80 -16 0 -29 39
-29 90 0 40 -10 75 -46 155 l-46 102 48 72 47 71 -25 28 c-32 33 -47 87 -37
126 5 16 23 44 41 63 25 25 40 33 68 33 20 0 39 -3 43 -6z m-9718 -34 c3 -5 2
-10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m12636 -5 c1
-17 -18 -17 -25 0 -4 11 -1 15 10 13 8 -2 15 -7 15 -13z m-5756 -115 c18 -20
18 -20 -13 -9 -18 6 -29 15 -26 20 8 14 19 11 39 -11z m105 -82 c0 -12 -35 -9
-48 4 -7 7 -12 18 -12 26 0 10 7 9 30 -5 17 -9 30 -21 30 -25z m-8242 -13 c55
-46 81 -148 61 -241 -7 -33 -13 -84 -14 -114 0 -57 -70 -264 -85 -255 -7 4
-20 97 -35 240 -6 58 -37 145 -51 145 -3 0 -28 -18 -54 -40 -26 -22 -51 -40
-54 -40 -12 0 -6 127 9 176 10 33 28 63 57 91 69 68 118 79 166 38z m13914
-26 c33 -24 112 -152 97 -158 -19 -6 -86 30 -107 58 -11 15 -32 51 -47 80
l-27 54 28 -7 c15 -4 40 -16 56 -27z m-7942 11 c0 -5 -4 -10 -9 -10 -6 0 -13
5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m8267 -7 c2 -6 -4 -13 -13 -17 -21
-8 -39 15 -21 26 15 9 30 5 34 -9z m-3207 -35 c0 -10 -4 -18 -10 -18 -5 0 -10
15 -10 33 0 24 3 28 10 17 5 -8 10 -23 10 -32z m-8798 -50 c-12 -12 -35 -1
-27 12 3 5 13 6 21 3 10 -4 12 -9 6 -15z m11852 -174 c21 -21 20 -37 -1 -30
-10 3 -31 8 -47 12 l-29 7 24 13 c31 17 35 17 53 -2z m339 -155 c34 -16 70
-29 79 -29 15 0 14 -6 -8 -55 -13 -31 -24 -58 -24 -60 0 -2 14 -2 31 2 25 5
36 0 65 -28 19 -18 34 -36 34 -40 0 -16 -96 12 -115 34 -11 12 -36 32 -55 44
-19 12 -51 39 -71 60 -38 39 -149 187 -149 198 0 3 34 -17 76 -45 41 -28 103
-65 137 -81z m-14733 109 c0 -7 3 -23 6 -35 5 -17 2 -23 -10 -23 -12 0 -16 9
-16 35 0 19 5 35 10 35 6 0 10 -6 10 -12z m14457 -20 c37 -41 72 -128 52 -128
-16 0 -52 20 -76 43 -23 20 -29 59 -17 91 8 22 17 20 41 -6z m-13337 -61 c0
-17 -36 -37 -66 -37 -10 0 -5 13 17 40 33 41 48 40 49 -3z m7820 -34 c32 -26
57 -49 55 -52 -3 -2 -24 4 -48 13 -49 18 -93 59 -82 75 9 16 10 15 75 -36z
m-8960 -154 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10
-9 10 -21z m9036 -51 c140 -107 336 -313 633 -666 185 -221 228 -278 218 -288
-9 -9 -106 76 -162 142 -31 37 -193 211 -359 386 -311 330 -408 448 -367 448
4 0 21 -10 37 -22z m-8720 -39 c19 -37 14 -76 -17 -125 -25 -41 -35 -37 -44
16 -7 41 18 140 36 140 4 0 16 -14 25 -31z m-216 -34 c0 -61 -16 -74 -25 -21
-6 30 7 76 20 76 3 0 5 -25 5 -55z m-2070 36 c0 -14 -18 -23 -30 -16 -6 4 -8
11 -5 16 8 12 35 12 35 0z m17096 -56 c39 -16 69 -61 78 -115 6 -37 7 -39 39
-33 32 5 33 4 51 -50 22 -64 18 -119 -10 -163 l-19 -28 -3 59 -3 60 -88 42
c-75 36 -99 54 -174 133 -48 50 -87 95 -87 100 0 23 158 19 216 -5z m-5466
-37 c0 -5 -4 -8 -10 -8 -14 0 -32 39 -24 52 6 10 33 -25 34 -44z m-9660 -58
c0 -82 -3 -100 -15 -100 -17 0 -18 13 -9 123 4 43 11 77 15 77 5 0 9 -45 9
-100z m14919 -1 c41 -47 59 -75 52 -82 -17 -17 -55 2 -113 57 -66 62 -76 96
-29 96 22 0 40 -14 90 -71z m-14809 -121 c0 -30 -19 -58 -39 -58 -27 0 -29 7
-19 53 17 74 17 75 39 48 10 -13 19 -32 19 -43z m14870 -56 c-14 -5 -16 -3
-24 28 l-7 25 21 -25 c14 -15 18 -26 10 -28z m127 -9 c8 -12 11 -23 6 -26 -12
-8 -55 31 -48 43 9 15 25 8 42 -17z m-14332 -13 c3 -5 -1 -10 -9 -10 -9 0 -16
5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m14639 -91 c-8 -14 -34 -11 -34 4 0
8 3 17 7 20 9 9 34 -13 27 -24z m-4019 -89 c9 -10 34 -22 56 -25 l39 -7 0 -76
-1 -77 -30 58 c-39 76 -58 74 -79 -10 -8 -34 -18 -64 -21 -68 -3 -3 -23 32
-44 79 l-37 84 38 71 38 71 12 -41 c7 -22 20 -49 29 -59z m-10389 -47 c-18
-33 -21 -17 -5 24 6 15 13 23 16 16 2 -6 -3 -24 -11 -40z m3854 -55 c11 -63
11 -71 -9 -110 -20 -41 -20 -43 -2 -63 50 -54 52 -88 12 -193 -16 -42 -35 -95
-43 -117 -15 -45 -45 -65 -97 -65 -31 0 -33 2 -27 28 3 15 15 99 26 187 24
188 48 292 86 370 31 66 35 63 54 -37z m10620 8 c0 -15 -27 -3 -57 24 -35 31
-41 46 -21 52 11 4 78 -62 78 -76z m-70 -88 c0 -11 -48 -10 -71 2 -16 9 -59
77 -59 95 0 2 29 -16 65 -42 36 -26 65 -50 65 -55z m-14090 40 c-11 -29 -29
-61 -40 -72 l-20 -20 0 27 c0 32 14 62 43 95 33 35 38 26 17 -30z m14165 -166
c-10 -10 -44 21 -54 49 -23 67 -11 75 24 14 19 -32 32 -61 30 -63z m-4055 14
c0 -58 -7 -66 -59 -66 -32 0 -54 21 -76 72 l-15 37 38 4 c20 2 54 4 75 5 l37
2 0 -54z m-11480 8 c0 -21 -4 -33 -10 -29 -5 3 -10 19 -10 36 0 16 5 29 10 29
6 0 10 -16 10 -36z m1268 -14 c-20 -35 -48 -54 -48 -33 0 7 61 73 67 73 2 0
-7 -18 -19 -40z m14357 -61 c31 -28 65 -76 65 -94 0 -10 -43 16 -73 44 -16 14
-35 38 -43 54 -13 24 -13 27 0 27 9 0 31 -14 51 -31z m-3238 -154 c3 -51 -1
-96 -10 -125 l-14 -45 -21 91 c-25 103 -27 151 -10 209 l11 40 20 -45 c13 -29
22 -73 24 -125z m-2341 17 c-8 -7 -44 18 -65 44 -37 47 -22 49 24 4 24 -24 43
-46 41 -48z m84 -60 c2 -2 -2 -7 -10 -12 -11 -7 -18 -3 -27 13 -19 34 -15 42
10 21 12 -10 24 -20 27 -22z m-6360 -163 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9
23 8 8 24 -1 24 -14z m988 -24 c-6 -14 -17 -25 -24 -25 -11 0 -11 5 -2 25 6
14 17 25 24 25 11 0 11 -5 2 -25z m-3798 -23 c0 -14 -39 -46 -47 -39 -3 4 2
16 12 27 19 21 35 26 35 12z m-1264 -27 c4 -14 3 -29 0 -33 -9 -8 -26 18 -26
41 0 26 19 20 26 -8z m4274 0 c39 -20 38 -26 -2 -14 -18 5 -34 9 -35 9 -2 0
-3 5 -3 10 0 13 6 13 40 -5z m-9780 -14 c0 -5 -7 -11 -15 -15 -9 -3 -15 0 -15
9 0 8 7 15 15 15 8 0 15 -4 15 -9z m18138 -76 c14 -30 15 -45 4 -45 -11 0 -43
56 -35 64 12 11 20 6 31 -19z m-13601 -222 c-2 -32 -3 -6 -3 57 0 63 1 89 3
58 2 -32 2 -84 0 -115z m4673 162 c0 -6 -33 -19 -75 -29 -29 -7 -29 -7 -11 13
17 19 86 31 86 16z m-9470 -35 c0 -15 -29 -19 -40 -5 -8 10 -8 14 2 18 16 5
38 -2 38 -13z m7154 -29 c16 -6 11 -12 -32 -40 -52 -33 -124 -51 -135 -34 -7
11 110 83 133 82 8 0 23 -4 34 -8z m13382 -26 c21 -51 15 -233 -8 -219 -27 17
-68 90 -68 121 0 31 43 133 56 133 3 0 12 -16 20 -35z m-15528 -22 c2 -11 -4
-26 -13 -33 -13 -11 -15 -8 -15 25 0 39 22 46 28 8z m4287 8 c8 -14 -44 -51
-71 -51 -28 0 -31 16 -7 42 17 19 68 25 78 9z m671 -57 c80 -38 82 -44 24 -98
-35 -33 -57 -46 -79 -46 -16 0 -38 -3 -48 -6 -16 -5 -16 -4 2 11 12 9 32 15
48 12 35 -5 34 6 -3 33 -45 32 -88 35 -180 11 -46 -11 -85 -19 -87 -18 -2 2 0
19 3 37 6 27 15 36 60 57 104 47 171 49 260 7z m184 -14 c0 -10 -32 -30 -49
-30 -19 0 -12 17 12 28 28 14 37 14 37 2z m-10161 -67 c-22 -32 -53 -76 -68
-97 l-26 -40 -3 49 c-4 60 16 91 79 122 24 12 48 22 52 23 4 0 -11 -26 -34
-57z m3171 -124 c7 -25 9 -67 6 -95 -9 -77 -44 -184 -61 -184 -21 0 -45 40
-45 76 0 17 -9 57 -20 89 -25 72 -25 68 5 61 14 -4 25 -2 25 4 0 5 -11 10 -24
10 l-24 0 26 81 27 80 37 -39 c21 -23 42 -59 48 -83z m14695 70 c-6 -9 -11 -7
-22 6 -7 11 -13 26 -13 34 0 12 5 11 21 -6 13 -13 18 -26 14 -34z m4418 27 c3
-8 0 -17 -8 -19 -15 -7 -25 1 -25 20 0 17 26 17 33 -1z m-16787 -95 c32 -141
-3 -287 -87 -366 l-31 -30 7 80 c4 44 22 154 41 243 19 90 34 167 34 170 1 23
22 -35 36 -97z m3968 80 c15 -6 3 -14 -55 -40 -41 -18 -80 -36 -86 -38 -17 -7
-17 59 -1 75 13 13 111 15 142 3z m4646 -50 c0 -32 -4 -40 -22 -44 -77 -17
-537 -69 -562 -63 l-29 7 29 30 c54 57 97 71 239 80 72 4 144 11 161 14 17 4
50 2 73 -5 35 -9 47 -9 64 4 36 25 47 20 47 -23z m574 -7 l90 -17 -70 -27 -69
-28 -23 26 c-12 15 -27 35 -32 45 -13 22 -10 22 104 1z m-206 -84 c6 -24 9
-46 8 -48 -2 -2 -27 -2 -55 0 l-52 3 37 53 c21 29 40 49 44 45 4 -4 12 -28 18
-53z m-228 30 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0
25 -4 25 -10z m480 -60 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15
10 8 0 15 -4 15 -10z m471 -76 c17 -21 9 -24 -39 -11 -44 12 -54 23 -24 29 24
6 49 -2 63 -18z m225 -39 c210 -37 818 -326 961 -458 13 -11 21 -22 19 -25 -3
-2 -103 37 -223 86 -399 165 -644 251 -868 303 -55 12 -104 26 -108 30 -12 10
20 67 40 72 36 9 92 7 179 -8z m5138 -61 c4 -15 4 -29 1 -33 -8 -7 -35 28 -35
46 0 24 28 13 34 -13z m1862 -26 c17 -16 47 -108 36 -108 -4 1 -20 12 -37 25
l-30 24 -37 -36 -38 -37 0 75 c0 42 2 78 5 81 10 9 86 -9 101 -24z m1646 -15
c26 -31 30 -43 15 -43 -23 0 -61 35 -53 48 10 17 20 15 38 -5z m-20932 -108
c7 -21 7 -41 1 -57 -9 -25 -138 -145 -146 -136 -2 2 15 53 38 114 24 60 47
117 50 128 7 18 8 18 27 1 11 -10 24 -32 30 -50z m18980 49 c0 -8 -5 -12 -10
-9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-3095 -84 c8 -15 17 -51 20 -81 4 -30
11 -66 15 -81 7 -21 6 -28 -4 -28 -25 0 -59 36 -75 81 -13 36 -22 45 -44 47
-15 2 -27 6 -27 9 0 3 16 27 36 54 39 54 51 54 79 -1z m3479 -77 c6 -26 4 -33
-8 -33 -13 0 -16 11 -15 53 0 54 9 47 23 -20z m-19706 40 c2 -8 -5 -13 -17
-13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z m16122 -142 c0 -11 -5 -10 -20 4
-20 18 -25 49 -13 81 5 13 10 6 20 -27 7 -24 13 -50 13 -58z m-13374 23 c-13
-99 -57 -144 -140 -144 -37 0 -49 15 -34 43 6 12 21 17 48 17 38 0 42 3 81 65
22 36 43 65 46 65 3 0 3 -21 -1 -46z m16944 -180 c0 -2 -16 -4 -36 -4 -31 0
-37 4 -50 35 -7 19 -14 49 -14 66 0 31 0 31 50 -31 28 -34 50 -64 50 -66z
m-3740 86 c0 -23 -5 -30 -19 -30 -18 0 -19 3 -10 30 6 17 15 30 20 30 5 0 9
-13 9 -30z m4251 -172 c25 -35 29 -50 29 -106 l0 -64 -27 7 c-16 3 -31 10 -35
13 -4 4 -10 52 -14 106 -8 110 -4 114 47 44z m-431 23 c0 -14 -23 -115 -27
-121 -8 -9 -23 25 -23 52 0 36 20 78 37 78 7 0 13 -4 13 -9z m-5472 -137 c98
-97 96 -119 -6 -68 -47 23 -162 133 -162 154 0 16 35 17 65 1 17 -9 63 -48
103 -87z m-5392 -107 c14 -25 13 -31 -6 -75 -40 -91 -104 -131 -155 -98 -40
26 -32 61 37 163 l62 93 23 -28 c13 -15 30 -39 39 -55z m-8778 -68 c-5 -35
-28 -36 -28 -1 0 16 3 32 7 36 11 11 25 -10 21 -35z m20066 21 c20 -7 26 -16
26 -41 l0 -31 -40 32 c-52 41 -44 62 14 40z m-3858 -77 c-4 -37 -9 -69 -12
-72 -12 -12 -52 48 -58 86 -5 38 -4 42 16 46 62 12 61 13 54 -60z m-13846 -3
c0 -9 -9 -25 -20 -35 -20 -18 -20 -17 -20 18 0 19 3 43 7 53 6 16 7 16 20 -2
7 -10 13 -25 13 -34z m6154 -99 c3 -5 -1 -12 -10 -15 -20 -8 -29 -1 -19 13 8
13 22 14 29 2z m7586 -59 c55 -65 69 -68 70 -12 1 44 13 53 43 29 46 -36 64
-87 125 -354 38 -165 45 -216 32 -235 -9 -13 -14 -11 -34 12 -24 26 -45 85
-126 346 -37 121 -60 163 -60 111 0 -11 -5 -30 -11 -41 -10 -19 -12 -19 -51
18 -22 22 -46 51 -54 66 -21 40 -16 135 6 126 4 -2 31 -31 60 -66z m-5910 -34
c0 -3 -13 -9 -30 -13 -16 -4 -30 -12 -30 -18 0 -7 12 -47 27 -89 15 -42 24
-80 21 -83 -14 -14 -91 33 -153 94 l-66 64 23 26 c12 14 38 43 57 63 l34 36
58 -36 c33 -20 59 -40 59 -44z m-1580 15 c0 -37 -39 -81 -104 -117 -37 -20
-69 -36 -71 -36 -11 0 -4 63 9 89 19 35 37 50 83 67 54 19 83 18 83 -3z m225
-54 c28 -59 114 -132 232 -197 240 -134 419 -317 622 -636 45 -70 133 -201
196 -290 142 -203 208 -309 256 -411 79 -171 191 -336 342 -501 100 -111 102
-121 102 -424 0 -281 -7 -337 -70 -575 -71 -269 -115 -536 -161 -967 l-27
-257 21 -143 c27 -180 24 -193 -72 -397 -89 -188 -131 -290 -159 -391 -19 -68
-21 -105 -23 -387 -1 -172 -4 -331 -8 -355 -12 -83 -75 -735 -87 -897 -15
-216 -6 -652 20 -946 11 -126 25 -304 31 -395 24 -372 85 -648 236 -1072 79
-221 100 -327 109 -538 13 -350 73 -590 221 -880 113 -223 242 -401 495 -685
242 -271 273 -314 399 -545 199 -367 356 -699 445 -945 l47 -130 8 -210 c11
-266 27 -340 135 -615 60 -153 82 -259 96 -470 5 -74 12 -164 15 -199 9 -93
-31 -383 -61 -436 -17 -29 -27 -77 -40 -178 -18 -144 -16 -190 12 -269 16 -46
16 -52 -21 -279 -25 -160 -40 -291 -47 -427 -13 -254 -29 -342 -144 -801 -52
-210 -109 -443 -125 -518 -37 -169 -74 -250 -170 -378 -42 -55 -80 -106 -84
-113 -6 -9 5 -28 34 -57 l42 -42 -43 -84 c-56 -113 -103 -180 -194 -279 -133
-145 -137 -154 -280 -530 -107 -284 -134 -340 -209 -441 -77 -102 -144 -243
-216 -453 -33 -98 -112 -319 -174 -490 -63 -171 -128 -358 -145 -416 -16 -58
-57 -169 -90 -248 -71 -167 -83 -211 -110 -398 -19 -135 -40 -215 -74 -279
l-15 -30 -7 28 c-4 16 0 63 10 110 25 118 17 136 -22 56 -29 -58 -115 -328
-107 -337 1 -2 19 8 40 22 44 30 54 23 54 -40 0 -48 -17 -82 -135 -269 -222
-352 -298 -460 -381 -543 -100 -100 -114 -127 -259 -492 -131 -330 -181 -427
-354 -699 -96 -151 -89 -150 -116 -4 -50 270 -92 402 -205 644 l-72 157 -58 6
c-72 8 -103 32 -124 93 -9 26 -16 50 -16 55 0 4 22 8 49 8 53 0 61 8 61 62 0
22 -11 41 -39 69 -38 38 -40 39 -114 39 l-76 0 -10 38 c-6 20 -11 42 -11 47 0
6 -18 29 -40 50 -22 21 -40 42 -40 47 0 4 39 9 88 10 l87 3 3 52 c3 48 -3 64
-81 215 -47 90 -95 197 -107 238 -28 96 -44 126 -91 172 -35 35 -37 40 -28 73
11 39 -5 87 -55 159 -14 22 -43 90 -62 152 l-36 113 -53 16 c-29 8 -55 15 -58
15 -3 0 -17 4 -30 9 -24 9 -25 11 -11 48 30 86 14 152 -57 230 -38 42 -49 75
-46 143 2 37 2 37 -99 56 -15 2 -28 20 -43 55 -12 28 -21 55 -19 60 2 6 23 -4
46 -21 46 -33 62 -32 62 3 0 33 -24 61 -74 91 l-44 26 -7 100 -8 100 -29 0
c-45 0 -66 16 -79 61 -12 39 -12 40 21 68 l34 28 -52 7 c-65 8 -71 20 -82 157
-7 83 -13 108 -49 185 -48 101 -56 112 -129 166 -75 56 -95 90 -88 154 9 76
-13 142 -73 222 -72 98 -80 132 -46 215 5 11 2 17 -8 17 -8 0 -26 9 -40 20
-19 15 -38 59 -81 195 -31 96 -91 264 -132 372 -100 261 -160 442 -174 528 -7
39 -59 210 -117 380 -105 307 -106 311 -104 399 1 82 -1 90 -23 107 -39 31
-51 81 -32 136 9 26 16 60 16 77 0 55 30 160 56 197 43 62 42 94 -6 200 -65
146 -79 193 -96 328 -8 70 -26 180 -39 246 -32 158 -61 390 -75 600 -22 347
-31 457 -38 476 -7 18 -10 15 -31 -21 -12 -22 -25 -40 -27 -40 -3 0 -23 38
-45 85 l-40 84 26 40 c40 59 53 154 33 236 -13 56 -13 66 -1 77 58 47 56 178
-2 178 -8 0 -26 10 -41 23 -36 31 -26 58 37 104 31 22 48 41 45 49 -3 8 -11
52 -18 98 l-12 85 42 40 c50 49 68 105 76 246 4 67 17 143 41 230 19 72 35
133 35 137 0 3 -13 9 -30 13 -16 4 -32 10 -35 14 -2 5 9 33 25 63 16 30 41
104 56 164 53 219 59 238 84 251 34 17 40 34 50 130 10 93 16 103 68 103 37 0
39 6 12 40 -28 36 -25 43 25 64 25 10 45 21 43 25 -2 3 -15 30 -31 58 l-27 53
29 32 29 33 -29 56 c-38 75 -28 84 85 81 44 -1 95 2 113 6 31 7 31 7 18 40
-13 29 -18 32 -55 32 -39 0 -40 1 -40 33 1 29 8 36 71 75 106 67 149 136 115
187 -9 13 -16 28 -16 33 0 5 29 28 64 53 l63 44 -1 50 c-1 41 7 66 45 140 25
50 61 127 79 171 l33 81 54 17 c44 14 53 21 48 34 -15 37 -17 83 -5 111 6 16
45 57 85 89 l72 60 -4 49 c-5 44 0 59 50 153 30 58 57 121 61 142 6 35 5 37
-29 48 -38 13 -42 27 -21 69 40 76 126 114 201 88 l40 -14 112 256 112 256
-50 3 c-35 2 -49 7 -49 18 0 24 36 87 64 114 l27 24 6 -22 c8 -34 20 -27 39
25 32 83 21 206 -29 325 l-16 38 72 57 c40 31 73 57 75 59 2 1 -8 25 -22 53
-34 67 -34 123 1 187 25 46 27 62 39 274 22 370 55 589 128 853 64 227 100
422 138 732 8 67 13 216 13 380 -1 307 3 278 -110 950 -41 242 -95 584 -120
760 -48 339 -65 424 -120 600 -67 217 -162 589 -189 735 -9 47 -23 148 -31
224 -18 158 -37 241 -95 404 -67 189 -111 385 -155 696 -31 216 -34 205 90
437 114 215 145 289 155 381 16 142 48 220 129 321 51 63 128 131 391 347 330
270 438 369 571 522 70 81 138 161 150 178 13 16 25 30 28 30 3 0 15 -19 26
-41z m-8961 -36 c-7 -32 -21 -50 -30 -41 -11 12 7 68 22 68 11 0 13 -7 8 -27z
m8515 -20 c53 -59 59 -84 31 -120 -42 -55 -242 -263 -252 -263 -14 0 0 94 24
155 11 28 42 87 69 132 27 45 54 96 60 112 6 17 15 31 19 31 5 0 27 -21 49
-47z m9243 7 c3 -16 1 -30 -4 -30 -11 0 -30 49 -22 61 9 15 21 1 26 -31z
m-19272 -222 c15 -64 8 -112 -20 -148 l-20 -25 1 150 c0 136 2 147 14 114 7
-20 18 -61 25 -91z m1489 -77 c-12 -70 -21 -86 -33 -55 -17 44 13 159 35 137
8 -8 8 -31 -2 -82z m16326 -11 c7 -21 0 -40 -15 -40 -12 0 -23 37 -15 50 10
16 23 12 30 -10z m-16345 -160 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m16584 -51 c18 -21 26 -42 26 -67 0 -59 -13 -68
-44 -32 -20 25 -26 42 -26 81 0 27 4 49 9 49 5 0 21 -14 35 -31z m-8510 -80
c-15 -15 -31 -26 -36 -23 -5 4 3 19 18 35 15 15 31 26 36 23 5 -4 -3 -19 -18
-35z m8404 -23 c24 -22 28 -47 11 -94 l-11 -31 -39 19 c-21 11 -39 28 -40 38
-2 30 3 65 12 90 10 26 13 25 67 -22z m5209 -7 c26 -17 111 -96 188 -174 144
-145 224 -214 380 -326 103 -74 293 -256 574 -549 303 -318 442 -490 671 -835
186 -279 222 -363 285 -655 38 -177 123 -466 199 -679 96 -266 113 -366 151
-901 8 -118 20 -273 25 -345 7 -83 7 -168 1 -235 -11 -124 -288 -1724 -305
-1760 -11 -24 -17 -26 -151 -38 -233 -20 -402 -61 -745 -177 -91 -31 -246 -79
-345 -106 -333 -92 -666 -245 -868 -397 -78 -60 -367 -216 -378 -205 -17 17
123 242 475 768 444 662 433 647 906 1255 717 922 924 1201 980 1315 21 44 57
135 81 203 24 71 55 141 73 164 52 69 66 92 66 114 0 20 -52 108 -72 120 -12
8 -51 -29 -96 -91 -57 -80 -104 -126 -149 -145 -44 -20 -80 -69 -103 -145 -23
-71 -66 -147 -182 -315 -321 -465 -385 -554 -476 -663 -54 -65 -114 -144 -132
-175 -19 -31 -77 -99 -128 -150 -79 -77 -97 -90 -110 -80 -12 10 -19 7 -38
-15 -13 -16 -24 -35 -24 -44 0 -16 -6 -16 -88 9 -44 13 -44 12 -73 -20 -27
-30 -38 -62 -21 -62 4 0 20 9 37 20 21 14 33 16 42 8 19 -15 16 -29 -12 -71
-22 -32 -31 -37 -65 -37 -47 0 -62 -18 -31 -39 12 -9 32 -23 44 -33 l22 -16
-24 -26 c-23 -24 -134 -87 -178 -101 -23 -6 -23 -5 -23 82 0 141 5 155 84 256
76 98 157 170 241 213 45 24 53 33 64 72 15 57 58 119 118 171 69 61 174 180
268 306 137 184 841 1247 889 1344 60 118 61 158 5 187 -25 13 -33 13 -64 0
-75 -32 -193 -136 -486 -432 -348 -352 -417 -432 -747 -872 -260 -344 -369
-470 -559 -647 -119 -110 -152 -134 -139 -99 3 9 22 68 41 131 30 100 34 124
29 182 -6 62 -4 70 28 132 l35 65 -46 -7 c-79 -13 -81 -12 -81 24 0 47 8 54
76 67 l61 12 -1 127 c-1 98 4 149 22 231 13 58 26 154 29 213 3 60 11 114 17
120 35 38 41 55 48 145 4 54 10 123 14 154 8 67 -1 82 -61 109 -62 29 -69 24
-40 -24 34 -58 32 -88 -7 -133 -36 -41 -37 -42 -23 -229 8 -109 -5 -185 -30
-185 -17 0 -32 34 -78 167 -29 85 -31 95 -19 138 11 42 10 50 -8 80 -26 41
-26 41 10 54 36 12 37 22 7 116 -14 42 -29 71 -38 73 -13 2 -19 -30 -39 -195
-37 -321 -44 -368 -55 -366 -5 1 -13 15 -17 31 -5 17 -34 59 -67 95 -80 89
-95 131 -88 254 4 79 12 117 41 196 28 77 36 113 36 166 0 38 7 88 15 111 19
54 19 60 -3 60 -29 0 -59 -41 -66 -92 -7 -49 -34 -94 -102 -172 l-40 -47 -20
172 c-10 95 -23 174 -29 176 -14 4 -73 -223 -85 -324 -5 -44 -16 -98 -26 -120
-16 -37 -16 -38 -25 -13 -4 14 -29 133 -54 265 -84 443 -145 667 -255 933
-110 268 -137 302 -239 302 -30 0 -81 5 -113 12 -57 12 -59 13 -69 53 -27 109
-51 141 -89 117 -11 -6 -42 -12 -69 -12 -59 0 -66 -7 -111 -116 -23 -57 -32
-97 -40 -190 -5 -66 -19 -149 -31 -187 l-22 -68 31 -127 c35 -144 37 -160 57
-482 8 -129 20 -266 26 -305 18 -116 66 -251 149 -420 126 -261 141 -329 114
-533 -20 -151 -14 -260 29 -490 22 -114 26 -125 60 -160 20 -21 37 -43 37 -50
0 -7 -12 -25 -26 -39 l-27 -26 27 -20 26 -19 -19 -24 -20 -25 45 -97 c25 -53
47 -98 49 -100 2 -3 -16 -27 -40 -54 -52 -58 -55 -81 -24 -160 14 -37 20 -70
18 -115 -2 -53 1 -66 17 -77 19 -14 18 -15 -5 -40 l-23 -25 21 -27 c27 -34 27
-59 -1 -78 -20 -14 -24 -14 -41 2 -25 22 -43 58 -51 102 -15 72 -37 140 -46
140 -6 0 -10 -6 -10 -12 0 -32 -22 -88 -34 -88 -18 0 -46 102 -52 188 -3 37
-11 251 -19 476 -7 225 -18 413 -23 418 -20 20 -80 -267 -101 -482 -6 -58 -18
-193 -26 -300 -8 -107 -18 -214 -21 -238 l-6 -42 -92 87 c-50 49 -116 109
-146 134 -53 45 -285 209 -295 209 -3 0 -5 -22 -5 -50 0 -27 -2 -50 -5 -50 -3
0 -29 19 -58 41 -56 45 -120 78 -151 79 -25 0 -226 -204 -250 -254 -27 -57
-23 -128 11 -195 152 -297 340 -643 376 -693 71 -100 160 -174 277 -233 65
-32 124 -86 150 -135 10 -19 58 -138 108 -265 l90 -230 22 -195 c12 -107 19
-213 16 -235 -8 -60 -98 -293 -153 -397 -36 -70 -71 -116 -143 -192 -112 -118
-152 -173 -290 -395 -73 -118 -118 -179 -161 -218 -46 -43 -70 -77 -109 -155
-57 -114 -65 -171 -30 -203 84 -76 85 -176 6 -308 -30 -49 -35 -65 -30 -100 3
-23 8 -122 13 -220 7 -168 9 -178 32 -203 24 -26 89 -64 109 -64 5 0 10 16 10
35 0 19 3 35 6 35 5 0 114 -82 148 -112 6 -5 -3 -21 -22 -41 l-32 -33 50 -29
c28 -17 52 -39 55 -50 2 -11 9 -53 15 -92 l12 -73 34 0 c37 0 93 25 159 70 22
15 60 39 86 53 46 26 55 39 104 152 14 33 35 70 46 81 20 22 20 23 3 78 l-18
57 99 99 c73 73 117 109 165 133 52 26 69 40 85 73 28 58 56 90 127 147 35 27
97 80 138 117 184 165 514 406 651 476 114 57 389 147 405 131 3 -3 -58 -102
-135 -220 -78 -118 -141 -219 -141 -225 0 -5 13 -15 30 -22 l29 -12 -61 -79
-62 -80 -11 28 -12 28 -12 -23 c-10 -18 -9 -23 4 -28 22 -9 19 -27 -10 -54
-14 -13 -46 -54 -72 -91 -44 -65 -45 -70 -41 -126 5 -48 0 -71 -23 -131 -58
-147 -95 -207 -164 -269 -47 -41 -73 -75 -94 -118 -15 -33 -52 -87 -83 -120
-95 -104 -144 -170 -233 -314 -48 -78 -90 -141 -94 -141 -3 0 -50 7 -105 15
-419 63 -895 70 -1310 19 -66 -8 -220 -32 -341 -54 -278 -49 -432 -67 -667
-80 -173 -9 -273 -23 -588 -80 -83 -16 -140 -20 -205 -17 -84 4 -175 29 -175
47 0 4 82 159 181 345 162 301 192 350 283 463 164 205 284 386 398 599 37 69
74 121 128 178 128 135 190 233 306 485 l75 164 137 144 c132 140 137 143 145
118 5 -14 12 -26 15 -26 23 0 116 67 159 114 112 122 481 711 460 733 -11 10
-53 -27 -113 -99 -33 -40 -76 -82 -94 -92 -41 -24 -43 -28 82 141 138 186 198
306 198 393 0 51 31 128 82 206 68 105 80 137 80 212 -1 91 -35 155 -198 372
-140 188 -200 276 -290 429 -83 142 -143 176 -360 205 -101 13 -257 56 -277
75 -4 4 6 44 23 89 37 102 37 106 3 97 -15 -3 -32 -7 -37 -8 -4 -1 -18 27 -31
63 l-22 65 -44 5 -44 5 -8 64 c-7 54 -4 75 17 140 34 105 45 167 53 286 5 84
3 108 -11 135 -13 27 -16 66 -16 215 0 203 9 266 51 371 20 53 31 104 39 186
6 62 14 122 17 133 6 24 31 53 55 66 15 8 19 3 29 -36 11 -42 13 -45 45 -45
27 0 35 -5 38 -22 10 -48 26 -258 26 -346 0 -75 3 -92 15 -92 17 0 20 70 16
330 -5 250 29 517 98 795 46 181 68 227 158 325 146 160 217 262 287 413 54
115 119 201 205 273 107 90 119 103 132 141 10 31 9 41 -6 69 -9 18 -26 34
-36 37 -17 5 -16 11 16 92 18 48 53 119 77 158 82 137 330 502 366 540 37 39
390 321 805 643 299 231 292 226 350 232 l49 5 83 126 c79 120 122 171 155
184 31 13 90 -2 137 -34z m-23108 -79 c7 -47 15 -96 17 -110 5 -25 5 -25 -11
-5 -10 11 -28 43 -41 71 -23 49 -23 52 -8 98 9 26 20 44 24 39 4 -4 12 -46 19
-93z m18043 25 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
11 -8 11 -13z m-16631 -202 c24 -53 45 -99 47 -103 1 -4 -24 -23 -57 -43 l-60
-36 -8 37 c-12 58 -9 142 6 195 7 26 17 47 20 47 4 0 27 -44 52 -97z m15629 3
c22 -46 50 -120 62 -166 l22 -82 57 6 57 7 -10 -43 c-11 -53 -31 -75 -77 -88
-63 -17 -70 -15 -59 23 8 29 3 44 -49 147 -77 151 -98 216 -84 253 18 47 40
33 81 -57z m1097 32 c26 -24 45 -104 36 -148 l-6 -31 -22 21 c-24 22 -68 113
-81 164 -7 28 -6 29 22 22 16 -4 39 -17 51 -28z m-157 -27 c19 -15 22 -23 14
-31 -9 -9 -22 -6 -53 9 -36 19 -40 24 -29 40 13 21 21 19 68 -18z m-20151 19
c10 -5 47 -45 81 -89 81 -102 343 -322 424 -356 20 -8 57 -15 83 -15 29 0 60
-7 78 -18 17 -10 83 -93 148 -183 64 -90 126 -175 137 -189 l20 -25 0 40 0 39
30 -34 c37 -44 37 -52 3 -44 -18 4 -5 -8 36 -32 40 -25 94 -72 145 -128 235
-259 360 -387 384 -393 17 -4 37 0 56 11 l29 17 94 -100 c51 -55 121 -118 156
-141 59 -38 65 -40 117 -34 46 5 59 3 79 -13 28 -23 30 -41 17 -181 -8 -92 -8
-94 12 -83 15 8 23 7 36 -8 22 -25 20 -40 -5 -34 -24 7 -24 8 14 -65 31 -59
51 -62 35 -6 -5 20 -8 38 -6 40 3 2 21 -11 40 -29 24 -22 40 -48 47 -78 19
-75 52 -151 101 -229 69 -110 83 -141 138 -300 28 -80 71 -190 95 -245 24 -55
54 -129 66 -166 12 -36 42 -101 67 -145 97 -167 124 -221 137 -280 8 -34 24
-75 35 -90 43 -59 47 -104 34 -376 -7 -139 -12 -278 -12 -309 0 -67 -22 -145
-52 -187 -78 -106 -140 -182 -156 -191 -14 -7 -31 -4 -68 13 l-49 23 -42 -29
c-38 -28 -43 -29 -76 -17 -35 12 -36 12 -100 -44 -80 -71 -92 -72 -82 -11 6
41 5 45 -12 41 -10 -3 -54 -13 -98 -22 -75 -16 -83 -21 -132 -71 -33 -35 -69
-89 -100 -152 l-48 -97 -230 -117 c-127 -65 -235 -117 -241 -118 -21 0 -94
222 -128 390 -8 41 -25 138 -36 215 -12 77 -39 239 -61 360 -44 247 -46 294
-24 565 34 417 48 499 115 651 174 394 228 601 217 834 -7 154 -45 257 -143
385 -89 117 -129 136 -213 99 -67 -28 -104 -72 -192 -221 -39 -67 -100 -170
-134 -228 -34 -58 -68 -123 -75 -145 -7 -22 -43 -119 -81 -216 -78 -204 -121
-339 -144 -459 -10 -47 -24 -101 -32 -120 -14 -34 -60 -90 -74 -90 -17 0 -31
71 -59 297 -46 372 -70 616 -70 703 0 43 13 149 29 237 70 383 66 615 -16 861
-51 154 -211 434 -285 499 -49 42 -117 56 -209 43 -208 -30 -284 -121 -402
-488 -25 -75 -53 -175 -63 -222 -11 -54 -28 -100 -47 -128 -74 -109 -82 -177
-98 -777 -16 -605 -9 -919 25 -1150 30 -198 46 -261 85 -319 22 -33 25 -48 23
-105 -4 -86 25 -195 79 -302 72 -144 73 -151 44 -235 -14 -40 -25 -91 -25
-113 0 -49 -8 -51 -72 -13 -64 36 -325 312 -432 456 -64 85 -151 178 -341 363
-140 136 -280 279 -310 317 -31 37 -78 114 -104 171 -59 123 -97 163 -207 216
-50 24 -86 48 -94 64 -33 61 -107 73 -208 32 l-61 -25 -21 33 c-47 75 -36 145
41 281 73 128 137 187 222 208 28 7 28 8 13 31 -22 33 -20 54 8 96 14 20 54
104 90 187 115 268 268 523 506 841 199 265 263 326 603 568 104 74 334 239
511 367 176 128 328 235 336 239 22 8 30 8 49 -3z m19451 -98 c0 -13 -12 -22
-22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-5245 -12 c4 -6 -7 -10 -24 -10
-17 0 -31 5 -31 10 0 6 11 10 24 10 14 0 28 -4 31 -10z m-10395 -90 c0 -11 -7
-20 -15 -20 -13 0 -14 4 -5 20 6 11 13 20 15 20 3 0 5 -9 5 -20z m13168 0 c26
-25 26 -22 4 -60 l-19 -30 -26 28 c-32 33 -33 39 -10 64 20 23 24 23 51 -2z
m3315 -121 c26 -74 22 -81 -30 -49 -53 32 -66 61 -58 128 l7 57 28 -33 c15
-19 39 -65 53 -103z m-3715 91 l47 -1 -28 -24 c-15 -14 -32 -25 -36 -25 -4 0
-20 14 -37 32 -20 22 -24 29 -11 25 10 -4 40 -7 65 -7z m-11738 -4 c0 -1 -5
-17 -11 -35 -12 -35 -35 -43 -25 -8 3 12 6 28 6 35 0 6 7 12 15 12 8 0 15 -2
15 -4z m11551 -9 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z
m-12873 -260 l-3 -62 -20 30 c-27 40 -27 135 0 190 l20 40 3 -68 c2 -37 2 -96
0 -130z m10507 131 c24 -11 33 -32 17 -42 -12 -7 -73 34 -66 45 7 12 18 11 49
-3z m6342 -94 c-12 -13 -37 6 -37 28 l0 22 22 -21 c12 -13 19 -25 15 -29z
m-16568 -51 c1 -7 -6 -13 -14 -13 -16 0 -22 50 -8 64 7 7 20 -23 22 -51z
m15561 31 c14 -36 13 -184 -1 -184 -22 0 -67 40 -83 74 -9 19 -16 57 -16 85
l0 51 45 0 c40 0 47 -3 55 -26z m2190 -48 c0 -7 3 -34 6 -60 6 -38 4 -46 -10
-46 -13 0 -16 11 -16 60 0 33 4 60 10 60 6 0 10 -6 10 -14z m145 -92 c8 -17
15 -47 15 -66 0 -31 -4 -36 -55 -61 l-55 -27 0 132 0 132 40 -39 c21 -21 46
-53 55 -71z m-7470 9 l60 -65 -40 7 c-45 8 -70 27 -92 68 -14 28 -12 58 4 56
4 -1 35 -30 68 -66z m5515 6 c-16 -28 -32 -24 -18 6 6 13 15 22 19 19 5 -3 4
-14 -1 -25z m-15870 -74 c-35 -36 -67 -65 -71 -65 -4 0 -10 21 -13 48 -4 26
-9 55 -12 65 -5 15 3 17 78 17 l82 0 -64 -65z m10452 -157 c-22 -22 -95 27
-177 119 -77 87 -41 78 76 -18 71 -59 107 -95 101 -101z m2636 150 c-5 -16
-58 -24 -58 -9 0 8 13 13 53 20 5 0 7 -5 5 -11z m2453 -15 c9 -10 14 -42 15
-82 l1 -66 36 -6 c49 -8 86 -34 92 -67 3 -15 16 -61 30 -102 31 -95 31 -111
-1 -149 l-26 -31 -45 32 c-24 18 -46 35 -48 38 -1 3 0 26 5 52 6 44 4 52 -29
100 -66 99 -111 183 -111 208 0 31 35 90 54 90 7 0 20 -7 27 -17z m-226 -119
c20 -63 58 -159 86 -212 41 -80 49 -103 44 -132 -11 -65 -20 -66 -53 -8 -80
142 -141 352 -126 436 4 17 8 32 9 32 2 0 20 -52 40 -116z m-9681 75 c-8 -14
-34 -11 -34 4 0 8 3 17 7 20 9 9 34 -13 27 -24z m-7177 -43 c67 -63 68 -112 2
-182 -29 -30 -29 -31 -11 -47 18 -16 21 -16 57 13 39 31 68 37 95 20 13 -8 13
-13 -3 -37 -46 -72 -51 -90 -37 -133 12 -35 11 -45 -3 -75 -9 -19 -22 -35 -29
-35 -34 0 -184 24 -191 30 -5 4 -1 31 7 61 9 29 16 56 16 60 0 3 -25 17 -55
30 -66 29 -66 31 11 144 61 90 77 134 67 185 -6 33 -5 34 13 21 10 -8 38 -33
61 -55z m19546 26 c10 -10 14 -21 11 -25 -9 -9 -55 23 -48 34 8 14 19 11 37
-9z m-18053 -152 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m18052 -140 l-9 -24 -15 25 c-20 33 -13 127 10 131 14 3 18 -7
20 -52 2 -31 -1 -67 -6 -80z m-19372 -50 c0 -13 -11 -13 -30 0 -12 8 -11 10 8
10 12 0 22 -4 22 -10z m1440 -71 c0 -27 -22 -70 -34 -66 -24 8 -29 35 -16 82
l13 48 19 -23 c10 -12 18 -31 18 -41z m15500 -4 c-13 -39 -24 -37 -35 7 -6 22
-3 27 17 30 31 5 32 4 18 -37z m2248 -44 c9 -73 8 -74 -10 -51 -18 23 -23 74
-11 120 8 31 10 23 21 -69z m-78 9 c0 -22 -4 -40 -10 -40 -5 0 -10 18 -10 40
0 22 5 40 10 40 6 0 10 -18 10 -40z m-18930 15 c0 -8 -7 -15 -15 -15 -16 0
-20 12 -8 23 11 12 23 8 23 -8z m19196 -12 c-3 -16 -8 -57 -12 -93 l-6 -64
-25 47 c-26 51 -24 70 8 111 27 34 42 33 35 -1z m-2292 -26 c8 -13 4 -23 -22
-50 -33 -34 -52 -81 -52 -128 0 -21 -7 -28 -41 -39 l-41 -14 33 -26 32 -27
-27 -7 c-38 -9 -62 7 -81 59 l-17 44 31 27 c18 15 59 62 92 106 61 79 73 87
93 55z m-16065 -23 c-33 -34 -34 -34 -37 -13 -4 26 21 49 52 49 17 0 15 -5
-15 -36z m-1335 -11 c-13 -53 -23 -66 -42 -52 -82 62 -82 79 1 79 47 0 47 0
41 -27z m17157 -25 c9 -17 8 -18 -9 -9 -11 6 -22 20 -25 31 -5 18 -3 19 9 9 8
-7 20 -21 25 -31z m-16858 6 c15 -3 27 -12 27 -20 0 -22 -37 -16 -67 12 -23
20 -24 24 -8 20 11 -3 32 -8 48 -12z m1452 -5 c5 -15 0 -28 -15 -43 -20 -18
-22 -19 -16 -3 3 10 6 30 6 43 0 30 16 32 25 3z m15767 -50 c32 -29 32 -30 8
-25 -14 3 -45 8 -69 12 l-44 7 22 19 c32 25 46 23 83 -13z m-16205 -57 c3 -33
2 -77 -2 -98 -6 -36 -8 -38 -21 -19 -15 20 -18 93 -7 158 8 53 23 33 30 -41z
m18211 34 c16 -32 8 -100 -11 -93 -19 6 -40 73 -33 102 8 32 26 28 44 -9z
m-19059 -90 c9 -11 9 -16 0 -21 -10 -7 -28 10 -29 28 0 12 17 8 29 -7z m1291
-34 c0 -48 -8 -57 -32 -35 -21 19 -23 44 -6 61 23 23 38 13 38 -26z m15816 12
c40 -19 65 -62 88 -151 18 -69 20 -97 7 -89 -5 3 -23 1 -40 -5 -33 -12 -34
-14 -9 -79 l10 -25 23 28 c30 34 35 34 35 -3 0 -26 -3 -30 -29 -30 -16 0 -36
-3 -44 -6 -14 -5 -25 3 -60 45 -23 28 -34 20 -42 -29 -6 -36 -10 -40 -43 -45
-60 -10 -65 -13 -58 -38 3 -12 6 -31 6 -42 1 -44 15 -49 56 -21 50 33 56 33
52 -6 -3 -32 -32 -60 -76 -73 -20 -6 -25 0 -48 50 -13 32 -34 98 -46 149 -11
50 -31 112 -44 137 -13 26 -24 49 -24 53 0 3 15 3 33 0 60 -11 60 -11 37 -49
-16 -26 -18 -35 -8 -35 28 0 51 26 45 50 -4 16 1 36 14 57 l21 33 22 -41 c23
-44 74 -79 114 -79 l23 0 -29 43 c-39 56 -60 99 -83 164 l-18 53 41 0 c22 0
56 -7 74 -16z m-16106 -88 c0 -41 -4 -78 -10 -81 -6 -4 -10 23 -10 74 0 47 4
81 10 81 6 0 10 -31 10 -74z m-4298 13 c-4 -55 -11 -79 -23 -79 -10 0 -12 38
-3 93 9 52 29 41 26 -14z m19933 -131 c101 -213 231 -542 265 -673 16 -61 36
-158 44 -215 15 -99 57 -513 53 -516 -1 -1 -22 8 -47 20 l-45 21 -2 65 c-2 36
-9 103 -16 150 -11 76 -15 85 -35 88 -12 2 -22 6 -22 10 0 4 -2 81 -5 172 -7
271 -31 386 -124 608 -119 284 -167 422 -145 422 3 0 38 -69 79 -152z m-19778
-55 c-2 -32 -3 -6 -3 57 0 63 1 89 3 58 2 -32 2 -84 0 -115z m11544 -59 c47
-173 52 -200 56 -324 4 -114 8 -143 28 -185 15 -32 26 -79 30 -130 3 -44 17
-136 30 -205 13 -69 33 -192 44 -275 17 -119 27 -162 51 -210 21 -42 35 -92
45 -165 9 -58 27 -145 40 -195 13 -49 40 -166 60 -258 35 -166 35 -169 19
-220 -13 -44 -14 -78 -7 -225 8 -163 7 -172 -10 -172 -27 0 -55 18 -68 43 -18
32 -48 206 -55 317 -4 65 -12 105 -25 128 -27 47 -24 69 11 91 l30 18 -25 11
c-41 19 -61 82 -119 372 -31 154 -75 337 -100 415 -65 206 -86 327 -105 600
-23 321 -24 387 -6 430 20 46 23 100 12 250 -6 75 -5 105 2 94 5 -8 33 -101
62 -205z m-7525 120 c19 -32 24 -48 16 -56 -7 -7 -19 4 -41 38 -28 46 -31 64
-11 64 5 0 21 -21 36 -46z m16436 -66 c-9 -9 -15 -9 -24 0 -10 10 -10 15 2 22
20 12 38 -6 22 -22z m-20634 -125 l-4 -38 -13 30 c-10 25 -10 37 0 70 l13 40
4 -32 c2 -18 2 -50 0 -70z m20602 -113 c16 -31 35 -83 44 -116 15 -60 57 -306
53 -311 -2 -1 -33 12 -71 29 -61 29 -71 31 -107 21 -33 -9 -43 -8 -60 6 -20
16 -19 18 55 65 42 26 76 51 76 55 0 4 -16 23 -36 42 -44 41 -74 101 -74 149
0 21 12 56 31 88 36 63 44 60 89 -28z m105 50 c-13 -25 -35 -41 -35 -25 0 16
26 55 37 55 11 0 11 -6 -2 -30z m-20718 -322 c-2 -35 -3 -9 -3 57 0 66 1 94 3
63 2 -32 2 -86 0 -120z m20438 142 c13 -24 13 -30 2 -30 -11 0 -37 39 -37 55
0 16 22 0 35 -25z m-18875 -117 c-1 -21 -13 -53 -21 -53 -16 0 -22 30 -9 45
13 16 30 20 30 8z m16368 -17 c43 -16 357 -184 405 -218 15 -11 38 -43 52 -72
17 -36 48 -73 98 -118 40 -36 97 -100 126 -142 52 -76 53 -78 35 -97 -18 -20
-18 -21 20 -56 41 -38 39 -46 -14 -60 -17 -4 -42 3 -88 28 -58 31 -72 34 -147
34 -78 0 -85 2 -121 31 -21 17 -52 52 -69 77 -35 54 -20 53 -201 11 l-112 -26
29 -71 c36 -88 33 -102 -23 -94 -52 7 -68 27 -68 84 l0 44 -67 32 -68 32 50 3
c28 2 54 7 59 12 5 5 -54 29 -135 54 -79 26 -150 49 -158 51 -9 4 -5 12 15 27
16 12 31 23 34 25 8 6 -54 52 -71 53 -9 0 -32 -18 -52 -40 -31 -35 -40 -40
-64 -34 -15 4 -30 9 -32 11 -3 2 5 24 18 49 12 26 21 48 19 49 -2 1 -18 10
-36 19 -44 23 -38 46 18 68 37 15 63 17 145 12 97 -6 101 -5 140 21 35 24 43
26 74 16 23 -7 39 -7 45 -1 15 15 2 22 -106 66 -90 36 -96 40 -81 56 16 18 83
40 188 63 79 18 95 18 143 1z m-388 -66 c12 -8 9 -10 -13 -10 -16 0 -26 4 -22
10 8 12 16 12 35 0z m-385 -171 c-4 -5 -13 -9 -22 -9 -13 0 -13 3 -3 15 13 15
35 11 25 -6z m-16935 -30 c0 -16 -4 -29 -10 -29 -5 0 -10 16 -10 36 0 21 4 33
10 29 6 -3 10 -19 10 -36z m20226 -11 c7 -20 10 -39 6 -42 -12 -12 -42 28 -42
56 0 40 20 32 36 -14z m-3612 2 c37 -14 36 -50 -1 -50 -16 0 -53 -3 -83 -6
l-55 -7 25 28 c39 42 70 52 114 35z m3911 -51 c16 -16 -8 -43 -51 -57 l-39
-13 -47 53 c-26 29 -48 56 -48 59 0 9 173 -31 185 -42z m806 -59 c75 -94 108
-170 119 -274 5 -47 11 -90 14 -95 3 -4 12 -45 20 -90 12 -69 12 -91 1 -139
-12 -52 -11 -67 11 -172 14 -62 23 -125 21 -140 -3 -20 -11 -9 -44 60 -76 161
-112 263 -122 347 -5 53 -16 94 -30 116 -34 55 -23 128 24 167 16 13 16 15 -2
21 -10 4 -30 8 -45 8 -21 1 -28 6 -28 21 0 11 -9 46 -20 77 -11 32 -20 73 -20
93 0 38 27 87 40 73 5 -4 32 -37 61 -73z m-4716 -10 l30 -13 -45 -14 c-88 -27
-142 -27 -196 -3 l-49 23 40 13 c47 16 175 12 220 -6z m408 -5 c4 -8 3 -16 -1
-19 -13 -8 -134 -27 -139 -22 -3 3 6 16 20 30 20 20 36 26 70 26 30 0 47 -5
50 -15z m3532 -65 c15 -29 32 -130 21 -130 -3 0 -19 24 -36 53 -34 59 -44 141
-14 116 8 -7 22 -25 29 -39z m-151 -107 c25 -36 26 -53 3 -53 -16 0 -59 68
-52 80 11 18 24 11 49 -27z m-2985 8 c11 -7 10 -11 -7 -20 -28 -15 -32 -14
-32 9 0 21 16 26 39 11z m-921 -45 l42 -30 -56 -23 c-32 -13 -60 -23 -64 -23
-4 0 -3 21 1 48 4 26 8 53 8 60 1 15 7 12 69 -32z m-18482 -3 c-11 -11 -19 6
-11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m19883 -7 c13 -15 -4 -26 -40 -26
-17 0 -18 3 -9 20 12 23 33 26 49 6z m-16365 -18 c-20 -64 -36 -91 -45 -77
-16 26 -9 57 17 78 33 26 37 26 28 -1z m16475 -81 c1 -11 -41 2 -55 17 -7 6
-15 22 -19 36 -8 24 -6 23 33 -10 22 -19 41 -39 41 -43z m10459 41 c-5 -13
-57 -30 -70 -22 -15 10 -8 23 15 27 43 8 59 7 55 -5z m-27133 -52 c7 -57 2
-86 -21 -123 -38 -61 -54 13 -24 108 13 38 26 69 31 69 4 0 11 -24 14 -54z
m10028 24 c3 -11 1 -23 -4 -26 -5 -3 -9 -1 -9 5 0 5 -3 16 -6 25 -3 9 -2 16 4
16 5 0 12 -9 15 -20z m8628 1 c13 -11 22 -21 19 -24 -18 -15 -133 -77 -137
-73 -12 11 9 96 25 106 27 15 68 12 93 -9z m-18359 -61 c-3 -31 -8 -40 -23
-40 -21 0 -24 16 -9 54 16 42 36 33 32 -14z m18850 -17 c46 -45 93 -131 113
-209 8 -32 27 -87 42 -122 l26 -63 53 7 c46 5 53 4 63 -14 22 -40 14 -52 -33
-52 -23 0 -71 3 -105 6 l-61 7 -10 41 c-30 116 -45 151 -83 189 l-39 39 12 50
c15 59 8 68 -52 68 -42 0 -68 21 -68 54 0 56 84 55 142 -1z m-2191 16 c11 -11
18 -26 16 -32 -8 -24 -33 -16 -55 18 -22 34 -22 35 -2 35 11 0 29 -9 41 -21z
m10183 -26 c-13 -10 -21 -10 -32 -1 -12 10 -11 14 4 25 13 10 21 10 32 1 12
-10 11 -14 -4 -25z m-28444 -9 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5
12 10 9 6 -3 10 -13 10 -21z m1499 -110 c65 -103 77 -164 43 -230 l-20 -39 -8
29 c-4 16 -1 52 5 80 11 48 10 52 -10 68 -11 10 -39 23 -61 28 -25 7 -38 16
-35 24 3 7 8 38 12 69 4 32 10 57 14 57 3 0 31 -39 60 -86z m16880 32 c7 -8
15 -31 18 -52 l6 -38 -44 32 c-24 18 -55 41 -69 52 l-25 20 51 0 c32 0 56 -5
63 -14z m-9149 -35 c118 -7 143 -12 200 -38 36 -16 148 -60 250 -98 196 -73
239 -100 243 -149 2 -26 -2 -30 -32 -38 -53 -14 -96 -2 -130 38 -17 19 -31 39
-31 45 0 5 -18 0 -40 -11 -76 -39 -129 -28 -263 53 -70 42 -140 60 -339 83
l-148 18 50 29 c46 26 49 30 43 59 -6 31 -6 31 28 24 19 -4 95 -11 169 -15z
m8809 -15 c10 -12 8 -17 -7 -25 -21 -11 -42 2 -42 26 0 17 35 17 49 -1z m-541
-50 c63 -9 148 -25 190 -36 113 -30 302 -127 414 -212 142 -108 327 -276 334
-303 7 -28 -60 -188 -247 -597 -65 -141 -132 -300 -149 -355 -75 -241 -109
-556 -75 -709 9 -43 30 -107 45 -141 63 -140 141 -221 333 -351 125 -85 168
-124 153 -139 -13 -13 -137 31 -194 68 -34 22 -98 79 -143 127 -86 92 -94 88
-36 -21 61 -115 129 -173 437 -369 85 -54 168 -110 184 -124 28 -24 29 -27 23
-88 -3 -35 -17 -108 -31 -162 -14 -55 -48 -223 -76 -374 -28 -151 -53 -281
-56 -289 -6 -19 -114 127 -430 584 -508 734 -484 694 -463 755 19 53 7 131
-29 183 -29 44 -30 48 -17 74 20 37 21 33 -25 134 -61 136 -52 142 53 38 l72
-70 3 45 c4 57 -9 88 -140 324 -55 101 -125 237 -154 303 -78 173 -190 391
-293 568 -49 84 -97 176 -106 205 -71 219 -260 400 -665 636 -96 56 -181 107
-187 112 -24 20 52 65 174 103 211 66 259 76 488 99 84 9 505 -4 613 -18z
m-14172 -63 c-7 -17 -8 -17 -17 0 -12 21 -2 44 13 29 6 -6 8 -19 4 -29z
m-1891 -34 c5 -20 1 -32 -16 -48 -20 -18 -39 -27 -39 -18 0 2 7 27 16 56 16
55 27 57 39 10z m7728 -27 c10 -4 -82 -22 -213 -40 -217 -31 -258 -33 -245
-12 5 8 161 46 220 53 54 7 220 6 238 -1z m9187 -27 c25 -26 31 -38 23 -46 -8
-8 -19 -2 -42 22 -54 60 -37 82 19 24z m1699 -11 c2 -2 -13 -29 -33 -60 -21
-31 -41 -52 -45 -48 -7 8 -41 132 -41 148 0 5 110 -32 119 -40z m-10029 16 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m12724 -93
c16 -22 18 -31 8 -52 -13 -29 -34 -32 -56 -9 -19 18 -21 65 -4 82 16 16 28 11
52 -21z m-21354 -52 c30 -16 35 -33 21 -70 -8 -20 -15 -12 -65 73 -10 16 9 15
44 -3z m18820 -105 c6 -10 10 -32 10 -49 0 -16 4 -33 10 -36 22 -14 65 -103
119 -252 70 -191 124 -313 364 -825 101 -216 190 -409 196 -428 6 -19 21 -107
32 -195 37 -295 101 -562 165 -691 109 -221 318 -362 583 -394 52 -6 142 -21
200 -33 90 -18 133 -21 290 -19 305 5 365 -15 459 -151 l53 -77 -32 -72 c-115
-264 -284 -462 -541 -636 -193 -130 -270 -194 -459 -378 -183 -180 -226 -207
-377 -248 -48 -13 -103 -30 -122 -38 -58 -25 -134 -88 -178 -147 -53 -72 -120
-253 -161 -437 -58 -255 -118 -421 -268 -739 -219 -462 -258 -548 -327 -723
-205 -516 -259 -821 -197 -1099 10 -45 16 -94 14 -110 -3 -15 -22 -119 -44
-231 -23 -119 -37 -213 -33 -230 14 -58 53 -109 116 -149 108 -69 131 -81 190
-98 31 -9 71 -27 90 -40 18 -13 44 -32 58 -42 18 -14 42 -18 100 -17 109 2
177 30 254 108 44 46 88 77 163 115 55 30 123 71 150 94 54 44 100 67 137 67
14 0 89 -14 166 -31 78 -17 144 -29 147 -25 3 3 0 19 -7 36 -9 22 -10 34 -2
42 17 17 130 4 200 -22 67 -24 67 -32 0 -44 -41 -7 -48 -20 -20 -34 9 -6 105
-13 212 -17 226 -8 271 -1 295 44 35 69 85 83 211 60 99 -17 164 -41 164 -59
0 -25 -21 -40 -60 -41 -19 0 -44 -4 -55 -8 -24 -10 11 -14 383 -42 173 -13
301 -27 350 -39 118 -30 244 -98 334 -179 54 -48 89 -72 113 -77 31 -5 38 -13
65 -69 34 -72 69 -99 178 -139 63 -22 79 -24 117 -16 58 13 118 1 242 -49 134
-54 160 -53 113 6 -11 15 -19 27 -17 29 5 5 177 -61 177 -68 0 -12 -65 -91
-90 -110 -23 -16 -206 -83 -508 -184 -207 -69 -492 -116 -830 -136 -223 -13
-485 -1 -717 32 -124 18 -205 35 -530 111 -95 22 -242 34 -580 50 -387 18
-444 11 -734 -95 -85 -31 -168 -56 -185 -56 -17 0 -54 11 -83 24 l-51 24 -254
-202 c-169 -136 -274 -227 -316 -277 -115 -134 -352 -510 -607 -964 -178 -316
-251 -490 -315 -751 -17 -71 -76 -280 -130 -464 -108 -368 -174 -608 -246
-890 -81 -318 -73 -492 25 -590 56 -56 121 -79 243 -87 93 -6 149 1 398 48
231 45 350 77 392 104 21 14 60 53 87 87 44 55 48 65 34 75 -14 11 -13 16 8
50 12 21 42 61 65 89 38 46 49 52 103 64 l60 13 36 -32 c20 -17 38 -31 41 -31
14 0 57 30 207 144 167 127 440 316 456 316 5 0 5 -15 -2 -35 -23 -77 13 -55
178 105 142 139 233 210 351 276 48 27 119 76 158 109 87 74 382 285 571 410
187 123 329 204 514 292 93 45 214 114 310 177 183 122 373 227 571 316 140
63 142 65 114 77 -46 19 -73 15 -138 -21 -34 -19 -248 -118 -476 -221 -629
-284 -664 -300 -815 -382 -77 -41 -248 -141 -380 -221 -284 -172 -312 -188
-345 -197 -20 -6 -11 7 41 61 36 38 98 107 139 154 109 128 222 224 320 274
113 58 126 62 133 36 5 -21 52 -44 68 -34 17 10 9 24 -27 42 l-36 19 123 70
c68 39 211 116 316 172 433 227 566 286 743 331 47 11 114 32 150 45 36 14
108 34 160 45 52 12 147 42 210 68 134 55 192 60 255 24 33 -20 163 -58 170
-50 1 2 10 18 19 35 30 59 43 62 228 59 92 -1 169 -4 172 -7 17 -17 -43 -217
-194 -643 -205 -578 -235 -657 -310 -806 -115 -233 -219 -378 -410 -573 -136
-140 -223 -216 -402 -354 -64 -49 -186 -151 -271 -226 -84 -75 -180 -158 -213
-183 -217 -169 -476 -294 -701 -339 -132 -26 -684 -96 -760 -97 -59 0 -84 5
-140 30 -37 17 -87 36 -109 42 -99 25 -854 49 -974 31 -41 -6 -183 -20 -315
-31 -494 -42 -567 -56 -995 -194 -179 -58 -336 -107 -350 -110 -53 -11 -118
-66 -174 -149 -55 -81 -55 -83 -45 -124 10 -38 9 -46 -19 -95 -38 -68 -44 -97
-46 -238 -2 -74 -7 -124 -16 -140 -7 -14 -45 -60 -85 -102 -91 -98 -161 -196
-276 -384 -190 -311 -260 -398 -425 -525 -197 -153 -278 -259 -539 -704 -86
-148 -202 -333 -257 -410 -171 -239 -231 -352 -302 -570 -43 -131 -67 -174
-179 -324 -47 -63 -113 -164 -148 -225 -68 -123 -83 -147 -329 -531 -95 -148
-178 -286 -185 -306 -27 -76 -59 -124 -139 -208 -164 -173 -208 -224 -264
-309 -32 -48 -88 -130 -123 -182 -41 -59 -80 -133 -103 -193 -21 -53 -58 -127
-82 -166 -44 -68 -56 -106 -70 -225 -6 -48 -13 -60 -76 -111 -19 -16 -33 -57
-77 -231 l-53 -211 -61 -11 c-33 -6 -62 -10 -63 -9 0 1 2 28 5 60 l7 57 -38 0
c-56 0 -92 38 -128 134 -27 73 -28 81 -14 107 18 35 78 67 140 76 29 4 48 12
51 22 3 9 34 77 69 151 34 74 66 155 70 180 3 25 24 75 46 112 53 89 69 147
67 238 -3 94 8 117 66 132 32 9 54 23 75 49 24 30 39 39 74 44 40 6 44 10 49
38 3 18 0 55 -5 84 -6 28 -8 71 -4 95 6 41 15 58 60 122 12 17 21 54 26 101 7
69 16 92 137 332 138 276 252 527 299 660 28 80 29 84 13 126 -15 44 -15 44
14 78 73 86 151 220 177 304 6 22 38 96 70 165 38 82 79 198 120 335 246 837
290 959 635 1755 228 525 231 534 244 760 10 160 61 599 91 784 24 145 50 197
139 276 l64 58 -21 27 c-32 41 -43 91 -31 144 12 55 58 129 106 171 33 30 34
32 19 55 -21 33 -20 63 5 131 29 77 61 108 100 100 32 -6 91 13 104 34 5 8 -4
10 -37 5 -42 -6 -47 -5 -71 25 -44 52 -38 65 84 180 l110 106 -11 124 c-12
134 -32 244 -55 311 -9 23 -13 44 -11 46 8 8 50 -19 89 -56 37 -35 39 -35 53
-17 20 28 18 33 -27 74 -46 41 -72 91 -63 119 24 75 62 153 84 176 22 23 40
30 89 36 34 4 67 13 73 19 7 7 15 41 19 75 4 43 20 94 47 153 30 67 72 211 72
249 0 2 11 6 25 9 35 9 93 63 116 109 11 21 47 68 80 103 128 138 265 386 333
604 41 132 144 604 186 856 44 257 55 420 41 589 -12 139 -12 141 19 290 17
82 39 204 50 270 27 162 27 162 49 168 14 3 21 20 30 71 24 139 -22 261 -154
405 l-40 44 -5 -64 -5 -64 -161 228 -162 227 54 187 c61 210 81 251 196 411
l81 114 -40 56 c-22 32 -42 62 -46 68 -4 6 18 38 48 70 66 71 77 108 46 159
-12 20 -21 38 -21 39 0 2 20 -1 45 -6 41 -9 47 -8 65 14 35 40 50 75 59 140 6
42 24 93 54 153 51 100 81 188 127 375 17 69 49 183 71 254 53 169 58 215 29
301 -35 105 -20 177 56 271 44 53 59 97 74 222 7 56 15 102 19 102 4 0 31 -29
59 -64 77 -95 122 -111 232 -81 77 21 260 18 260 -4 0 -4 -25 -20 -55 -36
l-55 -30 67 -5 68 -5 14 -88 c14 -83 14 -100 -13 -325 -58 -498 -147 -1151
-166 -1232 -37 -149 -75 -617 -68 -820 3 -100 7 -123 32 -177 16 -35 31 -63
33 -63 3 0 18 9 34 21 34 24 36 45 10 122 l-18 57 26 -7 c14 -3 37 -10 50 -15
13 -5 26 -7 28 -4 3 2 -14 33 -36 68 -69 110 -83 151 -82 242 0 45 4 93 9 106
8 21 10 18 14 -23 7 -56 57 -163 93 -197 l25 -23 0 47 c0 43 4 53 38 85 20 20
46 41 56 46 17 10 12 19 -53 105 -58 75 -71 98 -67 119 3 14 10 58 16 96 16
105 27 140 79 255 57 124 66 159 81 305 8 80 19 128 41 180 46 110 64 135 98
135 55 0 61 -7 61 -71 0 -32 4 -59 9 -59 4 0 20 12 35 26 24 25 26 33 26 110
l0 82 -36 -9 c-19 -6 -48 -7 -64 -4 -41 9 -90 62 -90 97 0 27 25 88 36 88 2 0
34 -27 69 -60 36 -34 70 -58 75 -55 5 3 0 24 -10 48 -12 25 -20 65 -20 98 0
47 -4 61 -25 80 -20 20 -25 34 -25 79 0 48 28 293 46 404 11 67 23 18 28 -110
3 -86 11 -146 30 -216 14 -54 31 -142 37 -195 6 -54 15 -105 20 -115 23 -42
99 -66 99 -31 0 9 -7 73 -16 141 -9 67 -14 129 -11 136 3 7 22 17 42 20 l37 7
-7 62 c-3 34 -9 75 -11 90 -6 28 -6 29 40 26 25 -2 49 0 53 4 4 4 9 73 11 153
3 118 0 159 -14 215 l-18 70 47 252 c38 206 45 264 40 319 -5 60 -3 71 21 112
15 26 38 54 52 63 37 24 54 65 55 135 0 35 6 110 13 166 l12 104 -28 14 c-37
19 -35 36 10 81 81 80 125 177 171 377 16 67 49 200 74 296 25 96 62 272 81
390 46 280 109 721 103 726 -2 3 -42 -33 -88 -78 -63 -63 -96 -106 -133 -173
-68 -127 -194 -305 -211 -298 -8 2 -19 30 -26 61 -14 68 -5 108 62 277 l44
110 0 245 c0 239 1 246 24 294 30 60 32 128 7 240 -12 53 -16 96 -11 132 6 53
6 53 -36 91 -63 57 -76 64 -127 72 -37 5 -49 12 -59 32 -7 14 -9 35 -6 48 5
20 11 22 49 19 24 -2 44 -1 44 3 0 10 -25 36 -59 59 -33 23 -67 83 -56 100 3
5 22 12 43 16 49 9 62 20 62 56 l0 30 40 -32 c22 -18 45 -40 50 -50z m-18670
60 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m19068 -63 c2 -26 -1 -47 -5 -47 -4 1 -26 16 -48 35 l-40 34 35 15 c19 8 40
14 45 12 6 -1 11 -24 13 -49z m5142 36 c-1 -5 -6 -35 -13 -68 l-13 -60 -27 49
c-15 27 -26 50 -25 51 9 7 64 34 71 35 4 0 7 -3 7 -7z m-1026 -55 c18 -29 35
-93 86 -340 22 -108 59 -259 81 -334 35 -116 41 -147 37 -198 -5 -56 -42 -155
-54 -143 -3 3 -27 103 -54 223 -56 249 -88 360 -170 595 l-58 166 52 26 c63
32 62 32 80 5z m-23288 -23 c11 -22 15 -38 9 -43 -15 -14 -75 -32 -75 -23 0
17 32 101 39 101 4 0 16 -16 27 -35z m19049 -15 c-9 -14 -25 5 -25 30 0 12 3
12 16 -2 9 -9 13 -21 9 -28z m-745 9 c7 -11 9 -24 5 -27 -7 -7 -35 25 -35 40
0 16 19 8 30 -13z m-8215 -20 c6 -19 0 -38 -9 -28 -10 12 -16 49 -7 49 5 0 13
-9 16 -21z m9310 -8 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
m5016 -61 c22 -12 25 -30 7 -48 -8 -8 -18 -7 -39 5 -27 16 -27 18 -12 35 19
21 19 21 44 8z m2785 -97 c-8 -8 -55 18 -70 39 -33 46 -15 52 29 8 24 -24 43
-45 41 -47z m-18329 53 c21 -21 -84 -41 -158 -31 -50 7 -55 19 -15 36 23 10
162 6 173 -5z m10320 -43 c45 -37 53 -37 66 1 2 6 19 -2 39 -17 37 -29 48 -60
48 -144 0 -47 -7 -51 -57 -32 -37 14 -90 65 -114 111 -19 36 -41 118 -32 118
2 0 25 -17 50 -37z m-19188 -160 c1 -61 -21 -93 -65 -93 -24 0 -25 3 -19 28 3
15 15 37 26 48 20 22 20 22 0 60 -28 53 -26 70 12 107 l32 32 6 -65 c4 -36 7
-89 8 -117z m10102 146 c9 -13 19 -46 22 -72 5 -48 4 -48 -16 -30 -25 22 -47
74 -47 109 0 31 16 28 41 -7z m-14911 -6 c-1 -21 -13 -53 -21 -53 -10 0 -12
43 -2 53 9 9 23 9 23 0z m17967 -28 c24 -17 61 -120 84 -234 l13 -63 -29 -24
c-67 -53 -68 -54 -61 -95 3 -22 9 -57 12 -79 7 -43 -13 -80 -43 -80 -20 0 45
-89 82 -111 18 -11 49 -19 76 -19 l46 0 7 -46 c12 -90 11 -93 -66 -165 -83
-77 -89 -100 -49 -182 25 -51 36 -58 46 -27 8 26 17 25 54 -6 36 -30 39 -61
11 -97 -19 -24 -24 -25 -88 -20 -56 5 -70 3 -82 -12 -11 -13 -11 -20 -2 -33
40 -55 52 -80 52 -109 0 -41 31 -70 88 -85 l43 -11 -58 -23 c-55 -22 -123 -82
-123 -108 -1 -33 -17 -37 -69 -16 -66 26 -66 26 -81 -10 -10 -24 -10 -36 0
-60 24 -57 36 -58 89 -6 41 40 49 45 60 32 10 -12 10 -22 0 -51 -11 -28 -11
-42 0 -70 l12 -34 -100 -137 c-56 -75 -101 -140 -101 -145 0 -5 26 -9 58 -9
31 0 62 -4 69 -8 8 -5 -12 -33 -62 -87 -41 -44 -74 -85 -75 -91 0 -5 14 -23
30 -39 l30 -29 0 26 c0 36 46 68 98 68 32 0 45 6 61 26 12 15 21 33 21 40 0
24 17 15 30 -16 12 -30 9 -127 -5 -150 -3 -6 -47 -10 -101 -10 -91 0 -95 -1
-89 -20 4 -11 -1 -43 -10 -71 -22 -65 -14 -92 38 -128 59 -41 97 -79 97 -96 0
-19 -73 -72 -111 -80 -60 -13 -143 40 -168 108 -7 17 -11 133 -11 300 0 150
-6 351 -13 447 -15 218 -15 404 2 495 6 39 32 156 56 260 91 397 135 655 145
855 6 102 14 217 20 255 5 39 9 76 10 83 0 17 31 15 57 -3z m11143 -8 c0 -26
-9 -39 -20 -32 -10 6 0 55 11 55 5 0 9 -10 9 -23z m-15760 -25 c0 -13 -12 -22
-22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-11190 -124 c0 -15 -54 19 -89 55
-19 21 -30 40 -26 47 5 8 25 -6 61 -41 30 -29 54 -57 54 -61z m29860 16 c0
-87 -40 -124 -75 -70 -16 25 -12 59 16 134 l10 25 24 -23 c19 -18 25 -34 25
-66z m-30331 4 c17 -15 31 -30 31 -35 0 -4 -22 -42 -49 -82 l-48 -75 36 -50
c69 -96 101 -123 163 -135 82 -17 100 -35 106 -104 4 -53 6 -56 25 -45 17 9
24 7 39 -12 22 -27 23 -44 2 -36 -9 3 -27 9 -41 12 -24 6 -24 5 -13 -16 12
-23 12 -23 -41 -16 -30 4 -54 8 -55 9 -1 1 -15 31 -32 67 -20 40 -64 101 -116
159 -84 93 -116 150 -116 210 0 14 12 63 27 108 29 88 28 87 82 41z m30131 -3
c0 -19 -4 -35 -10 -35 -5 0 -10 16 -10 35 0 19 5 35 10 35 6 0 10 -16 10 -35z
m-16861 -62 c1 -13 -3 -23 -9 -23 -5 0 -10 21 -9 48 1 38 2 42 9 22 4 -14 8
-35 9 -47z m-13069 -73 c0 -23 -27 -7 -59 35 -28 38 -31 48 -21 60 11 13 18 9
46 -34 19 -28 34 -55 34 -61z m28268 14 c34 -9 26 -24 -13 -24 -26 0 -33 3
-29 15 7 17 9 17 42 9z m-5938 -56 l19 -43 -71 -3 c-39 -2 -73 -1 -76 2 -10
11 30 58 61 71 45 20 46 20 67 -27z m-19430 2 c12 -7 12 -12 -4 -29 -11 -12
-23 -21 -28 -21 -15 0 -21 30 -8 45 14 17 20 18 40 5z m-2806 -112 c47 -23 83
-45 80 -49 -9 -14 -91 -21 -113 -10 -25 14 -74 101 -56 101 3 0 43 -19 89 -42z
m410 -15 c16 -97 -8 -113 -49 -33 -30 59 -30 57 -7 63 42 11 49 7 56 -30z
m2406 -11 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-4849
-15 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m1451 -107 c12
-33 23 -88 26 -139 l5 -84 -43 6 c-75 10 -119 71 -80 112 l23 24 -27 31 c-31
38 -33 70 -5 130 l21 45 29 -35 c16 -19 39 -59 51 -90z m22787 96 c12 -14 35
-125 28 -132 -4 -4 -97 131 -97 141 0 11 58 4 69 -9z m5721 -177 c-11 -20 -77
-55 -87 -46 -2 3 17 44 44 91 l48 86 3 -55 c2 -30 -2 -64 -8 -76z m-5544 39
c4 -6 -1 -17 -11 -25 -21 -15 -37 -1 -28 24 6 16 29 17 39 1z m-21867 -17 c9
-6 10 -10 2 -16 -15 -8 -41 1 -41 15 0 12 20 13 39 1z m12741 -10 c0 -4 22
-39 50 -76 52 -71 67 -114 52 -154 -9 -25 -9 -25 -56 23 -47 47 -88 121 -101
184 -6 31 -5 32 24 32 17 0 31 -4 31 -9z m-1174 -46 c9 -25 15 -48 12 -50 -5
-6 -168 -54 -181 -55 -10 0 -3 25 29 96 l24 54 49 0 c49 0 49 0 67 -45z
m-12393 -120 c30 -38 98 -133 153 -210 283 -399 529 -671 964 -1066 341 -310
528 -490 955 -919 457 -461 519 -510 715 -571 41 -13 81 -28 89 -34 40 -29 83
-195 50 -195 -5 0 -81 42 -168 94 -310 185 -444 244 -868 385 -489 162 -902
337 -1140 482 -89 55 -134 73 -250 104 -118 32 -140 43 -211 108 -32 29 -66
59 -78 67 -30 22 -44 53 -44 101 0 71 -30 248 -56 331 -14 43 -43 115 -64 160
-38 77 -39 84 -34 152 8 88 -4 149 -67 336 -58 175 -73 259 -95 529 l-17 208
28 37 28 37 28 -33 c16 -18 53 -64 82 -103z m11131 93 c10 -38 7 -48 -14 -48
-16 0 -20 7 -20 35 0 40 24 49 34 13z m-10624 -8 c14 -8 25 -7 40 2 17 11 23
10 40 -8 18 -18 19 -24 9 -47 -20 -44 -4 -68 86 -132 82 -57 85 -61 80 -92 -7
-40 11 -58 77 -78 29 -8 55 -21 60 -28 10 -15 10 -107 1 -107 -5 0 -44 23 -88
50 -44 26 -81 47 -83 46 -1 -2 5 -22 13 -46 22 -61 20 -72 -12 -68 -23 2 -33
14 -55 59 -23 50 -65 96 -76 85 -2 -2 17 -32 42 -66 25 -34 46 -66 46 -70 0
-4 -17 -11 -37 -15 -38 -7 -39 -6 -127 86 -155 164 -229 276 -210 318 8 19 18
13 112 -75 56 -53 102 -93 102 -90 0 4 -11 24 -25 46 -46 73 -24 69 68 -12 67
-60 70 -61 63 -33 -14 57 -46 110 -111 184 -66 75 -70 82 -58 94 9 10 21 9 43
-3z m4465 -49 c8 -14 -16 -61 -32 -61 -16 0 -16 10 -3 44 10 27 24 33 35 17z
m13902 -99 c32 -50 83 -169 83 -194 0 -32 -104 140 -140 232 l-21 54 22 -19
c12 -11 37 -44 56 -73z m11620 41 c-11 -10 -27 8 -27 30 l0 21 16 -22 c9 -13
14 -26 11 -29z m-17935 -4 c51 -69 70 -517 27 -647 -40 -120 -161 -218 -331
-271 -72 -22 -400 -81 -450 -81 -20 0 -40 10 -60 31 l-31 30 6 92 c6 111 44
234 97 316 69 106 206 229 526 474 133 103 174 113 216 56z m-377 -8 c-9 -18
-44 -52 -78 -77 -57 -43 -62 -45 -65 -25 -5 33 18 90 43 107 20 13 105 34 113
28 2 -1 -4 -16 -13 -33z m15885 32 c-1 -14 -30 -47 -36 -41 -8 8 14 48 26 48
6 0 10 -3 10 -7z m-14295 -135 c7 -40 16 -127 19 -193 l6 -120 -27 55 c-26 51
-28 65 -31 210 -3 168 7 183 33 48z m-14084 69 c-13 -13 -26 -3 -16 12 3 6 11
8 17 5 6 -4 6 -10 -1 -17z m17371 8 c48 -9 158 -31 245 -51 493 -110 602 -152
733 -284 70 -70 84 -92 200 -314 257 -491 231 -434 224 -497 -6 -56 -5 -57 40
-107 57 -65 87 -116 135 -233 21 -52 55 -121 75 -154 49 -81 62 -129 42 -160
-19 -29 -49 -39 -75 -25 -11 5 -41 38 -67 73 -119 158 -252 263 -430 339 -123
53 -352 102 -617 133 -77 9 -162 25 -193 36 -31 12 -102 56 -157 98 -78 58
-107 87 -124 121 -22 43 -27 47 -124 84 -92 35 -100 40 -94 60 59 178 59 181
60 521 0 176 2 332 3 348 2 32 6 32 124 12z m-11820 -251 c98 -138 117 -170
112 -191 -4 -14 -31 -53 -61 -87 -90 -100 -124 -186 -166 -421 l-24 -130 -125
-37 c-142 -41 -175 -56 -236 -106 -61 -48 -104 -118 -163 -261 -70 -171 -99
-289 -99 -410 0 -53 -7 -134 -15 -181 -20 -116 -20 -131 20 -474 73 -621 127
-945 212 -1266 25 -96 48 -191 51 -210 4 -32 3 -34 -13 -20 -25 21 -144 190
-210 299 -143 238 -242 467 -373 862 -109 328 -180 578 -223 782 l-32 152 59
52 c32 30 119 94 192 144 149 102 248 178 339 263 123 114 159 252 114 437
-37 152 -29 191 54 281 44 47 58 72 75 130 12 39 37 106 56 149 19 42 34 89
34 103 0 28 -26 50 -72 61 -36 9 -35 20 2 42 35 20 34 16 15 79 -8 27 -15 52
-15 55 0 6 73 39 85 39 2 0 5 -20 7 -45 l3 -45 47 40 c46 40 69 40 48 1 -14
-28 -12 -66 4 -81 32 -26 60 -7 115 78 30 45 57 82 60 81 3 0 59 -74 123 -165z
m17562 145 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3
-15z m386 -4 c7 -8 8 -15 2 -15 -5 0 -15 7 -22 15 -7 8 -8 15 -2 15 5 0 15 -7
22 -15z m-23466 -37 c12 -46 7 -108 -9 -108 -12 0 -15 14 -15 65 0 64 12 86
24 43z m30650 6 c20 -8 21 -28 2 -43 -11 -9 -15 -8 -20 5 -7 18 -8 44 -2 44 2
0 11 -3 20 -6z m-17504 -199 c28 -47 42 -125 23 -125 -24 0 -154 236 -153 278
0 14 101 -104 130 -153z m5922 99 c12 -26 3 -55 -17 -56 -15 -1 -33 41 -28 63
7 27 31 24 45 -7z m217 -28 c14 -15 18 -26 12 -30 -6 -4 -17 7 -26 24 -19 36
-16 38 14 6z m7481 -41 c0 -36 -3 -65 -7 -65 -4 0 -36 19 -71 42 l-64 42 63
22 c35 12 67 22 72 23 4 1 7 -28 7 -64z m-1066 27 c16 -14 46 -70 46 -86 0 -2
-9 -2 -20 1 -21 6 -100 89 -100 105 0 14 51 0 74 -20z m2757 -19 c-28 -53 -92
-80 -98 -43 -4 19 54 59 85 59 19 1 21 -2 13 -16z m-27037 -116 l7 -69 -32 7
c-31 7 -29 3 49 -86 80 -92 95 -117 67 -111 -17 3 -226 81 -238 89 -4 2 13 29
38 59 l47 54 -41 0 c-60 0 -90 15 -106 55 -23 53 -19 57 34 30 l50 -25 6 30
c5 24 13 30 43 36 20 3 42 7 47 9 17 5 22 -8 29 -78z m15351 -46 c5 -18 0 -31
-19 -49 l-25 -25 -6 36 c-4 21 -4 54 0 74 l7 37 18 -24 c11 -14 22 -36 25 -49z
m2381 32 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m268 -26
c37 -54 42 -67 26 -67 -13 0 -90 102 -84 112 10 16 22 7 58 -45z m8904 21 c7
-7 8 -17 1 -30 -15 -27 -29 -22 -29 12 0 32 9 37 28 18z m-15304 -14 c10 -25
7 -74 -4 -74 -12 0 -16 9 -24 58 -4 23 -2 32 8 32 8 0 17 -7 20 -16z m168 -51
c16 -15 18 -35 18 -228 0 -181 -2 -214 -16 -226 -13 -10 -17 -10 -25 2 -24 37
-28 85 -14 154 20 96 19 124 -10 170 -30 49 -31 70 -5 113 23 37 27 38 52 15z
m-13119 -45 c44 -16 30 -40 -38 -65 -33 -12 -63 -22 -67 -22 -10 -1 -11 113
-2 122 4 3 23 -1 43 -9 20 -9 49 -21 64 -26z m27920 -39 c-17 -17 -37 -1 -22
17 8 10 15 12 23 4 7 -7 7 -13 -1 -21z m-26812 1 c10 -6 26 -24 34 -40 15 -30
15 -30 -14 -30 -23 0 -34 8 -50 36 -12 19 -21 37 -21 40 0 8 31 4 51 -6z
m18117 -42 c13 -13 17 -68 5 -68 -16 0 -43 35 -43 57 0 25 19 30 38 11z m1476
-49 c17 -16 -2 -21 -40 -12 -53 12 -60 38 -8 29 22 -4 43 -12 48 -17z m-18900
-79 l6 -65 -30 33 c-16 18 -30 36 -30 41 0 10 41 64 45 59 1 -2 5 -32 9 -68z
m-536 6 c62 -32 118 -77 165 -134 32 -38 36 -47 23 -54 -23 -13 -46 3 -157
104 -122 110 -131 135 -31 84z m26692 16 c0 -15 -70 -82 -85 -82 -8 0 -40 10
-71 21 l-58 22 28 23 c24 21 38 24 107 24 43 0 79 -4 79 -8z m-27880 -106 c0
-68 -21 -81 -50 -33 -11 17 -20 35 -20 39 0 8 52 47 63 48 4 0 7 -24 7 -54z
m27975 5 c-9 -16 -25 -3 -18 15 4 10 9 12 15 6 6 -6 7 -15 3 -21z m-8522 -49
c9 -8 17 -27 17 -41 0 -28 -7 -23 -40 33 -23 39 -16 41 23 8z m-17605 -10 c2
-7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m28288 -4 c-3 -13 -6
-31 -6 -40 0 -10 -7 -18 -16 -18 -11 0 -14 5 -10 16 3 9 9 27 12 40 3 13 11
24 16 24 6 0 8 -10 4 -22z m-17207 -111 c1 -63 -20 -112 -61 -137 -18 -11 -37
-20 -41 -20 -22 0 -14 41 29 152 l47 122 13 -34 c7 -19 13 -57 13 -83z
m-11207 63 c38 -23 48 -37 54 -65 11 -60 9 -59 -124 38 -29 21 -50 42 -47 48
12 18 71 8 117 -21z m26031 -38 c26 -38 47 -73 46 -78 0 -5 -23 -28 -52 -51
l-52 -42 -3 50 c-2 30 -8 49 -15 49 -7 0 -57 -14 -111 -30 -116 -35 -126 -36
-126 -12 0 32 24 52 74 65 42 11 50 17 60 47 8 24 22 40 46 52 64 32 81 26
133 -50z m-26173 17 c-12 -42 -13 -43 -55 -29 -20 7 -38 10 -40 8 -2 -2 16
-28 40 -58 24 -30 44 -58 45 -62 0 -5 -21 -8 -46 -8 -54 0 -73 17 -74 67 0 48
60 113 105 113 33 0 33 -1 25 -31z m18530 -4 c18 -22 5 -30 -19 -13 -12 8 -18
18 -14 21 10 11 19 8 33 -8z m9900 -11 c0 -8 -6 -11 -15 -8 -8 4 -15 12 -15
20 0 8 6 11 15 8 8 -4 15 -12 15 -20z m-17216 -42 c-9 -16 -24 -39 -34 -52
l-18 -24 -7 37 c-9 46 6 67 47 67 l29 0 -17 -28z m-12759 -31 c18 -13 21 -21
12 -30 -16 -16 -31 -14 -50 7 -13 14 -14 21 -5 30 15 15 16 15 43 -7z m1890
-94 c28 -23 72 -93 63 -102 -8 -8 -82 25 -105 47 -24 22 -53 75 -53 97 0 14
56 -11 95 -42z m14565 -37 c0 -16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5
30 10 30 6 0 10 -13 10 -30z m11018 -22 c-8 -13 -58 -45 -112 -73 -62 -32
-104 -60 -116 -79 l-18 -28 -7 49 c-9 67 12 119 57 138 20 9 68 14 122 15 l88
0 -14 -22z m-25421 -80 c35 -33 29 -48 -16 -48 -43 0 -71 26 -71 63 0 17 5 19
32 13 18 -3 42 -16 55 -28z m9129 21 c9 -16 -10 -31 -30 -23 -13 5 -14 9 -5
20 14 17 25 18 35 3z m-11140 -30 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33
5 0 12 -5 16 -11z m30126 -34 c3 -16 1 -25 -7 -25 -7 0 -15 11 -17 25 -3 16
-1 25 7 25 7 0 15 -11 17 -25z m-8720 -25 c-6 -44 -22 -47 -22 -6 0 23 5 36
13 36 9 0 12 -10 9 -30z m-247 4 c10 -10 14 -26 11 -43 -3 -14 -10 -77 -17
-138 -6 -62 -15 -113 -19 -113 -14 0 -89 60 -100 80 -15 28 -4 57 36 103 26
29 34 46 34 76 0 35 8 47 32 50 4 1 14 -6 23 -15z m-619 -65 c3 -6 -5 -23 -20
-37 l-26 -26 -30 29 c-16 16 -30 32 -30 37 0 12 98 10 106 -3z m4741 -45 c39
-25 57 -63 59 -128 1 -33 12 -97 23 -140 12 -44 21 -89 21 -100 0 -36 -45
-119 -92 -170 l-44 -49 -12 249 c-7 137 -15 275 -19 308 -6 58 -6 58 18 51 13
-4 34 -13 46 -21z m1993 12 l33 -13 -65 -37 c-36 -20 -68 -36 -71 -36 -12 0
-7 40 8 70 18 34 43 38 95 16z m-25213 -104 c45 -45 83 -96 112 -151 42 -77
60 -98 126 -144 29 -21 93 -142 81 -154 -16 -16 -136 59 -184 114 -68 80 -159
172 -221 224 l-55 46 29 66 c15 37 32 67 36 67 5 0 39 -31 76 -68z m25251 -24
c8 -8 -1 -25 -39 -70 -47 -53 -89 -78 -76 -44 7 18 90 126 98 126 3 0 10 -5
17 -12z m2157 -49 c50 -6 93 -14 97 -18 6 -5 -56 -218 -66 -228 -1 -1 -26 10
-56 25 -49 24 -67 27 -205 32 -169 6 -169 3 -3 65 48 18 74 33 68 39 -5 5 -42
11 -82 13 -61 4 -96 -1 -212 -31 -184 -48 -219 -46 -91 5 158 63 290 95 445
108 8 0 56 -4 105 -10z m-11496 -8 c7 -5 9 -11 4 -14 -13 -8 -53 3 -53 14 0
11 31 11 49 0z m215 -12 c33 -26 33 -34 -3 -49 -24 -10 -35 -9 -62 5 -63 32
-66 39 -23 52 54 16 58 16 88 -8z m-7684 -8 c0 -50 -23 -63 -69 -37 l-25 14
40 20 c49 26 54 27 54 3z m7533 -101 c100 -15 172 -44 264 -106 96 -64 176
-148 223 -230 53 -95 106 -160 245 -303 72 -75 159 -173 192 -217 117 -159
210 -378 268 -629 75 -329 108 -402 213 -469 55 -35 76 -76 76 -151 0 -50 -5
-70 -34 -121 -35 -63 -50 -74 -231 -169 -54 -28 -59 -28 -98 -15 -40 13 -42
12 -69 -13 -60 -58 -115 -55 -210 9 -93 62 -146 75 -332 81 l-165 6 -110 60
c-174 94 -273 156 -295 184 -22 28 -26 67 -10 107 l10 26 -84 0 -84 0 -35 83
c-72 168 -109 327 -122 522 -3 50 -12 149 -20 220 -20 181 -38 509 -39 705 -1
208 4 221 140 344 l95 86 72 0 c40 0 102 -5 140 -10z m-6747 -60 c189 -39 412
-43 536 -9 23 6 60 8 83 5 l41 -7 73 -253 c51 -176 82 -266 102 -296 84 -124
62 -257 -91 -569 -163 -329 -382 -599 -650 -797 -485 -359 -542 -399 -696
-481 -85 -45 -108 -53 -115 -42 -12 19 -11 267 1 409 9 106 14 122 55 205 24
50 47 110 50 135 4 25 29 140 56 255 27 116 53 227 58 248 l9 37 -34 0 c-44 0
-44 14 -3 117 25 62 33 99 39 179 l6 101 90 139 c49 76 105 170 125 209 30 59
44 75 89 102 29 18 58 34 63 36 6 2 7 9 2 16 -5 10 -21 11 -58 7 -43 -6 -49
-5 -43 9 30 78 38 132 27 173 -15 58 -15 59 17 76 36 20 52 20 168 -4z m17304
-5 c0 -3 -9 -23 -19 -43 l-19 -38 -116 -17 c-224 -34 -413 -95 -566 -183 -103
-59 -237 -120 -280 -127 l-35 -6 53 48 c63 58 253 159 424 226 219 85 558 170
558 140z m-7510 -35 c13 -13 20 -33 20 -60 0 -22 -2 -40 -4 -40 -5 0 -67 20
-99 32 -15 6 -32 20 -38 30 -26 49 77 82 121 38z m7890 10 c0 -16 -114 -69
-182 -85 -35 -8 -93 -15 -129 -15 -53 0 -69 4 -84 20 -10 11 -16 20 -14 21 2
0 81 15 174 34 181 35 235 41 235 25z m-16630 -112 c0 -9 -11 -30 -25 -45
l-24 -28 0 85 0 85 24 -40 c14 -22 25 -48 25 -57z m-195 -5 c15 -49 44 -145
66 -215 l40 -128 62 66 62 67 12 -59 c6 -33 8 -62 5 -65 -4 -3 -38 -32 -76
-64 -66 -57 -69 -59 -83 -40 -27 37 -83 154 -107 227 -45 132 -54 298 -17 298
5 0 22 -39 36 -87z m4179 -37 c14 -19 26 -37 26 -40 0 -3 -38 -6 -85 -6 l-85
0 17 26 c16 25 63 53 89 54 7 0 23 -15 38 -34z m-4051 -29 c18 -15 22 -68 8
-115 l-6 -24 -19 21 c-15 17 -56 136 -56 164 0 8 21 -6 73 -46z m16172 24 c-6
-4 -36 -20 -68 -34 -51 -23 -56 -24 -47 -7 16 30 52 50 89 50 20 0 31 -4 26
-9z m-25970 -30 c45 -21 69 -44 84 -82 8 -23 5 -30 -23 -58 -58 -56 -92 -37
-132 74 -13 36 -24 70 -24 75 0 16 52 11 95 -9z m25715 -41 c-19 -13 -30 -13
-30 0 0 6 10 10 23 10 18 0 19 -2 7 -10z m-11905 -54 l50 -11 -1 -113 c0 -98
-3 -116 -20 -132 -10 -10 -28 -20 -39 -22 -17 -3 -21 4 -25 47 -5 49 -5 49
-17 22 -7 -15 -13 -29 -13 -31 0 -2 18 -26 40 -52 56 -68 52 -103 -11 -119
-21 -5 -31 -1 -47 20 -24 30 -61 181 -69 282 -5 67 -4 73 20 97 29 29 53 31
132 12z m11178 -125 c5 -21 -8 -35 -103 -112 l-55 -44 40 50 c109 138 109 138
118 106z m-21987 -186 c43 -102 80 -182 81 -178 2 4 -17 80 -40 168 -36 133
-41 161 -30 168 8 5 16 7 17 5 11 -12 146 -451 141 -458 -3 -5 -20 7 -38 27
l-32 37 -5 -52 -5 -52 -62 40 -63 39 6 58 6 58 -43 15 c-43 15 -44 16 -100
145 -31 72 -56 131 -54 133 5 6 130 39 136 36 3 -2 41 -87 85 -189z m-2724 87
c-15 -5 -62 50 -54 62 3 5 19 -6 35 -24 18 -20 25 -36 19 -38z m-116 37 c9
-16 -12 -33 -31 -25 -14 5 -15 9 -5 21 15 18 26 19 36 4z m22474 -9 c0 -15
-42 -80 -51 -80 -13 0 -11 14 7 59 10 27 20 38 30 34 8 -3 14 -9 14 -13z
m-11380 -152 c0 -42 -4 -58 -14 -58 -13 0 -26 67 -26 135 l0 30 20 -25 c14
-18 20 -41 20 -82z m6183 69 c-3 -5 -9 -3 -14 4 -4 7 -5 17 -2 22 3 5 9 3 14
-4 4 -7 5 -17 2 -22z m-18869 -97 c4 -4 5 -13 2 -19 -9 -13 -66 14 -66 30 0
12 49 4 64 -11z m18312 -113 c53 -27 174 -297 174 -391 0 -24 9 -63 20 -87 16
-36 20 -66 20 -169 0 -139 -6 -158 -60 -180 -36 -15 -40 -12 -94 90 -77 145
-109 263 -166 599 -45 269 -43 282 30 202 25 -27 59 -56 76 -64z m-34 65 c-5
-24 -6 -25 -13 -7 -12 31 -10 45 5 39 8 -3 11 -15 8 -32z m1587 -90 c13 -7
-20 -23 -37 -18 -23 8 -34 29 -25 52 5 13 10 12 32 -8 14 -13 28 -24 30 -26z
m-319 -66 c-6 -5 -11 -8 -12 -5 -2 2 -14 16 -27 31 -13 15 -20 32 -16 39 6 8
16 2 36 -22 17 -21 24 -38 19 -43z m-504 9 c4 -8 3 -23 -1 -32 -7 -17 -8 -16
-15 2 -5 11 -9 26 -9 33 -1 17 18 15 25 -3z m-14637 -142 c14 -13 30 -54 51
-136 37 -146 39 -180 5 -117 -21 42 -95 244 -95 262 0 13 17 9 39 -9z m14767
-19 c12 -18 13 -33 5 -77 -12 -64 -3 -103 34 -157 44 -64 46 -141 4 -210 l-22
-35 -38 129 c-36 121 -39 138 -39 257 0 124 1 127 21 122 11 -3 27 -16 35 -29z
m809 -61 c-14 -76 -100 -132 -183 -117 -61 10 -192 75 -192 94 0 26 103 44
201 35 85 -8 139 9 162 49 12 21 12 21 15 -3 2 -13 1 -39 -3 -58z m-19045 48
c0 -11 -4 -22 -9 -25 -11 -7 -20 14 -13 32 7 19 22 14 22 -7z m-782 -180 c23
-20 29 -41 12 -41 -19 0 -99 44 -94 52 9 15 58 8 82 -11z m10972 -98 c0 -34
-12 -63 -26 -63 -7 0 -12 6 -10 13 3 6 7 24 10 40 7 30 26 37 26 10z m-10569
-39 c107 -43 105 -42 78 -70 l-22 -24 30 0 c21 0 45 12 73 35 51 42 92 51 148
31 66 -24 116 -63 268 -212 295 -290 440 -414 481 -414 13 0 13 4 -2 26 -16
25 -16 26 2 22 14 -2 18 -13 20 -47 1 -24 4 -46 7 -49 4 -3 21 -1 40 5 l33 10
137 -163 c76 -90 141 -164 146 -164 5 0 11 19 15 41 l7 41 21 -20 c15 -16 22
-38 25 -82 l4 -60 45 -12 c35 -8 50 -19 66 -46 11 -19 48 -57 83 -86 34 -28
111 -105 171 -171 60 -66 160 -168 222 -227 62 -60 111 -108 109 -108 -2 0
-39 11 -83 25 -44 14 -81 25 -82 25 -1 0 10 -28 23 -61 l25 -61 -39 -47 c-21
-25 -50 -52 -63 -58 -32 -17 -94 -16 -127 1 -54 29 -245 251 -452 526 -264
351 -496 625 -715 846 -172 172 -256 232 -488 350 -93 47 -173 96 -215 131
-37 31 -69 58 -71 59 -4 4 7 34 12 34 2 0 32 -12 68 -26z m119 -4 c0 -21 -16
-35 -24 -22 -8 13 5 53 15 46 5 -3 9 -14 9 -24z m10190 -189 c-11 -22 -78 -76
-85 -69 -2 2 17 44 43 93 l47 90 3 -47 c2 -26 -2 -56 -8 -67z m-9606 91 c2 -4
1 -14 -4 -22 -7 -11 -11 -11 -24 -1 -9 7 -13 17 -10 22 7 11 31 12 38 1z
m25666 -82 c0 -15 -31 -60 -41 -60 -14 0 -11 19 9 45 18 26 32 32 32 15z
m-25490 -25 c0 -8 -6 -15 -12 -15 -7 0 -23 -3 -35 -6 -14 -4 -23 -2 -23 5 0
12 33 29 58 30 6 1 12 -6 12 -14z m25184 -11 c14 -5 13 -9 -4 -34 -31 -44
-105 -87 -196 -115 -46 -14 -136 -53 -200 -86 -116 -61 -174 -84 -174 -70 0
17 60 61 127 94 40 20 92 49 115 65 64 43 239 134 273 143 42 10 41 10 59 3z
m-21984 -34 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-1370 -29 c13 -20 17 -32 10 -36 -6 -4 -13 -5 -14 -4 -17 23 -36 55
-36 61 0 17 20 6 40 -21z m23800 -21 c0 -20 -69 -105 -77 -96 -9 9 9 48 39 82
35 40 38 41 38 14z m-16336 -36 c-19 -46 -37 -63 -269 -257 -99 -83 -184 -157
-189 -165 -21 -35 -81 -93 -124 -121 -43 -27 -82 -44 -251 -111 -72 -28 -55
-7 49 63 266 178 505 365 674 527 64 62 119 110 122 108 2 -3 -3 -22 -12 -44z
m8458 -14 c2 -50 0 -60 -14 -64 -16 -4 -18 4 -18 60 0 48 3 64 14 64 10 0 15
-17 18 -60z m3253 -20 c-10 -11 -22 -17 -28 -13 -6 4 -3 12 8 20 28 20 41 16
20 -7z m1884 -173 c6 -35 11 -69 11 -75 0 -13 -31 -15 -80 -6 -31 7 -32 8 -26
48 4 29 15 49 38 69 18 15 35 27 39 27 4 0 12 -28 18 -63z m-22765 12 c-3 -5
-16 -2 -29 7 -14 9 -22 20 -19 25 3 5 16 2 29 -7 14 -9 22 -20 19 -25z m317
-32 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m24079 -53 c0 -11
-5 -14 -16 -10 -9 3 -18 6 -20 6 -3 0 -2 5 1 10 10 16 35 12 35 -6z m-5864
-72 c-14 -14 -16 -14 -16 -1 0 20 23 44 29 28 2 -6 -4 -18 -13 -27z m-18236
-2 c0 -20 -5 -30 -15 -30 -10 0 -15 10 -15 30 0 20 5 30 15 30 10 0 15 -10 15
-30z m23956 -13 c-3 -13 -15 -36 -27 -50 -23 -31 -108 -92 -115 -84 -12 12 29
68 83 115 32 28 59 49 61 47 3 -2 2 -15 -2 -28z m674 3 c0 -13 -92 -140 -101
-140 -25 0 -14 28 31 85 49 61 70 78 70 55z m-7940 -88 c0 -37 -18 -64 -57
-86 -11 -6 -73 18 -73 29 0 7 112 95 121 95 5 0 9 -17 9 -38z m8000 -47 c0
-10 -11 -15 -35 -15 -24 0 -35 5 -35 15 0 10 11 15 35 15 24 0 35 -5 35 -15z
m-14082 -49 c5 -43 -18 -136 -34 -136 -11 0 -14 19 -14 90 0 88 0 89 23 81 14
-5 23 -18 25 -35z m-10298 -29 c0 -6 -9 -2 -20 8 -11 10 -17 22 -13 28 6 10
33 -19 33 -36z m20383 -346 c21 -44 23 -58 15 -83 -13 -37 -33 -45 -58 -23
-48 43 -87 -27 -57 -103 16 -40 16 -40 -17 -95 -34 -57 -185 -257 -194 -257
-3 0 -2 55 1 123 6 134 19 181 82 307 26 51 62 100 114 153 70 73 76 77 82 55
4 -13 18 -47 32 -77z m-18212 32 c13 -15 11 -16 -11 -10 -14 4 -29 7 -33 7 -5
0 -5 5 -2 10 9 15 31 12 46 -7z m-1798 -30 c15 -20 24 -39 22 -41 -10 -10 -53
29 -57 52 -7 36 4 32 35 -11z m1061 -15 c3 -17 2 -36 -4 -42 -13 -13 -20 -5
-27 38 -7 48 21 52 31 4z m1005 -72 c151 -57 196 -78 202 -95 5 -11 5 -21 0
-21 -23 0 -233 76 -291 105 -67 34 -161 102 -125 91 11 -3 107 -39 214 -80z
m-1186 7 c-2 -21 -6 -49 -9 -63 -5 -24 -6 -24 -20 -6 -14 20 -19 87 -7 99 3 4
14 7 23 7 12 0 15 -8 13 -37z m-773 -57 c0 -19 -7 -23 -54 -31 l-38 -8 6 32
c3 17 9 45 12 63 l6 32 34 -37 c19 -20 34 -43 34 -51z m17364 52 c-3 -12 -13
-37 -21 -57 l-15 -35 -15 29 c-15 28 -15 30 18 57 40 34 42 34 33 6z m-12461
-95 c4 -3 2 -12 -4 -20 -9 -10 -15 -10 -25 -2 -8 6 -11 15 -8 20 6 11 28 12
37 2z m3 -145 c27 -30 54 -75 54 -88 0 -28 -32 -3 -63 50 -36 60 -31 83 9 38z
m4477 -60 c-10 -21 -12 -21 -18 -5 -10 25 2 54 17 39 8 -8 8 -18 1 -34z
m-11853 -63 c0 -28 -4 -35 -19 -35 -19 0 -20 11 -5 58 8 26 24 10 24 -23z
m2801 -114 c36 -36 37 -60 4 -91 -22 -21 -35 -24 -87 -23 -34 1 -74 5 -89 8
l-27 6 20 47 c31 72 44 82 100 82 41 0 54 -5 79 -29z m16918 -32 c1 -24 -36
-85 -45 -76 -4 4 13 100 23 127 4 12 7 11 13 -5 5 -11 9 -32 9 -46z m-8059
-78 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m10438 -29 c2 -7
-3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m2312 8 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-4640 -240 c17 -32
0 -47 -21 -19 -23 29 -24 39 -4 39 8 0 19 -9 25 -20z m-2110 -40 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-16305 -51 c6
-19 0 -38 -9 -28 -10 12 -16 49 -7 49 5 0 13 -9 16 -21z m16215 -115 c27 -11
40 -22 40 -35 0 -31 -29 -34 -76 -8 -48 26 -50 29 -32 47 15 15 20 15 68 -4z
m-16380 -52 c0 -13 -36 -43 -43 -36 -10 10 12 44 28 44 8 0 15 -4 15 -8z
m-110 -119 c0 -31 -25 -84 -50 -108 -36 -35 -46 -28 -30 20 17 52 80 121 80
88z m-2370 -98 c0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0 9 9 15 25 15 16 0
25 -6 25 -15z m380 -14 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9
-9z m1890 -56 c0 -8 -9 -24 -20 -35 -39 -39 -57 -14 -18 27 25 27 38 29 38 8z
m24290 -61 c0 -11 -5 -14 -16 -10 -9 3 -22 6 -30 6 -8 0 -14 5 -14 10 0 6 14
10 30 10 21 0 30 -5 30 -16z m-26359 -57 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8
6 -3 11 -8 11 -12z m273 -53 c9 -8 16 -24 16 -34 0 -18 -2 -18 -45 -3 -48 16
-55 26 -27 42 24 15 38 13 56 -5z m25966 -49 c0 -10 -10 -15 -34 -15 -61 0
-55 27 7 29 18 1 27 -4 27 -14z m808 -21 c12 -4 22 -10 22 -15 0 -14 -34 -10
-56 6 -21 16 -8 20 34 9z m-24782 -62 c-20 -39 -44 -64 -108 -111 -46 -33 -84
-56 -86 -51 -8 22 39 99 79 132 46 38 131 90 137 84 2 -2 -8 -27 -22 -54z
m24489 26 c22 -11 61 -38 88 -60 26 -22 111 -84 189 -139 l142 -99 21 26 c21
27 23 27 106 20 161 -13 266 -50 357 -123 l43 -35 -22 -24 c-28 -30 -66 -31
-145 -4 -32 12 -70 19 -84 18 -22 -3 -15 -11 58 -67 l83 -64 -29 -31 c-261
-285 -396 -415 -570 -549 -87 -68 -417 -289 -475 -319 -54 -27 -120 -32 -202
-14 -38 8 -71 13 -73 12 -1 -2 20 -18 48 -36 27 -18 50 -35 50 -39 0 -23 -86
-71 -127 -71 -46 0 -59 14 -39 41 22 32 20 61 -6 94 l-21 27 41 -7 41 -7 -35
37 c-49 52 -45 78 24 163 l34 42 -63 0 c-60 1 -67 3 -108 39 -52 47 -70 39
191 82 l197 32 7 44 c10 66 -1 131 -25 157 -11 12 -58 42 -103 66 -139 73
-251 146 -297 194 l-44 45 92 60 c115 75 152 87 200 63 20 -9 44 -32 55 -51
l20 -34 -57 6 c-31 3 -57 2 -57 -3 0 -4 17 -22 37 -40 l36 -32 19 36 c33 65
109 99 163 73 l33 -16 63 80 c35 43 69 79 76 79 14 0 28 98 18 131 -11 36 -35
58 -88 86 -27 13 -47 29 -45 34 2 6 21 33 43 59 36 44 43 49 70 43 17 -3 48
-14 70 -25z m-626 -44 c-9 -11 -15 -12 -25 -3 -11 9 -12 15 -3 25 9 11 15 12
25 3 11 -9 12 -15 3 -25z m-23861 -51 c5 -10 7 -33 5 -50 -5 -30 -9 -33 -39
-33 -19 0 -34 2 -34 5 0 12 45 95 52 95 4 0 11 -8 16 -17z m24771 -13 c8 -24
7 -30 -5 -30 -7 0 -14 3 -14 8 0 4 -3 17 -6 30 -9 33 12 27 25 -8z m-1998 -20
l24 -19 -55 0 -55 0 24 19 c13 11 27 20 31 20 4 0 18 -9 31 -20z m2239 -36 c0
-8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-2770
-3 c0 -9 -24 -21 -41 -21 -16 0 -10 18 9 23 28 8 32 7 32 -2z m-19470 -16 c0
-17 -4 -18 -49 -9 -44 9 -39 24 9 24 29 0 40 -4 40 -15z m300 -25 c0 -5 -21
-10 -46 -10 -27 0 -43 4 -39 10 3 6 24 10 46 10 21 0 39 -4 39 -10z m-3335
-47 c-81 -72 -187 -153 -201 -153 -47 0 93 140 168 169 74 28 79 25 33 -16z
m3528 22 c44 -8 50 -25 8 -25 -51 0 -91 12 -91 27 0 11 7 14 23 10 12 -3 39
-9 60 -12z m6482 -75 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10
2 0 7 -4 10 -10z m-10030 -40 c-10 -11 -24 -20 -30 -20 -7 0 -15 9 -18 20 -4
17 0 20 30 20 35 0 36 0 18 -20z m-117 -95 c-3 -3 -9 2 -12 12 -6 14 -5 15 5
6 7 -7 10 -15 7 -18z m22282 -235 c0 -6 -30 -9 -72 -9 -63 2 -67 3 -33 9 62
11 105 11 105 0z m-1250 -100 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m-13191 -115 c19 -7 -12 -30 -68 -50 -34 -12 -41
-12 -51 0 -13 16 -30 81 -23 88 4 4 99 -22 142 -38z m13883 32 c40 -18 22 -41
-24 -32 -29 5 -35 11 -31 26 6 22 17 23 55 6z m198 5 c0 -5 -9 -17 -20 -27
-16 -15 -26 -17 -47 -9 -35 12 -31 28 9 35 50 10 58 10 58 1z m-1214 -17 c-37
-28 -131 -57 -201 -62 l-70 -5 25 21 c36 28 142 54 245 59 20 1 20 1 1 -13z
m6934 -119 c0 -34 -101 -197 -166 -266 -48 -52 -121 -106 -130 -97 -7 7 30 79
79 152 61 91 217 243 217 211z m-22073 -53 c-22 -42 -47 -61 -97 -72 -79 -18
-77 -12 15 48 50 32 91 57 93 56 2 -2 -3 -16 -11 -32z m18548 -3 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-524 -79 c24 -24
29 -38 29 -76 0 -25 -4 -44 -8 -41 -4 3 -29 31 -55 64 -35 44 -43 62 -34 71
18 18 36 13 68 -18z m-3211 5 c0 -8 -19 -18 -44 -25 -56 -14 -56 -14 -56 3 0
8 17 18 38 24 53 14 62 14 62 -2z m-18400 -15 c0 -11 -25 -21 -51 -21 -24 0
-25 17 -1 23 30 8 52 7 52 -2z m5926 -112 c53 -67 58 -71 90 -66 19 3 37 1 42
-6 5 -7 49 -90 98 -186 82 -161 89 -178 89 -233 0 -62 -10 -78 -52 -78 -32 0
-75 41 -143 135 -73 101 -117 193 -130 268 -5 30 -20 77 -33 103 -28 56 -48
120 -39 128 12 13 23 3 78 -65z m16242 50 c56 -7 102 -16 102 -21 0 -5 -12
-26 -26 -48 -18 -27 -32 -39 -43 -35 -9 2 -43 13 -76 23 -51 15 -63 23 -77 52
-22 45 -22 52 0 46 9 -2 63 -10 120 -17z m-25552 -96 c20 -27 16 -38 -6 -18
-20 18 -26 35 -14 35 4 0 13 -8 20 -17z m25464 -3 c6 -11 7 -20 2 -20 -10 0
-32 21 -32 32 0 16 19 8 30 -12z m-80 -69 c-14 -28 -27 -34 -47 -22 -15 8 -12
11 17 24 19 9 36 16 38 16 1 1 -2 -8 -8 -18z m-6742 -89 c27 -35 14 -71 -50
-138 -34 -35 -63 -63 -64 -61 -2 2 -10 31 -18 66 -14 62 -14 62 8 71 38 14 56
39 56 77 l0 35 28 -17 c15 -10 33 -24 40 -33z m-758 28 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-6706 -77 c-19 -14 -34
-9 -34 12 0 18 22 25 38 12 11 -9 11 -13 -4 -24z m6515 -38 c37 -43 45 -72 38
-136 l-6 -50 53 -23 c29 -13 72 -37 96 -55 62 -45 98 -42 145 10 28 31 40 38
48 30 12 -12 15 -136 4 -147 -3 -3 -14 -1 -25 5 -16 8 -21 7 -26 -5 -13 -34
-7 -44 20 -33 15 5 29 8 31 5 21 -21 31 -223 13 -270 -9 -23 -15 -26 -62 -26
-51 0 -129 -27 -142 -48 -3 -6 -2 -22 4 -36 9 -24 14 -26 69 -26 67 0 60 11
81 -125 6 -38 13 -80 15 -91 4 -20 -5 -24 -122 -53 l-126 -32 -37 36 c-19 19
-38 35 -41 35 -3 0 -14 -27 -24 -60 -10 -33 -22 -60 -26 -60 -8 0 -26 189 -21
225 2 20 45 65 89 95 l23 15 -21 68 c-21 65 -21 68 -3 105 29 61 29 136 1 248
-14 54 -39 162 -56 239 -16 77 -32 152 -36 168 -10 37 6 34 44 -8z m10944 -7
c-22 -24 -39 -8 -23 22 9 17 14 19 26 9 13 -10 12 -14 -3 -31z m-10673 -224
c0 -21 -24 -21 -41 0 -13 15 -14 25 -7 34 9 10 15 9 29 -4 11 -10 19 -23 19
-30z m-370 -44 c0 -16 -4 -30 -9 -30 -5 0 -11 14 -13 30 -3 20 0 30 9 30 8 0
13 -12 13 -30z m-17136 -272 c-3 -5 -14 -2 -25 8 -10 9 -16 21 -13 26 3 5 14
2 25 -8 10 -9 16 -21 13 -26z m21711 32 c4 -6 -8 -10 -29 -10 -20 0 -36 5 -36
10 0 6 13 10 29 10 17 0 33 -4 36 -10z m-4488 -257 c2 -6 -3 -13 -12 -16 -10
-5 -15 0 -15 14 0 21 21 22 27 2z m-6638 -76 c-7 -15 -19 -27 -26 -27 -8 0
-13 3 -11 8 25 51 33 61 40 54 5 -5 4 -20 -3 -35z m131 -79 c-1 -7 -9 -29 -19
-48 l-19 -35 -7 37 c-4 20 -4 41 0 47 9 15 45 14 45 -1z m-10360 -73 c30 -30
51 -55 47 -55 -30 0 -117 75 -117 101 0 19 17 8 70 -46z m22880 -35 c0 -9 -43
-23 -114 -36 -62 -11 -40 19 25 32 70 15 89 16 89 4z m-1415 -54 c19 -8 35
-17 35 -20 0 -3 -13 -12 -29 -20 -26 -14 -29 -21 -35 -88 -5 -50 -4 -74 3 -76
26 -9 41 -36 41 -74 l0 -41 158 8 c86 4 198 10 247 14 l90 6 -33 18 c-18 10
-72 27 -119 37 -107 23 -120 34 -67 56 38 16 41 16 110 -8 69 -25 72 -25 95
-7 13 10 36 21 52 24 33 8 177 -33 184 -52 10 -25 -18 -43 -66 -43 -45 0 -47
-1 -79 -54 -72 -121 -134 -143 -232 -81 -63 40 -78 42 -113 15 -24 -19 -31
-19 -129 -9 -70 7 -120 18 -157 35 l-54 24 -41 -19 c-31 -15 -68 -20 -154 -23
-121 -3 -125 -2 -98 50 29 54 97 102 190 132 l40 13 -63 19 c-47 14 -61 23
-59 36 2 9 45 45 96 79 99 67 126 74 187 49z m-16337 -16 c41 -16 77 -20 165
-20 115 0 157 -6 157 -21 0 -14 -65 -28 -250 -54 -153 -21 -177 -23 -196 -10
-21 14 -70 83 -63 89 56 42 106 47 187 16z m-203 -1 c11 -17 -1 -21 -36 -14
-23 5 -28 10 -18 16 19 12 46 11 54 -2z m12255 -34 c9 -11 10 -46 3 -147 -5
-73 -8 -135 -6 -137 2 -3 21 13 43 34 22 21 43 36 46 32 4 -4 -10 -71 -32
-150 l-39 -142 -36 -3 c-25 -2 -42 3 -54 15 -16 16 -17 32 -11 183 3 91 9 202
13 248 6 81 6 82 33 82 15 0 33 -7 40 -15z m4630 -11 c0 -12 -6 -15 -22 -10
-13 3 -31 6 -40 6 -10 0 -18 5 -18 10 0 6 18 10 40 10 30 0 40 -4 40 -16z
m-4822 -30 c23 -15 30 -56 13 -82 -9 -16 -14 -10 -55 61 l-28 49 23 -7 c13 -4
34 -13 47 -21z m5065 12 c99 -15 114 -22 65 -31 -43 -8 -180 16 -186 33 -5 15
11 15 121 -2z m-16301 -68 c106 -40 172 -85 292 -198 61 -58 123 -115 136
-127 l25 -22 0 42 c0 36 4 43 27 51 22 8 32 6 47 -8 38 -35 78 -195 54 -219
-3 -4 -21 15 -39 42 -30 44 -73 85 -81 78 -1 -2 57 -131 131 -288 73 -156 138
-299 144 -318 7 -19 12 -63 12 -97 0 -51 -3 -64 -15 -64 -26 0 -109 46 -202
112 -48 34 -362 246 -698 470 -379 254 -615 418 -623 434 -12 23 -11 25 22 39
60 25 176 54 321 79 188 33 348 31 447 -6z m14958 -41 c0 -23 -2 -22 -19 10
-10 19 -9 22 4 17 8 -4 15 -15 15 -27z m-3850 -6 c0 -42 -13 -111 -21 -111 -5
0 -9 29 -9 65 0 51 3 65 15 65 8 0 15 -8 15 -19z m3648 -6 c10 -21 8 -27 -15
-45 -32 -25 -48 -26 -38 -2 4 9 12 29 17 45 12 33 22 34 36 2z m1072 15 c0 -5
-14 -10 -31 -10 -17 0 -28 4 -24 10 3 6 17 10 31 10 13 0 24 -4 24 -10z
m-21330 -30 c11 -7 12 -12 4 -20 -15 -15 -64 -2 -64 16 0 16 37 19 60 4z
m5915 -19 c-15 -14 -55 -23 -55 -13 0 15 12 22 39 22 15 0 21 -4 16 -9z
m10860 -81 c3 -6 -3 -23 -14 -39 l-21 -29 20 -43 c27 -60 25 -98 -10 -186 -22
-55 -32 -100 -37 -164 l-6 -89 63 0 63 0 -7 -99 c-8 -112 3 -136 29 -67 11 27
22 43 30 40 12 -5 35 -46 35 -64 0 -5 -34 -35 -76 -67 -82 -61 -81 -58 -59
-144 6 -23 2 -27 -31 -40 l-37 -14 2 -75 c2 -72 18 -131 39 -144 5 -3 21 4 36
15 29 23 34 58 11 92 -14 22 -13 25 25 62 22 22 40 42 40 45 0 4 -16 14 -35
23 -19 9 -35 21 -35 27 0 15 38 50 54 50 8 0 26 14 40 31 20 24 26 41 26 80 0
43 3 49 20 49 35 0 79 -29 86 -55 3 -14 3 -50 -1 -80 -6 -48 -4 -55 14 -67 12
-7 21 -23 21 -38 0 -26 -61 -133 -128 -224 -38 -53 -41 -70 -27 -173 l8 -53
-40 0 -39 0 -12 -113 c-21 -198 -87 -381 -187 -515 -38 -50 -50 -60 -61 -51
-8 6 -14 18 -14 25 0 17 -24 18 -51 4 -17 -9 -19 -7 -19 17 0 53 21 198 35
249 17 58 55 98 133 139 80 42 80 43 94 150 l12 98 -40 33 c-21 19 -43 32 -48
31 -23 -8 -30 -37 -19 -79 11 -41 10 -45 -23 -95 -36 -56 -48 -63 -58 -37 -11
27 -7 67 14 139 25 87 26 130 4 121 -9 -3 -34 -9 -56 -13 l-41 -6 7 93 c4 51
14 129 23 174 16 80 20 223 12 417 l-4 97 53 103 c60 121 72 172 72 319 l0
108 48 20 c56 24 59 25 67 12z m-189 -257 c-5 -62 -13 -113 -17 -113 -13 0
-22 68 -16 120 5 42 34 119 41 108 1 -1 -2 -54 -8 -115z m-12178 6 c9 -1 4 -5
-13 -8 -34 -8 -48 -4 -39 10 3 6 13 8 21 5 8 -3 22 -7 31 -7z m122 -98 c0 -22
-9 -32 -41 -50 -22 -13 -43 -21 -45 -18 -12 12 -16 115 -5 136 l12 22 40 -31
c28 -23 39 -39 39 -59z m16813 64 c10 -4 17 -14 15 -22 -5 -25 -274 -179 -422
-242 -201 -85 -411 -161 -446 -161 -57 0 -64 74 -16 170 72 143 190 216 411
255 84 15 422 15 458 0z m-4368 -37 c44 -191 45 -235 5 -273 l-30 -29 -28 20
c-15 11 -41 47 -58 80 -52 97 -52 97 7 124 54 26 55 24 -16 51 -30 12 -30 12
35 29 84 23 79 23 85 -2z m-12357 -77 c24 -5 45 -11 48 -14 2 -2 -11 -12 -30
-22 -34 -19 -35 -19 -56 0 -12 11 -26 28 -32 38 -8 16 -7 18 9 13 10 -3 37
-10 61 -15z m-173 -133 c25 -11 34 -28 15 -28 -16 0 -60 23 -60 32 0 11 14 10
45 -4z m205 -26 c-40 -4 -51 5 -26 24 17 12 22 12 39 -4 19 -17 19 -17 -13
-20z m302 -58 c81 -53 145 -100 142 -105 -7 -12 -87 -11 -121 2 -15 6 -55 12
-88 13 -33 2 -90 13 -128 25 l-67 23 0 53 c0 49 3 56 28 69 54 30 79 21 234
-80z m-289 -36 c23 -8 27 -15 23 -32 -3 -11 -6 -24 -6 -28 0 -17 -32 -7 -55
17 -30 29 -35 71 -7 60 9 -4 30 -12 45 -17z m-2259 -119 c-9 -46 -17 -86 -19
-87 -5 -5 -45 75 -45 90 0 12 71 90 77 84 2 -1 -4 -41 -13 -87z m-124 -16 l0
-88 -21 33 c-25 38 -30 39 -55 11 -21 -24 -112 -79 -130 -79 -18 0 -23 61 -9
97 9 21 28 39 57 54 40 20 133 57 151 58 4 1 7 -38 7 -86z m2488 34 c28 -26
29 -57 1 -57 -24 0 -79 51 -79 74 0 22 46 11 78 -17z m191 2 c13 -17 21 -32
19 -33 -10 -7 -78 -23 -78 -19 0 16 24 83 30 83 3 0 16 -14 29 -31z m-2959
-125 l0 -65 -30 21 c-17 12 -35 31 -41 43 -10 18 -8 24 15 44 46 39 56 31 56
-43z m5213 51 c35 -14 47 -31 47 -65 0 -25 -30 -41 -52 -29 -16 9 -38 65 -38
97 0 15 0 15 43 -3z m-45 -217 c68 -94 68 -91 -27 -119 -42 -12 -44 -12 -66
13 -23 27 -52 146 -41 173 3 9 18 15 39 15 32 0 40 -7 95 -82z m238 -102 c138
-280 184 -488 129 -573 -15 -23 -21 -25 -62 -19 -74 10 -77 15 -38 71 44 61
44 90 1 152 l-34 49 25 42 25 41 -34 15 c-18 8 -46 19 -61 25 -37 15 -43 35
-22 81 16 36 16 41 0 92 -10 29 -33 83 -51 121 -19 37 -34 69 -34 71 0 2 19
-1 41 -7 41 -10 42 -11 115 -161z m-6664 -200 c32 -24 -20 -48 -67 -30 -14 6
-14 8 1 25 20 22 42 24 66 5z m6411 -38 c3 -24 2 -47 -3 -52 -16 -16 -30 6
-30 50 0 59 25 61 33 2z m-5763 -110 c0 -6 -7 -5 -15 2 -8 7 -15 17 -15 22 0
6 7 5 15 -2 8 -7 15 -17 15 -22z m24314 -39 c-3 -5 -10 -7 -15 -3 -5 3 -7 10
-3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m-9218 -134 c-7 -115 -37 -240 -79
-324 l-32 -66 -3 52 c-4 67 8 129 61 325 35 133 43 154 50 130 5 -15 6 -67 3
-117z m-9210 124 c3 -6 0 -15 -7 -20 -14 -8 -32 9 -23 23 7 11 22 10 30 -3z
m11560 -85 c-3 -9 -6 -20 -6 -25 0 -5 -4 -9 -10 -9 -5 0 -10 11 -10 25 0 16 6
25 16 25 11 0 14 -5 10 -16z m2314 3 c0 -8 -112 -99 -116 -95 -8 8 38 59 70
79 29 17 46 23 46 16z m4530 -47 c0 -43 -18 -88 -44 -109 -14 -12 -18 -8 -31
22 -8 20 -15 42 -15 48 0 13 67 79 81 79 5 0 9 -18 9 -40z m-8700 5 c0 -8 -7
-15 -15 -15 -9 0 -12 6 -9 15 4 8 10 15 15 15 5 0 9 -7 9 -15z m8590 -239 c0
-2 -22 -18 -50 -35 -41 -25 -50 -28 -50 -14 0 28 31 53 67 53 18 0 33 -2 33
-4z m-6885 -220 c-10 -25 -21 -46 -26 -46 -19 0 -18 22 3 64 29 60 47 45 23
-18z m-17417 -7 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0
15 -5 13 -11z m15098 -74 c-3 -9 -11 -13 -16 -10 -8 5 -7 11 1 21 14 18 24 11
15 -11z m-8483 -186 c44 -12 77 -57 77 -106 l0 -32 -52 6 c-29 3 -78 8 -108
12 l-55 6 1 40 c1 29 9 45 28 62 30 25 52 28 109 12z m49 -203 c43 -23 63 -39
63 -52 0 -12 -7 -19 -17 -18 -10 0 -67 1 -128 2 -118 2 -122 4 -99 56 25 54
92 59 181 12z m11795 -103 c2 -6 -5 -14 -17 -18 -26 -8 -33 -3 -24 19 7 19 35
18 41 -1z m-12065 -35 c-18 -18 -33 1 -21 24 11 20 12 20 21 4 7 -12 7 -21 0
-28z m2398 -18 c11 0 34 -12 50 -27 27 -24 36 -47 66 -163 19 -74 39 -139 44
-143 4 -5 18 1 30 13 23 23 23 36 4 138 -4 20 -2 32 5 32 18 0 57 -90 81 -190
13 -52 40 -138 60 -190 21 -52 51 -133 68 -180 17 -47 34 -94 38 -105 l7 -20
-23 20 c-48 42 -107 135 -139 221 -29 77 -120 248 -128 240 -6 -5 130 -506
162 -600 19 -55 33 -102 30 -104 -3 -3 -27 17 -53 44 -38 39 -56 72 -91 162
-24 61 -45 112 -47 112 -2 0 -8 -19 -15 -42 l-11 -43 -15 28 c-20 40 -14 72
15 71 26 -1 26 -3 7 61 -25 84 -24 82 -50 50 -13 -17 -25 -28 -27 -25 -4 4
-47 194 -63 277 -4 21 0 45 11 68 16 34 16 37 -2 56 -14 15 -20 44 -25 105 -4
56 -12 91 -23 102 -9 11 -13 27 -9 41 5 20 7 21 15 7 5 -9 18 -16 28 -16z
m-8151 -113 c15 -12 25 -29 23 -36 -3 -9 -13 -4 -33 15 -46 44 -37 61 10 21z
m17671 -17 c13 -9 11 -13 -9 -31 -27 -22 -66 -22 -66 1 0 8 11 20 25 27 30 15
31 15 50 3z m-356 -52 c3 -13 7 -45 9 -71 6 -58 -12 -71 -47 -38 l-23 21 -57
-25 c-31 -14 -60 -25 -65 -25 -4 0 -3 30 3 68 l11 67 70 11 c39 7 76 12 82 13
7 0 15 -9 17 -21z m-16584 -423 c20 -24 6 -28 -15 -5 -10 11 -13 20 -8 20 6 0
16 -7 23 -15z m7394 -91 c12 -48 -14 -44 -29 4 -4 16 -2 22 8 22 8 0 18 -12
21 -26z m-7127 -118 c75 -38 133 -109 133 -162 0 -22 -33 -17 -76 12 -29 19
-194 181 -194 190 0 8 101 -21 137 -40z m7327 -88 c-9 -14 -24 2 -24 25 1 21
1 21 15 3 8 -10 12 -23 9 -28z m-6930 -171 c28 -13 93 -58 143 -100 52 -42
107 -79 125 -82 34 -8 122 -90 148 -139 12 -24 12 -28 0 -36 -8 -6 -31 -10
-50 -10 -29 0 -56 15 -144 80 -103 77 -254 160 -289 160 -8 0 -18 9 -22 20
-11 35 -63 69 -115 76 -46 6 -50 9 -50 33 0 14 -3 36 -6 47 l-6 21 108 -23
c59 -13 130 -35 158 -47z m6996 -4 c17 -36 29 -68 27 -70 -3 -2 -14 4 -26 14
-14 12 -24 36 -30 71 -4 28 -6 52 -5 52 2 0 17 -30 34 -67z m290 26 c0 -6 -4
-7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-7495 -84
l30 -25 -27 0 c-17 0 -31 7 -38 19 -20 39 -8 41 35 6z m7395 -75 c22 -22 25
-36 9 -46 -11 -6 -69 42 -69 58 0 16 40 8 60 -12z m0 -176 c-7 -7 -21 -14 -32
-14 -18 0 -17 2 4 30 18 25 23 28 31 15 5 -10 4 -21 -3 -31z m254 -21 c19 -54
19 -63 6 -63 -14 0 -37 40 -45 78 -11 52 20 41 39 -15z m-6836 -64 c19 -7 22
-16 22 -59 0 -59 -11 -63 -63 -22 -37 30 -87 85 -87 97 0 8 95 -4 128 -16z
m5030 -20 c34 -11 62 -23 62 -26 0 -2 -5 -13 -10 -24 -16 -28 -80 -26 -107 4
-25 27 -36 67 -19 67 7 0 40 -9 74 -21z m1682 -67 c17 -13 23 -34 31 -97 7
-60 21 -105 56 -180 55 -121 134 -341 126 -349 -11 -11 -70 -6 -81 7 -5 6 -18
50 -27 95 -25 123 -49 185 -116 300 l-60 103 7 70 c7 76 20 86 64 51z m-6556
-49 c3 -10 8 -35 12 -56 6 -37 6 -37 -21 -27 -14 5 -35 15 -46 20 -15 9 -27 5
-56 -17 -29 -22 -35 -24 -30 -10 4 10 7 39 7 63 l0 44 64 0 c53 0 66 -3 70
-17z m4957 -9 c22 -25 22 -26 3 -16 -12 6 -24 18 -28 27 -8 21 -3 19 25 -11z
m138 -60 c6 -6 -31 -33 -46 -34 -11 0 -43 37 -43 51 0 7 81 -8 89 -17z m-4982
-26 c38 -43 37 -48 -9 -33 -79 28 -75 25 -54 41 27 19 39 18 63 -8z m103 -78
c17 -17 28 -50 17 -50 -2 0 -18 16 -37 35 -25 26 -30 35 -17 35 9 0 26 -9 37
-20z m4924 4 c15 -6 96 -105 96 -117 0 -13 -110 -7 -143 7 -50 21 -72 51 -59
80 13 28 69 44 106 30z m-4140 -13 c17 -6 9 -16 -52 -64 -39 -31 -73 -57 -76
-57 -8 0 22 100 34 116 13 15 62 18 94 5z m10514 -138 c-24 -24 -29 -25 -57
-13 -43 17 -39 28 12 33 23 2 49 4 57 5 9 1 5 -8 -12 -25z m-10842 -56 c2 -2
-4 -40 -14 -86 l-17 -83 -30 6 c-16 4 -39 15 -49 26 -18 18 -18 23 -7 77 7 32
16 62 18 67 5 7 91 1 99 -7z m344 -112 c0 -17 -2 -18 -24 -9 -23 9 -20 24 4
24 11 0 20 -7 20 -15z m10150 -36 c0 -18 -42 -109 -51 -109 -20 0 -18 23 6 70
23 46 45 65 45 39z m-5717 -39 c3 -11 1 -22 -3 -25 -12 -7 -33 32 -25 45 9 14
21 5 28 -20z m991 -69 c48 -48 48 -49 36 -86 -7 -20 -13 -35 -15 -33 -13 14
-83 168 -77 168 4 0 29 -22 56 -49z m909 -49 c18 -16 23 -32 8 -32 -16 0 -61
42 -55 51 6 10 19 5 47 -19z m3688 -125 c-16 -16 -20 -5 -8 23 9 22 11 23 14
6 3 -10 0 -23 -6 -29z m-4541 -22 l24 -40 -24 16 c-15 10 -26 28 -28 45 -4 33
-4 33 28 -21z m4890 -120 c26 -64 19 -82 -23 -66 -26 10 -57 56 -57 85 0 16
39 48 50 41 4 -3 18 -30 30 -60z m-4823 -106 c51 -32 65 -68 58 -147 -7 -65
-6 -68 28 -117 69 -97 86 -138 97 -235 11 -96 19 -120 53 -158 17 -18 21 -37
22 -90 1 -93 17 -135 60 -155 29 -14 37 -25 51 -74 9 -32 34 -93 55 -135 25
-50 39 -91 39 -117 0 -21 11 -69 25 -105 25 -66 34 -156 16 -156 -35 0 -160
260 -259 540 -123 347 -220 649 -257 803 -46 186 -46 182 12 146z m4392 -46
c-18 -21 -40 5 -32 36 l6 24 19 -23 c14 -18 16 -26 7 -37z m-4050 -56 c-64
-49 -79 -54 -79 -23 0 17 62 46 74 35 5 -5 8 -10 5 -12z m-954 -167 c69 -127
83 -162 50 -129 -23 24 -153 253 -148 262 11 18 29 -5 98 -133z m-2059 -97
c28 -30 19 -43 -28 -43 -32 0 -84 41 -74 58 14 23 75 14 102 -15z m6977 -66
c7 -19 -17 -68 -46 -96 l-26 -24 -6 26 c-13 50 61 139 78 94z m-4813 -37 c19
-38 15 -55 -8 -27 -12 15 -27 67 -18 67 3 0 15 -18 26 -40z m990 -70 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1222 -12
c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m1276 -115 c4 -27 9
-61 12 -77 8 -37 0 -32 -76 54 l-62 70 60 0 60 0 6 -47z m116 -108 c20 -32 44
-124 35 -133 -4 -4 -61 112 -87 178 -9 24 -9 24 12 5 11 -10 29 -33 40 -50z
m3550 -64 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m370 -32 c0
-10 -4 -19 -10 -19 -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z
m-3980 -148 c17 -33 11 -47 -10 -21 -11 14 -20 28 -20 33 0 14 20 7 30 -12z
m904 -250 c7 -10 -49 -33 -58 -24 -12 12 -6 22 17 26 12 2 25 5 29 6 4 0 9 -3
12 -8z m-551 -96 c-17 -35 -17 -35 -29 -13 -14 27 -2 48 27 48 18 0 18 -2 2
-35z m-383 -165 c11 -11 20 -24 20 -29 0 -5 3 -16 6 -25 9 -24 -14 -19 -41 9
-25 24 -34 65 -15 65 6 0 19 -9 30 -20z m1028 -80 c-3 -52 -5 -55 -30 -58 -25
-3 -31 4 -63 67 -19 39 -35 75 -35 80 0 5 29 0 65 -12 l66 -21 -3 -56z m76
-123 c9 -53 16 -104 16 -112 0 -20 -39 -20 -68 1 -38 26 -44 48 -31 117 10 51
17 67 38 80 14 9 27 15 28 14 1 -1 9 -46 17 -100z m2296 63 c0 -16 -54 -120
-62 -120 -14 0 -9 70 7 100 15 28 55 43 55 20z m-3059 -23 c-1 -12 -15 -9 -19
4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-202 -128 c7 -23 10 -44 7 -47 -7 -7 -7
-6 -28 39 -15 34 -15 49 2 49 4 0 12 -18 19 -41z m751 -91 c22 -37 26 -83 9
-125 -11 -28 -11 -27 -36 37 -37 99 -40 120 -14 120 13 0 29 -12 41 -32z
m2804 -14 c11 -28 7 -51 -13 -75 -15 -18 -22 -21 -31 -12 -10 9 -8 22 5 57 19
48 29 56 39 30z m-2524 -17 c-1 -7 -14 -28 -31 -47 -29 -34 -31 -34 -45 -16
-22 30 -17 44 24 65 38 19 52 19 52 -2z m-210 -218 c0 -5 -7 -9 -15 -9 -8 0
-15 7 -15 15 0 9 6 12 15 9 8 -4 15 -10 15 -15z m-580 -390 c279 -417 260
-385 223 -366 -40 22 -97 85 -200 222 -111 148 -148 206 -229 358 -61 113 -68
138 -41 137 7 -1 118 -158 247 -351z m2745 254 c-13 -55 -40 -59 -29 -5 4 19
13 32 21 32 11 0 13 -7 8 -27z m628 -81 c-15 -32 -27 -40 -83 -59 -85 -29
-100 -29 -100 0 0 32 27 53 78 62 47 8 96 43 109 78 7 20 8 18 11 -11 2 -18
-5 -49 -15 -70z m-659 -59 c6 -17 -1 -31 -29 -60 -20 -21 -35 -31 -32 -23 21
68 43 118 48 112 4 -4 10 -17 13 -29z m-1630 -28 c3 -9 0 -15 -9 -15 -8 0 -15
7 -15 15 0 8 4 15 9 15 5 0 11 -7 15 -15z m-2853 -491 c11 -14 10 -18 -6 -30
-10 -8 -22 -14 -26 -14 -13 0 -19 33 -8 46 15 18 24 18 40 -2z m2702 -29 c8
-18 -11 -31 -25 -17 -11 11 -3 32 12 32 4 0 10 -7 13 -15z m1972 -224 c-4 -17
-11 -31 -16 -31 -12 0 -11 12 1 46 14 35 24 24 15 -15z m-4204 -214 c28 -19
34 -29 32 -56 -2 -58 -17 -53 -51 14 -17 36 -28 65 -23 65 4 0 23 -10 42 -23z
m-21 -179 c0 -4 -6 -8 -14 -8 -16 0 -48 45 -39 54 7 7 53 -33 53 -46z m242
-110 c99 -82 131 -103 163 -108 42 -6 48 -17 32 -65 -6 -18 -1 -22 36 -32 88
-24 167 -126 196 -251 l9 -44 -40 4 c-24 2 -43 -2 -45 -8 -2 -6 53 -55 122
-109 69 -54 125 -101 125 -105 0 -3 -24 -24 -54 -46 l-55 -39 32 -33 c37 -38
33 -43 -47 -67 -109 -33 -148 -17 -232 95 l-54 72 20 25 c29 37 25 50 -27 86
-109 75 -124 96 -138 199 -4 34 -16 58 -44 90 -54 61 -74 104 -66 142 18 89
18 90 -17 126 -18 19 -46 44 -61 55 l-28 20 16 48 c9 26 21 47 25 47 5 0 64
-46 132 -102z m2459 -51 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1
-17z m-2567 -322 c3 -9 0 -15 -9 -15 -16 0 -27 14 -18 24 10 10 21 7 27 -9z
m3526 -65 c0 -21 -25 -100 -31 -100 -6 0 -29 53 -29 66 0 7 44 44 52 44 4 0 8
-4 8 -10z m-1795 -10 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6
0 13 -4 16 -10z m-1511 -266 c12 -17 15 -29 8 -36 -10 -10 -42 26 -42 49 0 20
13 15 34 -13z m1969 0 c79 -41 106 -77 107 -141 0 -42 0 -42 -28 -32 -56 19
-122 115 -122 175 0 18 4 18 43 -2z m239 -195 c103 -22 171 -76 223 -178 37
-74 35 -83 -15 -66 -22 8 -60 26 -86 41 -39 23 -52 26 -99 20 -83 -10 -99 2
-124 94 -11 42 -21 82 -21 89 0 14 54 14 122 0z m-2013 -106 c69 -102 53 -119
-24 -23 -51 63 -64 100 -37 100 4 0 32 -34 61 -77z m1839 -56 l23 -24 -48 8
c-86 14 -104 30 -95 90 l4 29 47 -39 c25 -21 56 -50 69 -64z m1821 -403 c30
-13 52 -26 50 -28 -2 -2 -53 2 -114 10 -60 7 -119 14 -130 14 -20 1 -20 1 0
12 54 31 110 29 194 -8z m-1339 1 c24 -14 24 -14 -24 -15 -42 0 -47 2 -36 15
15 18 28 18 60 0z m-1209 -78 c-18 -18 -35 7 -26 40 l7 28 15 -29 c11 -20 12
-31 4 -39z m1099 33 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m677 -46 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z
m998 -39 c44 -23 79 -46 79 -51 -1 -5 -14 -18 -29 -29 -21 -16 -38 -19 -83
-16 -31 3 -95 5 -142 5 -73 1 -91 -2 -123 -23 -31 -19 -44 -22 -70 -14 l-31 9
73 59 c112 91 114 93 151 103 49 15 84 6 175 -43z m-1780 15 c22 -9 23 -8 -52
-34 -47 -16 -55 -8 -23 24 21 21 42 24 75 10z m-185 -125 c-14 -43 -43 -59
-135 -74 -74 -13 -91 -21 -118 -61 -24 -34 -22 -38 19 -46 28 -5 52 0 102 20
68 28 226 56 239 42 10 -10 -57 -68 -127 -109 -126 -75 -276 -121 -498 -151
-137 -19 -148 -20 -155 -3 -3 9 26 33 83 68 49 29 86 57 84 61 -3 4 -27 8 -53
8 -50 0 -71 12 -71 42 0 33 46 33 124 -1 39 -17 80 -31 90 -31 23 0 66 37 66
56 0 7 -21 43 -46 79 -26 36 -44 69 -41 72 3 3 104 24 224 47 237 46 234 46
213 -19z m-744 44 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16
-11z m-319 -77 c27 -17 68 -80 59 -90 -13 -12 -98 42 -122 77 -13 20 -24 39
-24 43 0 9 58 -11 87 -30z m373 -27 c0 -16 -43 -21 -74 -9 -31 12 -16 24 29
24 33 0 45 -4 45 -15z m2920 -59 c0 -7 -150 -54 -205 -65 -27 -5 -84 -12 -125
-15 -87 -7 -91 -10 99 73 l135 59 48 -24 c26 -13 48 -26 48 -28z m-3070 -105
c0 -9 -70 -21 -83 -14 -4 2 -5 9 -2 14 8 12 85 12 85 0z m-270 -65 c0 -23 -29
-41 -48 -30 -27 17 -25 32 5 37 15 2 31 5 36 6 4 0 7 -5 7 -13z"
      />
      <path
        d="M24090 34685 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"
      />
      <path
        d="M24445 33310 c-3 -5 -1 -18 4 -27 9 -16 10 -14 11 10 0 28 -5 34 -15
17z"
      />
      <path d="M23223 31143 c31 -2 83 -2 115 0 31 2 5 3 -58 3 -63 0 -89 -1 -57 -3z" />
      <path d="M22923 31123 c26 -2 67 -2 90 0 23 2 2 3 -48 3 -49 0 -68 -1 -42 -3z" />
      <path
        d="M23546 31114 c-9 -8 -16 -19 -16 -23 0 -12 71 -121 79 -121 4 0 16
18 26 40 10 22 21 40 24 40 4 0 15 -16 25 -35 11 -19 23 -35 27 -35 4 0 17 16
29 36 22 34 22 36 4 49 -21 15 -152 65 -171 65 -6 0 -19 -7 -27 -16z"
      />
      <path
        d="M23410 30980 c0 -5 13 -10 29 -10 16 0 44 -3 62 -6 32 -5 33 -5 15
10 -22 18 -106 22 -106 6z"
      />
      <path
        d="M23863 30963 c-21 -8 -15 -20 18 -33 37 -16 60 -1 39 25 -12 15 -33
18 -57 8z"
      />
      <path d="M23542 30934 c2 -18 12 -33 28 -41 36 -18 37 -8 1 33 l-33 36 4 -28z" />
      <path
        d="M21897 28800 c8 -29 28 -41 51 -33 19 7 18 10 -17 30 -37 21 -39 22
-34 3z"
      />
      <path
        d="M22380 28340 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10
-5 0 -9 -4 -9 -10z"
      />
      <path
        d="M16014 35046 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28
-8 8 -13 7 -18 -6z"
      />
      <path
        d="M16144 34060 c14 -17 29 -30 33 -30 4 1 16 15 26 31 l19 29 -52 0
-52 0 26 -30z"
      />
      <path
        d="M16136 33922 c-3 -5 6 -14 19 -20 16 -8 26 -8 31 -1 7 12 5 15 -23
23 -12 4 -24 3 -27 -2z"
      />
      <path
        d="M13987 32333 c-2 -5 -7 -26 -11 -48 -4 -22 -9 -48 -12 -57 -3 -11 0
-18 9 -18 10 0 17 16 21 50 4 28 9 57 12 65 6 15 -11 22 -19 8z"
      />
      <path
        d="M13941 32019 c-7 -22 -11 -42 -9 -43 2 -2 22 2 46 8 l43 11 -34 33
-34 33 -12 -42z"
      />
      <path
        d="M13889 31779 c-12 -7 -12 -13 1 -36 13 -23 22 -28 55 -28 28 0 41 5
43 15 4 21 -79 62 -99 49z"
      />
      <path
        d="M17920 30970 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"
      />
      <path
        d="M14261 21240 c-8 -4 -10 -13 -7 -19 9 -13 43 -15 51 -2 8 14 -28 31
-44 21z"
      />
      <path
        d="M10127 18586 c-5 -12 19 -35 59 -59 7 -4 17 -1 23 7 9 10 3 19 -29
40 -46 30 -46 30 -53 12z"
      />
      <path
        d="M10665 18570 c-4 -6 6 -10 22 -10 22 0 25 2 13 10 -19 12 -27 12 -35
0z"
      />
      <path
        d="M10064 18175 c-7 -16 63 -105 83 -105 28 0 22 23 -19 70 -46 53 -55
59 -64 35z"
      />
      <path
        d="M9980 18092 c0 -5 9 -21 21 -36 15 -20 29 -26 57 -26 33 0 35 1 17
13 -56 37 -95 58 -95 49z"
      />
      <path
        d="M12905 18070 c-22 -24 -17 -31 27 -33 38 -2 45 18 12 39 -18 13 -23
12 -39 -6z"
      />
      <path
        d="M9873 17953 c-34 -20 -60 -40 -57 -45 5 -7 138 -78 146 -78 2 0 -1
36 -6 80 -5 44 -12 80 -15 80 -3 -1 -34 -17 -68 -37z"
      />
      <path
        d="M9677 17934 c-4 -4 12 -30 35 -58 23 -28 43 -53 45 -55 2 -3 9 1 15
7 9 9 1 23 -33 61 -46 51 -52 55 -62 45z"
      />
      <path
        d="M14890 16506 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12
-12z"
      />
      <path
        d="M9313 15396 c-57 -14 -155 -59 -172 -80 -7 -8 61 5 186 35 109 27
203 51 208 53 25 11 -176 3 -222 -8z"
      />
      <path
        d="M15505 14547 c-4 -11 -2 -29 6 -44 17 -31 23 -16 11 29 -8 27 -11 30
-17 15z"
      />
      <path
        d="M29361 30273 c0 -73 11 -64 23 20 6 39 5 47 -8 47 -13 0 -16 -13 -15
-67z"
      />
      <path
        d="M27920 27635 c0 -23 72 -195 81 -189 5 3 9 28 9 55 0 46 -4 54 -45
97 -24 26 -45 43 -45 37z"
      />
      <path
        d="M18617 18656 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"
      />
      <path
        d="M23330 14450 c0 -54 20 -130 34 -130 4 0 6 43 6 95 0 88 -1 95 -20
95 -18 0 -20 -7 -20 -60z"
      />
      <path
        d="M22705 10890 c-11 -12 -15 -26 -10 -37 6 -16 9 -15 30 11 34 42 16
66 -20 26z"
      />
      <path
        d="M21027 6895 c-13 -37 -13 -45 -2 -45 13 0 35 49 35 76 0 30 -19 12
-33 -31z"
      />
      <path
        d="M19412 6370 c-11 -18 -10 -20 13 -20 23 0 24 2 15 20 -6 11 -12 20
-13 20 -1 0 -8 -9 -15 -20z"
      />
      <path
        d="M19650 6030 c-11 -7 -12 -12 -4 -20 8 -8 14 -7 23 4 14 17 1 28 -19
16z"
      />
      <path d="M32541 26853 l1 -58 14 45 c15 46 13 70 -6 70 -6 0 -10 -24 -9 -57z" />
      <path
        d="M32527 26746 c-3 -8 -15 -81 -26 -163 -12 -81 -24 -164 -26 -183 -5
-35 -5 -35 10 -10 32 54 56 156 62 263 4 71 3 107 -4 107 -6 0 -13 -6 -16 -14z"
      />
      <path
        d="M32834 26720 c3 -14 6 -45 7 -70 l1 -45 13 30 c19 42 18 59 -6 86
l-21 24 6 -25z"
      />
      <path d="M32685 26639 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
      <path
        d="M31490 26521 c0 -13 62 -51 73 -44 5 3 -4 16 -19 29 -30 24 -54 31
-54 15z"
      />
      <path
        d="M32471 26278 c-1 -55 2 -68 14 -68 11 0 14 7 10 23 -3 12 -9 42 -14
67 -9 45 -9 45 -10 -22z"
      />
      <path
        d="M32765 26171 c-11 -22 -12 -32 -3 -37 13 -8 38 22 38 48 0 27 -18 22
-35 -11z"
      />
      <path
        d="M32717 26015 c-15 -36 -27 -70 -27 -75 0 -6 15 -10 34 -10 39 0 41
-4 56 -95 5 -32 11 -60 13 -62 3 -2 21 -6 41 -10 42 -7 63 7 83 55 l13 33 -87
114 c-49 62 -91 114 -94 114 -3 1 -17 -28 -32 -64z"
      />
      <path
        d="M32336 25603 c-3 -43 -9 -98 -13 -122 -4 -34 -3 -43 8 -39 9 3 18 35
26 94 16 122 16 144 -1 144 -9 0 -15 -22 -20 -77z"
      />
      <path
        d="M32530 25520 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18
-8 2 -13 -5 -13 -18z"
      />
      <path
        d="M33130 25346 c0 -10 -30 -364 -35 -418 -5 -48 -3 -58 10 -58 8 0 15
1 16 3 4 14 35 464 32 470 -4 8 -23 10 -23 3z"
      />
      <path
        d="M32842 25133 c-26 -37 -27 -43 -7 -43 17 0 37 35 33 58 -3 13 -8 10
-26 -15z"
      />
      <path
        d="M32782 25070 c-12 -11 -19 -27 -16 -35 8 -20 20 -11 32 25 13 36 12
36 -16 10z"
      />
      <path d="M33000 24976 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
      <path
        d="M32660 24910 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"
      />
      <path
        d="M33032 24326 c-49 -325 -48 -370 2 -194 37 128 64 380 48 448 -6 27
-16 -24 -50 -254z"
      />
      <path
        d="M29766 23540 c-9 -100 -15 -184 -12 -187 3 -3 15 28 27 68 25 79 31
230 12 278 -7 18 -14 -20 -27 -159z"
      />
      <path
        d="M29717 23159 c-48 -27 -55 -95 -17 -183 l16 -40 13 113 c6 63 11 115
9 116 -2 2 -11 -1 -21 -6z"
      />
      <path
        d="M29551 22947 c-13 -16 -5 -27 20 -27 24 0 35 20 18 31 -19 12 -25 11
-38 -4z"
      />
      <path
        d="M30516 17981 c-3 -4 16 -23 41 -41 33 -24 45 -29 40 -16 -4 10 -7 21
-7 26 0 27 -61 53 -74 31z"
      />
      <path
        d="M25190 25125 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"
      />
      <path
        d="M26500 18770 c0 -18 23 -59 37 -64 13 -5 53 13 53 24 0 3 -20 16 -45
28 -25 12 -45 18 -45 12z"
      />
      <path d="M26555 18619 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
      <path
        d="M26401 18326 c-6 -24 -13 -51 -17 -60 -4 -11 -1 -16 14 -16 15 0 21
9 26 46 8 62 8 74 -3 74 -5 0 -14 -20 -20 -44z"
      />
      <path
        d="M26375 18057 c-26 -26 -45 -53 -45 -65 0 -20 5 -22 53 -22 56 0 77
12 77 44 0 26 -22 86 -32 86 -4 0 -28 -19 -53 -43z"
      />
      <path
        d="M24130 12792 c-14 -52 -13 -62 9 -62 13 0 20 10 25 31 9 46 8 56 -9
62 -10 4 -17 -5 -25 -31z"
      />
      <path
        d="M26652 11236 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
      />
      <path
        d="M20962 4024 c-14 -10 -22 -25 -22 -45 0 -37 9 -37 48 4 25 26 29 36
20 45 -15 15 -19 15 -46 -4z"
      />
      <path
        d="M21508 22883 c-40 -46 -50 -116 -23 -163 8 -14 15 -20 15 -15 0 6 7
52 14 103 7 50 12 92 11 92 -1 0 -9 -8 -17 -17z"
      />
      <path
        d="M21460 22603 c0 -42 26 -73 59 -73 18 0 31 7 35 16 4 9 5 18 4 19 -2
1 -24 16 -50 34 l-48 32 0 -28z"
      />
      <path
        d="M21465 21340 c-11 -17 5 -32 21 -19 7 6 11 15 8 20 -7 12 -21 11 -29
-1z"
      />
      <path
        d="M32600 16681 c19 -10 46 -22 60 -25 l25 -7 -28 25 c-18 17 -39 26
-60 26 l-32 -1 35 -18z"
      />
      <path
        d="M31832 15708 c5 -15 98 -24 98 -10 0 14 -26 22 -67 22 -23 0 -33 -4
-31 -12z"
      />
      <path
        d="M23730 15025 c0 -28 15 -34 24 -9 10 25 7 34 -9 34 -9 0 -15 -9 -15
-25z"
      />
      <path
        d="M23612 13288 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"
      />
      <path
        d="M23486 12182 c-4 -41 -5 -76 -3 -79 3 -2 12 17 21 42 13 40 13 52 2
78 -13 32 -13 31 -20 -41z"
      />
      <path
        d="M17490 4616 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33
-7z"
      />
      <path
        d="M27485 34930 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"
      />
      <path
        d="M30780 32526 c0 -1 27 -26 60 -55 33 -29 60 -48 60 -42 0 18 -85 101
-103 101 -9 0 -17 -2 -17 -4z"
      />
      <path
        d="M31121 32177 c-8 -10 3 -23 45 -56 30 -24 57 -41 60 -39 7 7 -53 86
-75 97 -14 8 -22 7 -30 -2z"
      />
      <path
        d="M31250 32039 c0 -26 44 -81 88 -111 l47 -31 -36 39 c-19 21 -50 56
-67 77 -18 21 -32 32 -32 26z"
      />
      <path
        d="M31390 31875 c1 -6 7 -21 14 -34 9 -17 15 -20 21 -11 4 7 -1 21 -14
33 -11 12 -21 17 -21 12z"
      />
      <path
        d="M31560 31638 c0 -19 26 -42 34 -30 3 5 -3 17 -14 27 -17 15 -20 16
-20 3z"
      />
      <path
        d="M32185 30540 c-7 -12 7 -41 51 -102 49 -66 76 -133 72 -176 -3 -36
-1 -37 98 -108 l100 -71 -6 42 c-10 69 -167 343 -225 394 -35 30 -78 41 -90
21z"
      />
      <path
        d="M32540 30055 c26 -28 40 -32 40 -11 0 8 -14 19 -32 25 l-32 11 24
-25z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M24500 35384 c-168 -18 -268 -35 -650 -110 -179 -35 -363 -66 -410
-70 -187 -14 -790 -185 -1017 -287 -198 -90 -408 -219 -576 -354 -129 -104
-470 -406 -601 -533 -402 -388 -656 -741 -771 -1070 -49 -142 -173 -650 -190
-780 -7 -58 -16 -169 -20 -247 -4 -77 -10 -156 -14 -175 -7 -30 -8 -26 -14 32
-28 248 -80 533 -133 730 -19 69 -28 134 -34 240 -6 114 -13 161 -33 219 l-25
75 21 28 21 28 -24 57 c-43 106 -247 507 -314 617 -198 325 -403 526 -816 801
-552 366 -1007 560 -1447 614 -124 16 -481 34 -538 28 -28 -3 -80 -17 -115
-32 -59 -25 -72 -27 -150 -21 -47 3 -107 9 -133 12 -44 6 -52 3 -78 -22 l-29
-28 -148 -1 c-145 0 -148 1 -226 33 -92 39 -222 66 -398 83 -167 15 -562 6
-673 -15 -108 -21 -175 -22 -218 -1 l-36 17 -96 -58 -96 -57 -29 31 c-22 24
-37 32 -63 32 -64 0 -384 -117 -576 -210 -196 -96 -185 -95 -165 -22 12 43
-49 20 -130 -52 -34 -28 -139 -114 -234 -190 -245 -194 -415 -350 -586 -534
-81 -88 -157 -174 -170 -191 -13 -17 -66 -73 -118 -124 -82 -79 -101 -104
-137 -179 -34 -73 -53 -97 -116 -154 -80 -73 -101 -108 -92 -154 5 -24 -1 -39
-31 -77 -34 -45 -37 -54 -44 -143 -3 -52 -21 -171 -40 -263 l-33 -169 -37 -19
c-49 -25 -84 -72 -109 -150 -32 -98 -74 -170 -160 -272 -87 -105 -142 -157
-165 -157 -16 0 -16 10 2 103 6 27 2 26 -98 -40 -255 -165 -317 -239 -436
-513 -140 -324 -190 -525 -217 -872 -8 -104 -22 -217 -31 -251 -22 -84 -22
-205 1 -297 19 -79 93 -231 242 -499 117 -209 123 -228 133 -396 5 -77 16
-189 26 -249 24 -152 15 -199 -79 -417 -41 -96 -87 -212 -101 -259 -32 -106
-56 -260 -56 -365 0 -58 -6 -92 -20 -124 -30 -66 -25 -122 8 -89 18 18 22 7
29 -70 6 -70 5 -72 -26 -101 -21 -20 -34 -44 -40 -75 -16 -86 -63 -130 -191
-177 -111 -40 -132 -52 -195 -109 -76 -68 -114 -78 -202 -52 -39 12 -105 45
-153 76 -47 30 -121 78 -165 106 -90 57 -387 178 -666 271 -141 48 -193 60
-265 65 -91 6 -91 6 -464 -72 -24 -5 -27 -1 -42 48 -25 80 -41 297 -41 548 0
339 33 597 100 782 52 145 244 519 410 803 161 276 212 374 276 535 98 248
242 739 271 930 29 187 50 428 49 575 0 133 -4 169 -37 325 -52 247 -91 493
-115 725 -41 386 -44 400 -132 579 -83 167 -179 305 -347 499 -92 106 -177
176 -372 308 -69 46 -154 111 -190 144 -141 131 -229 185 -299 185 -35 0 -59
9 -102 35 -83 51 -182 88 -399 147 l-195 53 -318 0 -319 0 -19 24 c-10 13 -19
27 -19 32 0 4 40 32 90 61 83 49 103 69 81 82 -10 7 -255 -91 -377 -150 -113
-55 -249 -144 -335 -221 -288 -253 -436 -390 -512 -472 -155 -168 -270 -403
-317 -648 -11 -59 -29 -144 -40 -188 -33 -137 -52 -294 -48 -385 6 -105 63
-269 173 -495 l72 -150 139 -141 c127 -129 147 -145 232 -187 51 -26 152 -65
225 -88 88 -27 145 -51 170 -71 35 -27 44 -30 95 -26 58 4 118 27 115 44 -1 5
-4 16 -8 26 -4 11 0 18 15 23 12 4 44 20 70 37 37 23 58 29 84 26 34 -4 36 -2
36 23 0 39 24 59 72 59 l40 0 -16 25 c-9 13 -16 26 -16 28 0 2 25 21 56 41 44
30 61 48 79 90 87 196 89 358 8 490 -34 55 -165 188 -293 298 -109 93 -150
140 -150 170 0 37 69 91 379 297 259 173 325 212 344 207 48 -12 120 -92 246
-275 255 -370 228 -317 347 -675 105 -313 107 -324 112 -429 6 -138 -2 -175
-103 -502 -158 -510 -274 -814 -327 -862 -11 -10 -41 -53 -66 -96 -61 -106
-140 -189 -242 -255 -193 -125 -366 -279 -494 -439 -128 -160 -245 -393 -320
-635 -50 -162 -64 -222 -96 -408 -16 -91 -38 -194 -49 -230 -31 -102 -68 -257
-85 -356 -53 -304 -58 -464 -41 -1324 l14 -735 51 -245 c118 -562 148 -663
224 -747 30 -34 44 -67 91 -224 31 -101 61 -199 67 -218 5 -18 32 -57 59 -87
56 -62 83 -111 100 -180 17 -69 29 -82 46 -50 11 22 21 26 55 26 55 0 122 -33
147 -73 23 -36 20 -40 -30 -56 -28 -9 -44 -8 -79 4 -24 8 -45 13 -47 11 -8 -8
9 -74 27 -104 21 -34 136 -134 275 -240 58 -44 97 -82 110 -107 16 -30 39 -50
100 -85 44 -25 107 -64 140 -86 33 -22 141 -85 240 -138 176 -96 249 -146 213
-146 -35 0 -188 54 -323 115 -117 52 -202 84 -295 111 -70 20 325 -199 442
-245 43 -17 117 -38 165 -47 90 -16 270 -73 333 -104 61 -31 73 -51 65 -113
-9 -67 -1 -82 76 -164 33 -34 59 -67 59 -74 0 -23 -60 -106 -113 -156 -69 -65
-176 -140 -506 -355 -425 -277 -567 -391 -707 -564 -149 -186 -171 -192 -559
-149 -394 43 -472 61 -910 215 -110 38 -203 70 -206 70 -4 0 -9 -13 -12 -30
l-6 -29 -98 46 c-282 131 -322 154 -452 266 -42 36 -130 106 -193 154 -225
171 -605 493 -651 552 -55 71 -104 97 -210 110 -86 10 -129 33 -165 85 -31 46
-37 88 -22 168 15 78 22 58 -74 221 -32 56 -59 89 -72 93 -31 8 -80 73 -105
138 -24 67 -78 148 -155 236 -31 36 -67 77 -80 93 -27 30 -64 35 -88 12 -8 -8
-18 -37 -21 -63 -22 -158 -56 -347 -64 -355 -6 -6 -16 2 -28 19 -13 21 -18 48
-18 109 -1 52 -9 111 -25 169 -30 112 -23 132 62 159 46 15 59 24 67 48 5 16
21 44 35 62 46 59 57 111 68 314 12 211 9 235 -43 284 -16 14 -31 38 -34 52
-7 25 -4 27 33 33 37 6 42 11 60 53 35 81 42 122 48 276 3 83 12 206 20 274
13 110 13 126 0 133 -12 7 -10 18 13 71 16 34 38 85 51 112 21 49 22 49 32 25
10 -24 12 -21 29 35 24 75 65 271 74 350 20 166 95 261 246 309 85 28 234 56
292 56 53 0 103 -25 127 -65 43 -70 84 -353 69 -485 -17 -148 -60 -233 -182
-357 -69 -70 -78 -83 -61 -88 54 -18 62 -32 36 -65 l-19 -23 -81 46 c-45 26
-85 47 -90 47 -5 0 -26 -31 -47 -70 -31 -57 -43 -70 -62 -70 -37 0 -60 -16
-68 -47 -45 -178 -14 -299 127 -498 127 -179 312 -334 523 -439 308 -153 478
-166 827 -65 113 33 158 104 111 175 -14 22 -15 32 -4 71 6 25 10 47 9 49 -2
2 -55 -10 -118 -26 -63 -16 -119 -30 -124 -30 -5 0 -6 38 -4 84 7 99 30 155
72 175 26 12 39 11 126 -14 l97 -28 21 -71 c22 -76 59 -139 82 -143 17 -3 85
76 118 139 24 43 25 92 7 169 -6 23 -3 28 24 37 36 13 36 13 15 53 l-15 30
101 111 c143 157 143 157 152 363 22 535 22 485 -7 710 -30 238 -65 433 -96
526 -11 34 -44 110 -73 168 -29 58 -65 143 -80 188 -30 89 -72 161 -165 278
-33 42 -73 97 -88 123 -15 26 -40 66 -56 89 -22 31 -28 48 -23 67 5 21 -5 38
-58 99 -103 118 -243 244 -350 315 -92 61 -102 66 -156 66 -48 1 -65 7 -112
37 -90 61 -152 88 -284 125 -127 36 -155 38 -264 19 -24 -4 -54 1 -95 15 -37
14 -95 24 -150 27 -78 4 -110 0 -245 -30 -85 -20 -219 -45 -297 -57 -404 -60
-718 -183 -974 -381 -279 -217 -347 -287 -422 -440 -71 -146 -193 -317 -316
-444 -57 -59 -129 -149 -177 -220 -89 -134 -218 -395 -259 -525 -23 -75 -100
-506 -137 -765 -7 -49 -12 -164 -12 -255 -1 -192 26 -370 99 -655 58 -229 194
-594 296 -797 93 -185 261 -420 666 -933 39 -49 99 -142 133 -205 93 -171 133
-225 241 -331 94 -91 105 -107 271 -385 306 -508 301 -499 342 -704 27 -132
57 -198 168 -372 39 -62 86 -148 104 -191 88 -210 186 -288 416 -328 62 -11
170 -31 241 -44 70 -14 133 -25 140 -25 7 0 26 27 42 61 l29 60 -28 51 -29 51
29 -7 c121 -29 230 -85 362 -189 30 -23 61 -47 69 -54 14 -11 11 -23 -25 -113
-23 -55 -42 -105 -42 -110 0 -20 61 -9 107 20 75 48 95 36 68 -39 -14 -42 -21
-131 -9 -131 3 0 30 26 59 59 72 77 101 89 161 66 25 -9 58 -28 72 -40 l27
-23 -27 -11 c-16 -6 -39 -15 -53 -21 -14 -5 -25 -16 -25 -24 0 -16 113 -61
129 -51 16 9 14 41 -4 76 -14 25 -14 30 -1 35 21 8 54 -13 76 -49 29 -46 25
-55 -24 -62 -41 -7 -44 -9 -50 -45 -3 -21 -9 -45 -12 -53 -4 -11 37 -36 163
-100 107 -55 162 -88 153 -91 -9 -4 -146 47 -310 113 -162 66 -296 119 -298
117 -2 -2 14 -17 35 -32 21 -15 61 -44 88 -65 28 -20 73 -48 102 -63 46 -23
100 -73 89 -83 -2 -3 -158 39 -347 91 -188 53 -344 94 -346 92 -2 -2 1 -34 7
-72 13 -78 11 -76 153 -175 169 -117 325 -174 575 -210 167 -23 263 -52 348
-104 136 -82 283 -100 724 -91 374 9 526 20 698 52 305 57 269 55 616 44 348
-11 439 -19 618 -56 145 -30 164 -30 233 1 141 62 284 44 359 -45 42 -51 36
-67 -29 -75 -67 -9 -71 -26 -12 -49 54 -20 57 -20 57 3 0 34 12 51 34 51 27 0
126 -29 126 -36 0 -3 -9 -19 -21 -36 -12 -16 -18 -32 -15 -35 4 -4 52 -6 109
-4 282 8 250 12 370 -48 l108 -53 58 22 58 21 54 -24 c95 -43 118 -82 144
-252 14 -87 26 -129 60 -200 55 -117 76 -184 121 -385 19 -91 36 -167 37 -170
1 -3 -2 -24 -6 -48 -7 -43 8 -98 37 -132 22 -26 64 -144 82 -230 39 -195 5
-421 -163 -1080 -99 -388 -98 -383 -86 -405 7 -13 27 35 82 194 40 116 77 211
82 211 4 0 10 -4 13 -9 5 -8 -62 -263 -151 -576 -32 -113 -102 -258 -126 -263
-24 -5 -22 14 17 173 19 77 33 141 31 143 -5 6 -63 -141 -89 -229 -15 -48 -39
-105 -55 -127 -30 -40 -100 -82 -138 -82 -46 0 -218 -42 -305 -74 -116 -43
-164 -56 -206 -56 -18 0 -44 -8 -58 -17 -75 -49 -243 13 -543 199 -79 49 -182
110 -230 136 -148 80 -207 157 -277 366 -19 55 -31 103 -26 108 4 4 55 27 114
52 186 78 335 110 400 86 108 -40 179 -30 335 46 124 60 204 128 235 199 38
87 156 403 171 455 45 156 61 409 36 573 -37 249 -123 465 -255 640 -80 107
-266 295 -400 406 -94 78 -99 81 -148 81 -33 0 -70 9 -104 25 l-54 25 7 -33
c4 -17 9 -40 12 -50 4 -16 1 -18 -19 -12 -34 11 -74 32 -154 81 -48 30 -98 51
-155 65 -64 16 -89 27 -106 47 -12 15 -29 34 -36 43 -11 13 -43 17 -158 22
-164 6 -198 -2 -218 -51 l-13 -31 -32 24 c-60 46 -96 43 -278 -18 -89 -30
-228 -76 -307 -101 -79 -26 -187 -69 -240 -95 -159 -81 -621 -351 -621 -365 0
-4 18 -6 40 -4 58 5 50 -14 -40 -90 -94 -79 -140 -103 -201 -105 -37 -2 -58
-9 -85 -30 l-35 -29 36 -39 c61 -67 46 -133 -53 -243 -55 -60 -60 -70 -55
-101 5 -31 0 -41 -62 -110 -56 -62 -73 -90 -97 -158 -88 -243 -90 -250 -93
-342 -3 -71 -8 -102 -29 -145 -31 -67 -45 -155 -38 -238 4 -53 0 -72 -22 -121
-35 -78 -35 -143 -1 -193 32 -47 31 -51 -6 -81 -27 -21 -33 -35 -45 -98 -19
-108 -18 -156 6 -186 11 -14 20 -38 20 -53 0 -26 34 -79 116 -178 l27 -34 -45
7 -45 7 43 -179 c23 -99 56 -221 72 -271 l30 -92 -22 0 c-37 0 -65 28 -72 71
-6 35 -12 42 -50 59 -49 21 -51 28 -32 120 12 60 9 90 -28 265 -24 118 -38
129 -80 65 -15 -22 -29 -38 -32 -35 -5 4 -71 450 -72 484 0 11 -3 11 -15 1
-16 -13 -15 -87 6 -410 17 -270 57 -483 164 -872 107 -389 153 -494 290 -665
32 -39 62 -102 125 -260 148 -373 241 -537 394 -689 219 -217 755 -626 1012
-771 39 -22 100 -63 135 -90 142 -110 253 -173 552 -312 239 -111 351 -159
548 -237 131 -52 176 -61 227 -48 l23 7 -28 19 c-15 10 -100 54 -188 98 -88
43 -178 95 -200 115 l-40 36 100 -4 c118 -5 185 -27 279 -92 58 -39 65 -41 87
-29 39 21 89 16 149 -16 289 -156 370 -173 622 -131 62 11 163 25 223 31 93
10 122 18 190 51 44 21 123 50 175 64 196 54 267 78 360 125 52 26 135 64 184
84 48 20 115 50 148 67 32 16 91 41 131 56 165 59 288 136 495 310 84 71 112
101 112 118 0 21 -5 24 -43 27 l-42 3 96 100 c53 55 153 174 224 265 70 91
143 182 163 202 l35 37 -29 6 c-16 4 -32 7 -36 8 -10 3 248 324 331 413 36 40
80 91 96 115 17 24 45 51 62 59 25 12 36 28 53 72 29 78 85 156 216 297 157
170 229 276 340 499 52 105 98 192 102 192 21 0 41 -94 47 -220 3 -74 15 -175
25 -225 30 -151 33 -235 15 -505 -11 -164 -15 -304 -11 -392 3 -76 1 -172 -4
-215 -12 -91 -81 -298 -222 -668 -114 -300 -111 -295 -284 -409 -72 -48 -144
-108 -210 -173 -79 -79 -135 -124 -274 -215 -302 -198 -453 -284 -965 -553
-578 -303 -672 -362 -1012 -642 -196 -161 -328 -292 -408 -404 -32 -46 -116
-160 -184 -254 -320 -433 -491 -784 -580 -1188 -95 -426 -130 -722 -131 -1077
0 -333 27 -533 120 -895 89 -347 151 -507 314 -814 120 -227 199 -353 287
-461 156 -191 267 -332 324 -410 104 -144 205 -220 335 -254 30 -8 150 -60
265 -116 440 -213 616 -287 775 -325 91 -21 110 -33 84 -52 -10 -7 -32 -13
-49 -13 -31 0 -75 -23 -75 -40 0 -15 49 -19 351 -30 l295 -11 100 25 c54 13
145 31 200 40 181 27 438 121 572 209 96 62 141 99 317 258 l140 127 114 242
c63 133 123 253 134 266 11 14 37 36 59 50 49 31 75 73 88 144 15 78 58 208
85 255 30 51 80 213 106 341 26 130 36 392 20 545 -24 229 -83 476 -172 714
-87 235 -142 321 -238 370 -63 33 -417 387 -405 406 5 8 9 34 9 59 l0 45 136
110 c75 60 183 158 240 216 118 120 176 163 376 278 223 129 304 190 634 487
160 144 200 184 181 184 -5 0 -43 -24 -83 -54 -75 -54 -116 -78 -156 -90 -69
-22 13 91 224 304 87 89 105 112 110 145 6 41 36 79 190 248 73 80 107 147
124 243 10 51 20 76 40 97 32 32 49 35 49 8 0 -16 10 -20 65 -26 l66 -7 66 59
c161 140 357 330 399 387 52 69 144 248 144 280 -1 12 -7 32 -15 46 -33 58
-21 88 85 213 102 120 157 238 190 407 15 77 42 117 94 140 49 20 47 17 105
220 109 386 123 455 132 645 4 99 14 248 23 330 28 277 28 319 7 575 -26 303
-30 330 -96 670 -30 151 -63 345 -75 430 -46 337 -127 689 -235 1018 -88 269
-138 373 -296 614 -35 54 -68 119 -84 170 -15 46 -41 112 -57 148 l-29 64 18
71 c14 59 17 110 15 315 -2 198 1 257 14 310 28 110 5 221 -73 358 -102 179
-108 294 -23 435 22 36 40 74 40 84 0 32 38 81 82 106 24 13 115 64 203 114
245 139 199 126 419 118 218 -7 252 -15 411 -87 208 -94 237 -111 302 -176 81
-82 127 -176 187 -387 156 -555 276 -1102 276 -1258 0 -101 1 -93 -99 -522
-38 -165 -90 -400 -114 -521 l-45 -222 -117 -273 c-127 -296 -221 -541 -260
-674 -47 -163 -55 -232 -55 -484 0 -256 -6 -218 76 -556 13 -55 43 -138 65
-185 23 -47 84 -176 135 -288 121 -264 207 -395 364 -553 121 -123 212 -199
236 -199 8 0 19 11 24 25 12 30 19 31 63 5 33 -20 34 -22 29 -67 l-5 -46 44 8
c88 17 110 12 260 -63 175 -87 267 -119 376 -132 185 -21 348 19 618 151 139
68 200 88 262 89 28 0 35 -6 53 -40 11 -22 24 -40 28 -40 21 0 96 91 115 140
21 52 26 57 95 92 68 35 78 37 133 31 46 -4 64 -2 84 11 26 17 140 184 132
192 -2 3 -17 -6 -33 -19 -16 -13 -39 -29 -51 -36 -25 -13 -73 -15 -73 -3 0 4
33 45 74 91 53 60 97 125 157 234 182 331 312 609 345 738 19 74 14 152 -14
225 l-19 49 34 215 c26 165 33 238 31 317 -3 95 -6 106 -43 180 -30 58 -45 78
-60 78 -19 0 -20 -6 -17 -115 3 -123 -5 -129 -31 -27 -9 33 -18 62 -21 65 -3
3 -17 -5 -31 -18 -15 -14 -29 -20 -34 -14 -5 5 -16 45 -26 89 -21 98 -112 279
-141 282 -48 6 -71 -50 -73 -180 -2 -86 -53 -33 -76 78 -21 100 -57 153 -130
192 -33 17 -73 47 -90 66 -27 31 -46 39 -183 78 -175 49 -135 53 -350 -35
l-120 -49 24 -38 c13 -21 24 -43 24 -48 0 -5 -26 -25 -57 -45 -32 -19 -71 -48
-87 -65 l-28 -30 36 -6 c20 -4 36 -10 36 -14 0 -4 -24 -34 -53 -67 -88 -97
-184 -258 -203 -339 -8 -39 -33 -142 -54 -230 -21 -88 -47 -196 -57 -240 l-18
-80 -60 -8 c-33 -4 -96 -6 -139 -5 l-80 3 -13 50 c-43 164 -56 414 -27 553 8
42 33 122 55 177 22 55 55 161 74 236 93 366 193 552 402 746 236 219 326 310
385 388 59 78 70 86 382 298 238 161 337 235 384 283 34 37 62 70 62 74 0 22
-101 -30 -345 -180 -102 -62 -250 -169 -325 -235 -65 -57 -129 -94 -163 -95
-16 0 -16 2 -2 24 9 13 73 75 142 138 77 70 145 142 178 189 43 60 75 91 157
150 57 42 139 95 183 119 100 54 160 93 205 136 19 18 94 68 165 112 102 63
154 103 241 189 100 97 127 132 273 354 163 248 192 280 229 260 34 -18 50
-48 101 -191 97 -268 163 -412 236 -515 58 -82 498 -511 596 -583 75 -54 79
-58 73 -88 -6 -31 -2 -34 266 -244 150 -117 293 -231 318 -253 26 -22 83 -64
128 -94 88 -58 107 -81 207 -253 63 -110 144 -215 322 -420 108 -124 134 -161
180 -255 81 -168 117 -282 118 -379 0 -45 -3 -100 -7 -123 l-8 -42 -30 22
c-17 12 -132 150 -257 307 -124 157 -257 317 -296 355 -199 199 -385 245 -655
159 -67 -21 -93 -35 -134 -74 -57 -53 -118 -87 -221 -120 -101 -33 -119 -44
-230 -139 -151 -130 -226 -223 -301 -376 -63 -128 -85 -209 -91 -345 -6 -129
4 -214 42 -344 27 -94 231 -567 288 -669 67 -119 138 -193 283 -297 48 -35 84
-83 72 -96 -15 -14 -138 74 -223 160 -179 181 -326 375 -417 553 -59 115 -123
314 -152 474 -12 68 -25 119 -28 113 -17 -27 -19 -176 -4 -265 88 -512 442
-1022 938 -1350 134 -89 340 -195 517 -268 245 -101 367 -126 616 -125 165 1
211 5 560 57 209 31 401 61 427 66 144 33 315 117 503 249 122 85 159 130 194
233 l20 61 -31 -6 c-43 -8 -76 -41 -88 -87 -11 -38 -32 -49 -101 -49 l-29 0
30 61 c42 82 22 70 -120 -75 -122 -124 -174 -161 -190 -136 -14 22 -12 53 5
76 19 25 19 53 -1 75 -23 26 -13 55 50 146 100 146 106 172 58 247 -18 28 -32
56 -32 62 0 27 23 25 55 -6 37 -36 49 -34 98 18 l28 29 -75 22 -75 21 10 28
c47 119 68 185 63 193 -3 5 -19 16 -36 25 l-30 16 33 34 c40 41 47 83 48 292
l1 142 31 0 c28 0 31 2 24 23 -23 71 -19 90 54 233 77 152 103 237 122 395 21
187 1 326 -87 600 -48 150 -64 219 -89 387 -8 50 5 55 28 10 l18 -33 -6 60
c-8 92 -134 710 -161 793 -23 70 -72 167 -80 159 -10 -10 109 -588 141 -687
40 -124 46 -224 9 -132 -9 20 -34 60 -55 88 -50 65 -74 134 -88 250 -13 108
-32 175 -66 229 -37 61 -76 146 -82 181 -4 25 1 38 27 67 l32 37 -23 65 c-13
36 -26 65 -29 65 -3 0 -16 -18 -30 -40 -14 -22 -28 -40 -32 -40 -13 0 -9 63 8
138 14 64 15 79 1 145 -8 41 -31 109 -51 152 -49 106 -48 126 15 205 25 32 28
46 35 143 4 61 3 107 -2 107 -5 0 -22 -23 -37 -50 -16 -28 -31 -50 -35 -50 -4
0 -34 -5 -67 -10 -66 -11 -75 -6 -75 45 0 55 174 537 208 578 17 19 21 20 40
8 11 -7 7 -29 -22 -111 -43 -122 -44 -149 -11 -215 28 -57 31 -101 11 -150
-12 -28 -12 -35 -2 -35 18 0 46 33 46 54 0 37 19 56 49 50 36 -8 56 19 65 88
4 25 13 52 21 58 26 22 63 95 98 196 57 162 84 207 217 350 l121 132 167 90
c231 125 240 129 308 142 51 10 71 9 124 -4 35 -9 85 -30 111 -45 66 -42 115
-53 148 -37 51 26 79 26 174 1 183 -47 282 -83 309 -112 14 -16 57 -91 96
-168 38 -77 77 -156 87 -175 29 -54 31 -125 5 -195 -110 -294 -139 -419 -164
-690 -21 -224 -38 -302 -92 -410 -45 -92 -100 -228 -160 -395 -26 -73 -44
-104 -78 -140 -39 -40 -53 -70 -126 -260 -155 -405 -188 -525 -227 -815 -37
-278 -39 -525 -4 -671 23 -98 23 -104 7 -150 -20 -60 -20 -123 0 -216 25 -113
21 -254 -15 -478 -44 -277 -53 -470 -48 -1025 4 -530 4 -526 74 -739 39 -117
141 -332 285 -598 49 -92 112 -222 140 -290 71 -177 142 -296 245 -415 47 -54
53 -66 53 -105 0 -38 5 -49 36 -79 20 -19 53 -70 74 -114 43 -91 71 -122 120
-131 l34 -7 -33 57 c-18 32 -30 60 -28 63 13 12 77 -64 106 -127 18 -39 32
-73 30 -74 -7 -6 -108 -56 -113 -56 -3 0 -10 18 -16 41 -18 65 -108 152 -179
174 l-24 7 27 -33 c15 -19 73 -95 129 -169 99 -130 103 -134 120 -117 18 18
20 18 45 1 15 -10 57 -42 95 -71 61 -49 67 -57 67 -91 0 -33 5 -40 44 -64 29
-17 53 -43 71 -76 37 -68 98 -102 184 -102 33 0 61 -3 61 -6 0 -3 -14 -23 -30
-44 l-30 -38 59 -99 c74 -123 90 -137 146 -138 40 -1 47 -5 74 -42 16 -23 49
-55 73 -71 24 -17 59 -47 78 -67 19 -20 60 -50 91 -65 31 -16 99 -59 150 -96
104 -73 155 -102 213 -119 22 -7 42 -17 44 -23 9 -27 -35 -22 -131 13 -131 48
-221 94 -382 194 -115 72 -175 97 -175 74 0 -10 242 -170 354 -235 176 -103
362 -169 526 -187 54 -6 103 -17 118 -27 42 -27 553 -537 568 -566 26 -50 20
-58 -44 -58 -160 0 -353 -100 -592 -305 -261 -225 -280 -247 -280 -324 0 -50
-19 -98 -69 -176 -66 -103 -72 -137 -82 -400 -7 -209 -6 -263 11 -417 11 -97
20 -229 20 -292 0 -213 50 -424 151 -636 52 -108 59 -135 46 -182 -7 -26 -6
-28 26 -28 32 0 34 -3 50 -53 24 -77 24 -78 -30 -62 -26 8 -49 13 -50 11 -7
-6 46 -136 83 -207 26 -49 76 -113 164 -207 158 -171 355 -362 437 -425 145
-111 320 -204 548 -290 116 -44 126 -46 285 -56 235 -16 211 -20 267 50 l48
60 85 -6 c83 -6 90 -5 285 54 521 156 816 300 1194 584 210 158 273 215 350
321 55 75 73 92 117 112 47 21 54 28 68 72 24 73 63 128 163 233 69 71 101
114 133 178 41 83 42 85 26 117 l-15 33 57 18 c31 9 58 18 60 18 1 1 -3 16 -9
33 -10 28 -8 35 19 69 16 21 42 70 56 110 15 40 38 87 51 105 58 80 77 119 72
145 -4 19 6 46 32 93 54 96 66 131 111 337 14 63 15 86 6 98 -10 14 -16 11
-46 -20 -18 -19 -37 -35 -41 -35 -3 0 -2 24 3 53 17 105 12 98 68 91 l50 -7 0
39 c0 22 3 69 6 105 l7 66 -32 12 c-39 17 -39 24 3 46 31 17 33 21 29 59 -3
23 2 100 11 171 25 208 20 392 -19 659 -13 87 -20 108 -56 162 l-41 63 42 55
c74 98 99 237 64 356 -12 41 -27 63 -66 99 -141 128 -185 178 -233 266 -43 79
-49 96 -47 143 2 51 -1 58 -68 156 -38 56 -97 134 -131 172 -33 38 -84 103
-113 144 -93 133 -187 209 -576 470 -547 367 -898 616 -1392 987 -332 249
-706 551 -774 626 -34 37 -103 129 -155 204 -51 75 -112 160 -135 189 -42 51
-42 53 -37 113 7 73 -3 102 -95 286 -35 72 -73 155 -84 185 -27 80 -71 300
-120 605 -41 258 -43 280 -43 490 0 221 9 359 25 385 16 25 41 -10 107 -150
94 -199 319 -598 365 -650 l41 -45 -6 52 c-4 39 -2 55 9 64 21 17 29 5 49 -78
10 -44 22 -73 30 -73 37 0 21 78 -28 136 -19 21 -62 86 -95 144 -56 95 -60
106 -45 118 9 6 29 12 44 12 33 0 56 -28 122 -150 25 -47 61 -106 79 -131 172
-242 189 -260 255 -285 39 -15 43 -20 43 -51 0 -57 30 -103 127 -197 50 -49
97 -102 104 -118 15 -37 40 -42 156 -34 95 7 123 0 123 -28 0 -6 -15 -21 -34
-33 -38 -24 -66 -77 -66 -122 0 -34 18 -55 154 -175 58 -52 80 -66 86 -56 6
10 24 3 79 -31 l70 -44 1 -53 c0 -64 11 -71 89 -53 31 7 81 15 111 18 71 6
104 -17 114 -78 7 -44 7 -44 72 -18 29 12 35 9 145 -64 118 -78 166 -121 192
-172 l16 -30 15 38 c9 20 20 37 24 37 11 0 53 -30 113 -79 73 -60 129 -84 304
-130 83 -22 202 -56 265 -76 140 -44 182 -54 284 -65 60 -7 85 -15 103 -32 19
-18 39 -23 111 -28 103 -7 123 -16 154 -72 l24 -43 109 2 c323 7 640 69 1060
207 274 91 528 257 821 537 182 175 273 253 428 369 75 55 140 109 146 119 5
11 60 63 122 117 260 226 349 336 485 597 52 100 145 267 206 372 265 454 354
708 411 1175 36 289 46 443 46 687 0 446 -29 706 -116 1054 -64 255 -88 327
-157 464 -81 163 -168 279 -274 366 -76 63 -151 156 -223 274 -30 49 -74 104
-105 132 -62 56 -341 227 -493 303 -205 102 -481 176 -745 201 -158 14 -491 7
-562 -14 -59 -16 -48 -22 60 -33 39 -3 83 -13 100 -22 29 -15 29 -15 7 -23
-12 -5 -123 -13 -246 -18 l-223 -9 -149 -59 c-82 -33 -196 -72 -254 -87 -127
-33 -186 -59 -267 -119 -101 -75 -186 -200 -214 -313 -7 -27 -24 -74 -40 -105
-35 -70 -55 -149 -68 -264 -5 -50 -19 -115 -30 -145 -25 -65 -26 -98 -6 -147
8 -20 14 -37 13 -37 -45 -16 -45 -17 -51 -201 -2 -55 -9 -119 -16 -143 l-12
-42 -29 15 -30 15 21 -57 c11 -32 54 -126 94 -209 67 -138 79 -155 143 -215
134 -124 226 -164 480 -206 178 -29 212 -26 258 22 l32 34 12 -28 c6 -16 25
-42 42 -57 34 -33 58 -75 58 -102 0 -22 34 -32 110 -32 31 0 56 -6 70 -18 21
-17 22 -26 23 -157 1 -86 -4 -161 -12 -195 -13 -53 -56 -150 -67 -150 -2 0
-14 21 -25 48 l-21 47 -35 -40 c-50 -56 -76 -119 -69 -161 4 -18 11 -36 16
-39 5 -3 37 13 71 35 33 23 62 40 64 38 9 -9 -27 -84 -54 -112 -17 -18 -42
-61 -56 -96 -23 -58 -58 -110 -76 -110 -3 0 -12 20 -18 45 -8 33 -15 43 -26
39 -17 -7 -19 4 -5 41 9 24 8 25 -11 15 -15 -9 -19 -18 -15 -38 5 -24 -2 -32
-51 -70 -52 -38 -62 -42 -115 -42 -32 0 -58 3 -58 6 0 3 45 58 101 122 154
179 221 292 242 412 l7 35 -33 -36 c-17 -20 -58 -68 -89 -107 -101 -124 -362
-327 -476 -370 -56 -21 -89 -26 -202 -30 -178 -5 -268 15 -435 98 -199 99
-273 168 -608 563 -280 329 -432 797 -414 1271 4 118 11 163 40 276 41 157
139 418 197 528 34 64 362 550 489 724 38 53 100 97 218 157 108 54 190 79
303 91 121 14 182 28 358 84 96 31 175 50 240 56 121 13 633 29 767 24 690
-26 668 -26 750 -9 43 9 115 30 159 46 45 17 104 32 131 34 61 4 85 13 85 31
0 22 31 31 211 59 361 56 529 116 1047 372 240 118 393 209 860 506 206 131
337 209 360 213 60 10 112 47 194 137 55 61 115 111 205 173 149 103 265 214
311 299 19 36 84 116 161 198 78 84 141 162 161 198 20 38 75 104 151 183 152
157 201 219 283 355 36 60 95 143 129 185 44 52 94 135 162 263 54 104 117
218 141 255 24 38 52 85 62 105 29 57 141 336 173 431 15 46 46 110 68 142 40
60 61 129 61 198 0 37 9 59 64 154 19 33 29 70 37 135 6 48 17 138 25 198 7
61 21 184 29 275 8 91 20 197 26 237 11 66 10 75 -11 117 -12 25 -19 54 -16
64 4 10 12 90 20 178 17 187 13 200 -52 222 -26 8 -47 23 -53 36 -8 16 -2 125
18 372 20 234 27 364 21 393 -5 30 -4 56 4 78 10 27 9 48 -5 116 -21 103 -22
185 -1 237 9 23 19 39 21 36 3 -3 20 -62 38 -133 18 -70 34 -121 34 -113 1 8
12 92 26 185 14 94 25 187 25 207 0 21 -15 84 -34 140 -19 57 -57 176 -85 264
-44 139 -57 170 -97 220 -55 70 -74 111 -74 158 0 27 -11 49 -45 91 -25 31
-77 102 -115 158 -88 131 -187 238 -362 388 -261 225 -468 337 -761 413 -155
40 -817 166 -875 166 l-53 0 3 -37 c3 -36 2 -37 -47 -56 -72 -27 -162 -19
-250 22 -37 17 -82 31 -99 31 -75 0 -220 -54 -282 -106 l-29 -24 62 6 c53 6
62 4 71 -13 9 -16 3 -23 -51 -51 -34 -18 -91 -57 -127 -88 -36 -31 -99 -81
-140 -111 -83 -61 -103 -82 -160 -164 -57 -83 -59 -99 -16 -147 l36 -39 -38
-27 c-22 -15 -52 -26 -71 -26 -18 0 -41 -5 -52 -10 -10 -6 -59 -71 -108 -144
-49 -73 -112 -160 -140 -193 -98 -118 -104 -139 -74 -253 l16 -66 -25 -54
c-14 -30 -46 -79 -72 -108 -29 -34 -50 -71 -57 -98 -13 -49 -52 -88 -81 -81
-15 4 -18 -1 -18 -35 0 -26 -13 -65 -41 -119 -50 -96 -48 -113 21 -176 l49
-45 -32 -47 c-32 -47 -40 -98 -23 -161 4 -15 1 -32 -8 -43 -12 -15 -16 -16
-48 -3 l-34 15 -32 -53 c-44 -72 -44 -128 2 -227 34 -74 51 -137 81 -299 20
-106 80 -288 132 -400 110 -239 284 -420 508 -530 147 -73 268 -102 430 -103
154 -1 196 9 262 63 54 45 126 76 201 86 l49 6 -10 29 c-16 42 8 60 135 99
122 38 148 56 148 104 0 30 -5 36 -39 50 -53 21 -62 40 -45 89 18 50 18 88 -1
157 -17 64 -12 85 43 195 65 130 41 241 -63 294 -48 25 -56 26 -234 26 l-184
0 6 201 c8 230 25 346 68 452 l30 75 -16 110 c-9 60 -14 114 -12 121 3 7 25
16 49 20 23 4 108 23 188 42 81 19 156 32 169 29 27 -7 171 -132 237 -206 24
-27 46 -62 50 -77 24 -107 37 -134 91 -193 15 -16 33 -61 49 -119 13 -51 40
-139 59 -194 50 -148 60 -246 45 -438 -11 -139 -11 -173 4 -281 16 -111 16
-137 2 -292 -8 -96 -22 -190 -31 -215 -15 -40 -20 -144 -12 -240 2 -21 -3 -25
-36 -31 -20 -3 -39 -8 -41 -10 -2 -2 -9 -132 -16 -289 -6 -157 -16 -304 -21
-328 -5 -24 -25 -74 -44 -112 -43 -85 -57 -143 -46 -192 13 -58 0 -67 -54 -38
-42 23 -47 24 -73 10 -29 -17 -42 -64 -23 -83 21 -21 10 -33 -89 -91 -112 -66
-167 -120 -300 -291 -47 -61 -175 -200 -285 -311 -278 -278 -447 -403 -766
-564 -188 -95 -263 -124 -385 -149 -114 -23 -116 -25 -104 -57 6 -14 10 -29
10 -33 0 -15 -213 -80 -330 -100 -63 -12 -225 -32 -360 -46 -289 -29 -297 -31
-303 -55 -2 -10 -16 -26 -31 -35 -26 -17 -105 -39 -113 -31 -2 2 -9 33 -15 70
l-11 66 -150 0 -149 0 -49 50 c-53 54 -80 66 -140 56 -31 -5 -44 -2 -60 13
-12 11 -40 23 -63 26 -50 9 -62 18 -86 66 -16 31 -42 50 -142 107 -68 39 -154
85 -193 102 -38 17 -78 39 -89 48 -22 20 -50 72 -42 79 2 3 41 -7 86 -22 75
-25 108 -27 84 -6 -5 4 -85 69 -179 143 -108 86 -207 175 -271 244 -134 144
-176 169 -298 170 l-89 1 -45 78 -44 78 21 44 21 43 -40 80 c-22 44 -40 81
-40 84 0 2 15 9 33 16 17 7 79 35 136 64 l104 51 135 2 c126 2 135 3 150 24 8
12 45 35 81 51 36 17 86 45 111 63 62 45 158 92 216 106 27 6 80 30 118 52 50
30 80 41 110 42 29 0 54 9 86 31 99 68 193 117 343 178 186 76 263 118 352
191 83 68 108 61 34 -9 -254 -239 -352 -337 -342 -347 8 -8 44 12 124 67 188
129 477 430 593 617 71 114 85 171 101 401 14 197 25 280 65 481 23 117 27
188 16 305 -8 84 -8 89 63 376 39 159 71 302 71 316 0 14 -7 39 -15 54 -22 42
-18 105 11 181 17 47 32 123 47 243 42 338 19 1069 -48 1530 -14 96 -34 249
-45 340 -11 101 -27 187 -39 221 -27 72 -98 172 -193 273 -74 80 -74 80 -105
67 -42 -17 -59 -7 -133 82 -87 103 -117 151 -124 201 -4 28 -19 57 -39 82 -18
22 -46 61 -62 88 -39 68 -203 307 -308 448 -69 93 -87 125 -87 151 l0 34 -64
5 c-84 7 -157 47 -211 114 -22 28 -91 121 -152 207 -140 192 -147 227 -39 187
55 -21 56 -21 62 -71 4 -27 9 -49 12 -49 28 0 74 28 79 48 8 33 -5 173 -23
251 -26 114 -110 238 -196 289 -27 16 -28 19 -28 104 0 130 -25 232 -72 291
-21 27 -38 57 -38 68 0 10 13 33 30 52 l29 34 -19 63 c-14 48 -19 92 -18 174
0 79 -4 123 -16 154 -15 41 -15 47 5 104 18 55 30 123 58 343 15 119 30 157
66 167 38 10 55 33 55 74 0 26 7 37 34 53 32 20 60 57 119 161 14 25 63 81
109 126 76 74 90 83 163 107 123 40 190 51 310 50 118 0 203 -16 318 -59 87
-33 102 -54 94 -134 -6 -55 -5 -60 18 -77 17 -12 30 -37 40 -72 7 -29 22 -63
33 -75 19 -21 19 -22 -42 -136 -62 -117 -119 -186 -186 -225 -38 -22 -47 -47
-30 -90 l11 -28 39 38 40 39 0 -139 0 -140 -42 63 -42 64 -29 -59 c-26 -49
-31 -55 -38 -38 -4 11 -8 45 -8 75 -1 56 -17 100 -37 107 -7 2 -28 -22 -48
-54 -34 -54 -37 -62 -33 -118 22 -292 43 -408 93 -507 63 -125 209 -286 310
-340 101 -54 263 -76 312 -42 26 18 29 34 6 34 -8 0 -46 7 -85 16 l-69 16 0
41 c0 59 17 86 75 120 75 43 111 83 134 147 27 73 131 175 114 111 -2 -10 5
-38 17 -62 28 -56 27 -54 45 -39 13 10 24 11 57 1 24 -8 43 -9 47 -3 75 113
76 114 56 128 -29 21 -66 17 -109 -12 -40 -28 -40 -28 -66 17 -8 13 -4 18 20
23 32 6 50 36 50 81 0 25 105 235 124 247 7 5 18 -8 29 -34 l17 -41 35 44 c19
24 35 55 35 69 0 31 26 74 108 182 82 106 165 275 198 403 24 89 27 119 28
275 1 195 -13 286 -65 421 -44 113 -98 190 -228 323 -118 120 -409 362 -535
444 -84 55 -124 70 -314 123 -359 99 -553 142 -647 142 -49 0 -101 -4 -115
-10 -24 -10 -23 -11 30 -36 l55 -25 -86 5 c-104 7 -128 2 -173 -37 -18 -17
-69 -46 -111 -65 -76 -35 -78 -35 -91 -16 -25 35 -70 42 -163 26 -113 -20
-138 -36 -182 -120 l-36 -70 -129 -46 c-71 -26 -130 -46 -132 -44 -1 1 9 23
24 48 l25 46 -53 -15 c-72 -21 -169 -74 -251 -135 -87 -66 -370 -352 -449
-455 -120 -157 -211 -337 -264 -524 -36 -127 -59 -174 -104 -213 -29 -25 -36
-36 -29 -52 15 -39 9 -110 -12 -150 -11 -22 -40 -114 -64 -205 -24 -91 -53
-201 -64 -244 -24 -89 -27 -192 -9 -293 10 -63 10 -72 -11 -118 -36 -79 -36
-138 -2 -375 35 -248 50 -442 50 -656 1 -255 36 -522 98 -739 58 -205 153
-409 415 -895 125 -232 236 -452 273 -541 58 -140 115 -301 140 -395 15 -55
42 -148 62 -209 58 -181 48 -314 -37 -490 -44 -92 -126 -220 -161 -252 -33
-30 -215 -115 -319 -148 -78 -25 -104 -28 -316 -35 -361 -13 -533 -40 -770
-121 -150 -51 -153 -50 -267 79 -272 304 -435 676 -499 1137 -7 50 -12 173
-12 275 1 216 19 326 85 500 23 62 54 177 75 280 20 96 54 230 75 297 69 220
83 305 83 508 0 178 -1 181 -42 337 -55 211 -125 392 -244 638 -55 113 -122
257 -150 320 -62 144 -135 255 -237 358 -108 110 -89 68 -298 647 -215 598
-287 752 -469 995 -150 202 -345 368 -754 642 -215 144 -377 230 -544 287
-123 43 -158 61 -366 196 -196 127 -289 181 -422 244 -205 99 -338 139 -749
227 -363 77 -449 93 -601 109 -181 19 -561 18 -738 -1z m-8845 -254 c-3 -5
-22 -10 -41 -10 -19 0 -34 5 -34 10 0 6 18 10 41 10 24 0 38 -4 34 -10z
m17605 -420 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20
-4 20 -10z m-1049 -373 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1
-17z m-6541 -166 c0 -10 -61 -21 -74 -14 -13 8 16 23 47 23 15 0 27 -4 27 -9z
m-12665 -12 c4 -5 -1 -19 -11 -30 -13 -15 -20 -16 -31 -8 -11 10 -11 15 3 30
19 22 30 24 39 8z m13050 -19 c3 -5 0 -10 -7 -10 -7 0 -31 -3 -53 -6 -38 -5
-39 -4 -21 10 22 18 72 21 81 6z m-638 -150 c5 -26 2 -29 -33 -39 -63 -18 -65
-16 -43 37 12 26 26 58 31 72 l10 25 15 -34 c8 -18 16 -46 20 -61z m-16867
-42 c45 -54 111 -148 119 -171 10 -25 3 -22 -62 32 -61 50 -117 120 -117 145
0 11 49 6 60 -6z m213 -59 c-5 -5 -17 -7 -27 -3 -16 6 -16 8 0 26 16 17 19 17
27 2 5 -10 5 -20 0 -25z m4006 -60 c-25 -19 -49 -25 -49 -11 0 11 54 40 64 34
5 -3 -2 -13 -15 -23z m-4571 -349 c41 -58 106 -142 143 -188 137 -166 226
-361 273 -597 49 -246 70 -448 71 -660 0 -245 -8 -293 -87 -504 -49 -133 -267
-661 -294 -713 l-14 -28 -53 21 c-28 11 -53 22 -55 23 -1 2 25 104 59 227 73
271 101 400 128 584 39 267 52 612 30 772 -12 88 -99 437 -154 618 -50 164
-95 284 -172 454 -36 79 -67 163 -70 185 l-4 41 61 -65 c34 -36 96 -112 138
-170z m17577 190 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
12 -4 9 -10z m-11437 -115 c201 -22 271 -38 375 -87 113 -54 151 -94 145 -152
-6 -46 -145 -375 -243 -571 -108 -217 -200 -308 -420 -415 -139 -68 -149 -70
-171 -29 -18 36 -17 120 6 474 6 88 10 293 8 456 -3 280 -2 298 16 318 24 26
76 27 284 6z m12032 8 c0 -9 -17 -122 -25 -167 l-5 -29 46 42 c64 60 154 93
154 58 0 -8 -16 -63 -36 -123 -39 -118 -53 -225 -36 -272 8 -24 7 -38 -8 -69
-29 -58 -43 -172 -43 -338 0 -130 3 -163 26 -250 15 -55 35 -111 45 -125 34
-46 34 -50 11 -50 -36 0 -143 121 -190 215 -33 67 -41 96 -50 175 -15 140 10
463 42 547 8 22 8 24 -5 14 -9 -7 -24 -36 -34 -64 -36 -95 -47 -192 -46 -407
l1 -205 -74 80 c-125 135 -183 241 -223 409 -73 302 1 509 197 555 47 11 253
14 253 4z m6230 -32 c0 -32 -10 -51 -54 -108 -140 -178 -185 -323 -225 -708
-35 -340 -45 -510 -45 -770 0 -240 1 -260 38 -499 32 -211 36 -256 26 -293
-15 -64 -244 -454 -275 -470 -22 -12 -25 -11 -29 9 -13 56 -35 222 -31 226 2
3 23 0 47 -6 23 -6 45 -9 48 -6 3 3 5 36 5 72 0 75 -26 154 -146 443 -129 313
-154 417 -153 629 1 153 19 264 63 380 11 31 119 254 240 497 148 297 231 454
257 482 33 38 207 161 226 161 5 0 8 -18 8 -39z m-5658 -93 c-10 -22 -11 -21
-11 12 -1 26 2 31 11 22 8 -8 8 -17 0 -34z m-19114 -166 c36 -87 37 -92 18
-92 -8 0 -27 33 -45 78 -35 87 -36 92 -18 92 6 0 27 -35 45 -78z m25022 -81
c-11 -23 -25 -41 -30 -41 -19 0 -10 63 18 113 l27 51 3 -41 c2 -27 -4 -56 -18
-82z m-5457 53 c23 -15 62 -45 87 -69 73 -69 126 -102 210 -130 91 -30 146
-81 172 -156 126 -369 248 -751 248 -775 0 -17 -6 -36 -12 -42 -29 -23 -201
-82 -250 -86 -29 -2 -71 -11 -93 -20 -168 -67 -573 -76 -607 -13 -7 14 -9 96
-5 256 6 266 17 334 102 626 25 88 52 207 60 265 13 96 31 170 41 170 2 0 23
-12 47 -26z m-14234 -171 c7 -60 16 -169 21 -243 11 -169 12 -175 110 -458
l80 -233 -33 -14 c-119 -50 -159 -44 -345 48 -171 84 -206 118 -276 263 -31
63 -59 127 -62 140 -11 43 47 141 146 249 50 55 107 127 127 160 58 95 92 134
147 165 28 17 56 30 62 30 6 0 16 -45 23 -107z m6840 50 c0 -25 -1 -26 -9 -8
-12 27 -12 35 0 35 6 0 10 -12 9 -27z m14507 -62 c-22 -21 -46 0 -26 24 10 12
16 13 27 3 12 -10 12 -13 -1 -27z m-7993 -78 c-1 -23 -9 -54 -17 -70 -15 -27
-15 -26 -16 45 0 65 2 73 18 70 13 -3 17 -13 15 -45z m7917 -21 c0 -23 -8 -33
-40 -51 -36 -20 -60 -21 -60 -3 0 4 14 24 31 45 37 46 69 50 69 9z m-19368
-54 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-1305 -42
c-4 -9 -13 -13 -21 -10 -12 5 -13 10 -5 26 13 24 35 9 26 -16z m18960 -131
c-3 -41 -16 -107 -28 -147 l-23 -72 -14 35 c-21 51 -11 145 23 208 16 28 33
51 38 51 6 0 8 -28 4 -75z m-18977 19 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21
0 8 5 12 10 9 6 -3 10 -13 10 -21z m1258 4 c-6 -18 -28 -21 -28 -4 0 9 7 16
16 16 9 0 14 -5 12 -12z m11507 3 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5
0 11 -4 15 -9z m8055 -37 c0 -9 -10 -14 -27 -14 -25 0 -26 1 -8 20 19 21 35
18 35 -6z m79 -40 c2 -2 -4 -7 -13 -10 -11 -4 -24 0 -34 10 -16 16 -15 17 13
10 17 -4 32 -8 34 -10z m-28159 -19 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0
8 5 15 10 15 6 0 10 -7 10 -15z m27810 6 c0 -5 -7 -11 -15 -15 -9 -3 -15 0
-15 9 0 8 7 15 15 15 8 0 15 -4 15 -9z m-28121 -21 c11 -6 10 -9 -7 -14 -13
-5 -35 1 -59 14 l-38 21 45 -7 c25 -3 51 -10 59 -14z m10690 -25 c138 -24 282
-103 377 -207 120 -132 254 -475 254 -653 0 -96 -30 -145 -145 -233 -33 -25
-109 -92 -169 -149 -129 -121 -177 -145 -294 -146 -85 0 -85 -1 -106 93 -33
145 -39 281 -22 546 12 183 14 290 8 404 -10 178 -4 360 11 360 1 0 40 -7 86
-15z m7785 -5 c64 -25 113 -74 190 -193 107 -164 169 -307 226 -517 21 -80 50
-181 63 -225 13 -43 22 -87 19 -97 -5 -21 -128 -123 -223 -184 -139 -90 -376
-194 -443 -194 -24 0 -26 -4 -26 -39 0 -45 -15 -92 -33 -103 -18 -11 -67 -10
-67 2 0 6 11 33 25 60 14 27 25 67 25 88 0 51 32 98 95 139 81 54 89 75 93
242 4 167 -7 341 -49 716 -16 149 -27 282 -23 298 5 23 11 27 39 27 19 0 59
-9 89 -20z m2291 -27 c-18 -43 -21 -47 -28 -36 -7 12 20 73 33 73 6 0 4 -14
-5 -37z m-413 -19 c3 -46 -3 -61 -22 -49 -16 10 -13 95 4 95 9 0 15 -15 18
-46z m7998 1 c0 -2 -16 -22 -35 -45 l-35 -42 0 31 c0 36 32 73 55 65 8 -4 15
-7 15 -9z m-18486 -67 c3 -18 8 -181 11 -363 9 -512 16 -567 104 -799 22 -60
41 -117 41 -127 0 -28 -38 -47 -131 -64 -201 -38 -286 -27 -416 52 -94 57
-213 160 -229 199 -31 74 -11 175 84 441 56 155 82 265 82 343 0 51 0 52 88
139 97 97 170 150 258 186 83 34 102 33 108 -7z m-2355 15 c0 -5 -4 -17 -9
-28 -8 -18 -9 -17 -9 8 -1 15 4 27 9 27 6 0 10 -3 9 -7z m9908 -167 c-4 -83
-14 -216 -23 -296 -20 -180 -15 -451 11 -550 14 -51 60 -162 92 -221 8 -14
-50 -19 -116 -9 -219 31 -591 211 -729 353 -50 51 -48 69 12 106 55 34 130
122 288 337 81 110 179 233 218 272 81 81 215 173 238 165 13 -5 14 -28 9
-157z m-17307 124 c14 -11 20 -20 14 -20 -7 0 -22 9 -35 20 -31 25 -12 26 21
0z m5570 2 c0 -14 -39 -32 -46 -21 -4 6 0 15 7 20 17 11 39 12 39 1z m-5430
-57 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m7307 9 c13 -4 20 -10 17 -15 -8 -14 -50 -10 -64 6 -10 12 -9 15 6 15 11 0 29
-3 41 -6z m18963 -92 c0 -25 -4 -42 -8 -36 -5 5 -23 28 -41 52 -33 42 -33 43
-15 63 l19 21 22 -27 c17 -19 23 -39 23 -73z m-17225 54 l30 -25 -37 -37 c-21
-21 -39 -36 -41 -33 -6 8 5 119 11 119 4 0 21 -11 37 -24z m18973 -19 c4 -29
-12 -37 -22 -11 -10 26 -7 46 7 42 6 -3 13 -17 15 -31z m-10753 -4 c-12 -62
-16 -73 -24 -69 -8 5 -5 42 4 74 6 19 24 14 20 -5z m10554 -95 c9 -15 12 -30
9 -34 -12 -12 -57 6 -68 26 -25 46 34 54 59 8z m-7314 10 c-2 -7 -9 -33 -16
-58 l-11 -45 -19 24 c-25 31 -24 46 7 70 30 24 45 27 39 9z m7375 -69 c0 -14
-17 -10 -23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z m220 6 c0 -32 -99 -103
-122 -89 -17 11 -6 25 50 58 57 34 72 41 72 31z m-10840 -37 c0 -19 -18 -43
-25 -36 -9 9 4 48 16 48 5 0 9 -6 9 -12z m9011 -10 c6 -12 20 -114 30 -227 16
-188 19 -207 39 -223 12 -10 32 -18 45 -18 30 0 42 -30 18 -48 -37 -26 -91
-36 -134 -23 -56 17 -75 40 -89 106 -15 70 -8 108 25 147 27 32 32 81 15 153
-6 27 -6 59 2 98 11 61 29 73 49 35z m-5941 -16 c0 -13 -12 -22 -22 -16 -10 6
-1 24 13 24 5 0 9 -4 9 -8z m-15301 -96 c9 -11 6 -17 -15 -30 -14 -10 -32 -15
-40 -12 -13 5 -12 9 3 31 20 28 35 31 52 11z m11030 -76 c-15 -20 -26 -27 -33
-20 -7 7 0 20 19 40 36 37 45 23 14 -20z m2785 8 c7 -34 3 -44 -13 -33 -13 7
-15 65 -2 65 5 0 11 -14 15 -32z m-9014 -153 c0 -14 -4 -25 -10 -25 -5 0 -10
11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m7382 8 c46 -53 52 -82 56 -268
l4 -180 -21 27 c-55 69 -66 113 -69 281 -2 93 0 157 6 157 5 0 16 -8 24 -17z
m2078 -237 c0 -14 -4 -28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10
-11 10 -24z m-116 -202 c11 -28 6 -84 -9 -114 -9 -17 -18 -30 -20 -30 -10 0
-35 60 -35 83 0 24 34 77 50 77 4 0 11 -7 14 -16z m-444 -46 c39 -85 20 -120
-83 -154 -29 -9 -101 -41 -161 -71 -101 -49 -114 -53 -181 -53 -39 0 -95 -7
-123 -15 -49 -15 -72 -14 -72 4 0 4 26 18 57 31 31 13 127 66 212 118 131 80
315 182 328 182 2 0 13 -19 23 -42z m-8536 -41 l27 -47 -26 -10 c-58 -22 -70
-6 -54 71 12 56 13 55 53 -14z m-216 -29 c12 -6 22 -14 22 -18 0 -8 -35 -20
-57 -20 -14 0 -37 33 -48 69 l-7 25 33 -23 c19 -12 44 -27 57 -33z m-8678 14
c0 -5 -7 -17 -15 -28 -15 -19 -20 -8 -9 20 6 17 24 22 24 8z m-55 -152 c17
-19 17 -22 3 -37 -25 -24 -46 -11 -40 26 5 36 12 38 37 11z m17855 -21 c0 -11
-4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m-1290
-31 c29 -48 9 -57 -40 -18 -31 24 -38 35 -28 41 25 17 49 9 68 -23z m8680 8
c0 -8 -4 -18 -10 -21 -5 -3 -10 -4 -10 -1 0 2 -3 11 -6 20 -4 11 -1 16 10 16
9 0 16 -6 16 -14z m-24305 -83 c-18 -64 -115 -276 -123 -268 -10 9 31 152 65
225 62 137 91 158 58 43z m5458 -3 c34 -27 47 -76 47 -178 0 -73 -5 -97 -46
-210 -64 -177 -182 -470 -207 -512 -29 -50 -124 -146 -181 -183 l-49 -31 -53
58 c-173 191 -241 537 -148 761 14 33 32 81 40 106 30 96 79 129 194 129 66 0
104 9 305 75 30 10 75 3 98 -15z m11360 -102 c52 -70 52 -73 -4 -102 -48 -26
-51 -26 -78 -10 -28 17 -30 16 -85 -15 -55 -30 -80 -33 -74 -7 5 19 181 165
200 165 9 1 28 -14 41 -31z m1414 -9 c37 -14 90 -65 113 -109 9 -19 30 -93 45
-165 15 -71 44 -204 66 -294 21 -90 39 -176 39 -191 0 -39 -29 -71 -95 -105
-67 -34 -150 -38 -202 -12 -82 43 -144 154 -198 350 -55 206 -88 478 -60 506
25 25 240 40 292 20z m-14875 -64 c-2 -14 -8 -25 -13 -25 -10 0 -12 43 -3 53
13 12 21 -3 16 -28z m20389 -17 c22 -12 39 -24 39 -27 0 -22 -48 -77 -92 -105
-69 -43 -159 -89 -165 -83 -14 14 45 109 105 170 36 37 68 67 70 67 3 0 22
-10 43 -22z m-6896 -110 c-28 -93 -90 -178 -129 -178 -16 0 -66 56 -66 74 0
23 87 97 131 111 64 20 72 19 64 -7z m7340 -545 c51 -65 122 -198 185 -348 51
-120 103 -224 138 -278 l26 -40 -50 -46 c-45 -42 -196 -141 -213 -141 -13 0
-66 81 -193 300 -70 118 -129 229 -132 245 -5 22 0 44 19 78 14 27 25 54 25
61 0 48 36 123 82 172 28 30 55 54 59 54 5 0 29 -26 54 -57z m-12670 -10 c14
-83 48 -178 140 -383 159 -357 256 -523 437 -750 47 -58 151 -193 233 -300
443 -582 651 -809 1030 -1125 323 -270 621 -468 1350 -896 l160 -95 7 -74 c17
-167 85 -332 200 -485 37 -49 68 -100 68 -112 0 -12 -12 -35 -26 -52 -29 -35
-43 -84 -44 -151 0 -68 -18 -120 -54 -156 l-31 -31 19 -33 c19 -32 19 -35 3
-99 -21 -83 -22 -81 6 -81 12 0 32 -3 43 -6 21 -6 19 -11 -38 -145 -34 -76
-64 -139 -69 -139 -4 0 -9 19 -11 43 -3 37 -6 42 -28 42 -30 0 -41 -21 -75
-145 -48 -175 -121 -296 -338 -558 -263 -318 -393 -457 -897 -967 -275 -278
-465 -479 -561 -595 -430 -516 -549 -696 -850 -1285 -97 -191 -155 -316 -168
-365 -11 -41 -77 -336 -146 -655 -159 -732 -176 -808 -182 -817 -2 -4 -32 10
-67 32 -35 22 -66 40 -69 40 -3 0 -13 -45 -23 -100 -35 -198 -48 -166 -55 138
-9 335 -23 431 -125 852 -95 392 -173 623 -341 1010 -164 378 -217 474 -394
720 -236 329 -349 477 -485 640 -215 258 -305 336 -589 514 -77 48 -203 132
-281 186 -124 87 -145 106 -183 165 -48 74 -111 122 -173 131 -20 4 -46 16
-57 28 -12 12 -74 155 -140 317 -143 355 -185 446 -304 658 -71 126 -104 174
-145 212 l-54 49 50 138 c28 75 67 200 87 276 20 76 52 182 71 235 22 61 49
175 75 316 23 121 47 234 54 250 21 52 66 88 383 307 169 116 552 383 852 592
671 469 672 469 1077 988 113 144 117 151 109 200 -5 31 -2 45 14 65 11 15 26
46 32 70 6 24 27 64 48 89 24 29 60 101 100 197 35 84 95 224 133 312 76 175
90 234 61 266 -17 18 -15 29 51 282 37 144 73 265 79 269 20 14 25 9 31 -29z
m-12328 -13 c18 -11 64 -50 102 -88 87 -85 92 -105 55 -222 -35 -111 -64 -167
-111 -212 -59 -57 -97 -68 -272 -79 -164 -11 -244 -4 -322 28 -40 15 -89 64
-89 87 0 21 350 358 430 415 72 51 144 90 165 91 6 0 25 -9 42 -20z m14693
-116 c55 -26 100 -53 100 -60 0 -8 -9 -23 -21 -35 -24 -24 -37 -21 -123 25
-43 22 -62 39 -69 59 -8 26 -4 57 8 57 3 0 50 -21 105 -46z m-8252 -1 c41 -15
42 -16 42 -62 0 -52 -24 -83 -87 -115 -31 -15 -33 -15 -43 3 -13 26 -13 87 2
151 9 40 15 49 27 44 9 -3 35 -13 59 -21z m3166 -25 c58 -17 136 -97 136 -138
0 -34 -38 -80 -65 -80 -29 0 -55 20 -55 44 0 21 -6 24 -102 37 -114 15 -159
16 -458 4 -199 -8 -207 -7 -248 14 -40 20 -51 34 -34 44 7 5 440 57 622 76
126 12 158 12 204 -1z m2961 -66 c16 -54 2 -55 -19 -2 -19 48 -20 53 -4 48 6
-2 17 -23 23 -46z m5325 -251 l101 -204 -56 5 c-106 9 -141 77 -152 293 -3 66
-3 118 0 115 2 -3 51 -97 107 -209z m-11810 164 c36 -19 38 -34 6 -64 -21 -20
-31 -22 -63 -16 -72 13 -82 40 -25 73 45 26 45 26 82 7z m976 -148 c-16 -79
-60 -109 -157 -108 -40 0 -74 3 -76 5 -3 3 35 50 84 106 l88 101 34 -37 c28
-30 32 -41 27 -67z m-8347 -1 c1 -45 -3 -53 -39 -87 -22 -21 -50 -43 -61 -50
-19 -10 -20 -9 -14 13 5 12 28 66 52 118 49 104 61 105 62 6z m13413 -1 c-4
-26 -22 -45 -22 -25 0 12 18 50 24 50 2 0 1 -11 -2 -25z m-5326 3 c11 -17 -46
-59 -75 -56 -32 4 -36 19 -11 46 22 24 74 30 86 10z m-347 -132 c-9 -9 -64
-10 -69 -1 -6 12 52 24 64 13 5 -4 8 -10 5 -12z m10190 -35 c7 -23 15 -49 18
-57 3 -8 -1 -14 -11 -14 -9 0 -18 12 -22 28 -3 15 -10 41 -14 59 -8 31 -2 51
10 36 4 -5 12 -28 19 -52z m-7319 -4 c80 0 213 2 295 4 163 4 225 -3 225 -27
0 -8 -7 -46 -16 -84 -12 -54 -45 -121 -163 -327 -178 -312 -207 -356 -308
-463 -108 -115 -437 -387 -486 -402 -34 -10 -58 -7 -218 33 -173 42 -180 45
-199 77 -14 23 -27 32 -45 32 -26 0 -26 0 -19 58 3 31 8 59 10 61 2 2 -14 12
-35 22 -57 25 -71 53 -78 156 l-5 88 28 17 c65 37 81 66 124 223 23 83 53 171
67 197 63 117 196 242 312 296 99 45 137 52 256 45 61 -4 175 -7 255 -6z
m-10772 -26 c4 -25 -17 -56 -30 -43 -12 12 2 74 16 69 6 -2 12 -14 14 -26z
m17400 -12 c78 -13 167 -56 207 -100 36 -40 44 -61 95 -260 28 -111 35 -126
51 -122 15 4 19 15 19 48 0 107 13 225 26 225 27 0 115 -82 141 -133 27 -51
28 -56 25 -192 -2 -77 2 -193 9 -258 l13 -117 -31 -33 c-32 -33 -43 -79 -73
-292 -17 -119 -14 -115 -71 -115 l-49 0 0 34 c0 19 -4 46 -9 59 -11 28 -49 67
-66 67 -6 0 -27 -27 -46 -60 -18 -33 -38 -60 -43 -60 -23 0 -195 101 -304 178
-199 143 -329 250 -460 379 -118 118 -152 166 -152 215 0 13 24 64 53 113 29
50 78 137 108 194 84 161 147 221 252 240 51 9 231 3 305 -10z m531 -148 c-1
-14 -4 -37 -8 -51 l-7 -25 -13 24 c-9 15 -11 39 -6 69 6 43 8 45 21 27 8 -10
14 -30 13 -44z m-4475 28 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15
3 5 -3 7 -10 3 -15z m4835 -73 c1 -12 -28 14 -40 35 -5 10 -6 26 -2 35 5 14
10 10 24 -22 10 -21 18 -43 18 -48z m-13276 -408 c-63 -258 -78 -306 -99 -325
-45 -41 -54 -32 -54 55 0 106 19 178 103 389 56 143 76 183 92 185 11 2 23 3
26 1 3 -1 -27 -139 -68 -305z m21135 283 c4 -28 -13 -20 -21 9 -3 14 -1 20 6
17 7 -2 14 -14 15 -26z m-8023 -67 c10 -10 13 -24 9 -38 -9 -29 -6 -28 -60
-24 l-48 3 33 38 c37 42 43 44 66 21z m-4745 -11 c0 -5 -5 -15 -10 -23 -8 -12
-10 -11 -10 8 0 12 5 22 10 22 6 0 10 -3 10 -7z m-7376 -18 c3 -10 -13 -25
-55 -50 -33 -19 -62 -35 -65 -35 -2 0 2 13 11 30 8 16 32 39 53 49 45 24 48
25 56 6z m382 -51 c37 -18 124 -108 124 -129 0 -7 -9 -19 -21 -26 -20 -14 -20
-15 20 -56 38 -38 41 -46 41 -96 0 -38 9 -79 30 -131 33 -81 46 -167 53 -347
4 -100 4 -106 -17 -115 -16 -7 -21 -18 -21 -45 0 -38 -24 -74 -55 -84 -15 -5
-18 -28 -23 -193 -4 -103 -14 -239 -22 -302 -9 -73 -15 -198 -15 -342 0 -125
-4 -238 -9 -251 -6 -16 -66 -64 -165 -131 -148 -102 -158 -107 -193 -100 -77
14 -170 68 -279 160 -28 23 -78 58 -111 78 -85 50 -182 157 -232 255 -85 171
-137 396 -125 536 9 97 26 211 32 222 4 5 45 34 92 63 170 106 321 213 350
248 17 20 37 57 46 82 33 94 44 109 109 139 92 44 119 83 222 329 l14 33 -37
-7 c-31 -6 -40 -3 -53 15 -13 16 -31 24 -74 28 -47 5 -57 9 -57 24 0 27 42 51
74 43 22 -5 35 3 86 50 85 78 133 89 216 50z m-1232 -41 c-9 -35 -13 -43 -25
-43 -12 0 -11 12 1 44 13 35 32 34 24 -1z m438 -15 c-7 -7 -12 -8 -12 -2 0 14
12 26 19 19 2 -3 -1 -11 -7 -17z m15938 -248 c117 -171 219 -288 430 -492 118
-114 199 -200 214 -229 14 -25 40 -106 57 -180 70 -305 102 -395 175 -494 36
-49 64 -112 64 -143 0 -25 -39 -70 -244 -284 -134 -140 -268 -275 -298 -301
-70 -59 -157 -112 -222 -133 -28 -9 -69 -26 -91 -37 l-39 -20 -92 96 c-112
116 -168 218 -184 334 -5 39 -27 140 -49 225 -70 273 -78 462 -27 655 55 207
58 348 11 543 -46 190 -44 406 5 517 10 24 42 66 70 94 l52 52 45 -44 c25 -24
80 -96 123 -159z m257 159 c7 -13 13 -36 13 -52 l0 -27 -37 19 c-40 20 -66 45
-76 73 -4 13 1 18 21 20 39 5 65 -6 79 -33z m297 -22 c23 -34 32 -107 15 -118
-30 -19 -46 -10 -41 21 2 16 -3 48 -12 70 -23 57 -21 74 4 60 11 -6 27 -21 34
-33z m-16344 -1 c0 -16 -34 -48 -43 -40 -9 10 22 66 33 59 6 -4 10 -12 10 -19z
m2973 -24 c120 -53 196 -174 238 -381 10 -53 32 -121 48 -151 15 -30 42 -82
60 -114 36 -69 41 -145 15 -223 -13 -37 -15 -71 -11 -150 l5 -101 -77 -25
c-143 -48 -307 -64 -478 -47 -145 13 -225 39 -316 100 -75 50 -110 60 -148 40
-20 -11 -14 -21 120 -184 14 -17 14 -19 1 -14 -53 19 -60 19 -60 4 0 -12 10
-16 35 -16 28 0 37 -5 50 -30 8 -16 15 -34 15 -40 0 -19 -49 -10 -173 33 -160
56 -229 102 -277 184 -19 34 -60 88 -92 120 -36 39 -61 75 -69 103 l-13 43 37
34 37 34 -69 157 c-112 250 -112 293 -5 217 34 -25 68 -45 74 -45 7 0 27 7 46
16 34 16 74 81 74 121 0 53 59 92 245 161 61 22 173 64 250 93 200 75 258 90
333 84 34 -2 82 -13 105 -23z m11513 13 c31 -21 46 -65 42 -125 l-3 -53 -47
71 c-43 64 -47 74 -36 97 13 29 16 30 44 10z m-19074 -26 c124 -29 120 -19
118 -281 -2 -252 -9 -297 -55 -360 -64 -87 -279 -202 -447 -238 -74 -17 -107
-4 -161 64 -81 103 -119 239 -99 356 13 70 50 158 90 209 15 20 35 54 45 76
35 77 95 121 237 171 82 29 156 30 272 3z m3951 -10 c-7 -64 -15 -89 -30 -89
-12 0 -14 7 -9 38 11 59 17 73 29 69 7 -2 11 -10 10 -18z m16908 -102 c-25
-17 -26 -17 -49 9 -24 28 -23 48 5 93 l16 25 27 -55 28 -54 -27 -18z m-16036
93 c-11 -11 -36 -22 -55 -26 l-35 -6 28 26 c16 14 40 26 55 26 26 0 26 0 7
-20z m-6075 -17 c0 -10 -4 -24 -9 -32 -12 -19 -24 3 -16 29 8 25 25 26 25 3z
m19194 -34 c60 -22 142 -47 183 -55 99 -19 271 -18 374 2 52 10 109 14 149 11
91 -8 198 -48 236 -90 30 -32 32 -41 39 -133 4 -54 5 -148 1 -210 -6 -108 -8
-113 -39 -146 -30 -32 -95 -179 -113 -253 -15 -68 -91 -162 -155 -195 -20 -11
-86 -36 -145 -56 -144 -47 -207 -45 -341 13 -143 63 -237 127 -349 238 -151
149 -208 252 -207 369 1 63 89 420 118 476 22 43 63 70 105 70 20 0 85 -18
144 -41z m-13240 -1 c-25 -42 -89 -107 -97 -99 -21 21 -2 56 48 83 58 32 59
32 49 16z m12578 -40 c-12 -12 -35 -1 -27 12 3 5 13 6 21 3 10 -4 12 -9 6 -15z
m8779 3 c40 -12 93 -60 116 -105 25 -49 62 -250 75 -408 13 -161 4 -268 -27
-314 -9 -14 -46 -40 -83 -57 -117 -54 -243 -39 -372 45 -79 52 -85 68 -70 215
15 143 9 261 -22 446 -24 151 -25 149 73 178 50 15 87 18 169 15 58 -2 121 -9
141 -15z m-21643 -66 c-6 -14 -15 -25 -20 -25 -11 0 -10 7 2 31 16 28 32 23
18 -6z m16546 -40 c-3 -14 -18 -34 -31 -45 -26 -20 -93 -28 -93 -11 0 5 26 32
57 60 45 41 59 48 65 36 5 -8 6 -26 2 -40z m-16029 16 c7 -12 -12 -24 -25 -16
-11 7 -4 25 10 25 5 0 11 -4 15 -9z m-1181 -271 c0 -136 -16 -190 -70 -241
l-25 -23 6 54 c3 30 10 111 15 178 11 138 20 165 53 160 21 -3 22 -7 21 -128z
m25062 105 c-22 -17 -36 -19 -36 -7 0 14 12 22 34 22 21 -1 21 -1 2 -15z
m-3339 -37 c37 -35 44 -83 19 -127 l-13 -24 -16 24 c-31 47 -48 101 -42 130 4
16 9 29 13 29 3 0 21 -14 39 -32z m-21917 -174 c0 -79 -20 -167 -42 -181 -8
-4 -9 17 -5 68 4 41 10 115 13 164 7 85 8 88 20 60 7 -16 13 -66 14 -111z
m4860 102 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m-9715 -275 c8 -83 -3 -89 -31 -16 -15 39 -15 51 -4 88 10 35 14 39 20
22 4 -11 11 -53 15 -94z m9509 35 c-19 -59 -34 -65 -34 -14 0 28 37 85 47 74
3 -2 -3 -29 -13 -60z m8636 25 c6 -16 10 -40 8 -53 l-4 -23 -11 25 c-17 41
-24 80 -14 80 5 0 14 -13 21 -29z m-10425 -16 c22 -13 48 -32 58 -43 19 -20
18 -21 -26 -27 -77 -12 -117 13 -117 71 0 32 2 35 23 29 12 -4 40 -17 62 -30z
m18664 -52 c1 -23 -21 -66 -81 -163 -74 -118 -83 -129 -91 -108 -22 58 8 136
103 271 59 84 66 84 69 0z m3449 -4 c-2 -5 -17 -19 -35 -30 -26 -17 -31 -17
-36 -4 -4 9 3 20 21 29 32 18 55 20 50 5z m-6426 -164 c115 -215 164 -280 311
-418 119 -112 257 -266 257 -288 0 -14 -38 -49 -53 -49 -33 0 -115 70 -264
226 -231 242 -253 272 -278 388 -7 33 -26 102 -43 151 -43 130 -46 147 -27
143 9 -2 52 -69 97 -153z m-16586 48 c-7 -80 -38 -184 -52 -171 -2 3 3 62 13
132 13 95 21 126 31 126 12 0 14 -15 8 -87z m21004 37 c0 -21 -16 -35 -24 -22
-8 13 5 53 15 46 5 -3 9 -14 9 -24z m-18326 -188 c6 -19 4 -22 -18 -22 -33 0
-37 9 -24 59 l11 43 12 -29 c7 -16 16 -39 19 -51z m17020 -97 c-35 -47 -61
-72 -66 -67 -14 14 25 84 62 116 60 50 62 28 4 -49z m-18967 48 c30 -3 56 -10
59 -15 8 -13 -137 -308 -151 -308 -6 0 -22 11 -35 25 -22 23 -22 25 -6 59 12
25 16 63 16 146 l0 113 32 -7 c18 -3 56 -9 85 -13z m21841 -15 c-9 -13 -26
-24 -39 -26 -23 -2 -23 -2 6 23 37 31 52 32 33 3z m-9458 -116 c0 -4 -16 -23
-35 -42 -42 -40 -44 -62 -10 -102 20 -23 23 -33 15 -49 -6 -10 -13 -19 -16
-19 -7 0 -154 103 -151 106 11 11 183 114 189 114 4 0 8 -4 8 -8z m-16800 -37
c30 -9 73 -20 95 -26 57 -14 62 -25 25 -44 -25 -13 -46 -15 -82 -11 -82 11
-87 18 -32 37 49 17 49 17 14 18 -19 0 -107 -8 -196 -18 -184 -22 -171 -16
-394 -159 -85 -55 -156 -96 -158 -91 -2 5 9 31 24 59 57 105 207 195 389 235
84 19 248 18 315 0z m16850 -65 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7
10 15 10 8 0 15 -4 15 -10z m-12553 -96 c28 -7 28 -8 6 -45 -21 -35 -32 -36
-67 -4 -23 22 -25 28 -15 47 24 46 32 52 43 29 5 -12 20 -24 33 -27z m-4047
26 c8 -5 12 -12 8 -15 -9 -9 -48 4 -48 16 0 12 21 11 40 -1z m-141 -34 c25 -7
47 -18 49 -24 5 -16 -5 -15 -64 4 -90 30 -77 48 15 20z m16765 -25 c7 -10 -17
-21 -42 -21 -15 1 -14 3 2 15 23 17 33 19 40 6z m-16351 -41 c-3 -11 -9 -20
-13 -20 -12 0 -20 28 -14 45 9 23 33 -1 27 -25z m-2254 -1 c6 -18 11 -52 11
-78 0 -45 -7 -62 -156 -378 -19 -39 -49 -87 -67 -105 -29 -31 -229 -163 -450
-298 l-88 -53 -35 34 c-19 18 -47 58 -62 89 -23 47 -27 68 -27 140 0 82 2 89
48 179 26 52 46 95 44 97 -1 1 -27 -27 -57 -63 -51 -61 -55 -64 -61 -42 -24
84 6 145 103 216 39 29 73 51 76 49 3 -4 -12 -49 -44 -130 -5 -13 23 2 82 44
137 96 245 165 315 201 68 34 313 127 341 128 9 1 20 -12 27 -30z m2189 12
c10 -6 8 -9 -10 -13 -28 -5 -48 0 -48 13 0 11 40 12 58 0z m211 -85 c14 -14
22 -29 18 -32 -4 -4 -32 -2 -63 5 -50 12 -58 11 -66 -3 -5 -8 -15 -13 -23 -10
-20 8 -19 24 0 24 14 0 14 3 0 24 -16 24 -16 25 12 28 46 5 94 -9 122 -36z
m22216 -65 c3 -5 -31 -42 -76 -83 -74 -67 -80 -76 -77 -106 l4 -33 -50 6 c-28
4 -57 11 -65 15 -23 12 58 90 138 135 39 21 78 47 88 57 18 20 29 23 38 9z
m-18625 -91 c8 -43 1 -50 -24 -24 -19 18 -19 17 -4 49 14 31 18 27 28 -25z
m20019 15 c47 -24 76 -67 126 -187 26 -62 66 -148 87 -190 52 -100 59 -171 29
-282 -27 -98 -50 -142 -98 -187 -108 -103 -227 -104 -375 -2 -68 46 -79 50
-73 31 17 -55 74 -105 179 -155 20 -9 36 -21 36 -26 0 -11 -120 0 -186 19 -84
23 -125 68 -152 167 l-11 39 50 -4 c27 -1 49 -1 49 1 0 2 -32 53 -71 113 -60
93 -128 222 -161 307 l-9 25 -19 -30 c-27 -40 -25 -58 14 -179 23 -70 29 -102
20 -97 -32 18 -155 136 -168 160 -13 25 -76 274 -76 299 0 19 30 43 55 43 17
0 30 11 47 42 52 93 105 106 436 107 195 1 249 -2 271 -14z m-8437 -72 c21
-20 38 -40 38 -44 0 -7 -148 38 -159 48 -3 2 4 10 15 18 32 25 65 18 106 -22z
m-17482 -39 c0 -26 -6 -46 -17 -57 -19 -17 -110 -43 -119 -34 -10 10 18 51 69
104 56 58 67 56 67 -13z m-2757 -43 c28 -16 83 -39 121 -51 83 -27 147 -81
172 -145 14 -38 15 -53 4 -117 -15 -91 -39 -155 -75 -202 l-28 -37 42 7 c22 4
41 4 41 0 0 -18 -54 -108 -97 -160 -85 -103 -84 -102 -25 -72 68 35 78 33 86
-11 12 -76 7 -103 -23 -134 -47 -46 -78 -40 -162 32 -76 66 -78 79 -6 79 20 0
37 3 37 6 0 3 -5 25 -11 50 -10 39 -14 44 -33 38 -46 -14 -65 -33 -68 -71 l-3
-38 -37 37 -38 36 0 224 c-1 203 -9 343 -26 453 -5 35 -2 46 21 73 15 18 33
32 41 32 8 0 38 -13 67 -29z m33347 1 c31 -15 80 -72 80 -93 0 -16 -42 -31
-87 -32 -65 0 -126 51 -139 119 l-6 31 59 -5 c32 -2 74 -11 93 -20z m-2449 -3
c16 -30 5 -91 -30 -174 -42 -98 -69 -247 -80 -428 -10 -165 -25 -222 -70 -261
-17 -15 -21 -30 -21 -77 0 -54 -30 -160 -51 -183 -12 -13 -30 4 -134 133 -111
139 -144 171 -211 208 l-51 28 17 85 c33 157 72 215 198 296 117 76 187 136
201 176 41 108 77 154 153 192 63 31 64 31 79 5z m-6381 -120 c17 -31 7 -33
-20 -4 -19 20 -20 25 -7 25 9 0 21 -9 27 -21z m8980 -89 c0 -5 -5 -10 -11 -10
-5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-34189 -42 c32 -119 28 -187
-11 -223 -20 -18 -20 -17 -20 32 0 62 12 233 16 233 2 0 8 -19 15 -42z m24905
-12 c11 -12 37 -33 57 -49 68 -52 67 -60 -20 -101 l-77 -37 -37 41 c-31 34
-43 40 -77 40 l-41 0 19 -67 c10 -38 16 -71 12 -75 -7 -7 -202 -16 -202 -9 0
13 178 236 208 261 45 37 112 54 127 32 6 -9 19 -25 31 -36z m-27856 -221 c14
-282 19 -336 46 -494 14 -79 23 -146 20 -148 -9 -9 -67 125 -85 199 -47 184
-53 483 -14 663 l6 30 9 -30 c4 -16 12 -115 18 -220z m37140 165 c0 -5 -7 -7
-15 -4 -8 4 -12 10 -9 15 6 11 24 2 24 -11z m50 -56 c0 -32 -4 -44 -12 -42
-14 5 -23 51 -14 74 11 30 26 12 26 -32z m-36455 -30 c111 -36 173 -68 223
-117 52 -50 110 -156 92 -167 -9 -5 -17 -77 -26 -221 -22 -353 -32 -422 -75
-518 -44 -97 -130 -207 -174 -221 -46 -15 -164 3 -199 31 -31 25 -38 47 -78
254 -28 146 -32 190 -31 315 1 209 51 585 84 640 16 26 25 30 62 30 23 0 78
-12 122 -26z m26695 6 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10
9 0 16 -4 16 -10z m-27560 -22 c0 -2 22 -183 49 -403 28 -220 48 -401 47 -403
-7 -6 -48 103 -66 175 -24 90 -37 229 -45 461 -3 113 -2 172 5 172 5 0 10 -1
10 -2z m2990 -51 c0 -13 -6 -32 -14 -42 -13 -18 -14 -18 -20 3 -8 23 8 62 24
62 6 0 10 -10 10 -23z m34460 -53 c58 -32 69 -42 79 -74 7 -23 25 -48 45 -64
49 -37 121 -186 117 -242 -1 -22 5 -70 14 -105 10 -35 18 -102 18 -149 2 -78
-1 -90 -29 -141 -27 -51 -29 -59 -18 -80 18 -33 17 -74 -1 -89 -14 -11 -23 -7
-60 30 l-45 44 0 -31 c0 -56 -16 -83 -49 -83 -27 0 -34 -7 -67 -71 -53 -101
-121 -171 -206 -214 -97 -48 -112 -47 -128 13 -32 125 -41 151 -60 175 -11 14
-30 29 -41 32 -20 7 -19 9 16 45 30 31 36 43 33 71 l-3 34 -57 -3 -58 -4 25
57 c14 31 39 74 56 95 40 50 45 96 25 230 -8 58 -18 148 -21 200 -3 52 -8 132
-11 178 l-5 83 35 14 c22 10 69 15 126 15 98 0 150 15 172 49 7 12 17 21 22
21 5 0 39 -16 76 -36z m-34245 -61 c7 -56 3 -63 -27 -36 -21 19 -23 60 -6 98
11 24 13 25 19 8 3 -10 10 -42 14 -70z m-1240 48 c3 -5 1 -12 -5 -16 -5 -3
-10 1 -10 9 0 18 6 21 15 7z m25602 -9 c15 -10 5 -92 -12 -92 -31 0 -75 23
-75 39 0 19 24 44 50 54 21 8 24 8 37 -1z m-21534 -18 c3 -4 -15 -31 -40 -61
-30 -35 -50 -50 -56 -44 -6 6 5 27 33 60 41 50 51 57 63 45z m31637 -4 c0 -5
-12 -10 -27 -10 -22 0 -25 2 -13 10 20 13 40 13 40 0z m-6940 -19 c0 -8 -52
-31 -71 -31 -24 0 -4 19 29 28 20 5 38 10 40 11 1 1 2 -3 2 -8z m-18070 -52
c0 -16 -19 -9 -30 10 -17 33 -11 48 10 22 11 -13 20 -27 20 -32z m-170 -24
l44 -45 -61 0 c-33 0 -75 3 -93 7 l-33 6 44 38 c24 21 46 39 49 39 3 0 26 -20
50 -45z m18568 21 c15 -8 51 -39 81 -69 l53 -55 -58 -6 c-33 -3 -95 -8 -139
-11 -44 -3 -102 -8 -130 -12 -47 -5 -49 -4 -35 12 8 10 44 31 80 47 71 31 90
50 90 85 0 27 18 30 58 9z m-23808 -44 c115 -43 184 -93 314 -225 103 -106
116 -122 96 -125 -28 -5 -100 22 -135 50 -15 11 -37 44 -49 73 -28 63 -25 61
-266 116 -102 23 -188 44 -192 48 -4 3 16 25 45 49 63 51 84 52 187 14z m8514
-241 c-26 -105 -81 -242 -103 -255 -8 -5 -11 15 -11 69 0 73 -2 79 -30 105
l-30 28 97 140 98 139 3 -59 c2 -40 -6 -95 -24 -167z m14956 219 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-18412 -35 c-10
-23 -28 -34 -28 -18 0 7 31 43 36 43 2 0 -2 -11 -8 -25z m18431 -51 c-29 -31
-81 -59 -128 -68 -38 -7 -131 3 -131 14 0 14 78 40 170 56 52 8 97 17 99 20 2
2 7 4 9 4 3 0 -5 -12 -19 -26z m504 -2 c23 -16 81 -52 129 -81 117 -70 131
-95 157 -268 20 -136 26 -155 71 -213 74 -96 77 -142 16 -277 -24 -54 -60
-137 -80 -184 -45 -104 -73 -135 -113 -124 -15 3 -44 9 -65 12 -31 4 -37 2
-43 -17 -9 -28 -62 -60 -102 -60 -17 0 -127 30 -245 66 -391 120 -836 234
-915 234 -77 0 -104 -21 -206 -158 -52 -70 -117 -161 -146 -203 -29 -42 -60
-84 -70 -93 -20 -18 -196 -94 -296 -128 -38 -12 -71 -32 -90 -53 -24 -26 -54
-40 -145 -70 -110 -36 -119 -37 -210 -31 -52 3 -118 9 -147 12 l-51 7 -85 181
-85 181 108 105 c111 109 197 212 281 336 110 163 229 277 345 331 50 23 75
28 169 31 114 4 208 -8 370 -49 116 -30 234 -35 331 -15 183 37 304 109 535
321 104 95 153 120 359 181 96 29 176 52 178 53 2 1 22 -12 45 -27z m-24131
-61 c24 -12 86 -58 138 -102 193 -165 306 -220 450 -221 61 -1 88 5 140 27
156 67 226 85 356 95 69 5 129 8 133 6 4 -3 135 -7 291 -11 309 -6 326 -9 398
-70 40 -33 304 -340 355 -411 79 -112 130 -246 148 -394 12 -105 -2 -179 -52
-266 -69 -121 -158 -180 -236 -156 -58 18 -149 76 -248 158 -111 93 -252 188
-365 244 -47 23 -151 81 -232 129 -139 81 -148 88 -153 121 -13 80 -69 150
-121 150 -10 0 -45 -8 -78 -17 -73 -20 -126 -70 -175 -164 -60 -114 -56 -111
-330 -191 -192 -56 -215 -61 -334 -65 -142 -6 -260 8 -318 38 -74 37 -155 164
-180 280 -18 81 -5 193 46 409 63 270 117 378 210 422 48 22 96 19 157 -11z
m27868 -186 c0 -49 -3 -65 -14 -65 -11 0 -13 10 -9 43 11 105 23 116 23 22z
m-7740 34 c-7 -11 -14 -18 -17 -15 -8 8 5 36 17 36 7 0 7 -6 0 -21z m3015 11
c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z
m-26447 -22 c7 -7 12 -18 12 -25 0 -10 -6 -10 -25 3 -14 9 -22 20 -19 25 7 12
18 11 32 -3z m11762 -36 c0 -16 -33 -107 -42 -117 -15 -17 -39 26 -36 65 2 25
10 38 28 47 29 15 50 17 50 5z m21440 -52 c25 -49 25 -50 -3 -50 -53 0 -88 57
-49 79 26 16 30 14 52 -29z m-12520 4 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21
0 8 5 12 10 9 6 -3 10 -13 10 -21z m2595 -14 c-5 -8 -11 -8 -17 -2 -6 6 -7 16
-3 22 5 8 11 8 17 2 6 -6 7 -16 3 -22z m-13090 -13 c85 -30 231 -163 265 -240
56 -126 24 -351 -86 -602 -75 -171 -95 -185 -215 -149 -165 49 -301 179 -395
377 -47 101 -60 173 -40 240 16 53 139 242 199 305 74 78 176 104 272 69z
m-11431 -4 c10 -7 -33 -42 -52 -43 -10 0 -32 38 -32 57 0 6 70 -6 84 -14z
m12988 -199 c0 -60 -16 -134 -30 -134 -11 0 -60 82 -67 112 -8 32 7 65 49 100
l31 27 8 -22 c5 -12 9 -50 9 -83z m7478 61 c0 -6 -12 -22 -26 -36 l-27 -24 6
40 c4 22 7 46 7 54 0 11 5 10 20 -4 11 -10 20 -23 20 -30z m-420 9 c14 -36 12
-89 -3 -127 -13 -30 -94 -113 -103 -105 -6 7 80 258 89 258 4 0 12 -12 17 -26z
m-22361 -76 c27 -123 -4 -118 -35 6 l-22 88 23 -22 c14 -13 28 -43 34 -72z
m22501 11 c0 -40 -14 -81 -37 -105 -13 -14 -26 -24 -29 -21 -6 5 24 154 34
171 7 12 32 -23 32 -45z m-7100 1 c0 -29 -4 -40 -15 -40 -10 0 -15 10 -15 33
0 19 3 37 7 40 15 16 23 5 23 -33z m8600 0 c56 -6 106 -6 148 0 69 12 83 7
123 -37 29 -32 190 -300 214 -356 49 -117 -26 -299 -141 -343 -79 -30 -243 1
-343 65 -27 18 -86 70 -131 117 -126 131 -163 228 -157 409 3 102 15 130 60
151 33 15 56 14 227 -6z m-20274 -107 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2
-10 1 -21 -2 -24z m20783 -122 l21 -40 -21 -6 c-42 -14 -80 58 -64 122 l6 26
19 -31 c11 -17 28 -49 39 -71z m7497 84 c7 -17 -21 -65 -36 -65 -14 0 -12 54
2 68 16 16 27 15 34 -3z m3214 -67 l0 -51 -25 23 c-31 29 -31 44 -3 64 12 9
24 16 25 16 2 0 3 -23 3 -52z m-21030 32 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11
10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m8267 -78 c-14 -18 -25 8 -26 58 l0 35
18 -40 c13 -29 15 -44 8 -53z m-22417 68 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m3667 -47 c-3 -10 -5 -4 -5 12 0 17 2 24
5 18 2 -7 2 -21 0 -30z m-3607 -67 c0 -5 -7 -3 -15 4 -8 7 -12 17 -9 22 7 10
24 -9 24 -26z m22480 30 c0 -2 -10 -35 -21 -72 -15 -48 -20 -83 -16 -115 5
-44 2 -53 -51 -138 l-56 -91 -52 0 -52 0 -7 -57 c-12 -99 -42 -176 -86 -227
-52 -59 -69 -60 -69 -3 0 66 16 108 60 152 40 41 60 86 60 137 0 17 7 29 19
33 36 11 101 65 126 104 13 22 32 68 41 103 8 35 20 80 25 100 10 39 43 78 66
78 7 0 13 -2 13 -4z m-140 -45 c0 -46 -1 -46 -58 -56 -43 -7 -44 -7 -40 21 2
19 14 36 37 51 49 33 61 30 61 -16z m-11170 15 c0 -15 -21 -26 -52 -26 -27 0
-18 23 13 31 36 10 39 10 39 -5z m2940 -16 c0 -30 -13 -40 -25 -20 -8 13 4 50
16 50 5 0 9 -13 9 -30z m46 -45 c7 -17 -4 -70 -12 -62 -3 3 -9 22 -14 41 -6
28 -5 36 6 36 8 0 17 -7 20 -15z m23482 -45 c3 -30 -19 -70 -40 -70 -4 0 -8
30 -8 66 l0 66 23 -14 c13 -10 23 -28 25 -48z m-31454 -92 c49 -48 87 -91 83
-94 -7 -7 -64 22 -109 58 -32 25 -108 125 -108 143 0 16 53 -26 134 -107z
m21756 38 c0 -16 -28 -42 -36 -33 -8 7 14 47 26 47 6 0 10 -6 10 -14z m-5636
-5 c5 -8 -35 -31 -54 -31 -19 0 -10 21 13 30 29 11 35 12 41 1z m-9381 -133
l25 -68 -23 0 c-15 0 -39 16 -65 42 l-40 42 32 33 c18 18 35 30 39 26 3 -5 18
-38 32 -75z m21812 42 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m-12213 -35 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9
5 15 11 13 6 -2 11 -8 11 -13z m12207 -59 c-7 -7 -123 -91 -136 -100 -25 -15
-12 15 23 53 19 22 45 42 57 44 32 6 61 8 56 3z m-21664 -63 c83 -100 202
-196 315 -253 68 -35 80 -60 27 -60 -55 0 -133 28 -183 66 -54 42 -73 49 -174
65 -93 14 -97 16 -71 40 27 25 26 51 0 115 -25 59 -20 84 14 84 18 0 38 -17
72 -57z m2055 -33 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6
0 10 -9 10 -20z m-1092 -66 c12 -4 22 -13 22 -21 0 -12 -7 -14 -31 -9 -17 3
-38 6 -46 6 -13 0 -13 3 -3 15 14 16 23 18 58 9z m-5563 -24 c3 -5 2 -10 -4
-10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m6074 -131 c1 -58
-12 -74 -76 -99 l-53 -20 0 21 c0 26 22 69 74 142 41 59 41 59 47 30 4 -15 7
-49 8 -74z m-6083 44 c21 -28 16 -36 -10 -16 -13 10 -22 21 -19 26 7 11 15 8
29 -10z m-3084 -71 c-16 -5 -50 16 -62 39 -10 17 -6 17 31 -8 23 -15 37 -29
31 -31z m83 -90 c-11 -11 -55 30 -55 51 1 20 3 19 30 -12 16 -19 28 -37 25
-39z m9675 27 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10
-10 10 -16z m-6857 -6 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m13825 -39 c22 -39 -59 -184 -103 -184 -8 0 -22 -12 -32 -26 l-17 -27 -8 28
c-10 38 9 88 63 162 45 64 79 80 97 47z m5996 10 c23 -9 20 -23 -4 -17 -11 3
-20 9 -20 14 0 11 3 11 24 3z m-19654 -65 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10
16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m21499 -43 c24 -13 40 -25 35 -28 -18
-10 -184 -41 -190 -35 -7 6 12 44 34 70 21 23 68 21 121 -7z m7067 -76 l63 0
-46 -97 c-25 -54 -47 -100 -49 -102 -2 -2 -21 9 -42 26 -38 31 -39 31 -51 11
-11 -18 -15 -19 -27 -7 -14 15 -34 101 -34 152 l0 30 61 -7 c34 -3 90 -6 125
-6z m-14917 -232 c14 -24 13 -31 -5 -75 -19 -46 -22 -48 -54 -45 -19 2 -61 4
-94 5 -52 2 -60 5 -68 26 -13 36 -4 50 48 72 l45 20 -26 35 c-14 19 -23 38
-20 44 10 16 158 -53 174 -82z m-9434 -166 c-7 -8 -85 40 -99 60 -9 12 -16 38
-16 56 l0 34 59 -73 c33 -41 58 -75 56 -77z m-11307 7 c-34 -60 -47 -68 -54
-30 -9 43 -8 45 29 58 49 18 50 16 25 -28z m522 -10 c0 -9 -7 -22 -15 -29 -12
-10 -15 -9 -15 6 0 11 3 30 7 42 4 18 8 20 14 10 5 -7 9 -20 9 -29z m35030
-28 c-8 -15 -21 -21 -45 -21 l-34 0 42 39 c36 34 42 37 45 21 2 -10 -2 -28 -8
-39z m-35700 -11 c0 -16 -4 -30 -9 -30 -5 0 -11 14 -13 30 -3 20 0 30 9 30 8
0 13 -12 13 -30z m-238 -84 c18 -36 38 -146 25 -146 -5 0 -97 214 -97 226 0
16 53 -43 72 -80z m481 2 c49 -61 68 -102 68 -151 1 -45 -10 -80 -36 -112 -16
-20 -17 -19 -52 20 -45 51 -65 98 -65 155 0 46 27 130 42 130 5 0 24 -19 43
-42z m11427 -40 c51 -35 57 -51 34 -93 -24 -45 -29 -49 -61 -40 -26 6 -163
130 -163 147 0 4 35 8 79 8 64 0 84 -4 111 -22z m-11700 -43 c0 -16 -6 -25
-15 -25 -18 0 -20 21 -3 38 17 17 18 16 18 -13z m-270 -186 c0 -11 -4 -17 -10
-14 -5 3 -10 15 -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m5944 5 c23 -9
20 -23 -4 -17 -11 3 -20 9 -20 14 0 11 3 11 24 3z m23016 -4 c0 -5 -12 -10
-26 -10 -14 0 -23 4 -19 10 3 6 15 10 26 10 10 0 19 -4 19 -10z m-28735 -114
c27 -48 42 -111 27 -120 -22 -14 -82 108 -82 167 0 16 2 17 16 7 8 -8 26 -32
39 -54z m11717 4 l33 -30 -50 15 c-27 8 -51 15 -52 15 -1 0 0 7 3 15 9 23 30
18 66 -15z m1907 -27 c16 -20 28 -40 25 -45 -3 -4 -37 -8 -76 -8 l-71 0 6 37
c7 41 20 53 59 53 19 0 36 -11 57 -37z m20636 -13 c3 -5 -1 -10 -10 -10 -9 0
-13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m-22417 -35 c2 -2 -8 -7 -23
-11 -21 -5 -31 -2 -42 11 -22 31 -15 36 24 20 20 -9 39 -18 41 -20z m22922 18
c-1 -5 -8 -19 -16 -33 -8 -14 -13 -17 -10 -7 3 10 6 25 6 33 0 8 5 14 10 14 6
0 10 -3 10 -7z m230 -270 c-40 -274 -34 -254 -69 -247 -22 5 -36 -1 -64 -26
l-37 -32 -11 29 c-17 44 -4 120 41 239 34 90 40 119 39 169 l-3 60 65 16 c35
8 65 12 67 8 2 -4 -11 -101 -28 -216z m-780 198 c0 -17 -18 -21 -24 -6 -3 9 0
15 9 15 8 0 15 -4 15 -9z m570 -35 c10 -13 10 -20 -4 -41 -9 -13 -26 -29 -38
-36 -20 -11 -21 -10 -15 36 4 26 7 54 7 62 0 16 28 4 50 -21z m843 -21 c-20
-40 -29 -51 -35 -41 -11 20 6 61 34 80 12 9 24 16 25 16 2 0 -9 -25 -24 -55z
m-11852 25 c44 -24 60 -102 38 -185 -12 -43 -102 -253 -454 -1060 -319 -731
-311 -698 -308 -1215 1 -358 9 -504 40 -727 9 -68 14 -126 10 -130 -13 -12
-131 51 -180 97 -27 24 -60 66 -73 92 -32 63 -184 413 -245 562 -80 199 -138
264 -311 351 -108 54 -164 59 -220 21 -19 -13 -52 -26 -74 -30 -80 -15 -121
-60 -349 -391 -172 -249 -253 -353 -404 -519 -209 -230 -230 -249 -319 -296
-46 -24 -107 -61 -136 -84 -57 -44 -396 -348 -514 -461 -116 -110 -177 -216
-207 -358 -3 -15 -29 -54 -58 -85 -84 -91 -96 -113 -114 -209 -23 -121 -22
-213 5 -291 12 -34 22 -85 22 -113 0 -54 -29 -291 -36 -298 -14 -14 -126 109
-572 621 -111 128 -151 216 -177 388 -27 173 -13 351 41 520 33 105 173 386
208 419 35 33 41 22 22 -37 -19 -55 -13 -82 16 -82 46 0 398 62 463 81 153 46
261 123 367 261 33 44 88 101 122 126 92 71 183 160 232 228 68 95 118 212
189 444 103 333 138 441 150 455 8 10 34 10 140 -1 326 -33 534 -46 652 -40
110 6 275 28 313 42 9 4 -13 19 -60 42 -86 42 -95 54 -145 194 -18 51 -37 101
-41 112 -7 17 -3 18 48 12 54 -7 55 -7 91 33 68 75 64 154 -18 336 -32 72 -89
189 -126 261 -96 183 -111 244 -113 466 0 97 2 179 5 182 3 3 39 -5 80 -19 41
-13 127 -40 192 -60 l118 -36 -6 -43 c-7 -43 -7 -43 34 -60 59 -23 67 -21 124
29 77 67 96 71 245 46 95 -16 145 -30 197 -56 47 -23 86 -35 120 -37 77 -3
276 12 319 25 50 14 130 87 150 137 8 22 32 58 52 80 88 98 348 270 407 270
15 0 37 -5 48 -10z m-14412 -25 c35 -17 74 -62 89 -101 6 -16 35 -112 65 -214
67 -223 90 -286 122 -325 31 -38 39 -29 24 29 -6 26 -9 49 -7 52 13 12 50 -36
59 -76 5 -25 26 -69 45 -99 36 -54 35 -70 -6 -71 -27 0 -79 -39 -86 -64 -3
-15 -17 -29 -37 -36 -18 -7 -63 -26 -101 -43 -87 -38 -177 -45 -296 -24 -47 9
-209 38 -360 66 -151 27 -345 62 -430 77 -393 68 -676 13 -994 -195 -75 -48
-155 -81 -200 -81 -30 0 -59 22 -197 144 l-121 109 6 46 c13 99 46 173 98 225
40 39 51 56 55 90 6 46 25 66 63 66 17 0 63 31 147 100 128 106 128 105 403
210 l125 47 335 6 c306 5 546 21 727 47 37 5 122 10 188 11 66 0 131 5 145 9
41 13 109 11 139 -5z m19859 -29 c17 -24 28 -46 25 -50 -17 -17 -41 -2 -61 39
-26 53 -26 55 -9 55 8 0 28 -20 45 -44z m-7778 -27 c0 -4 -11 -21 -25 -36
l-25 -28 5 28 c16 85 15 84 31 63 8 -11 14 -23 14 -27z m12840 -84 c0 -62 -4
-79 -20 -95 -18 -18 -80 -29 -80 -14 0 6 94 184 98 184 1 0 2 -34 2 -75z
m-33719 42 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m6887
-54 c-28 -65 -30 -67 -72 -47 -38 19 -44 28 -24 41 10 6 107 51 115 53 1 0 -7
-21 -19 -47z m4270 -12 c48 -21 128 -62 176 -90 104 -59 147 -68 265 -53 47 6
190 14 316 17 390 10 1000 22 1003 20 1 -1 18 -53 36 -116 66 -226 61 -272
-62 -519 -12 -24 -25 -36 -42 -38 -17 -2 -30 -12 -38 -30 -6 -15 -21 -39 -32
-53 -28 -33 -29 -89 -5 -192 26 -110 35 -115 117 -72 50 27 69 43 77 66 6 17
16 29 23 27 22 -8 48 -53 56 -100 9 -44 8 -47 -17 -58 -14 -7 -80 -16 -147
-20 -86 -5 -131 -13 -157 -26 l-37 -19 138 -100 c145 -105 150 -107 233 -85
38 11 40 13 38 45 -2 19 -1 37 3 40 6 6 174 -193 172 -204 -1 -3 -29 -14 -63
-25 -34 -10 -61 -20 -59 -22 2 -1 128 -32 280 -68 299 -70 338 -83 382 -124
44 -42 54 -76 76 -270 25 -218 46 -305 105 -429 153 -324 388 -549 625 -599
64 -13 91 -14 180 -4 142 16 335 10 335 -10 0 -10 -34 -21 -127 -40 -70 -14
-129 -28 -133 -32 -13 -13 65 -29 181 -37 103 -8 133 -14 224 -49 l105 -40 76
-198 c42 -110 88 -226 104 -259 15 -33 29 -85 32 -116 5 -52 0 -69 -87 -285
-51 -126 -99 -242 -108 -259 -9 -17 -17 -58 -18 -95 -2 -79 -21 -121 -93 -203
-27 -31 -62 -73 -77 -92 l-27 -36 -22 21 c-27 25 -36 25 -121 -3 -61 -21 -71
-28 -89 -63 -93 -186 -206 -300 -334 -339 -23 -7 -24 -6 -19 43 3 31 20 81 44
129 l39 78 5 240 c4 204 7 240 20 240 11 0 17 -18 22 -75 19 -185 18 -183 34
-163 45 58 81 299 70 463 -10 132 -42 287 -72 337 -62 106 -262 209 -519 267
-95 22 -155 66 -253 184 -86 104 -223 241 -259 261 -28 14 -28 14 -3 -49 11
-28 20 -55 20 -60 0 -6 -18 -10 -40 -10 -41 0 -60 18 -60 58 0 10 -21 45 -46
77 -53 69 -107 177 -160 320 -45 122 -110 260 -161 340 l-38 60 -3 -52 c-2
-29 6 -89 17 -135 10 -46 22 -105 26 -133 l6 -50 -26 30 c-37 43 -88 136 -130
240 -49 119 -73 165 -86 165 -6 0 -27 8 -46 17 -43 20 -72 78 -73 142 0 56
-37 215 -61 263 -42 82 -136 107 -241 63 -11 -4 -29 5 -57 30 l-41 37 -45 -22
c-112 -54 -221 -164 -280 -280 -40 -79 -74 -186 -101 -317 -41 -202 -88 -309
-180 -412 l-41 -46 -11 70 c-6 39 -15 126 -21 195 -31 342 -115 666 -262 1005
-39 88 -126 293 -194 455 -68 162 -155 358 -194 435 -38 77 -75 161 -80 186
-27 120 -73 649 -56 649 2 0 44 -18 93 -39z m-10944 6 c17 -12 15 -15 -17 -40
-20 -15 -39 -27 -42 -27 -9 0 4 52 17 67 13 16 20 16 42 0z m25916 -47 c267
-14 803 -58 867 -71 107 -21 156 -63 501 -424 178 -186 319 -341 315 -346 -4
-4 -54 -26 -110 -48 -142 -57 -207 -66 -355 -52 -213 20 -394 69 -580 155
-157 73 -247 97 -377 104 -110 5 -154 1 -411 -39 -36 -6 -167 -34 -291 -64
-394 -94 -443 -99 -522 -62 -73 34 -166 117 -166 148 -1 18 -16 41 -47 71 -58
57 -71 106 -48 175 24 71 41 100 87 150 51 56 103 92 204 142 189 94 379 144
618 161 55 4 105 8 110 8 6 1 98 -3 205 -8z m8005 0 c-3 -5 -10 -10 -16 -10
-5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-100 -22 c11 -45 -72 -288
-98 -288 -21 0 -69 40 -65 54 5 22 143 256 150 256 4 0 10 -10 13 -22z
m-27320 -28 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7 -16
3 -22z m-7707 -117 c23 -70 17 -78 -22 -32 -27 31 -30 47 -20 87 9 31 17 19
42 -55z m7632 46 c0 -23 -54 -92 -64 -82 -14 14 -4 49 21 75 32 33 43 35 43 7z
m22419 -8 c6 -10 13 -38 17 -62 15 -101 47 -129 149 -129 l52 0 7 -39 c4 -28
17 -49 48 -77 97 -91 103 -97 90 -102 -7 -2 -56 17 -110 43 -91 45 -105 56
-244 196 l-147 149 37 20 c48 25 88 26 101 1z m-29365 0 c3 -4 -5 -18 -18 -30
-29 -27 -47 -10 -20 20 19 21 30 24 38 10z m33896 -41 c0 -5 -9 -14 -20 -20
-22 -12 -27 -1 -8 18 14 14 28 16 28 2z m605 -199 c3 -5 1 -12 -5 -16 -5 -3
-10 1 -10 9 0 18 6 21 15 7z m-33557 -61 c67 -53 86 -80 175 -253 86 -168 208
-355 330 -507 89 -111 131 -183 142 -242 9 -48 -8 -57 -133 -69 -115 -11 -151
-22 -182 -53 l-22 -24 49 -36 c73 -53 141 -70 366 -91 37 -4 65 -11 61 -16
-12 -20 -85 -49 -123 -49 -21 0 -83 10 -137 21 -60 13 -147 23 -220 25 -110 3
-127 6 -185 34 -70 33 -96 36 -146 18 -29 -11 -36 -9 -65 12 -72 52 -201 177
-218 210 -25 50 -162 487 -168 535 -4 36 10 128 40 268 8 40 13 47 33 47 16 0
30 11 45 35 32 51 78 82 193 130 56 24 105 43 109 44 3 1 29 -17 56 -39z
m-678 -25 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m146
-27 c48 -53 47 -85 -5 -132 -18 -16 -25 -17 -46 -8 -29 13 -32 42 -9 78 11 17
15 37 11 64 -4 35 -3 39 11 31 10 -5 27 -20 38 -33z m-538 -9 c10 -16 11 -24
1 -35 -11 -13 -14 -13 -27 -1 -9 9 -11 23 -7 36 8 26 17 27 33 0z m14054 -69
c15 -21 17 -31 8 -57 -10 -27 -14 -30 -38 -24 -27 7 -72 62 -72 87 0 32 77 27
102 -6z m7293 -41 c6 -27 1 -49 -9 -38 -10 11 -17 69 -8 69 6 0 13 -14 17 -31z
m-1566 -30 c-16 -10 -23 -4 -14 10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m14302
-13 c119 -25 157 -53 307 -217 l32 -35 -25 -33 c-23 -32 -23 -35 -8 -64 15
-30 14 -33 -31 -121 -53 -104 -56 -129 -20 -152 22 -15 25 -22 19 -58 -12 -68
-45 -157 -77 -203 -25 -37 -46 -52 -133 -93 -89 -41 -115 -59 -182 -127 -88
-88 -154 -127 -283 -168 -82 -26 -88 -26 -181 -14 l-96 12 -111 106 -112 106
0 55 c0 32 4 50 9 45 5 -6 36 -45 70 -87 86 -110 124 -129 152 -77 8 16 2 24
-38 51 -26 18 -71 56 -100 85 -45 44 -54 60 -64 112 -10 46 -17 61 -30 61 -30
0 -77 48 -93 97 -34 98 -18 125 148 257 73 59 202 169 287 246 166 151 229
191 339 214 84 19 140 19 221 2z m913 -19 c-1 -10 -19 -42 -40 -72 l-38 -55
-39 60 c-22 33 -38 63 -35 65 6 7 118 23 138 21 8 -1 14 -10 14 -19z m-13286
-241 c33 -97 42 -138 42 -190 0 -36 -4 -66 -9 -66 -22 0 -50 54 -56 108 -4 31
-13 89 -20 127 -26 140 -27 145 -13 145 8 0 30 -50 56 -124z m-278 73 c0 -17
-4 -28 -10 -24 -5 3 -10 17 -10 31 0 13 5 24 10 24 6 0 10 -14 10 -31z m8808
-43 c1 -2 -9 -13 -24 -25 -27 -21 -82 -27 -132 -13 l-23 7 31 42 31 42 57 -25
c31 -13 58 -26 60 -28z m-20795 -3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0
19 -2 13 -5z m11920 -131 c17 -19 17 -20 -9 -26 -33 -9 -51 7 -60 53 l-7 35
29 -21 c16 -12 37 -30 47 -41z m-8063 13 c0 -14 -4 -25 -10 -25 -5 0 -10 11
-10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m7870 -56 c0 -13 -74 -11 -88 3
-13 13 -16 60 -6 71 8 7 94 -61 94 -74z m-7530 19 c0 -15 -19 -8 -29 11 -14
25 0 37 16 15 7 -10 13 -21 13 -26z m-4010 13 c0 -10 -51 -22 -63 -14 -4 2 -5
9 -2 14 8 12 65 12 65 0z m5253 -48 c31 -24 57 -46 57 -49 0 -2 -19 -4 -41 -4
-35 0 -48 6 -81 40 -34 33 -38 43 -28 55 14 17 15 16 93 -42z m19855 19 c2 -7
-3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m-180 -128 l-4 -29 -11
25 c-6 14 -14 36 -18 50 l-7 25 22 -21 c15 -15 20 -30 18 -50z m-2290 24 c-7
-20 -68 -23 -68 -4 0 12 10 16 36 16 24 0 34 -4 32 -12z m-31688 -115 l0 -108
-90 90 c-49 49 -87 91 -85 93 5 6 122 29 153 31 22 1 22 -1 22 -106z m5879 4
c21 -35 22 -39 5 -34 -11 3 -36 9 -56 13 -21 3 -38 7 -38 8 0 1 7 24 17 51
l16 49 16 -24 c9 -13 27 -41 40 -63z m13081 -1 c-17 -22 -32 -9 -18 14 8 12
16 16 22 10 6 -6 4 -15 -4 -24z m-3876 -45 c-7 -7 -12 3 -18 34 l-6 30 14 -30
c8 -16 12 -32 10 -34z m-9322 40 c13 -5 44 -35 68 -66 35 -46 41 -58 29 -66
-22 -14 -58 -1 -102 37 -23 19 -44 34 -49 34 -5 0 18 -40 51 -88 75 -109 83
-132 82 -208 0 -55 2 -62 24 -72 33 -15 52 -47 79 -133 13 -41 42 -122 65
-181 35 -90 40 -113 35 -152 -5 -34 -2 -57 11 -86 l18 -40 -28 -40 c-51 -74
-139 -174 -183 -207 -80 -61 -383 -143 -532 -143 -25 0 -110 -11 -190 -25
-168 -29 -196 -25 -231 31 -37 61 -54 67 -177 59 -60 -4 -119 -4 -131 0 -62
19 -86 168 -85 525 0 326 6 357 81 441 62 68 132 113 323 206 154 76 155 77
228 74 64 -2 91 3 210 43 75 25 164 49 197 54 82 12 179 14 207 3z m9494 -76
c23 -40 98 -274 90 -282 -9 -9 -80 64 -93 95 -7 18 -20 72 -28 120 -8 48 -18
98 -21 111 -5 22 -3 23 11 11 9 -7 27 -32 41 -55z m-13196 20 c10 -12 10 -21
1 -42 -14 -30 -26 -22 -34 25 -7 35 10 44 33 17z m31837 -52 c-8 -48 -36 -90
-48 -72 -15 24 -10 65 12 93 26 33 43 24 36 -21z m-27725 -29 c43 -30 48 -44
15 -44 -31 0 -67 27 -67 51 0 25 7 24 52 -7z m22839 -68 c12 -32 14 -34 75
-39 85 -6 123 -30 157 -97 15 -30 51 -77 81 -105 39 -37 65 -75 95 -139 55
-117 59 -136 29 -170 -21 -25 -24 -26 -45 -11 -14 10 -26 34 -33 69 -13 63
-34 89 -44 54 -3 -13 -9 -52 -12 -88 -5 -54 -2 -78 20 -143 14 -42 26 -79 26
-82 0 -2 -19 -9 -42 -15 -90 -24 -88 -19 -81 -197 l6 -156 -51 -46 c-29 -25
-89 -95 -135 -156 -112 -150 -128 -157 -381 -186 -289 -32 -374 -18 -612 101
-143 70 -169 87 -218 141 -55 60 -141 193 -152 236 -4 14 2 33 16 53 35 49 62
139 67 227 6 79 7 82 35 94 39 16 41 23 23 59 l-16 31 49 54 c30 33 68 92 96
152 73 151 215 309 323 360 95 44 180 40 222 -11 17 -20 109 -21 196 -2 34 7
74 10 93 5 27 -6 45 -1 98 27 75 39 95 35 115 -20z m-26229 -75 c158 -55 283
-148 375 -280 26 -36 63 -84 84 -107 50 -56 65 -104 49 -158 -26 -87 -79 -165
-131 -192 -55 -28 -391 -86 -500 -86 -62 0 -89 10 -134 52 -16 15 -48 33 -69
40 -51 16 -122 82 -152 142 -13 26 -33 82 -45 125 -20 70 -21 85 -10 146 15
91 57 171 123 233 61 58 81 70 158 96 68 23 169 18 252 -11z m12564 -179 c-12
-11 -17 7 -9 34 l8 29 3 -28 c2 -16 1 -31 -2 -35z m15937 17 c21 -6 56 -26 77
-45 41 -37 67 -100 90 -218 15 -76 26 -59 35 53 4 47 13 97 20 111 l13 24 21
-34 c16 -26 21 -52 21 -101 0 -46 5 -70 14 -79 8 -6 12 -18 9 -26 -8 -22 -23
-17 -24 9 -2 45 -39 -132 -49 -237 -18 -181 -41 -211 -188 -241 -142 -29 -624
-97 -647 -91 -14 4 -39 19 -57 35 l-31 28 16 66 c10 36 17 69 17 72 0 3 -19 5
-42 5 -76 1 -158 50 -213 130 -24 35 -26 44 -17 65 6 14 17 25 24 25 7 -1 47
-27 88 -60 62 -49 76 -56 81 -42 4 9 16 76 28 147 32 189 32 190 110 240 131
85 275 147 379 165 70 12 182 11 225 -1z m618 -9 c33 -17 38 -24 30 -39 -10
-18 -127 -58 -138 -47 -12 11 10 67 34 86 31 24 25 24 74 0z m-11836 -111
c-24 -106 -73 -254 -116 -350 -71 -157 -188 -359 -209 -359 -17 0 -11 24 29
117 38 87 82 225 136 428 15 54 35 115 45 135 28 55 120 133 128 109 2 -5 -4
-41 -13 -80z m-4628 -74 c31 -59 73 -181 73 -207 0 -53 -56 -121 -122 -150
l-47 -20 15 -30 c30 -58 -15 -59 -104 -1 l-24 15 41 35 c23 19 51 53 62 75 23
44 38 164 39 298 l1 85 20 -25 c12 -14 33 -47 46 -75z m-8395 13 c-6 -6 -16
-7 -22 -3 -9 6 -9 9 1 16 17 10 34 0 21 -13z m4306 -41 c25 -37 26 -56 8 -107
l-13 -35 -12 70 c-6 39 -14 78 -17 88 -9 28 9 19 34 -16z m20697 -27 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-19535 -44 c0
-8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15 25 12 25 -4z m19210 -41 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-16986 -100
c20 -30 36 -57 36 -60 0 -12 -50 -4 -64 11 -14 13 -24 104 -12 104 2 0 20 -25
40 -55z m17330 47 c2 -4 -4 -22 -15 -39 -13 -21 -32 -34 -62 -42 -72 -19 -92
-18 -92 4 0 20 13 28 100 63 59 24 62 24 69 14z m-20684 -125 c0 -18 -2 -19
-10 -7 -13 20 -13 43 0 35 6 -3 10 -16 10 -28z m2942 -12 c0 -5 -5 -11 -11
-13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m544 -103 c-12 -12
-17 -12 -40 3 -25 17 -26 19 -10 37 17 18 18 18 41 -4 20 -19 21 -24 9 -36z
m6571 -12 c6 -27 8 -50 6 -50 -4 0 -43 87 -43 96 0 2 6 4 14 4 8 0 17 -19 23
-50z m-10054 2 c15 -16 15 -20 1 -40 -23 -33 -34 -27 -34 18 0 44 8 49 33 22z
m8277 -2 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-4070 -20 c13 -8 13 -10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z
m16908 -1 l31 -11 -19 -46 -18 -47 -48 3 c-26 1 -50 4 -52 7 -6 5 60 104 69
105 3 0 20 -5 37 -11z m-28987 -91 c-12 -32 -65 -48 -88 -26 -12 13 -7 19 39
50 44 29 53 32 56 18 2 -9 -2 -28 -7 -42z m28808 13 l53 -19 -55 -41 c-31 -22
-62 -41 -68 -41 -7 0 -23 9 -35 21 l-22 21 -15 -27 c-19 -38 -31 -16 -17 34
19 71 64 86 159 52z m-20943 -23 c24 -47 15 -252 -15 -326 -27 -68 -26 -80 4
-94 22 -10 25 -17 25 -64 0 -45 -4 -56 -27 -74 l-26 -21 12 -78 c6 -43 9 -85
5 -95 -8 -21 -36 -36 -67 -36 -20 0 -26 9 -40 53 -24 76 -22 116 18 287 19 82
41 204 49 269 14 111 34 211 42 211 2 0 11 -14 20 -32z m20650 -16 c-9 -8 -26
10 -26 26 1 15 2 15 15 -2 8 -11 13 -21 11 -24z m1189 18 c-9 -15 -156 -102
-162 -96 -3 3 2 17 12 32 25 39 174 102 150 64z m-11745 -27 c0 -7 -4 -13 -10
-13 -5 0 -10 12 -10 28 0 21 2 24 10 12 5 -8 10 -21 10 -27z m11470 -122 c-14
-28 -50 -51 -77 -51 -38 0 -26 28 20 49 57 26 71 26 57 2z m-2610 -44 c0 -6
-9 -2 -20 8 -11 10 -20 23 -20 28 0 6 9 2 20 -8 11 -10 20 -23 20 -28z
m-18795 -150 c-3 -43 -19 -148 -35 -233 -17 -86 -30 -175 -30 -199 0 -55 -12
-63 -40 -28 -20 25 -22 36 -17 103 3 42 28 166 58 283 45 172 55 203 62 180 4
-15 5 -63 2 -106z m14049 102 c69 -15 106 -29 163 -65 91 -56 114 -88 203
-269 155 -316 222 -580 191 -750 -6 -33 -29 -235 -51 -450 -37 -366 -52 -471
-84 -605 l-13 -55 -60 1 c-45 1 -72 7 -112 30 -101 54 -218 235 -296 454 -21
58 -62 161 -92 230 -87 197 -120 288 -145 400 -22 102 -23 119 -21 540 1 239
5 455 10 480 l9 45 58 0 c48 0 61 4 75 22 18 23 15 24 165 -8z m-17163 -75
c42 -41 91 -127 108 -189 6 -22 22 -148 36 -280 14 -132 32 -287 41 -345 18
-125 12 -204 -23 -305 -14 -38 -52 -151 -85 -250 -65 -197 -115 -319 -189
-466 -113 -226 -196 -340 -257 -354 -44 -9 -97 22 -168 97 -134 143 -187 319
-244 806 -33 286 -24 445 39 687 62 235 156 378 316 477 22 14 103 57 180 97
162 84 183 86 246 25z m24312 33 c27 -12 27 -12 -38 -39 -69 -28 -75 -26 -55
26 11 29 45 34 93 13z m-253 -22 c-7 -8 -16 -12 -21 -9 -13 8 -1 24 18 24 13
0 13 -3 3 -15z m-10906 -67 c15 -26 23 -49 17 -52 -12 -8 -64 81 -56 95 8 12
6 15 39 -43z m7878 -17 c22 -26 56 -117 47 -125 -5 -4 -234 -66 -244 -66 -4 0
-5 25 -3 55 5 65 24 94 93 147 54 41 65 40 107 -11z m3088 10 c-11 -22 -37
-34 -53 -24 -6 4 1 15 18 29 32 25 50 23 35 -5z m-13260 -130 l0 -59 -56 -7
c-31 -4 -58 -4 -61 -1 -5 4 105 126 114 126 1 0 3 -26 3 -59z m12490 -250 c0
-5 -11 -11 -25 -13 -29 -6 -33 6 -8 21 18 10 33 6 33 -8z m-13400 -57 c0 -17
-7 -38 -16 -45 -14 -11 -19 -10 -35 10 -26 31 -24 46 8 71 31 24 43 14 43 -36z
m-9580 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m2111 -21 c9 -18 7 -25 -10 -42 l-21 -21 0 42 c0 46 13 54 31 21z
m-138 -30 c12 -16 17 -40 17 -93 0 -39 3 -81 6 -93 12 -45 -31 -25 -95 42 -56
60 -61 69 -61 111 0 37 4 46 23 54 33 14 90 4 110 -21z m20417 -24 c-25 -27
-60 -33 -60 -11 0 13 70 49 79 40 3 -2 -6 -15 -19 -29z m-8856 19 c27 -10 18
-21 -14 -16 -16 2 -30 8 -30 13 0 10 21 12 44 3z m-4006 -39 c-12 -37 -28 -38
-28 0 0 21 10 32 32 34 3 1 1 -15 -4 -34z m-7982 -50 c19 -30 22 -26 -35 -55
-47 -23 -61 -18 -61 24 0 53 68 74 96 31z m7984 -50 c0 -10 -10 -15 -30 -15
-47 0 -35 27 13 29 9 1 17 -6 17 -14z m-580 -36 c0 -6 -4 -7 -10 -4 -5 3 -10
11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m4360 -85 c0 -12 -6 -15 -22 -10
-13 3 -29 6 -36 6 -7 0 -10 5 -7 10 11 17 65 12 65 -6z m-3847 -136 c3 -60 2
-98 -4 -98 -6 0 -21 11 -35 25 -28 28 -28 40 4 143 20 67 28 52 35 -70z m2527
73 c0 -11 -40 -41 -54 -41 -5 0 1 11 14 25 24 26 40 32 40 16z m-14880 -57
c47 -43 50 -54 12 -54 -20 0 -41 13 -72 45 l-44 45 32 0 c23 0 44 -10 72 -36z
m11830 26 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-13790 -229 c0 -12 -39 -25 -48 -16 -7 7 17 25 36 25 6 0 12 -4 12 -9z
m118 -44 c24 -7 43 -16 43 -20 -1 -4 -1 -11 -1 -17 0 -8 -85 12 -97 24 -4 4 4
26 10 26 1 0 21 -6 45 -13z m10542 -169 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5
22 11 17 16 13 16 -11z m5724 -46 c-4 -18 -13 -32 -20 -32 -17 0 -18 19 -4 56
14 37 32 18 24 -24z m-15690 22 c9 -3 16 -13 16 -21 0 -10 -6 -13 -16 -9 -9 3
-20 6 -25 6 -5 0 -9 7 -9 15 0 16 9 19 34 9z m15289 -373 c-27 -97 -39 -127
-46 -115 -19 33 -2 118 37 188 21 37 40 66 42 63 3 -2 -12 -64 -33 -136z
m-9523 -61 c0 -15 -43 -22 -73 -11 -40 15 -33 21 23 21 28 0 50 -4 50 -10z
m13095 -110 c13 -26 31 -75 40 -111 26 -102 87 -314 93 -319 5 -5 -4 111 -14
188 -10 70 18 54 68 -40 45 -86 118 -174 163 -198 l26 -14 -38 -33 -38 -33 58
-52 c47 -43 58 -59 57 -84 0 -34 -16 -44 -77 -44 -17 0 -34 -3 -36 -7 -11 -18
32 -145 66 -196 56 -85 156 -144 277 -163 l42 -7 -5 -46 c-3 -25 -15 -67 -27
-94 -18 -40 -21 -63 -19 -160 2 -97 0 -117 -18 -152 -21 -40 -21 -41 -2 -75
10 -19 18 -38 19 -42 0 -4 -18 -13 -41 -19 -45 -14 -62 -37 -53 -73 5 -23 2
-25 -108 -51 -125 -30 -209 -30 -266 -1 -42 22 -102 75 -102 91 0 7 11 33 25
58 l26 46 28 -17 c34 -19 46 -12 91 53 l29 42 -85 32 c-103 39 -219 115 -276
181 -130 149 -198 359 -198 615 0 232 54 623 97 699 12 21 39 42 83 63 74 36
79 35 115 -37z m-19210 13 c0 -10 -264 -253 -274 -253 -4 0 -13 16 -20 36 -12
34 -11 38 17 78 44 60 111 114 177 141 56 22 100 22 100 -2z m5901 -47 c9 -3
33 -2 53 3 20 5 93 12 163 15 l128 6 -10 -26 c-15 -40 -12 -45 25 -42 41 2 76
-26 107 -86 25 -48 22 -131 -12 -316 -17 -98 -21 -147 -17 -225 4 -71 0 -127
-12 -195 l-17 -95 -29 49 c-30 52 -37 50 -49 -16 -8 -46 8 -189 34 -290 24
-94 24 -88 2 -88 -10 0 -28 -7 -41 -16 -18 -13 -22 -22 -17 -47 5 -23 1 -36
-14 -52 l-21 -22 33 -21 c29 -19 34 -19 49 -6 16 15 20 26 34 122 5 34 11 42
28 42 11 0 39 20 64 45 23 25 45 45 48 45 3 0 5 -27 5 -60 0 -119 -64 -227
-171 -284 -38 -21 -56 -26 -71 -19 -20 9 -20 10 12 46 31 36 27 47 -10 27 -10
-5 -21 -21 -24 -34 -5 -19 -15 -27 -44 -32 -52 -9 -70 -20 -132 -79 -129 -122
-207 -121 -319 2 -66 72 -130 191 -158 293 -25 93 -25 349 1 485 34 184 119
404 232 603 17 30 17 34 4 45 -48 37 -62 95 -36 145 15 29 75 88 116 116 23
15 25 15 37 -8 7 -13 20 -26 29 -30z m15432 -221 c49 -44 86 -81 82 -83 -19
-7 -122 43 -163 79 -54 47 -91 103 -83 125 7 18 11 15 164 -121z m6156 24 c76
-84 164 -258 154 -305 -2 -10 -47 57 -102 152 -55 93 -106 179 -114 192 -28
43 7 21 62 -39z m-23544 25 c0 -11 -42 -65 -47 -60 -6 6 29 66 39 66 4 0 8 -3
8 -6z m11844 -199 c3 -9 -1 -18 -10 -22 -19 -7 -28 2 -21 22 8 19 23 19 31 0z
m-3772 -57 c2 -29 4 -54 3 -56 -5 -8 -55 42 -55 55 0 17 28 53 41 53 4 0 9
-24 11 -52z m-248 11 c-10 -12 -31 -30 -45 -40 l-26 -18 5 27 c7 39 20 52 55
52 30 0 30 0 11 -21z m-6467 -66 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2
-26 0 -35z m-5187 31 c0 -15 -64 -93 -72 -88 -5 3 -3 15 5 27 8 12 19 32 26
45 10 19 41 32 41 16z m22146 -46 c-3 -13 -6 -26 -6 -30 0 -5 -4 -8 -10 -8 -5
0 -10 14 -10 30 0 21 5 30 16 30 12 0 15 -6 10 -22z m-24306 -8 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m26488 -88 c-3
-48 -7 -105 -7 -127 l-1 -40 -30 53 c-17 28 -46 62 -65 75 -19 12 -35 24 -35
27 0 3 29 25 64 50 35 24 62 48 60 52 -3 4 1 6 8 3 10 -4 12 -25 6 -93z
m-26722 51 c-4 -8 -9 -27 -12 -41 -3 -15 -24 -66 -46 -115 -44 -97 -45 -128
-2 -167 13 -13 24 -28 24 -33 0 -14 -93 -1 -128 17 -54 28 -70 115 -18 102 32
-8 64 40 111 165 32 83 40 96 56 92 13 -4 18 -11 15 -20z m27644 -145 l-1 -73
-19 24 c-32 38 -29 121 5 121 12 0 15 -14 15 -72z m2409 -49 c24 -66 40 -123
37 -126 -12 -12 -95 86 -105 124 -12 43 -14 106 -4 116 18 19 32 -3 72 -114z
m-27960 -31 c1 -66 1 -65 -76 -41 -34 11 -53 22 -53 32 0 26 38 84 78 118 l37
32 7 -42 c3 -23 7 -68 7 -99z m11836 93 c-3 -6 -15 -16 -26 -21 -14 -8 -19 -7
-19 3 0 8 10 19 23 24 25 12 32 10 22 -6z m8307 -33 c-5 -20 -6 -20 -18 -4 -7
10 -10 24 -7 32 9 23 32 -3 25 -28z m-20012 -38 c-21 -16 -27 -17 -39 -5 -11
11 -10 17 5 38 19 26 19 26 39 6 20 -20 20 -20 -5 -39z m5350 16 c0 -14 -4
-28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10 -11 10 -24z m-6626
-81 c-18 -28 -68 -55 -101 -55 -10 0 11 20 47 44 36 24 66 42 68 41 2 -2 -4
-16 -14 -30z m21214 0 c37 -8 41 -27 10 -49 -42 -30 -88 -15 -88 29 0 27 9 36
29 31 9 -3 31 -7 49 -11z m8010 -46 c2 -23 2 -23 -13 -5 -18 24 -20 39 -2 34
6 -3 13 -15 15 -29z m-24684 12 c4 -5 2 -21 -4 -35 -7 -20 -16 -26 -40 -26
l-31 0 27 35 c27 36 39 42 48 26z m-4917 -27 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m428 -14 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6
13 10 21 10 8 0 12 -4 9 -10z m-1162 -122 c7 -25 0 -41 -14 -33 -12 8 -12 55
0 55 5 0 11 -10 14 -22z m29817 12 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6
8 10 11 10 2 0 4 -4 4 -10z m361 -10 c10 -6 19 -17 19 -25 0 -23 -16 -18 -34
10 -18 27 -15 30 15 15z m-30231 -63 c22 -30 40 -59 40 -65 0 -6 -27 -43 -60
-83 -33 -39 -60 -79 -60 -89 0 -9 16 -47 35 -84 45 -87 46 -111 1 -60 l-34 39
-18 -38 c-41 -87 -46 46 -8 248 14 77 28 150 30 163 7 36 31 26 74 -31z
m29398 -3 c12 -4 22 -11 22 -16 0 -14 -53 -5 -58 10 -4 13 2 15 36 6z m510
-25 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13
-11z m-599 -42 c8 -11 5 -13 -19 -9 -17 2 -30 8 -30 13 0 14 36 11 49 -4z
m-13030 -53 c2 -2 2 -7 -2 -10 -8 -9 -47 6 -47 17 0 9 37 3 49 -7z m114 4 c39
-21 97 -71 97 -85 0 -7 -3 -13 -6 -13 -15 0 -133 93 -128 101 7 12 11 11 37
-3z m-8579 -33 c9 -46 9 -49 -1 -52 -9 -3 -33 53 -33 75 0 28 28 9 34 -23z
m12559 10 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m1066 -39
c35 -51 39 -61 26 -62 -10 0 -85 88 -85 100 0 3 7 6 15 6 8 0 28 -20 44 -44z
m-18669 27 c0 -5 -4 -15 -10 -23 -11 -18 -25 -7 -17 15 6 15 27 21 27 8z
m1986 -9 c-3 -9 -6 -23 -6 -32 -1 -15 -2 -15 -11 1 -12 21 -5 47 12 47 6 0 8
-7 5 -16z m-2128 -6 c20 -20 14 -29 -15 -22 -35 8 -37 10 -28 23 8 14 28 14
43 -1z m25603 -47 c-15 -27 -70 -56 -83 -43 -5 5 9 21 39 40 52 34 60 34 44 3z
m921 12 c17 -15 17 -17 2 -35 -18 -19 -41 -22 -93 -12 l-32 7 33 28 c38 32 64
36 90 12z m-26482 -45 c0 -4 -11 -8 -25 -8 -27 0 -33 18 -13 38 10 10 15 9 25
-5 7 -9 13 -20 13 -25z m-2124 -14 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9
9 15 11 12 3 -3 3 -13 -1 -22z m4156 -129 c-15 -66 -32 -119 -37 -117 -12 3
-8 101 5 152 11 41 52 109 56 95 2 -5 -9 -64 -24 -130z m7053 73 c-6 -49 -27
-98 -41 -98 -7 0 1 53 17 113 15 57 32 47 24 -15z m4755 20 c0 -6 -6 -5 -15 2
-8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15 -16z m-10120 -132 c89 -181
92 -190 79 -285 -13 -101 -3 -158 32 -193 24 -25 35 -28 94 -28 l67 0 -7 -42
c-16 -98 -69 -168 -208 -274 -121 -91 -287 -152 -375 -139 -96 14 -179 75
-200 145 -6 19 -13 67 -17 105 -4 39 -15 110 -26 160 -29 137 -30 135 67 279
97 147 124 197 150 280 10 32 31 72 45 87 25 27 33 29 101 30 59 1 100 7 125
18 1 1 34 -64 73 -143z m-5896 107 c-33 -50 -64 -116 -64 -137 1 -49 16 -42
65 30 28 40 54 73 58 74 12 0 157 -101 157 -109 -1 -10 -150 -195 -150 -185 0
5 14 51 31 102 41 124 33 144 -31 74 -55 -59 -100 -82 -163 -82 l-45 0 23 80
c25 88 44 124 84 157 38 33 58 30 35 -4z m-684 -10 c0 -22 -2 -25 -10 -13 -13
20 -13 40 0 40 6 0 10 -12 10 -27z m24265 -34 c179 -85 284 -357 255 -664 -12
-123 -43 -294 -60 -325 -31 -58 -127 -77 -192 -37 -32 20 -128 103 -128 111 0
2 29 15 65 31 36 15 65 31 65 35 0 4 -25 10 -56 14 -126 15 -184 83 -235 276
-37 142 -33 234 21 429 l44 163 86 -4 c62 -3 98 -11 135 -29z m6022 5 c42 -32
53 -61 53 -152 0 -95 -15 -107 -38 -31 -36 120 -52 130 -52 32 0 -61 -1 -66
-16 -52 -8 9 -21 40 -28 71 -7 30 -27 75 -44 100 -18 26 -32 50 -32 53 0 4 29
5 65 3 45 -3 73 -10 92 -24z m-22262 -8 c17 -13 25 -70 10 -84 -19 -20 -63 52
-51 83 7 19 17 19 41 1z m5482 -73 c-21 -38 -172 -194 -226 -232 -62 -43 -79
-41 -50 7 29 49 74 97 189 200 66 59 96 80 98 69 2 -9 -3 -28 -11 -44z
m-12382 -3 c18 -20 18 -20 -16 -9 -46 16 -59 29 -28 29 14 0 34 -9 44 -20z
m20755 -10 c61 -9 109 -33 117 -57 4 -10 -174 -61 -180 -52 -3 4 -22 46 -43
96 -14 32 -14 33 13 28 16 -3 57 -10 93 -15z m-20410 -82 c0 -12 -14 -10 -28
4 -19 19 -14 29 8 16 11 -7 20 -16 20 -20z m29163 -71 c21 -113 20 -107 1
-107 -27 0 -45 64 -38 135 4 36 10 65 14 65 4 0 14 -42 23 -93z m-30463 -13
c-6 -49 -13 -90 -14 -92 -9 -9 -38 90 -32 111 6 26 46 81 53 74 2 -3 -1 -44
-7 -93z m7900 66 c6 -11 7 -20 2 -20 -10 0 -32 21 -32 32 0 16 19 8 30 -12z
m-8095 -81 c7 -33 3 -45 -14 -34 -13 8 -15 65 -2 65 5 0 12 -14 16 -31z m1003
19 c7 -7 8 -17 3 -27 -8 -14 1 -16 68 -17 114 -1 203 -33 400 -142 l83 -47 79
-158 c78 -157 79 -159 64 -193 -8 -19 -24 -50 -37 -67 -42 -61 -183 -200 -263
-259 -44 -33 -97 -82 -118 -109 -21 -27 -44 -49 -51 -49 -8 0 -76 -18 -152
-40 -119 -34 -250 -64 -371 -84 l-32 -5 -25 77 c-37 119 -49 226 -43 391 3 80
10 170 17 199 l12 52 29 -21 29 -21 20 44 c14 32 19 64 19 123 l-1 80 -33 -70
c-32 -68 -34 -69 -41 -41 -11 45 13 151 52 228 19 39 37 68 39 66 2 -2 0 -39
-4 -83 -4 -44 -7 -81 -5 -83 4 -6 75 108 107 171 33 65 55 81 138 96 3 1 10
-4 17 -11z m19382 -6 c0 -57 -63 -170 -86 -154 -7 5 2 34 28 90 36 79 58 103
58 64z m-19748 -37 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6
-2 11 -8 11 -13z m7518 -11 c10 -27 5 -48 -10 -39 -4 3 -13 18 -18 35 -9 24
-8 30 4 30 8 0 19 -12 24 -26z m22226 -21 c3 -16 8 -34 10 -41 2 -8 -3 -12
-13 -10 -17 3 -36 61 -25 73 12 12 22 4 28 -22z m-29876 -104 c0 -5 -4 -9 -10
-9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m18295 -108 c-8 -7
-45 36 -45 54 0 7 10 25 23 41 l22 29 3 -59 c2 -32 0 -61 -3 -65z m10592 48
c200 -109 233 -135 275 -214 32 -61 178 -411 178 -427 0 -4 -10 -8 -21 -10
-12 -2 -31 -15 -42 -30 -19 -25 -20 -33 -9 -89 6 -34 32 -102 57 -151 36 -73
45 -101 45 -143 0 -98 -56 -198 -134 -240 l-36 -19 -35 34 c-19 19 -35 37 -35
41 0 3 21 15 48 26 l47 20 -32 17 c-43 22 -49 51 -24 114 12 29 21 59 21 68 0
9 -36 -13 -93 -56 -52 -38 -98 -70 -103 -70 -21 1 -206 287 -290 450 l-39 75
-5 -70 -5 -70 -32 66 c-57 115 -82 260 -83 472 0 96 -7 86 77 117 32 12 53 49
53 93 0 33 29 57 68 57 25 0 71 -19 149 -61z m-28819 -26 c-3 -53 -12 -92 -26
-120 -33 -63 -46 -65 -40 -6 4 47 55 203 66 203 2 0 2 -35 0 -77z m30111 40
c20 -77 30 -214 32 -458 2 -309 4 -289 -24 -321 l-24 -27 -18 44 c-36 88 -26
799 11 799 7 0 18 -17 23 -37z m-22385 -1 c2 -4 -4 -13 -14 -20 -20 -12 -36 0
-25 18 7 12 32 13 39 2z m20630 -265 c9 -133 20 -259 23 -279 5 -34 3 -38 -16
-38 -18 0 -21 6 -21 38 0 21 -13 76 -28 123 -34 100 -49 254 -32 320 19 70 29
89 43 84 10 -4 18 -68 31 -248z m-27252 228 c0 -5 -5 -11 -11 -13 -6 -2 -11 4
-11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m23183 -32 c-7 -27 -17 -83 -23
-125 -10 -79 -36 -144 -65 -162 -9 -6 -18 -8 -20 -6 -10 11 21 238 36 267 16
30 61 73 77 73 4 0 2 -21 -5 -47z m-24739 -25 c-3 -13 -6 -26 -6 -30 0 -5 -4
-8 -10 -8 -5 0 -10 14 -10 30 0 21 5 30 16 30 12 0 15 -6 10 -22z m4629 -33
c-12 -29 -18 -36 -27 -27 -9 9 -8 20 6 48 24 51 44 31 21 -21z m-2931 -57
c-16 -53 -35 -88 -44 -83 -6 3 -6 15 0 30 6 14 14 36 20 50 12 31 34 34 24 3z
m19299 -64 c77 -39 311 -261 413 -390 122 -156 134 -211 85 -411 -51 -211 -64
-306 -57 -431 8 -154 -5 -178 -139 -245 -95 -47 -166 -67 -243 -67 -138 0
-185 36 -195 147 -31 340 -29 563 8 928 17 164 75 485 87 485 5 0 23 -7 41
-16z m1527 -21 c0 -5 -9 -19 -20 -33 -15 -21 -21 -23 -32 -12 -11 11 -9 17 12
32 26 21 40 25 40 13z m7137 -60 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-24765 -25 c-16 -41 -17 -42 -20 -15 -2 16 2 35 9 43 22 27 27 14
11 -28z m4371 9 c99 -46 125 -79 181 -231 95 -258 122 -382 105 -486 -15 -96
-65 -259 -124 -400 -86 -208 -99 -225 -193 -254 -31 -10 -94 -33 -140 -52 -45
-19 -91 -34 -101 -34 -34 0 -75 45 -141 154 l-64 105 -68 16 c-37 8 -73 15
-81 15 -8 0 -19 6 -25 13 -9 10 -4 17 19 31 l30 18 -31 34 c-25 30 -30 43 -30
89 0 29 4 56 8 59 5 3 19 -12 31 -34 12 -22 27 -37 34 -34 20 7 9 130 -18 194
-47 112 -42 165 27 310 36 77 41 83 50 63 8 -17 6 -31 -12 -65 -15 -28 -24
-67 -27 -113 l-5 -70 41 82 c121 247 296 530 361 586 55 46 80 47 173 4z
m15685 -32 c11 -14 28 -55 38 -91 l18 -67 -23 -18 c-30 -25 -73 -24 -96 1 -18
20 -19 20 -66 -32 -40 -44 -50 -50 -59 -38 -15 23 -12 53 11 104 11 26 19 56
17 68 -2 16 10 29 57 59 33 21 65 38 72 38 6 1 20 -10 31 -24z m-508 -79 c0
-14 -4 -28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10 -11 10 -24z
m-21458 -243 c108 -65 195 -119 193 -121 -2 -2 -29 3 -59 12 -38 11 -72 15
-105 10 -40 -5 -59 -1 -119 25 -38 17 -75 31 -80 31 -27 0 -74 83 -107 189
l-7 23 43 -26 c24 -14 133 -79 241 -143z m5855 127 c-6 -16 -17 -30 -24 -30
-9 0 -9 7 -2 30 6 17 17 30 24 30 11 0 11 -6 2 -30z m-112 -90 c18 -20 17 -20
-18 -20 -40 0 -40 0 -31 24 8 22 27 20 49 -4z m-3860 -220 c7 -11 -43 -46 -52
-36 -7 7 25 46 38 46 4 0 10 -4 14 -10z m-4920 -179 c-8 -65 -8 -66 -21 -36
-18 42 -17 86 3 115 16 23 18 23 21 6 2 -10 1 -49 -3 -85z m1635 50 c0 -14
-18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m-735 -41 c-10 -11 -22
-17 -27 -14 -12 8 11 34 30 34 13 0 12 -3 -3 -20z m12360 -26 c-22 -16 -41 -4
-31 21 5 13 10 14 28 4 21 -11 21 -12 3 -25z m14919 -58 c20 -49 35 -90 33
-91 -3 -3 -179 -25 -195 -25 -18 0 -14 51 6 82 15 22 113 125 119 124 0 -1 17
-41 37 -90z m-13809 54 c10 -20 15 -50 13 -83 l-3 -52 -17 32 c-16 28 -26 133
-13 133 2 0 12 -14 20 -30z m-13210 0 c3 -5 4 -10 1 -10 -2 0 -12 -3 -20 -6
-12 -5 -15 -2 -10 10 7 18 20 21 29 6z m29430 -69 c-4 -17 -13 -31 -21 -31
-18 0 -18 4 2 41 19 37 28 32 19 -10z m-19300 -83 c-4 -35 -9 -65 -11 -67 -10
-11 -18 14 -17 50 2 50 11 79 25 79 6 0 8 -22 3 -62z m-10346 41 c17 -6 31
-14 31 -18 0 -4 -15 -22 -34 -40 l-35 -34 -47 28 -48 28 28 23 c30 26 57 30
105 13z m20710 -46 c1 -15 -3 -21 -13 -17 -21 8 -27 33 -14 57 11 21 11 21 19
2 4 -11 8 -30 8 -42z m-4143 -17 l18 -29 -21 12 c-26 13 -28 17 -37 56 -7 28
-6 29 7 10 8 -11 22 -33 33 -49z m9814 49 c19 -23 1 -39 -21 -19 -10 9 -16 21
-13 25 8 13 20 11 34 -6z m-21710 -46 c0 -16 3 -29 6 -29 4 0 27 17 52 37 43
35 45 36 58 18 23 -31 16 -56 -27 -107 l-40 -48 163 0 c139 0 169 -3 206 -20
23 -10 42 -23 42 -28 0 -36 -258 -333 -448 -515 -144 -138 -238 -142 -359 -15
-59 63 -107 151 -130 241 -12 47 -14 89 -10 171 3 59 7 110 9 111 2 2 36 10
75 19 90 18 125 37 189 96 54 51 96 76 159 95 49 15 55 12 55 -26z m409 -3 c8
-10 8 -16 -3 -25 -10 -9 -16 -8 -26 3 -12 15 -6 36 9 36 5 0 14 -6 20 -14z
m-4948 -45 l23 -18 -37 -7 c-20 -4 -61 -18 -89 -31 -52 -24 -132 -33 -142 -17
-6 10 61 77 84 85 8 3 43 6 76 6 46 1 68 -4 85 -18z m4804 -31 c3 -5 -1 -10
-10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m19293 -6 c79 -55
129 -360 87 -528 -35 -136 -237 -486 -281 -486 -50 0 -169 87 -251 184 -135
157 -172 328 -114 514 37 120 58 143 183 208 229 117 324 145 376 108z m-7727
-30 c27 -20 83 -74 124 -120 81 -91 92 -125 59 -172 -14 -20 -23 -23 -67 -20
l-52 3 -3 65 c-3 69 1 61 -125 232 -19 25 -17 48 5 48 6 0 32 -16 59 -36z
m13101 10 c-12 -61 -162 -412 -261 -609 -126 -248 -383 -685 -468 -793 -29
-37 -89 -98 -132 -137 -44 -38 -109 -97 -146 -129 -71 -64 -85 -64 -85 -3 0
34 12 59 56 113 42 50 64 103 64 149 0 35 0 35 45 35 56 0 59 18 10 62 -43 38
-43 38 43 175 l67 105 35 -5 c19 -3 50 -9 68 -13 29 -6 37 -2 70 33 68 72 122
161 122 203 0 30 9 49 44 92 72 89 131 187 245 408 111 214 194 344 214 337 7
-2 10 -13 9 -23z m-29350 -20 c16 -36 8 -41 -159 -97 -134 -45 -233 -67 -301
-67 l-33 0 3 63 3 62 60 -2 c33 -1 89 -3 125 -4 55 -2 78 3 150 34 105 44 136
47 152 11z m13048 0 c14 -35 12 -54 -4 -54 -16 0 -26 21 -26 56 0 32 18 31 30
-2z m3440 -95 c0 -4 -5 -11 -12 -15 -12 -8 -119 106 -111 119 5 8 123 -91 123
-104z m-3130 71 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m12440 -19 c0 -5 -7 -12 -16 -15 -24 -9 -28 -7 -14 9 14 17 30
20 30 6z m-8157 -79 c10 -10 14 -22 10 -25 -7 -8 -43 21 -43 35 0 14 16 9 33
-10z m-9241 -52 c-2 -16 -10 -30 -18 -30 -10 0 -11 6 -3 30 6 17 14 30 18 30
4 0 6 -13 3 -30z m17408 -25 c0 -16 -27 -28 -36 -14 -7 12 15 41 27 33 5 -3 9
-11 9 -19z m-25681 -49 c-10 -10 -79 -18 -79 -9 0 14 63 32 74 22 5 -5 8 -11
5 -13z m1476 -7 c-4 -12 -27 -71 -52 -133 -120 -297 -153 -408 -188 -631 -44
-281 -43 -279 -70 -302 -33 -29 -90 -30 -177 -3 -127 39 -199 137 -234 315
-17 86 -17 93 0 147 13 40 15 61 8 74 -8 14 4 38 62 123 97 145 191 237 286
284 122 59 272 125 310 135 55 16 62 15 55 -9z m400 -4 c50 -8 107 -15 128
-15 20 0 37 -3 37 -7 0 -20 -62 -113 -91 -137 -42 -36 -132 -81 -136 -69 -2 6
-24 39 -49 74 -45 64 -72 135 -57 149 8 8 37 17 63 19 8 0 56 -6 105 -14z
m24175 -15 c-26 -37 -40 -46 -40 -26 0 18 35 56 51 56 6 0 1 -13 -11 -30z
m-5495 -59 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m5447 -57
c-7 -34 -9 -36 -16 -17 -4 12 -2 32 4 43 17 31 22 19 12 -26z m-2817 -44 c-13
-50 -25 -45 -25 11 1 50 2 53 16 34 9 -12 13 -30 9 -45z m3495 7 c30 -18 93
-70 141 -117 74 -73 93 -100 141 -195 70 -138 188 -410 188 -433 0 -27 -56
-117 -92 -147 -80 -68 -199 -98 -324 -84 -171 21 -322 160 -378 349 -25 83
-59 328 -52 374 15 100 105 215 211 271 59 31 85 28 165 -18z m-19162 -5 c2
-7 -2 -12 -8 -12 -17 0 -50 19 -50 29 0 13 53 -3 58 -17z m18001 12 c3 -3 0
-9 -7 -16 -8 -8 -15 -8 -27 2 -22 19 -18 32 8 24 12 -3 24 -8 26 -10z m-22375
-48 c9 -24 34 -100 55 -168 21 -67 50 -150 65 -183 49 -115 30 -197 -89 -376
-173 -260 -369 -375 -511 -302 -77 40 -121 106 -170 253 -25 72 -29 101 -29
190 0 88 4 113 23 154 52 113 190 250 374 373 108 72 175 102 229 103 34 0 37
-3 53 -44z m1391 34 c3 -5 -2 -21 -11 -35 -15 -24 -19 -25 -32 -11 -14 14 -14
17 3 36 20 22 31 25 40 10z m8240 -50 c-10 -11 -22 -17 -27 -14 -12 8 11 34
30 34 13 0 12 -3 -3 -20z m-4775 -80 c-6 -12 -21 -20 -36 -20 l-25 0 28 50 28
50 8 -30 c4 -18 3 -38 -3 -50z m5246 -24 c-9 -23 -27 -54 -42 -71 l-25 -30 6
40 c3 22 20 69 38 105 32 65 32 65 35 31 2 -19 -4 -53 -12 -75z m-112 57 c-8
-32 -24 -55 -24 -32 0 26 12 59 21 59 5 0 6 -12 3 -27z m-5544 11 c0 -12 -38
-53 -43 -48 -7 8 22 54 34 54 5 0 9 -3 9 -6z m-7670 -73 c0 -11 -31 -24 -39
-16 -3 3 1 10 9 15 19 12 30 12 30 1z m25079 -189 c69 -73 134 -150 143 -170
9 -20 38 -105 63 -188 45 -149 45 -151 29 -190 -18 -43 -22 -149 -10 -256 l7
-67 -34 -6 c-82 -16 -237 97 -372 270 -112 144 -130 210 -101 368 16 82 16 93
1 144 -21 73 -13 104 41 152 24 21 44 42 44 45 0 4 -11 22 -25 40 -14 18 -22
36 -18 39 18 18 114 -57 232 -181z m-20556 116 c-18 -33 -126 -98 -140 -85
-12 12 47 75 89 96 62 31 74 28 51 -11z m13037 -56 c0 -11 -22 -32 -32 -32 -5
0 -4 9 2 20 11 20 30 28 30 12z m-4354 -145 c-9 -32 -27 -64 -47 -82 l-31 -30
7 35 c12 58 75 166 83 142 2 -6 -3 -35 -12 -65z m-140 36 c-3 -10 -8 -30 -11
-45 -4 -16 -12 -25 -20 -22 -18 7 2 84 22 84 9 0 12 -7 9 -17z m-301 -12 c8
-14 -27 -81 -43 -81 -7 0 -12 3 -10 8 2 4 10 24 18 45 14 37 24 45 35 28z
m4760 -21 c5 -21 2 -39 -9 -55 -19 -29 -27 -30 -68 -9 l-32 17 42 38 c23 22
46 39 51 39 5 0 12 -14 16 -30z m11191 -5 c-4 -14 -11 -25 -16 -25 -13 0 -13
16 1 42 14 26 23 16 15 -17z m-92 -8 c-13 -22 -37 -47 -46 -47 -15 0 -8 23 13
47 22 22 45 22 33 0z m-1066 -87 c8 -22 8 -30 -1 -30 -27 0 -40 18 -33 44 8
32 20 27 34 -14z m-10358 -46 c0 -40 -3 -54 -12 -51 -8 2 -12 19 -10 43 6 82
22 88 22 8z m1750 -16 c0 -5 -4 -8 -10 -8 -5 0 -10 10 -10 23 0 18 2 19 10 7
5 -8 10 -18 10 -22z m8868 -50 c-2 -6 -18 -22 -36 -36 l-32 -25 0 60 c0 33 3
63 7 67 10 9 65 -52 61 -66z m-205 30 c14 -18 28 -43 32 -56 6 -22 4 -23 -27
-20 -32 3 -33 4 -36 56 -2 28 -1 52 2 52 3 0 16 -15 29 -32z m-88 -99 c-12
-18 -45 11 -45 39 l1 27 24 -28 c14 -16 23 -33 20 -38z m-23976 -37 c80 -83
107 -128 97 -166 -4 -16 -39 -58 -79 -96 l-72 -68 -3 119 c-2 92 -6 119 -16
119 -8 0 -16 7 -20 15 -8 23 6 145 17 145 5 0 40 -31 76 -68z m20501 17 c0
-10 -30 -20 -41 -13 -13 7 -11 28 3 42 10 10 15 9 25 -5 7 -9 13 -20 13 -24z
m-2335 -58 c-10 -16 -25 -3 -25 22 0 20 1 20 16 5 9 -9 13 -21 9 -27z m-4495
2 c0 -93 -66 -141 -157 -117 -20 5 -14 13 55 69 81 67 102 77 102 48z m-17650
-47 c0 -2 3 -12 6 -21 6 -14 3 -14 -20 -4 -31 14 -34 29 -6 29 11 0 20 -2 20
-4z m5420 -108 c0 -33 -14 -50 -33 -42 -15 5 -15 9 4 35 25 35 29 36 29 7z
m19055 -37 c-33 -48 -45 -52 -45 -12 0 20 9 36 27 51 40 31 50 10 18 -39z
m-5105 19 c0 -16 -4 -30 -9 -30 -5 0 -11 14 -13 30 -3 20 0 30 9 30 8 0 13
-12 13 -30z m8423 5 c20 -14 37 -28 37 -30 0 -6 -202 -125 -212 -125 -20 0 27
80 75 126 28 28 54 52 57 53 3 0 22 -10 43 -24z m-19260 -115 c210 -175 264
-254 288 -412 17 -117 3 -176 -81 -343 -39 -77 -70 -146 -70 -153 0 -29 -51
-111 -99 -155 -98 -91 -226 -131 -371 -116 -90 10 -130 34 -195 121 -65 86
-87 139 -125 306 -27 119 -32 157 -27 217 4 54 2 75 -7 78 -9 4 -18 -19 -30
-76 -23 -111 -25 -117 -40 -117 -17 0 -48 111 -48 171 0 109 111 259 192 259
11 0 20 7 20 15 0 52 88 163 158 199 17 9 47 36 69 61 33 39 46 46 103 59 36
8 75 14 86 15 14 1 79 -47 177 -129z m-8380 94 c18 -9 47 -27 65 -41 17 -13
48 -30 67 -36 l36 -13 -30 -28 -30 -29 -43 35 c-24 20 -66 56 -93 81 -44 41
-47 46 -27 47 13 0 37 -7 55 -16z m20270 -59 c145 -30 273 -55 282 -55 22 0
55 28 55 47 0 7 5 13 11 13 9 0 175 -139 252 -209 16 -16 16 -21 -4 -101 -29
-113 -22 -180 25 -274 42 -82 42 -88 17 -124 -16 -23 -20 -52 -25 -202 -3 -96
-10 -192 -17 -214 -19 -69 -71 -112 -184 -151 -124 -43 -185 -46 -237 -10 -20
14 -73 73 -118 132 -107 143 -219 367 -285 568 -26 83 -65 193 -86 246 -37 95
-61 192 -74 301 l-7 58 33 15 c19 8 48 14 66 15 17 0 150 -25 296 -55z
m-16605 -24 c-15 -24 -28 -27 -28 -6 0 13 31 38 38 30 2 -2 -3 -12 -10 -24z
m21522 9 c0 -11 -4 -20 -9 -20 -5 0 -11 9 -14 20 -3 13 0 20 9 20 8 0 14 -9
14 -20z m-20930 5 c0 -5 -116 -85 -124 -85 -9 0 14 54 31 72 14 16 93 27 93
13z m14680 -91 c0 -19 -4 -34 -10 -34 -5 0 -10 18 -10 41 0 24 4 38 10 34 6
-3 10 -22 10 -41z m4454 27 c15 -17 13 -51 -3 -51 -4 0 -22 16 -41 35 l-34 35
30 0 c17 0 38 -8 48 -19z m-16824 -7 c0 -13 -104 -353 -124 -406 -31 -83 -79
-177 -105 -208 l-22 -25 4 65 c2 48 13 87 41 150 21 47 46 108 56 135 9 28 41
107 69 177 39 93 56 125 67 121 8 -3 14 -7 14 -9z m-6360 -34 c0 -5 -6 -10
-14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m21900 -129
c17 -42 60 -134 97 -205 72 -140 67 -151 -24 -53 -83 91 -153 247 -153 341 l0
39 25 -23 c13 -12 38 -57 55 -99z m-4590 39 c0 -19 -61 -31 -67 -13 -2 6 8 13
24 16 15 2 31 5 36 6 4 0 7 -4 7 -9z m7840 -10 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-20850 -44 c0 -11 -19 -15 -25 -6
-3 5 1 10 9 10 9 0 16 -2 16 -4z m19227 -143 c52 -54 89 -99 84 -101 -6 -2
-25 1 -43 7 -18 5 -52 15 -75 21 -40 10 -42 12 -66 84 -40 119 -23 117 100
-11z m-23352 58 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z
m12525 -46 c-9 -11 -17 -12 -32 -4 -15 8 -17 13 -8 24 9 11 17 12 32 4 15 -8
17 -13 8 -24z m-8110 -11 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15
-7z m15149 -388 c78 -148 139 -271 136 -274 -2 -2 -34 1 -70 8 -110 21 -137
69 -194 340 -28 133 -36 187 -28 192 6 4 12 6 13 5 1 -1 66 -123 143 -271z
m-15985 245 c7 -11 -39 -73 -47 -64 -11 10 -8 19 14 47 22 29 25 30 33 17z
m276 -11 c0 -5 -9 -14 -20 -20 -22 -12 -27 -1 -8 18 14 14 28 16 28 2z m16779
-46 c2 -2 -10 -4 -27 -4 -24 0 -30 4 -26 15 5 12 10 12 27 3 12 -6 24 -12 26
-14z m98 -46 c12 -10 19 -98 8 -98 -3 0 -32 7 -65 16 l-59 17 25 48 c23 46 25
48 51 38 15 -6 33 -15 40 -21z m6793 22 c0 -17 -39 -50 -60 -50 -24 0 -26 22
-4 44 17 18 64 22 64 6z m-21460 -70 c0 -30 -17 -50 -41 -50 -17 0 -16 4 3 51
22 52 38 51 38 -1z m-2362 -27 c4 -40 -7 -41 -48 -7 -35 30 -26 46 20 42 20
-3 26 -9 28 -35z m127 28 c3 -5 -6 -22 -19 -37 -20 -24 -26 -26 -40 -15 -22
18 -20 24 12 43 31 20 39 21 47 9z m23615 -9 c0 -37 -40 -69 -273 -223 -210
-139 -295 -184 -312 -167 -6 5 293 244 370 295 78 52 186 113 203 113 6 0 12
-8 12 -18z m-6590 -159 c0 -24 -18 -13 -29 19 -11 31 -11 31 9 13 11 -10 20
-24 20 -32z m-20012 -14 c66 -35 83 -40 119 -35 36 5 49 1 95 -30 29 -20 77
-48 105 -61 56 -27 88 -67 99 -122 6 -30 6 -31 -32 -31 -55 1 -121 33 -179 89
-38 38 -64 53 -110 66 -73 20 -143 54 -193 94 -20 16 -48 32 -61 36 -24 7 -24
8 -6 21 33 24 82 16 163 -27z m19832 -29 c0 -22 -4 -40 -10 -40 -5 0 -10 8
-10 18 0 9 -3 27 -6 40 -5 16 -2 22 10 22 12 0 16 -10 16 -40z m90 -10 c0 -11
-4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m105 -78
l0 -67 48 -8 c63 -12 65 -37 2 -37 -65 0 -85 23 -85 98 0 31 3 67 6 80 12 43
29 4 29 -66z m-19615 48 c0 -4 -3 -9 -6 -13 -9 -9 -55 23 -49 34 6 9 55 -10
55 -21z m1725 -70 c-3 -5 -21 -10 -38 -10 -24 0 -28 3 -17 10 20 13 63 13 55
0z m17755 -43 c0 -43 -38 -60 -74 -34 -17 13 -16 15 15 35 44 28 59 28 59 -1z
m-19166 -38 c-4 -6 -11 -6 -20 2 -21 17 -17 31 6 19 11 -6 18 -15 14 -21z
m6716 -109 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m17755 -170 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m-20390 -270 c-15 -30 -47 -45 -82 -38 -13 2 -5 11 32 35 63 40
69 40 50 3z m1142 -297 c27 -16 150 -100 273 -187 l223 -160 -39 -29 c-22 -17
-158 -150 -304 -298 -223 -225 -275 -272 -327 -298 -34 -17 -66 -31 -71 -31
-25 0 -36 121 -37 384 0 228 3 282 18 341 37 144 138 305 192 305 12 0 45 -12
72 -27z m14267 -28 c155 -77 274 -192 317 -305 22 -59 22 -88 0 -330 -14 -156
-30 -203 -82 -237 -46 -30 -59 -67 -59 -165 l0 -68 -77 0 -78 -1 25 -20 c14
-12 43 -37 64 -57 37 -33 38 -36 21 -48 -25 -19 -78 -27 -119 -20 -47 9 -108
66 -245 230 -65 77 -171 200 -235 271 -142 157 -159 188 -143 263 12 57 9 69
-44 189 l-23 53 20 27 c20 27 257 154 349 187 22 7 67 25 100 40 82 35 124 33
209 -9z m-17090 -154 c6 -10 -44 -34 -53 -26 -3 4 3 13 13 21 23 16 33 18 40
5z m-173 -73 c3 -52 -18 -83 -50 -74 -9 2 -28 7 -44 10 -25 6 -26 9 -16 29 22
42 42 57 76 57 27 0 33 -4 34 -22z m15851 -55 c40 -39 47 -53 25 -53 -12 0
-77 71 -77 84 0 14 17 4 52 -31z m-15986 -33 c-3 -11 -8 -29 -11 -39 -6 -19
-7 -19 -40 1 -19 11 -35 24 -35 29 0 12 38 28 68 28 19 1 22 -3 18 -19z
m18721 -34 l66 -45 -79 -50 c-43 -28 -80 -49 -82 -47 -8 8 11 186 20 186 5 0
38 -20 75 -44z m-397 -21 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25
10 25 6 0 10 -11 10 -25z m-18443 -17 c13 -12 23 -25 23 -29 0 -5 -20 -20 -45
-35 -54 -31 -135 -57 -135 -43 0 37 85 129 119 129 8 0 25 -10 38 -22z m18633
-32 c0 -63 -15 -75 -54 -45 -35 27 -34 64 2 83 46 25 52 21 52 -38z m-14745
-11 c59 -20 105 -51 105 -69 0 -7 -11 -29 -24 -48 -17 -26 -21 -40 -14 -52 11
-20 2 -68 -23 -118 -24 -48 -24 -51 4 -37 39 22 148 133 175 179 14 25 32 53
40 63 17 19 75 23 118 7 l28 -11 -69 -62 c-181 -164 -341 -284 -512 -386 -56
-33 -122 -76 -148 -95 -37 -28 -56 -36 -89 -36 -34 0 -58 -9 -109 -42 -228
-146 -291 -178 -354 -178 -18 0 -56 -11 -84 -26 -37 -18 -63 -24 -97 -22 -43
3 -47 5 -47 28 0 21 11 30 64 56 73 35 91 55 91 101 0 28 7 37 60 73 69 47
120 95 296 280 236 247 334 360 334 385 0 14 7 26 18 29 35 10 188 -2 237 -19z
m-4071 -157 c-9 -18 -54 -78 -99 -133 -141 -173 -265 -343 -265 -365 0 -29 26
-36 66 -16 61 29 71 17 28 -33 -24 -29 -30 -31 -94 -31 -88 0 -109 -17 -205
-165 -75 -114 -98 -157 -211 -385 -92 -187 -96 -200 -168 -486 -50 -196 -71
-263 -91 -290 -28 -37 -37 -41 -53 -21 -20 23 4 220 37 301 16 39 36 106 45
149 8 42 36 139 61 215 39 121 48 140 75 156 43 25 75 78 110 181 35 102 66
152 264 420 211 287 265 354 337 425 37 36 76 81 88 100 12 21 34 39 53 46 31
10 33 9 36 -12 2 -12 -5 -38 -14 -56z m18470 -274 c9 -24 -3 -35 -24 -22 -14
9 -16 16 -8 25 14 17 25 16 32 -3z m-1365 -88 c9 -10 9 -16 1 -21 -16 -10 -40
2 -40 20 0 19 24 20 39 1z m1031 -45 c0 -46 -24 -79 -87 -123 l-46 -31 6 53
c4 29 13 63 22 76 17 27 86 74 98 67 4 -2 7 -21 7 -42z m-18295 20 c-3 -6 -11
-11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m18515 -57 c11 -25 20 -54 20
-65 0 -19 -55 -88 -63 -79 -2 3 -7 47 -11 98 -6 89 -6 92 14 92 15 0 26 -12
40 -46z m-1101 -81 c-8 -9 -16 -10 -22 -4 -13 13 0 31 19 24 11 -4 12 -8 3
-20z m1997 -9 c55 -14 126 -40 159 -58 48 -26 68 -32 103 -28 l42 4 26 -57
c20 -43 28 -83 36 -173 9 -102 14 -123 38 -162 16 -25 40 -70 53 -100 35 -77
81 -263 73 -293 -14 -58 -88 -117 -145 -117 -81 0 -285 140 -570 391 -241 213
-249 226 -182 303 56 65 74 101 69 147 -3 34 -1 38 26 43 15 3 44 6 62 6 26 0
34 4 34 19 0 35 23 83 44 91 29 12 23 13 132 -16z m-15121 -14 c0 -13 -6 -25
-12 -27 -9 -3 -13 6 -13 27 0 21 4 30 13 28 6 -3 12 -15 12 -28z m16715 10 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-18762 -45 c24 -29 25 -32 18 -137 -14 -234 -59 -361 -163 -459 -142 -132
-398 -347 -449 -374 l-55 -30 -21 25 c-76 91 -128 289 -109 417 23 156 76 256
201 383 138 139 315 214 489 207 56 -3 67 -6 89 -32z m2003 -53 c7 -17 5 -22
-9 -22 -25 0 -42 20 -42 48 0 23 1 23 22 10 11 -8 25 -24 29 -36z m89 -17 c0
-8 -9 -24 -21 -36 -16 -16 -23 -18 -35 -8 -20 17 -17 26 14 43 35 19 42 19 42
1z m16118 -13 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z
m-16576 -196 c5 -43 -19 -43 -37 0 -18 45 -19 42 3 46 20 5 29 -8 34 -46z
m-764 -86 c4 0 -16 -22 -43 -50 -27 -27 -52 -50 -56 -50 -3 0 -14 27 -23 61
-24 85 -13 96 61 64 31 -14 58 -25 61 -25z m642 46 c0 -11 -19 -15 -25 -6 -3
5 1 10 9 10 9 0 16 -2 16 -4z m0 -96 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3
6 10 10 16 10 5 0 9 -4 9 -10z m-4202 -62 c-8 -13 -27 -44 -42 -70 -18 -31
-32 -45 -42 -41 -17 6 -17 4 3 54 9 24 29 49 47 60 41 25 51 24 34 -3z m1087
-164 c21 -9 41 -49 50 -104 4 -25 14 -73 22 -107 19 -77 11 -123 -27 -166
l-29 -33 20 -30 c26 -40 24 -74 -9 -120 -69 -95 -132 -124 -270 -124 -91 0
-168 21 -281 76 -106 52 -165 101 -181 149 -11 35 5 89 88 290 33 79 14 72
167 59 77 -6 83 -5 150 27 69 32 234 87 267 88 9 1 24 -2 33 -5z m-1240 -145
c-15 -54 -21 -66 -31 -56 -11 10 17 107 31 107 11 0 11 -9 0 -51z m21654 -38
c36 -27 76 -60 88 -73 52 -56 92 -191 108 -369 6 -62 4 -83 -10 -114 -24 -49
-37 -56 -198 -110 l-139 -47 -51 17 c-68 22 -256 118 -286 146 -20 18 -22 29
-18 66 7 61 56 156 123 237 138 167 271 293 313 296 2 0 33 -22 70 -49z m-619
-82 c0 -17 -40 -79 -51 -79 -13 0 -12 6 11 50 19 38 40 53 40 29z m-18502 -66
c2 -9 -7 -13 -27 -13 -31 0 -42 14 -18 23 19 8 40 3 45 -10z m-2617 -96 c-13
-13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m21048 -3 c11 -10 -14
-80 -41 -110 -34 -38 -48 -33 -48 18 0 19 -9 33 -27 45 -16 9 -30 18 -31 19
-2 1 -1 12 3 24 6 20 11 21 73 14 37 -3 69 -8 71 -10z m-21079 -162 c0 -15 -8
-36 -17 -47 -15 -19 -15 -18 -9 14 3 18 6 42 6 53 0 10 5 16 10 13 6 -3 10
-18 10 -33z m690 -53 c0 -13 -51 -19 -70 -9 -22 12 -26 41 -9 73 12 20 13 20
45 -18 19 -22 34 -43 34 -46z m21510 42 c0 -13 -26 -32 -33 -24 -8 7 11 33 24
33 5 0 9 -4 9 -9z m-20560 -109 c0 -33 -32 -50 -160 -85 -201 -56 -264 -67
-393 -70 -128 -2 -160 6 -216 51 -47 38 -8 42 245 30 l229 -11 59 36 c53 33
105 49 191 60 17 2 33 5 38 6 4 0 7 -7 7 -17z m-966 -32 c-5 -5 -24 -13 -42
-16 -25 -5 -32 -3 -32 10 0 12 10 16 42 16 27 0 39 -3 32 -10z m-854 -67 c25
-33 21 -58 -26 -162 -29 -63 -36 -72 -49 -61 -9 7 -15 29 -15 54 0 42 48 196
61 196 4 0 17 -12 29 -27z m22760 -50 c-1 -12 -51 -46 -57 -39 -9 8 27 46 44
46 7 0 13 -3 13 -7z m-21635 -58 l30 -13 -28 -11 c-43 -17 -116 17 -91 42 8 8
46 0 89 -18z m155 -13 c0 -8 -43 -32 -55 -32 -15 0 -1 22 18 30 29 11 37 12
37 2z m2027 -93 c70 -26 59 -53 -79 -186 -68 -65 -141 -136 -162 -158 -81 -82
-168 -321 -195 -540 -6 -49 -20 -161 -31 -248 -18 -148 -21 -159 -47 -183 -40
-36 -102 -64 -146 -64 -31 0 -45 -7 -71 -35 -41 -43 -65 -44 -137 -7 -38 19
-67 44 -97 85 -24 31 -63 71 -88 88 -24 16 -51 41 -60 54 -42 65 -57 231 -29
335 8 30 31 89 51 130 33 66 58 96 213 248 207 204 236 228 401 321 142 81
241 124 343 151 86 22 97 23 134 9z m16394 -153 c89 -60 -4 -38 -101 23 l-35
23 41 -5 c25 -3 63 -19 95 -41z m3109 -67 c0 -55 -28 -74 -102 -72 -105 3 -94
24 24 48 36 7 58 31 58 63 0 21 2 24 10 12 5 -8 10 -31 10 -51z m-22826 -95
c6 -21 1 -33 -20 -55 -31 -33 -44 -25 -44 28 0 51 53 73 64 27z m22616 -74
c13 -8 13 -11 0 -24 -18 -19 -46 -5 -37 18 7 18 16 20 37 6z m-275 -10 c3 -5
-15 -23 -41 -40 -55 -35 -104 -40 -104 -12 0 13 17 24 63 39 75 26 74 25 82
13z m-1914 -102 c54 -16 112 -68 139 -125 38 -82 67 -85 94 -12 l18 52 49 -6
c27 -3 63 -14 80 -24 17 -10 79 -78 139 -151 59 -73 177 -207 262 -298 84 -91
153 -170 153 -176 0 -14 -117 -84 -184 -109 -106 -40 -187 -51 -331 -46 -144
4 -178 13 -252 66 l-38 26 0 111 c0 240 -71 461 -207 645 l-43 59 41 0 c23 0
59 -5 80 -12z m-20441 -85 c0 -52 -1 -55 -15 -37 -18 24 -19 48 -3 74 7 11 14
20 15 20 2 0 3 -26 3 -57z m1088 45 c21 -21 13 -32 -50 -73 -77 -50 -78 -51
-78 -27 0 25 35 69 74 93 37 22 38 23 54 7z m-224 -196 c10 -17 -28 -78 -91
-143 -65 -68 -83 -80 -83 -58 0 13 151 209 162 209 4 0 9 -3 12 -8z m276 -160
c0 -30 -43 -72 -73 -72 -35 0 -72 33 -92 82 l-17 43 58 54 58 54 33 -69 c18
-38 33 -79 33 -92z m21644 61 c-4 -21 -11 -46 -16 -56 -10 -19 -76 -52 -85
-43 -5 4 95 136 103 136 3 0 2 -17 -2 -37z m-20654 10 c0 -5 -5 -15 -10 -23
-8 -12 -10 -11 -10 8 0 12 5 22 10 22 6 0 10 -3 10 -7z m-2060 -84 c0 -4 -10
-15 -22 -23 -19 -14 -23 -14 -34 2 -11 14 -10 19 2 28 16 12 54 8 54 -7z
m19391 -40 c11 -5 17 -14 14 -20 -7 -11 -45 7 -45 21 0 13 7 13 31 -1z
m-18046 -200 c83 -89 155 -171 162 -183 17 -30 16 -65 -6 -137 -11 -35 -24
-98 -30 -138 -27 -203 -193 -373 -415 -428 -66 -16 -70 -16 -105 3 -73 39
-151 251 -151 409 0 177 84 397 192 502 45 44 174 133 193 133 5 0 78 -73 160
-161z m18914 59 c12 -56 14 -101 5 -147 -8 -40 -19 -39 -40 2 -19 36 -34 49
-51 43 -22 -9 -36 48 -22 93 13 43 18 48 36 30 8 -8 14 0 23 27 12 34 14 36
26 20 7 -10 18 -40 23 -68z m3096 -8 c3 -5 1 -19 -5 -30 -9 -17 -14 -19 -25
-10 -11 9 -12 16 -2 31 13 21 23 24 32 9z m-23366 -52 c-8 -14 -14 -17 -21
-10 -15 15 -2 43 16 36 12 -5 13 -10 5 -26z m19411 22 c12 -8 11 -10 -7 -10
-12 0 -25 5 -28 10 -8 13 15 13 35 0z m3618 -205 c-6 -13 -14 -22 -19 -20 -12
8 2 45 17 45 11 0 11 -5 2 -25z m-882 -150 c49 -32 53 -49 39 -175 -7 -63 -18
-135 -25 -160 -18 -67 -68 -156 -120 -211 -40 -42 -51 -49 -85 -49 -34 0 -45
7 -84 49 -55 57 -171 256 -171 291 0 35 51 120 95 158 35 32 126 82 195 108
42 17 123 11 156 -11z m834 -64 c0 -16 -8 -35 -17 -42 -15 -13 -16 -11 -10 11
4 14 7 33 7 43 0 9 5 17 10 17 6 0 10 -13 10 -29z m-200 -6 c0 -13 -24 -54
-30 -50 -12 8 2 55 16 55 8 0 14 -2 14 -5z m-4136 -67 c9 -12 16 -42 16 -66 0
-39 -2 -42 -19 -33 -24 13 -40 60 -34 94 6 33 17 34 37 5z m3649 0 c12 -18
-18 -314 -34 -339 -16 -27 -31 8 -36 89 -7 104 21 275 44 270 9 -2 21 -11 26
-20z m641 -13 c-4 -8 -11 -15 -16 -15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1
-7 -2 -15z m-3934 -84 c0 -16 -28 -20 -34 -5 -4 9 1 14 14 14 11 0 20 -4 20
-9z m3348 -3 c7 -7 12 -28 12 -47 0 -33 0 -34 -20 -16 -11 10 -20 31 -20 47 0
30 9 35 28 16z m550 -24 c3 -19 -82 -214 -162 -372 -31 -61 -60 -92 -73 -78
-6 5 70 182 150 349 57 121 78 146 85 101z m-3283 -85 c4 -11 4 -23 1 -27 -8
-7 -36 30 -30 40 9 14 23 8 29 -13z m130 -59 c-21 -23 -37 -25 -68 -8 -22 12
-21 12 8 19 17 4 41 7 54 8 23 1 24 0 6 -19z m115 -29 c-1 -13 -3 -13 -15 3
-19 26 -19 42 0 26 8 -7 15 -20 15 -29z m2759 -423 c-48 -94 -104 -185 -156
-249 l-47 -57 -40 20 c-23 11 -43 22 -45 24 -2 2 45 55 106 116 60 62 132 146
159 187 43 66 49 72 52 49 2 -15 -11 -54 -29 -90z m-19202 56 c10 -27 -10 -32
-62 -17 -42 13 -47 17 -30 24 35 14 85 10 92 -7z m-1437 -34 c0 -46 -10 -60
-59 -84 -71 -36 -91 -40 -91 -21 0 20 129 146 142 139 4 -3 8 -18 8 -34z m318
-12 c12 -24 24 -55 27 -70 3 -15 19 -40 36 -56 25 -24 32 -39 38 -90 4 -34 4
-95 -1 -135 -26 -227 -53 -407 -68 -446 l-17 -44 -33 48 c-18 27 -33 53 -33
59 0 6 17 48 36 94 l36 82 -53 0 -53 0 -12 -42 c-37 -124 -132 -246 -226 -289
-53 -24 -86 -24 -119 2 -32 25 -105 167 -171 333 -45 113 -47 123 -43 195 9
146 41 173 213 181 156 8 204 29 320 140 46 44 88 80 93 80 5 0 19 -19 30 -42z
m1282 -95 c18 -23 21 -36 16 -73 -20 -148 -20 -143 11 -171 15 -15 45 -32 66
-39 20 -6 37 -17 37 -25 0 -24 -50 -186 -69 -223 -25 -49 -89 -122 -106 -122
-7 0 -23 12 -35 28 -22 27 -23 67 -5 128 7 23 -14 38 -37 28 -7 -3 -45 -53
-85 -112 -146 -217 -218 -284 -325 -301 -62 -10 -162 3 -197 26 -23 15 -23 17
-16 117 5 79 3 111 -9 146 -15 43 -15 46 14 105 39 80 53 182 40 289 -5 44
-10 84 -10 87 0 13 213 57 305 64 100 7 189 25 280 54 81 27 100 26 125 -6z
m220 21 c0 -21 -24 -94 -31 -94 -5 0 -15 11 -21 25 -10 22 -8 29 12 50 22 23
40 32 40 19z m17196 -135 c34 -16 78 -47 98 -69 58 -65 239 -443 234 -489 -4
-37 -58 -49 -223 -50 -169 -1 -211 13 -244 77 -13 25 -31 43 -49 50 l-29 10
-33 237 c-21 154 -29 240 -22 247 15 15 102 27 157 21 28 -2 78 -18 111 -34z
m-1199 -8 c76 -59 169 -169 207 -241 40 -77 53 -132 62 -261 7 -110 0 -133
-51 -155 -28 -12 -41 -11 -91 2 -82 21 -131 52 -201 128 -69 73 -107 144 -124
229 -11 55 -10 62 27 174 40 118 68 163 103 163 10 0 41 -17 68 -39z m-18467
-227 c0 -11 -6 -29 -14 -40 -14 -18 -16 -17 -35 26 -21 46 -26 94 -14 126 5
13 13 4 34 -37 15 -30 28 -63 29 -75z m20566 99 c-10 -10 -19 5 -10 18 6 11 8
11 12 0 2 -7 1 -15 -2 -18z m464 -32 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37
5 0 9 -4 9 -9z m-1780 -107 c0 -38 -3 -45 -17 -42 -10 2 -22 15 -27 30 -8 22
-6 32 9 47 25 28 35 18 35 -35z m891 -252 l22 -19 -48 -16 c-27 -10 -50 -17
-52 -17 -6 0 -3 19 7 44 11 31 38 34 71 8z m-2571 -67 c-7 -8 -19 -15 -27 -15
-13 0 -13 3 -3 15 7 8 19 15 27 15 13 0 13 -3 3 -15z m1690 -5 c0 -5 -7 -10
-15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-140 -100 c-20
-38 -14 -74 21 -123 60 -86 38 -177 -47 -193 -36 -7 -140 12 -150 27 -3 6 7
27 23 47 33 41 93 66 135 58 39 -8 28 16 -39 85 -48 50 -54 59 -44 78 10 17
99 55 109 46 2 -2 -2 -13 -8 -25z m-18215 -89 c-9 -16 -25 -3 -18 15 4 10 9
12 15 6 6 -6 7 -15 3 -21z m665 -86 c0 -8 -78 -45 -96 -45 -19 0 -54 27 -54
42 0 4 34 8 75 8 41 0 75 -2 75 -5z m-144 -363 c-6 -19 -66 -49 -66 -34 0 9
64 62 68 57 2 -2 1 -12 -2 -23z"
      />
      <path d="M23555 25421 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z" />
      <path
        d="M24065 28918 c-14 -67 -35 -244 -35 -304 0 -81 8 -114 25 -114 8 0
15 44 24 148 6 81 14 180 18 220 5 62 4 72 -10 72 -9 0 -19 -10 -22 -22z"
      />
      <path d="M14352 27691 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z" />
      <path
        d="M31645 25632 c-16 -11 -36 -26 -43 -35 -12 -14 -7 -16 45 -22 89 -9
126 -22 177 -61 26 -20 49 -34 52 -32 4 4 -43 79 -68 108 -51 60 -109 75 -163
42z"
      />
      <path
        d="M8625 25800 c-3 -5 21 -23 54 -40 38 -19 62 -26 66 -19 9 13 10 13
-57 42 -32 14 -60 22 -63 17z"
      />
      <path
        d="M25080 22850 c41 -17 142 -28 127 -14 -8 8 -125 33 -152 33 -11 0 -1
-8 25 -19z"
      />
      <path
        d="M24606 21654 c-25 -24 -19 -30 28 -29 38 0 43 2 33 15 -26 34 -39 37
-61 14z"
      />
      <path
        d="M24657 21603 c-11 -10 -8 -51 3 -58 6 -4 10 8 10 29 0 37 -2 41 -13
29z"
      />
      <path
        d="M15981 22348 c-5 -12 -7 -24 -6 -26 6 -7 45 23 42 32 -7 20 -27 17
-36 -6z"
      />
      <path
        d="M17733 19600 c-23 -24 39 -100 81 -100 24 0 19 11 -27 63 -32 37 -45
46 -54 37z"
      />
      <path d="M18176 18654 c-7 -19 3 -64 15 -64 5 0 9 18 9 40 0 40 -13 53 -24 24z" />
      <path
        d="M36240 21218 c0 -42 5 -47 19 -19 7 13 6 24 -4 37 -13 18 -14 16 -15
-18z"
      />
      <path d="M8314 21469 l-29 -31 55 22 c84 33 91 39 44 40 -32 0 -46 -6 -70 -31z" />
      <path
        d="M31764 21556 c-10 -8 -16 -18 -12 -22 8 -7 65 13 74 27 9 15 -41 10
-62 -5z"
      />
      <path
        d="M27004 17860 c3 -19 18 -46 32 -60 l26 -25 -27 55 c-34 69 -40 74
-31 30z"
      />
      <path
        d="M5897 16556 c-15 -22 -13 -36 4 -36 8 0 22 50 16 56 -2 3 -11 -6 -20
-20z"
      />
      <path
        d="M5596 16009 c-9 -45 -8 -49 9 -49 11 0 15 11 15 40 0 48 -15 53 -24
9z"
      />
      <path
        d="M13787 11093 c-3 -5 6 -16 19 -26 26 -20 31 -12 10 16 -14 18 -22 21
-29 10z"
      />
      <path
        d="M10937 6044 c-3 -3 -1 -19 5 -35 12 -35 44 -41 34 -6 -3 12 -6 28 -6
35 0 12 -22 17 -33 6z"
      />
      <path
        d="M12214 6069 c-12 -20 12 -32 37 -18 23 12 23 12 4 20 -28 11 -33 10
-41 -2z"
      />
      <path
        d="M12200 5930 c0 -25 4 -39 10 -35 6 3 10 19 10 35 0 16 -4 32 -10 35
-6 4 -10 -10 -10 -35z"
      />
      <path
        d="M32849 32370 c-12 -95 -11 -101 7 -115 10 -8 22 -15 25 -15 22 -1 25
146 5 195 -8 19 -17 35 -19 35 -3 0 -10 -45 -18 -100z"
      />
      <path
        d="M34613 32042 c-45 -17 -53 -29 -27 -39 21 -8 60 11 74 37 12 23 10
23 -47 2z"
      />
      <path
        d="M33551 30506 c-10 -11 -6 -18 18 -35 23 -17 32 -34 41 -77 10 -50 20
-63 93 -138 75 -75 144 -122 167 -114 5 2 -51 86 -125 188 -137 189 -164 213
-194 176z"
      />
      <path
        d="M33996 29962 c-2 -4 1 -18 8 -31 8 -15 16 -20 20 -13 4 7 2 21 -4 32
-12 22 -16 24 -24 12z"
      />
      <path
        d="M5370 27805 c0 -32 47 -82 85 -91 29 -7 29 -6 -24 54 -29 34 -55 62
-57 62 -2 0 -4 -11 -4 -25z"
      />
      <path
        d="M5490 25475 c0 -17 2 -18 24 -9 18 7 12 24 -10 24 -8 0 -14 -7 -14
-15z"
      />
      <path
        d="M36803 25438 c-24 -12 -29 -23 -13 -33 12 -8 50 16 50 32 0 15 -7 15
-37 1z"
      />
      <path
        d="M5040 25235 c-10 -12 -9 -16 3 -21 17 -6 37 2 37 16 0 15 -29 19 -40
5z"
      />
      <path
        d="M33095 23743 c-169 -85 -158 -78 -154 -92 2 -6 69 19 149 55 80 36
155 70 167 74 23 10 22 41 -1 39 -6 0 -78 -34 -161 -76z"
      />
      <path
        d="M5075 22520 c-3 -6 15 -20 42 -31 88 -38 93 -40 93 -25 0 7 -26 25
-59 40 -66 29 -68 30 -76 16z"
      />
      <path
        d="M3780 20400 c0 -16 38 -32 46 -19 7 12 -12 29 -33 29 -7 0 -13 -4
-13 -10z"
      />
      <path
        d="M15027 19022 c-35 -36 -34 -58 2 -76 27 -15 29 -14 35 7 9 33 7 85
-2 91 -5 3 -21 -7 -35 -22z"
      />
      <path
        d="M15139 18914 c-11 -14 -10 -15 11 -12 13 1 25 9 28 16 5 17 -23 15
-39 -4z"
      />
      <path
        d="M19245 15750 c-3 -5 3 -14 14 -19 26 -14 44 -14 39 1 -5 15 -46 29
-53 18z"
      />
      <path
        d="M19798 14797 c-12 -29 -10 -63 3 -50 6 6 9 23 7 39 -2 24 -4 26 -10
11z"
      />
      <path
        d="M24241 14650 c-8 -32 -32 -317 -36 -425 l-4 -140 15 75 c8 41 23 120
34 175 23 113 24 197 5 270 -7 28 -13 48 -14 45z"
      />
      <path
        d="M19429 13540 c-80 -115 -89 -131 -89 -165 0 -25 1 -25 21 -11 58 40
144 198 137 250 -2 16 -19 -2 -69 -74z"
      />
      <path
        d="M24247 13590 c-12 -32 -13 -40 -2 -40 11 0 35 48 35 71 0 23 -19 5
-33 -31z"
      />
      <path
        d="M29681 12959 c9 -13 25 -26 35 -28 16 -3 17 -1 5 13 -8 10 -24 22
-35 28 -20 11 -20 10 -5 -13z"
      />
      <path
        d="M30040 12641 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"
      />
      <path
        d="M30260 12532 c0 -22 37 -52 70 -58 41 -8 40 -8 -22 35 -27 18 -48 28
-48 23z"
      />
      <path
        d="M30570 12315 c0 -8 6 -15 14 -15 8 0 21 -3 30 -6 10 -4 16 -1 16 9 0
8 -10 17 -22 21 -34 9 -38 8 -38 -9z"
      />
      <path
        d="M25601 11063 c1 -10 4 -29 8 -42 l6 -24 13 28 c10 21 10 31 1 41 -16
19 -29 18 -28 -3z"
      />
      <path
        d="M25890 10633 c0 -16 49 -88 50 -73 0 8 -9 30 -20 48 -19 31 -30 40
-30 25z"
      />
      <path
        d="M25956 10519 c61 -70 218 -264 253 -314 l41 -58 0 42 c0 23 -5 52
-12 64 -23 45 -223 249 -262 269 l-41 20 21 -23z"
      />
      <path
        d="M26340 10066 c46 -47 86 -86 87 -86 1 0 -7 22 -18 49 -26 61 -74 109
-119 117 -34 6 -33 5 50 -80z"
      />
      <path
        d="M30003 4797 c-29 -12 -53 -27 -53 -34 0 -12 74 15 127 46 33 19 -17
11 -74 -12z"
      />
      <path
        d="M29755 4710 l-80 -28 52 -1 c45 -1 57 3 85 29 18 16 31 29 28 29 -3
0 -41 -13 -85 -29z"
      />
      <path
        d="M29395 4635 l-200 -33 107 -1 c58 -1 140 5 181 13 72 14 147 40 147
50 0 9 -20 6 -235 -29z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M18641 37638 c-30 -32 -45 -85 -45 -159 0 -54 6 -78 36 -141 l36 -76
-34 -7 c-19 -3 -34 -8 -34 -10 0 -2 18 -37 40 -77 22 -40 40 -77 40 -82 0 -15
-127 2 -169 24 -48 24 -70 25 -116 6 l-35 -15 0 46 0 47 -32 -31 c-89 -82
-110 -82 -173 0 -27 34 -50 63 -51 65 -1 2 -16 -4 -34 -13 -23 -12 -31 -22
-27 -35 3 -12 -12 -35 -49 -71 -50 -49 -60 -68 -171 -328 l-117 -276 -86 -85
c-75 -75 -94 -101 -159 -225 -116 -220 -229 -410 -288 -485 -69 -89 -310 -286
-417 -342 -44 -22 -166 -98 -273 -169 -218 -144 -404 -243 -567 -301 -152 -54
-287 -127 -413 -222 -132 -100 -200 -191 -229 -306 -27 -103 -45 -119 -184
-163 -152 -49 -178 -72 -199 -178 -18 -87 -73 -129 -169 -129 -62 -1 -189 -63
-217 -105 -10 -16 -77 -75 -148 -130 -75 -58 -136 -113 -145 -132 -9 -18 -36
-42 -59 -55 -45 -25 -165 -137 -227 -213 -48 -58 -78 -78 -129 -85 -39 -5 -44
-9 -89 -85 -57 -93 -78 -116 -175 -196 -39 -32 -81 -75 -93 -94 -17 -27 -30
-36 -60 -41 -39 -6 -43 -11 -198 -248 -185 -281 -225 -347 -246 -401 -21 -54
-215 -783 -260 -976 -77 -333 -110 -580 -126 -939 -5 -129 -23 -347 -40 -483
-67 -562 -67 -794 4 -1389 48 -408 55 -539 32 -628 -8 -31 -20 -164 -26 -300
-14 -277 -24 -311 -67 -237 -23 37 -194 240 -348 412 -110 122 -147 184 -160
262 -6 39 -8 41 -30 33 -18 -7 -29 -4 -47 9 -41 33 -302 320 -310 341 -4 11
-3 32 3 47 l10 27 19 -32 c15 -24 27 -32 49 -32 34 0 61 23 61 51 0 23 -3 34
-10 33 -3 -1 -30 -5 -61 -8 -72 -9 -82 8 -37 60 l32 36 -27 47 c-21 37 -31 45
-49 43 -13 -2 -29 -5 -36 -5 -8 -1 -22 25 -36 68 -42 129 -60 152 -149 185
-42 16 -64 31 -78 54 -35 58 -22 78 37 56 27 -11 27 -11 116 60 18 15 16 19
-46 101 -35 47 -79 115 -96 150 -43 85 -98 164 -150 218 -40 41 -44 43 -56 26
-8 -10 -17 -15 -21 -12 -3 4 1 13 11 20 16 12 15 17 -15 82 -18 39 -37 73 -43
77 -6 4 -41 8 -78 8 -60 0 -71 3 -99 28 -28 25 -32 36 -34 83 -1 30 -3 58 -4
62 0 4 -17 5 -37 1 -37 -7 -37 -7 -30 22 4 16 9 36 11 44 4 11 -8 20 -37 30
-59 21 -88 66 -88 138 l0 55 33 -38 c29 -33 40 -39 71 -38 56 2 64 16 32 58
-37 48 -94 85 -175 111 -56 19 -69 28 -88 61 -24 40 -30 83 -13 83 5 0 27 -16
49 -35 21 -19 41 -35 45 -35 14 0 5 66 -14 106 -12 23 -68 92 -125 152 -98
102 -106 114 -119 171 -8 33 -23 75 -34 93 -10 18 -50 115 -87 216 -37 101
-85 211 -105 245 -72 120 -279 579 -295 653 -10 51 -14 134 -14 300 1 247 -10
357 -55 544 -14 58 -28 121 -31 142 -4 20 -24 59 -45 87 -42 56 -56 112 -47
184 6 46 -36 271 -79 420 -36 123 -33 182 15 351 22 77 66 259 97 405 31 146
60 269 63 273 3 4 27 -10 52 -31 35 -31 57 -64 101 -157 63 -134 68 -148 207
-584 137 -432 149 -476 166 -628 16 -137 153 -835 225 -1142 55 -236 89 -354
181 -625 175 -517 271 -702 416 -801 52 -36 162 -87 171 -80 1 2 -23 72 -53
156 -31 84 -53 155 -50 158 2 3 32 -19 66 -48 l61 -53 -7 34 c-13 64 -42 117
-73 133 -26 13 -32 25 -46 88 -30 131 -30 144 -7 198 12 28 28 102 37 167 8
65 16 119 18 120 1 1 17 -7 35 -18 32 -20 33 -22 27 -69 -8 -61 14 -109 79
-173 49 -49 52 -60 30 -114 -8 -20 -14 -38 -12 -39 8 -8 68 44 83 71 20 37 16
91 -16 225 -15 63 -23 133 -25 210 -2 106 -6 127 -49 265 -96 304 -170 524
-223 665 -134 354 -212 607 -372 1205 -208 779 -313 1150 -350 1235 -90 209
-281 471 -460 629 -94 83 -103 63 -22 -50 75 -104 152 -242 185 -331 l26 -68
-40 0 c-22 0 -40 -2 -40 -5 0 -3 21 -25 46 -49 35 -34 44 -49 39 -65 -3 -11 1
-48 9 -82 21 -82 21 -103 -1 -94 -35 15 -61 17 -79 7 -17 -9 -16 -12 10 -23
24 -11 27 -17 22 -43 -23 -117 -24 -113 37 -169 49 -46 107 -137 107 -167 0
-6 -10 -10 -22 -8 -16 2 -31 18 -48 53 -21 42 -30 50 -56 53 -28 3 -33 9 -73
104 -43 104 -47 142 -15 169 20 17 18 23 -13 35 -24 9 -35 29 -71 122 -25 63
-54 121 -68 134 -38 35 -57 77 -50 110 9 45 -104 232 -156 259 -37 19 -37 19
-23 51 13 33 13 34 -32 80 -25 25 -42 51 -39 57 4 6 21 14 38 19 l32 8 -39 24
c-24 15 -52 23 -74 23 -27 -1 -44 6 -66 27 -16 16 -37 30 -45 32 -8 1 -62 -26
-120 -61 -141 -86 -216 -164 -308 -318 -41 -68 -77 -123 -81 -123 -4 0 -16 17
-26 38 l-18 37 -11 -50 -11 -50 -18 25 c-21 31 -22 58 -1 81 16 17 15 19 -9
28 -31 12 -51 42 -66 96 -6 22 -29 59 -51 82 -31 33 -48 42 -79 45 l-39 3 12
35 c7 21 28 47 55 68 38 29 49 32 83 27 36 -6 38 -5 38 18 0 32 -22 49 -57 45
-24 -3 -28 -7 -25 -30 3 -28 2 -28 -58 -28 -51 0 -65 4 -85 25 -22 21 -25 33
-25 101 0 42 5 117 12 167 11 86 11 92 -14 171 -15 44 -34 114 -42 153 -17 79
-73 233 -85 233 -4 0 -18 -44 -32 -97 -22 -90 -24 -117 -24 -323 0 -241 4
-275 61 -520 19 -80 56 -278 84 -441 27 -163 61 -352 75 -420 50 -242 104
-545 116 -640 27 -221 -24 -283 -117 -143 -22 33 -51 62 -74 74 -42 21 -59 44
-85 113 -10 26 -27 57 -39 70 -12 13 -20 32 -18 46 5 34 -20 55 -147 125 -289
158 -360 186 -482 186 -86 0 -111 -14 -122 -68 -5 -22 -21 -34 -95 -69 -209
-98 -282 -242 -282 -555 0 -160 11 -261 75 -718 50 -353 141 -1050 155 -1180
6 -47 10 -181 10 -299 0 -207 1 -216 22 -238 20 -21 23 -41 35 -238 l13 -215
79 -312 c78 -306 185 -698 236 -868 15 -47 29 -116 32 -155 5 -55 12 -79 34
-110 24 -33 32 -62 48 -170 26 -168 105 -556 136 -665 27 -96 128 -601 146
-730 13 -95 7 -138 -72 -495 -45 -202 -60 -261 -129 -492 -27 -89 -65 -235
-84 -325 -19 -90 -47 -212 -60 -273 -34 -147 -72 -375 -93 -565 -13 -117 -17
-239 -17 -495 0 -311 4 -384 42 -860 23 -286 53 -619 67 -740 51 -460 68 -684
100 -1300 20 -392 38 -594 81 -910 81 -604 207 -1403 269 -1707 47 -229 79
-345 175 -638 78 -238 95 -318 67 -307 -7 3 -116 80 -242 171 l-228 166 -25
-37 c-52 -76 -58 -154 -18 -208 13 -18 12 -22 -11 -41 -25 -20 -26 -25 -27
-130 0 -87 4 -124 22 -183 13 -40 21 -75 18 -78 -2 -3 -19 -6 -37 -6 -45 -3
-54 18 -74 183 -9 74 -18 140 -20 146 -2 8 -21 6 -64 -5 -72 -18 -109 -9 -129
33 -11 23 -12 63 -3 227 15 276 15 280 -9 286 -15 4 -17 9 -10 23 14 26 12 53
-5 76 -15 19 -15 19 -35 -5 -13 -15 -18 -31 -14 -43 3 -10 -15 3 -41 31 -31
33 -57 51 -79 55 l-34 7 -7 96 c-5 59 -4 103 2 112 5 9 29 29 51 44 23 16 42
32 42 37 0 4 -23 25 -51 46 -39 29 -49 41 -45 58 2 12 8 42 11 67 6 44 11 49
58 74 l50 28 -49 25 c-27 13 -51 26 -52 27 -2 2 7 18 20 35 17 24 23 45 23 83
0 71 -60 196 -121 253 l-44 42 44 6 c40 6 44 10 49 39 4 28 -2 38 -49 82 -70
66 -75 86 -32 129 18 18 39 35 46 37 10 3 3 26 -25 84 -33 71 -38 89 -39 157
-1 121 -27 120 -39 -2 -10 -100 -17 -102 -59 -19 -36 72 -37 76 -31 151 9 103
-18 319 -52 418 -88 256 -96 314 -84 615 7 173 17 277 40 420 41 255 74 673
101 1280 12 273 30 427 66 561 15 54 30 115 34 134 4 19 17 60 29 90 19 49 19
56 6 66 -14 11 -14 16 5 52 19 37 20 85 1 96 -6 4 1 255 19 636 l10 225 -35
30 -35 30 -1 200 -2 200 52 160 c72 220 94 367 95 630 0 156 -4 217 -17 275
-53 224 -58 309 -43 695 10 295 -7 468 -64 643 -11 33 -24 93 -30 133 -24 172
-78 324 -215 604 -62 128 -109 207 -190 320 -129 180 -139 192 -187 216 -42
22 -148 26 -211 8 -38 -10 -77 -55 -77 -89 0 -9 -25 -33 -55 -55 -113 -83
-165 -182 -172 -335 -5 -108 34 -459 67 -598 25 -105 25 -193 1 -313 -34 -162
-44 -268 -44 -449 -1 -96 -1 -274 -1 -395 0 -121 0 -355 0 -520 0 -382 31
-723 101 -1110 26 -142 27 -167 28 -480 1 -313 3 -344 35 -600 l34 -270 -4
-370 c-5 -451 -5 -855 1 -1115 3 -181 1 -206 -25 -350 -27 -144 -29 -177 -33
-475 -10 -640 40 -1410 112 -1730 14 -60 54 -201 90 -312 35 -111 76 -248 90
-305 75 -297 156 -516 251 -676 30 -51 71 -132 90 -180 19 -48 65 -147 101
-220 157 -318 332 -522 612 -713 84 -57 262 -182 395 -278 132 -96 269 -191
303 -212 145 -89 303 -154 450 -184 43 -9 88 -23 100 -31 44 -28 56 -99 58
-339 2 -225 10 -287 50 -349 22 -34 45 -126 45 -179 0 -33 6 -57 19 -74 11
-13 25 -50 31 -81 23 -118 42 -177 94 -295 30 -67 59 -149 66 -182 23 -113 62
-220 120 -330 31 -60 78 -160 104 -220 54 -130 143 -281 249 -425 60 -82 77
-112 77 -138 0 -24 -4 -33 -13 -29 -7 3 -49 15 -92 27 -109 31 -150 47 -220
91 -33 21 -85 47 -116 58 -58 22 -91 49 -108 91 -8 19 -9 8 -5 -47 4 -39 4
-83 0 -96 -6 -24 -47 -47 -147 -82 l-37 -13 20 45 c16 36 19 59 15 106 -3 32
-7 61 -10 63 -2 2 -21 -3 -41 -11 -35 -15 -40 -15 -59 0 -92 67 -90 66 -137
60 -44 -5 -47 -4 -140 67 -88 68 -95 76 -98 112 -4 42 -21 61 -107 120 -40 28
-85 44 -227 80 -97 25 -205 58 -240 74 -89 40 -330 203 -386 261 -147 152
-154 160 -168 207 -10 36 -27 61 -63 94 -26 25 -91 99 -143 165 -52 66 -102
126 -111 134 -25 20 -21 28 20 38 52 12 52 30 1 43 -85 22 -81 19 -74 59 6 40
-14 81 -39 81 -7 0 -24 -14 -36 -31 l-22 -32 -34 68 c-33 64 -33 68 -16 80 10
7 29 22 43 34 l25 20 -58 1 c-37 0 -59 4 -61 12 -2 7 -8 55 -14 106 -12 103
-35 162 -110 282 -26 41 -69 117 -97 169 -59 110 -88 136 -150 135 l-44 -1
-18 76 -19 76 -45 3 c-43 3 -46 5 -41 28 l4 25 35 -21 c20 -12 37 -19 39 -17
2 2 -2 32 -8 66 l-13 61 -25 -40 c-21 -35 -29 -40 -50 -36 -14 3 -46 9 -71 12
-44 6 -46 5 -67 -30 -25 -42 -33 -43 -87 -13 -51 29 -100 94 -121 162 -20 61
-11 85 32 85 19 0 24 -5 24 -27 0 -36 16 -61 29 -48 5 6 21 38 34 73 l26 62
-114 0 -113 0 -17 49 c-14 43 -20 50 -50 55 -19 4 -47 17 -62 30 l-27 24 18
40 c14 30 17 56 14 99 -5 59 5 118 22 128 6 3 35 -17 65 -46 30 -28 55 -48 55
-44 0 38 -37 127 -66 159 -30 33 -34 43 -34 93 0 65 -20 104 -78 150 -21 17
-62 58 -91 91 l-52 61 30 16 c45 23 39 47 -28 135 -50 65 -57 78 -44 86 14 8
1 27 -77 114 -52 58 -101 105 -110 105 -20 0 -25 -95 -10 -184 6 -35 8 -66 5
-69 -12 -12 -37 28 -51 83 -12 47 -27 72 -69 117 -29 31 -76 96 -105 143 -62
103 -118 170 -173 208 -32 21 -51 27 -94 27 -68 0 -102 -16 -176 -83 l-58 -52
-30 -139 c-16 -77 -29 -141 -29 -142 0 -2 17 -12 39 -23 47 -24 51 -34 51
-120 0 -72 -1 -69 88 -183 23 -31 60 -95 81 -145 20 -49 53 -113 73 -143 121
-190 148 -226 189 -250 97 -58 114 -81 234 -324 245 -494 308 -609 348 -635
35 -23 37 -27 37 -77 0 -50 3 -55 69 -132 86 -100 116 -151 151 -255 41 -123
288 -517 616 -984 80 -114 170 -238 200 -274 66 -81 399 -408 554 -545 203
-179 261 -216 539 -339 69 -30 202 -98 297 -150 339 -189 574 -256 838 -241
304 17 337 18 401 7 62 -11 225 -58 225 -65 0 -2 -48 -120 -106 -262 -169
-415 -157 -378 -148 -464 15 -142 11 -182 -24 -237 -46 -71 -54 -132 -42 -293
5 -75 17 -183 25 -240 9 -60 18 -217 22 -365 6 -274 0 -382 -38 -615 -18 -111
-20 -152 -14 -360 16 -604 43 -842 141 -1233 50 -200 141 -481 185 -574 37
-77 79 -128 183 -221 44 -40 82 -76 84 -81 1 -5 -20 -11 -48 -15 -27 -4 -50
-10 -50 -14 0 -4 25 -37 54 -74 89 -108 91 -115 88 -256 -4 -152 10 -208 73
-289 82 -106 121 -166 235 -368 263 -466 325 -563 470 -745 50 -63 120 -158
155 -211 34 -52 93 -131 130 -175 88 -103 113 -141 140 -215 16 -45 44 -88
101 -155 76 -89 81 -93 120 -93 59 -1 90 -29 97 -87 7 -65 31 -91 130 -142
115 -58 169 -109 339 -321 78 -97 185 -228 238 -291 53 -63 122 -153 152 -200
31 -47 100 -138 154 -202 71 -85 120 -156 174 -254 74 -134 84 -147 166 -204
32 -22 44 -38 52 -72 7 -28 25 -56 50 -82 l40 -38 13 36 c7 20 16 36 20 36 4
0 11 -23 16 -52 l8 -52 125 -84 c102 -68 130 -82 153 -78 37 7 68 -8 95 -49
37 -53 398 -254 612 -340 174 -70 250 -85 465 -95 250 -11 288 -22 620 -178
404 -190 785 -382 785 -396 0 -19 -12 -22 -155 -41 -166 -22 -211 -45 -274
-138 -54 -82 -191 -304 -191 -312 0 -3 10 -5 23 -5 12 0 32 -3 44 -6 21 -6 21
-7 6 -30 -9 -14 -13 -27 -10 -30 3 -4 56 5 116 18 85 19 111 22 115 12 6 -15
-7 -20 -68 -29 -40 -6 -51 -13 -76 -50 -44 -64 -92 -101 -163 -124 l-65 -22
36 -34 37 -33 -45 -12 c-25 -6 -53 -13 -62 -16 -25 -7 -58 -58 -65 -98 -4 -28
-1 -39 20 -60 l26 -26 169 6 c131 6 171 4 177 -6 12 -19 -10 -27 -94 -33 -87
-6 -126 -22 -165 -67 -33 -40 -33 -54 0 -69 62 -28 104 -26 714 39 134 14 189
12 235 -9 41 -18 75 -15 90 8 3 6 36 11 73 11 37 0 153 7 257 16 158 14 251
16 559 9 l368 -7 33 -30 34 -29 16 25 c9 14 37 35 62 47 42 20 64 22 301 27
l256 5 75 38 c42 21 95 54 117 73 l42 35 7 104 c5 81 3 123 -11 191 -16 82
-16 91 -1 140 10 30 36 74 62 105 64 75 75 100 87 200 14 110 8 135 -34 151
-75 27 -96 40 -158 96 -36 33 -85 75 -110 94 l-45 35 28 -35 c15 -19 39 -50
53 -68 15 -18 25 -34 23 -36 -2 -3 -85 -1 -185 4 -232 10 -246 16 -318 153
-39 74 -46 130 -21 163 8 10 15 27 15 37 0 16 7 13 48 -16 52 -38 135 -82 196
-103 50 -18 45 -13 -80 82 -86 65 -103 74 -131 70 -28 -4 -34 -1 -39 20 -6 25
-39 54 -60 54 -7 0 -15 -14 -19 -30 -9 -39 -18 -38 -44 6 -27 46 -27 47 12 39
29 -6 24 2 -65 87 -53 51 -107 111 -120 133 -14 22 -64 128 -113 235 -48 107
-113 244 -144 305 -48 95 -56 117 -56 165 1 66 49 195 80 216 17 11 44 11 170
-1 83 -8 153 -15 157 -15 5 0 26 -16 48 -36 21 -19 90 -60 152 -91 143 -70
292 -155 434 -249 273 -179 452 -354 610 -594 81 -123 170 -221 263 -288 399
-290 363 -270 425 -246 13 5 16 -1 16 -27 0 -19 4 -39 8 -46 10 -17 272 -145
362 -178 92 -33 231 -64 286 -65 41 0 50 6 97 64 19 23 35 30 81 37 34 4 73
18 96 33 20 13 102 105 180 203 208 259 616 662 1116 1100 255 223 514 428
740 585 75 52 193 118 212 118 4 0 28 -48 52 -107 23 -60 67 -146 97 -193 69
-109 107 -189 128 -270 22 -85 23 -184 2 -233 -23 -53 -89 -128 -182 -206 -44
-37 -134 -116 -200 -175 -66 -59 -159 -133 -206 -166 -86 -59 -156 -130 -310
-314 -105 -126 -104 -124 -213 -222 -58 -52 -116 -114 -138 -149 -32 -50 -51
-66 -106 -96 -75 -40 -112 -84 -112 -136 0 -42 -18 -86 -76 -185 -38 -64 -44
-83 -39 -108 5 -21 2 -39 -9 -55 -9 -13 -21 -47 -27 -74 -9 -42 -7 -60 11
-122 28 -93 61 -129 121 -129 23 0 57 5 76 12 29 11 36 9 62 -10 36 -26 54
-28 72 -6 38 46 124 57 193 25 23 -10 127 -40 231 -65 105 -26 215 -59 245
-72 44 -21 121 -35 385 -74 265 -38 343 -53 395 -75 82 -35 174 -42 255 -21
32 9 98 16 145 16 113 0 399 -42 575 -85 77 -18 172 -37 210 -40 40 -4 100
-19 140 -35 97 -39 207 -60 320 -60 62 0 108 -5 132 -15 33 -14 38 -14 67 5
74 48 73 45 37 90 -29 36 -31 44 -23 70 12 32 11 32 -63 45 l-39 7 68 36 67
35 -29 24 c-18 13 -32 36 -36 56 -7 39 -196 282 -226 292 -34 11 -80 55 -102
99 -11 22 -31 83 -44 134 -15 60 -40 125 -70 180 -105 191 -116 218 -121 284
-7 78 13 155 64 248 70 130 148 197 256 220 27 5 95 30 152 54 118 51 189 67
258 59 39 -5 58 -1 100 21 63 31 95 37 402 76 302 38 368 51 535 107 302 100
490 219 627 395 24 32 144 149 266 260 410 373 487 457 487 531 0 26 25 52 82
86 54 32 120 110 155 183 48 98 48 102 11 120 l-33 16 43 17 c57 23 76 53 100
160 27 120 33 134 81 188 34 40 39 52 34 80 -4 28 -1 37 22 55 15 12 36 22 47
22 40 0 76 29 93 75 19 48 29 54 55 30 16 -14 20 -12 45 22 16 21 54 67 85
103 91 105 128 166 144 242 l6 27 55 -25 c83 -38 105 -27 204 99 43 56 136
163 207 238 71 75 153 164 182 198 30 34 76 74 103 90 53 30 104 100 265 368
41 67 93 150 117 185 50 72 67 115 67 169 0 31 5 41 29 55 24 14 30 25 35 71
3 30 19 80 35 111 15 31 36 81 46 111 20 61 70 123 115 141 25 10 29 16 23 34
-13 35 -5 83 37 251 42 170 87 275 131 307 14 11 45 24 69 29 61 14 88 36 177
147 43 53 105 126 138 162 116 125 172 246 199 432 32 213 13 547 -46 800 -34
150 -42 113 -32 -154 16 -453 7 -583 -53 -796 -14 -52 -34 -103 -43 -115 -16
-19 -20 -19 -49 -8 -17 8 -31 21 -31 30 0 9 9 67 21 129 12 62 18 116 15 119
-16 16 -46 -40 -70 -127 -15 -55 -33 -99 -42 -105 -43 -26 -66 -50 -84 -86
-11 -21 -24 -39 -29 -39 -5 0 -12 30 -15 68 -4 37 -9 74 -12 83 -3 10 8 21 30
31 31 15 35 22 45 84 14 79 30 121 73 187 30 46 30 50 22 120 -12 113 -11 304
3 318 9 9 13 3 18 -25 15 -77 26 -53 40 90 7 69 33 119 69 136 l25 11 -31 54
c-28 50 -31 61 -28 131 4 100 4 265 -1 323 -3 36 3 60 34 125 47 101 61 201
46 324 -21 175 -23 370 -4 484 17 101 17 108 0 126 -10 11 -20 45 -24 81 -5
40 -14 66 -24 74 -38 27 -7 96 58 132 20 12 20 12 -1 40 -18 23 -29 28 -65 28
-24 0 -56 -4 -71 -8 l-28 -7 0 119 c0 80 -5 132 -15 161 -29 82 -21 130 44
264 71 145 94 219 121 390 11 71 32 161 46 200 69 188 65 169 62 320 l-3 141
40 78 c59 115 62 117 207 133 119 14 198 27 473 78 72 14 209 32 305 40 254
22 381 52 525 124 84 42 141 80 215 142 30 26 123 95 205 155 188 135 242 181
296 253 24 31 73 89 109 128 62 68 131 175 165 258 l17 40 -34 -25 c-58 -44
-60 -42 -27 18 49 87 81 132 146 204 76 83 95 115 133 221 37 103 75 158 127
188 51 28 108 110 99 143 -3 13 -17 29 -31 37 -32 17 -32 25 6 47 35 20 135
141 150 181 6 14 8 36 5 48 -4 16 12 45 65 115 40 52 92 115 116 142 58 62 83
118 82 187 l0 55 -25 -30 c-13 -16 -63 -85 -110 -152 -90 -129 -109 -143 -183
-143 -22 1 -47 9 -64 23 l-27 23 58 41 c33 23 89 56 126 73 71 34 103 70 126
144 8 23 35 62 68 95 l55 56 10 -45 9 -45 42 49 c91 107 133 146 159 146 42 0
42 29 -1 47 -20 8 -39 17 -40 18 -5 5 58 98 76 113 9 6 29 12 44 12 28 0 28 0
13 30 -34 65 -5 131 48 111 26 -10 34 -27 39 -85 l3 -29 31 26 c43 37 59 78
45 117 -10 29 -9 31 25 48 l36 17 -26 20 -26 21 52 34 c64 43 85 72 97 132 6
35 26 70 79 140 87 115 120 180 137 270 7 38 20 77 29 86 48 54 55 124 29 300
-13 84 -14 127 -7 170 l9 57 -37 -3 c-52 -5 -105 15 -131 50 -25 34 -85 68
-120 68 -38 0 -63 -37 -105 -155 -22 -61 -45 -123 -52 -140 l-12 -30 -23 44
-22 45 -56 -86 c-30 -47 -60 -95 -66 -107 -19 -35 -28 -24 -41 54 -7 41 -16
72 -21 70 -56 -35 -682 -1049 -1099 -1780 -69 -122 -121 -195 -191 -270 -26
-27 -71 -91 -101 -141 -111 -185 -221 -305 -507 -549 -272 -232 -431 -356
-626 -487 -99 -67 -254 -174 -345 -237 -91 -63 -194 -130 -230 -148 -66 -34
-274 -92 -286 -80 -4 4 -2 42 4 85 13 89 48 176 224 552 66 140 149 320 184
399 71 159 86 186 158 281 124 164 143 256 125 613 -13 274 -8 483 16 606 18
90 62 241 75 254 4 5 23 -12 43 -37 l34 -45 5 67 c4 60 15 88 86 233 116 237
190 345 276 407 43 31 45 32 56 13 16 -31 13 -41 -30 -87 -34 -37 -40 -50 -40
-87 0 -24 3 -47 7 -50 3 -4 39 11 78 32 l72 39 86 -48 c84 -47 97 -50 97 -24
0 38 52 140 140 274 118 179 144 240 138 325 -4 54 -2 63 27 101 18 23 53 80
78 125 25 46 66 104 91 128 26 25 48 58 52 75 12 57 74 161 138 231 51 55 63
75 59 93 -3 15 6 44 25 80 17 32 40 96 50 142 54 239 92 343 138 381 15 12 16
12 11 -6 -20 -67 -119 -586 -114 -592 4 -4 11 -2 16 3 7 7 121 418 227 819 22
85 28 135 36 335 15 320 13 1838 -1 1951 -6 48 -16 212 -21 365 -9 275 -17
355 -45 465 -8 31 -19 145 -24 255 -6 109 -17 253 -25 319 -33 270 -31 399 8
795 9 85 27 288 41 450 36 420 45 466 149 767 87 251 140 514 210 1038 70 528
78 678 51 956 -38 381 -85 644 -146 809 -89 241 -240 391 -463 459 -76 24
-117 26 -149 10 -12 -6 -77 -67 -144 -135 -104 -108 -127 -137 -167 -217 -72
-143 -89 -221 -89 -417 -1 -109 5 -215 18 -310 36 -276 13 -815 -55 -1310 -37
-269 -43 -436 -37 -1055 5 -455 3 -629 -8 -760 -29 -362 -38 -703 -32 -1270 7
-703 16 -809 89 -1125 l40 -175 -6 -390 c-3 -214 -10 -480 -16 -590 -11 -216
-44 -557 -60 -617 -6 -22 -70 -112 -167 -235 -197 -247 -293 -384 -343 -487
-60 -122 -71 -180 -68 -353 2 -142 1 -153 -30 -258 -61 -204 -74 -334 -58
-580 7 -116 5 -139 -13 -209 -27 -104 -88 -244 -119 -278 -36 -39 -351 -158
-418 -158 -23 0 -24 3 -24 66 0 64 56 576 105 949 62 478 172 919 355 1427 96
264 110 314 120 443 5 55 29 197 53 315 82 390 92 471 91 760 0 147 -5 276
-11 305 -10 42 -7 79 13 220 25 178 66 628 84 925 6 96 10 375 9 620 -2 378 1
461 15 553 21 133 18 158 -65 522 -110 489 -139 697 -159 1140 -9 212 -58 586
-156 1205 -44 275 -47 356 -17 423 12 26 68 105 124 175 211 261 304 433 469
862 56 146 134 342 174 435 41 94 81 206 91 250 9 44 30 104 46 133 26 47 29
60 24 105 -4 35 -1 68 10 102 8 28 24 96 35 152 24 128 18 197 -40 419 -22 88
-43 186 -47 219 -3 33 -12 69 -19 80 -21 30 -17 70 14 126 l27 50 -39 34 -38
34 7 133 c4 88 3 143 -4 161 l-11 28 52 -7 c60 -8 59 1 -4 47 -56 40 -83 38
-98 -6 -11 -35 -12 -35 -40 -20 -38 20 -50 43 -50 98 0 51 25 140 43 155 7 6
32 14 55 18 l41 6 3 -68 3 -69 33 32 c48 47 40 103 -14 103 -20 0 -25 9 -41
83 -18 79 -18 82 -1 85 10 2 18 7 18 12 0 5 -8 7 -17 3 -10 -3 11 20 46 53 59
55 64 63 68 111 5 45 2 54 -19 75 -13 13 -33 28 -43 34 -18 10 -17 13 18 56
43 52 44 58 16 84 -19 17 -21 26 -15 93 7 71 6 75 -18 95 -22 18 -28 35 -40
126 l-15 105 34 52 c19 29 35 54 35 55 0 2 -18 3 -40 3 -47 1 -76 25 -126 103
-19 29 -38 51 -42 48 -5 -3 -31 -14 -59 -25 l-52 -20 19 42 c26 56 26 107 1
114 -17 4 -20 18 -26 110 -9 125 -7 123 -148 151 -54 11 -100 22 -103 25 -3 3
1 18 9 34 26 49 41 137 28 161 -16 31 -84 38 -172 18 -39 -9 -96 -16 -125 -16
l-54 0 6 73 c7 81 24 210 56 402 16 100 22 183 23 360 2 127 6 374 10 550 4
188 3 429 -4 585 -9 246 -21 337 -41 325 -5 -3 -13 -38 -20 -78 -27 -163 -29
-171 -62 -198 l-32 -27 22 -99 c33 -147 35 -178 21 -418 -15 -274 -16 -327 -6
-493 l7 -134 -64 7 -64 7 -24 -53 -25 -54 -17 58 c-17 56 -17 60 4 130 23 75
23 75 56 20 10 -16 13 -14 32 23 l21 41 -44 -5 -45 -5 0 36 c0 28 7 41 34 64
19 15 42 42 50 58 16 30 22 115 9 115 -4 0 -49 -41 -99 -91 -106 -106 -92
-115 -119 78 -16 109 -15 114 4 178 16 49 19 80 15 124 -6 56 -6 58 25 80 34
24 51 21 51 -10 0 -22 22 -25 39 -5 10 11 8 16 -8 21 -28 9 -231 211 -231 230
0 9 11 42 25 75 14 32 22 66 19 74 -4 11 7 16 48 21 101 13 105 8 47 -58 -42
-47 -49 -60 -39 -72 15 -18 45 -19 89 -4 31 11 35 9 76 -31 l43 -42 6 48 c3
27 9 76 12 109 4 32 15 73 25 90 24 39 24 77 -2 185 l-20 88 -57 -24 c-96 -42
-107 -49 -110 -72 -5 -31 -20 -27 -36 11 -9 19 -15 25 -15 15 -1 -21 -23 -23
-50 -7 -15 10 -28 6 -72 -17 -79 -43 -125 -93 -134 -145 -11 -65 -29 -104 -86
-180 -54 -74 -73 -84 -83 -44 -3 13 -11 36 -16 51 l-11 27 -39 -38 c-56 -54
-86 -131 -110 -277 -11 -69 -24 -138 -30 -153 -6 -15 -7 -25 -3 -23 5 2 25 12
45 23 21 10 40 16 44 13 12 -13 -15 -147 -43 -208 l-30 -65 -19 43 -19 44 -29
-76 -29 -76 -52 6 -53 7 -11 -39 c-6 -22 -11 -45 -11 -52 0 -48 -85 -117 -131
-105 -19 5 -63 51 -71 75 -3 9 -25 8 -93 -6 -148 -29 -296 -99 -515 -246 -130
-87 -221 -141 -231 -138 -9 3 16 57 73 158 28 50 43 100 30 100 -16 0 -297
-295 -358 -375 -116 -154 -146 -204 -163 -267 -13 -46 -41 -99 -107 -198 -203
-303 -269 -455 -439 -1015 -20 -66 -57 -174 -82 -240 -87 -235 -94 -277 -103
-650 -7 -272 -13 -361 -34 -505 -51 -344 -60 -447 -48 -540 5 -47 19 -114 30
-150 11 -36 37 -150 57 -255 71 -370 91 -435 160 -521 30 -36 134 -124 147
-124 3 0 21 26 41 58 20 33 52 69 70 82 42 29 76 80 160 240 35 69 90 166 122
215 64 102 121 217 158 321 60 167 408 1465 438 1633 25 139 48 216 92 301 32
63 464 742 514 808 23 31 78 72 95 72 19 0 28 -95 28 -302 l0 -226 42 -14 43
-15 -56 -35 -56 -36 -6 -66 c-5 -52 -14 -79 -46 -132 -44 -72 -59 -129 -117
-434 -47 -247 -51 -294 -30 -358 24 -71 9 -126 -38 -146 -26 -11 -37 -24 -50
-62 -20 -59 -20 -74 -2 -74 29 0 27 -21 -6 -78 -33 -56 -33 -58 -30 -171 2
-98 0 -118 -17 -147 -16 -26 -19 -45 -15 -81 5 -41 1 -59 -30 -123 -26 -55
-36 -88 -36 -125 0 -55 -30 -132 -55 -141 -20 -8 -45 -56 -45 -85 0 -41 -228
-456 -322 -587 -38 -52 -142 -243 -276 -505 -145 -283 -236 -450 -278 -507
-101 -138 -220 -341 -321 -550 -322 -661 -350 -713 -412 -784 -64 -73 -81
-112 -81 -184 0 -51 -48 -172 -127 -322 -74 -140 -116 -202 -189 -280 -83 -90
-117 -142 -180 -281 -65 -142 -121 -238 -185 -316 -50 -61 -60 -87 -135 -347
-28 -95 -46 -139 -67 -163 -27 -31 -75 -63 -93 -63 -12 0 5 169 26 249 33 132
43 239 44 501 2 241 3 257 24 297 27 52 27 63 0 140 l-22 62 22 69 c26 80 28
114 7 145 -12 20 -12 27 6 66 19 44 19 44 -1 87 l-21 43 27 52 c25 50 26 55
14 106 -11 44 -10 81 2 215 l16 163 -27 50 c-28 54 -31 94 -17 215 7 59 5 70
-18 115 l-26 50 27 21 c25 20 26 22 17 90 -21 167 -5 244 57 266 33 11 34 14
14 48 -19 34 -19 94 1 132 15 29 15 37 1 102 -9 39 -18 109 -21 155 -4 58 -13
99 -30 137 -20 44 -23 60 -15 86 14 49 11 113 -10 239 -11 63 -22 139 -26 169
-3 30 -24 147 -45 260 -22 113 -55 300 -76 415 -64 373 -113 538 -259 880
-123 290 -218 456 -400 701 -292 393 -633 739 -990 1004 -284 212 -473 322
-1055 614 -636 320 -923 445 -1270 556 -107 34 -263 83 -345 110 -179 57 -289
108 -416 191 -52 34 -139 87 -194 117 -136 74 -214 130 -321 235 -106 101
-197 224 -260 350 -96 189 -133 315 -179 599 -28 177 -54 258 -107 338 -96
144 -267 241 -428 242 -79 1 -124 -17 -183 -71 -94 -87 -134 -224 -124 -421
12 -231 49 -359 178 -620 50 -102 113 -241 141 -310 27 -69 66 -156 87 -195
199 -372 320 -574 405 -677 79 -96 186 -183 381 -313 325 -216 627 -379 915
-494 74 -30 261 -109 415 -176 549 -240 701 -303 790 -330 384 -114 554 -208
775 -425 106 -104 561 -597 720 -780 180 -207 374 -487 498 -720 159 -300 375
-1084 451 -1645 65 -470 66 -498 65 -1205 l-1 -655 -36 -465 c-61 -774 -67
-873 -74 -1195 -6 -334 2 -481 38 -622 12 -48 23 -142 29 -243 10 -186 36
-308 91 -425 28 -60 32 -81 32 -145 -1 -75 -58 -440 -71 -451 -4 -3 -28 -13
-54 -23 -70 -26 -126 -81 -255 -254 -212 -285 -254 -350 -436 -682 -53 -97
-103 -184 -110 -194 -12 -16 -16 -16 -77 -1 l-65 16 0 67 c-1 128 -7 150 -38
156 -29 6 -29 7 -65 115 l-21 63 21 37 21 37 -75 135 c-42 74 -94 165 -117
204 -22 38 -41 75 -41 81 0 35 -35 116 -65 152 -34 39 -38 41 -78 36 -59 -8
-78 13 -49 54 43 59 39 93 -19 160 -16 17 -52 67 -80 110 -94 141 -181 250
-223 280 -65 46 -74 46 -90 7 -17 -41 -26 -43 -52 -13 -31 35 -163 117 -208
129 -96 26 -136 51 -161 99 -14 27 -14 31 10 54 22 22 25 33 25 101 0 85 -1
85 -84 63 -35 -9 -51 -9 -72 1 l-28 12 59 53 59 52 -22 55 c-41 102 -45 140
-39 352 5 230 14 268 78 368 51 78 75 144 84 229 4 36 18 108 32 160 16 60 27
136 30 205 4 94 9 115 29 146 28 41 29 50 8 90 -14 29 -14 34 10 97 16 41 49
96 87 142 80 100 99 138 99 201 0 34 7 63 19 83 11 17 29 66 41 109 12 43 31
98 42 124 19 43 20 58 13 213 -7 182 -4 208 46 322 36 82 69 208 69 265 0 36
-8 51 -55 110 -85 105 -100 142 -100 245 0 47 -3 89 -6 93 -4 3 -17 -13 -31
-35 l-24 -41 -69 58 c-39 32 -78 74 -87 93 -55 110 -100 220 -105 258 -5 37
-4 42 14 42 26 0 45 -23 58 -69 8 -26 16 -36 30 -36 17 0 20 8 23 58 2 31 7
57 12 57 20 0 50 -57 65 -126 10 -41 20 -76 25 -79 16 -10 49 43 58 95 10 51
8 60 -20 124 -17 39 -34 98 -38 133 -12 95 -53 153 -148 209 -2 1 10 29 27 63
17 33 31 63 31 66 0 3 -20 5 -44 5 l-44 0 -16 68 c-8 37 -18 86 -22 110 l-7
43 -43 -7 c-42 -6 -45 -5 -55 24 -6 17 -28 42 -49 56 -29 19 -42 36 -50 67
-15 53 -41 202 -36 206 10 10 109 -114 140 -175 34 -68 45 -80 58 -67 14 14
-99 315 -160 428 -17 31 -60 78 -120 130 -172 150 -177 152 -263 121 -24 -8
-68 -15 -98 -15 -45 0 -60 -5 -93 -30 -21 -17 -38 -36 -38 -42 0 -7 -27 -66
-60 -132 -46 -92 -62 -137 -70 -192 -12 -87 -9 -114 41 -360 53 -262 77 -438
88 -638 16 -263 41 -328 191 -490 175 -189 299 -290 438 -356 47 -22 81 -44
76 -48 -5 -4 -33 -27 -62 -50 -61 -50 -82 -85 -82 -136 0 -32 23 -95 35 -95 2
0 22 19 44 43 l40 42 3 -50 c6 -126 -9 -165 -62 -165 -14 0 -68 20 -119 45
-72 35 -92 41 -87 28 17 -44 28 -119 22 -155 -9 -53 -30 -94 -77 -145 -35 -39
-38 -46 -33 -85 4 -31 -3 -72 -25 -148 -17 -58 -31 -115 -31 -127 0 -19 -5
-23 -34 -23 -33 0 -34 -2 -41 -47 -4 -28 -23 -77 -48 -118 -35 -59 -41 -79
-42 -127 0 -52 -3 -60 -37 -95 -45 -46 -49 -84 -18 -148 19 -40 20 -45 5 -108
-9 -39 -13 -83 -9 -105 8 -50 -7 -79 -46 -87 -16 -4 -32 -10 -35 -15 -5 -8 92
-86 104 -83 3 1 18 4 33 8 l26 7 -25 -50 c-18 -38 -26 -75 -33 -153 -4 -57
-17 -129 -27 -159 -11 -30 -23 -73 -27 -95 -4 -21 -16 -56 -27 -77 -29 -56
-34 -116 -15 -162 20 -48 20 -49 -3 -36 -29 15 -56 12 -82 -9 l-22 -18 47 -18
c38 -15 52 -16 67 -7 16 10 19 9 19 -6 0 -10 -7 -28 -16 -40 -13 -20 -21 -21
-69 -16 -48 6 -59 3 -110 -26 l-57 -32 44 -25 c23 -14 44 -26 46 -27 1 -2 -18
-24 -43 -49 l-46 -45 3 -70 c1 -52 -2 -76 -15 -96 -23 -34 -32 -33 -47 5 -8
17 -33 54 -57 82 -47 56 -54 87 -27 138 13 26 13 41 4 105 -6 42 -9 82 -6 90
3 8 10 117 16 242 6 125 13 236 16 247 4 18 4 18 -6 3 -11 -18 -47 -306 -75
-597 -34 -366 -10 -631 75 -817 58 -128 123 -206 359 -427 351 -329 458 -436
526 -522 98 -126 462 -691 573 -892 131 -235 229 -394 289 -466 28 -33 94 -91
158 -136 l110 -78 43 18 c55 24 62 24 62 -1 0 -32 -65 -194 -109 -268 -137
-236 -260 -520 -386 -892 -125 -368 -155 -450 -167 -450 -19 0 -208 192 -270
274 -38 51 -101 162 -175 309 -175 350 -383 751 -602 1156 -108 201 -202 378
-209 394 -11 25 -9 30 21 57 17 16 35 30 39 30 4 0 15 14 23 31 18 34 14 66
-16 133 -21 48 -15 56 49 56 50 0 50 -3 18 121 -52 204 -127 350 -236 459 -84
85 -172 136 -335 198 -71 27 -197 83 -280 125 -173 87 -262 119 -505 180 -243
62 -401 128 -560 237 -97 67 -104 79 -155 259 l-46 164 24 26 c35 38 44 60 45
112 0 55 16 89 75 158 53 61 146 269 280 621 20 52 66 144 103 205 36 60 110
193 165 295 55 102 138 241 184 310 80 119 88 126 145 153 90 42 112 80 226
393 50 140 182 409 229 469 18 22 62 91 99 153 l67 112 -15 48 c-10 30 -14 79
-13 137 l2 90 -81 160 c-45 88 -92 184 -104 213 l-23 53 20 27 c11 15 25 43
32 64 11 34 10 38 -14 62 -38 38 -55 86 -65 189 -5 51 -16 123 -25 160 -18 80
-13 127 22 167 l24 29 -56 408 c-56 407 -65 517 -46 536 6 6 13 9 15 6 3 -2
18 -72 34 -154 41 -208 41 -137 -2 306 -51 522 -50 503 -27 678 19 139 19 157
6 178 -9 13 -25 23 -38 24 -15 0 -35 18 -63 58 -75 105 -160 119 -276 43 -160
-104 -242 -231 -389 -599 -27 -69 -99 -241 -159 -382 -60 -141 -113 -276 -119
-301 -6 -25 -11 -140 -11 -257 0 -225 0 -230 74 -621 41 -218 73 -322 136
-448 l47 -94 -45 -22 c-89 -45 -158 -150 -177 -268 -3 -25 -20 -115 -36 -200
-17 -85 -35 -192 -40 -238 -8 -72 -18 -99 -73 -210 -63 -125 -141 -264 -290
-516 -95 -162 -147 -294 -181 -461 -78 -382 -225 -927 -350 -1300 -113 -338
-148 -498 -161 -740 -12 -228 21 -409 106 -575 73 -140 112 -186 200 -235 146
-82 367 -219 515 -319 83 -56 158 -105 168 -109 13 -5 21 0 29 18 6 14 15 25
19 25 5 0 80 -39 168 -88 149 -81 169 -96 303 -220 118 -110 158 -141 230
-177 48 -24 121 -51 162 -60 74 -17 77 -17 148 5 62 19 88 22 173 17 242 -13
312 -82 252 -250 -37 -102 -47 -170 -54 -348 -11 -279 28 -489 166 -899 118
-349 229 -584 396 -836 133 -200 212 -263 349 -280 57 -6 69 -13 250 -147 240
-175 256 -190 256 -236 0 -20 -32 -153 -70 -296 -123 -455 -154 -656 -143
-910 6 -145 -2 -198 -62 -384 -120 -373 -170 -619 -184 -905 -6 -120 -13 -163
-31 -211 -18 -47 -23 -85 -26 -176 -2 -64 -8 -129 -14 -145 -11 -27 -15 -22
-107 156 -52 102 -153 293 -223 425 -216 405 -256 493 -281 618 -6 29 -28 105
-50 170 -57 170 -72 272 -67 447 4 147 15 190 60 252 20 28 0 33 -33 9 -17
-13 -35 -19 -39 -15 -24 26 -28 124 -20 449 9 364 3 467 -31 568 -19 57 -20
70 -10 164 7 56 24 171 40 257 l27 155 -35 36 -36 35 -3 112 -4 111 50 -7 50
-6 7 114 c8 132 2 192 -27 256 l-20 45 -32 -114 -32 -113 -33 38 c-67 76 -87
160 -67 275 7 40 5 51 -13 76 -13 16 -24 51 -28 83 -6 56 -27 110 -42 110 -5
0 -14 22 -21 50 -27 114 -132 226 -446 476 -379 303 -653 447 -975 513 -257
53 -642 85 -922 78 -216 -6 -289 -17 -405 -63 l-65 -27 -231 18 c-268 21 -970
29 -1136 14 -145 -14 -308 -51 -485 -111 l-150 -51 -190 -1 c-183 -1 -193 -2
-239 -26 -27 -14 -63 -42 -79 -63 l-31 -38 60 -60 60 -60 -87 -133 c-48 -73
-117 -166 -153 -207 -56 -63 -88 -117 -210 -354 -151 -293 -209 -416 -244
-525 -28 -86 -52 -201 -52 -253 0 -23 -7 -67 -15 -97 -8 -30 -15 -74 -15 -97
0 -23 -9 -79 -20 -125 -12 -49 -20 -118 -20 -169 0 -61 -6 -103 -21 -145 -15
-44 -19 -74 -16 -118 4 -39 1 -69 -7 -85 -16 -31 -28 -140 -31 -266 -1 -49 -7
-117 -13 -149 -15 -77 -15 -230 0 -434 15 -197 4 -291 -43 -391 -27 -58 -36
-102 -75 -376 -24 -170 -53 -337 -64 -369 -11 -33 -42 -94 -70 -135 -63 -98
-87 -153 -95 -221 -4 -30 -13 -73 -21 -94 -8 -21 -28 -95 -44 -163 -17 -68
-37 -137 -45 -153 -8 -16 -22 -60 -31 -97 -8 -37 -28 -93 -44 -124 -16 -31
-45 -119 -64 -195 -19 -77 -48 -182 -63 -234 -27 -91 -30 -95 -55 -92 -19 2
-51 -15 -113 -58 -47 -33 -88 -60 -90 -60 -3 0 -5 67 -5 150 0 173 -7 196 -60
210 -25 7 -41 19 -50 37 l-13 28 -10 -32 c-12 -39 -50 -77 -100 -98 -33 -14
-38 -14 -43 -1 -3 9 5 30 18 48 22 31 23 39 18 158 -9 215 -5 422 9 479 13 51
15 53 42 47 l29 -5 1 57 c1 31 4 275 8 542 4 267 11 493 15 502 4 10 16 20 25
23 39 12 41 41 31 388 -5 182 -12 366 -15 407 -18 229 -64 436 -138 616 -25
60 -48 123 -51 140 -8 35 -20 26 155 104 130 57 240 132 317 214 70 76 242
328 311 457 91 170 111 243 141 509 31 270 65 483 96 585 14 50 31 108 36 130
13 52 5 238 -21 493 -13 129 -17 210 -12 225 5 13 22 29 38 35 15 6 160 20
320 32 161 11 318 27 348 35 58 16 383 175 590 291 150 84 275 167 518 346
105 77 207 148 226 157 20 10 62 21 95 24 106 10 144 48 271 271 164 284 269
668 268 976 -1 226 -28 297 -265 696 -181 304 -181 305 -233 469 -84 267 -162
441 -300 670 -85 142 -105 192 -138 351 -33 155 -52 305 -77 599 -27 321 -40
387 -100 505 -41 83 -69 103 -251 176 -93 37 -169 73 -169 78 0 6 36 79 80
161 214 400 231 444 311 830 41 197 35 423 -17 617 -38 142 -166 397 -381 759
-116 196 -168 261 -245 310 -116 73 -146 109 -183 218 -53 161 -160 255 -195
172 -6 -16 -20 -37 -30 -46 -15 -13 -17 -27 -13 -73 3 -32 -2 -97 -11 -147
-18 -102 -21 -192 -6 -229 6 -17 21 -28 49 -36 l40 -10 -25 -25 c-26 -26 -31
-65 -14 -111 8 -20 7 -39 -6 -77 -14 -43 -15 -64 -6 -146 7 -60 7 -113 2 -141
-13 -63 -12 -211 1 -300 10 -68 7 -101 -26 -345 -26 -187 -39 -322 -42 -440
-4 -135 -9 -181 -24 -224 -14 -37 -19 -76 -18 -125 1 -56 -6 -98 -35 -196 -20
-68 -36 -130 -36 -136 0 -7 20 -24 45 -38 41 -25 45 -30 45 -66 0 -49 -15 -59
-34 -22 -40 77 -74 67 -116 -34 -38 -93 -37 -123 7 -138 17 -6 34 -14 37 -17
3 -3 -12 -59 -34 -124 -22 -66 -40 -123 -40 -127 0 -4 13 -5 30 -2 28 6 31 3
49 -38 29 -67 34 -119 26 -261 -7 -117 -10 -132 -30 -151 -38 -36 -47 -66 -56
-180 -10 -137 -5 -147 80 -168 l61 -16 -40 46 c-38 43 -59 86 -47 98 12 12 66
-8 105 -38 l41 -31 -6 -69 -6 -69 47 -43 c25 -24 46 -48 46 -53 0 -5 -20 -19
-45 -31 l-46 -22 42 -63 c24 -34 61 -83 83 -108 40 -45 137 -228 170 -320 9
-26 46 -93 82 -150 85 -136 121 -213 244 -519 128 -320 222 -532 365 -827 96
-198 247 -473 259 -473 15 0 -122 448 -228 744 -44 122 -49 145 -29 133 20
-12 144 -278 158 -337 32 -134 39 -153 72 -192 29 -35 33 -47 33 -98 0 -42 6
-69 24 -101 23 -41 49 -126 66 -212 6 -29 3 -43 -11 -61 -11 -14 -19 -40 -19
-61 0 -20 -4 -45 -10 -55 -21 -39 -421 -216 -555 -245 -297 -64 -311 -69 -647
-234 -271 -133 -329 -176 -486 -355 -101 -116 -113 -133 -152 -231 -23 -58
-41 -107 -39 -109 2 -2 28 -15 57 -30 28 -14 52 -30 52 -34 0 -4 -7 -18 -16
-30 -12 -18 -25 -22 -69 -22 -44 0 -56 -4 -65 -20 -10 -18 -7 -20 30 -26 39
-6 40 -8 62 -75 22 -68 22 -70 4 -102 -31 -54 -45 -65 -91 -72 -65 -9 -178
-69 -233 -123 -26 -26 -91 -113 -145 -195 -144 -217 -543 -975 -644 -1221 -44
-107 -101 -216 -114 -216 -9 0 -201 534 -233 647 -31 110 -67 191 -216 498
-62 127 -112 239 -112 250 -1 36 68 130 141 195 38 34 107 110 153 168 45 59
105 134 131 167 27 33 88 123 135 200 107 172 204 277 346 374 173 118 230
194 483 636 248 434 312 563 374 753 27 81 33 117 37 219 5 136 -10 310 -38
453 -29 144 -136 488 -164 529 -57 83 -60 99 -62 341 -2 244 -12 360 -49 546
-20 96 -83 354 -111 447 -5 15 -2 18 14 13 19 -6 20 -5 11 29 -16 57 -64 169
-94 221 -16 27 -86 108 -157 180 l-130 133 -64 6 c-53 6 -62 10 -53 21 9 11 8
18 -2 31 -13 15 -15 13 -19 -16 l-5 -33 -27 22 c-16 12 -28 30 -28 41 0 29
106 217 231 409 121 188 179 297 256 489 119 296 193 634 223 1010 20 261 10
360 -49 503 -18 41 -44 86 -59 101 -71 68 -196 88 -264 42 -35 -24 -57 -72
-43 -94 8 -13 -42 -111 -56 -111 -8 0 -9 19 -5 62 7 54 6 60 -7 49 -9 -7 -32
-53 -52 -104 -20 -51 -46 -108 -58 -127 -19 -30 -22 -50 -22 -135 0 -90 3
-107 27 -152 l26 -51 -39 -26 c-21 -14 -43 -26 -49 -26 -5 0 -10 18 -10 39 0
22 -3 42 -8 44 -7 5 -62 -37 -62 -47 0 -2 16 -13 35 -25 l36 -21 -25 -43 c-14
-23 -37 -92 -51 -152 -31 -130 -72 -270 -122 -413 -26 -76 -34 -111 -29 -137
4 -19 11 -37 15 -40 5 -2 41 88 81 201 40 112 81 216 92 229 11 14 34 29 51
33 19 6 31 15 29 22 -15 65 -15 68 29 115 58 62 76 71 100 49 26 -24 24 -49
-6 -62 -22 -10 -24 -16 -18 -39 5 -16 8 -63 7 -105 0 -70 -5 -86 -42 -162 -23
-46 -42 -89 -42 -94 0 -6 8 -17 18 -24 11 -7 24 -23 30 -35 11 -19 8 -24 -33
-48 -48 -28 -52 -37 -30 -75 8 -14 14 -28 15 -32 0 -9 -123 -88 -128 -83 -2 2
4 37 13 78 21 97 18 138 -15 154 -13 6 -25 11 -27 10 -5 -6 -54 -400 -49 -404
3 -3 20 -8 38 -12 30 -7 33 -10 30 -42 -6 -66 -35 -140 -72 -182 l-38 -42 -7
38 c-5 28 -11 37 -22 33 -8 -4 -25 -9 -38 -12 -21 -5 -24 -13 -28 -72 -5 -84
-73 -308 -127 -419 -25 -50 -40 -95 -40 -118 l0 -37 35 21 c23 13 35 16 35 8
0 -7 -20 -118 -45 -247 -25 -129 -45 -244 -45 -256 0 -11 14 -33 31 -50 36
-34 59 -74 59 -103 0 -32 -42 -54 -90 -46 -38 6 -40 6 -40 -20 0 -49 31 -177
57 -234 16 -35 28 -86 33 -136 4 -44 37 -231 73 -415 36 -184 78 -400 93 -480
44 -240 137 -525 223 -685 43 -80 53 -110 75 -230 31 -164 32 -231 6 -305
l-19 -55 21 -15 c12 -8 31 -23 42 -32 20 -17 20 -17 -25 -18 -53 0 -141 -28
-208 -66 -25 -14 -89 -44 -141 -66 -185 -78 -645 -416 -815 -601 -140 -150
-228 -302 -248 -426 -6 -39 -8 -74 -4 -77 3 -3 19 3 35 15 17 12 34 21 40 21
17 0 43 -115 33 -143 -10 -24 -56 -55 -108 -71 -28 -9 -52 15 -127 129 -100
152 -272 289 -470 376 -96 42 -127 62 -196 125 -45 41 -85 74 -89 74 -3 -1
-17 -7 -31 -15 -37 -21 -53 -19 -45 5 8 26 -19 70 -44 70 -28 0 -30 9 -16 56
l13 45 -85 70 c-103 87 -122 120 -103 180 8 23 20 43 27 46 7 3 35 -11 60 -31
46 -36 88 -54 100 -43 3 4 -13 29 -36 58 -33 41 -40 57 -34 78 11 49 44 102
99 162 73 79 128 165 153 242 23 65 33 146 77 592 29 297 30 503 4 1060 -11
239 -15 278 -55 485 -71 362 -81 473 -116 1249 -25 539 -23 640 20 1116 11
116 23 280 26 365 19 457 98 765 339 1325 79 183 88 197 221 380 182 250 260
346 414 512 268 289 635 604 946 812 190 126 378 218 590 286 94 31 219 79
276 108 164 83 435 248 544 332 94 73 178 123 235 140 14 4 48 29 76 56 59 55
176 132 314 208 81 44 388 262 631 447 36 28 105 72 155 99 196 105 417 388
565 720 230 522 282 1053 138 1427 -52 135 -158 272 -263 342 -47 32 -69 33
-95 4z m13359 -1029 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14
6 0 10 -9 10 -21z m-330 -341 c0 -4 -6 -8 -13 -8 -17 0 -59 44 -51 53 7 7 64
-33 64 -45z m-406 -147 c-15 -39 -28 -50 -37 -33 -3 4 4 27 15 51 13 30 22 41
29 34 7 -7 5 -23 -7 -52z m396 -181 c0 -25 -10 -50 -21 -50 -10 0 -12 40 -3
64 8 20 24 11 24 -14z m-124 -178 c-4 -3 -12 0 -18 6 -9 9 -8 18 2 39 l14 28
4 -33 c2 -19 1 -37 -2 -40z m-256 48 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
0 6 5 10 10 10 6 0 10 -4 10 -10z m-200 -388 c-17 -6 -50 19 -56 43 -11 46 7
48 37 3 16 -23 25 -44 19 -46z m-22510 -14 c0 -7 3 -23 6 -35 9 -31 -10 -29
-24 2 -16 34 -15 45 3 45 8 0 15 -6 15 -12z m97 -22 c-4 -9 -11 -16 -17 -16
-11 0 -14 33 -3 44 11 10 26 -11 20 -28z m23144 -7 c24 -12 16 -29 -15 -29
-28 0 -36 8 -29 26 6 17 18 18 44 3z m217 -101 c21 -21 14 -43 -30 -98 -38
-48 -43 -51 -55 -34 -20 27 -16 87 8 118 22 27 57 34 77 14z m-23228 -178 c0
-16 -4 -30 -10 -30 -5 0 -10 6 -10 14 0 8 -3 21 -6 30 -4 11 -1 16 10 16 11 0
16 -9 16 -30z m22160 -54 c-1 -21 -1 -21 -15 -2 -21 27 -20 53 0 36 8 -7 15
-22 15 -34z m996 2 l27 -33 -51 -3 -52 -3 0 -46 0 -46 45 6 c25 4 45 4 45 2 0
-3 -34 -48 -75 -101 -44 -56 -77 -90 -79 -82 -3 7 -10 33 -16 57 -7 25 -22 60
-35 79 l-24 35 56 64 c63 71 99 103 118 103 7 0 26 -15 41 -32z m-1566 -8 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-20205 -240 c17 -18 17 -21 -4 -55 -11 -20 -21 -41 -21 -46 0 -5 20 -18 45
-29 47 -20 49 -23 60 -120 12 -95 12 -95 -12 -98 -26 -4 -43 28 -43 78 0 25
-8 33 -61 62 l-61 33 6 80 c7 82 23 115 56 115 9 0 25 -9 35 -20z m-694 -83
c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m-721 -14 c0 -19
-3 -24 -10 -17 -6 6 -8 18 -4 27 9 24 14 21 14 -10z m23053 -190 l11 -53 -36
-37 c-25 -26 -41 -54 -49 -88 -25 -107 -70 -134 -221 -135 -89 0 -98 2 -98 18
0 41 32 54 139 54 l101 0 11 42 c15 60 79 298 86 321 5 16 9 12 25 -25 11 -25
25 -69 31 -97z m-2014 53 c-7 -14 -16 -26 -21 -26 -5 0 -1 14 7 31 20 37 31
33 14 -5z m-5784 -14 c0 -18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13
-16z m110 -112 c-3 -5 -20 -10 -36 -10 -18 0 -28 4 -24 10 3 6 20 10 36 10 18
0 28 -4 24 -10z m5557 -52 c-11 -18 -38 -66 -62 -106 -48 -81 -64 -98 -55 -55
16 70 30 97 75 143 56 58 70 63 42 18z m-20951 -31 c-1 -12 -15 -9 -19 4 -3 6
1 10 8 8 6 -3 11 -8 11 -12z m1503 -38 c10 -60 31 -94 78 -126 30 -20 53 -48
77 -92 30 -57 32 -66 20 -90 -9 -20 -13 -22 -16 -10 -3 11 -18 19 -41 22 -20
2 -38 3 -40 2 -2 -2 27 -26 62 -55 56 -44 64 -54 56 -70 -6 -10 -15 -22 -20
-25 -6 -3 -17 -20 -26 -36 -19 -38 -10 -72 17 -64 10 3 28 7 38 10 16 5 20 -1
26 -44 4 -27 20 -77 36 -111 16 -34 29 -65 29 -69 0 -4 -34 -15 -76 -26 -62
-15 -81 -17 -107 -7 -39 15 -47 5 -47 -65 0 -59 13 -94 41 -111 22 -14 139
-29 139 -18 0 4 -16 22 -35 40 -26 25 -35 42 -35 66 l0 33 24 -22 23 -22 16
24 c15 23 63 44 74 33 3 -3 -1 -27 -8 -55 -16 -64 -4 -92 50 -117 32 -15 43
-27 50 -54 21 -77 22 -75 -46 -78 -64 -3 -73 -11 -53 -42 8 -12 11 -11 16 8 6
22 7 22 26 5 11 -10 26 -29 33 -44 12 -22 11 -31 -6 -70 -18 -39 -19 -49 -8
-89 17 -65 55 -132 99 -175 l37 -37 -24 -25 c-21 -20 -24 -31 -20 -60 11 -64
22 -68 94 -28 35 19 68 35 72 35 13 0 44 -137 35 -151 -5 -9 -16 -2 -36 22
l-29 34 -21 -25 c-16 -20 -19 -35 -16 -77 5 -58 4 -59 -52 -43 -34 9 -36 12
-60 100 l-25 90 -37 -25 c-20 -14 -41 -25 -46 -25 -5 0 -13 25 -17 56 l-7 57
-26 -26 -25 -26 38 -153 c35 -141 41 -209 15 -182 -4 5 -39 95 -76 199 -53
150 -67 201 -66 242 1 45 -3 55 -24 72 -22 18 -24 25 -24 111 0 52 -9 136 -20
193 -19 94 -23 104 -57 134 l-37 31 12 79 c7 43 12 84 12 91 0 22 -20 12 -31
-15 l-11 -28 -19 50 c-24 62 -24 108 -2 146 17 28 17 32 -1 82 -10 28 -27 64
-37 79 -23 31 -24 38 -5 38 7 0 19 7 26 15 11 13 8 15 -13 15 -14 0 -27 -6
-29 -12 -3 -7 -8 6 -11 29 -4 23 -3 53 3 68 18 47 11 117 -16 175 -15 30 -32
77 -39 103 l-14 49 50 -7 49 -7 -21 54 c-12 29 -23 58 -26 65 -5 12 20 19 73
22 29 1 32 -2 38 -40z m22096 21 c0 -17 -7 -20 -39 -20 -44 0 -71 8 -71 20 0
8 17 11 93 18 11 1 17 -5 17 -18z m-823 -6 c3 -8 -2 -23 -11 -32 -15 -15 -16
-14 -16 16 0 33 16 43 27 16z m-22870 -166 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13
2 -7 2 -19 0 -25z m22747 -120 c-8 -35 -16 -67 -17 -71 -1 -5 -15 1 -30 14
-34 26 -31 61 9 97 45 40 54 31 38 -40z m-1629 -88 c3 -5 3 -10 -2 -10 -4 0
-18 -3 -30 -6 -17 -5 -23 -2 -23 10 0 18 45 23 55 6z m-4075 -40 c8 -5 12 -12
9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30 -1z m-11141 -192 c-23 -31 -75 -68
-97 -68 -9 0 -6 13 9 43 31 61 34 64 74 59 l35 -4 -21 -30z m14855 36 c12 -5
8 -20 -27 -90 -23 -46 -45 -84 -49 -84 -20 0 -6 78 22 128 17 28 32 52 34 52
3 0 11 -3 20 -6z m3341 -96 c30 -13 31 -24 6 -62 l-19 -29 -11 39 c-12 42 -14
64 -6 64 2 0 16 -5 30 -12z m-3277 -180 c-10 -53 -40 -122 -58 -133 -13 -8
-13 36 1 72 11 30 58 113 63 113 2 0 -1 -23 -6 -52z m-15148 33 c0 -5 -18 -25
-40 -45 -30 -27 -49 -36 -75 -36 l-36 0 16 29 c11 22 28 34 62 44 58 18 73 20
73 8z m-6180 -55 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6
0 10 -6 10 -14z m-49 -36 c80 -24 124 -63 200 -177 29 -43 113 -165 187 -271
81 -116 140 -211 149 -240 12 -38 13 -93 7 -302 -15 -575 56 -1106 211 -1590
68 -212 157 -413 342 -778 102 -200 182 -366 179 -369 -2 -3 -26 2 -51 11
l-47 15 18 -27 c9 -15 36 -49 59 -77 52 -61 65 -86 65 -120 l0 -27 -42 25
c-60 34 -110 111 -144 217 -16 49 -32 90 -35 90 -4 0 -10 -16 -13 -35 -5 -26
-12 -35 -27 -35 -21 0 -46 22 -116 107 l-43 51 36 29 36 29 -113 74 c-63 41
-141 100 -174 130 -33 31 -127 112 -210 181 -82 70 -162 139 -177 155 -35 38
-58 94 -58 144 0 22 -9 63 -20 92 -11 29 -41 159 -66 288 -80 415 -193 832
-290 1077 -70 175 -104 308 -129 498 -41 321 -58 699 -34 762 38 100 137 124
300 73z m21169 -47 c-1 -5 -7 -19 -15 -33 l-14 -25 -1 26 c0 14 3 29 7 32 9 9
23 9 23 0z m-2830 -108 c-7 -8 -17 -12 -23 -8 -6 4 -3 11 8 19 23 17 32 10 15
-11z m5836 -161 c32 -22 33 -58 2 -49 -68 21 -84 57 -50 118 l17 32 3 -43 c2
-32 9 -46 28 -58z m-2904 41 c-5 -27 -22 -45 -22 -23 0 25 12 58 20 53 4 -2 5
-16 2 -30z m2722 5 c31 -38 46 -88 46 -161 0 -94 19 -165 47 -178 28 -13 29
-12 38 83 6 70 21 112 49 135 21 17 35 6 58 -46 27 -60 20 -90 -53 -235 -32
-65 -59 -121 -59 -126 0 -4 34 -25 76 -45 l77 -38 -54 -36 c-30 -20 -57 -39
-61 -42 -19 -17 -50 -211 -49 -306 1 -55 -3 -145 -9 -200 -6 -55 -17 -161 -25
-235 -9 -92 -23 -165 -44 -231 -19 -58 -39 -156 -51 -249 -19 -137 -19 -155
-6 -176 15 -21 14 -24 -18 -56 -25 -25 -40 -54 -55 -108 -64 -223 -127 -344
-238 -455 -94 -94 -367 -330 -449 -388 -46 -33 -55 -36 -60 -22 -15 38 7 148
59 305 63 189 101 336 153 605 37 184 38 197 39 425 2 269 8 309 85 540 82
245 110 377 135 630 17 172 41 242 108 316 19 22 62 82 95 134 77 121 125 190
134 190 3 0 18 -14 32 -30z m600 1 c22 -24 13 -41 -20 -41 -26 0 -40 20 -30
45 8 21 29 19 50 -4z m-25006 -141 l-3 -25 -17 25 c-15 22 -15 32 -6 70 l12
45 8 -45 c5 -25 7 -56 6 -70z m1788 86 c10 -8 16 -17 12 -21 -11 -11 -48 5
-48 21 0 18 11 18 36 0z m17154 4 c24 -16 -26 -53 -59 -45 -36 9 -37 12 -16
35 20 22 51 26 75 10z m6040 -92 c0 -14 -18 -9 -24 8 -11 28 -6 39 9 20 8 -11
15 -23 15 -28z m-24970 -72 c0 -13 3 -39 7 -57 l6 -34 -21 19 c-22 19 -28 50
-16 80 9 24 24 19 24 -8z m19119 -41 c26 -45 10 -55 -23 -13 -18 23 -22 35
-13 40 7 4 13 8 14 8 0 0 10 -16 22 -35z m5951 -91 c0 -7 -56 21 -72 35 -17
15 -17 18 3 51 l21 34 24 -59 c13 -32 24 -60 24 -61z m-23131 -18 c9 -10 8
-15 -3 -19 -16 -6 -40 16 -29 26 10 11 19 8 32 -7z m-1909 -91 c0 -52 -14 -60
-24 -14 -9 47 -7 59 9 59 11 0 15 -12 15 -45z m19358 -228 c32 -34 66 -117 53
-130 -14 -14 -15 -13 -55 76 -36 79 -36 94 2 54z m-16124 -97 c19 -49 20 -50
2 -50 -8 0 -19 12 -24 28 -5 15 -13 35 -17 45 -5 12 -2 17 8 17 8 0 22 -18 31
-40z m-60 -51 c36 -43 35 -98 -4 -166 -37 -65 -51 -74 -43 -29 4 27 2 35 -13
40 -11 3 -31 17 -46 31 -36 33 -37 79 -3 146 l26 50 29 -21 c15 -11 40 -34 54
-51z m21825 0 c28 -76 34 -194 12 -249 -38 -93 -78 -117 -142 -84 -28 14 -33
14 -55 -1 -28 -18 -31 -30 -8 -39 26 -10 47 -7 66 11 19 17 19 16 13 -44 -9
-75 5 -123 47 -158 35 -29 33 -34 -16 -45 -27 -6 -38 -4 -48 8 -7 8 -28 21
-46 29 -32 13 -54 8 -134 -35 -13 -6 -48 91 -48 132 1 81 26 234 43 257 9 13
44 53 77 89 63 68 61 67 90 20 7 -13 13 -10 38 14 30 28 30 29 16 70 -15 41
-15 41 16 69 18 16 37 26 43 24 7 -2 23 -32 36 -68z m142 -78 c-6 -20 -17 -45
-25 -56 -14 -19 -15 -19 -26 9 -14 38 -4 82 23 101 19 13 22 13 30 -1 6 -10 5
-30 -2 -53z m-25388 -36 c15 -31 27 -57 27 -59 0 -2 -20 -11 -45 -20 l-46 -15
6 52 c13 116 19 120 58 42z m18712 -135 c-6 -9 -9 -9 -16 1 -10 17 0 34 13 21
6 -6 7 -16 3 -22z m-12995 -15 c-12 -15 -30 -12 -30 6 0 5 10 9 21 9 18 0 19
-2 9 -15z m-2414 -76 c28 -56 7 -90 -66 -103 -34 -7 -36 -5 -62 43 -15 28 -24
53 -19 55 5 3 23 1 41 -5 29 -11 34 -9 58 15 15 14 28 26 29 26 1 0 10 -14 19
-31z m-2046 -61 c25 -54 50 -177 50 -245 0 -28 -19 -11 -38 35 -15 35 -52 225
-52 267 0 18 15 -4 40 -57z m2380 28 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4
0 6 5 11 10 11 6 0 10 -2 10 -4z m15184 -9 c12 -9 17 -23 14 -42 l-3 -29 -28
42 c-28 43 -20 56 17 29z m-15245 -6 c17 -5 28 -17 34 -40 4 -18 6 -35 4 -37
-8 -9 -157 19 -157 29 0 6 10 21 22 34 22 23 50 27 97 14z m-71 -143 c17 -17
16 -78 -2 -78 -20 0 -46 39 -46 67 0 25 27 32 48 11z m11907 -60 c-9 -44 -21
-55 -30 -28 -4 14 -1 28 11 41 24 26 27 24 19 -13z m3525 -19 c0 -6 -9 -8 -22
-3 -29 8 -31 10 -23 23 7 13 45 -4 45 -20z m-15395 -39 c8 -24 -13 -66 -26
-53 -11 11 -1 73 12 73 4 0 10 -9 14 -20z m207 -27 c37 -33 18 -136 -29 -153
-16 -6 -38 -15 -49 -21 -16 -8 -27 -8 -43 2 -29 19 -27 22 19 43 46 20 70 58
70 111 0 39 5 42 32 18z m16200 -73 c1 -21 -5 -38 -17 -46 -19 -14 -65 -19
-65 -8 0 8 74 93 78 89 2 -2 4 -18 4 -35z m5177 4 c8 -7 -22 -34 -38 -34 -10
0 -28 12 -42 26 l-24 27 50 -8 c28 -4 52 -9 54 -11z m-23785 -35 c3 -17 6 -41
6 -53 -1 -21 -1 -21 -20 3 -23 27 -27 81 -6 81 7 0 16 -14 20 -31z m14038
-156 c23 -52 18 -90 -17 -123 -14 -13 -27 -22 -29 -19 -9 9 7 198 16 192 6 -3
19 -25 30 -50z m-319 -104 c-30 -12 -34 -10 -23 12 10 17 14 18 27 8 13 -12
13 -14 -4 -20z m-13690 -1 c4 -12 4 -24 1 -27 -6 -7 -24 20 -24 37 0 21 17 13
23 -10z m17827 -14 c14 -37 13 -49 -4 -35 -16 14 -27 61 -14 61 5 0 13 -12 18
-26z m-10552 -30 c33 -9 27 -21 -15 -28 -21 -3 -49 -9 -63 -13 -23 -6 -24 -5
-10 11 12 16 43 34 60 35 3 1 15 -2 28 -5z m6208 -100 c-3 -9 -9 -27 -12 -40
-4 -14 -11 -23 -18 -21 -18 6 -19 43 -1 61 21 21 39 21 31 0z m-6226 -38 c0
-2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m6770 -41 c0 -115 -42
-295 -69 -295 -16 0 -14 22 19 170 17 74 30 136 30 137 0 2 5 3 10 3 6 0 10
-7 10 -15z m1480 -157 c0 -31 -14 -35 -53 -17 -31 14 -47 48 -47 102 l0 41 50
-49 c36 -36 50 -57 50 -77z m-8378 -20 c-18 -19 -25 -20 -48 -9 -32 14 -26 22
23 31 48 8 52 5 25 -22z m268 -164 c0 -19 -16 -18 -24 2 -11 27 -7 37 9 24 8
-7 15 -19 15 -26z m-80 -54 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m1500 -139 c0 -5 -13 -11 -30 -13 -20 -3 -30 0 -30 9
0 8 12 13 30 13 17 0 30 -4 30 -9z m-1445 -109 c-6 -7 -35 18 -35 31 0 5 9 2
20 -8 11 -10 18 -20 15 -23z m6585 7 c0 -5 -7 -6 -15 -3 -8 4 -15 13 -15 21 0
13 3 13 15 3 8 -7 15 -16 15 -21z m1210 -94 c0 -5 -5 -3 -10 5 -5 8 -10 20
-10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m97 -7 c11 -13 23 -29 27 -36 10
-16 -45 -162 -61 -162 -21 0 -45 47 -41 82 3 32 4 33 48 30 33 -2 41 0 30 7
-8 6 -28 11 -44 11 -23 0 -27 3 -21 18 4 9 12 29 17 45 12 33 19 34 45 5z
m-6490 -58 c3 0 3 -22 -1 -48 -6 -45 -4 -49 24 -70 16 -13 30 -29 30 -37 0 -8
-17 -33 -37 -56 -35 -39 -36 -42 -24 -72 7 -18 15 -40 18 -49 4 -15 -59 -142
-76 -153 -8 -5 -21 90 -21 155 0 24 -10 52 -31 83 -38 58 -40 116 -8 217 l21
69 50 -20 c27 -10 51 -19 55 -19z m83 15 c0 -19 -20 -28 -51 -23 -22 3 -22 4
-5 21 22 21 56 22 56 2z m6240 -25 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m-7815 -60 c-5 -8 -11 -8 -17 -2 -6 6 -7 16
-3 22 5 8 11 8 17 2 6 -6 7 -16 3 -22z m15455 -22 c0 -24 -46 -89 -76 -108
-18 -13 -46 -20 -77 -20 l-47 0 54 55 c56 57 98 85 129 85 9 0 17 -6 17 -12z
m-15316 -14 c9 -23 7 -36 -3 -30 -12 8 -21 46 -11 46 4 0 11 -7 14 -16z m7926
-50 c0 -24 -5 -34 -15 -34 -10 0 -15 10 -15 33 0 19 3 37 6 40 13 13 24 -5 24
-39z m-8134 -210 c4 -23 1 -34 -17 -49 l-22 -20 6 34 c4 18 7 45 7 59 0 34 17
17 26 -24z m1904 36 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m5050 -115 c-14 -16 -38 -19 -67 -8 -15 6 -13 9 12 14 56 12
69 11 55 -6z m-5720 -205 c0 -10 -4 -22 -10 -25 -6 -4 -10 7 -10 25 0 18 4 29
10 25 6 -3 10 -15 10 -25z m5063 -48 c-2 -15 6 -23 31 -32 18 -6 41 -19 51
-30 20 -22 37 -87 29 -113 -6 -17 -8 -17 -31 5 -14 12 -38 51 -54 86 -21 47
-33 62 -49 62 -22 0 -27 19 -8 38 17 17 33 9 31 -16z m-5099 -49 c-11 -61 -19
-69 -34 -34 -11 28 -10 34 5 51 27 30 37 24 29 -17z m5056 -10 c0 -26 0 -26
-20 -8 -16 14 -17 20 -8 27 22 13 28 9 28 -19z m-12820 -41 c0 -4 -5 -13 -12
-20 -13 -13 -52 3 -43 19 7 11 55 12 55 1z m8448 -19 l21 -28 32 19 32 19 -6
-24 c-4 -13 -7 -33 -7 -44 0 -16 -19 -30 -75 -58 -41 -20 -75 -34 -75 -32 0
22 44 175 50 175 5 0 17 -12 28 -27z m4458 -34 c3 -5 -6 -9 -20 -9 -22 0 -56
31 -56 52 0 9 67 -30 76 -43z m-4996 -19 c0 -22 -4 -40 -10 -40 -16 0 -23 49
-10 65 18 22 20 19 20 -25z m-163 -13 c-3 -24 -11 -57 -18 -73 l-11 -29 -10
25 c-10 28 -4 64 18 98 22 33 27 27 21 -21z m7576 -92 c12 -17 14 -26 5 -31
-11 -7 -48 29 -48 47 0 17 25 8 43 -16z m-7447 -56 c-3 -17 -6 -38 -6 -45 0
-8 -4 -14 -10 -14 -11 0 -13 33 -4 68 10 34 27 27 20 -9z m-1788 -86 c4 -24
-53 -139 -92 -187 l-23 -30 18 120 c10 65 18 120 18 122 1 2 18 2 39 0 29 -2
38 -7 40 -25z m-4333 -19 c30 -23 33 -28 24 -54 l-10 -28 -45 15 c-24 8 -46
18 -48 22 -5 7 32 71 41 71 2 0 19 -12 38 -26z m-4640 -134 c-5 -8 -11 -8 -17
-2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7 -16 3 -22z m6059 -60 l6 -45 -20 24
c-21 25 -24 41 -13 80 6 22 7 23 13 5 4 -10 10 -39 14 -64z m-2821 -15 c0 -6
-7 -11 -16 -13 -12 -3 -15 1 -11 13 7 17 25 17 27 0z m13347 -242 c-1 -36 -2
-37 -15 -19 -16 21 -19 39 -8 49 15 16 23 5 23 -30z m-7492 -72 c10 -6 7 -10
-13 -15 -30 -8 -41 -3 -30 13 7 13 25 14 43 2z m-8950 -127 c-3 -37 -4 -38
-16 -17 -7 13 -10 34 -6 49 10 38 26 14 22 -32z m4752 -39 c0 -8 -7 -15 -16
-15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4 -7 4 -15z m16018 -95 c-5 -17
-14 -33 -19 -36 -14 -8 -11 40 3 54 21 21 27 13 16 -18z m-11853 11 c7 -12
-12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m1682 -29 l13 -33 -25 17
c-14 9 -30 26 -36 36 -9 17 -8 19 12 16 15 -2 27 -15 36 -36z m-10807 1 c17
-47 26 -58 56 -63 25 -4 29 -10 32 -41 2 -19 -3 -48 -9 -65 l-13 -29 -9 47
c-10 45 -12 47 -46 50 l-36 3 -3 63 c-3 66 11 84 28 35z m4958 -34 c-13 -37
-28 -37 -28 -1 0 33 7 43 25 36 10 -4 11 -12 3 -35z m5672 21 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-9950 -161 c0 -108
-3 -140 -12 -137 -8 3 -14 25 -15 54 -2 27 -8 80 -14 117 -10 61 -9 70 7 88 9
10 21 19 26 19 4 0 8 -63 8 -141z m8226 87 c18 -14 18 -15 -8 -21 -31 -8 -48
0 -48 21 0 18 30 18 56 0z m787 -44 c18 -21 31 -62 20 -62 -8 0 -73 74 -73 83
0 10 39 -6 53 -21z m1078 -96 c9 -33 -10 -44 -50 -30 -32 12 -41 52 -15 71 23
17 55 -3 65 -41z m-10797 -228 c21 -30 7 -47 -16 -19 -10 13 -18 27 -18 32 0
16 19 9 34 -13z m15131 -62 c-32 -84 -48 -68 -19 18 11 32 20 44 26 38 7 -7 4
-26 -7 -56z m-15265 -61 c0 -26 -4 -35 -16 -35 -12 0 -15 6 -10 23 3 12 6 28
6 35 0 6 5 12 10 12 6 0 10 -16 10 -35z m175 25 c3 -5 2 -10 -4 -10 -5 0 -13
5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-5 -108 c0 -8 -16 -32 -35 -53
-29 -32 -35 -47 -35 -84 0 -62 -26 -123 -61 -140 -59 -31 -69 -18 -69 83 l0
66 34 -33 c31 -30 35 -31 44 -15 6 11 6 29 1 48 -14 46 -1 84 44 133 38 41 41
43 58 27 11 -10 19 -24 19 -32z m15060 -43 c0 -15 -11 -41 -25 -56 -29 -33
-31 -24 -13 62 10 52 13 56 25 39 7 -10 13 -30 13 -45z m8365 -229 c204 -103
318 -306 360 -640 9 -74 39 -223 66 -330 92 -362 82 -444 -83 -702 -45 -70
-53 -78 -81 -78 -46 1 -100 29 -200 103 -50 38 -114 76 -150 89 -34 12 -89 43
-122 68 -33 26 -172 121 -310 211 -321 212 -489 334 -583 424 l-75 73 19 32
c39 66 148 193 214 248 37 31 150 114 251 184 101 70 222 158 269 197 172 143
211 163 302 158 40 -2 76 -13 123 -37z m-19450 -73 c28 -14 81 -44 120 -66
l70 -41 -116 0 -115 0 -12 46 c-11 41 -17 47 -65 69 -50 22 -51 23 -22 27 42
5 82 -5 140 -35z m-2275 17 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11
15 -7z m-1250 -19 c-7 -8 -16 -12 -21 -9 -13 8 -1 24 18 24 13 0 13 -3 3 -15z
m-451 -117 c1 -10 -3 -18 -8 -18 -14 0 -22 31 -15 54 7 21 7 21 15 1 4 -11 8
-28 8 -37z m-148 -10 c15 -73 27 -339 20 -413 l-7 -70 -13 58 c-29 133 -42
360 -25 446 8 39 14 34 25 -21z m17796 2 c-6 -17 -15 -30 -19 -30 -10 0 -10 1
3 38 6 18 15 30 19 27 5 -3 3 -19 -3 -35z m-16911 5 c15 -22 16 -48 10 -208
-4 -100 -9 -201 -13 -224 l-5 -43 -24 49 c-25 49 -43 61 -96 61 -29 0 -58 31
-58 63 0 10 7 29 16 43 12 17 15 38 12 69 -4 32 -2 45 7 45 19 0 33 -21 47
-73 15 -56 28 -61 28 -10 0 24 -8 45 -21 59 l-22 23 23 53 c13 29 29 68 37 86
16 38 37 41 59 7z m3287 -68 c115 -48 213 -87 218 -87 6 0 5 8 -1 20 -16 30
-8 81 15 95 28 18 116 -17 168 -67 l40 -38 -21 -23 c-26 -27 -28 -37 -8 -37 8
0 16 10 18 23 4 29 38 26 98 -11 65 -40 110 -103 110 -155 0 -32 3 -38 12 -29
19 19 52 14 91 -15 32 -25 55 -68 43 -80 -3 -3 -35 5 -71 17 -36 12 -68 20
-70 19 -2 -2 28 -30 66 -62 39 -32 77 -74 86 -92 15 -31 15 -36 0 -59 -26 -39
-200 -158 -347 -236 -235 -126 -370 -221 -555 -394 -118 -110 -179 -156 -247
-185 -51 -22 -141 -88 -233 -171 -71 -65 -103 -79 -141 -61 -35 16 -49 57 -68
197 -9 67 -26 168 -36 223 -40 207 -18 325 120 631 69 154 91 212 135 365 41
144 68 199 117 240 60 51 106 66 183 62 61 -3 97 -15 278 -90z m13887 -63 c0
-5 -6 -19 -14 -30 -14 -18 -16 -18 -32 -2 -19 18 -24 69 -10 103 7 18 10 16
32 -20 13 -22 24 -45 24 -51z m-330 20 c0 -3 -11 -25 -25 -48 l-25 -43 44 -17
c24 -9 47 -24 50 -33 4 -8 -1 -42 -10 -75 -14 -50 -15 -66 -5 -94 11 -32 11
-36 -10 -52 -30 -25 -37 -104 -15 -167 8 -26 13 -51 10 -56 -3 -5 -21 -9 -41
-9 -28 0 -43 8 -69 36 -35 37 -42 54 -22 54 6 0 20 7 30 17 14 13 18 31 18 90
0 74 0 74 -32 88 -18 7 -36 11 -39 9 -4 -2 -10 -27 -14 -54 -4 -28 -10 -50
-14 -50 -4 0 -28 22 -54 48 l-48 49 61 134 c34 74 65 139 71 144 8 8 62 22
132 34 4 0 7 -2 7 -5z m-17100 -18 c0 -9 -8 -12 -25 -8 -14 2 -25 8 -25 13 0
5 11 9 25 9 14 0 25 -6 25 -14z m17410 -119 c0 -48 -34 -125 -78 -176 l-29
-33 -26 75 c-15 41 -27 80 -27 85 0 6 18 13 40 17 30 5 49 17 73 45 17 21 35
36 39 34 5 -3 8 -24 8 -47z m-3130 8 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3
43 11 11 13 8 13 -18z m-9890 -201 c0 -19 -10 -17 -40 5 -21 17 -22 21 -10 36
13 16 15 16 32 -5 10 -12 18 -28 18 -36z m-4126 -166 c-10 -16 -24 -8 -30 18
-5 17 -2 29 7 35 10 6 16 1 21 -18 4 -14 5 -30 2 -35z m16629 -20 c-7 -13 -40
-9 -52 6 -11 14 -6 40 12 64 8 10 14 3 27 -25 10 -21 15 -41 13 -45z m17 -214
c0 -4 -16 -28 -35 -54 l-35 -48 20 -46 c16 -39 17 -46 4 -46 -19 0 -53 75 -54
116 0 39 17 62 55 73 38 12 45 12 45 5z m139 -64 c8 -24 7 -30 -5 -30 -7 0
-14 3 -14 8 0 4 -3 17 -6 30 -9 33 12 27 25 -8z m-17045 -27 c-6 -28 -61 -89
-71 -79 -5 5 64 106 73 106 2 0 2 -12 -2 -27z m11324 -168 c-2 -13 -8 -6 -21
21 l-18 39 21 -21 c12 -12 20 -29 18 -39z m5438 -72 c-8 -8 -36 5 -36 17 0 7
6 7 21 0 11 -7 18 -14 15 -17z m-16646 -134 c0 -16 -17 -36 -24 -28 -2 2 -6
21 -9 43 -6 37 -6 38 13 21 11 -10 20 -26 20 -36z m17150 24 c-1 -18 -28 -55
-33 -46 -10 15 4 53 19 53 8 0 14 -3 14 -7z m-12307 -115 c120 -118 119 -116
134 -287 10 -128 20 -175 77 -381 106 -378 110 -394 103 -420 -5 -19 -53 -56
-189 -147 -101 -67 -209 -134 -241 -148 -144 -63 -320 -92 -640 -105 -310 -12
-335 -3 -473 166 l-64 78 22 26 c12 14 86 88 163 165 91 90 159 168 192 220
65 100 38 68 362 427 270 299 449 488 462 488 4 0 45 -37 92 -82z m11757 -17
c17 -34 17 -40 4 -60 -20 -27 -49 -27 -68 1 -22 31 -20 58 5 85 26 27 33 24
59 -26z m-160 1 c0 -4 16 -32 35 -62 38 -59 46 -112 20 -146 -13 -18 -14 -17
-15 18 0 34 -6 42 -55 78 -50 37 -85 82 -85 110 0 5 22 10 50 10 28 0 50 -4
50 -8z m295 -63 c-8 -12 -41 6 -49 27 -12 31 7 38 31 10 13 -15 21 -32 18 -37z
m195 -19 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-104 -220 c111 -137 105 -126 58 -102 -66 34 -114 97 -139 182 -9 30 -8
30 81 -80z m224 55 l0 -35 -50 0 c-27 0 -50 4 -50 8 0 18 61 59 93 61 4 1 7
-15 7 -34z m-136 -15 c42 -32 94 -100 76 -100 -36 0 -101 56 -119 103 -13 34
-6 34 43 -3z m356 9 c11 -19 10 -20 -10 -9 -11 6 -18 15 -15 21 9 13 13 11 25
-12z m3877 -122 c109 -68 254 -164 323 -215 69 -50 163 -112 210 -138 47 -26
119 -74 159 -107 41 -33 108 -75 148 -94 40 -19 81 -45 92 -59 11 -14 29 -24
44 -24 61 0 132 -55 199 -153 39 -58 95 -96 141 -97 50 0 -19 -41 -148 -87
-107 -39 -228 -103 -365 -193 -148 -98 -151 -99 -310 -141 -87 -22 -148 -32
-200 -32 -127 0 -253 48 -360 137 -30 25 -78 62 -107 82 -52 36 -52 37 -38 65
14 26 13 29 -7 43 -12 9 -28 16 -34 16 -7 0 -26 14 -43 31 -37 36 -41 82 -12
143 21 44 18 61 -10 77 -19 10 -19 11 1 36 27 34 25 79 -5 128 -30 48 -33 131
-10 270 20 127 110 435 127 435 4 0 97 -55 205 -123z m-4062 51 c11 -16 12
-24 4 -27 -12 -4 -39 25 -39 41 0 16 19 8 35 -14z m-11315 -303 c-1 -22 -50
48 -50 70 0 13 -4 35 -9 49 -7 17 -7 26 2 29 11 4 57 -119 57 -148z m-6435 58
c4 -31 9 -62 12 -70 3 -7 1 -13 -5 -13 -5 0 -14 26 -21 57 -13 68 -14 83 -2
83 5 0 12 -25 16 -57z m9125 -18 c0 -13 -20 -35 -32 -35 -10 0 -11 64 -1 74 8
8 33 -21 33 -39z m-90 -95 c-55 -66 -212 -191 -370 -295 -118 -78 -129 -88
-191 -176 -130 -184 -166 -245 -172 -294 -4 -26 -21 -73 -37 -104 -33 -61
-154 -190 -167 -177 -5 4 -8 35 -8 69 0 54 7 75 52 167 97 199 152 269 340
436 379 339 474 408 586 423 5 0 -10 -22 -33 -49z m-8307 -176 c9 -9 -4 -35
-14 -28 -5 3 -9 12 -9 20 0 14 12 19 23 8z m22508 -34 c5 0 9 -4 9 -9 0 -12
-38 -2 -47 12 -4 7 0 8 11 4 10 -4 22 -7 27 -7z m-123 -49 c3 -8 -1 -12 -9 -9
-7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m-127 -71 c19 0 49 -41
49 -66 0 -26 0 -26 -50 16 -46 38 -59 69 -23 56 8 -3 19 -6 24 -6z m-15931
-357 c0 -7 -3 -13 -7 -13 -13 0 -52 67 -45 75 8 8 52 -45 52 -62z m-1460 43
c0 -14 -135 -126 -151 -126 -38 0 31 84 98 119 46 24 53 25 53 7z m3491 -29
c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m-3367 -16 c6 -9
-74 -61 -93 -61 -23 0 -9 19 32 44 47 28 53 30 61 17z m166 -46 c0 -23 -6 -38
-16 -42 -23 -9 -32 8 -22 45 12 44 38 43 38 -3z m-560 -69 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m580 -46 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m1689 -105
c20 -19 55 -53 79 -74 36 -33 42 -44 42 -79 0 -23 4 -44 10 -47 5 -3 14 -15
20 -25 8 -15 6 -21 -10 -30 -17 -9 -29 -7 -64 14 -24 14 -56 40 -72 58 -25 31
-94 165 -94 186 0 7 34 29 49 31 3 1 21 -15 40 -34z m-2221 -97 c-5 -15 -78
-24 -78 -9 0 12 18 19 53 20 19 1 27 -3 25 -11z m3542 -18 c0 -16 -2 -30 -5
-30 -8 0 -23 44 -18 53 10 17 23 5 23 -23z m-1095 -208 c-8 -9 -35 20 -28 31
4 6 11 3 19 -8 8 -10 12 -21 9 -23z m1005 -161 c0 -5 -7 -14 -15 -21 -12 -10
-15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z m1450 -153 c0 -34 -10 -35 -39
-4 l-22 23 23 15 c27 18 38 8 38 -34z m9022 1 c-4 -26 -6 -29 -14 -15 -10 18
-4 46 10 46 5 0 6 -14 4 -31z m-213 -386 l1 -53 -66 0 c-75 0 -76 -9 5 88 51
62 59 57 60 -35z m84 1 c3 -3 3 -12 0 -20 -4 -11 -9 -12 -19 -3 -8 6 -14 15
-14 20 0 10 24 12 33 3z m-49 -105 c25 -47 16 -82 -37 -133 l-41 -41 -12 40
c-22 72 -16 112 24 158 22 25 44 17 66 -24z m-5221 -414 c151 -14 209 -25 598
-120 260 -64 454 -116 492 -133 140 -65 242 -170 348 -358 56 -100 145 -234
154 -234 6 0 -10 50 -41 125 -31 74 -39 164 -18 185 9 9 22 2 54 -30 80 -80
139 -251 145 -425 3 -91 5 -101 28 -120 31 -25 42 -107 38 -280 -1 -76 4 -151
13 -205 38 -205 40 -228 21 -270 -16 -34 -16 -48 -4 -147 7 -59 21 -130 32
-158 14 -37 21 -90 28 -210 4 -88 13 -184 18 -214 16 -80 14 -118 -9 -141 -11
-11 -20 -23 -20 -26 0 -4 16 -28 36 -55 l36 -49 -21 -26 c-25 -32 -27 -84 -6
-143 23 -67 29 -138 15 -195 -11 -48 -9 -70 34 -306 25 -140 58 -305 73 -368
21 -87 25 -120 17 -140 -6 -15 -11 -67 -13 -117 -4 -129 -14 -208 -27 -212 -7
-2 -15 9 -19 24 l-7 28 -11 -23 c-38 -82 122 -836 264 -1250 42 -119 94 -232
107 -232 5 0 19 12 33 26 l24 25 -57 53 c-56 52 -73 84 -66 124 3 19 7 18 62
-19 60 -40 86 -46 130 -29 l26 10 -21 -42 -22 -42 39 -34 c36 -31 38 -34 21
-45 -50 -32 -50 -33 -38 -55 9 -17 8 -28 -4 -52 -17 -33 -19 -64 -9 -123 5
-33 3 -42 -21 -67 l-27 -28 38 -46 c21 -25 42 -46 46 -46 5 0 6 17 2 38 -5 35
-3 39 46 77 28 22 54 40 59 40 5 0 25 -20 46 -44 37 -45 40 -60 24 -146 -1 -5
1 -20 5 -32 6 -19 4 -23 -16 -23 -26 0 -68 -26 -68 -42 0 -5 10 -8 23 -5 12 3
39 9 60 12 l37 7 0 -50 c0 -49 1 -51 33 -57 24 -5 63 -39 152 -131 125 -129
163 -154 232 -154 46 0 71 -16 154 -98 81 -81 181 -156 338 -254 98 -62 132
-78 162 -78 43 0 248 -71 289 -100 71 -50 459 -206 605 -242 22 -5 67 -16 100
-25 33 -8 70 -21 82 -29 33 -21 413 -536 413 -559 0 -4 -10 -21 -22 -38 -20
-28 -22 -41 -20 -142 2 -77 -4 -164 -22 -290 -32 -233 -33 -243 -56 -553 -10
-145 -21 -266 -24 -269 -3 -3 -27 7 -53 23 -51 30 -435 317 -519 388 -29 24
-74 52 -100 61 -63 24 -92 50 -104 96 -10 35 -32 60 -128 145 -24 20 -75 80
-115 133 -39 53 -111 143 -158 201 -48 58 -129 157 -181 220 -65 81 -109 124
-144 145 -36 21 -63 48 -90 90 -76 117 -496 665 -605 789 -80 90 -224 184
-339 220 -94 30 -412 96 -675 141 -276 47 -439 57 -865 53 l-365 -3 -87 -41
c-113 -55 -269 -156 -309 -202 -42 -47 -59 -90 -66 -165 -13 -148 74 -321 190
-375 75 -35 157 -36 345 -6 l44 7 -21 -41 -21 -40 29 -7 c22 -5 40 1 76 24 26
17 73 35 104 42 74 16 309 16 391 0 81 -16 236 -73 302 -112 l50 -30 -7 51
c-6 46 -5 52 23 80 36 35 52 38 52 9 0 -64 68 -132 145 -144 73 -12 103 -26
186 -85 84 -62 130 -75 158 -47 19 18 145 -117 163 -175 8 -29 34 -66 79 -113
108 -115 139 -161 139 -207 0 -33 -3 -39 -16 -34 -67 26 -69 20 -14 -35 31
-30 77 -79 104 -109 l47 -55 -4 43 c-3 23 -1 42 4 42 12 0 36 -40 45 -73 6
-26 5 -28 -20 -21 -22 5 -26 3 -26 -16 0 -30 120 -182 173 -219 49 -34 117
-104 177 -181 129 -168 417 -454 627 -622 167 -135 355 -242 523 -297 36 -12
118 -54 182 -92 146 -87 287 -157 399 -199 117 -43 150 -73 200 -178 23 -48
61 -114 84 -147 23 -33 61 -91 85 -130 23 -38 71 -100 106 -137 61 -63 64 -69
59 -105 -3 -21 -26 -205 -50 -409 -24 -204 -47 -378 -51 -388 -9 -23 -44 -20
-152 15 -53 16 -173 43 -266 59 -94 17 -191 36 -216 44 -53 16 -353 131 -478
182 -144 60 -455 221 -509 264 -28 22 -78 79 -111 127 -33 49 -71 94 -84 102
-13 8 -52 15 -88 16 -70 2 -124 21 -116 41 19 49 17 84 -9 145 -31 72 -51 130
-60 173 -9 42 -57 86 -94 86 -36 0 -36 1 -14 59 l17 44 -28 24 c-21 18 -29 34
-30 62 -4 67 -92 216 -231 391 -55 69 -99 142 -162 265 -47 94 -135 255 -196
360 -60 104 -130 228 -154 275 -150 285 -338 473 -613 614 -229 116 -426 166
-657 166 -149 0 -279 -21 -405 -66 -135 -48 -178 -78 -364 -255 -212 -199
-325 -324 -433 -474 -44 -60 -107 -136 -139 -167 -88 -83 -99 -120 -117 -381
-16 -247 -11 -291 44 -341 20 -18 21 -18 55 3 50 31 70 71 99 199 31 132 61
201 117 262 22 25 43 59 46 78 l7 34 46 -7 c25 -4 49 -9 53 -11 11 -7 -3 -36
-32 -69 -44 -48 -70 -109 -80 -192 -13 -97 -17 -110 -47 -151 -15 -19 -28 -56
-34 -93 -10 -63 -21 -88 -64 -148 -30 -41 -32 -61 -5 -61 30 0 66 43 103 122
28 62 33 67 44 52 23 -32 14 -61 -48 -144 -33 -45 -58 -88 -55 -96 8 -22 66
-53 111 -59 54 -9 177 18 250 54 80 40 173 120 245 210 81 102 119 178 135
267 17 97 56 162 167 279 102 107 195 174 336 244 132 64 211 79 340 62 149
-18 174 -39 257 -213 80 -170 120 -279 160 -446 44 -176 78 -253 150 -330 44
-47 68 -89 129 -223 41 -90 144 -317 229 -504 86 -187 152 -344 147 -349 -4
-4 -77 55 -162 132 -359 327 -558 493 -633 528 -109 51 -188 44 -304 -26 -45
-27 -102 -57 -129 -66 -63 -23 -195 -23 -296 0 -156 37 -271 9 -440 -104 -220
-149 -291 -227 -405 -450 -94 -185 -201 -431 -201 -465 0 -15 7 -55 16 -89 27
-101 15 -158 -63 -318 -37 -76 -69 -138 -72 -138 -3 0 -32 75 -65 168 -91 261
-224 628 -263 727 -61 154 -134 254 -393 539 -154 168 -182 194 -267 244 -78
46 -301 142 -329 142 -15 0 -53 -7 -87 -16 -81 -21 -117 -12 -177 41 -61 54
-82 59 -281 59 l-156 1 -64 -33 c-35 -19 -82 -51 -104 -71 -22 -21 -68 -59
-102 -85 -35 -26 -77 -68 -94 -93 -36 -52 -69 -147 -69 -201 -1 -21 -29 -129
-63 -242 -70 -228 -78 -240 -122 -171 -14 23 -32 41 -40 41 -18 0 -40 -42 -50
-95 -4 -22 -23 -85 -42 -140 -23 -68 -35 -121 -37 -166 -2 -56 -7 -72 -29 -99
-25 -29 -27 -38 -27 -122 0 -75 -3 -94 -20 -115 -16 -21 -20 -41 -20 -101 0
-59 -5 -86 -24 -127 -14 -29 -29 -87 -35 -133 -12 -96 -30 -136 -74 -162 -27
-17 -77 -27 -77 -16 0 2 13 36 30 75 16 40 53 141 81 224 47 141 51 157 45
221 -4 59 -2 73 16 98 14 20 23 56 29 111 6 47 37 177 74 302 140 480 216 756
213 771 -2 11 -20 0 -65 -41 -35 -31 -63 -54 -63 -51 0 18 114 325 144 389 21
43 84 146 140 230 57 84 138 211 182 282 95 156 134 198 239 252 44 22 107 55
139 72 84 44 159 43 290 -4 53 -19 113 -35 132 -35 34 0 35 1 20 21 -23 34 -3
69 41 69 15 0 40 -23 82 -74 91 -111 213 -198 339 -241 86 -30 342 -69 404
-62 135 16 224 125 235 288 l6 79 -42 0 c-45 0 -48 -7 -25 -51 8 -16 13 -38
10 -47 -7 -20 -60 -55 -70 -45 -3 3 -6 143 -6 311 0 256 -2 309 -16 337 -8 18
-86 102 -172 187 -173 168 -238 213 -406 276 -250 94 -410 109 -572 54 -61
-21 -130 -35 -205 -43 -177 -18 -266 -55 -386 -162 -77 -68 -125 -98 -273
-172 -74 -37 -165 -87 -201 -111 -125 -83 -224 -195 -294 -334 -15 -30 -63
-105 -106 -165 -165 -234 -374 -657 -470 -950 -68 -204 -86 -240 -286 -543
-208 -314 -277 -377 -692 -627 -253 -152 -247 -149 -408 -190 -194 -50 -319
-112 -418 -208 l-75 -72 -169 365 -170 365 -49 -3 c-64 -3 -80 13 -63 67 16
56 14 88 -10 120 -14 20 -22 49 -26 103 -5 70 -4 76 14 82 12 4 43 30 71 60
27 29 64 59 81 66 24 9 34 22 42 54 6 22 15 54 20 70 5 16 9 56 9 88 0 33 4
68 10 78 5 10 35 28 65 39 31 12 74 30 95 41 24 12 59 20 91 20 72 0 159 28
159 51 0 14 -25 26 -115 54 -63 20 -115 41 -115 46 0 18 29 67 42 72 7 2 28
-2 47 -9 54 -22 75 -17 134 35 51 45 144 91 183 91 13 0 12 -4 -1 -31 -9 -17
-15 -32 -13 -34 2 -2 56 -7 121 -10 136 -9 141 -5 71 58 l-45 42 31 38 c44 52
102 79 180 84 78 5 103 -6 77 -35 -23 -25 -21 -32 8 -32 33 0 32 -10 -9 -49
l-34 -33 -25 26 c-15 15 -37 26 -52 26 -29 0 -65 -16 -65 -29 0 -12 121 -26
195 -22 33 1 80 -4 105 -12 61 -18 96 -7 150 48 49 50 45 53 -50 29 -56 -14
-74 -15 -125 -4 l-59 12 27 24 c16 13 40 27 55 30 15 4 41 19 58 34 18 15 54
33 82 40 28 6 53 18 56 26 5 13 76 21 76 9 0 -3 -9 -24 -20 -45 -11 -22 -18
-40 -15 -40 3 0 28 11 55 25 57 29 73 31 90 10 48 -58 170 49 183 162 8 72 24
98 123 204 47 50 83 93 80 96 -3 3 -30 -9 -60 -27 -99 -56 -99 -15 2 100 36
41 80 85 99 98 36 25 36 24 37 103 l1 57 -27 -19 c-35 -26 -38 -24 -38 15 0
52 17 87 48 98 25 10 27 14 24 58 -3 31 2 61 13 84 19 40 87 106 110 106 26 0
18 -62 -15 -113 -16 -26 -30 -50 -30 -52 0 -3 17 -12 37 -20 35 -15 39 -15 51
2 26 33 52 98 52 128 0 40 20 67 78 107 26 17 93 78 149 134 l102 103 -6 48
c-4 36 -1 58 11 82 29 55 51 71 102 71 45 0 45 0 69 54 32 72 67 85 110 42
l23 -23 37 37 37 38 -51 17 c-28 9 -51 20 -51 24 0 17 42 61 59 61 19 0 115
76 197 156 42 41 62 53 97 58 59 9 121 63 151 130 13 29 38 64 56 79 30 25 34
26 54 13 21 -13 29 -10 110 37 l87 50 43 -21 c55 -28 61 -27 92 3 l24 25 77
-15 c42 -8 78 -16 80 -19 2 -2 -41 -41 -97 -86 -72 -59 -97 -86 -88 -91 21
-14 59 -10 142 11 43 12 80 19 82 17 11 -11 -49 -46 -134 -78 -152 -56 -139
-63 138 -70 129 -4 271 -12 315 -18 125 -18 304 -51 365 -67 70 -18 141 -18
185 0 19 8 57 36 86 62 l50 48 -98 49 c-117 58 -157 94 -178 158 -21 62 -64
121 -109 148 -26 16 -38 33 -46 62 -5 21 -15 57 -20 78 -26 101 -170 152 -595
211 -44 6 -106 22 -137 36 -84 37 -207 47 -463 40 -245 -6 -367 -22 -498 -66
-148 -50 -177 -75 -52 -45 58 14 78 13 59 -4 -5 -5 -65 -32 -134 -60 -207 -87
-282 -126 -330 -175 l-44 -46 29 0 c55 0 58 -13 12 -57 -23 -22 -52 -46 -64
-52 -25 -13 -73 -15 -73 -3 0 4 20 28 45 52 24 24 43 45 41 47 -4 5 -198 -110
-244 -144 -25 -19 -31 -29 -26 -48 5 -19 -17 -52 -112 -177 -109 -142 -164
-232 -164 -268 0 -8 43 28 96 80 53 52 93 89 89 82 -4 -7 -4 -20 -1 -29 6 -14
11 -12 37 13 56 53 111 123 104 134 -5 8 -12 7 -23 -2 -16 -12 -16 -11 -1 12
18 28 59 46 83 37 22 -9 20 -61 -4 -115 -17 -37 -22 -42 -43 -37 -18 5 -26 2
-31 -11 -9 -23 0 -30 19 -14 31 25 24 2 -16 -61 -118 -182 -249 -309 -249
-240 0 37 -19 32 -41 -10 -17 -34 -18 -41 -5 -35 9 3 23 9 32 13 14 5 16 1 12
-20 -4 -20 -1 -27 14 -29 16 -3 8 -19 -54 -114 -112 -167 -223 -308 -331 -419
-89 -91 -96 -97 -82 -61 8 22 14 41 12 43 -2 2 -42 -15 -89 -37 -47 -22 -89
-40 -92 -40 -3 0 -6 15 -6 33 0 26 10 42 46 77 55 54 114 139 138 199 9 24 42
71 73 105 30 34 98 126 151 206 53 80 109 163 126 185 l31 40 -30 -22 c-87
-62 -304 -346 -327 -429 -6 -24 -47 -73 -136 -163 -70 -70 -147 -146 -172
-167 -25 -22 -90 -94 -145 -160 -192 -230 -313 -352 -392 -393 -22 -11 -22
-11 3 45 l25 56 -28 -6 c-15 -3 -62 -24 -103 -46 -194 -103 -234 -120 -290
-123 l-55 -2 1 90 c2 154 52 276 262 640 113 196 264 493 277 548 11 43 -14
34 -65 -25 l-44 -52 -13 31 c-10 27 -8 45 18 144 51 195 96 270 281 466 73 78
85 104 69 147 -6 15 -11 33 -11 41 0 11 277 159 645 344 45 23 62 26 170 26
166 0 178 7 435 241 486 442 651 632 751 864 48 111 246 833 278 1015 6 33 16
140 21 237 7 118 24 257 49 410 70 420 91 671 91 1123 0 171 5 364 10 430 19
229 40 510 40 540 l0 30 -23 -35 -22 -35 -3 45 c-5 64 14 119 44 130 20 8 24
16 24 47 l-1 38 -20 -35 -20 -35 5 75 c5 80 20 127 57 174 20 26 25 57 50 300
15 149 33 332 39 406 12 148 27 206 65 255 53 71 442 386 512 416 119 51 272
59 618 34 259 -19 359 -19 453 1 128 26 227 71 357 160 114 78 122 82 165 78
25 -2 121 -11 213 -19z m1305 -207 c17 -17 15 -48 -4 -55 -16 -6 -64 15 -64
28 0 12 30 39 44 39 6 0 17 -5 24 -12z m55 -88 c73 -27 123 -83 160 -177 l17
-43 -35 -15 c-46 -18 -62 -12 -69 30 -4 19 -26 57 -49 85 -64 78 -87 112 -87
126 0 18 -1 18 63 -6z m-12857 -38 c-11 -10 -15 4 -8 28 l7 25 3 -23 c2 -13 1
-26 -2 -30z m18649 3 c286 -33 356 -51 505 -125 109 -55 185 -123 200 -181 9
-32 6 -45 -20 -102 -27 -56 -32 -82 -40 -193 -36 -518 -32 -504 -313 -924
-121 -180 -186 -287 -220 -365 -27 -60 -71 -141 -96 -180 -50 -74 -118 -193
-221 -390 -35 -66 -102 -185 -150 -265 -143 -237 -195 -352 -314 -698 -117
-339 -239 -604 -309 -671 l-46 -45 -20 45 c-21 48 -58 288 -71 464 -7 102 -10
114 -44 170 -64 105 -79 168 -84 360 -7 278 18 470 153 1153 82 419 99 526
120 737 28 298 55 414 126 560 22 44 55 100 74 125 19 25 51 68 71 97 35 50
35 52 20 82 -22 42 -20 75 8 108 l24 27 36 -27 c34 -26 37 -27 65 -12 16 8 32
23 35 33 13 38 90 98 213 163 70 37 137 68 148 68 11 0 79 -6 150 -14z
m-14526 -42 c55 -53 358 -544 486 -787 32 -61 75 -129 97 -152 29 -31 38 -50
38 -76 0 -58 26 -115 98 -217 38 -53 74 -109 80 -126 7 -16 18 -79 26 -140 17
-129 106 -407 155 -486 24 -39 33 -69 41 -137 15 -117 41 -235 95 -427 72
-256 72 -421 0 -715 -30 -122 -35 -155 -30 -210 5 -54 1 -86 -25 -185 -17 -66
-35 -154 -41 -195 -10 -86 -42 -157 -94 -213 l-35 -38 -47 40 c-36 33 -54 41
-84 41 -21 0 -41 5 -44 10 -4 6 -37 26 -75 45 -82 41 -110 73 -110 127 0 34
-6 45 -41 76 -40 36 -41 38 -34 87 l6 51 -49 17 c-27 10 -60 29 -72 42 -21 23
-22 26 -8 67 l15 43 -47 59 c-34 41 -57 85 -80 150 -59 165 -162 420 -207 511
-25 50 -79 144 -121 210 -41 66 -123 212 -182 325 -115 221 -224 414 -321 567
-76 121 -122 213 -189 378 -29 72 -99 227 -155 345 -171 357 -221 482 -210
529 9 43 48 73 181 139 223 112 424 186 624 231 52 12 122 28 155 35 33 8 84
14 113 15 48 1 56 -2 91 -36z m13949 -145 c-3 -7 -15 -14 -29 -16 -24 -4 -24
-3 -4 12 24 19 38 20 33 4z m-13648 -102 c19 -29 26 -47 19 -51 -6 -4 -12 -5
-13 -4 -47 64 -57 80 -51 89 9 15 12 14 45 -34z m1901 -119 c-1 -12 -15 -9
-19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-1645 -109 c15 -30 15 -32 -2 -45
-10 -7 -28 -13 -41 -13 -28 0 -32 33 -7 68 21 30 30 28 50 -10z m-98 -37 c28
-24 30 -71 4 -71 -30 0 -55 35 -48 65 8 30 14 31 44 6z m-2488 -183 c23 -40
122 -255 220 -480 99 -224 197 -443 219 -486 53 -106 164 -270 245 -362 169
-193 241 -299 301 -445 33 -79 42 -113 111 -420 53 -233 130 -495 258 -880
284 -847 318 -932 474 -1190 369 -608 419 -685 536 -825 l71 -85 3 -105 c3
-87 0 -124 -22 -212 -22 -92 -87 -287 -128 -385 l-14 -33 -115 98 c-304 259
-481 347 -946 476 -89 24 -170 49 -180 54 -45 24 -54 48 -64 154 -19 221 -74
367 -258 678 -50 84 -113 207 -141 273 -79 187 -289 778 -680 1917 -159 460
-321 863 -416 1030 -57 102 -79 152 -138 320 -20 58 -46 121 -57 139 -22 37
-72 61 -127 61 -27 0 -50 9 -80 31 l-42 31 32 38 c18 21 35 39 38 39 3 1 16
-17 30 -39 26 -42 29 -44 46 -32 21 14 34 51 34 96 0 25 4 46 9 46 6 0 22 -16
37 -35 14 -19 30 -35 34 -35 12 0 203 300 196 307 -3 4 -19 -6 -35 -21 l-28
-27 -10 38 c-17 68 7 97 129 152 104 48 104 48 125 -1 l19 -43 18 63 c11 39
22 62 32 62 7 0 14 -8 14 -17 0 -10 5 -35 11 -55 10 -37 11 -38 58 -38 62 0
74 14 82 102 5 52 12 74 29 93 13 14 25 25 26 25 1 0 21 -32 44 -72z m4335 21
c6 -27 1 -49 -9 -38 -10 11 -17 69 -8 69 6 0 13 -14 17 -31z m-1786 -36 c-10
-11 -14 -11 -20 -1 -10 17 -2 28 17 21 11 -4 12 -8 3 -20z m1551 -52 c0 -14
-18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m15111 -65 c74 -16 162
-51 179 -71 9 -10 3 -41 -23 -122 -42 -129 -63 -232 -77 -370 -13 -140 -61
-253 -134 -322 l-25 -23 -20 41 c-12 23 -23 41 -25 41 -2 0 -22 -27 -45 -60
-38 -54 -40 -59 -17 -49 54 25 115 -27 100 -86 -6 -24 -180 -207 -216 -226
-33 -18 -21 29 38 153 31 65 54 123 52 129 -7 22 -137 -185 -179 -286 -66
-160 -305 -784 -327 -855 -47 -152 -61 -245 -102 -652 -15 -152 -55 -242 -162
-369 -113 -134 -211 -286 -304 -472 -60 -119 -81 -147 -162 -207 -100 -77
-114 -102 -123 -218 -5 -75 -16 -124 -47 -217 -48 -140 -64 -266 -52 -415 12
-146 67 -535 95 -669 24 -116 31 -238 16 -290 -6 -21 -8 -20 -25 19 -41 93
-133 153 -349 225 -281 94 -475 91 -771 -12 -138 -48 -253 -109 -366 -194
-128 -98 -227 -161 -284 -183 -26 -10 -49 -16 -51 -14 -2 2 14 62 36 133 39
130 39 130 54 420 16 294 23 364 52 468 13 49 21 61 49 75 l34 17 -25 37 c-14
20 -25 44 -25 54 0 21 263 395 348 494 159 186 292 430 621 1138 188 403 224
523 276 909 27 204 49 291 97 388 31 61 132 195 147 195 17 0 154 166 223 270
38 58 106 177 150 265 44 88 100 189 123 225 45 71 177 239 259 330 28 32 66
77 85 100 72 89 138 119 441 201 264 70 342 80 461 55z m-12535 -27 c-3 -17
-6 -39 -6 -47 0 -10 -3 -12 -9 -6 -11 12 -2 84 11 84 6 0 8 -13 4 -31z m-7343
-40 c-17 -17 -37 -1 -22 17 8 10 15 12 23 4 7 -7 7 -13 -1 -21z m-1393 11 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4921
-53 c-7 -7 -15 -6 -25 3 -22 17 -9 32 16 19 14 -8 17 -14 9 -22z m-3791 -147
c0 -29 -4 -40 -15 -40 -17 0 -18 4 -9 49 9 44 24 39 24 -9z m-3540 -23 c0 -10
-4 -15 -10 -12 -5 3 -10 18 -10 33 0 21 2 24 10 12 5 -8 10 -23 10 -33z m5050
-7 c17 -41 6 -50 -14 -11 -18 35 -19 41 -7 41 5 0 14 -14 21 -30z m6045 -49
c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-8335 -43 c13 -22 20
-50 19 -83 -1 -93 -14 -92 -32 3 -24 126 -22 137 13 80z m2436 -85 c-5 -36
-15 -56 -43 -85 -33 -34 -38 -36 -45 -21 -19 49 -9 78 40 115 26 20 49 37 51
37 2 1 1 -20 -3 -46z m-4386 7 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m4251 -17 l24 -27 -24 15 c-14 9 -27 21 -29 27 -6
19 3 14 29 -15z m6132 -13 c-8 -32 -29 -43 -60 -33 l-28 10 40 21 c50 26 54
27 48 2z m107 -24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z m-210 -11 c0 -4 -8 -20 -17 -38 -17 -30 -18 -31 -21 -9 -5 32 4
52 23 52 8 0 15 -2 15 -5z m-2406 -49 c23 -42 16 -55 -17 -33 -19 12 -23 22
-19 46 5 37 11 34 36 -13z m7502 30 c3 -8 4 -26 2 -41 l-3 -26 -40 40 -39 41
37 0 c23 0 40 -5 43 -14z m-10936 -52 c0 -6 -9 -14 -19 -17 -20 -5 -31 15 -31
57 0 18 2 18 25 -4 14 -13 25 -29 25 -36z m-2669 -7 c-8 -8 -11 -7 -11 4 0 20
13 34 18 19 3 -7 -1 -17 -7 -23z m2629 -62 c0 -19 -4 -35 -10 -35 -12 0 -24
45 -15 59 13 22 25 10 25 -24z m-2599 -29 c21 -46 25 -139 8 -199 -6 -22 -12
-37 -14 -35 -4 4 -35 246 -35 273 0 26 20 7 41 -39z m-2493 -35 c13 -40 21
-75 18 -79 -4 -3 -18 6 -33 20 -20 21 -27 39 -31 84 -7 80 15 68 46 -25z
m5242 13 c31 -29 45 -52 61 -106 12 -38 19 -71 17 -74 -10 -9 -89 -7 -99 3
-16 16 -38 213 -24 213 3 0 23 -16 45 -36z m2190 -14 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2253 -27 c7 -19 -29 -24 -48
-7 -11 10 -19 20 -19 24 0 12 63 -4 67 -17z m5740 -25 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m-3407 -112 c0 -12 -4 -27 -9 -35 -14 -22 -34
24 -25 62 6 29 7 30 20 12 8 -10 14 -28 14 -39z m-7047 -89 c-3 -5 -7 -6 -10
-3 -9 9 -21 75 -14 81 7 8 30 -67 24 -78z m5003 8 c21 -22 24 -31 18 -58 -15
-59 -6 -96 46 -192 62 -114 64 -147 11 -182 -19 -13 -39 -22 -45 -20 -6 2 -33
47 -60 100 -46 89 -50 103 -54 183 -3 63 -7 82 -14 69 -9 -15 -13 -14 -39 11
-21 20 -29 37 -29 61 0 40 20 51 93 52 38 1 53 -4 73 -24z m5434 -121 c0 -22
-7 -49 -15 -60 -13 -17 -14 -10 -14 71 1 84 1 88 14 60 8 -16 14 -48 15 -71z
m200 -23 c0 -11 -4 -23 -10 -26 -6 -4 -10 5 -10 19 0 14 5 26 10 26 6 0 10 -9
10 -19z m-3544 -75 c-3 -11 -6 -29 -6 -38 0 -10 -7 -18 -15 -18 -16 0 -18 12
-9 58 4 21 11 29 21 25 9 -4 13 -13 9 -27z m3310 -114 c-11 -10 -13 3 -7 43
l6 40 3 -39 c2 -21 1 -41 -2 -44z m-8738 -60 c2 -7 -3 -12 -12 -12 -9 0 -16 7
-16 16 0 17 22 14 28 -4z m9002 -97 c0 -8 -5 -17 -11 -21 -16 -9 -30 18 -17
33 12 15 28 8 28 -12z m-10861 -102 c76 -152 83 -173 65 -180 -21 -8 -84 38
-90 67 -4 14 -24 55 -46 92 -46 78 -47 118 -3 118 21 0 32 -14 74 -97z m10581
29 c0 -15 -5 -34 -10 -42 -7 -11 -10 -2 -10 33 0 30 4 46 10 42 6 -3 10 -18
10 -33z m-8694 10 c11 -7 34 -45 34 -56 0 -3 -9 -6 -20 -6 -22 0 -70 41 -70
60 0 11 39 13 56 2z m5438 -83 c-6 -11 -24 -2 -24 11 0 5 7 7 15 4 8 -4 12
-10 9 -15z m-1740 -21 c8 -18 17 -58 21 -88 7 -49 6 -53 -9 -41 -19 16 -36 73
-36 124 0 46 7 47 24 5z m-264 -50 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6
0 10 -10 10 -22z m20 -79 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9
6 -3 10 -10 10 -16z m318 -148 c23 -58 40 -106 38 -108 -7 -7 -75 58 -81 77
-4 13 -17 20 -45 22 l-39 3 36 63 c20 35 39 60 43 55 4 -4 26 -55 48 -112z
m5182 -16 c8 -24 8 -45 0 -74 -13 -50 -30 -62 -30 -23 0 16 -3 58 -7 93 -6 58
-5 63 10 51 9 -8 21 -29 27 -47z m-10210 -91 c0 -20 -10 -52 -22 -72 -24 -39
-26 -77 -13 -233 6 -79 5 -105 -10 -148 -9 -29 -21 -49 -25 -44 -5 4 -16 44
-26 88 -28 129 -21 400 13 509 5 18 10 15 44 -22 31 -32 39 -49 39 -78z m4782
-12 c-33 -5 -52 20 -52 66 l0 34 39 -48 39 -49 -26 -3z m22618 41 c0 -5 -6
-30 -14 -58 -11 -44 -14 -47 -20 -27 -11 33 2 92 19 92 8 0 15 -3 15 -7z
m-2465 -33 c4 -6 -2 -18 -13 -28 -11 -9 -45 -52 -76 -95 -53 -73 -104 -117
-135 -117 -22 0 -7 63 27 112 44 63 94 108 138 123 48 17 51 18 59 5z m205
-45 c0 -10 -74 -105 -82 -105 -17 0 5 56 33 83 27 25 49 35 49 22z m-20435
-93 c12 -46 18 -85 13 -88 -9 -6 -12 0 -33 83 -8 34 -18 70 -22 79 -4 11 -2
15 7 12 7 -2 23 -41 35 -86z m5262 26 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m-4879 8 c15 -8 35 -23 45 -34 18 -19 18 -21 -1 -58 l-19 -38
-24 37 c-30 46 -55 54 -114 33 -44 -14 -47 -14 -65 4 -34 34 -28 39 75 63 40
9 77 7 103 -7z m-7078 -102 c26 -56 26 -67 -2 -59 -43 13 -68 46 -68 87 0 36
1 37 25 26 13 -6 33 -30 45 -54z m2123 27 c7 -7 5 -17 -8 -31 -21 -23 -71 -26
-98 -5 -18 13 -18 14 3 35 18 18 30 21 58 16 19 -3 40 -10 45 -15z m24906 -23
c-16 -29 -39 -47 -62 -48 -24 0 -10 25 29 51 38 25 47 24 33 -3z m2303 -35
c24 -22 23 -39 -11 -168 -17 -60 -29 -111 -27 -112 1 -1 18 11 37 27 19 16 39
29 45 30 6 0 18 -16 27 -35 17 -35 16 -39 -14 -145 l-32 -110 -60 32 c-33 18
-76 51 -95 75 -26 31 -44 43 -63 43 -15 0 -39 4 -53 10 -24 9 -26 14 -26 69 0
65 0 65 74 76 25 4 46 10 46 14 0 3 -27 21 -60 39 -65 36 -67 44 -17 90 26 24
159 81 191 82 11 0 28 -7 38 -17z m-29198 -139 c-5 -29 6 -53 90 -196 79 -133
93 -164 79 -167 -17 -3 -31 10 -175 172 -82 92 -82 93 -93 225 l-5 72 55 -36
c52 -35 55 -39 49 -70z m6734 84 c15 -15 15 -43 1 -70 -15 -28 -29 -7 -29 45
0 38 8 45 28 25z m22702 -23 c0 -3 -13 -26 -29 -51 -27 -44 -29 -46 -40 -26
-17 32 -14 41 22 62 32 20 47 25 47 15z m-2604 -122 c-2 -2 -27 2 -57 8 l-53
12 29 25 29 25 28 -34 c15 -18 26 -34 24 -36z m-24330 41 c-3 -9 -6 -20 -6
-25 0 -6 -4 -8 -9 -5 -12 8 -2 46 11 46 6 0 7 -7 4 -16z m26291 -90 c-33 -66
-57 -88 -57 -51 0 57 62 159 84 137 7 -7 0 -32 -27 -86z m-27057 6 c0 -19 -17
-18 -48 2 -26 16 -30 23 -21 34 9 12 16 10 40 -7 16 -11 29 -24 29 -29z m4928
-12 c6 -16 8 -28 3 -28 -9 0 -31 44 -31 62 0 16 13 -1 28 -34z m5129 -75 c-2
-21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z m17083 73 c0 -24 -16 -43
-27 -32 -8 8 6 56 17 56 6 0 10 -11 10 -24z m-27026 -247 c-3 -5 -25 8 -49 28
-36 30 -42 39 -32 51 10 12 18 8 49 -28 21 -23 35 -46 32 -51z m41 81 c-3 -5
-11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m5017 -145
c153 -272 270 -496 264 -510 -10 -27 -35 -28 -91 -5 -70 28 -110 74 -130 148
-9 31 -27 82 -41 112 -29 64 -114 361 -107 373 14 23 43 -10 105 -118z m-159
23 c4 -12 4 -24 1 -27 -6 -7 -24 20 -24 37 0 21 17 13 23 -10z m-4659 -28 c55
-23 64 -53 15 -48 -22 2 -40 13 -57 36 -29 37 -21 39 42 12z m496 -10 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m14708
-23 c2 -15 -1 -27 -7 -27 -6 0 -11 3 -11 8 0 4 -3 18 -7 31 -4 18 -2 23 8 19
8 -2 15 -17 17 -31z m-15189 -85 c-22 -4 -59 24 -59 44 0 26 26 21 52 -10 26
-30 26 -31 7 -34z m26946 18 c26 -28 31 -114 12 -181 -25 -85 -33 -92 -100
-84 l-56 7 -9 42 c-11 54 -2 65 52 59 41 -5 44 -4 55 27 17 51 14 66 -26 97
-43 35 -38 53 16 53 25 0 44 -7 56 -20z m-21320 -28 c-6 -7 -35 18 -35 31 0 5
9 2 20 -8 11 -10 18 -20 15 -23z m-932 -2 c6 -24 -8 -36 -17 -14 -8 22 -7 34
3 34 5 0 11 -9 14 -20z m-4102 -63 c-7 -7 -17 -5 -31 8 -16 14 -18 21 -8 33
10 12 15 11 30 -9 13 -15 16 -25 9 -32z m25916 -44 l-42 -88 -3 39 c-2 21 2
52 9 68 11 28 53 68 70 68 4 0 -11 -39 -34 -87z m-28517 32 c-7 -9 -15 -13
-17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m2378 -53 c80 -55 202 -170 202
-193 0 -12 -10 -12 -90 7 -68 15 -69 16 -86 60 -21 55 -27 59 -58 39 -24 -16
-27 -15 -80 27 -30 23 -55 47 -55 52 -2 19 51 77 65 72 7 -3 53 -32 102 -64z
m4414 -77 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8
11 -13z m21453 -64 c-8 -33 -44 -91 -56 -91 -20 0 -9 69 17 99 29 35 49 31 39
-8z m459 -3 c5 -24 3 -28 -14 -28 -15 0 -20 7 -20 28 0 52 22 52 34 0z
m-25778 -24 c23 -6 35 -34 14 -34 -20 0 -79 34 -74 42 4 5 13 7 21 4 8 -4 26
-9 39 -12z m5138 -46 c15 -20 13 -38 -3 -38 -7 0 -31 43 -31 55 0 13 21 2 34
-17z m-5351 -24 c39 -26 48 -50 20 -56 -14 -2 -8 -9 20 -27 39 -24 93 -31 139
-17 24 7 27 5 33 -26 3 -18 9 -41 11 -50 5 -15 -1 -18 -36 -18 -72 0 -221 68
-330 150 -24 18 -24 18 -5 40 40 45 82 46 148 4z m25701 -31 c-17 -33 -24 -38
-58 -41 -22 -2 -50 -1 -64 3 -21 5 -23 8 -11 20 8 7 40 20 71 29 31 9 60 21
63 27 4 6 9 8 12 5 3 -3 -3 -22 -13 -43z m-20515 -18 c58 -48 76 -75 48 -75
-14 0 -127 110 -127 124 0 13 16 3 79 -49z m8795 -65 l7 -45 -26 28 c-25 29
-32 67 -18 117 6 23 8 22 19 -15 6 -23 15 -61 18 -85z m-9649 18 c-10 -74 -15
-82 -44 -62 -23 17 -51 70 -51 99 0 11 12 15 51 15 l51 0 -7 -52z m-4205 33
c0 -17 -15 -19 -35 -5 -18 13 -18 14 8 14 15 0 27 -4 27 -9z m25849 -48 c-18
-58 -61 -139 -123 -233 l-56 -85 0 51 c0 35 8 66 25 100 14 27 30 78 37 114 6
36 16 70 22 77 14 14 100 32 104 21 2 -4 -2 -25 -9 -45z m-463 -10 c-19 -27
-26 -32 -26 -18 0 18 39 69 48 61 2 -3 -8 -22 -22 -43z m-21291 -63 c8 -16 15
-35 15 -43 0 -8 32 -67 71 -132 76 -124 112 -211 107 -253 -2 -21 -23 6 -105
143 -110 183 -177 320 -169 342 8 20 61 -18 81 -57z m288 -38 l59 -92 -28 -47
c-15 -27 -28 -50 -30 -52 -2 -2 -24 13 -50 34 -32 26 -56 59 -76 102 l-30 63
38 45 c20 25 42 44 48 42 5 -2 37 -45 69 -95z m20986 34 c9 -11 6 -18 -15 -35
-29 -23 -46 -20 -37 7 15 41 33 51 52 28z m-18531 -101 c-3 -19 -4 -19 -17 4
-8 15 -10 35 -5 60 l7 36 9 -40 c5 -22 7 -49 6 -60z m-1788 65 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m8824 -51 c-10
-16 -24 -9 -24 12 0 18 2 19 15 9 8 -7 12 -16 9 -21z m-7028 -96 c-11 -11 -19
6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m104 -18 c0 -19 -4 -35 -9 -35
-10 0 -22 51 -14 62 11 19 23 5 23 -27z m-9140 10 c9 -10 11 -33 7 -69 -5 -47
-1 -63 25 -118 32 -68 73 -166 65 -158 -29 30 -143 183 -157 210 -23 46 -43
118 -36 136 7 19 80 18 96 -1z m27424 -21 c10 -25 7 -34 -9 -34 -8 0 -15 4
-15 9 0 5 -3 16 -6 25 -4 10 -1 16 9 16 8 0 18 -7 21 -16z m-10989 -86 c4 -23
3 -38 -3 -38 -9 0 -22 45 -22 78 0 28 18 -1 25 -40z m-7561 -16 c17 -62 20
-124 6 -157 -9 -22 -7 -26 -34 66 -17 58 -20 106 -10 133 11 28 21 17 38 -42z
m7324 1 c9 -29 9 -39 -1 -45 -8 -5 -18 5 -29 30 -22 48 -22 52 0 52 13 0 22
-12 30 -37z m-16031 -69 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z
m8973 1 c0 -2 -16 -79 -35 -171 -19 -91 -35 -167 -35 -169 0 -4 -61 15 -68 21
-2 1 8 22 22 47 14 24 26 52 26 62 0 20 -37 65 -54 65 -6 0 -17 4 -25 9 -32
20 96 139 152 141 9 0 17 -2 17 -5z m18535 -5 c-3 -5 -11 -10 -16 -10 -6 0 -7
5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-18399 -152 c8 -15 14 -39 14 -53 0
-27 -60 -175 -70 -175 -12 0 -40 107 -40 149 0 23 10 65 21 92 21 48 22 49 41
32 11 -10 26 -30 34 -45z m6959 -47 c-13 -14 -17 -14 -31 1 -13 13 -14 20 -4
39 l13 23 19 -24 c17 -21 17 -25 3 -39z m-7265 -16 l25 -13 -25 -6 c-84 -18
-133 -26 -137 -22 -2 2 10 15 27 30 35 29 72 33 110 11z m18434 -10 c-4 -8
-10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m-18551 -115 c34 -19 68
-30 93 -30 21 0 45 -4 52 -8 15 -10 62 -73 62 -84 0 -4 -39 -8 -86 -8 l-86 0
-64 56 c-45 40 -64 64 -64 80 0 33 28 31 93 -6z m18371 -11 c-3 -5 -10 -7 -15
-3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m142 -4 c3 -14 3 -36 0
-50 l-7 -26 40 21 c46 23 80 25 105 7 16 -12 9 -21 -80 -105 -55 -53 -125
-108 -163 -129 -36 -20 -115 -72 -174 -116 -126 -93 -265 -158 -280 -131 -11
19 7 66 35 91 29 26 50 71 43 92 -5 16 5 22 64 40 80 24 115 58 106 101 -9 42
58 89 70 49 4 -10 13 -19 20 -19 17 0 45 38 45 61 0 16 143 139 161 139 5 0
11 -11 15 -25z m-17126 -4 c8 -16 5 -25 -13 -47 -33 -38 -61 -27 -52 18 4 18
9 36 12 41 8 14 42 7 53 -12z m-1002 -69 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16
16 0 17 22 14 28 -4z m17905 -2 c44 -17 46 -23 12 -38 -36 -17 -40 -15 -53 13
-14 30 -15 48 -4 43 4 -2 24 -10 45 -18z m-26821 -31 c28 -15 6 -29 -47 -29
l-40 1 24 19 c27 23 35 24 63 9z m9828 -65 c-8 -22 -21 -39 -37 -45 -35 -13
-63 -11 -63 5 0 20 39 66 74 87 26 16 31 17 34 4 2 -8 -2 -31 -8 -51z m-3180
36 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z
m19800 -83 c-12 -61 -27 -87 -137 -240 -85 -119 -122 -157 -154 -157 -44 0
-17 51 164 310 133 191 131 189 135 162 2 -12 -2 -46 -8 -75z m-16667 -66 c3
-11 2 -22 -1 -25 -9 -10 -31 15 -25 30 7 20 20 17 26 -5z m-9658 -36 c29 -28
32 -50 9 -59 -32 -12 -42 -5 -57 39 -15 43 -15 45 4 45 11 0 31 -11 44 -25z
m9505 -125 c7 -13 7 -20 1 -20 -6 0 -15 9 -21 20 -7 13 -7 20 -1 20 6 0 15 -9
21 -20z m370 -15 c-7 -14 -17 -25 -22 -25 -15 0 -8 39 9 49 25 13 29 5 13 -24z
m-9770 10 c7 -9 10 -18 7 -21 -5 -6 -67 23 -67 31 0 12 49 3 60 -10z m9210
-13 c0 -18 -18 -42 -31 -42 -4 0 -2 14 4 30 13 33 27 40 27 12z m17480 -33
c-22 -59 -25 -63 -39 -55 -6 4 -5 18 3 39 7 17 16 40 19 50 4 9 13 17 22 17
13 0 12 -7 -5 -51z m-26638 -16 c19 -17 24 -58 8 -68 -13 -8 -50 31 -50 53 0
34 15 40 42 15z m9084 -18 c-21 -16 -32 -13 -21 4 3 6 14 11 23 11 15 -1 15
-2 -2 -15z m-8970 -131 c-6 -16 -8 -16 -26 1 -16 14 -17 21 -8 32 10 12 13 12
26 -1 8 -8 12 -22 8 -32z m25723 7 c-15 -43 -43 -63 -96 -68 l-48 -5 40 42
c40 42 66 58 97 59 14 1 15 -3 7 -28z m-16511 -78 c21 -5 14 -11 -49 -44 -41
-22 -75 -39 -76 -39 -2 0 -3 11 -3 24 0 48 64 77 128 59z m6800 -115 c5 -7 13
-23 17 -36 l7 -23 -43 22 c-40 20 -42 22 -24 35 23 17 30 17 43 2z m-13548
-428 c0 -4 -7 -10 -15 -14 -11 -4 -15 2 -15 21 0 21 2 24 15 13 8 -7 15 -16
15 -20z m5 -97 c37 -67 37 -67 25 -125 -7 -32 -16 -58 -19 -58 -3 0 -26 50
-49 111 -41 105 -42 112 -26 130 9 10 20 16 24 14 5 -3 25 -35 45 -72z m23595
-61 c0 -12 -60 -82 -71 -82 -18 0 -8 25 23 57 31 32 48 41 48 25z m-15615 -11
c14 -6 2 -10 -44 -15 -68 -8 -89 -2 -50 13 29 12 67 12 94 2z m370 -1 c4 -6
-5 -10 -19 -10 -14 0 -26 5 -26 10 0 6 9 10 19 10 11 0 23 -4 26 -10z m595
-80 c11 -7 4 -10 -27 -10 -24 0 -43 5 -43 10 0 13 50 13 70 0z m-900 -15 c24
-13 21 -14 -40 -14 -59 0 -63 1 -46 14 25 19 54 19 86 0z m13230 -2 c0 -14
-19 -38 -25 -32 -8 8 5 39 16 39 5 0 9 -3 9 -7z m180 -27 c0 -22 -11 -46 -21
-46 -5 0 -9 14 -9 30 0 20 5 30 15 30 8 0 15 -6 15 -14z m-13910 -37 l33 -12
-24 -13 c-27 -14 -34 -11 -49 17 -12 22 -6 23 40 8z m1775 -20 c16 -6 13 -8
-16 -8 -19 -1 -44 6 -55 14 -18 15 -17 16 16 9 19 -4 44 -10 55 -15z m-1437
-41 c-3 -7 -16 -20 -30 -28 -22 -12 -30 -12 -55 -1 l-28 14 50 12 c67 17 67
17 63 3z m1499 -49 c26 -10 27 -12 8 -19 -45 -19 -105 -6 -105 22 0 12 64 10
97 -3z m-10797 -15 c0 -11 -6 -14 -22 -9 -39 12 -44 25 -10 25 22 0 32 -5 32
-16z m10683 -65 c50 -33 116 -74 148 -90 70 -35 77 -44 41 -57 -33 -11 -67 0
-103 34 l-25 24 -34 -25 c-41 -31 -44 -31 -37 -3 5 18 -5 33 -50 74 -48 45
-55 55 -49 78 3 14 9 26 12 26 3 0 47 -27 97 -61z m-1693 26 c0 -40 -30 -61
-97 -69 -65 -7 -86 -22 -97 -69 -7 -28 -8 -28 -61 -22 l-55 7 0 -51 c0 -28 -2
-51 -5 -51 -6 0 -87 40 -94 46 -13 12 100 98 189 144 64 34 193 89 208 89 6 1
12 -10 12 -24z m3960 -47 c41 -24 47 -40 16 -46 -20 -4 -66 34 -66 55 0 18 5
17 50 -9z m-4030 -43 c-7 -8 -20 -15 -29 -15 -13 1 -13 3 3 15 26 19 42 19 26
0z m3918 3 c-5 -15 -42 -22 -64 -14 -25 10 -5 26 32 26 24 0 34 -4 32 -12z
m-2339 -118 c11 -5 38 -10 60 -10 42 0 61 -18 61 -58 0 -17 -5 -18 -51 -11
-133 21 -169 60 -142 158 6 22 8 21 30 -22 12 -26 32 -51 42 -57z m-2013 45
c-38 -50 -70 -69 -103 -60 l-26 6 35 35 c30 31 106 69 117 58 2 -2 -8 -19 -23
-39z m-7749 -4 c24 -22 66 -48 91 -57 65 -24 74 -33 67 -63 -4 -14 -8 -28 -10
-30 -2 -2 -24 6 -48 18 -36 18 -49 19 -75 10 -25 -9 -42 -7 -84 6 -29 10 -57
21 -61 25 -7 6 61 130 71 130 2 0 24 -18 49 -39z m8227 14 c-6 -16 -24 -21
-24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m13422 -81 c18 -5 20 -11 14 -54 -7
-53 -32 -90 -62 -90 -16 0 -18 9 -18 75 0 81 4 86 66 69z m-13603 -41 c6 -5
-84 -73 -98 -73 -3 0 -5 18 -5 40 l0 40 48 0 c27 0 52 -3 55 -7z m2132 -3 c8
-12 -41 -32 -69 -28 -19 3 -18 5 9 20 35 20 51 22 60 8z m-9111 -110 c43 -48
44 -56 10 -64 -46 -12 -86 40 -68 88 9 24 18 20 58 -24z m-994 14 c0 -11 -8
-14 -27 -12 -39 4 -42 28 -4 28 21 0 31 -5 31 -16z m7790 -60 l0 -75 -46 37
-46 38 43 38 c24 20 45 38 47 38 1 0 2 -34 2 -76z m13860 10 c7 -30 9 -58 6
-61 -10 -10 -46 29 -46 48 0 20 17 69 24 69 3 0 10 -25 16 -56z m-21329 26
c21 -12 25 -52 8 -96 l-10 -28 -35 62 c-18 34 -34 65 -34 67 0 9 51 5 71 -5z
m269 -56 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
-16z m-1070 -23 c0 -5 -15 -12 -32 -16 -18 -4 -41 -9 -50 -11 -10 -3 -18 -1
-18 5 0 6 15 15 33 20 43 12 67 13 67 2z m8433 -47 c8 -9 -21 -64 -33 -64 -12
0 -12 0 1 39 11 30 19 37 32 25z m-360 -140 c9 -9 -29 -66 -69 -104 l-37 -35
23 40 c64 111 67 115 83 99z m14210 -34 c-3 -11 -10 -20 -15 -20 -6 0 -7 7 -4
16 3 9 6 20 6 25 0 6 4 8 9 5 5 -3 7 -15 4 -26z m-14343 -114 c0 -5 -86 -56
-94 -56 -3 0 0 14 5 30 10 26 16 30 50 30 21 0 39 -2 39 -4z m-5740 -284 c0
-13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m12360 -32 c0 -4 -7
-10 -15 -14 -10 -4 -15 1 -15 14 0 13 5 18 15 14 8 -4 15 -10 15 -14z m-11488
0 c40 -11 83 -56 105 -108 l14 -34 20 26 c28 35 40 33 78 -14 44 -55 109 -97
204 -132 45 -16 125 -52 177 -81 230 -124 299 -158 365 -178 97 -29 141 -57
216 -136 94 -100 242 -284 234 -291 -15 -15 -345 -4 -505 17 -214 28 -329 54
-651 150 -335 100 -467 129 -630 141 -71 5 -146 14 -167 19 -56 16 -251 31
-391 31 l-125 0 -115 -54 c-137 -64 -193 -80 -264 -74 -61 5 -131 36 -135 59
-2 9 20 51 49 93 28 43 77 120 108 172 47 78 65 99 111 129 136 86 223 115
353 115 44 0 86 11 180 46 224 85 229 87 302 86 41 0 113 -11 175 -26 126 -31
134 -32 125 -8 -13 33 -10 37 45 48 72 16 78 16 122 4z m9891 -226 c31 -6 37
-34 8 -34 -44 0 -121 24 -121 38 0 14 23 13 113 -4z m-358 -55 c4 -6 -3 -17
-14 -26 -12 -8 -32 -36 -44 -61 -22 -46 -23 -47 -110 -71 -87 -24 -87 -25 -87
-57 0 -36 -5 -39 -80 -49 -41 -5 -49 -2 -84 28 l-40 34 139 79 c77 43 176 91
220 106 92 32 92 32 100 17z m405 -50 c0 -18 -35 -5 -38 14 -3 16 -1 17 17 7
12 -6 21 -15 21 -21z m8152 -18 c82 -21 244 -98 350 -167 95 -61 116 -111 83
-192 -23 -54 -53 -82 -89 -82 -31 0 -46 8 -130 68 -104 75 -229 121 -456 168
-113 24 -159 28 -280 28 -171 0 -260 -16 -458 -78 -75 -25 -199 -63 -275 -85
-182 -55 -301 -106 -615 -268 -286 -146 -318 -157 -353 -113 -29 38 -133 366
-134 424 0 65 17 77 179 121 126 34 132 35 256 29 154 -8 233 2 480 62 l185
45 450 24 c248 13 479 26 515 28 166 9 219 6 292 -12z m-8395 -16 c-22 -19
-39 -25 -77 -25 -45 0 -71 13 -56 27 5 5 90 17 146 21 9 1 4 -8 -13 -23z m336
17 c5 -7 -37 -62 -48 -62 -3 0 -5 11 -5 25 0 29 41 58 53 37z m-5914 -120 c-4
-2 -17 2 -28 8 -20 10 -20 12 -4 37 l17 26 12 -34 c6 -19 8 -35 3 -37z m114
26 c-9 -36 -23 -48 -54 -48 -38 0 -36 9 9 42 45 33 52 34 45 6z m5321 -14 c7
-18 0 -34 -15 -34 -5 0 -9 11 -9 25 0 28 15 34 24 9z m-486 -26 c7 -7 12 -29
12 -51 0 -33 -8 -47 -57 -99 l-58 -60 34 7 c33 7 34 7 27 -21 -4 -16 -19 -41
-33 -56 -33 -35 -63 -23 -63 25 l-1 32 -19 -23 c-10 -13 -22 -44 -25 -70 -4
-26 -9 -60 -12 -76 l-5 -30 -44 46 c-26 28 -44 56 -44 70 0 36 43 103 68 106
12 2 22 10 22 18 0 10 -5 12 -12 8 -15 -9 -4 7 66 91 78 94 113 114 144 83z
m1822 -107 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m-2860
-202 c21 -100 28 -309 11 -309 -5 0 -19 24 -32 53 -21 48 -24 68 -26 242 -2
179 -2 188 12 150 9 -22 24 -83 35 -136z m1098 125 c-12 -11 -47 16 -41 32 5
14 9 13 26 -5 12 -11 18 -23 15 -27z m1827 0 c14 -6 14 -8 -1 -25 -29 -32 -70
-16 -44 16 14 17 21 18 45 9z m-335 -34 c0 -5 -7 -7 -15 -4 -8 4 -12 10 -9 15
6 11 24 2 24 -11z m-5836 -12 c5 -9 7 -20 4 -23 -9 -9 -45 12 -52 30 -8 23 33
16 48 -7z m-845 -89 c-15 -9 -32 -2 -24 11 3 5 13 6 21 3 12 -4 13 -8 3 -14z
m3001 -65 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m990 -19
c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z
m-3350 -62 c0 -9 -7 -25 -17 -35 -15 -17 -15 -20 7 -43 l23 -25 -24 -26 c-13
-14 -52 -45 -87 -67 -44 -29 -65 -50 -69 -68 -5 -27 -20 -39 -74 -62 l-34 -14
0 36 c0 51 -25 52 -54 1 -12 -22 -28 -40 -34 -40 -7 0 -45 10 -86 23 l-74 22
161 78 c125 60 167 85 188 113 34 45 76 66 110 58 24 -6 26 -4 19 15 -4 15 -1
29 10 41 19 21 35 18 35 -7z m14080 -43 c0 -27 -3 -50 -7 -50 -4 0 -27 7 -50
16 -57 22 -57 47 0 67 23 9 45 16 50 16 4 1 7 -21 7 -49z m80 -10 c0 -18 -26
-58 -32 -50 -3 3 -2 27 0 55 4 45 6 47 18 30 8 -10 14 -26 14 -35z m-2259 -42
c12 -23 -2 -52 -37 -75 -24 -15 -26 -15 -45 3 -19 19 -19 20 13 66 34 48 46
50 69 6z m-14968 -95 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m17126 -73 c15 -71 13 -101 -8 -127 -11 -13 -22 -23 -25 -23 -3 0 -6 45 -6
100 0 80 3 100 14 100 9 0 18 -19 25 -50z m-16949 -40 c0 -5 -7 -10 -16 -10
-8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m4230 -85 c0 -14 -4 -25
-10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m-28 -121 c3
-3 0 -29 -8 -57 -15 -57 -51 -97 -88 -97 -23 0 -23 2 -14 43 30 125 25 117 67
117 21 0 40 -3 43 -6z m5648 -154 c-1 -14 -7 -34 -15 -44 -14 -19 -15 -18 -15
15 0 50 11 84 21 67 5 -7 9 -24 9 -38z m-5783 -5 c30 -21 46 -75 34 -109 -6
-14 -13 -26 -16 -26 -2 0 -10 20 -16 45 -6 25 -15 48 -20 51 -5 3 -25 -5 -45
-17 -43 -26 -61 -12 -38 32 21 41 63 51 101 24z m-82 -145 c4 -6 -9 -10 -32
-10 -28 0 -34 3 -23 10 20 13 47 13 55 0z m114 -36 c16 -35 7 -57 -35 -89
l-34 -26 -26 20 c-15 12 -33 21 -40 21 -19 0 -54 -59 -54 -89 0 -13 -9 -39
-21 -57 l-21 -34 36 0 c38 0 45 10 21 30 -22 18 -19 28 15 45 33 17 78 19 100
5 13 -8 13 -12 -1 -37 -9 -15 -27 -59 -39 -98 -33 -101 -55 -140 -99 -175 -37
-30 -39 -35 -43 -95 -4 -71 -29 -109 -77 -120 -17 -4 -36 -22 -55 -51 -37 -58
-127 -166 -133 -160 -3 3 18 49 46 102 28 53 50 98 49 98 -56 20 -61 71 -17
169 18 40 35 75 39 80 3 4 45 12 93 18 94 13 93 12 49 68 -18 22 -20 23 -50 7
-70 -36 -69 -17 9 168 l62 146 71 39 c88 49 137 54 155 15z m931 -24 c0 -5
-16 -10 -36 -10 -21 0 -33 4 -29 10 3 6 19 10 36 10 16 0 29 -4 29 -10z m-180
-30 c-6 -11 -21 -20 -35 -20 -37 0 -39 18 -3 29 48 13 50 13 38 -9z m12478
-10 c27 -19 45 -41 50 -62 5 -18 8 -34 6 -36 -1 -2 -23 13 -49 32 -55 43 -81
51 -118 37 -32 -12 -36 1 -11 37 22 32 71 29 122 -8z m-7183 -31 c-9 -14 -97
-59 -106 -53 -16 9 -10 23 27 64 l37 41 23 -22 c13 -12 21 -25 19 -30z
m-12730 0 c34 -10 37 -13 20 -20 -48 -19 -145 -3 -130 22 8 12 61 11 110 -2z
m277 -44 c46 -8 95 -24 125 -41 l48 -29 -50 -11 c-73 -17 -81 -17 -93 11 -10
22 -15 24 -31 15 -30 -16 -121 -11 -171 9 -36 15 -46 24 -48 46 l-4 28 74 -7
c40 -4 107 -13 150 -21z m-247 -55 c-23 -25 -46 -25 -76 0 l-24 19 59 1 c58 0
59 0 41 -20z m535 -7 c19 -25 20 -28 5 -31 -21 -5 -45 16 -45 39 0 27 15 24
40 -8z m12222 -130 c33 -32 36 -53 8 -53 -12 0 -24 -9 -29 -22 l-8 -21 -21 21
c-26 26 -28 49 -6 80 19 28 22 28 56 -5z m-5562 -8 c0 -25 -34 -49 -53 -38 -7
4 40 63 50 63 2 0 3 -11 3 -25z m-3530 -84 c0 -35 -24 -60 -34 -35 -9 24 -7
49 6 62 16 16 28 5 28 -27z m9140 -28 c5 -4 -20 -23 -32 -23 -4 0 -12 9 -18
20 -10 19 -9 20 17 14 15 -4 30 -9 33 -11z m-5671 -39 c-15 -18 -22 -13 -13
10 3 9 10 13 16 10 5 -3 4 -12 -3 -20z m-583 -346 c-245 -651 -315 -851 -381
-1088 -74 -261 -85 -288 -190 -445 -144 -218 -328 -419 -467 -512 -181 -120
-412 -215 -667 -274 -139 -32 -179 -35 -204 -16 -55 42 -224 254 -291 366 -41
69 -85 141 -98 160 l-22 34 30 -7 c35 -9 70 -35 152 -114 42 -41 73 -63 96
-68 73 -13 73 -12 -49 211 -63 116 -115 213 -115 216 0 4 11 10 25 13 14 4 96
40 183 80 178 84 306 136 507 207 235 84 357 158 538 327 50 48 171 153 268
234 229 191 300 279 658 811 85 125 155 226 157 224 2 -1 -57 -163 -130 -359z
m11554 338 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-11010
-45 c0 -11 -28 -41 -39 -41 -18 0 -20 15 -4 32 17 19 43 24 43 9z m5792 -33
c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9 -9 9 -15 0 -24z m-9452 -4 c0 -14
-12 -19 -23 -8 -9 9 4 35 14 28 5 -3 9 -12 9 -20z m-406 -19 c-4 -8 -10 -12
-15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m271 -17 c-4 -18 -12 -44 -17 -57
l-9 -25 -27 28 -27 27 35 30 c44 37 53 37 45 -3z m-156 -277 l110 -218 -20
-32 c-31 -52 -25 -76 36 -138 l56 -55 -26 -34 c-47 -61 -91 -76 -260 -85 -218
-11 -337 -33 -629 -113 -89 -24 -206 -54 -261 -66 -55 -12 -104 -25 -109 -28
-36 -21 174 -30 265 -11 30 6 122 11 205 10 131 0 156 2 190 19 50 26 62 25
76 -5 l11 -24 46 39 c53 45 52 46 64 -62 l7 -58 -30 0 c-36 0 -90 25 -90 42 0
6 -2 9 -5 6 -3 -2 4 -31 15 -63 11 -32 20 -59 20 -60 0 -3 -30 -8 -106 -20
l-42 -6 -17 50 c-15 48 -18 51 -43 45 -42 -10 -54 -24 -47 -54 5 -23 2 -28
-17 -34 -44 -11 -56 -6 -139 61 l-82 66 -39 -41 c-34 -37 -38 -47 -38 -92 0
-27 -1 -50 -2 -50 -2 0 -27 9 -56 20 -55 21 -130 25 -241 14 -62 -7 -62 -7
-56 -35 6 -26 4 -28 -30 -35 -20 -3 -45 -2 -56 2 -13 6 -44 60 -84 144 -62
133 -348 830 -342 836 2 2 56 10 120 19 208 27 540 39 1029 38 l476 -1 7 114
c4 63 11 114 16 114 5 0 58 -98 118 -219z m4644 202 c-13 -2 -35 -2 -50 0 -16
2 -5 4 22 4 28 0 40 -2 28 -4z m5297 -95 c0 -13 -55 -9 -68 4 -13 13 -16 50
-5 62 7 7 73 -52 73 -66z m-5340 -8 c0 -14 -24 -23 -47 -17 -15 3 -14 6 7 15
32 14 40 14 40 2z m10247 -36 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13
4 -17z m-9437 -46 c0 -6 -7 -7 -16 -4 -9 3 -18 6 -20 6 -3 0 -3 4 1 10 7 11
35 2 35 -12z m107 -82 c28 -14 60 -37 72 -51 23 -30 18 -54 -25 -117 l-26 -36
58 -82 57 -83 -14 -56 c-10 -41 -20 -59 -39 -68 -24 -12 -25 -11 -32 33 -8 54
-42 102 -102 143 -37 26 -82 88 -71 99 2 2 25 -9 50 -24 l47 -27 -7 24 c-3 13
-20 36 -36 52 -35 32 -81 36 -152 10 -44 -16 -48 -16 -64 -1 -15 16 -12 22 37
95 77 112 77 112 115 106 28 -4 34 -2 40 17 6 19 9 20 23 7 10 -8 40 -26 69
-41z m-592 -28 c47 -40 85 -76 85 -80 0 -4 -11 -15 -25 -24 -22 -14 -33 -15
-86 -5 -34 7 -63 10 -65 8 -3 -3 14 -20 38 -38 36 -29 50 -34 98 -35 30 0 64
-4 76 -7 27 -9 250 -254 342 -376 66 -87 202 -334 202 -366 0 -7 -37 -44 -82
-81 -385 -316 -506 -441 -587 -603 -89 -178 -123 -337 -146 -691 -11 -157 -22
-210 -120 -581 -52 -195 -95 -360 -95 -365 0 -6 20 -32 45 -58 55 -59 85 -136
85 -216 0 -77 11 -103 55 -130 l36 -22 33 38 c31 36 35 38 60 26 33 -15 33
-39 1 -83 -13 -19 -32 -44 -41 -57 -13 -18 -15 -31 -8 -65 l9 -42 72 1 c155 1
214 89 253 376 31 233 67 398 120 558 37 113 55 189 79 345 46 293 71 378 142
485 56 83 127 142 244 202 55 28 161 84 235 124 74 40 154 80 177 88 l41 14
69 -71 c84 -88 113 -129 188 -275 33 -64 100 -168 155 -240 127 -168 135 -183
185 -325 24 -67 103 -259 175 -427 125 -291 213 -515 295 -753 27 -77 42 -107
51 -103 35 14 124 77 119 85 -3 5 -18 12 -35 16 -46 10 -110 61 -110 89 0 36
-25 101 -80 211 -28 55 -60 134 -71 175 -11 41 -47 139 -80 217 -61 146 -68
177 -46 207 11 15 21 7 92 -81 95 -118 224 -253 241 -253 7 0 21 12 32 27 17
24 22 25 36 14 53 -44 82 -118 59 -151 -14 -20 -16 -20 -39 -6 -43 29 -47 22
-40 -56 4 -40 11 -77 16 -81 12 -13 259 44 287 66 13 10 33 37 44 60 l21 40
-43 -31 c-33 -25 -53 -32 -88 -32 -65 0 -75 9 -66 59 l7 41 71 0 c41 -1 82 -7
96 -14 24 -13 26 -12 64 40 l38 54 -16 49 c-8 26 -20 108 -25 183 -15 214 -56
381 -125 518 -13 25 -31 71 -40 102 -9 31 -22 59 -30 62 -11 4 -15 23 -15 73
0 80 -22 135 -81 205 -46 55 -59 85 -59 138 0 24 -6 44 -16 52 -47 39 -54 50
-54 83 0 42 -19 53 -61 38 -26 -10 -31 -8 -59 23 -36 41 -35 40 -10 81 l20 33
-38 15 c-20 9 -52 30 -71 48 -42 40 -102 128 -95 139 3 4 20 8 37 9 69 1 69 9
1 83 l-64 68 0 -33 c0 -65 -15 -67 -54 -6 -26 41 -45 58 -71 67 -57 19 -57 60
1 185 17 36 24 68 24 114 0 79 40 178 75 187 13 3 90 74 173 157 146 149 227
254 281 368 20 43 128 159 148 159 23 0 94 -97 246 -337 170 -268 207 -330
207 -348 0 -15 60 -108 66 -102 3 2 -4 36 -15 76 -12 45 -16 73 -10 77 13 7 6
15 69 -86 29 -47 60 -95 68 -107 24 -39 -12 -41 -63 -5 l-43 31 -7 -40 c-9
-50 14 -95 143 -283 110 -161 190 -290 302 -486 124 -217 294 -492 373 -604
86 -121 175 -217 327 -350 63 -55 177 -159 253 -230 131 -123 138 -132 133
-161 -12 -59 -76 -176 -125 -228 -55 -58 -66 -78 -174 -317 -139 -307 -186
-440 -333 -940 -61 -208 -90 -290 -106 -307 -17 -17 -54 -29 -148 -48 -152
-31 -289 -72 -435 -132 -60 -25 -132 -52 -159 -60 -57 -17 -96 -52 -96 -87 0
-18 -11 -33 -37 -51 l-38 -25 -13 30 c-6 16 -17 30 -23 30 -14 0 -267 -151
-334 -199 -27 -20 -85 -68 -128 -107 -62 -58 -107 -87 -225 -148 -172 -87
-242 -136 -274 -189 -62 -103 -61 -102 -106 -102 l-42 0 -216 -240 c-123 -137
-222 -256 -231 -277 -9 -23 -51 -71 -106 -123 l-92 -87 -15 23 c-36 55 -350
483 -423 577 -166 213 -254 292 -517 467 -270 179 -511 306 -797 419 -155 61
-269 93 -558 155 -426 91 -636 120 -857 121 -49 0 -88 3 -88 6 0 3 12 21 27
41 l27 35 -24 44 c-14 24 -28 44 -32 44 -5 0 -16 15 -25 34 -16 34 -15 35 16
82 34 52 36 65 20 113 -13 36 -25 40 -35 9 -6 -21 -7 -21 -20 -3 -16 22 -18
83 -4 121 13 34 -4 62 -65 104 -45 31 -65 32 -65 4 0 -24 -33 -12 -46 16 -7
14 -15 42 -18 63 -7 39 -7 39 22 32 29 -6 31 -4 47 47 10 29 20 58 22 64 2 6
-14 19 -37 29 -37 17 -41 17 -58 2 -25 -23 -31 -22 -63 9 l-27 26 -10 -35 c-7
-25 -6 -54 4 -99 18 -86 18 -178 0 -178 -26 0 -37 29 -39 102 -2 69 -4 77 -50
145 -49 74 -69 135 -48 148 20 12 60 -4 101 -41 45 -41 47 -37 24 49 -22 84
-51 139 -72 135 -28 -5 -26 -38 4 -69 31 -32 27 -34 -44 -19 l-48 11 -30 159
c-28 148 -29 160 -13 160 22 0 56 -42 64 -80 10 -45 21 -44 56 5 18 25 34 45
35 45 2 0 26 -72 55 -160 28 -88 53 -160 55 -160 3 0 4 47 2 104 -3 89 0 107
15 124 18 20 18 21 -12 36 -48 25 -56 50 -31 98 21 40 21 41 2 62 -37 39 -62
91 -62 126 0 22 -13 55 -35 90 -37 57 -42 79 -25 110 6 11 123 93 260 184 272
180 359 254 470 398 81 104 245 358 306 472 94 176 149 402 164 664 9 181 25
262 126 681 33 136 64 241 78 265 36 61 138 153 203 184 57 26 61 27 220 24
128 -3 175 0 219 13 30 9 63 13 73 10 16 -5 21 4 33 51 l13 57 31 -26 31 -26
32 44 c18 25 37 45 42 45 5 0 48 -33 94 -72z m9372 23 c171 -26 904 -157 967
-174 l48 -13 -43 -40 c-140 -128 -790 -482 -1136 -618 -177 -70 -239 -86 -329
-86 -99 0 -165 25 -302 118 -356 240 -564 331 -846 368 -135 17 -172 32 -183
72 -9 31 -7 281 2 290 3 4 52 -2 108 -12 202 -35 242 -38 417 -33 186 6 239
15 415 70 129 41 231 64 335 77 111 13 396 3 547 -19z m-7487 -73 c-117 -163
-200 -354 -241 -552 -17 -81 -28 -105 -53 -106 -13 0 4 146 29 246 27 105 94
246 156 324 40 52 154 170 164 170 2 0 -23 -37 -55 -82z m-9053 -113 c32 -63
63 -115 70 -115 17 0 16 7 -7 75 -11 32 -20 61 -20 65 0 4 20 3 45 -3 l45 -10
-6 -53 -7 -52 45 -7 c54 -8 77 -20 63 -34 -8 -8 -151 -71 -160 -71 -2 0 -20
28 -41 63 -71 119 -57 108 -111 92 -64 -20 -61 -20 -68 23 -3 20 -8 47 -10 58
-4 17 6 29 43 53 26 17 50 31 55 31 4 0 33 -52 64 -115z m8013 46 c0 -10 -8
-7 -25 9 -14 13 -25 27 -25 32 0 15 49 -26 50 -41z m-2055 30 c6 -11 -33 -36
-42 -27 -3 3 -3 12 0 21 7 17 33 20 42 6z m-3245 -72 c0 -6 -7 -8 -16 -5 -9 3
-18 6 -20 6 -2 0 -4 7 -4 15 0 13 4 14 20 5 11 -6 20 -16 20 -21z m-2865 -89
c-2 -8 -6 -27 -9 -42 -8 -34 -41 -55 -61 -38 -35 29 -44 131 -14 166 11 13 18
9 50 -28 21 -24 36 -50 34 -58z m2811 33 c-11 -11 -19 6 -11 24 8 17 8 17 12
0 3 -10 2 -21 -1 -24z m3264 -51 c0 -14 -9 -39 -20 -57 -13 -21 -23 -59 -26
-102 -3 -37 -13 -90 -22 -118 l-15 -50 -13 30 c-22 51 -17 161 12 260 14 50
28 94 31 98 9 15 53 -36 53 -61z m3145 48 c3 -5 -12 -44 -34 -87 l-40 -78 -1
48 c0 49 10 77 38 110 17 19 28 22 37 7z m420 -30 c-3 -5 -13 -10 -21 -10 -8
0 -12 5 -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z m2033 -72 c109 -133 249 -253
457 -391 257 -170 484 -255 767 -288 195 -22 196 -22 223 -5 25 17 27 16 61
-13 42 -38 108 -72 194 -101 49 -17 92 -22 175 -24 97 -2 124 -6 225 -39 63
-21 133 -40 155 -43 70 -10 70 -10 18 -72 -27 -31 -109 -135 -182 -230 -73
-95 -169 -216 -213 -270 -101 -122 -141 -181 -208 -307 -54 -101 -158 -275
-165 -275 -2 0 -15 21 -31 47 -43 73 -98 127 -197 192 -51 32 -110 78 -132
101 -22 22 -69 57 -104 77 -40 22 -100 72 -155 127 -136 136 -301 318 -316
346 -47 91 -184 294 -233 346 -55 59 -63 74 -126 241 -82 212 -152 376 -232
536 -33 64 -55 117 -50 117 5 0 36 -33 69 -72z m392 53 c0 -20 -25 -51 -41
-51 -11 0 -11 0 2 39 12 34 39 42 39 12z m2701 9 c25 -14 25 -62 -1 -85 -27
-24 -37 -12 -42 48 -3 50 5 57 43 37z m-12004 -67 c8 -20 -39 -63 -68 -63 -26
0 -24 26 6 55 28 29 53 32 62 8z m-2147 -23 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-125 -111 c4 -38 5 -72 3 -75 -3
-2 -23 9 -46 26 -39 28 -40 31 -31 62 9 33 33 58 55 58 8 0 15 -26 19 -71z
m-325 47 c0 -2 3 -11 6 -20 9 -23 -13 -20 -26 4 -9 16 -8 20 5 20 8 0 15 -2
15 -4z m2660 -62 c0 -13 -4 -24 -9 -24 -13 0 -24 37 -16 50 10 16 25 1 25 -26z
m6250 -33 c0 -18 -10 -41 -26 -58 l-26 -28 6 39 c4 32 29 76 43 76 1 0 3 -13
3 -29z m390 1 c0 -5 -14 -21 -31 -38 l-31 -29 18 38 c16 32 44 51 44 29z
m-1156 -43 c24 -41 11 -51 -19 -14 -28 34 -31 45 -12 45 7 0 21 -14 31 -31z
m-2416 -41 c5 -7 13 -24 17 -37 10 -32 -10 -19 -30 20 -15 29 -7 40 13 17z
m3492 -33 c0 -23 -3 -25 -50 -25 -27 0 -50 4 -50 8 0 5 21 16 48 24 26 9 48
17 50 17 1 1 2 -10 2 -24z m-381 -84 c-11 -28 -27 -56 -35 -62 -12 -9 -14 -4
-14 34 0 36 6 53 33 84 30 37 32 38 35 17 2 -12 -7 -45 -19 -73z m166 63 c-16
-39 -64 -102 -79 -103 -5 -1 -6 16 -3 37 4 25 18 48 42 70 46 42 59 41 40 -4z
m-6884 -9 c1 -17 -18 -17 -25 0 -4 11 -1 15 10 13 8 -2 15 -7 15 -13z m10626
-41 c7 -18 -22 -84 -37 -84 -5 0 -10 17 -10 38 0 52 32 84 47 46z m-12498 -28
c8 -10 9 -16 1 -21 -13 -8 -30 3 -30 21 0 18 14 18 29 0z m5581 -85 l0 -89
-32 -5 c-62 -9 -67 55 -10 132 20 28 38 51 40 51 1 0 2 -40 2 -89z m3260 79
c0 -9 -32 -30 -46 -30 -4 0 1 9 11 20 19 21 35 26 35 10z m-1216 -16 c9 -24 7
-28 -9 -14 -17 14 -20 30 -6 30 5 0 12 -7 15 -16z m-5584 -13 c0 -11 -31 -24
-39 -16 -3 3 1 10 9 15 19 12 30 12 30 1z m6721 -72 c8 -15 5 -24 -15 -44 -14
-14 -29 -25 -34 -25 -8 0 -32 93 -32 121 0 14 70 -32 81 -52z m3689 0 c0 -30
-1 -31 -22 -17 -13 8 -29 18 -36 22 -19 12 -4 26 29 26 26 0 29 -3 29 -31z
m-10430 -19 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m10800 -12 c0 -7 3 -23 6 -35 7 -26 -4 -29 -30 -10 -15 11 -17 17 -7
35 13 24 31 29 31 10z m-4202 -34 c2 -11 -6 -29 -18 -39 -19 -17 -22 -18 -37
-2 -16 15 -15 18 7 42 27 29 44 28 48 -1z m-194 7 c3 -5 -1 -12 -10 -15 -20
-8 -29 -1 -19 13 8 13 22 14 29 2z m1848 -38 c35 -37 78 -110 78 -133 0 -7
-19 5 -42 24 -23 20 -56 40 -73 44 -28 8 -30 11 -26 48 5 67 13 69 63 17z
m-1802 8 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10
-9z m-7485 -30 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m12775
-42 c0 -4 -3 -21 -6 -38 -6 -31 -7 -31 -67 -31 -49 0 -65 4 -84 22 -21 20 -22
23 -7 39 13 15 30 18 90 16 41 -1 74 -5 74 -8z m-8220 -12 c0 -15 -38 -148
-44 -154 -2 -3 -13 21 -22 52 l-18 57 32 29 c35 31 52 37 52 16z m-4465 -6 c7
-12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m715 -1 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1746 -46 c-19
-13 -46 -19 -46 -9 0 8 55 34 60 28 3 -2 -4 -11 -14 -19z m211 -10 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2127 -14 c-20 -13 -40 -13 -40 0
0 6 12 10 28 10 21 0 24 -2 12 -10z m12026 -8 c11 -17 8 -18 -29 -13 -51 7
-63 31 -15 31 21 0 37 -6 44 -18z m-12401 -2 c-3 -5 -11 -10 -16 -10 -6 0 -7
5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m4193 -122 c14 -14 16 -28 2 -28 -5
0 -14 9 -20 20 -12 22 -1 27 18 8z m-46 -141 c6 -13 6 -23 -3 -33 -15 -18 -69
-47 -75 -41 -3 3 0 35 7 71 l11 67 25 -23 c14 -13 29 -31 35 -41z m-2273 -85
c17 -24 71 -137 71 -149 0 -2 -9 1 -21 7 -37 20 -93 77 -113 115 -17 31 -18
38 -5 46 25 16 47 10 68 -19z m1891 -13 c0 -18 -61 -90 -71 -83 -5 3 5 25 21
50 30 42 50 56 50 33z m8823 -40 c-17 -17 -37 -1 -22 17 8 10 15 12 23 4 7 -7
7 -13 -1 -21z m-10825 -22 c7 -8 8 -18 1 -27 -8 -13 -11 -12 -24 4 -22 29 0
51 23 23z m2372 -5 c0 -11 -22 -32 -32 -32 -5 0 -4 9 2 20 11 20 30 28 30 12z
m13306 -33 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z
m-86 -100 c0 -4 -9 -11 -21 -14 -23 -8 -109 13 -109 26 0 5 17 20 38 34 l37
26 28 -33 c15 -18 27 -35 27 -39z m-4800 44 c0 -5 -9 -19 -19 -32 -22 -29 -36
-13 -15 17 15 21 34 30 34 15z m-11173 -35 c58 -33 73 -49 73 -78 0 -36 -29
-44 -70 -19 -30 18 -60 71 -60 104 0 20 12 19 57 -7z m4603 -52 c0 -8 -4 -17
-9 -20 -10 -7 -23 19 -14 28 11 11 23 6 23 -8z m-2450 -13 c0 -14 -19 -38 -25
-32 -8 8 5 39 16 39 5 0 9 -3 9 -7z m13778 -31 c9 -6 6 -14 -13 -32 -28 -26
-31 -26 -40 13 -6 24 -4 27 16 27 13 0 30 -4 37 -8z m-11331 -116 c-2 -20 -63
-63 -74 -52 -6 5 23 121 35 141 8 13 42 -65 39 -89z m11272 -22 c-14 -18 -69
-19 -69 -1 0 20 30 58 54 66 18 7 21 5 24 -21 2 -16 -2 -36 -9 -44z m-13829
31 c0 -9 -80 -58 -86 -52 -10 10 44 57 66 57 11 0 20 -2 20 -5z m-6493 -143
c17 -41 14 -46 -20 -33 -33 13 -57 48 -57 84 l0 28 31 -22 c17 -12 38 -38 46
-57z m8973 -31 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10
-4 10 -9z m-2813 -26 c2 -6 -19 -18 -46 -28 -59 -21 -61 -21 -61 -3 0 15 66
47 89 43 8 -1 16 -7 18 -12z m10475 -146 c79 -50 153 -101 166 -112 26 -24
186 -246 255 -352 95 -149 137 -261 161 -430 9 -60 18 -124 21 -141 5 -30 1
-35 -137 -137 -79 -58 -185 -139 -237 -180 -115 -92 -139 -99 -276 -87 -342
33 -601 152 -817 378 -94 98 -139 165 -173 256 -36 95 -52 248 -38 351 11 73
14 81 55 121 72 71 406 247 578 304 131 43 192 73 225 110 29 32 55 22 217
-81z m-10668 54 c12 -26 14 -39 6 -41 -6 -2 -36 -26 -67 -53 -58 -51 -93 -61
-134 -39 -16 9 -19 7 -19 -15 0 -32 33 -65 65 -65 23 0 145 41 263 89 l52 21
0 -25 c0 -46 -38 -72 -175 -120 -71 -25 -139 -45 -151 -45 -32 0 -77 41 -124
113 l-40 62 -68 0 c-37 0 -69 5 -72 10 -3 8 398 141 436 144 6 1 18 -16 28
-36z m-2004 7 l35 -19 -30 -1 c-30 0 -55 15 -55 32 0 12 11 9 50 -12z m-678
-22 c-6 -6 -15 -8 -19 -4 -4 4 -1 11 7 16 19 12 27 3 12 -12z m748 -66 c140
-82 437 -269 496 -313 71 -52 101 -92 120 -156 48 -164 -76 -437 -290 -641
-130 -123 -196 -146 -585 -201 -253 -37 -283 -37 -326 -11 -90 55 -171 237
-205 460 -22 141 -19 156 93 525 50 164 90 230 194 316 91 75 134 87 298 81
l110 -4 95 -56z m2260 24 c0 -20 -62 -46 -107 -45 l-38 1 60 29 c67 32 85 36
85 15z m-3000 -53 c0 -39 -1 -38 -58 12 l-24 20 29 6 c42 8 53 0 53 -38z
m16550 0 c0 -10 -5 -25 -10 -33 -7 -11 -10 -7 -10 18 0 17 5 32 10 32 6 0 10
-8 10 -17z m-16666 -28 c-1 -11 -7 -46 -14 -77 -12 -52 -11 -60 10 -103 l23
-47 -23 -49 c-13 -27 -27 -49 -30 -49 -21 0 -34 36 -32 86 2 50 0 54 -23 60
-34 8 -55 -1 -55 -26 0 -31 -13 -25 -69 35 -37 39 -51 62 -49 77 3 23 60 68
85 68 15 0 14 -7 -4 -90 l-6 -25 23 20 c12 11 33 41 46 67 28 54 59 80 94 76
18 -2 25 -9 24 -23z m8204 13 c-3 -7 -12 -14 -21 -16 -12 -2 -15 1 -11 12 7
19 38 22 32 4z m-8621 -30 c-45 -176 -94 -328 -106 -328 -11 0 -13 19 -7 110
8 119 2 131 -35 79 -26 -38 -38 -26 -40 42 -4 91 26 118 130 119 61 0 63 -1
58 -22z m6108 -58 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m-5635 -74 c0 -13 -8 -30 -17 -37 -15 -13 -16 -11 -10 11 4 14 7
33 7 43 0 10 5 15 10 12 6 -3 10 -17 10 -29z m5616 -38 c-3 -13 -6 -29 -6 -35
0 -7 -7 -13 -15 -13 -17 0 -20 23 -5 51 15 27 34 24 26 -3z m-6003 -18 c-8
-32 -29 -43 -60 -33 l-28 10 40 21 c50 26 54 27 48 2z m5974 -171 c14 -27 17
-125 5 -133 -5 -3 -28 -8 -52 -12 l-43 -7 7 74 c5 57 12 78 28 92 26 21 39 18
55 -14z m-5867 9 c0 -7 -5 -20 -10 -28 -8 -12 -10 -9 -10 13 0 15 5 27 10 27
6 0 10 -6 10 -12z m123 -170 c8 -219 24 -271 141 -468 48 -81 54 -95 40 -103
-27 -15 -59 8 -92 65 -17 29 -59 85 -94 123 -43 49 -77 100 -108 165 -42 89
-45 100 -45 180 0 85 2 89 37 75 13 -5 19 0 23 22 16 73 38 102 76 103 14 0
17 -22 22 -162z m16507 153 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4
9 -9z m-8263 -51 c14 -22 7 -50 -12 -50 -7 0 -15 14 -17 30 -7 44 8 54 29 20z
m-323 -45 c8 -33 -4 -57 -15 -29 -11 27 -11 54 0 54 5 0 12 -11 15 -25z m-205
-37 c14 -19 5 -23 -18 -9 -7 5 -10 14 -6 21 4 6 8 10 9 8 1 -2 8 -11 15 -20z
m-1995 -99 c49 -43 52 -61 17 -109 -17 -24 -35 -66 -42 -98 -15 -76 -37 -118
-52 -103 -30 30 -3 341 29 341 7 0 29 -14 48 -31z m6566 2 c0 -12 -20 -25 -27
-18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m-3952 -28 c2 -13 -11 -32 -43 -58 -25
-20 -47 -36 -49 -34 -2 2 -6 18 -9 36 -6 29 -3 34 36 57 47 28 61 28 65 -1z
m-361 -23 c7 -15 10 -36 6 -48 -5 -21 -7 -20 -34 15 -16 21 -29 44 -29 51 0
23 44 9 57 -18z m8613 11 c0 -5 -9 -11 -20 -14 -22 -6 -28 8 -7 16 19 8 27 8
27 -2z m-8130 -21 c20 -20 17 -50 -5 -50 -7 0 -15 8 -18 18 -2 9 -8 25 -12 35
-10 23 10 22 35 -3z m-8654 -25 c7 -19 14 -42 14 -51 0 -18 -27 -54 -41 -54
-7 0 -49 113 -49 132 0 4 14 8 31 8 25 0 32 -6 45 -35z m8554 -55 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-190 -28 c0
-13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m7898 -158 c-37 -38
-78 -48 -101 -25 -9 10 -6 18 16 37 21 18 40 24 75 24 l47 0 -37 -36z m-14503
-30 c9 -9 14 -23 11 -31 -3 -8 -6 -19 -6 -24 0 -13 -44 -11 -58 3 -17 17 -15
45 6 57 23 14 28 14 47 -5z m1005 -79 c112 -69 199 -154 257 -252 70 -118 260
-623 283 -753 11 -61 6 -170 -9 -198 -5 -9 -24 0 -66 32 -33 24 -63 42 -67 40
-4 -3 14 -37 41 -77 63 -93 75 -132 66 -212 -13 -128 -11 -128 -95 -23 -81
100 -128 138 -169 138 -70 0 -135 71 -147 163 -5 37 -11 47 -25 47 -40 0 -145
52 -252 125 -73 49 -138 83 -187 100 -67 23 -100 44 -100 67 0 4 20 30 45 57
33 38 49 68 65 123 17 59 114 370 207 661 14 46 16 47 36 34 12 -8 65 -40 117
-72z m8667 31 c59 -18 134 -39 166 -46 97 -20 102 -27 162 -265 30 -116 74
-263 98 -328 l45 -118 -52 -60 c-79 -91 -141 -137 -252 -189 -55 -26 -136 -71
-178 -100 -184 -123 -470 -391 -588 -550 -72 -97 -156 -179 -162 -159 -15 45
-48 163 -48 177 -1 10 15 58 34 107 20 50 73 198 118 330 45 132 88 251 95
265 22 43 106 114 188 159 76 42 80 43 123 32 25 -6 77 -12 115 -12 83 -1 109
15 109 67 0 42 -23 64 -66 64 -74 0 -45 63 37 81 24 6 55 17 68 26 22 14 23
19 17 80 -10 97 -24 116 -114 159 -42 20 -79 38 -80 39 -2 2 13 30 34 62 48
75 56 137 25 182 -13 17 -18 31 -12 31 5 0 58 -15 118 -34z m3381 -47 c-17
-10 -28 -2 -21 17 4 11 8 12 20 3 11 -10 11 -14 1 -20z m1339 17 c-10 -8 -27
-12 -37 -9 -29 8 -13 23 23 23 29 -1 30 -2 14 -14z m-14353 -8 c3 -7 7 -25 10
-40 6 -26 10 -28 51 -28 25 0 45 -3 45 -8 0 -4 -20 -32 -45 -62 -43 -53 -64
-107 -50 -130 3 -6 -14 0 -39 12 -44 21 -46 24 -46 64 l0 42 -46 7 c-58 8 -68
30 -28 62 16 13 43 27 59 31 18 5 35 19 43 35 14 28 40 36 46 15z m14236 -100
c0 -27 -21 -60 -111 -173 -226 -285 -369 -399 -328 -260 13 45 18 49 74 57 11
2 25 13 31 26 6 12 32 36 56 51 63 40 103 121 95 191 l-6 49 47 30 c51 32 110
60 130 61 7 0 12 -13 12 -32z m-10214 -64 c-8 -30 -17 -30 -34 -3 -11 18 -11
24 4 40 16 18 19 18 27 4 5 -9 6 -27 3 -41z m5360 -100 c-6 -22 -62 -71 -72
-62 -2 3 13 40 33 84 l37 79 4 -39 c2 -22 1 -49 -2 -62z m4624 76 c-28 -22
-40 -25 -40 -10 0 6 10 15 23 20 37 15 44 11 17 -10z m-13735 0 c3 -6 -1 -13
-10 -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z m115 -14 c0 -7 -25 -30 -56 -51
-42 -27 -59 -34 -64 -24 -9 13 9 31 70 68 41 25 50 26 50 7z m8738 -22 c2 -1
-4 -28 -14 -59 -19 -61 -21 -61 -90 -35 -21 8 -25 15 -20 33 4 12 9 34 12 49
6 26 8 27 58 21 28 -3 52 -7 54 -9z m-9753 -4 c7 -11 -35 -30 -66 -30 -28 0
-23 17 9 28 36 14 50 14 57 2z m737 -70 l-7 -44 -60 -13 c-78 -16 -95 -16 -95
0 0 23 163 133 168 113 1 -6 -1 -31 -6 -56z m-1062 15 c11 -13 5 -15 -46 -14
-58 1 -59 1 -29 14 39 18 60 18 75 0z m9990 -110 c0 -16 -6 -25 -15 -25 -15 0
-21 31 -8 43 13 14 23 6 23 -18z m-9177 -19 c44 -5 77 -30 77 -59 0 -19 -5
-20 -75 -14 -92 8 -101 -9 -25 -48 28 -14 50 -29 50 -33 0 -5 -10 -36 -21 -71
-16 -48 -27 -65 -47 -72 -42 -16 -55 -7 -84 61 -31 75 -35 135 -12 202 l15 46
42 -4 c23 -3 59 -6 80 -8z m-1530 -32 c3 -4 2 -16 -4 -26 -13 -24 -33 -10 -23
15 6 18 16 21 27 11z m5746 -8 c10 -11 8 -16 -8 -21 -25 -8 -41 1 -41 21 0 18
34 18 49 0z m-4819 -16 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13 10 21
10 8 0 14 -4 14 -10z m1025 -66 c-32 -25 -50 -30 -60 -15 -9 15 67 63 80 50 7
-7 1 -18 -20 -35z m9010 -15 c-26 -71 -64 -128 -106 -156 -22 -14 -42 -24 -44
-21 -12 12 69 149 111 188 25 23 47 40 50 37 2 -2 -3 -24 -11 -48z m-10151
-45 c9 -8 16 -25 16 -37 0 -18 -3 -16 -25 13 -14 19 -25 35 -25 37 0 9 21 1
34 -13z m9876 -84 c0 -28 -13 -40 -24 -22 -7 12 5 52 15 52 5 0 9 -13 9 -30z
m4758 -15 c2 -1 -15 -17 -37 -34 l-40 -32 -6 23 c-3 13 -1 36 4 51 11 28 11
28 43 11 18 -9 34 -18 36 -19z m-14140 -23 c-7 -8 -14 -10 -21 -3 -7 7 -6 15
3 26 10 12 15 13 21 3 4 -7 3 -18 -3 -26z m2022 -72 c0 -16 -4 -30 -8 -30 -5
0 -7 14 -4 30 2 17 6 30 8 30 2 0 4 -13 4 -30z m11975 -34 c9 -59 1 -80 -46
-114 -22 -16 -63 -58 -90 -93 -65 -82 -100 -119 -112 -119 -16 0 1 138 22 178
11 20 37 53 58 73 50 47 142 119 153 119 5 0 12 -20 15 -44z m-11796 -8 c1 -5
-3 -8 -8 -8 -11 0 -21 37 -13 45 6 5 20 -19 21 -37z m7162 32 c6 0 7 -4 4 -10
-7 -12 -55 -13 -55 -2 0 15 13 23 28 17 7 -3 18 -5 23 -5z m-7068 -99 c-2 -3
-11 2 -19 11 -11 11 -14 23 -8 39 7 23 8 23 19 -11 7 -19 11 -37 8 -39z
m12142 2 c-6 -28 -51 -83 -67 -83 -16 0 -7 24 25 66 37 50 52 56 42 17z
m-12132 -348 c3 -61 2 -84 -5 -71 -17 30 -28 139 -21 211 l7 70 7 -60 c4 -33
9 -100 12 -150z m-5270 110 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12
3 -20z m5105 -6 c-16 -10 -33 5 -23 21 5 8 11 8 21 -1 12 -10 12 -14 2 -20z
m-2910 -97 c5 -16 -20 -15 -43 1 -27 20 -10 43 18 24 11 -7 23 -19 25 -25z
m2890 20 c11 -7 8 -42 -4 -42 -10 0 -54 24 -54 30 0 20 34 27 58 12z m7092
-174 c0 -39 -14 -88 -25 -88 -9 0 -10 11 -4 43 4 23 8 45 9 50 0 12 20 8 20
-5z m-7380 -195 c0 -13 -3 -13 -15 -3 -8 7 -12 16 -9 21 8 13 24 1 24 -18z
m6008 -25 c15 -15 -41 -58 -75 -58 -21 0 -43 25 -43 49 0 19 100 27 118 9z
m594 -39 c80 -24 97 -33 88 -44 -20 -24 -66 -38 -88 -26 -32 17 -57 13 -82
-14 -13 -14 -26 -25 -30 -25 -10 0 -30 48 -30 70 0 29 18 70 31 70 7 0 56 -14
111 -31z m181 -141 c-9 -69 -37 -101 -113 -123 -21 -6 -18 0 22 41 l47 49 -21
25 -20 25 28 12 c54 23 63 18 57 -29z m-103 15 c3 -3 -13 -20 -35 -38 -36 -31
-41 -32 -56 -19 -17 15 -30 59 -21 68 5 6 105 -5 112 -11z m4968 -390 c-19
-44 -26 -48 -34 -18 -7 27 6 45 33 45 10 0 10 -6 1 -27z m-6762 -179 c-11 -7
-22 -11 -25 -8 -3 3 3 12 13 20 11 7 22 11 25 8 3 -3 -3 -12 -13 -20z m-131
-93 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m-18 -97 c3
-10 -265 -263 -328 -310 -72 -53 -55 -18 43 87 103 111 254 242 272 236 5 -2
11 -8 13 -13z m-397 -376 c0 -22 -26 -58 -42 -58 -13 0 -9 34 8 58 19 28 34
28 34 0z m-3066 -589 c13 -73 22 -136 20 -138 -2 -2 -16 23 -31 55 -21 46 -28
78 -31 149 -6 129 13 100 42 -66z m7237 -202 c-13 -13 -26 -3 -16 12 3 6 11 8
17 5 6 -4 6 -10 -1 -17z m-110 -213 c-11 -32 -26 -77 -35 -99 -8 -22 -15 -58
-16 -79 0 -40 -51 -136 -72 -136 -7 0 -9 39 -6 108 3 91 8 114 31 158 25 50
109 134 115 116 1 -5 -6 -35 -17 -68z m-6754 -6 c35 -21 36 -23 15 -26 -23 -3
-62 22 -62 40 0 12 5 10 47 -14z m-200 -69 c40 -52 49 -89 30 -126 -12 -22
-30 -33 -84 -51 -38 -12 -75 -22 -81 -22 -6 0 -23 23 -38 51 -16 33 -21 50
-12 47 79 -30 88 -32 100 -20 9 9 4 22 -20 56 l-32 44 47 30 c27 17 50 31 53
31 3 1 20 -17 37 -40z m-147 -26 c-1 -13 -49 -68 -54 -62 -3 3 -7 16 -8 29 -2
18 3 25 22 31 35 10 40 10 40 2z m-1051 -57 c9 -10 9 -16 1 -21 -13 -8 -42 14
-34 26 8 13 20 11 33 -5z m2006 -1114 c-8 -8 -25 10 -19 20 4 6 9 5 15 -3 4
-7 6 -15 4 -17z"
      />
      <path
        d="M10390 33624 c-15 -38 -13 -44 19 -44 65 0 80 25 32 53 -38 22 -40
22 -51 -9z"
      />
      <path
        d="M10413 33166 c4 -10 7 -22 7 -27 0 -11 37 -12 44 -1 3 5 -9 17 -26
27 -29 17 -31 17 -25 1z"
      />
      <path
        d="M10450 32996 c0 -8 -3 -21 -6 -30 -4 -11 0 -16 13 -16 16 0 19 5 15
30 -4 30 -22 43 -22 16z"
      />
      <path
        d="M10475 32860 c-11 -17 5 -32 21 -19 7 6 11 15 8 20 -7 12 -21 11 -29
-1z"
      />
      <path
        d="M10501 32758 c-10 -26 8 -35 67 -37 36 -1 39 5 16 37 -21 31 -72 30
-83 0z"
      />
      <path
        d="M32477 31883 c-14 -13 -6 -23 29 -38 20 -8 38 -15 40 -15 9 0 3 37
-8 48 -13 13 -50 16 -61 5z"
      />
      <path
        d="M10865 27010 c22 -22 41 -36 44 -29 6 18 -34 64 -60 67 -22 3 -21 0
16 -38z"
      />
      <path d="M24040 26990 c-8 -5 -12 -11 -9 -14 3 -3 14 1 25 9 21 16 8 20 -16 5z" />
      <path d="M22205 20179 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
      <path
        d="M22157 19843 c-4 -6 -1 -28 7 -49 10 -29 16 -35 20 -23 9 20 -17 88
-27 72z"
      />
      <path d="M22203 19605 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z" />
      <path
        d="M22221 19463 c-1 -18 4 -33 9 -33 12 0 12 17 0 45 -7 17 -9 15 -9
-12z"
      />
      <path d="M22381 18642 c-1 -59 12 -92 35 -92 17 0 16 3 -17 95 l-18 50 0 -53z" />
      <path
        d="M22300 18633 c3 -42 38 -177 48 -181 16 -5 15 18 -7 107 -10 41 -23
77 -29 79 -7 2 -12 0 -12 -5z"
      />
      <path
        d="M20920 15930 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10
-19 0 -35 -4 -35 -10z"
      />
      <path
        d="M18380 15780 c0 -9 31 -30 44 -30 16 0 3 18 -19 28 -14 7 -25 8 -25
2z"
      />
      <path
        d="M19086 15512 c-8 -13 22 -52 40 -52 9 0 14 7 12 16 -4 21 -44 48 -52
36z"
      />
      <path
        d="M19276 15435 c-74 -51 -73 -46 -22 -133 l47 -80 90 -22 c49 -12 92
-20 96 -17 3 3 -4 34 -16 68 -15 44 -35 77 -70 114 -28 29 -53 65 -57 79 -3
14 -9 26 -13 25 -3 0 -28 -15 -55 -34z"
      />
      <path
        d="M17917 15160 c-3 -22 -14 -59 -26 -82 -23 -47 -22 -53 7 -44 39 13
72 47 72 74 0 23 -33 92 -44 92 -2 0 -6 -18 -9 -40z"
      />
      <path
        d="M18176 15181 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5
4 -12 2 -15 -3z"
      />
      <path d="M18080 15116 c0 -68 0 -69 20 -51 25 22 25 40 0 85 l-19 35 -1 -69z" />
      <path
        d="M22365 14790 c-3 -5 3 -14 14 -20 26 -13 35 2 11 18 -11 7 -21 8 -25
2z"
      />
      <path
        d="M17245 14609 c-4 -6 -5 -13 -2 -16 8 -7 47 7 47 18 0 13 -37 11 -45
-2z"
      />
      <path
        d="M16200 14522 c-39 -19 -67 -61 -57 -85 5 -15 10 -15 50 -1 40 14 45
19 54 59 12 51 7 54 -47 27z"
      />
      <path
        d="M16121 14327 c-42 -15 -56 -36 -47 -72 l7 -28 30 29 c17 16 40 41 51
57 22 30 14 33 -41 14z"
      />
      <path
        d="M15885 12820 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8
0 -12 -4 -9 -10z"
      />
      <path
        d="M15370 12585 c0 -9 6 -12 15 -9 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0
-15 -7 -15 -15z"
      />
      <path
        d="M15157 12560 c-32 -98 -32 -96 7 -79 29 14 34 44 12 75 -12 16 -15
16 -19 4z"
      />
      <path
        d="M14938 12543 c-28 -7 -22 -33 7 -33 14 0 28 0 32 -1 4 0 9 4 11 10 4
13 -28 29 -50 24z"
      />
      <path
        d="M10656 21203 c-3 -3 -6 -21 -6 -40 0 -23 5 -33 15 -33 10 0 15 10 15
34 0 34 -11 52 -24 39z"
      />
      <path
        d="M10510 21168 c0 -7 -3 -23 -6 -35 -4 -13 -2 -23 4 -23 12 0 33 56 25
64 -11 10 -23 7 -23 -6z"
      />
      <path
        d="M10410 20951 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"
      />
      <path
        d="M12073 20197 c3 -9 11 -34 17 -56 17 -64 101 -116 85 -53 -11 43 -41
85 -76 106 -27 16 -31 17 -26 3z"
      />
      <path
        d="M12655 18379 c-3 -9 9 -48 27 -87 25 -53 36 -70 46 -63 11 6 5 25
-26 87 -28 55 -42 74 -47 63z"
      />
      <path
        d="M12730 18157 c0 -5 8 -22 17 -40 14 -26 18 -29 21 -13 2 10 -5 28
-17 39 -12 12 -21 18 -21 14z"
      />
      <path
        d="M33367 16782 c-10 -10 -14 -22 -10 -25 7 -8 43 21 43 35 0 14 -16 9
-33 -10z"
      />
      <path
        d="M21145 8250 c-10 -17 12 -37 25 -24 8 8 6 15 -4 23 -10 9 -16 9 -21
1z"
      />
      <path
        d="M21706 8161 c-15 -29 -26 -54 -24 -56 2 -2 19 2 38 9 40 13 51 47 27
81 -14 18 -16 16 -41 -34z"
      />
      <path
        d="M18465 8038 c13 -99 38 -103 28 -5 -4 41 -10 57 -20 57 -12 0 -14
-10 -8 -52z"
      />
      <path
        d="M21176 8034 c-13 -33 -5 -79 20 -121 21 -36 38 -50 89 -73 65 -29
118 -85 131 -137 8 -31 19 -29 43 11 35 56 23 136 -19 136 -22 0 -24 10 -11
58 10 34 1 41 -29 22 -12 -8 -13 -14 -3 -41 7 -18 11 -34 10 -36 -7 -7 -77 19
-101 38 -15 12 -49 52 -75 90 -36 50 -50 65 -55 53z"
      />
      <path
        d="M21584 8015 c-10 -40 2 -91 33 -143 l27 -47 8 50 c12 75 1 121 -33
145 l-28 21 -7 -26z"
      />
      <path
        d="M21561 7842 c-15 -36 -10 -47 31 -68 29 -15 30 -15 23 8 -4 13 -15
38 -25 57 l-17 33 -12 -30z"
      />
      <path
        d="M16780 7502 c0 -14 25 -41 40 -42 8 -2 26 -2 40 -1 22 2 20 5 -20 26
-49 26 -60 29 -60 17z"
      />
      <path
        d="M16850 7379 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"
      />
      <path d="M16857 6944 c-10 -11 -8 -44 2 -44 6 0 13 4 16 10 8 12 -9 43 -18 34z" />
      <path
        d="M15700 7332 c0 -8 52 -42 64 -42 15 0 3 17 -23 33 -32 19 -41 21 -41
9z"
      />
      <path
        d="M15545 7253 c32 -54 44 -66 60 -59 34 13 -14 86 -57 86 -18 0 -18 -1
-3 -27z"
      />
      <path d="M8470 36315 c1 -26 2 -28 11 -12 8 14 8 22 0 30 -8 8 -11 3 -11 -18z" />
      <path
        d="M8620 36185 c-10 -12 -10 -18 0 -30 7 -8 16 -15 21 -15 11 0 11 31 0
48 -6 10 -10 10 -21 -3z"
      />
      <path
        d="M8695 36090 l-38 -39 47 -21 c49 -20 66 -24 66 -14 0 14 -24 100 -29
106 -4 5 -24 -10 -46 -32z"
      />
      <path
        d="M8786 35933 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24
19z"
      />
      <path
        d="M8710 35647 c0 -8 7 -20 15 -27 12 -10 15 -10 15 3 0 8 -7 20 -15 27
-12 10 -15 10 -15 -3z"
      />
      <path
        d="M9930 35235 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"
      />
      <path
        d="M9926 35042 c-8 -13 23 -72 39 -72 19 0 19 2 1 45 -14 33 -29 44 -40
27z"
      />
      <path
        d="M17538 34545 c-23 -15 -35 -31 -32 -39 4 -11 15 -9 49 12 42 25 51
42 29 49 -5 2 -26 -8 -46 -22z"
      />
      <path
        d="M32793 34164 c-17 -7 -4 -34 13 -27 8 3 14 11 14 19 0 14 -6 16 -27
8z"
      />
      <path
        d="M13640 33075 c-11 -13 -8 -15 13 -15 19 0 25 4 21 15 -4 8 -10 15
-14 15 -4 0 -13 -7 -20 -15z"
      />
      <path
        d="M30240 31679 c1 -52 1 -53 15 -29 19 33 19 55 0 70 -13 11 -15 5 -15
-41z"
      />
      <path
        d="M17147 31533 c-3 -5 -8 -32 -12 -60 -6 -45 -4 -53 9 -53 10 0 16 9
16 23 0 12 3 39 6 60 4 23 3 37 -4 37 -6 0 -13 -3 -15 -7z"
      />
      <path
        d="M15607 30351 c-5 -22 1 -33 14 -25 12 7 12 44 0 44 -5 0 -11 -9 -14
-19z"
      />
      <path
        d="M15734 30045 c-6 -24 0 -44 10 -34 11 11 17 59 7 59 -6 0 -13 -11
-17 -25z"
      />
      <path
        d="M10702 29875 c7 -16 15 -22 21 -16 13 13 1 41 -17 41 -13 0 -14 -4
-4 -25z"
      />
      <path d="M15536 29645 c4 -8 10 -12 15 -9 11 6 2 24 -11 24 -5 0 -7 -7 -4 -15z" />
      <path
        d="M23080 29120 c0 -21 33 -60 50 -60 6 0 -2 18 -16 40 -30 46 -34 48
-34 20z"
      />
      <path
        d="M11420 28820 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"
      />
      <path
        d="M11495 28759 c-8 -12 24 -51 36 -43 9 5 -10 54 -22 54 -4 0 -11 -5
-14 -11z"
      />
      <path
        d="M11597 28594 c-7 -13 19 -47 33 -42 8 2 5 12 -8 27 -10 14 -21 20
-25 15z"
      />
      <path
        d="M23270 28254 c-13 -34 -13 -44 4 -44 13 0 26 28 26 58 0 22 -20 13
-30 -14z"
      />
      <path
        d="M23747 27898 c-39 -66 -59 -138 -55 -199 l3 -54 42 144 c24 80 41
146 39 148 -2 2 -15 -15 -29 -39z"
      />
      <path
        d="M23605 27479 c-10 -30 0 -33 13 -4 6 14 7 25 2 25 -5 0 -12 -9 -15
-21z"
      />
      <path
        d="M23544 27338 c-56 -111 -98 -283 -119 -493 l-6 -60 21 45 c34 72 149
544 137 557 -3 3 -18 -19 -33 -49z"
      />
      <path
        d="M23693 26903 c-7 -3 -13 -14 -13 -26 0 -21 0 -21 16 1 17 25 16 32
-3 25z"
      />
      <path
        d="M24630 25477 c0 -22 34 -47 63 -47 37 0 34 11 -13 38 -46 26 -50 27
-50 9z"
      />
      <path d="M24542 25451 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />
      <path
        d="M20426 25211 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5
4 -12 2 -15 -3z"
      />
      <path
        d="M7281 21376 c-9 -10 -8 -16 4 -26 13 -11 20 -11 41 1 24 12 24 14 8
26 -23 17 -39 16 -53 -1z"
      />
      <path
        d="M23356 21349 c-10 -22 12 -75 25 -62 7 7 6 21 -4 44 -11 26 -16 30
-21 18z"
      />
      <path
        d="M23430 20736 c0 -27 7 -39 30 -56 35 -25 36 -21 14 43 -19 56 -44 63
-44 13z"
      />
      <path
        d="M33480 20000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"
      />
      <path d="M23421 19745 c1 -93 2 -98 14 -70 27 57 28 90 6 131 l-20 39 0 -100z" />
      <path
        d="M7680 19690 c0 -16 5 -30 10 -30 6 0 10 14 10 30 0 17 -4 30 -10 30
-5 0 -10 -13 -10 -30z"
      />
      <path
        d="M35297 19609 c-10 -37 -9 -43 14 -57 20 -13 21 -12 14 20 -11 52 -21
64 -28 37z"
      />
      <path
        d="M34756 19537 c-26 -44 -33 -101 -17 -141 12 -30 61 76 61 130 0 59
-14 62 -44 11z"
      />
      <path
        d="M35150 19555 c0 -21 35 -123 40 -120 4 3 11 21 15 41 5 30 2 41 -16
60 -21 22 -39 31 -39 19z"
      />
      <path d="M7736 19172 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z" />
      <path
        d="M7734 19056 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28
-8 8 -13 7 -18 -6z"
      />
      <path
        d="M6007 18483 c-15 -14 -6 -53 15 -67 21 -15 23 -15 30 5 13 33 -24 83
-45 62z"
      />
      <path
        d="M32108 18288 c-50 -31 -53 -35 -40 -55 5 -8 23 1 60 29 80 62 66 81
-20 26z"
      />
      <path
        d="M6200 18280 c0 -9 31 -30 44 -30 13 0 5 28 -10 34 -23 9 -34 7 -34
-4z"
      />
      <path
        d="M6037 18273 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
      />
      <path
        d="M6364 18106 c-3 -8 0 -24 6 -36 17 -31 34 -11 21 23 -12 30 -19 34
-27 13z"
      />
      <path
        d="M15855 17820 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"
      />
      <path
        d="M34391 17551 c-14 -24 -14 -31 -1 -31 14 0 32 38 21 45 -6 3 -15 -3
-20 -14z"
      />
      <path
        d="M5840 17260 c42 -63 73 -74 44 -17 -21 41 -54 77 -71 77 -9 0 2 -23
27 -60z"
      />
      <path
        d="M9432 15339 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17
-8 3 -12 -1 -9 -9z"
      />
      <path
        d="M30718 10702 c-31 -37 -41 -115 -36 -282 l3 -105 22 68 c23 68 48
266 41 322 l-3 29 -27 -32z"
      />
      <path
        d="M30250 9220 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"
      />
      <path
        d="M11459 6917 c15 -19 46 -23 56 -7 3 6 0 10 -7 10 -7 0 -24 3 -38 7
-22 6 -24 5 -11 -10z"
      />
      <path
        d="M18210 4086 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
      />
      <path
        d="M18250 4056 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
      />
      <path
        d="M21510 3475 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"
      />
      <path
        d="M18620 3266 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12
-12z"
      />
      <path
        d="M15353 2564 c-46 -20 -43 -38 5 -29 42 8 57 18 48 34 -9 13 -13 13
-53 -5z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M16650 36013 c-75 -9 -146 -30 -245 -70 -126 -52 -116 -49 -335 -109
-347 -94 -701 -260 -1165 -544 -111 -68 -236 -126 -340 -159 -82 -26 -112 -41
-175 -89 -88 -69 -96 -73 -299 -163 -85 -38 -177 -84 -205 -102 -28 -18 -89
-52 -136 -74 -47 -23 -121 -68 -165 -100 -44 -32 -98 -69 -120 -82 -22 -14
-87 -59 -145 -102 -58 -42 -196 -131 -308 -198 -294 -175 -529 -335 -701 -475
-41 -34 -142 -106 -225 -161 -134 -89 -153 -105 -176 -150 l-26 -50 -41 9
c-60 12 -101 -12 -166 -97 -103 -136 -301 -332 -405 -401 -80 -52 -122 -91
-242 -222 -241 -263 -253 -275 -306 -318 -444 -358 -449 -362 -567 -506 -107
-130 -195 -217 -268 -264 -50 -33 -71 -54 -86 -88 -12 -24 -37 -55 -55 -68
-19 -12 -52 -48 -73 -79 -64 -93 -163 -206 -209 -239 -24 -17 -68 -40 -98 -52
-56 -21 -60 -25 -96 -110 -10 -25 -49 -83 -87 -130 -37 -47 -81 -109 -97 -137
-18 -33 -42 -59 -61 -68 -18 -9 -39 -21 -48 -28 -29 -22 -555 -853 -836 -1322
-330 -550 -507 -877 -721 -1335 -21 -47 -70 -125 -106 -175 -90 -118 -175
-263 -316 -535 -66 -126 -265 -470 -455 -785 -448 -742 -493 -833 -700 -1420
-57 -159 -144 -402 -195 -540 -143 -392 -191 -563 -245 -885 -38 -230 -71
-366 -132 -553 -68 -212 -134 -384 -211 -559 -111 -251 -154 -410 -174 -655
-13 -158 0 -375 32 -569 27 -158 36 -362 21 -494 -12 -108 -98 -626 -177
-1065 -58 -324 -73 -453 -81 -660 -10 -293 18 -546 97 -876 44 -182 49 -254
23 -335 -16 -52 -16 -58 13 -226 l29 -173 0 -710 c0 -738 1 -750 46 -1055 63
-415 130 -621 275 -843 55 -84 115 -151 343 -382 153 -154 292 -302 310 -328
18 -27 66 -137 107 -244 138 -364 250 -551 480 -794 105 -111 137 -159 169
-259 43 -133 138 -288 285 -465 63 -76 105 -138 135 -200 258 -536 319 -641
499 -855 165 -195 244 -324 375 -608 141 -306 225 -425 496 -702 192 -196 321
-340 650 -725 202 -236 428 -477 656 -700 292 -286 382 -357 855 -682 121 -82
221 -135 406 -212 53 -22 166 -73 250 -112 413 -191 717 -312 970 -385 103
-29 209 -65 236 -78 65 -33 116 -91 142 -164 35 -95 91 -142 237 -197 88 -32
127 -67 143 -126 20 -73 28 -82 118 -136 108 -65 154 -82 261 -94 51 -6 102
-19 131 -33 44 -22 194 -123 700 -471 129 -89 375 -255 545 -370 171 -114 371
-252 445 -305 286 -205 405 -277 673 -411 220 -110 499 -231 899 -387 167 -66
364 -146 438 -179 283 -126 455 -172 817 -218 144 -19 216 -10 269 30 44 34
51 47 82 173 l27 107 45 3 c25 2 84 -4 132 -14 74 -15 93 -16 130 -5 55 17
112 57 166 116 53 56 72 100 72 160 0 25 7 62 15 83 23 53 13 78 -37 94 -73
22 -56 41 47 55 50 6 98 12 108 12 11 1 24 15 33 36 8 19 16 35 19 35 3 0 16
-18 30 -40 16 -25 33 -40 45 -40 18 0 20 7 20 76 l0 77 46 -7 c41 -5 45 -4 39
12 -29 70 -25 113 12 155 15 16 14 19 -17 45 l-33 28 45 33 c45 32 45 33 47
96 2 54 -2 72 -23 108 -35 59 -33 84 14 150 42 59 52 105 25 127 -18 15 -40 1
-50 -31 -12 -36 -23 -19 -45 70 l-22 90 -41 13 -42 13 3 85 c3 108 -12 211
-43 277 -13 29 -22 53 -20 53 3 0 34 -11 70 -25 36 -13 65 -23 65 -22 0 6 -44
116 -57 145 -14 30 -18 32 -69 32 -30 0 -54 3 -54 8 0 4 -14 65 -30 136 -16
71 -29 130 -27 131 7 6 151 25 155 21 2 -3 -12 -28 -31 -55 -36 -50 -36 -50
-21 -88 9 -21 17 -39 18 -41 2 -1 22 5 46 13 23 8 45 15 49 15 3 0 4 -25 3
-56 -2 -30 1 -68 7 -83 12 -27 67 -68 78 -57 22 22 -132 546 -212 721 -18 39
-74 140 -125 224 -51 84 -88 156 -84 160 5 4 25 19 46 34 l36 27 7 97 c4 54
13 129 21 166 17 81 18 150 4 232 -17 95 -13 298 7 395 22 106 42 114 58 23 6
-35 14 -65 17 -68 3 -3 27 43 53 102 26 60 90 196 141 303 105 221 155 344
209 510 103 319 189 767 190 990 0 117 -3 139 -19 163 -10 15 -22 24 -26 20
-4 -4 -24 -97 -45 -206 -47 -252 -61 -293 -108 -318 -19 -11 -19 -9 -13 57 4
38 18 116 31 174 12 58 26 139 29 181 4 52 16 102 37 155 27 66 32 92 31 154
-2 124 -16 173 -78 268 -135 206 -330 338 -574 387 -113 23 -418 31 -548 15
-368 -45 -516 -109 -1422 -620 -313 -177 -683 -365 -955 -485 -170 -75 -443
-165 -502 -165 -28 0 -58 -8 -78 -20 -18 -11 -59 -25 -91 -31 -33 -5 -95 -19
-139 -29 -116 -28 -236 -34 -440 -21 -467 29 -726 119 -990 341 -44 37 -118
91 -165 120 -175 108 -299 228 -354 343 -33 68 -113 334 -160 531 -12 49 -27
91 -34 94 -7 2 -20 -14 -30 -39 l-18 -42 -18 64 c-21 79 -56 133 -98 155 l-32
17 43 38 43 38 -26 57 c-34 74 -33 111 8 258 45 157 56 227 56 353 0 115 25
274 70 448 17 66 53 204 79 306 40 152 58 203 99 280 79 145 104 221 96 285
-5 47 -4 53 15 59 36 11 59 53 97 173 26 83 44 123 64 144 38 38 53 37 45 -5
-8 -33 -128 -430 -220 -723 -62 -195 -87 -312 -110 -507 -19 -154 -16 -190 7
-102 109 421 183 697 195 728 31 78 38 41 44 -254 6 -235 10 -299 27 -370 97
-411 330 -655 757 -794 296 -96 621 -138 890 -115 151 13 199 26 210 59 3 12
18 24 33 27 15 3 32 7 37 9 6 1 32 64 59 139 57 161 57 164 -35 218 -61 37
-65 48 -16 48 16 0 69 9 118 21 l89 21 0 124 c0 68 2 124 6 124 3 0 33 -19 67
-42 81 -54 212 -183 408 -399 88 -97 177 -189 197 -203 63 -43 124 -30 157 34
21 40 19 46 -16 61 -29 11 -30 14 -14 25 48 36 143 26 217 -22 l37 -24 3 88 3
87 62 33 63 33 -26 39 c-21 32 -24 43 -15 54 23 27 15 72 -23 133 -62 100 -59
149 15 263 l39 61 -10 67 c-16 100 -75 320 -111 409 -17 43 -57 146 -89 229
-31 82 -87 204 -124 270 -66 119 -106 221 -106 274 0 21 -19 45 -85 109 -88
84 -115 101 -165 101 -33 0 -36 3 -45 53 l-7 37 38 0 c62 0 69 10 38 56 -15
21 -40 54 -55 72 -16 18 -40 55 -54 82 -13 27 -29 52 -35 55 -6 4 -33 51 -60
105 -31 63 -60 106 -80 120 -22 16 -30 29 -30 51 0 23 -16 47 -67 104 -38 40
-78 87 -91 104 -14 18 -44 38 -78 51 -83 30 -137 72 -210 161 -81 97 -146 155
-231 205 -71 41 -94 73 -73 99 11 13 20 8 72 -35 57 -47 102 -70 115 -57 3 4
-25 37 -63 75 -52 52 -83 74 -127 90 -56 21 -147 30 -147 15 0 -5 31 -26 68
-48 54 -32 63 -40 44 -43 -37 -6 -128 26 -174 61 -31 23 -54 54 -81 109 -37
73 -42 79 -133 139 -69 45 -94 67 -90 78 3 9 17 48 30 86 21 57 26 89 26 172
0 119 -10 103 153 248 109 97 159 159 243 296 117 192 235 453 363 804 77 208
156 362 256 495 48 63 106 135 129 160 55 58 134 176 180 267 19 40 72 117
116 172 83 103 123 171 135 231 12 63 459 914 620 1180 48 79 134 228 193 330
164 287 293 498 430 700 69 102 171 253 227 335 156 230 281 439 405 675 130
249 168 316 260 460 37 58 118 192 180 299 86 149 131 216 197 289 153 172
263 354 468 777 67 138 167 325 223 417 117 194 336 600 435 808 163 341 190
446 178 700 -3 56 3 82 39 185 55 156 108 271 119 259 5 -5 21 -47 36 -94 31
-96 48 -120 82 -120 21 0 23 -4 23 -49 0 -28 5 -53 11 -57 6 -3 23 6 39 21
l29 28 20 -34 c27 -44 27 -50 -9 -84 -28 -27 -29 -29 -13 -59 8 -17 37 -61 64
-97 56 -76 92 -150 87 -181 -2 -14 -17 -26 -48 -38 -25 -10 -47 -25 -48 -35
-8 -39 9 -65 62 -96 77 -43 80 -48 56 -104 -11 -25 -20 -53 -20 -60 0 -7 24
-28 53 -46 41 -26 62 -33 98 -31 26 0 53 -4 61 -11 47 -38 178 -256 178 -295
0 -7 50 -144 111 -305 62 -160 156 -416 210 -567 147 -407 213 -535 410 -787
56 -70 153 -207 216 -303 243 -367 440 -630 667 -893 172 -199 223 -278 373
-572 259 -512 447 -840 828 -1440 456 -719 747 -1266 1252 -2350 273 -586 476
-943 756 -1328 160 -222 177 -247 177 -275 0 -41 -179 -217 -823 -810 l-307
-283 35 -33 c19 -19 35 -41 35 -50 0 -29 17 -17 51 35 35 54 46 52 34 -7 -15
-81 -124 -146 -279 -165 -44 -6 -82 -12 -85 -14 -2 -2 -6 -30 -10 -62 -6 -55
-7 -57 -20 -34 -7 13 -11 35 -8 50 l5 26 -19 -24 c-26 -32 -24 -47 11 -68 37
-22 38 -37 3 -58 -36 -22 -62 -57 -69 -95 -5 -23 -3 -30 10 -30 22 0 20 -6
-12 -49 -16 -20 -59 -81 -97 -136 -38 -55 -102 -135 -142 -177 -56 -59 -73
-83 -73 -106 0 -16 -22 -73 -48 -128 -83 -169 -131 -311 -177 -522 -25 -115
-70 -252 -83 -252 -4 0 -20 11 -36 23 l-29 24 37 60 c47 77 65 126 66 182 0
43 -1 44 -19 27 -28 -26 -72 -131 -85 -205 -6 -38 -24 -87 -41 -115 -33 -57
-55 -132 -55 -193 0 -31 -6 -49 -22 -65 l-22 -22 -9 27 c-12 36 26 222 98 487
70 253 75 288 46 314 -21 19 -22 19 -35 0 -22 -29 -125 -391 -157 -549 -57
-282 -77 -612 -44 -722 25 -83 89 -145 210 -204 57 -27 112 -49 123 -49 29 0
66 -34 87 -79 10 -23 16 -43 14 -45 -2 -2 -31 -17 -63 -33 l-59 -30 48 -16
c83 -29 101 -24 137 33 27 44 35 50 65 50 49 0 94 22 131 64 38 44 37 56 -4
69 -31 11 -44 24 -44 46 0 10 14 12 60 9 l59 -4 -6 43 c-7 54 10 81 80 126 28
19 62 45 74 58 24 27 114 181 109 187 -2 2 -27 -8 -56 -23 -42 -21 -62 -25
-112 -23 l-60 3 112 123 c62 68 110 125 108 128 -3 2 -32 -15 -65 -40 -105
-77 -125 -82 -111 -29 5 18 37 60 77 101 46 47 74 86 87 118 20 57 38 84 100
156 40 46 84 116 84 133 0 3 -25 5 -55 5 -30 0 -55 4 -55 8 0 14 162 272 230
368 193 269 394 501 714 819 267 266 254 264 212 35 -53 -287 -61 -397 -60
-790 2 -355 3 -374 41 -699 21 -183 37 -351 35 -372 l-4 -39 -60 0 c-121 0
-149 40 -156 221 -5 134 8 271 45 450 20 103 23 132 14 165 -10 39 -69 129
-78 120 -2 -2 -19 -51 -37 -108 -17 -56 -36 -102 -41 -100 -6 1 -9 31 -7 65 3
58 1 65 -31 107 -50 65 -63 96 -78 179 l-13 73 -26 -56 c-41 -85 -57 -99 -106
-92 -40 5 -41 5 -143 -98 l-104 -104 32 -7 c17 -3 31 -10 31 -15 0 -31 -45
-218 -71 -297 l-31 -95 21 -14 c28 -20 27 -50 -4 -116 -17 -37 -25 -70 -25
-110 0 -50 -3 -58 -18 -58 -23 0 -52 -30 -52 -53 0 -24 7 -21 42 18 l31 36 24
-23 c39 -37 70 -97 94 -183 30 -110 51 -149 128 -229 40 -42 58 -66 46 -63
-11 3 -40 9 -63 12 -41 7 -43 6 -33 -12 14 -27 83 -89 123 -109 35 -18 178
-33 178 -18 -1 5 -37 33 -81 63 -44 29 -78 56 -75 58 2 3 37 0 78 -7 76 -11
129 -13 358 -11 84 1 208 -7 330 -21 l195 -23 3 -63 c2 -48 6 -63 16 -59 8 3
52 33 99 66 47 34 90 61 95 61 5 0 13 -27 16 -61 l7 -60 33 24 c18 14 54 40
82 58 65 43 93 68 110 101 26 50 54 258 109 818 62 626 75 740 86 740 26 0 73
-64 128 -174 68 -138 173 -440 265 -766 79 -282 263 -1031 277 -1128 7 -53 10
-152 6 -270 -6 -222 -62 -784 -82 -821 -7 -14 -24 -36 -37 -47 -53 -49 -561
-265 -768 -327 -271 -82 -427 -101 -755 -94 -323 8 -608 39 -839 92 -107 24
-264 43 -535 65 -145 12 -302 28 -349 36 -181 31 -296 87 -432 209 -137 124
-258 201 -609 390 -365 196 -572 283 -775 327 -145 32 -412 32 -579 0 -150
-28 -290 -73 -392 -125 -76 -39 -84 -41 -161 -39 -100 2 -93 9 -201 -193 -121
-223 -153 -311 -219 -600 -24 -102 -28 -142 -28 -270 0 -266 50 -474 229 -950
124 -327 148 -376 235 -470 20 -22 53 -70 73 -107 21 -36 55 -89 77 -117 156
-197 230 -383 230 -576 0 -111 -23 -205 -96 -390 -90 -231 -147 -407 -199
-618 -73 -299 -115 -558 -115 -717 l0 -76 43 -14 c31 -11 43 -21 45 -39 3 -22
1 -23 -31 -17 -23 4 -38 2 -45 -7 -19 -22 -35 -225 -29 -357 8 -159 36 -323
82 -473 63 -210 126 -322 237 -421 90 -80 674 -441 755 -466 104 -32 228 -49
408 -56 194 -8 284 3 372 42 32 14 109 38 171 54 128 33 201 69 242 119 27 31
33 34 92 37 40 1 92 13 143 32 50 18 119 34 184 42 129 14 263 48 365 90 56
24 95 49 147 97 97 89 137 102 184 61 l25 -23 -172 -137 c-95 -75 -197 -155
-226 -178 -33 -26 -61 -58 -72 -83 -10 -23 -18 -43 -16 -44 10 -10 136 61 384
215 455 284 627 417 907 697 171 170 222 242 259 361 26 83 57 129 213 321 57
69 22 57 -81 -29 -77 -63 -100 -78 -107 -67 -5 8 -9 17 -9 21 0 19 -36 38 -71
38 -21 0 -39 3 -39 8 0 21 43 72 92 110 51 39 58 42 84 31 16 -6 56 -11 89
-12 73 -1 126 24 199 93 59 54 159 123 219 150 51 24 99 25 196 5 85 -18 275
-22 341 -6 117 27 173 53 680 317 513 266 854 417 1140 503 74 23 259 68 411
101 422 92 602 147 838 255 248 114 392 224 497 380 37 54 43 99 19 131 -22
29 -19 45 30 195 54 166 73 252 81 367 7 115 18 142 55 142 15 0 40 -5 55 -10
54 -21 131 71 118 139 l-6 29 41 -5 c46 -6 71 9 98 60 8 16 30 46 49 67 28 31
44 40 82 45 l47 6 45 87 c25 47 54 91 65 96 50 27 80 53 137 119 33 40 97 103
142 140 44 37 105 96 134 130 112 130 253 317 247 327 -4 7 -23 1 -52 -16 -39
-23 -44 -24 -40 -8 3 11 11 49 17 86 12 67 13 68 52 78 21 6 45 13 53 16 8 4
15 -5 20 -25 8 -36 29 -40 76 -15 47 24 215 288 329 516 92 186 103 203 189
296 50 54 102 114 116 132 14 18 54 61 91 94 52 48 66 67 67 91 1 21 28 68 90
155 67 94 116 181 198 348 60 123 129 253 154 289 28 42 60 113 90 198 47 132
47 132 85 144 42 12 79 51 112 114 11 22 51 81 88 131 65 86 120 184 120 214
0 8 51 74 113 147 l112 133 60 4 60 3 3 62 c2 39 -3 75 -13 98 -8 21 -15 39
-15 40 0 2 15 6 34 9 52 10 79 45 144 189 l59 132 52 -3 c44 -3 59 1 100 27
26 17 57 31 69 31 34 0 62 35 62 78 0 20 12 64 26 97 14 33 39 100 56 150 37
111 89 215 162 324 53 79 56 87 56 145 0 38 6 70 14 81 14 19 15 19 27 -8 7
-15 15 -27 19 -27 3 0 17 18 29 40 19 35 21 45 12 72 -6 17 -11 32 -11 34 0 2
14 11 31 19 45 24 69 52 69 81 l0 25 -29 -20 c-25 -18 -30 -19 -35 -5 -21 56
6 104 96 170 44 33 57 51 95 133 67 143 101 255 113 367 6 54 24 195 42 312
28 194 29 218 17 255 -21 63 -14 120 26 242 21 62 42 150 48 202 10 85 13 92
40 108 95 56 142 130 134 209 -3 28 -8 60 -12 72 -5 18 -2 22 22 27 28 5 29 9
52 124 13 65 27 170 31 233 4 63 15 150 24 194 18 88 37 305 65 766 39 615 44
766 38 1123 -7 457 -33 743 -127 1407 -27 190 -63 471 -80 625 -55 500 -94
733 -166 1005 -19 74 -46 177 -60 228 -13 51 -33 178 -43 282 -24 224 -50 381
-91 550 -17 69 -49 213 -71 320 -74 361 -118 501 -236 755 -144 308 -233 540
-284 738 -52 204 -161 476 -271 672 -92 165 -228 370 -427 638 -444 601 -492
671 -487 697 22 99 24 121 17 147 -9 30 -39 93 -45 93 -2 0 -1 -33 2 -73 4
-71 3 -75 -26 -108 -17 -19 -32 -32 -34 -29 -22 32 -68 147 -68 170 0 30 0 30
47 23 l48 -6 -53 60 c-36 43 -52 69 -52 89 0 16 -9 44 -20 62 l-20 32 61 -31
c34 -16 63 -28 65 -26 6 6 -24 87 -41 114 -8 12 -24 27 -35 33 -18 10 -22 6
-41 -37 l-21 -48 -34 84 c-38 94 -39 103 -22 130 9 15 5 29 -22 77 -84 148
-95 176 -95 236 0 47 -3 58 -16 58 -28 0 -69 40 -96 93 -14 29 -53 78 -85 109
l-60 57 7 65 c10 88 -3 120 -79 195 -56 56 -68 75 -112 182 -40 97 -60 133
-111 192 -35 39 -63 77 -63 83 0 7 -30 53 -66 104 -36 50 -118 180 -183 288
-65 108 -143 234 -173 278 -58 84 -75 138 -63 193 5 25 -5 44 -83 147 -50 66
-179 227 -289 359 -337 408 -453 551 -658 815 -425 546 -566 711 -875 1020
-340 341 -559 521 -944 775 -240 159 -465 325 -698 517 -282 233 -879 690
-1003 770 -88 56 -352 195 -465 243 -47 21 -161 63 -255 95 -93 32 -246 88
-340 125 -93 37 -228 89 -300 116 -71 28 -162 67 -200 88 -92 49 -478 290
-727 453 -220 144 -321 198 -423 226 -38 11 -88 29 -110 40 -356 184 -829 235
-1147 123 -175 -62 -204 -71 -242 -77 -60 -9 -90 -46 -106 -129 -30 -166 -79
-273 -153 -336 l-31 -26 13 -69 c6 -38 25 -123 41 -189 36 -153 49 -240 56
-379 4 -79 17 -160 46 -279 l39 -169 -15 -301 c-9 -166 -22 -400 -28 -519 -6
-120 -9 -221 -5 -225 4 -3 23 1 42 10 20 9 38 17 40 17 3 0 5 -33 5 -73 0 -40
7 -129 15 -197 9 -69 20 -208 25 -310 7 -149 14 -202 34 -275 31 -106 31 -123
9 -170 -17 -35 -16 -38 35 -249 29 -117 52 -235 52 -262 0 -27 9 -78 20 -113
11 -36 29 -139 40 -230 12 -102 28 -189 41 -226 19 -52 22 -82 24 -230 1 -161
2 -174 30 -245 l28 -75 6 -315 c5 -224 10 -321 19 -337 17 -30 43 -43 87 -43
l37 0 -31 -35 c-17 -19 -38 -49 -46 -66 -19 -36 -21 -138 -5 -198 14 -54 15
-119 1 -154 -16 -40 -14 -98 5 -142 24 -58 6 -202 -35 -284 -37 -72 -37 -78
-9 -101 l22 -17 -49 -149 c-62 -183 -72 -194 -196 -194 -171 0 -491 91 -533
152 -16 23 -34 94 -120 493 -162 742 -229 1216 -296 2095 -34 449 -47 558 -95
860 -19 119 -42 287 -51 374 -17 169 -55 344 -145 654 -64 224 -88 288 -193
517 -107 235 -157 310 -262 393 -43 35 -102 86 -129 113 -48 47 -53 49 -106
49 -47 0 -76 10 -214 76 -235 111 -295 134 -355 134 -49 0 -54 -2 -104 -53
-45 -47 -55 -53 -76 -45 -27 10 -31 6 -62 -72 -16 -42 -22 -79 -23 -157 l-2
-102 -78 -65 -79 -66 3 -50 c2 -42 -2 -53 -23 -75 -14 -14 -29 -25 -34 -25 -4
0 -23 21 -41 46 -29 42 -35 46 -64 40 -48 -10 -54 -22 -26 -52 18 -20 24 -38
25 -77 1 -29 5 -74 8 -102 6 -41 2 -59 -17 -100 l-23 -50 27 -52 c40 -75 44
-136 20 -278 -11 -66 -30 -205 -42 -310 -12 -104 -25 -193 -29 -198 -3 -4 -32
19 -63 52 -66 69 -71 95 -37 182 l21 52 -30 -6 c-34 -7 -37 -5 -46 44 -6 35
-6 36 34 48 28 8 46 21 56 41 17 32 18 28 -32 114 -8 13 -13 62 -13 126 l-1
105 -24 -27 c-16 -18 -26 -46 -30 -83 -4 -30 -11 -55 -15 -55 -20 0 -52 65
-80 166 -18 60 -43 127 -57 148 l-26 40 29 23 c16 13 33 23 37 23 5 0 5 5 2
10 -3 6 -14 10 -24 10 -24 0 -50 31 -105 120 -40 65 -46 81 -46 130 0 47 -9
74 -65 190 -41 84 -70 133 -77 131 -41 -13 -82 -11 -134 8 -31 12 -58 20 -59
19 -1 -2 -21 -31 -44 -65 -23 -35 -46 -63 -50 -63 -5 0 -28 -19 -51 -41 -51
-50 -111 -171 -233 -474 -102 -253 -102 -255 -171 -575 -218 -1019 -318 -1705
-380 -2620 -22 -323 -24 -334 -44 -387 -10 -27 -68 -129 -129 -228 -269 -440
-387 -707 -437 -985 -18 -96 -61 -230 -75 -230 -24 0 -114 99 -174 190 -144
221 -272 515 -314 725 -27 136 -26 800 2 995 15 111 19 199 20 495 2 391 2
389 81 1038 74 612 111 1044 134 1569 10 222 9 243 -11 344 -19 100 -20 129
-14 393 5 198 3 354 -6 506 -18 285 -14 391 17 544 13 68 28 129 31 134 4 6
16 -25 28 -69 18 -66 25 -80 43 -82 15 -2 25 4 31 20 11 29 -4 202 -25 283
-49 189 -219 355 -394 384 -54 9 -441 20 -490 14z m-660 -362 c-12 -23 -29
-35 -37 -27 -7 7 26 46 39 46 5 0 4 -9 -2 -19z m7089 -78 c-7 -10 -15 -20 -16
-22 -2 -2 -3 5 -3 17 0 14 6 22 16 22 14 0 15 -3 3 -17z m-7925 -354 c-3 -5
-10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m-484 -240
c0 -6 -12 -33 -27 -60 -20 -34 -34 -49 -48 -48 -40 2 -118 24 -122 35 -4 11
149 82 180 83 9 1 17 -4 17 -10z m1625 -371 c27 -32 27 -34 19 -118 -11 -110
-7 -201 11 -270 8 -30 24 -88 35 -128 32 -119 43 -235 37 -387 -7 -167 -36
-341 -147 -885 -235 -1142 -239 -1187 -213 -2180 6 -245 9 -475 6 -512 -5 -64
-7 -68 -36 -77 -62 -22 -83 -15 -125 42 -47 62 -168 167 -298 258 -106 74
-143 113 -179 192 -45 98 -47 115 -70 927 -16 550 -23 700 -35 715 -4 6 -15
34 -25 64 -16 49 -16 69 -6 220 7 91 22 227 35 301 13 74 22 138 20 142 -2 3
-26 9 -53 13 -61 8 -78 26 -91 95 -17 89 -13 135 14 167 l24 28 7 -28 c4 -16
5 -48 1 -73 -7 -56 6 -89 43 -109 63 -32 65 -26 81 170 5 66 12 145 15 176 l6
56 -143 53 c-79 30 -145 55 -147 56 -2 2 20 44 49 93 l51 91 -30 44 c-53 77
-42 83 44 26 l60 -40 5 -62 c5 -61 6 -63 46 -85 41 -23 42 -23 65 -4 l24 19
-22 51 -21 51 36 16 c20 8 56 20 79 27 36 9 45 8 61 -6 10 -9 22 -14 25 -10
12 12 7 23 -11 23 -9 0 -39 15 -65 34 -67 47 -98 53 -149 26 -30 -15 -51 -20
-75 -15 -18 3 -33 9 -33 13 0 31 143 192 171 192 3 0 37 -25 75 -55 38 -30 72
-55 75 -55 27 0 54 160 30 175 -6 3 -22 -10 -38 -30 -47 -59 -70 -39 -37 31
19 39 138 148 179 164 31 12 61 6 88 -18 23 -21 22 -41 -8 -85 -14 -20 -23
-37 -20 -37 3 0 32 14 65 30 33 17 62 30 65 30 3 0 5 -19 5 -41 0 -40 1 -41
22 -27 31 21 32 34 8 58 -21 21 -21 22 -3 35 48 37 70 39 143 12 l69 -26 -10
27 c-15 39 -4 80 37 137 74 104 92 176 59 235 -13 24 -12 27 18 52 41 35 50
35 82 -4z m-260 -209 c4 -5 1 -24 -6 -41 l-12 -30 -14 27 c-11 20 -12 30 -2
41 14 17 25 18 34 3z m8056 -272 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6
-10 -1 -17z m-21 -87 l21 -29 -24 -7 c-40 -10 -112 38 -90 60 4 3 21 6 39 6
25 0 38 -7 54 -30z m-4646 -100 c19 -36 43 -96 55 -133 20 -67 47 -244 38
-253 -3 -3 -36 50 -75 118 -55 96 -74 140 -87 203 -16 75 -16 83 -1 120 19 45
18 46 70 -55z m4507 17 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1
-17z m472 -42 c27 -11 76 -15 193 -15 l157 0 -7 -55 c-8 -73 9 -188 38 -251
13 -29 43 -72 65 -97 23 -24 41 -49 41 -55 0 -6 -19 -54 -41 -106 -32 -74 -47
-98 -65 -102 -12 -3 -25 -11 -27 -17 -8 -23 13 -318 27 -375 15 -61 21 -359
21 -1072 0 -311 -3 -373 -17 -420 -22 -73 -42 -406 -29 -475 5 -27 17 -79 27
-115 13 -52 15 -91 11 -200 -5 -118 -13 -164 -61 -363 -31 -125 -63 -246 -73
-268 l-18 -40 -102 50 c-122 59 -188 118 -180 159 3 15 8 44 12 64 6 27 0 63
-23 140 -17 57 -34 137 -37 178 -11 132 -55 477 -96 745 -22 143 -57 377 -79
520 -22 143 -49 343 -60 445 -44 400 -77 584 -128 716 l-20 49 38 348 c20 191
39 350 42 352 3 3 59 8 127 12 112 6 121 7 115 25 -10 33 6 76 57 144 27 37
49 73 49 80 0 17 0 17 43 -1z m-9374 -49 c-14 -16 -29 6 -22 33 l6 23 13 -23
c8 -15 9 -28 3 -33z m4259 -47 c78 -18 77 -17 58 -38 -17 -18 -122 -44 -138
-34 -5 2 -8 22 -8 44 0 44 7 47 88 28z m105 -147 c27 -50 57 -149 57 -189 0
-40 -27 -38 -85 6 -54 41 -85 84 -85 118 0 10 12 46 27 80 25 57 29 61 44 47
9 -8 28 -36 42 -62z m5668 -162 c26 -14 25 -57 -1 -65 -29 -9 -40 2 -40 41 0
36 9 41 41 24z m-5736 -267 c24 8 29 56 11 108 -20 53 -13 75 18 63 70 -27 87
-329 31 -553 -15 -57 -40 -135 -57 -175 -16 -39 -44 -110 -61 -158 -20 -54
-37 -88 -46 -88 -12 0 -13 21 -8 124 5 101 4 126 -8 136 -14 12 -35 6 -35 -10
0 -5 -5 -21 -10 -36 -10 -24 -12 -26 -26 -12 -12 12 -13 23 -4 63 6 27 20 64
32 83 25 40 40 196 31 319 -5 67 -3 87 11 108 9 14 23 25 31 25 19 0 19 2 -4
82 l-19 66 26 54 25 53 26 -128 c14 -74 29 -126 36 -124z m-148 186 l27 -42
-39 -33 c-37 -31 -65 -43 -65 -27 0 10 43 143 47 143 1 0 15 -19 30 -41z
m-7237 -73 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m3365 -27
c-3 -5 -26 -23 -51 -39 l-44 -31 0 24 c0 28 17 45 57 56 31 9 47 5 38 -10z
m9785 -69 c0 -64 -14 -80 -25 -29 -6 26 9 89 21 89 2 0 4 -27 4 -60z m-11490
-25 c35 -31 104 -178 160 -339 68 -196 116 -304 290 -650 179 -358 226 -464
238 -541 6 -33 9 -61 8 -62 -1 -1 -49 15 -106 36 -192 71 -276 76 -400 25 -56
-23 -74 -25 -130 -20 -36 3 -90 13 -120 21 -30 8 -73 15 -95 15 -22 0 -276
-43 -564 -95 -288 -52 -528 -92 -532 -89 -28 17 8 264 60 415 57 167 84 215
279 496 165 238 262 398 333 548 46 96 62 119 127 182 85 82 70 78 283 78 133
0 149 -2 169 -20z m-1875 -81 c-47 -39 -65 -47 -65 -29 0 24 38 55 68 55 l27
-1 -30 -25z m13281 11 c4 -8 3 -31 0 -50 l-7 -36 -46 38 -45 37 33 12 c46 17
59 17 65 -1z m-5936 -20 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25
10 25 6 0 10 -11 10 -25z m-7520 -128 c0 -20 -80 -81 -92 -69 -6 6 5 23 33 50
42 39 59 44 59 19z m7568 -55 c-5 -45 -28 -38 -28 8 0 37 2 41 16 30 8 -7 14
-25 12 -38z m7728 -36 c206 -93 262 -107 469 -116 148 -6 190 -11 236 -28 133
-52 261 -156 298 -243 11 -26 25 -84 30 -129 10 -90 30 -124 82 -135 44 -10
78 -42 85 -81 4 -23 18 -44 39 -61 32 -25 32 -28 28 -87 -5 -52 0 -80 30 -171
41 -128 95 -435 77 -435 -4 0 -29 10 -56 22 -41 18 -64 21 -144 19 -61 -1
-142 5 -225 19 -187 31 -322 35 -516 16 -199 -19 -362 -20 -562 -4 -142 12
-151 11 -245 -11 -124 -30 -182 -36 -226 -21 -46 15 -103 79 -112 127 -8 43
18 309 41 418 21 96 156 403 225 510 21 33 53 96 70 140 74 191 163 305 236
305 12 0 75 -24 140 -54z m-7796 -11 c-7 -65 -16 -77 -25 -36 -4 19 -1 40 10
60 8 17 17 31 18 31 2 0 0 -25 -3 -55z m-1840 -42 c0 -28 -3 -34 -10 -23 -13
20 -13 60 0 60 6 0 10 -17 10 -37z m-5835 11 c0 -17 -44 -66 -54 -60 -13 9 28
78 43 73 6 -2 11 -8 11 -13z m3886 -67 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5
6 -4 6 -10 -1 -17z m9814 -87 c4 -32 -1 -74 -19 -144 -33 -128 -39 -135 -57
-55 -9 41 4 145 33 254 l10 39 13 -24 c8 -14 16 -45 20 -70z m-6005 -40 c0
-22 -4 -40 -10 -40 -11 0 -13 39 -4 64 10 26 14 19 14 -24z m-7260 -20 c0 -25
-10 -50 -21 -50 -10 0 -12 63 -3 73 11 10 24 -3 24 -23z m14250 -219 c0 -25
-47 -119 -81 -162 -16 -21 -48 -52 -70 -70 l-40 -32 6 39 c9 48 38 102 100
186 36 47 54 64 67 60 10 -2 18 -12 18 -21z m-13540 -47 c-27 -47 -34 -54 -42
-39 -15 26 -1 61 34 83 18 12 34 19 36 17 2 -2 -10 -29 -28 -61z m6550 26 c0
-5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z
m1050 -189 c4 -53 15 -118 25 -146 25 -70 30 -323 16 -748 -13 -369 -17 -395
-73 -442 -60 -51 -108 -20 -108 70 -1 27 9 147 22 265 20 199 21 225 8 333
-12 105 -12 126 3 195 15 70 16 84 2 149 -18 86 -11 111 39 138 20 11 36 24
36 29 0 6 -22 33 -49 61 l-50 50 33 85 c18 47 36 94 40 105 6 18 9 16 28 -14
17 -26 23 -57 28 -130z m-3750 121 c0 -3 -5 -35 -11 -70 -11 -60 -13 -64 -26
-46 -14 18 -13 100 2 124 6 9 35 3 35 -8z m8667 -5 c-2 -6 -10 -61 -18 -120
-9 -77 -16 -103 -22 -91 -13 24 -12 164 1 197 11 26 49 40 39 14z m-13345 -59
l-42 -43 10 35 c12 42 48 76 64 60 7 -7 -2 -23 -32 -52z m728 -5 c0 -25 -28
-172 -40 -209 -6 -17 -24 -44 -40 -59 -24 -23 -30 -37 -30 -71 0 -35 -5 -47
-31 -68 -17 -14 -35 -26 -41 -26 -19 0 0 117 31 188 16 37 39 102 51 145 16
53 31 84 48 98 31 24 52 25 52 2z m-1110 -112 c-16 -30 -45 -44 -59 -27 -8 10
-2 25 26 62 l38 49 3 -32 c2 -18 -2 -41 -8 -52z m7596 63 c19 -5 20 -11 16
-57 -2 -29 -6 -54 -8 -55 -1 -2 -16 -1 -33 3 -20 4 -37 17 -46 35 -22 42 -19
57 13 68 34 13 33 13 58 6z m154 -162 c-31 -198 -37 -227 -66 -299 -25 -62
-25 -66 -14 -152 16 -113 7 -172 -30 -215 -24 -27 -28 -39 -23 -68 5 -34 4
-36 -28 -43 -54 -10 -84 -24 -96 -45 -14 -21 -17 -19 67 -40 l45 -12 -38 -34
c-41 -38 -77 -43 -159 -21 l-46 12 24 58 c16 37 26 83 29 130 6 112 28 171 87
230 55 55 84 109 94 177 3 25 20 73 36 108 24 50 28 68 21 85 -8 16 -7 26 3
35 17 15 18 48 3 90 -10 30 -8 35 47 97 32 37 60 61 62 55 2 -6 -6 -73 -18
-148z m-2735 68 c9 -18 15 -52 14 -87 -1 -32 2 -79 6 -105 6 -39 4 -52 -15
-82 -12 -20 -25 -36 -30 -36 -13 0 -40 77 -40 113 0 17 9 75 20 127 11 52 20
96 20 98 0 10 12 -3 25 -28z m-4215 -63 c0 -22 -44 -57 -72 -57 -22 0 -28 4
-28 23 0 41 22 56 72 49 15 -2 28 -9 28 -15z m12869 -148 c-16 -10 -23 -4 -14
10 3 6 11 8 17 5 6 -4 5 -9 -3 -15z m-12945 -54 c8 -53 3 -65 -29 -65 -53 0
-70 81 -22 100 38 15 45 11 51 -35z m6801 -12 c33 -50 32 -90 -4 -141 -50 -68
-54 -65 -47 34 7 92 15 144 21 144 3 0 16 -17 30 -37z m-8129 -158 c-12 -18
-26 -25 -48 -25 l-31 0 44 34 c47 36 62 32 35 -9z m9811 -12 c5 -13 -25 -26
-38 -17 -6 3 -5 11 2 20 13 16 31 14 36 -3z m-4197 -67 c0 -30 -5 -58 -11 -62
-10 -6 -79 52 -79 66 0 7 71 49 83 50 4 0 7 -24 7 -54z m8643 22 c-7 -39 -9
-42 -22 -34 -6 4 -6 16 1 36 14 40 29 38 21 -2z m-13454 -10 c-12 -21 -13 -21
-17 -4 -2 10 2 22 9 27 20 12 22 4 8 -23z m4695 -105 c9 -22 -10 -41 -25 -26
-13 13 -5 43 10 43 5 0 12 -8 15 -17z m-4504 -13 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4538 -125 c9 -28 10 -41 2
-49 -17 -16 -40 20 -40 62 0 45 21 38 38 -13z m8706 -57 c-3 -5 -16 -1 -27 9
-12 10 -24 19 -26 20 -1 1 0 7 4 13 5 8 15 5 31 -11 13 -12 21 -26 18 -31z
m-8684 -28 c16 -8 18 -15 10 -34 -15 -41 -21 -139 -12 -196 9 -49 7 -58 -13
-88 l-23 -33 -20 28 c-15 22 -21 51 -25 133 -4 58 -9 119 -13 136 -5 28 -3 32
33 46 21 9 39 16 41 17 2 1 12 -4 22 -9z m-5620 -65 c0 -22 -83 -126 -132
-165 l-40 -33 7 34 c15 67 43 108 98 139 51 29 67 35 67 25z m14280 -5 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-6104
-191 c22 -18 17 -41 -17 -82 -27 -32 -33 -53 -45 -135 -33 -225 -95 -311 -174
-242 -33 28 -34 30 -15 42 38 23 64 56 68 89 3 19 10 67 16 107 6 40 11 96 11
125 0 57 18 92 54 108 26 11 83 4 102 -12z m-8382 -46 c-10 -16 -40 -66 -67
-113 -55 -96 -105 -166 -138 -195 l-22 -20 7 40 c18 99 115 244 201 296 39 25
41 24 19 -8z m18180 -42 c5 -8 -42 -47 -49 -39 -1 2 1 13 6 26 8 22 33 30 43
13z m-14992 -16 c67 -14 114 -32 105 -41 -11 -11 -219 -66 -302 -79 -93 -15
-231 -18 -275 -6 -24 7 -23 8 22 39 129 89 293 121 450 87z m13121 -14 l22
-23 -55 6 c-104 13 -119 17 -113 33 10 25 118 13 146 -16z m-12372 -47 c16
-37 28 -69 26 -71 -4 -4 -136 22 -142 29 -4 4 73 108 81 108 3 0 18 -30 35
-66z m-4250 -89 c-38 -78 -121 -185 -143 -185 -14 0 -1 54 28 118 28 64 147
194 152 167 2 -10 -14 -55 -37 -100z m16974 75 c19 -20 19 -20 -21 -20 -41 0
-83 28 -67 44 12 12 66 -3 88 -24z m699 11 c12 -18 -154 -31 -406 -31 -260 0
-261 0 -243 20 17 19 31 20 331 20 172 0 315 -4 318 -9z m-13411 -154 c-11
-62 -41 -113 -104 -179 -70 -74 -105 -90 -134 -62 -13 13 -16 28 -12 57 7 49
35 78 99 101 28 10 57 30 70 48 64 84 93 96 81 35z m14794 -79 c22 -25 43 -61
43 -76 0 -9 -93 -52 -112 -52 -5 0 -7 8 -4 18 2 9 10 43 16 75 12 62 25 70 57
35z m-1697 -43 c10 -13 9 -15 -9 -15 -12 0 -21 6 -21 15 0 8 4 15 9 15 5 0 14
-7 21 -15z m-10470 -86 c0 -63 -9 -69 -30 -19 -14 35 -6 80 15 80 12 0 15 -14
15 -61z m9540 1 c0 -60 0 -60 -30 -60 -35 0 -53 27 -44 67 7 32 30 53 56 53
15 0 18 -9 18 -60z m-12140 29 c9 -16 8 -26 -5 -45 -18 -27 -40 -31 -49 -8 -9
25 -7 33 14 54 25 25 27 25 40 -1z m9118 -1 c14 -14 16 -64 3 -72 -17 -11 -44
32 -37 59 7 28 16 31 34 13z m-9690 -69 c-2 -6 -26 -20 -53 -30 -58 -22 -55
-21 -55 -10 0 8 85 50 103 51 5 0 7 -5 5 -11z m277 1 c3 -5 -1 -10 -9 -10 -8
0 -18 5 -21 10 -3 6 1 10 9 10 8 0 18 -4 21 -10z m13220 1 c6 -11 -25 -81 -36
-81 -16 0 -16 16 -2 53 15 37 27 45 38 28z m-305 -37 c0 -8 -5 -12 -10 -9 -6
4 -8 11 -5 16 9 14 15 11 15 -7z m525 -6 c14 -6 25 -15 25 -19 0 -8 -122 -147
-131 -148 -3 -1 -12 29 -21 65 l-16 66 52 24 c59 28 57 27 91 12z m-742 -49
c38 -10 38 -11 21 -30 -29 -32 -61 -23 -77 24 -7 20 3 21 56 6z m1140 -43 c15
-12 15 -15 2 -26 -12 -10 -18 -10 -30 0 -34 29 -8 53 28 26z m-14213 -15 c0
-13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m8480 -148 c24 -140 37
-309 23 -300 -21 13 -63 276 -62 392 0 91 13 62 39 -92z m4344 91 c68 -10 127
-27 180 -51 43 -19 88 -35 100 -35 11 1 33 2 49 3 16 1 43 -10 66 -25 39 -27
40 -27 85 -12 58 20 103 20 186 0 36 -8 110 -19 165 -24 55 -4 134 -14 175
-21 65 -11 96 -10 215 5 l140 18 22 -27 c38 -43 30 -77 -68 -297 -146 -327
-252 -527 -387 -728 -142 -212 -157 -230 -188 -230 -34 0 -165 110 -252 210
-40 47 -120 155 -177 240 -174 261 -335 485 -348 485 -18 0 -13 -22 24 -103
23 -50 30 -75 22 -83 -22 -22 -199 283 -227 392 -7 30 -23 71 -35 90 -11 18
-21 43 -21 54 0 20 65 134 84 147 17 12 81 9 190 -8z m-15824 -169 c17 -32 74
-66 150 -90 30 -10 72 -26 93 -37 22 -11 41 -17 44 -14 3 3 -17 29 -44 58 -48
50 -76 108 -53 108 6 0 87 -38 180 -85 l170 -85 -20 -40 -21 -40 43 -19 c56
-24 102 -75 162 -183 26 -48 107 -165 179 -260 71 -95 150 -204 175 -243 59
-90 125 -233 147 -315 57 -213 208 -509 421 -826 46 -69 84 -131 84 -139 0 -8
-30 -29 -67 -47 -60 -29 -77 -33 -148 -33 -98 0 -131 16 -230 107 -119 109
-539 463 -739 623 -318 254 -486 417 -840 817 -84 95 -181 191 -255 253 -65
55 -123 109 -129 120 -11 23 28 138 57 168 9 9 45 22 79 28 77 14 114 38 171
113 59 78 90 90 206 83 67 -5 96 -3 115 7 29 17 50 8 70 -29z m19328 -38 c154
-53 214 -151 183 -298 -7 -30 -16 -89 -21 -131 -15 -128 -33 -154 -302 -425
l-241 -241 47 -24 46 -23 -454 -369 c-250 -202 -458 -367 -463 -367 -4 1 -7
18 -6 36 2 34 1 35 -35 35 -44 0 -79 -27 -88 -68 -12 -57 -24 -71 -101 -113
-43 -23 -149 -85 -236 -137 -87 -52 -185 -103 -219 -113 -63 -20 -173 -26
-183 -9 -3 5 3 27 15 49 30 59 7 62 -51 5 -27 -27 -49 -51 -49 -55 0 -4 23
-20 50 -36 l50 -29 -23 -22 c-28 -26 -148 -52 -240 -52 -45 0 -77 7 -117 26
-52 24 -120 78 -120 96 0 4 29 8 64 8 58 0 66 -3 84 -26 16 -23 24 -26 48 -19
l29 7 -45 17 c-38 14 -50 26 -82 82 -21 36 -60 91 -87 122 -37 42 -52 69 -60
108 -6 29 -9 54 -7 56 2 3 36 0 75 -6 97 -15 97 -15 123 27 12 20 42 61 66 90
l44 54 -17 69 c-16 63 -16 72 -1 115 12 34 115 218 142 252 1 2 27 -13 58 -34
l56 -37 143 140 c132 130 147 141 208 162 76 25 138 73 175 137 24 40 24 45
10 66 -9 12 -16 33 -16 45 0 26 23 42 187 128 106 56 112 61 118 96 10 57 36
90 145 185 184 160 218 185 332 250 132 76 222 106 299 102 57 -3 57 -3 43
-60 -5 -21 1 -36 32 -72 l38 -46 18 28 c11 15 36 56 58 92 42 71 91 123 130
139 29 13 91 7 148 -12z m-12959 -206 c59 -286 48 -312 -83 -190 -48 45 -93
94 -101 110 -17 32 -19 77 -6 111 9 24 141 172 147 165 2 -1 21 -90 43 -196z
m-3084 159 c3 -11 4 -28 1 -37 -3 -10 -21 -115 -41 -233 -39 -238 -53 -285
-93 -322 -30 -28 -304 -179 -392 -215 -112 -46 -206 -26 -361 76 -184 122
-308 275 -464 574 -43 81 -75 150 -73 152 2 2 73 -5 158 -16 137 -18 187 -20
430 -15 157 4 383 17 525 30 325 30 302 30 310 6z m-1478 -56 c9 -23 -11 -38
-26 -20 -13 16 -7 36 10 36 5 0 12 -7 16 -16z m16542 -30 c-12 -13 -26 -21
-31 -18 -5 4 0 17 13 30 12 13 26 21 31 18 5 -4 0 -17 -13 -30z m-3609 -134
c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-12739 -166 c17 -35 29 -73 27 -88 -3 -22 -13 -11 -75 82 -39 59 -74 123 -78
142 l-6 35 51 -55 c28 -30 64 -83 81 -116z m10848 -122 c-20 -8 -39 8 -39 33
l0 26 24 -28 c14 -15 20 -30 15 -31z m3081 -32 c16 -32 18 -40 6 -40 -17 0
-56 46 -56 66 0 29 30 13 50 -26z m-13772 -42 c121 -112 242 -227 242 -231 0
-8 -113 14 -140 28 -15 8 -40 29 -56 47 -35 40 -154 204 -154 212 0 3 9 6 20
6 11 0 51 -28 88 -62z m12742 6 c15 -39 13 -44 -20 -44 -16 0 -30 4 -30 9 0
20 20 61 30 61 6 0 15 -12 20 -26z m1142 -179 c-18 -38 -28 -30 -39 31 l-10
55 30 -30 c27 -27 29 -33 19 -56z m3338 -16 c-28 -24 -36 -27 -38 -14 -5 24
17 45 47 45 l26 -1 -35 -30z m708 -8 c8 -55 -5 -69 -109 -122 l-102 -50 23 38
c69 113 102 152 134 156 45 5 51 3 54 -22z m-3910 -218 c115 -116 118 -130 19
-93 -132 50 -270 249 -200 289 14 8 22 1 45 -40 15 -26 76 -97 136 -156z
m-13218 123 c-24 -27 -72 -42 -91 -30 -7 4 10 16 42 30 69 30 78 30 49 0z
m4340 -21 c0 -2 -8 -20 -17 -40 -22 -46 -38 -45 -18 1 8 20 15 38 15 40 0 2 5
4 10 4 6 0 10 -2 10 -5z m-4445 -66 c4 -27 4 -53 1 -56 -3 -4 -48 -7 -99 -7
-91 -1 -94 -2 -149 -39 -55 -39 -56 -39 -88 -22 -18 9 -28 20 -24 25 13 11
327 149 341 150 6 0 14 -23 18 -51z m355 9 c0 -21 -33 -48 -59 -48 -26 1 -26
1 9 30 38 32 50 36 50 18z m15918 -20 c-8 -12 -20 -24 -26 -26 -19 -6 -14 26
6 37 28 17 35 13 20 -11z m726 -13 c-8 -20 -40 -28 -61 -16 -15 8 -16 10 -3
15 8 2 22 7 30 9 28 10 39 7 34 -8z m-548 -59 c10 -8 16 -17 13 -20 -9 -9 -59
11 -59 24 0 14 23 12 46 -4z m-16463 -87 c-12 -12 -26 -17 -36 -13 -25 9 -21
19 16 36 37 18 49 5 20 -23z m-1417 -92 c26 -29 63 -73 83 -99 19 -27 38 -48
42 -48 3 0 11 12 18 28 l13 27 45 -50 c139 -155 243 -330 243 -408 0 -27 12
-76 30 -120 33 -83 35 -99 19 -147 -11 -30 -14 -32 -47 -26 -91 14 -165 100
-268 311 -40 83 -96 186 -124 230 -28 44 -66 109 -85 145 -35 69 -95 231 -95
257 0 20 63 -30 126 -100z m17734 76 c-1 -5 -12 -28 -25 -53 l-24 -45 -1 41
c0 22 5 45 12 52 13 13 38 16 38 5z m-16190 -187 c0 -17 -22 -26 -61 -26 -37
0 -38 18 -1 26 15 3 32 7 37 9 20 7 25 5 25 -9z m44 -104 c4 -7 -54 -42 -69
-42 -15 0 -1 22 23 34 32 18 39 19 46 8z m-178 -72 c9 0 12 -4 9 -10 -8 -12
-52 -13 -59 -1 -8 12 6 22 22 16 7 -3 20 -5 28 -5z m12299 -32 c4 -3 -70 -156
-163 -339 -123 -241 -189 -384 -237 -514 -82 -221 -132 -332 -144 -319 -14 15
-51 123 -72 209 -38 161 -21 241 84 382 22 29 67 118 102 198 67 155 156 300
209 340 55 42 97 56 158 52 32 -2 60 -6 63 -9z m-11019 -219 c48 -80 84 -154
105 -218 48 -144 93 -225 152 -271 l27 -21 17 35 c19 42 29 45 39 14 11 -35 7
-73 -10 -106 -14 -27 -14 -31 0 -36 9 -3 29 -6 45 -6 41 0 36 -13 -16 -38 -51
-25 -51 -25 3 -96 l29 -39 -28 -24 -28 -24 6 -181 c8 -234 12 -229 -135 -146
-157 89 -232 149 -304 246 -25 33 -56 61 -84 74 -24 12 -47 31 -50 42 -4 11
-22 43 -40 71 -41 61 -51 105 -34 145 15 35 25 38 42 10 7 -11 19 -20 26 -20
12 0 13 53 7 317 -4 174 -8 324 -9 332 -1 11 22 24 79 42 44 15 82 25 84 23 2
-2 37 -58 77 -125z m-1496 94 c0 -22 -29 -53 -50 -53 -19 0 -70 37 -70 50 0 5
19 11 43 13 23 2 50 4 60 5 10 1 17 -5 17 -15z m2001 -381 c60 -90 117 -166
134 -178 51 -33 72 -93 90 -254 27 -260 19 -340 -35 -340 -26 0 -33 31 -14 69
20 41 13 68 -31 121 -42 50 -44 79 -10 151 l24 53 -32 19 c-84 50 -138 126
-171 239 -20 71 -38 82 -117 73 -37 -4 -59 -3 -59 3 0 5 29 28 65 51 36 22 65
44 65 49 0 5 -7 29 -15 52 -18 50 -18 50 -6 50 5 0 55 -71 112 -158z m-1525
88 c3 0 -9 -20 -26 -45 -46 -67 -84 -81 -120 -45 -22 22 -19 34 17 92 l23 38
50 -20 c28 -11 53 -20 56 -20z m-11 -175 c0 -5 -15 -11 -32 -13 -32 -3 -45 11
-20 22 19 8 52 2 52 -9z m-85 -85 c0 -12 -28 -25 -36 -17 -9 9 6 27 22 27 8 0
14 -5 14 -10z m20 -71 c0 -26 -3 -29 -31 -29 -30 0 -31 1 -17 23 8 12 18 28
22 35 12 19 26 4 26 -29z m12852 -149 c6 0 -6 -18 -25 -39 -48 -53 -67 -92
-74 -153 -4 -31 -15 -60 -29 -77 l-22 -26 -12 30 c-16 41 -5 85 41 177 38 76
66 103 97 92 8 -2 18 -4 24 -4z m-12791 -33 c66 -44 39 -110 -51 -122 -55 -8
-101 8 -162 56 -46 37 -42 45 35 70 77 25 137 24 178 -4z m12914 -192 c57
-214 64 -361 24 -500 -14 -51 -67 -155 -78 -155 -13 0 -107 104 -126 139 -101
195 -71 437 83 669 l36 54 14 -39 c7 -21 28 -96 47 -168z m345 180 c0 -15 -26
-20 -54 -9 -28 11 -17 24 19 24 24 0 35 -5 35 -15z m6130 -165 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-6740 -181 c0
-19 -37 -69 -51 -69 -5 0 -9 18 -9 39 0 30 6 44 22 55 24 17 38 8 38 -25z
m-12580 -5 c0 -19 -39 -66 -59 -71 -23 -6 -91 22 -91 37 0 5 26 15 58 23 63
17 92 20 92 11z m1635 -26 c-10 -32 -34 -68 -45 -68 -13 0 -13 46 0 71 6 10
20 19 31 19 16 0 19 -4 14 -22z m11572 -82 c-16 -61 -43 -79 -33 -21 11 57 26
89 36 79 6 -6 4 -28 -3 -58z m-11762 4 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10
0 6 4 10 9 10 6 0 13 -4 16 -10z m-3615 -71 c63 -22 119 -44 123 -48 13 -13
-22 -41 -52 -41 -16 0 -48 -3 -73 -6 -44 -6 -45 -6 -57 27 -6 19 -31 54 -54
78 -27 29 -35 41 -22 37 11 -4 72 -25 135 -47z m15234 -98 c-8 -25 -24 -81
-36 -123 -11 -43 -25 -78 -29 -78 -15 0 -59 74 -59 98 0 12 14 58 30 101 l31
79 39 -15 38 -15 -14 -47z m-14828 3 c108 -21 115 -24 84 -36 -57 -22 -290 -3
-274 23 6 10 59 27 90 28 12 1 57 -6 100 -15z m-319 -100 c14 -14 22 -28 19
-31 -7 -7 -96 39 -96 49 0 18 53 6 77 -18z m237 -111 c-26 -19 -59 -26 -87
-19 -23 5 -96 56 -97 67 0 3 46 -3 101 -14 91 -18 100 -22 83 -34z m3692 17
c8 0 14 -6 14 -13 0 -9 -10 -12 -30 -9 -18 3 -30 10 -30 19 0 10 6 13 16 9 9
-3 22 -6 30 -6z m3084 -31 c-88 -79 -110 -93 -123 -80 -9 9 0 24 45 67 46 44
63 54 90 54 l33 -1 -45 -40z m-6279 -69 c-22 -19 -41 -19 -41 0 0 9 22 17 50
19 10 0 7 -6 -9 -19z m13690 -54 c-18 -22 -61 -1 -61 30 0 32 21 36 49 8 20
-19 22 -26 12 -38z m-5961 -83 c8 -35 8 -76 2 -120 l-9 -68 -28 60 c-33 70
-42 133 -25 194 l12 44 19 -29 c10 -16 23 -52 29 -81z m9226 -136 c-131 -368
-231 -602 -273 -640 -18 -16 -64 -27 -175 -42 -76 -10 -101 -39 -138 -156 -18
-58 -30 -116 -28 -137 l3 -37 55 -3 c71 -4 84 -17 37 -37 -20 -8 -37 -17 -37
-20 0 -3 16 -20 37 -38 31 -29 35 -38 29 -62 -10 -41 -67 -88 -167 -140 -169
-87 -1065 -487 -1116 -498 -43 -9 -61 -8 -106 6 -85 27 -177 22 -333 -18 -207
-53 -369 -110 -516 -182 -80 -39 -151 -66 -178 -69 -25 -3 -232 13 -460 35
-458 45 -602 56 -920 71 -559 28 -853 103 -1360 347 l-155 75 -170 172 c-185
189 -250 236 -418 308 -96 42 -130 66 -153 109 -14 27 -48 212 -42 228 1 5 75
-17 163 -49 145 -54 168 -59 242 -59 180 -2 394 71 543 183 36 27 154 140 263
252 109 112 230 227 270 257 92 69 226 152 233 144 3 -3 9 -33 13 -66 5 -41
16 -72 35 -100 25 -36 28 -47 24 -101 -5 -68 -4 -66 -171 -266 -78 -93 -117
-132 -140 -139 -81 -26 -94 -45 -127 -187 -26 -112 -55 -159 -122 -199 -24
-14 -52 -41 -63 -61 -28 -52 -12 -82 63 -114 128 -55 207 -102 386 -230 210
-149 244 -175 331 -252 33 -30 74 -60 92 -68 39 -17 121 -18 160 -3 15 6 104
30 196 55 226 59 306 100 368 191 17 24 56 108 88 188 68 168 113 248 208 365
63 77 73 85 104 85 31 0 39 -8 109 -101 85 -114 155 -180 285 -266 50 -33 110
-80 135 -104 63 -60 124 -84 244 -97 89 -9 120 -7 247 11 108 17 157 20 198
13 105 -17 145 6 301 178 58 63 189 199 292 301 l187 186 37 -19 c63 -32 137
-33 229 -2 192 64 390 199 592 406 138 139 202 183 288 194 28 3 60 8 73 11
l23 5 -22 36 -23 37 128 123 c71 67 133 120 137 117 4 -2 -12 -60 -35 -127z
m-13510 11 c127 -184 280 -324 476 -435 158 -91 332 -133 543 -133 113 0 123
-2 143 -23 33 -35 53 -94 59 -172 10 -135 -41 -240 -163 -333 -38 -29 -211
-143 -384 -254 -624 -399 -682 -444 -797 -621 -83 -127 -83 -127 -300 -127
-286 0 -409 -17 -633 -84 -83 -25 -221 -22 -375 8 -71 15 -224 42 -340 62
-115 19 -264 48 -330 64 -100 24 -597 174 -1230 371 -71 22 -168 59 -215 81
l-85 41 -90 -22 c-99 -23 -244 -28 -335 -11 -113 21 -251 110 -309 197 -17 26
-38 72 -47 102 -9 31 -27 90 -40 131 -13 41 -24 93 -24 114 0 34 6 46 47 83
l46 43 -28 30 c-25 26 -27 33 -17 55 23 51 15 68 -66 146 -42 41 -106 97 -142
124 -52 40 -77 70 -125 150 -60 99 -116 164 -189 219 -21 16 -47 47 -57 70
-19 41 -25 96 -11 96 4 0 36 -25 72 -55 292 -244 410 -299 868 -404 201 -47
209 -48 291 -38 60 7 150 6 316 -3 215 -12 279 -11 485 6 109 9 110 8 110
-164 0 -118 4 -154 30 -259 71 -293 146 -396 331 -459 75 -25 233 -54 301 -54
35 0 37 2 43 38 7 43 4 38 73 128 58 77 134 226 211 414 85 206 110 252 156
277 68 38 69 37 62 -89 -11 -188 -57 -352 -146 -525 -27 -53 -48 -99 -45 -102
3 -3 21 4 41 14 24 13 54 47 87 97 28 43 59 80 68 83 35 12 85 -22 142 -98
137 -182 337 -312 624 -407 149 -49 255 -64 391 -57 159 8 272 45 256 84 -3 7
-7 19 -11 27 -3 9 5 15 24 19 56 11 113 67 159 155 24 46 72 126 108 177 36
52 83 131 104 177 56 123 55 118 20 130 -29 10 -30 11 -27 71 2 34 8 62 14 64
7 2 35 -33 63 -79 l51 -83 0 48 c0 35 -10 67 -36 120 -67 133 -121 196 -209
246 -68 38 -141 120 -199 223 -38 67 -46 89 -41 113 15 73 17 110 5 145 -21
63 -8 85 68 124 37 19 70 35 74 36 3 0 41 -51 84 -112z m9788 -67 c24 -26 17
-65 -11 -69 -21 -3 -23 1 -23 42 0 50 8 56 34 27z m-5464 -132 c14 -50 9 -56
-24 -34 -28 19 -32 35 -15 66 14 26 26 16 39 -32z m-13700 -11 c-1 -7 -7 -24
-15 -38 -14 -24 -14 -24 -15 -3 0 28 10 53 21 53 5 0 9 -6 9 -12z m18384 -119
c-28 -31 -37 -22 -13 13 13 18 25 26 27 19 2 -7 -4 -21 -14 -32z m-9274 -61
c0 -5 -4 -8 -10 -8 -9 0 -40 52 -40 67 1 10 50 -49 50 -59z m-9170 47 c0 -13
-45 -95 -52 -95 -17 0 -7 55 14 77 20 21 38 30 38 18z m18397 -42 c7 -19 -29
-53 -58 -53 -27 0 -23 25 7 49 32 25 43 26 51 4z m-18487 -178 c0 -40 -16 -73
-63 -130 l-25 -30 -7 36 c-7 40 14 102 56 160 26 37 39 25 39 -36z m7660 -40
c0 -24 -5 -35 -15 -35 -16 0 -18 12 -9 48 10 34 24 26 24 -13z m1560 -31 c0
-8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m11570 4
c0 -28 -13 -58 -24 -58 -8 0 -11 12 -8 35 3 21 10 35 18 35 8 0 14 -6 14 -12z
m-2434 -20 c-3 -13 -6 -34 -7 -48 0 -23 -1 -23 -9 -5 -15 35 -12 75 6 75 12 0
15 -6 10 -22z m-8806 -7 c0 -5 -9 -11 -20 -14 -22 -6 -26 3 -8 21 11 11 28 7
28 -7z m9560 -6 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0
4 -7 4 -15z m-9497 -33 c2 -4 0 -23 -6 -42 -8 -30 -10 -32 -17 -15 -4 11 -11
35 -15 53 -7 32 -6 33 13 23 12 -6 23 -14 25 -19z m8723 -97 c-3 -8 -15 -15
-27 -15 l-22 0 24 26 c23 24 37 19 25 -11z m1511 -123 c54 -138 60 -159 49
-177 -9 -13 -27 -21 -53 -23 l-40 -3 -7 73 c-4 52 -15 91 -37 135 -32 63 -33
83 -3 131 8 12 18 22 22 22 4 0 35 -71 69 -158z m-10282 138 c3 -5 -3 -10 -14
-10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m9458 -16 c-4 -10
-9 -12 -15 -6 -12 12 -1 35 12 27 5 -3 6 -13 3 -21z m-1093 -24 c0 -15 -22
-60 -30 -60 -15 0 -18 13 -8 41 9 27 38 41 38 19z m-10360 -8 c0 -13 -12 -22
-22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-7740 -82 c0 -5 -2 -10 -4 -10 -3
0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m3463 -66 c355 -89 374 -98
547 -273 236 -237 378 -431 527 -716 138 -264 245 -407 460 -615 192 -186 267
-240 334 -240 20 0 54 -10 77 -22 49 -27 289 -248 604 -555 124 -121 255 -244
290 -273 78 -64 102 -92 135 -155 14 -27 57 -84 96 -125 38 -41 96 -109 128
-151 44 -58 71 -83 113 -105 55 -28 56 -30 56 -71 0 -74 15 -82 354 -207 115
-43 223 -78 240 -78 49 0 66 -8 76 -34 15 -38 30 -35 343 57 74 22 72 19 51
90 -3 11 40 46 152 124 132 91 168 111 233 130 90 25 106 35 161 97 27 31 56
52 88 63 26 10 78 30 115 46 63 27 75 29 143 23 l74 -7 36 38 35 38 117 -12
c64 -7 132 -15 151 -18 l34 -6 -6 -86 c-3 -47 -15 -122 -26 -166 -24 -93 -108
-270 -153 -320 -27 -31 -35 -35 -82 -35 -28 0 -69 -9 -91 -19 -24 -11 -112
-90 -225 -203 -120 -119 -259 -244 -395 -353 -239 -194 -506 -422 -644 -550
-57 -53 -115 -98 -150 -114 -51 -24 -66 -26 -176 -25 -193 2 -256 33 -271 132
-11 79 28 35 -529 602 -165 168 -352 353 -415 410 -453 409 -531 490 -753 780
-78 102 -196 253 -262 335 -67 83 -169 214 -228 292 -144 192 -229 291 -397
467 -475 498 -1042 1193 -1153 1413 -34 66 -63 172 -82 298 -18 119 -19 165
-4 165 6 0 129 -30 272 -66z m15405 -40 c-17 -24 -35 -44 -40 -44 -4 0 -8 15
-8 34 0 42 43 84 66 65 11 -9 8 -19 -18 -55z m-18904 -26 c5 -21 3 -28 -9 -28
-15 0 -22 40 -8 53 8 9 9 8 17 -25z m8322 -33 c9 -13 18 -44 21 -67 l5 -43
-51 78 c-28 43 -51 80 -51 83 0 12 62 -29 76 -51z m16265 -7 c72 -59 92 -98
86 -169 -4 -49 -19 -82 -108 -240 -150 -269 -206 -356 -347 -535 -350 -444
-623 -745 -1223 -1344 -419 -419 -898 -926 -1031 -1092 -143 -178 -281 -418
-418 -728 -140 -316 -190 -392 -327 -501 -87 -69 -238 -149 -283 -149 -35 0
-268 51 -390 85 -297 83 -601 227 -702 332 -20 21 -52 65 -70 98 l-34 60 -90
-3 c-102 -4 -166 14 -232 66 -39 32 -405 416 -528 555 -47 54 -88 115 -129
195 -88 171 -226 381 -288 439 -87 82 -189 123 -307 123 -77 0 -126 14 -140
40 -6 10 -10 21 -10 24 0 16 111 1 404 -54 375 -71 601 -122 694 -157 104 -39
251 -119 317 -174 115 -94 176 -138 297 -211 115 -69 254 -188 299 -254 18
-27 311 -167 399 -191 85 -23 232 -22 325 2 130 33 267 102 465 235 233 157
462 364 723 655 137 153 311 315 602 560 288 243 660 587 701 650 42 63 61
149 48 220 l-9 48 -77 -104 c-153 -208 -340 -397 -597 -603 -158 -127 -381
-290 -387 -283 -3 2 97 97 223 209 354 318 383 346 489 462 54 60 134 147 177
192 81 86 102 124 135 242 l20 72 -64 0 c-77 0 -79 6 -30 94 48 86 112 166
191 238 68 62 95 106 95 151 0 34 35 62 74 59 29 -3 41 5 102 68 84 87 151
136 314 228 140 79 207 132 307 243 71 80 153 157 188 177 43 25 91 14 146
-30z m-11897 -7 c17 -6 17 -8 3 -14 -8 -3 -21 1 -27 9 -13 15 -6 17 24 5z
m-12624 -41 c0 -11 -7 -20 -15 -20 -13 0 -14 4 -5 20 6 11 13 20 15 20 3 0 5
-9 5 -20z m213 -10 c-3 -11 -9 -20 -14 -20 -5 0 -9 9 -9 20 0 11 6 20 14 20 9
0 12 -7 9 -20z m9257 16 c0 -18 -22 -56 -30 -51 -11 7 8 55 22 55 4 0 8 -2 8
-4z m10685 -36 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-19950 -72 c4 -24 16 -59 26 -78 25 -47 24 -70 -8 -116 -14 -21 -39
-65 -55 -97 -21 -43 -37 -62 -61 -72 -17 -7 -35 -11 -39 -9 -5 3 -8 0 -8 -6 0
-6 7 -13 16 -17 12 -4 3 -17 -38 -57 -29 -29 -66 -75 -81 -104 -27 -49 -27
-52 -8 -52 46 0 52 -11 21 -37 -37 -31 -37 -36 0 -50 17 -6 30 -18 30 -26 0
-14 -147 -447 -154 -454 -2 -2 -16 15 -31 37 -32 47 -45 40 -45 -26 0 -23 -3
-55 -6 -71 -5 -26 -9 -28 -39 -25 -60 7 -62 22 -23 120 20 48 41 114 48 147 7
33 30 109 51 169 22 59 44 140 50 180 10 63 16 76 54 117 54 58 76 101 126
247 22 63 48 131 58 151 10 20 24 54 31 76 12 37 55 95 71 95 4 0 10 -19 14
-42z m-342 -34 c3 -3 -8 -43 -24 -88 -16 -44 -32 -87 -35 -95 -3 -11 -8 -11
-16 -3 -8 8 -2 35 22 101 31 86 39 99 53 85z m19117 -63 c-10 -20 -30 -29 -30
-13 0 4 8 18 17 32 19 27 30 12 13 -19z m-9435 -41 c-3 -5 -10 -10 -16 -10 -5
0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m15308 3 c3 -5 -6 -16 -19 -26
-26 -20 -30 -16 -14 14 11 20 25 25 33 12z m-3813 -13 c0 -12 -40 -33 -48 -25
-4 4 2 13 12 21 23 16 36 18 36 4z m-19230 -20 c0 -15 -65 -83 -98 -103 -21
-12 -33 -14 -37 -6 -8 12 -10 11 63 71 59 48 72 55 72 38z m-1176 -77 c-7 -29
-21 -50 -27 -42 -2 2 0 19 5 37 10 39 31 44 22 5z m8786 -11 c0 -40 -28 -77
-46 -61 -19 15 -18 48 3 71 24 27 43 23 43 -10z m-199 -72 c5 0 9 -4 9 -10 0
-5 -11 -10 -25 -10 -16 0 -25 6 -25 16 0 11 5 14 16 10 9 -3 20 -6 25 -6z
m1209 -59 c0 -4 -12 -13 -26 -19 -26 -12 -26 -7 -1 25 9 13 27 9 27 -6z m-115
-82 c-28 -25 -39 -29 -48 -20 -16 16 25 51 59 51 24 0 23 -1 -11 -31z m15155
5 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z
m-25713 -71 c-1 -1 -26 -11 -55 -22 l-54 -21 7 42 c13 83 12 82 60 41 24 -21
42 -39 42 -40z m777 -15 c-8 -27 -12 -33 -19 -27 -7 8 7 49 17 49 5 0 6 -10 2
-22z m-865 -131 c1 -30 -6 -45 -35 -75 -21 -21 -33 -29 -30 -19 3 10 16 52 28
94 23 79 36 79 37 0z m10341 -13 c0 -19 -175 -214 -192 -214 -14 0 -8 44 11
85 24 52 76 101 126 119 46 17 55 18 55 10z m-10310 -169 c0 -14 -4 -25 -10
-25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m19678 3 c195 -21
202 -22 196 -42 -10 -33 -16 -36 -115 -56 -172 -36 -319 -21 -489 50 -95 39
-102 47 -54 55 22 4 44 8 49 10 32 11 237 3 413 -17z m-19792 -55 c-14 -15
-21 6 -12 37 l10 35 4 -33 c2 -19 1 -36 -2 -39z m2681 -25 c17 -18 48 -67 68
-108 21 -41 53 -100 73 -130 20 -31 192 -213 397 -420 374 -377 393 -399 636
-740 188 -262 187 -260 166 -275 -10 -7 -50 -23 -90 -36 -64 -21 -90 -24 -227
-23 -243 1 -452 49 -656 152 -289 146 -453 325 -793 866 -118 188 -122 205
-61 277 43 51 40 59 -21 59 -27 0 -29 2 -29 41 0 51 21 96 65 139 l34 33 76
-16 c42 -10 91 -17 111 -17 45 0 79 33 87 85 7 45 87 145 116 145 9 0 31 -15
48 -32z m187 -141 c6 -9 7 -19 3 -23 -9 -10 -48 26 -41 38 8 12 25 6 38 -15z
m-2464 3 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
-10z m-211 -231 c9 -27 9 -30 -4 -19 -8 7 -15 21 -15 31 0 28 7 23 19 -12z
m-289 -8 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m1159 -143
c5 -14 5 -28 -1 -35 -11 -14 -68 -18 -68 -6 0 7 51 63 57 63 2 0 7 -10 12 -22z
m25153 -45 c37 -41 49 -85 55 -208 7 -132 -7 -196 -68 -317 -57 -113 -76 -172
-94 -292 -35 -237 -39 -245 -140 -292 -240 -109 -527 -166 -740 -144 -152 16
-323 73 -339 115 -10 28 -6 79 9 105 22 39 19 46 -37 67 -51 20 -53 22 -61 68
-6 38 -4 55 13 89 21 44 131 196 141 196 4 0 14 -21 24 -47 l18 -47 40 45 c22
24 81 96 132 159 144 181 194 221 417 337 205 106 239 120 388 154 52 12 102
25 110 30 8 5 36 9 61 9 37 0 50 -5 71 -27z m-618 -51 c-14 -22 -61 -49 -70
-40 -3 4 7 25 22 47 26 35 31 38 44 25 11 -11 12 -19 4 -32z m-18024 -37 c-14
-16 -40 -20 -40 -6 0 10 17 19 39 20 11 1 11 -2 1 -14z m17918 -37 c-16 -25
-84 -68 -107 -68 -21 0 37 59 74 76 43 19 49 17 33 -8z m-4718 -137 c0 -5 -11
-19 -25 -30 -22 -16 -31 -18 -56 -9 -35 12 -35 13 17 32 47 17 64 19 64 7z
m-10880 -457 c0 -14 -28 -39 -36 -31 -3 3 -2 22 2 43 6 34 8 36 20 19 8 -10
14 -24 14 -31z m-8500 -202 c0 -11 -52 -85 -69 -99 -17 -13 -69 -17 -95 -7
-12 5 -5 15 32 44 71 56 132 85 132 62z m-74 -232 c-28 -42 -56 -48 -34 -7 27
52 41 68 51 58 7 -7 1 -23 -17 -51z m641 -125 c60 -32 195 -109 300 -172 212
-126 261 -169 334 -294 22 -38 70 -100 107 -137 147 -147 409 -390 438 -405
16 -8 63 -22 104 -31 72 -15 76 -15 102 4 33 24 34 24 66 2 51 -36 98 -44 292
-53 433 -19 507 -57 839 -437 145 -167 238 -291 320 -426 37 -61 81 -121 103
-139 59 -48 118 -107 118 -117 0 -5 -19 -3 -42 6 -22 8 -43 12 -45 10 -7 -7
39 -66 51 -66 5 0 18 7 28 17 17 15 19 15 39 -3 32 -29 108 -138 120 -173 17
-49 3 -93 -63 -193 -33 -51 -71 -119 -85 -153 -66 -162 -96 -208 -147 -225
-37 -12 -73 -2 -173 48 -57 29 -61 33 -67 74 -10 60 -30 87 -84 110 -26 11
-54 26 -62 33 -8 8 -76 44 -150 82 -160 82 -317 176 -592 354 -112 73 -268
167 -348 209 -267 141 -477 272 -670 418 -196 148 -313 213 -500 277 -52 18
-122 44 -154 59 -158 71 -348 234 -471 405 -33 46 -107 148 -165 225 -114 153
-149 221 -180 343 -34 131 -36 175 -10 227 43 89 114 126 327 166 149 29 192
23 320 -45z m-807 4 c0 -25 -11 -49 -21 -49 -5 0 -9 24 -9 53 1 48 2 50 15 33
8 -11 15 -28 15 -37z m3475 -68 c3 -5 -9 -20 -27 -32 -31 -22 -31 -12 0 24 17
20 19 20 27 8z m6065 -91 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10
20 10 11 0 20 -4 20 -10z m1311 -89 c-11 -50 -33 -85 -66 -107 -36 -23 -39
-13 -15 38 21 45 79 122 86 115 3 -2 0 -23 -5 -46z m-1396 -35 c-15 -32 -103
-111 -112 -102 -11 10 33 75 75 113 51 46 62 43 37 -11z m585 -128 c0 -55 -5
-81 -20 -105 -11 -18 -23 -33 -26 -33 -7 0 4 174 13 196 3 8 11 14 19 14 11 0
14 -17 14 -72z m-530 5 c0 -46 -17 -90 -45 -113 -27 -22 -28 -4 -5 84 17 62
26 83 36 79 9 -3 14 -21 14 -50z m16534 25 c50 -51 76 -160 76 -322 0 -93 -4
-135 -15 -156 -30 -59 -372 -346 -604 -508 -69 -48 -193 -130 -276 -183 -84
-53 -196 -127 -251 -166 -120 -85 -215 -140 -539 -313 -668 -356 -906 -486
-973 -534 -78 -57 -368 -314 -553 -493 -127 -121 -162 -140 -229 -123 -19 5
-62 16 -95 25 -43 11 -83 14 -140 10 -80 -6 -81 -6 -172 39 -50 24 -96 53
-101 63 -5 10 -13 40 -17 68 -9 66 -43 130 -106 202 -72 83 -83 106 -83 192
-1 56 3 75 14 81 145 69 173 93 237 210 58 104 68 115 145 155 227 118 404
288 503 485 55 109 64 113 218 121 73 4 159 14 192 23 88 24 227 95 339 176
188 134 307 176 486 170 63 -2 228 -4 365 -4 246 0 251 0 330 26 99 34 119 48
232 173 85 94 103 108 299 231 461 290 624 384 668 384 10 0 33 -15 50 -32z
m-15306 -19 c-4 -30 -35 -49 -48 -30 -12 21 6 51 31 51 15 0 20 -5 17 -21z
m-7645 -33 c23 -13 59 -29 79 -36 41 -12 88 -61 88 -90 0 -35 -38 -30 -86 11
-25 21 -52 39 -59 39 -30 0 -125 54 -125 71 0 35 47 37 103 5z m6225 -32 c-10
-37 -11 -38 -21 -16 -8 17 -6 26 8 42 24 27 27 22 13 -26z m-6455 -71 c21 -12
3 -23 -30 -18 -55 9 -65 14 -58 25 6 10 67 5 88 -7z m7771 -39 c37 -37 11 -86
-197 -362 -110 -145 -310 -441 -394 -582 -28 -47 -98 -173 -155 -280 -93 -174
-203 -360 -214 -360 -5 0 4 49 23 129 26 108 68 197 202 421 71 118 170 287
221 375 129 223 163 272 289 415 60 69 122 148 137 175 29 54 53 85 65 85 4 0
15 -7 23 -16z m10780 -121 c-13 -10 -20 -10 -23 -2 -4 14 19 30 33 22 5 -3 1
-12 -10 -20z m5398 -60 c14 -16 31 -38 37 -50 10 -20 9 -23 -13 -29 -17 -4
-35 1 -54 15 -48 34 -53 44 -36 69 20 29 35 28 66 -5z m-18881 -130 c-34 -66
-222 -226 -242 -206 -7 7 36 54 123 136 73 70 134 123 136 118 2 -5 -6 -27
-17 -48z m13497 35 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z
m-298 -137 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m5748 -69
c-10 -12 -14 -11 -25 4 -10 13 -10 21 -1 32 10 12 14 11 25 -4 10 -13 10 -21
1 -32z m-27888 -647 c8 -9 8 -15 2 -15 -18 0 -42 12 -42 21 0 14 26 10 40 -6z
m27936 -20 c31 -31 30 -72 -1 -67 -29 5 -44 22 -51 60 -5 26 -3 32 10 32 9 0
28 -11 42 -25z m-27854 -50 c4 -17 1 -25 -8 -25 -8 0 -14 11 -14 25 0 14 4 25
9 25 5 0 11 -11 13 -25z m26918 16 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37 5
0 9 -4 9 -9z m-6395 -31 c4 -6 -8 -10 -30 -10 -22 0 -34 4 -30 10 3 6 17 10
30 10 13 0 27 -4 30 -10z m-20600 -32 c-11 -51 -23 -78 -35 -78 -13 0 -13 10
2 65 14 53 44 65 33 13z m20799 -32 c71 -20 131 -37 133 -40 2 -2 -21 -16 -51
-31 -64 -31 -133 -36 -169 -12 -26 17 -60 67 -72 105 -6 20 -4 23 12 18 10 -3
76 -21 147 -40z m6239 -3 c8 -7 -21 -43 -35 -43 -10 0 -11 34 -1 43 8 9 28 9
36 0z m930 -64 c-17 -17 -37 -1 -22 17 8 10 15 12 23 4 7 -7 7 -13 -1 -21z
m-6310 -20 c-11 -11 -63 5 -63 19 0 19 14 21 42 6 19 -10 27 -19 21 -25z
m-11083 -153 c0 -17 -33 -76 -40 -71 -10 6 19 75 32 75 4 0 8 -2 8 -4z m16785
-256 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m740 -56 c3 -20 4 -39 0 -42 -9 -10 -24 12 -31 46 -5 25 -3 32 9 32 9 0 17
-14 22 -36z m-6731 -85 c-9 -10 -21 -16 -26 -13 -5 3 -2 14 8 25 9 10 21 16
26 13 5 -3 2 -14 -8 -25z m-17185 -188 c7 -5 9 -11 5 -14 -10 -5 -54 9 -54 18
0 8 34 6 49 -4z m203 -63 c3 -29 9 -35 46 -48 23 -8 42 -17 42 -21 0 -9 -48
-29 -71 -29 -11 0 -45 23 -75 51 -62 60 -60 79 12 79 41 0 43 -1 46 -32z
m-3632 -24 c81 -20 232 -92 284 -135 61 -51 65 -71 56 -254 -9 -177 -26 -274
-72 -403 -54 -153 -122 -270 -242 -412 -39 -47 -84 -102 -99 -123 -32 -42 -74
-67 -114 -67 -28 0 -241 57 -298 80 -47 18 -73 48 -84 99 -21 93 -34 250 -33
395 1 97 -2 152 -9 156 -7 5 -2 88 16 271 14 145 28 276 31 292 9 46 49 84
102 96 138 33 340 36 462 5z m1060 11 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26
6 0 6 -6 -2 -15z m26286 -120 c6 -22 14 -54 17 -71 6 -30 4 -32 -31 -38 -20
-4 -50 -9 -66 -12 -26 -6 -28 -4 -23 15 3 14 -3 31 -19 51 -20 24 -23 33 -14
49 17 33 52 52 90 49 31 -3 35 -7 46 -43z m-21976 26 c0 -14 -58 -70 -122
-118 -104 -79 -186 -210 -230 -368 -20 -72 -22 -104 -23 -313 0 -127 -4 -232
-8 -232 -19 0 -108 103 -135 156 -62 124 -77 188 -77 324 0 121 1 127 33 192
52 106 129 170 324 269 188 95 238 114 238 90z m20405 -132 c4 -5 -10 -9 -29
-9 -38 0 -56 20 -56 60 0 20 2 20 40 -10 22 -17 42 -36 45 -41z m-5010 11
c-13 -15 -22 -17 -29 -10 -12 12 8 30 33 30 11 0 10 -5 -4 -20z m350 7 c10 -8
15 -19 11 -25 -16 -26 -186 -57 -186 -34 0 4 21 22 47 40 51 34 97 41 128 19z
m-15409 -42 c170 -28 224 -47 285 -102 58 -52 163 -194 199 -267 46 -96 60
-166 71 -378 9 -188 9 -199 -10 -236 -32 -62 -114 -128 -233 -187 -149 -73
-208 -83 -364 -61 -321 46 -436 96 -509 220 -55 94 -133 450 -122 556 18 168
167 393 307 464 51 26 61 27 140 22 47 -3 153 -17 236 -31z m21614 -107 c0
-26 -9 -22 -59 20 -36 32 -42 42 -36 61 7 23 8 22 51 -20 24 -24 44 -51 44
-61z m-173 33 c49 -22 107 -44 129 -50 42 -11 42 -12 53 -74 23 -141 33 -244
27 -300 -6 -62 7 -111 37 -136 22 -18 23 -163 2 -246 -16 -61 -113 -275 -125
-275 -4 0 -15 9 -25 20 -10 11 -23 20 -29 20 -6 0 -54 -77 -106 -170 -52 -94
-99 -170 -105 -170 -24 0 -302 84 -348 106 -32 14 -71 24 -99 24 -67 0 -108
38 -144 133 -34 93 -106 228 -155 293 -46 60 -69 112 -69 154 0 34 20 79 45
100 14 12 14 16 -4 46 l-20 34 65 102 c84 133 124 181 180 214 l45 26 -26 34
c-19 25 -22 35 -13 38 7 2 103 10 213 16 197 12 200 12 230 39 81 75 117 78
242 22z m-28040 -58 c4 -34 2 -43 -18 -57 -13 -9 -27 -16 -31 -16 -14 0 -8 70
8 102 19 37 33 27 41 -29z m22056 43 c26 -11 41 -31 85 -120 99 -198 225 -337
442 -486 63 -44 173 -129 243 -189 120 -101 154 -125 217 -149 25 -9 25 -9
-17 -30 -50 -26 -566 -223 -778 -297 -82 -29 -178 -65 -211 -79 -109 -46 -321
-78 -408 -62 -57 11 -108 66 -175 184 -80 145 -138 284 -166 400 -75 310 -74
297 -24 419 16 39 29 79 29 87 0 37 45 129 85 173 86 97 211 139 445 152 80 4
158 9 173 9 15 1 42 -4 60 -12z m-11895 -202 c-26 -39 -38 -43 -38 -13 0 23
15 55 39 83 15 18 16 17 19 -9 2 -16 -6 -40 -20 -61z m-10198 -69 c-7 -8 -17
-12 -22 -9 -5 3 -1 15 9 26 20 22 32 6 13 -17z m8440 17 c0 -15 -88 -94 -94
-84 -7 12 61 92 80 92 8 0 14 -4 14 -8z m1720 -6 c0 -1 -5 -18 -12 -36 -10
-29 -12 -31 -18 -14 -8 21 5 54 20 54 6 0 10 -2 10 -4z m-8771 -236 c1 -110
-16 -201 -53 -283 l-25 -58 -33 17 c-18 9 -34 18 -36 19 -2 2 6 21 17 43 62
122 100 272 102 404 l1 83 14 -65 c7 -36 13 -108 13 -160z m6741 43 l0 -46
-23 21 c-23 22 -50 86 -40 96 3 3 18 -1 34 -10 26 -13 29 -20 29 -61z m-5948
-86 c180 -145 239 -187 413 -298 165 -105 203 -121 266 -118 46 3 52 0 78 -32
34 -40 46 -45 268 -104 238 -62 339 -104 708 -290 443 -225 612 -295 922 -386
59 -17 167 -56 238 -86 168 -70 640 -236 876 -307 158 -48 215 -71 404 -166
121 -60 287 -140 369 -177 99 -45 156 -77 169 -95 14 -18 22 -51 27 -105 5
-43 11 -109 15 -148 6 -63 5 -73 -17 -105 -58 -88 -199 -119 -578 -127 -345
-7 -601 25 -881 112 -113 35 -220 62 -579 150 -102 25 -300 81 -440 126 -1951
618 -1927 610 -2154 743 -160 94 -452 322 -510 398 -14 19 -38 65 -54 104 -15
39 -49 100 -75 136 -26 36 -47 71 -47 77 0 6 41 66 91 133 110 147 179 249
206 303 24 47 75 203 98 295 19 79 23 90 30 90 2 0 73 -55 157 -123z m7802
-71 c-16 -26 -34 -34 -34 -17 0 4 10 21 22 36 26 34 37 17 12 -19z m-2339 4
c28 -31 13 -103 -34 -153 -42 -44 -63 -45 -83 -3 -9 21 -7 30 8 52 31 43 33
47 48 87 17 41 34 47 61 17z m-7606 -102 l0 -73 -16 32 c-14 27 -14 39 -4 73
6 22 14 40 16 40 3 0 5 -33 4 -72z m26455 51 c44 -35 143 -214 205 -374 108
-278 251 -672 251 -693 0 -14 -11 -27 -30 -37 -57 -29 -432 -96 -735 -130
-279 -31 -332 -53 -806 -338 -198 -119 -466 -269 -585 -329 -71 -35 -103 -44
-235 -65 -186 -29 -214 -36 -449 -123 -211 -78 -345 -116 -381 -107 -13 4 -49
25 -79 48 -30 23 -65 45 -78 50 -37 14 -124 10 -177 -7 -40 -14 -56 -15 -97
-5 -27 6 -89 11 -138 11 -152 0 -281 -42 -940 -307 -346 -140 -404 -156 -493
-139 -29 5 -38 13 -50 44 -8 20 -18 59 -22 87 -8 60 -26 97 -59 123 l-25 19
138 115 c136 113 138 115 199 121 85 9 139 40 265 149 77 68 123 116 158 171
52 79 104 124 194 170 117 59 472 203 620 253 250 83 370 102 785 124 282 15
313 21 551 103 208 72 562 207 556 213 -1 2 -81 -22 -177 -52 -96 -29 -181
-51 -189 -49 -21 8 202 132 251 140 24 4 45 16 59 33 11 15 61 68 110 119 l89
93 51 -12 c70 -16 129 -2 269 65 132 64 260 142 446 274 119 84 136 93 177 93
40 0 55 7 129 62 152 113 191 127 242 87z m-26570 -5 c11 -27 7 -37 -9 -24
-16 14 -20 40 -6 40 5 0 12 -7 15 -16z m7636 -190 c0 -24 -4 -34 -12 -32 -18
6 -22 36 -8 53 18 21 20 19 20 -21z m10 -91 c0 -4 -19 -35 -41 -70 l-41 -63
22 -18 22 -18 -65 -28 c-35 -15 -75 -38 -87 -52 -22 -22 -22 -27 -11 -65 7
-23 15 -47 17 -53 6 -15 -74 -48 -113 -48 -14 0 -37 11 -51 25 l-26 26 55 53
c35 33 66 75 83 113 59 130 71 150 105 169 42 24 131 44 131 29z m-1610 -100
c0 -21 -17 -23 -40 -5 -18 15 -22 24 -16 47 l7 29 24 -30 c13 -16 24 -34 25
-41z m230 -183 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z
m21324 -41 c31 -12 56 -25 56 -28 0 -3 -14 -14 -31 -24 -30 -17 -31 -20 -26
-62 6 -42 5 -45 -16 -45 -37 0 -67 27 -67 61 0 29 19 119 25 119 2 0 28 -10
59 -21z m144 -34 c2 -1 4 -15 5 -31 2 -25 -2 -29 -28 -32 -27 -3 -32 1 -43 31
-7 18 -12 37 -12 41 0 7 70 -1 78 -9z m-72 -101 c-30 -21 -45 -16 -16 6 14 10
27 16 30 13 3 -2 -4 -11 -14 -19z m-21897 -540 c2 -2 1 -10 -2 -18 -5 -14 -11
-13 -44 6 -72 40 -84 49 -78 58 4 7 105 -31 124 -46z m22528 -160 c3 -8 -2
-42 -12 -75 -14 -45 -22 -58 -34 -55 -9 3 -34 8 -56 12 -48 8 -77 22 -73 35 4
12 139 97 156 98 7 1 15 -6 19 -15z m-22199 -13 c3 -8 -1 -12 -9 -9 -7 2 -15
10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m19632 -156 c0 -3 -27 -18 -60
-35 -65 -33 -78 -27 -29 14 26 22 89 37 89 21z m-19071 -81 c2 -2 2 -7 -2 -10
-8 -9 -47 6 -47 17 0 9 37 3 49 -7z m18902 -13 c-14 -10 -37 -26 -52 -35 -23
-14 -28 -14 -32 -1 -9 21 40 55 77 55 l31 0 -24 -19z m2434 -61 c17 -18 16
-19 -10 -26 -30 -7 -51 7 -42 30 9 22 30 20 52 -4z m-2334 -43 c-29 -29 -36
-16 -9 18 14 18 23 24 26 16 2 -7 -5 -22 -17 -34z m-88 -51 c-15 -13 -30 -22
-32 -20 -7 7 31 44 46 44 8 0 2 -10 -14 -24z m2337 4 c16 -11 48 -27 72 -35
24 -9 49 -18 57 -20 11 -4 9 -11 -9 -30 -13 -14 -30 -25 -37 -25 -41 0 -101
55 -117 108 -9 27 -2 28 34 2z m-2531 -103 c-2 -2 -21 -13 -41 -25 -38 -21
-38 -21 -38 -1 0 23 59 54 74 38 5 -5 8 -10 5 -12z m-17824 -66 c10 -17 -65
-191 -82 -191 -13 0 -15 12 -11 73 8 100 20 127 57 127 16 0 32 -4 36 -9z
m16590 -160 c-8 -13 -45 -15 -45 -2 0 5 10 11 23 13 27 5 31 3 22 -11z m3555
-43 l-1 -33 -14 25 c-18 32 -18 40 0 40 10 0 15 -10 15 -32z m-20480 -28 c0
-18 -5 -30 -13 -30 -9 0 -12 10 -9 30 2 17 8 30 13 30 5 0 9 -13 9 -30z
m20470 -37 c0 -10 -8 -35 -18 -56 l-17 -39 -28 22 c-15 12 -27 27 -27 34 0 17
29 43 55 49 34 8 35 8 35 -10z m180 -110 c0 -2 -5 -24 -11 -47 -9 -36 -14 -43
-30 -39 -23 6 -23 12 -3 62 10 27 20 38 30 34 8 -3 14 -7 14 -10z m-26340
-159 c52 -8 185 -35 295 -59 110 -24 286 -59 390 -76 181 -30 195 -31 301 -20
61 6 132 11 158 11 64 0 264 -49 468 -115 214 -69 518 -146 913 -230 622 -134
743 -168 949 -272 274 -137 603 -261 924 -347 73 -20 152 -46 174 -57 44 -22
170 -144 218 -211 l29 -40 -19 -38 c-26 -51 -54 -66 -172 -95 -262 -64 -440
-80 -858 -80 -488 1 -687 26 -1172 146 -156 38 -449 108 -653 155 -459 106
-576 135 -690 174 -95 32 -583 175 -995 291 -363 102 -417 112 -656 114 -217
3 -234 6 -286 62 -15 15 -64 87 -109 158 -176 279 -179 285 -179 332 0 80 37
99 270 141 63 12 149 31 190 42 148 41 307 46 510 14z m18496 -121 c-38 -75
-486 -511 -546 -531 -34 -12 -35 -11 -16 26 20 38 579 579 583 565 2 -6 -7
-33 -21 -60z m6409 0 c50 3 142 15 205 28 170 33 465 32 760 -4 199 -25 459
-46 556 -47 41 0 41 0 47 -41 13 -102 -27 -318 -89 -482 -45 -117 -175 -397
-184 -397 -4 0 -15 11 -24 25 -25 38 -75 65 -121 65 -45 0 -282 -78 -516 -169
-173 -67 -168 -66 -778 -156 -172 -25 -319 -44 -327 -41 -7 3 -16 22 -20 43
-4 21 -11 46 -16 56 -11 22 -38 22 -38 -1 0 -10 -7 -28 -16 -40 -14 -20 -23
-22 -127 -22 -178 0 -283 -21 -597 -120 -165 -52 -268 -80 -294 -80 -12 0 -15
6 -11 23 5 18 1 26 -20 35 -40 18 -71 15 -268 -29 -100 -22 -276 -60 -392 -85
-652 -139 -858 -188 -1035 -247 -151 -50 -197 -61 -250 -61 -109 1 -196 58
-241 159 -45 101 -24 204 85 420 34 67 70 142 79 167 20 51 48 81 92 98 45 18
410 49 571 50 291 0 465 62 782 277 187 127 219 143 568 272 331 122 432 153
791 242 l281 69 129 -6 c134 -7 169 0 202 40 10 12 19 10 69 -16 54 -28 62
-29 147 -25z m-17559 -204 c53 -35 73 -54 66 -61 -13 -13 -59 4 -98 34 -23 19
-61 78 -49 78 3 0 40 -23 81 -51z m-5121 -21 c25 -11 34 -28 14 -28 -17 0 -49
20 -49 30 0 13 4 12 35 -2z m25335 -88 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m-20190 -55 l25 -14 -37 -1 c-36 0 -47 8 -31
23 9 9 14 8 43 -8z m478 -139 c15 -8 65 -53 111 -100 82 -83 84 -85 58 -91
-50 -13 -164 84 -191 163 -17 47 -16 48 22 28z m19954 -46 c-3 -32 -8 -40 -24
-40 -15 0 -18 5 -13 23 4 12 9 30 11 40 10 35 30 18 26 -23z m-20398 -19 c-9
-10 -36 28 -29 39 6 9 12 6 21 -11 7 -13 10 -26 8 -28z m10516 -9 c0 -34 -91
-130 -186 -198 -49 -35 -70 -44 -104 -44 -32 0 -41 3 -38 15 3 15 305 254 321
255 4 0 7 -13 7 -28z m-10417 -181 c65 -69 97 -134 97 -195 0 -11 20 -63 44
-115 55 -120 62 -141 43 -141 -8 0 -32 13 -55 30 -116 84 -232 315 -232 462 0
44 2 48 18 39 10 -5 48 -41 85 -80z m10831 -174 l26 -118 -31 -47 c-18 -26
-53 -72 -78 -102 -73 -87 -320 -333 -381 -378 -48 -36 -168 -164 -264 -282
-29 -36 -73 -66 -83 -56 -9 8 64 135 122 212 29 39 101 115 160 170 90 84 112
111 145 176 21 43 42 99 46 125 6 41 26 70 157 235 82 104 151 188 152 186 2
-2 15 -56 29 -121z m-10146 90 c15 -12 16 -42 1 -70 -11 -20 -13 -18 -35 24
-13 24 -23 49 -24 56 0 12 37 6 58 -10z m19532 -37 c0 -22 -4 -40 -10 -40 -5
0 -10 18 -10 40 0 22 5 40 10 40 6 0 10 -18 10 -40z m-20512 -134 l-3 -56 -37
25 -37 25 26 46 c22 39 28 44 40 31 10 -9 13 -31 11 -71z m-398 29 c-7 -14
-15 -23 -17 -22 -2 2 0 18 3 36 5 22 10 29 17 22 7 -7 6 -18 -3 -36z m20664
-36 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z
m-754 -18 c0 -12 -29 -23 -41 -15 -5 3 -7 10 -4 15 8 12 45 12 45 0z m-19250
-158 c41 -43 127 -141 190 -218 63 -77 141 -171 174 -210 83 -97 168 -230 279
-437 l95 -178 -53 0 c-58 0 -107 14 -147 42 l-27 19 -26 -39 c-24 -37 -30 -40
-88 -46 -158 -18 -228 -111 -197 -261 7 -32 26 -80 42 -107 39 -63 39 -91 -2
-133 l-33 -34 17 -108 c36 -242 32 -295 -28 -360 l-33 -36 -124 -10 c-68 -5
-157 -10 -196 -11 -65 -1 -75 2 -98 24 -31 31 -29 6 -21 490 5 272 14 448 26
565 11 94 37 384 60 645 23 261 45 485 51 498 l9 23 28 -20 c15 -10 61 -55
102 -98z m9791 -91 c-43 -48 -91 -98 -106 -112 -27 -25 -27 -25 7 44 29 57 46
78 99 116 35 26 68 45 72 43 4 -3 -28 -44 -72 -91z m-17168 13 c38 -8 146 -39
240 -70 129 -43 179 -64 201 -85 l29 -29 -154 0 c-142 0 -159 -2 -216 -26 -78
-31 -129 -32 -174 -2 -37 25 -159 165 -159 183 0 18 70 43 120 43 25 0 76 -6
113 -14z m6372 -421 c-36 -208 -86 -486 -111 -617 -25 -131 -49 -284 -53 -341
-8 -97 -32 -173 -60 -190 -15 -10 -24 116 -17 237 19 306 39 539 52 607 19 96
40 165 125 430 54 165 76 220 96 238 14 13 27 21 29 19 2 -2 -25 -174 -61
-383z m-1327 364 c16 -16 15 -25 -3 -32 -27 -10 -46 4 -34 25 11 22 20 24 37
7z m18052 -39 c0 -20 -15 -26 -24 -10 -8 13 4 42 15 35 5 -3 9 -14 9 -25z
m-116 -39 c-21 -22 -108 -42 -89 -22 10 11 80 40 100 41 5 1 0 -8 -11 -19z
m-214 -40 c0 -5 -10 -12 -22 -14 -140 -30 -148 -30 -148 -12 0 9 20 17 58 24
78 13 112 14 112 2z m-15730 -192 c0 -46 -8 -68 -26 -68 -17 0 -17 13 -3 81
13 58 29 51 29 -13z m18710 12 c-16 -30 -53 -44 -86 -33 -27 9 -26 9 27 30 68
27 72 27 59 3z m-6529 -137 c34 -79 51 -166 52 -258 0 -91 -19 -225 -33 -225
-4 0 -24 63 -45 140 -35 133 -37 144 -30 242 5 80 3 117 -9 164 -9 34 -16 66
-16 70 0 14 60 -85 81 -133z m-12691 76 c0 -44 -2 -50 -15 -39 -24 20 -28 50
-9 71 9 10 19 19 21 19 1 0 3 -23 3 -51z m10460 36 c0 -36 -81 -135 -110 -135
-16 0 -11 33 8 63 27 40 102 93 102 72z m-9990 -225 c0 -27 -4 -50 -10 -50 -5
0 -10 23 -10 50 0 28 5 50 10 50 6 0 10 -22 10 -50z m10376 -107 c-15 -21 -26
-31 -26 -22 0 18 40 73 47 65 3 -2 -7 -22 -21 -43z m-176 -2 c0 -15 -38 -56
-45 -49 -8 8 23 58 36 58 5 0 9 -4 9 -9z m84 -150 c-26 -45 -51 -84 -56 -87
-17 -11 -19 25 -3 56 18 35 95 123 102 116 2 -2 -17 -41 -43 -85z m-15497 -35
c57 -18 111 -25 203 -29 135 -6 327 -40 457 -82 43 -14 90 -25 106 -25 15 0
27 -4 27 -10 0 -16 -61 -12 -153 10 -177 42 -282 51 -682 60 -214 4 -400 11
-413 15 -31 10 -24 25 29 53 41 22 51 23 144 18 59 -3 106 -2 115 4 25 16 85
11 167 -14z m6368 -135 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
m-7590 -16 c452 -29 564 -42 810 -100 144 -33 303 -41 475 -25 267 26 463 12
706 -49 66 -16 217 -46 336 -66 300 -49 368 -72 618 -211 242 -134 611 -274
873 -330 l127 -28 0 -89 0 -88 -42 5 c-24 4 -115 13 -203 22 -88 8 -236 31
-330 50 -219 45 -476 88 -690 116 -130 17 -220 22 -385 22 -330 0 -489 -25
-810 -127 l-165 -52 -145 1 c-157 1 -299 19 -506 64 -117 25 -145 28 -246 23
-138 -7 -199 3 -393 64 -155 49 -146 47 -197 38 -50 -10 -84 0 -104 31 -16 23
-16 27 -1 41 14 14 21 12 81 -21 68 -38 82 -37 45 3 -24 25 -59 41 -94 41 -15
0 -26 7 -30 20 -4 11 -19 27 -34 34 -69 36 -122 126 -151 258 -12 52 -11 59 8
90 12 19 30 42 41 52 18 17 21 15 76 -53 32 -39 62 -71 67 -71 12 0 7 14 -62
192 l-57 147 31 4 c63 8 116 7 351 -8z m26143 -47 c-3 -7 -11 -13 -18 -13 -7
0 -15 6 -17 13 -3 7 4 12 17 12 13 0 20 -5 18 -12z m-23630 -24 c35 -10 26
-26 -10 -19 -39 8 -61 25 -32 25 10 0 29 -3 42 -6z m20512 -66 c63 -5 405 -13
760 -17 718 -9 691 -6 847 -81 94 -44 133 -78 133 -114 0 -20 -254 -731 -266
-745 -1 -2 -26 16 -53 40 l-51 42 -53 -26 c-59 -30 -105 -33 -252 -16 -49 5
-205 14 -345 18 -239 9 -268 12 -460 49 -305 61 -454 75 -705 69 -230 -5 -341
-22 -522 -78 -96 -30 -101 -30 -243 -24 -80 3 -355 15 -613 25 -428 18 -471
22 -507 40 -34 17 -40 25 -40 52 0 43 20 105 39 122 26 23 418 182 609 247
216 73 367 145 490 234 148 106 200 123 467 150 330 34 502 37 765 13z
m-20182 -57 c46 -24 118 -96 104 -105 -11 -7 -221 104 -222 117 0 15 82 6 118
-12z m132 9 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
15 -10z m960 -4 c0 -7 -17 -32 -37 -56 -36 -40 -39 -41 -65 -29 -16 7 -28 18
-28 24 0 19 34 44 68 51 18 4 35 11 38 15 8 14 24 11 24 -5z m22130 -36 c0 -5
-7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-22113
-72 c28 -31 55 -86 48 -98 -3 -6 -21 -10 -40 -10 -28 0 -39 7 -64 42 -45 61
-40 82 20 87 8 0 25 -9 36 -21z m-818 -53 c25 -12 47 -52 36 -63 -4 -5 -92 54
-100 68 -9 15 32 12 64 -5z m13059 -24 c11 -6 11 -10 0 -19 -16 -13 -44 1 -34
17 8 13 16 14 34 2z m-12236 -58 c10 -9 18 -24 18 -34 0 -14 -16 -61 -26 -77
-1 -2 -16 4 -34 13 -33 17 -44 36 -55 102 l-7 43 43 -15 c24 -9 51 -23 61 -32z
m-648 -9 c4 -9 4 -19 2 -21 -5 -6 -106 26 -106 33 0 2 22 4 49 4 36 0 51 -4
55 -16z m157 -40 c14 -7 31 -20 38 -28 23 -28 -65 -20 -129 11 -52 24 -53 26
-30 33 30 10 86 2 121 -16z m15133 -214 c26 -9 26 -11 26 -113 0 -112 -17
-189 -50 -229 l-20 -23 6 50 c3 28 7 56 10 63 7 23 -14 12 -44 -23 -47 -52
-78 -111 -117 -219 -19 -54 -40 -107 -46 -117 -19 -36 -30 -9 -23 57 l7 64
-40 0 c-67 0 -70 13 -32 140 17 62 39 120 48 131 16 19 17 19 53 -6 20 -14 39
-25 42 -25 3 0 35 59 71 130 36 72 69 130 74 130 6 0 21 -4 35 -10z m-2948
-235 c13 -19 15 -36 9 -72 l-8 -48 -12 40 c-22 73 -27 105 -16 105 6 0 18 -11
27 -25z m591 -30 c-4 -8 -17 -15 -29 -15 l-23 1 24 20 c26 21 37 19 28 -6z
m-8679 -4 c10 -6 9 -10 -7 -14 -21 -5 -36 5 -24 17 8 8 15 7 31 -3z m8595 -42
c-12 -13 -26 -18 -33 -14 -9 6 -6 12 11 21 36 20 46 17 22 -7z m1532 -33 l40
-13 -30 -13 c-39 -17 -151 -17 -190 -1 l-30 13 25 13 c31 18 131 18 185 1z
m-1280 -27 c-18 -35 -93 -73 -110 -57 -7 7 -7 12 0 14 6 3 33 20 60 39 62 42
70 43 50 4z m4558 7 c32 -6 58 -16 58 -21 2 -19 -94 -45 -140 -39 -47 6 -91
23 -91 34 0 12 63 40 90 39 14 0 51 -6 83 -13z m-17093 -61 c-23 -25 -53 -31
-85 -18 -13 4 -1 12 41 28 33 12 61 21 63 19 3 -2 -6 -15 -19 -29z m12340 -23
c0 -14 -9 -26 -25 -34 -36 -16 -39 -6 -15 43 17 32 24 39 31 28 5 -8 9 -24 9
-37z m1941 6 c27 -51 -35 -82 -67 -33 -16 24 -15 27 0 44 20 22 52 17 67 -11z
m-10921 -38 c25 -48 25 -47 -2 -90 l-21 -35 -13 35 c-16 44 -17 85 -4 111 14
26 17 25 40 -21z m1344 31 c9 -14 -22 -34 -41 -26 -17 6 -17 7 1 21 23 16 33
18 40 5z m9957 -7 c11 -14 10 -18 -6 -30 -16 -12 -20 -12 -27 0 -9 15 -1 46
12 46 4 0 14 -7 21 -16z m-12101 -34 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10
-3 6 1 10 9 10 9 0 16 -4 16 -10z m1017 -16 c3 -8 1 -25 -4 -37 -9 -20 -11
-19 -15 16 -4 37 7 51 19 21z m-689 -61 l36 -38 -40 -3 c-32 -3 -43 1 -62 23
-18 21 -20 30 -11 41 19 23 39 16 77 -23z m363 -65 c5 -21 8 -77 7 -126 l-3
-87 -30 3 c-74 8 -135 80 -135 160 0 50 11 75 53 120 l31 32 33 -32 c18 -18
38 -49 44 -70z m-3449 65 c-6 -37 -20 -53 -46 -53 -47 0 -49 16 -5 49 52 38
58 38 51 4z m14008 1 c0 -13 -6 -15 -27 -9 -46 11 -51 25 -10 25 27 0 37 -4
37 -16z m-11074 -65 c24 -34 42 -64 41 -66 -2 -1 -41 4 -88 12 -46 8 -85 15
-87 15 -1 0 16 25 39 55 23 31 44 54 46 51 2 -2 24 -32 49 -67z m10919 32 c44
-10 14 -23 -34 -15 -23 4 -41 10 -41 15 0 10 30 10 75 0z m223 -7 c68 -19 88
-34 47 -34 -63 0 -103 12 -108 31 -6 23 -8 23 61 3z m431 -43 c25 -34 26 -41
7 -41 -21 0 -46 28 -46 51 0 27 15 24 39 -10z m-17534 -470 c-13 -21 -74 -33
-110 -21 -61 20 -50 30 35 30 47 0 79 -4 75 -9z m2641 -294 c17 -67 57 -121
118 -158 70 -44 126 -99 126 -124 0 -60 -72 -103 -565 -344 -214 -104 -446
-218 -515 -253 -128 -65 -221 -99 -322 -117 -32 -6 -92 -24 -134 -41 -41 -16
-90 -30 -107 -30 l-32 0 40 35 c24 21 34 35 25 35 -8 1 -89 5 -180 10 -186 10
-199 13 -570 127 -268 83 -289 91 -318 127 -16 20 -62 218 -62 265 0 6 12 16
28 22 90 35 146 42 392 49 140 5 304 16 364 24 135 20 317 63 342 81 17 12 16
14 -20 20 -86 17 -260 -5 -446 -55 -67 -18 -99 -21 -156 -17 -126 11 -130 21
-18 46 134 30 124 25 113 63 -5 18 -9 40 -9 49 0 26 187 69 350 80 74 6 223
16 330 24 238 18 306 19 577 3 237 -13 263 -9 342 45 55 38 128 66 200 77 89
13 93 11 107 -43z m-2162 -43 c17 -7 22 -34 7 -34 -23 0 -62 21 -56 30 7 11
26 13 49 4z m20116 -26 c23 -9 19 -13 -65 -55 -64 -33 -105 -47 -147 -51 -65
-5 -71 2 -30 40 88 81 155 99 242 66z m-20277 5 c-13 -2 -33 -2 -45 0 -13 2
-3 4 22 4 25 0 35 -2 23 -4z m22032 -122 c63 -65 128 -99 235 -121 100 -21
110 -25 110 -47 0 -22 -69 -13 -175 22 -44 14 -128 35 -187 46 -58 10 -139 30
-180 43 l-73 24 78 1 c60 1 77 4 77 15 0 8 4 26 10 40 15 42 51 34 105 -23z
m-18115 19 c11 -11 20 -27 20 -36 0 -19 -18 -54 -28 -54 -7 0 -32 78 -32 100
0 16 19 11 40 -10z m16975 -35 c50 -9 236 -45 415 -80 343 -67 553 -100 710
-110 52 -4 156 -15 231 -26 172 -24 172 -24 207 6 56 47 79 28 111 -93 32
-121 30 -201 -8 -275 -20 -39 -31 -75 -31 -102 0 -83 -26 -133 -119 -225 -132
-131 -348 -300 -383 -300 -31 0 -178 76 -178 91 0 6 7 24 16 40 8 17 14 32 12
34 -10 8 -256 25 -374 25 -128 0 -143 2 -194 26 l-55 25 -20 -25 c-12 -14 -25
-26 -29 -26 -5 0 -45 24 -90 54 -50 33 -114 65 -166 82 -119 40 -229 97 -504
264 -258 157 -354 233 -428 343 -45 67 -101 184 -94 196 10 15 91 30 196 35
58 3 130 12 160 22 124 37 182 44 355 40 95 -3 210 -12 260 -21z m-1032 -178
c18 -37 18 -38 -4 -83 l-22 -46 -12 36 c-7 19 -26 51 -43 70 -36 41 -46 69
-38 102 l7 25 47 -33 c26 -19 55 -50 65 -71z m-7103 63 c0 -5 -18 -10 -40 -10
-22 0 -40 5 -40 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m-91 -64 c10 -11 2
-16 -39 -26 -59 -14 -71 -9 -45 20 21 23 67 26 84 6z m-3256 -263 c-7 -2 -21
-2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-6837 -111 c-14 -14 -16 -14 -16
-1 0 20 23 44 29 28 2 -6 -4 -18 -13 -27z m-2548 -136 c75 -35 164 -71 197
-80 63 -16 304 -50 440 -61 85 -8 157 -32 133 -46 -24 -13 -191 -29 -308 -29
-147 0 -266 15 -407 50 -124 31 -130 35 -209 110 -57 54 -69 90 -36 109 30 18
49 13 190 -53z m20236 43 c-9 -15 -34 -10 -28 5 3 8 12 12 20 9 8 -3 11 -9 8
-14z m268 -118 c49 -28 50 -30 26 -36 -40 -10 -108 30 -93 55 9 14 9 14 67
-19z m366 -146 c132 -36 209 -62 203 -68 -20 -20 -95 -19 -150 2 -30 12 -92
31 -139 42 -92 21 -152 51 -152 76 0 8 6 13 13 11 6 -3 108 -31 225 -63z
m-7691 -252 c3 -7 -3 -9 -16 -6 -12 3 -21 10 -21 15 0 13 32 6 37 -9z m3138
-59 c105 -8 361 -18 570 -21 328 -6 372 -8 318 -18 -63 -12 -103 -36 -103 -62
0 -12 -13 -13 -77 -7 -119 11 -223 8 -273 -9 -32 -11 -92 -16 -205 -18 l-160
-2 -187 73 c-104 41 -188 78 -188 82 0 5 26 6 58 3 31 -3 143 -13 247 -21z
m-3325 -54 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25
-4 25 -10z m-2754 -87 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
-18z m171 -149 c8 -22 -13 -88 -37 -109 -11 -10 -20 -15 -20 -12 0 9 21 110
25 125 6 17 24 15 32 -4z m12173 -9 c0 -14 -33 -54 -41 -49 -10 6 19 54 32 54
5 0 9 -2 9 -5z m-12290 -81 c0 -19 -24 -84 -32 -84 -10 0 -10 7 3 53 5 20 14
37 19 37 6 0 10 -3 10 -6z m2790 -44 c0 -5 -13 -10 -30 -10 -16 0 -30 5 -30
10 0 6 14 10 30 10 17 0 30 -4 30 -10z m397 -84 c34 -16 69 -36 78 -46 14 -16
11 -21 -49 -74 -35 -31 -66 -56 -68 -56 -3 0 -36 30 -75 68 -72 68 -93 97 -93
127 0 28 129 16 207 -19z m-5012 -148 c14 -14 -87 -60 -157 -72 l-72 -12 37
-30 c27 -21 51 -30 89 -34 47 -4 55 -9 74 -40 13 -19 38 -51 57 -70 33 -33 35
-40 46 -142 10 -91 10 -112 -3 -136 -14 -28 -281 -214 -373 -261 -55 -27 -98
-27 -363 5 -272 32 -521 74 -681 114 -107 27 -877 266 -888 276 -2 2 1 22 8
44 16 54 46 67 182 80 185 17 402 40 429 47 23 5 21 7 -19 21 -41 14 -43 16
-26 28 10 8 38 21 63 31 37 14 51 15 86 4 50 -14 186 -35 192 -29 2 3 -3 27
-11 54 -27 90 -13 101 34 24 17 -27 33 -50 36 -50 17 0 73 37 121 80 30 28 59
50 65 50 19 0 84 -38 108 -64 l24 -26 -49 0 -49 0 30 -25 c43 -35 104 -30 89
8 -5 15 -2 15 43 1 26 -9 53 -17 60 -19 10 -3 9 -15 -2 -52 -11 -37 -12 -49
-2 -55 22 -15 80 1 131 36 28 19 96 55 151 81 l100 46 18 -26 c24 -32 48 -32
122 4 l60 29 -89 1 c-88 1 -89 1 -71 21 14 15 31 20 72 20 43 0 57 4 73 23 18
20 28 22 134 22 63 0 117 -3 121 -7z m-725 -39 c6 -22 14 -47 17 -56 5 -13 0
-15 -33 -9 -21 4 -46 14 -54 22 -12 12 -10 18 20 49 19 19 35 35 37 35 1 0 7
-18 13 -41z m178 6 c1 -1 -14 -30 -34 -64 -20 -34 -39 -60 -41 -57 -3 2 -2 38
2 80 l7 75 32 -16 c17 -8 32 -17 34 -18z m5847 25 c5 -9 -14 -16 -120 -41 -27
-6 -58 -16 -67 -21 -17 -8 -25 -48 -12 -61 3 -3 62 14 130 37 105 36 124 40
124 26 0 -20 -24 -41 -65 -59 l-30 -13 40 -24 c22 -13 58 -29 80 -35 53 -15
530 -4 685 16 229 30 509 11 820 -56 82 -17 184 -32 235 -34 67 -2 116 -11
190 -34 76 -25 131 -34 230 -42 72 -5 169 -12 217 -16 117 -8 172 11 195 68
11 29 21 39 38 39 34 0 43 -18 70 -128 29 -119 78 -248 151 -394 62 -124 76
-108 -118 -133 l-127 -17 -64 26 c-93 37 -190 44 -764 56 -282 6 -574 15 -650
20 -305 23 -552 66 -748 131 -154 51 -243 94 -330 160 -97 73 -353 337 -361
372 -3 15 -21 50 -39 77 l-33 49 31 6 c184 34 281 42 292 25z m223 -10 c46
-11 95 -20 109 -20 22 0 24 -2 12 -16 -22 -27 -86 -54 -127 -54 -26 0 -51 9
-73 25 -19 13 -52 34 -73 46 -76 41 12 53 152 19z m4640 -30 c31 -11 80 -34
110 -52 l53 -33 -158 -5 c-119 -4 -165 -9 -187 -21 l-30 -16 100 -17 c242 -41
381 -93 509 -191 33 -26 132 -95 220 -154 205 -138 619 -430 623 -440 6 -17
-71 -56 -333 -165 -305 -128 -343 -137 -393 -98 -15 12 -56 37 -92 56 -79 41
-95 58 -140 149 -48 98 -67 107 -227 107 l-122 0 -11 55 c-10 48 -16 58 -57
85 -25 17 -59 34 -75 37 -25 5 -29 9 -24 30 5 18 1 26 -20 35 -29 14 -70 2
-198 -56 -63 -29 -67 -33 -38 -33 18 0 40 -7 49 -16 15 -15 15 -17 0 -23 -9
-3 -31 2 -49 13 -25 14 -46 44 -88 128 -30 61 -93 172 -138 248 -46 76 -82
143 -79 150 2 7 42 27 88 46 46 18 154 67 239 107 86 41 185 81 220 89 78 18
171 12 248 -15z m-14526 -49 c34 -17 93 -54 129 -81 108 -79 125 -137 136
-461 5 -150 3 -205 -11 -289 -24 -138 -29 -150 -65 -150 -30 0 -81 -47 -81
-75 0 -9 -6 -12 -15 -9 -12 5 -15 26 -15 106 0 93 -2 100 -26 125 -22 22 -31
24 -65 19 -47 -7 -88 11 -117 51 -11 16 -43 88 -73 161 -60 150 -61 151 -145
102 -35 -20 -67 -50 -101 -97 -49 -67 -241 -345 -258 -372 -16 -27 61 -4 134
39 65 39 130 55 148 37 7 -7 -77 -146 -129 -214 -21 -28 -54 -58 -73 -67 -31
-15 -37 -15 -75 0 -35 14 -47 15 -80 4 -22 -7 -53 -10 -72 -6 -42 8 -442 279
-515 349 l-33 31 27 28 c15 15 32 28 38 28 21 0 192 103 285 173 52 39 169
136 260 216 274 242 363 304 519 358 122 43 180 42 273 -6z m2958 19 c0 -5
-14 -10 -31 -10 -17 0 -28 4 -24 10 3 6 17 10 31 10 13 0 24 -4 24 -10z m7185
0 c4 -6 -7 -10 -24 -10 -17 0 -31 5 -31 10 0 6 11 10 24 10 14 0 28 -4 31 -10z
m-3995 -134 c0 -45 -18 -65 -64 -73 -44 -7 -44 -9 12 70 37 52 52 53 52 3z
m-4860 5 c0 -21 -53 -71 -75 -71 -7 0 -20 -3 -28 -6 -16 -6 -20 3 -32 64 l-7
32 71 0 c63 0 71 -2 71 -19z m4456 -132 c8 -14 -18 -31 -30 -19 -9 9 0 30 14
30 5 0 12 -5 16 -11z m-1398 -429 c28 0 29 -16 1 -31 -27 -14 -69 3 -69 27 0
12 6 15 23 10 12 -3 32 -6 45 -6z m-3589 -204 c14 -14 25 -42 28 -68 l5 -43
-41 42 c-26 27 -41 51 -41 68 0 32 20 32 49 1z m517 -61 c27 -8 116 -26 196
-40 81 -14 171 -34 200 -45 109 -40 264 -84 638 -180 212 -54 543 -144 736
-199 443 -126 863 -221 975 -221 69 0 81 -3 97 -22 59 -74 93 -84 169 -53 35
15 38 14 74 -11 59 -41 135 -126 148 -166 16 -48 2 -100 -39 -150 -36 -43 -28
-44 -212 30 -274 111 -451 155 -1108 276 -509 93 -565 106 -1075 241 -154 41
-399 103 -545 137 -311 72 -369 77 -450 35 -28 -15 -53 -27 -54 -27 -2 0 -11
18 -21 39 -16 37 -22 41 -101 66 -46 14 -84 29 -84 34 0 22 40 43 72 37 103
-19 108 -19 108 -2 0 9 18 51 39 94 68 134 119 162 237 127z m-307 -32 l43 -6
-6 -76 c-4 -41 -8 -77 -10 -78 -11 -12 -193 70 -236 106 l-25 20 25 2 c14 1
35 1 46 0 16 -1 24 6 28 25 7 25 10 26 49 20 23 -4 62 -10 86 -13z m11729 -7
c43 -23 79 -66 111 -136 41 -89 74 -210 58 -215 -23 -8 -393 -84 -533 -109
-185 -34 -282 -63 -434 -129 -182 -80 -545 -189 -640 -193 -41 -2 -94 -11
-117 -19 -22 -8 -72 -17 -110 -21 -94 -8 -143 -22 -178 -49 -17 -13 -82 -36
-147 -54 -142 -38 -283 -90 -578 -214 -124 -52 -278 -113 -343 -135 -104 -37
-117 -40 -117 -24 0 54 41 443 49 463 13 37 176 176 224 193 83 28 342 76 414
76 39 0 109 9 155 20 46 11 184 34 308 51 273 37 335 49 470 94 58 19 144 43
192 53 l86 19 25 -25 c33 -33 66 -24 100 27 22 33 38 43 114 71 97 35 140 44
288 59 55 6 143 21 196 34 83 20 104 30 154 71 78 63 156 103 208 105 10 1 30
-5 45 -13z m2100 -78 c37 -35 87 -79 110 -98 l43 -35 -50 -34 c-83 -56 -108
-46 -202 78 -27 35 -49 69 -49 75 0 15 56 76 70 76 6 0 41 -28 78 -62z m-9088
-21 c0 -29 -1 -30 -16 -15 -10 10 -14 23 -9 37 9 30 25 16 25 -22z m-2634
-141 c24 -18 17 -26 -18 -19 -16 3 -28 12 -28 19 0 18 21 18 46 0z m13975
-134 c-19 -35 -49 -55 -75 -48 -33 8 -24 33 18 52 46 20 69 18 57 -4z m-15955
-56 c22 -17 11 -35 -13 -20 -19 12 -29 34 -16 34 5 0 18 -6 29 -14z m15768
-68 c-46 -88 -256 -265 -411 -346 -101 -52 -171 -79 -345 -131 -74 -22 -176
-56 -227 -76 -103 -39 -146 -43 -193 -19 l-32 17 45 23 c70 37 456 161 539
173 126 19 332 133 460 256 52 51 163 135 178 135 1 0 -5 -15 -14 -32z
m-12514 -88 c8 -5 12 -11 10 -13 -8 -8 -70 5 -70 14 0 12 41 11 60 -1z m-3100
-114 c0 -8 -13 -16 -30 -18 -34 -5 -51 9 -29 23 22 14 59 11 59 -5z m13720
-16 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z
m-13469 -161 c23 -12 16 -29 -11 -29 -21 0 -34 15 -25 30 8 13 12 12 36 -1z
m11205 -8 c56 -26 111 -32 195 -21 200 27 195 27 234 -14 19 -21 35 -41 35
-45 0 -3 -24 -24 -54 -46 -34 -24 -65 -58 -83 -89 -26 -47 -36 -55 -143 -109
-225 -113 -425 -247 -550 -366 -46 -44 -20 -29 164 94 266 177 328 200 389
147 22 -19 21 -31 -3 -62 l-20 -25 0 33 c0 17 -3 32 -7 32 -10 0 -194 -118
-423 -271 -293 -196 -518 -365 -753 -567 -12 -11 -21 -12 -28 -5 -7 7 42 58
153 163 90 84 172 162 183 173 52 52 -312 -232 -395 -308 -211 -192 -375 -291
-613 -370 -56 -18 -191 -65 -300 -104 -132 -47 -231 -76 -295 -87 -138 -22
-233 -56 -517 -187 -314 -144 -335 -152 -380 -133 -21 9 -42 30 -57 55 -13 23
-31 44 -40 47 -27 8 -56 -13 -69 -51 -7 -19 -16 -35 -19 -35 -3 0 -30 53 -59
117 l-53 117 24 18 c28 21 37 22 44 4 3 -8 18 -19 33 -26 24 -9 40 -7 102 15
108 37 160 97 109 125 -28 15 -25 26 8 34 15 3 37 8 50 12 31 9 29 -15 -5 -44
l-28 -23 39 6 c49 8 133 45 188 83 81 55 124 72 184 72 86 0 138 29 183 101
31 49 40 58 66 59 54 1 103 20 162 64 61 46 277 255 271 262 -3 2 -33 -6 -67
-17 -34 -12 -65 -19 -68 -16 -3 4 38 54 92 112 95 104 99 112 81 155 -4 11 4
21 28 34 42 22 38 46 -8 46 -17 0 -121 -40 -236 -91 -355 -157 -593 -250 -606
-236 -8 8 45 52 161 135 100 71 110 80 108 107 -3 43 26 68 136 116 53 23 152
71 221 106 110 56 297 127 580 219 41 14 145 42 230 64 85 21 259 69 385 105
229 66 431 118 576 150 70 15 79 15 114 0 39 -15 39 -15 71 16 l33 32 18 -31
c10 -18 21 -32 24 -32 3 0 41 45 83 100 42 55 78 100 80 100 1 0 20 -8 42 -19z
m-4226 -60 c0 -19 -23 -71 -31 -71 -12 0 -11 10 2 49 10 28 29 43 29 22z
m-6380 -11 c0 -23 63 -80 95 -86 44 -8 59 -27 52 -63 -11 -55 20 -73 249 -147
176 -57 343 -94 331 -75 -2 4 -11 23 -21 44 -16 36 -16 41 -1 63 18 28 53 44
97 44 42 0 292 -109 413 -180 55 -33 165 -90 245 -127 229 -106 225 -99 69
-108 l-132 -7 104 -55 c57 -30 106 -57 108 -59 9 -9 -10 -15 -75 -25 -96 -15
-143 -63 -98 -100 39 -32 47 -68 40 -183 l-7 -111 48 -46 c26 -25 82 -74 123
-109 41 -36 104 -91 139 -124 81 -73 160 -111 274 -129 100 -16 102 -18 219
-154 72 -83 113 -111 198 -133 47 -13 110 -66 110 -94 0 -24 -48 -20 -176 13
-128 34 -225 75 -294 126 -39 28 -62 37 -126 46 -98 13 -172 41 -344 129 -74
38 -215 108 -312 155 -213 104 -457 249 -828 492 -359 235 -1004 644 -1057
671 -48 25 -55 34 -63 81 -5 34 -9 37 -138 95 -104 47 -132 64 -132 79 0 18 5
19 66 13 36 -4 161 -25 277 -46 480 -90 505 -93 582 -80 98 17 104 23 42 43
-108 34 -147 80 -113 133 17 24 36 32 36 14z m6658 -12 c-3 -7 -13 -15 -24
-17 -16 -3 -17 -1 -5 13 16 19 34 21 29 4z m-7108 -23 c0 -8 -4 -15 -10 -15
-5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m1042 -92 c7 -15 37 -45
66 -67 29 -22 49 -43 44 -48 -5 -5 -32 -11 -60 -15 -64 -7 -83 7 -112 84 -26
71 -26 73 14 73 28 0 37 -5 48 -27z m10455 1 c-9 -9 -28 6 -21 18 4 6 10 6 17
-1 6 -6 8 -13 4 -17z m-10649 -21 c41 -13 49 -19 35 -24 -32 -13 -106 -8 -163
10 -53 18 -54 19 -30 29 29 13 83 8 158 -15z m5927 -15 c-2 -7 -47 -193 -101
-413 -53 -220 -100 -404 -104 -408 -15 -17 -21 71 -10 134 16 88 124 519 155
618 21 64 30 81 45 81 11 0 18 -5 15 -12z m6645 -57 c0 -11 -29 -35 -35 -29
-10 9 7 38 21 38 8 0 14 -4 14 -9z m-9255 -29 c-8 -8 -25 10 -19 20 4 6 9 5
15 -3 4 -7 6 -15 4 -17z m2825 -121 c0 -35 -9 -61 -21 -61 -10 0 -12 34 -3 68
9 32 24 27 24 -7z m-2752 -96 c2 -2 -13 -28 -34 -59 l-37 -56 -39 91 c-21 51
-38 94 -38 96 0 3 140 -65 148 -72z m2538 4 c-3 -17 -6 -46 -6 -64 0 -24 -6
-34 -25 -43 -35 -16 -35 -16 -35 17 0 36 16 80 37 103 24 27 36 22 29 -13z
m27 -131 c-10 -53 -19 -53 -29 0 -6 28 -4 32 15 32 18 0 20 -4 14 -32z m-6506
-63 c117 -69 153 -98 139 -112 -15 -15 -211 113 -266 173 -30 33 -37 36 127
-61z m6423 1 c0 -33 -20 -62 -33 -49 -17 16 2 83 23 83 6 0 10 -15 10 -34z
m-4380 -33 c69 -19 166 -40 217 -46 l92 -12 -34 -55 c-19 -30 -36 -59 -38 -64
-1 -4 -81 23 -177 62 -133 55 -182 80 -212 109 -66 65 -61 65 152 6z m-1670
-205 c140 -85 463 -298 488 -321 21 -20 22 -22 5 -28 -10 -4 -27 -2 -38 4 -50
25 -665 430 -665 437 0 15 123 -39 210 -92z m6027 86 c1 -5 -2 -16 -7 -24 -12
-20 -35 2 -26 25 6 17 27 16 33 -1z m179 -69 c-9 -36 -24 -23 -18 15 3 21 8
28 15 21 5 -5 7 -21 3 -36z m-8451 -16 c6 -6 6 -12 0 -18 -14 -14 -85 17 -79
34 4 10 12 11 37 3 18 -6 36 -15 42 -19z m12921 -254 c-19 -29 -37 -38 -61
-33 -12 2 -4 12 27 34 23 17 45 30 47 28 2 -2 -4 -15 -13 -29z m-4806 -25 c0
-16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10 -30z
m-5620 -231 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m7470 12 c0 -15 -48 -67 -55 -60 -3 4 6 21 20 38 28 33 35 37 35 22z
m-260 -190 c0 -12 -50 -32 -62 -25 -5 3 3 14 19 24 27 17 43 17 43 1z m2685
-102 c-9 -16 -20 -27 -25 -24 -6 4 -4 18 5 36 9 16 20 27 25 24 6 -4 4 -18 -5
-36z m-3155 -99 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z
m-6657 -56 c21 -28 15 -44 -11 -28 -18 11 -30 54 -15 54 4 0 16 -12 26 -26z
m362 -144 c99 -51 234 -117 300 -147 66 -29 126 -61 133 -70 39 -52 -11 -83
-147 -90 -90 -5 -100 -3 -163 24 l-68 29 31 23 31 23 -25 17 c-14 9 -44 37
-67 63 -44 50 -85 68 -136 61 -28 -4 -41 4 -120 82 l-89 86 70 -4 c65 -4 84
-12 250 -97z m7705 25 c-38 -39 -87 -57 -131 -48 -24 5 -27 8 -17 20 23 28
104 59 162 62 17 1 14 -5 -14 -34z m-8420 -69 c0 -8 -13 -10 -37 -8 l-36 5 24
18 c24 18 49 11 49 -15z m8078 2 c-17 -26 -68 -37 -68 -16 0 14 40 35 69 37
11 1 11 -3 -1 -21z m-7994 -24 c23 -9 20 -19 -8 -32 -20 -9 -27 -7 -40 7 -9
10 -16 21 -16 24 0 9 43 9 64 1z m505 -61 c12 -47 6 -62 -26 -63 -6 0 -19 -3
-29 -7 -15 -6 -16 -1 -12 42 5 44 26 75 49 75 4 0 12 -21 18 -47z m7151 -13
c-15 -28 -47 -42 -81 -35 -42 10 -36 20 24 37 70 21 69 21 57 -2z m500 1 c0
-14 -41 -41 -52 -35 -14 9 -9 21 15 32 28 14 37 14 37 3z m-7920 -40 c8 -4 16
-18 18 -30 4 -25 -11 -27 -90 -9 -48 11 -67 22 -68 39 0 3 14 12 32 20 32 16
44 14 108 -20z m7194 -50 c3 -5 -1 -11 -9 -15 -15 -6 -29 7 -18 18 9 9 21 8
27 -3z m556 -41 c0 -17 -46 -41 -66 -33 -15 6 -12 10 21 25 48 21 45 20 45 8z
m-7510 -48 l25 -17 -39 -3 c-39 -3 -85 19 -93 45 -6 16 75 -3 107 -25z m7410
9 c0 -21 -24 -77 -38 -88 -9 -7 -38 -13 -65 -13 l-49 0 33 41 c39 49 119 90
119 60z m-7240 -26 c17 -9 30 -20 30 -25 0 -12 0 -12 -39 1 -17 6 -31 17 -31
25 0 18 4 18 40 -1z m8801 -38 c-12 -30 -55 -62 -71 -52 -8 5 3 23 31 51 45
46 57 46 40 1z m-2576 -106 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21
15 7z m1039 -77 c22 -8 20 -26 -4 -57 -19 -24 -83 -47 -96 -35 -11 11 21 70
45 83 30 16 34 17 55 9z m-225 -99 c9 -10 -11 -36 -34 -45 -68 -26 -99 0 -48
40 24 19 65 21 82 5z m-113 -80 c27 -41 29 -96 5 -127 -26 -32 -98 -58 -162
-58 -60 0 -70 14 -21 28 21 7 48 22 61 36 l22 24 -35 21 c-46 29 -37 48 25 56
34 5 53 13 60 26 13 25 25 24 45 -6z m1044 -18 c0 -7 -16 -28 -35 -47 l-35
-34 0 26 c0 17 10 34 26 47 31 24 44 26 44 8z m-1310 -32 c0 -11 -12 -15 -45
-15 -33 0 -45 4 -45 15 0 11 12 15 45 15 33 0 45 -4 45 -15z m-672 -37 c7 -7
12 -19 12 -27 0 -11 -4 -10 -20 4 -21 19 -26 35 -12 35 4 0 13 -5 20 -12z
m-3913 -81 c9 -68 -7 -126 -42 -159 -27 -25 -65 -48 -79 -48 -12 0 7 124 26
167 18 40 64 93 81 93 4 0 11 -24 14 -53z m4225 9 c0 -11 -19 -15 -25 -6 -3 5
1 10 9 10 9 0 16 -2 16 -4z"
      />
      <path d="M15830 34050 c-8 -5 -11 -12 -7 -16 4 -4 15 0 24 9 18 18 8 23 -17 7z" />
      <path d="M15338 33520 c-7 -24 -3 -38 8 -28 3 4 4 17 2 30 l-3 23 -7 -25z" />
      <path
        d="M15420 33400 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"
      />
      <path
        d="M15626 32820 c21 -35 24 -34 17 3 -3 15 -12 27 -20 27 -12 0 -11 -5
3 -30z"
      />
      <path
        d="M15632 32764 c-13 -9 -28 -27 -33 -41 -10 -27 -43 -514 -55 -803 -9
-235 11 -591 36 -640 l18 -35 12 27 c10 21 10 44 1 103 -15 100 -14 157 4 290
46 329 58 491 58 750 0 143 -4 284 -9 313 -9 52 -9 52 -32 36z"
      />
      <path
        d="M15595 31176 c-7 -52 10 -162 26 -171 10 -7 39 70 39 103 0 30 -32
106 -48 116 -7 4 -13 -15 -17 -48z"
      />
      <path
        d="M15623 30898 c5 -85 33 -199 59 -238 10 -16 3 248 -8 286 -3 12 -17
27 -31 33 l-26 12 6 -93z"
      />
      <path
        d="M13625 31750 c-4 -6 7 -10 27 -10 25 0 29 3 18 10 -19 13 -37 13 -45
0z"
      />
      <path
        d="M18833 31469 c-32 -12 -30 -58 4 -95 32 -34 35 -27 25 47 -7 53 -8
56 -29 48z"
      />
      <path
        d="M11113 29954 c-7 -18 17 -38 31 -24 11 11 1 40 -14 40 -6 0 -13 -7
-17 -16z"
      />
      <path
        d="M28988 28469 c-18 -10 -25 -39 -10 -39 5 0 16 8 25 18 28 31 19 42
-15 21z"
      />
      <path
        d="M12040 24860 c0 -6 28 -10 65 -10 37 0 65 4 65 10 0 6 -28 10 -65 10
-37 0 -65 -4 -65 -10z"
      />
      <path
        d="M31390 17946 c-25 -7 -58 -20 -75 -28 -32 -17 -81 -65 -74 -72 3 -3
52 22 109 54 110 63 113 66 40 46z"
      />
      <path d="M31138 17823 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
      <path
        d="M33294 15306 c-51 -16 -149 -69 -136 -74 5 -1 56 7 113 18 105 22
169 45 169 61 0 13 -99 10 -146 -5z"
      />
      <path
        d="M32390 15060 c-505 -94 -730 -144 -990 -224 -129 -39 -362 -125 -342
-125 7 -1 142 29 299 65 162 37 296 63 309 60 16 -4 41 4 76 23 74 42 317 98
754 176 141 25 261 48 266 51 4 3 13 14 18 25 9 16 7 19 -7 18 -10 0 -182 -31
-383 -69z"
      />
      <path
        d="M32830 15064 c25 -7 46 -14 48 -14 1 0 2 7 2 15 0 11 -12 15 -47 14
l-48 0 45 -15z"
      />
      <path
        d="M30795 14666 c-60 -15 -84 -27 -110 -57 -20 -22 -20 -22 0 -14 11 4
63 23 115 41 52 19 100 36 105 38 22 10 -64 3 -110 -8z"
      />
      <path
        d="M25520 14851 c-11 -16 -16 -32 -11 -35 8 -6 51 43 51 58 0 15 -21 3
-40 -23z"
      />
      <path
        d="M16366 9271 c-12 -13 -14 -21 -6 -26 14 -8 15 -7 24 23 9 27 4 28
-18 3z"
      />
      <path
        d="M16109 9211 l-24 -19 25 -6 c88 -18 119 -17 141 5 28 28 12 39 -62
39 -41 0 -63 -5 -80 -19z"
      />
      <path
        d="M16930 9220 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"
      />
      <path
        d="M25237 6611 c-129 -105 -140 -115 -112 -105 32 12 106 63 148 103 34
31 84 92 75 90 -2 0 -52 -40 -111 -88z"
      />
      <path
        d="M22452 5493 c2 -20 9 -29 25 -31 23 -3 23 -3 5 27 -21 38 -34 40 -30
4z"
      />
      <path
        d="M15982 7023 c3 -24 9 -29 55 -39 l53 -12 -31 39 c-21 27 -39 39 -56
39 -21 0 -24 -4 -21 -27z"
      />
      <path
        d="M16376 6881 c-28 -31 -10 -44 25 -19 16 12 29 25 29 30 0 15 -37 8
-54 -11z"
      />
      <path
        d="M22966 32963 c-13 -64 -37 -354 -48 -586 -21 -458 25 -1087 111
-1512 23 -113 25 -74 6 125 -9 85 -15 238 -14 340 1 121 -4 228 -15 310 -15
119 -22 390 -10 390 3 0 19 -9 35 -21 28 -20 29 -20 29 -1 0 24 -25 46 -44 39
-17 -7 -23 14 -32 111 -6 59 -3 83 16 142 21 66 22 77 11 140 -12 65 -11 70
10 99 l22 30 -27 67 c-26 64 -27 76 -24 200 2 91 -1 137 -8 144 -9 9 -13 5
-18 -17z"
      />
      <path
        d="M6226 31399 c-137 -14 -240 -54 -297 -115 -13 -14 -36 -32 -51 -40
-24 -12 -370 -156 -443 -184 l-30 -11 74 -29 c101 -40 116 -56 114 -124 -3
-91 -21 -136 -72 -187 -39 -39 -57 -49 -119 -64 -40 -9 -74 -21 -77 -25 -3 -5
30 -11 72 -15 43 -3 108 -11 144 -17 l67 -11 -1 -50 c-1 -43 3 -53 30 -77 17
-15 55 -35 84 -45 30 -10 108 -38 173 -63 l119 -46 -44 -39 c-55 -49 -133 -87
-179 -87 -62 0 -114 -21 -160 -64 -25 -24 -66 -50 -90 -58 -25 -9 -93 -33
-151 -53 -58 -20 -144 -56 -190 -79 -46 -24 -122 -63 -169 -87 -47 -24 -121
-60 -165 -79 -158 -69 -285 -169 -307 -241 -9 -32 -8 -48 11 -107 l23 -69 -22
-43 c-17 -32 -20 -48 -12 -64 11 -24 308 -180 417 -219 39 -14 106 -30 150
-37 65 -9 109 -25 240 -88 l160 -76 128 2 c75 2 135 -2 147 -8 21 -11 70 -4
70 11 0 4 -62 42 -137 83 -124 68 -140 75 -178 69 -62 -8 -123 23 -174 87 -23
29 -40 58 -38 64 2 6 16 12 31 14 26 3 28 6 21 38 -3 20 -9 42 -12 50 -7 18 3
18 100 -7 172 -43 160 -37 154 -67 -3 -15 -8 -34 -12 -43 -4 -12 23 -29 125
-78 149 -71 188 -81 221 -57 37 25 63 20 174 -39 100 -52 156 -67 170 -45 11
18 25 10 25 -15 0 -15 10 -31 25 -41 46 -30 38 -6 -19 62 -32 36 -55 68 -52
71 2 2 42 -7 87 -22 61 -19 100 -25 145 -23 69 3 74 0 135 -73 l41 -49 -64
-30 c-58 -29 -69 -31 -134 -25 -65 6 -74 4 -92 -15 -24 -26 -33 -66 -22 -96 6
-15 26 -28 65 -41 31 -10 56 -22 56 -28 -1 -5 -36 -22 -79 -36 -42 -14 -77
-30 -76 -35 0 -5 20 -22 43 -39 39 -27 50 -30 121 -30 71 0 82 3 121 30 23 17
44 35 46 41 2 7 -29 35 -69 64 -40 29 -75 54 -77 56 -2 2 4 15 13 29 19 28 45
32 102 12 22 -8 64 -14 94 -15 30 0 81 -4 114 -8 l58 -6 -23 31 c-31 42 -21
57 66 101 67 34 73 35 165 32 208 -7 269 -7 275 0 12 11 -38 69 -80 93 -22 12
-58 26 -80 30 -45 8 -97 33 -173 84 -30 20 -199 119 -375 221 -184 106 -329
196 -340 211 -30 41 -16 62 71 102 90 42 164 57 280 57 76 0 87 3 115 26 38
32 71 28 141 -21 67 -45 114 -55 271 -55 195 0 415 35 650 103 73 21 80 25 78
48 -3 45 18 88 61 126 42 37 44 41 48 105 5 89 27 118 99 136 70 18 104 44
133 101 l24 47 -58 38 c-101 65 -178 88 -347 105 -108 11 -203 39 -235 68 -15
13 -18 25 -13 50 l6 32 -68 22 c-45 14 -103 23 -173 27 -82 3 -111 9 -132 24
-27 19 -27 19 -49 -3 -19 -19 -28 -20 -92 -14 -59 5 -76 11 -94 31 -13 14 -23
29 -23 33 0 5 20 14 45 21 25 7 67 26 93 43 43 27 55 30 142 31 52 1 149 13
215 25 79 15 179 25 295 29 l176 7 61 40 c48 31 63 46 67 71 7 34 44 73 94 98
18 10 51 35 74 58 l41 41 -56 22 c-88 36 -150 46 -342 55 -197 10 -259 20
-535 85 -102 25 -232 52 -290 60 -81 13 -124 25 -185 56 -44 21 -104 43 -134
49 -63 12 -470 11 -585 -1z m662 -1149 c24 -11 42 -26 42 -35 0 -28 -45 -62
-114 -85 -37 -12 -77 -33 -90 -47 -20 -21 -24 -22 -35 -9 -17 20 29 153 61
178 30 23 83 22 136 -2z m-350 -150 c29 0 -7 -26 -75 -54 -65 -27 -203 -52
-231 -41 -22 8 44 48 123 74 76 25 103 30 139 25 16 -2 36 -4 44 -4z m-408
-89 c0 -21 -20 -61 -30 -61 -20 0 -23 31 -5 50 19 21 35 26 35 11z m-215 -100
c-14 -28 -20 -31 -60 -31 -25 0 -45 2 -45 5 0 6 114 64 118 60 2 -2 -4 -17
-13 -34z m1848 -46 c4 -25 10 -57 13 -71 l7 -27 -134 8 c-74 4 -164 8 -201 9
-125 2 -104 19 104 78 106 31 196 54 199 52 3 -2 9 -24 12 -49z m-1983 5 c0
-25 -22 -36 -103 -51 -53 -10 -78 -10 -122 0 -31 7 -54 15 -51 18 11 12 185
51 229 52 40 1 47 -2 47 -19z m460 -849 c14 -10 26 -26 28 -35 7 -36 -66 -7
-102 41 l-19 25 34 -7 c19 -3 45 -15 59 -24z"
      />
      <path
        d="M33550 31248 c-130 -12 -282 -44 -710 -148 -201 -49 -450 -107 -555
-131 -104 -23 -194 -45 -198 -49 -24 -21 70 -29 288 -26 241 5 222 2 635 80
173 32 362 48 520 44 l125 -3 -44 -23 c-51 -28 -101 -37 -322 -57 -187 -17
-254 -32 -454 -103 -149 -53 -181 -76 -99 -71 70 5 68 -17 -3 -31 -32 -6 -78
-15 -103 -20 -51 -10 -59 -5 -69 42 l-7 33 -62 0 c-43 0 -66 -5 -77 -16 -16
-15 -40 -97 -32 -106 2 -2 46 -7 96 -12 98 -9 132 -24 90 -39 -13 -5 -59 -21
-101 -36 -43 -16 -78 -31 -78 -35 0 -4 18 -25 40 -46 22 -21 40 -43 40 -49 0
-5 -29 -55 -65 -111 -80 -126 -95 -167 -95 -262 0 -61 7 -96 40 -191 44 -128
61 -156 131 -220 26 -23 59 -63 74 -87 l26 -45 30 29 c38 38 83 47 169 32 83
-13 210 -15 210 -2 0 5 -13 12 -30 16 -63 14 -25 25 85 25 l115 0 0 30 0 30
115 0 114 0 10 -32 c5 -18 17 -53 26 -77 34 -93 -22 -146 -168 -160 -45 -4
-75 -10 -67 -13 8 -3 28 -9 44 -14 30 -9 55 -38 44 -50 -4 -3 -35 -16 -69 -29
l-62 -22 51 -17 c123 -41 169 -105 182 -255 6 -67 19 -124 41 -185 28 -78 30
-93 20 -121 -17 -49 -14 -92 10 -114 23 -21 90 -41 139 -41 35 0 123 20 116
27 -2 2 -32 11 -65 19 -80 18 -134 46 -160 83 -33 47 -28 55 32 48 28 -4 89
-9 135 -13 l83 -7 -22 37 c-12 20 -19 39 -16 42 3 3 29 1 58 -4 55 -10 86 -4
107 21 11 12 7 16 -25 21 -60 11 -73 16 -73 30 0 9 27 15 98 18 88 5 108 11
217 56 181 76 201 80 455 86 124 3 306 10 405 16 99 6 254 8 345 5 91 -3 170
-2 176 2 6 4 19 31 28 59 30 92 109 193 198 252 l38 24 -138 105 c-75 58 -148
113 -162 123 -21 16 -25 26 -25 76 l0 59 -70 48 c-103 72 -142 89 -205 92 -42
2 -71 11 -125 41 -65 36 -76 39 -145 38 -53 -1 -95 -9 -143 -27 -75 -27 -114
-24 -150 12 -18 18 -18 19 2 60 l21 41 -82 0 -81 0 24 26 c13 15 56 41 94 59
39 18 79 43 91 56 11 13 35 62 53 108 l32 84 -26 37 c-34 49 -32 74 8 103 41
31 77 50 84 44 2 -3 9 -37 15 -76 10 -71 11 -72 30 -54 17 15 19 27 15 76 l-5
57 74 68 75 68 -29 72 c-38 95 -51 108 -118 116 -70 8 -124 35 -179 90 -59 59
-87 77 -185 119 -299 127 -605 175 -920 145z m270 -248 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m766 -164 c3 -8 4 -31 2
-51 l-3 -38 -32 45 c-18 25 -29 48 -26 52 12 11 54 6 59 -8z m252 -59 c25 -23
29 -64 9 -91 -13 -18 -15 -17 -35 9 -15 19 -22 42 -22 72 0 47 5 48 48 10z
m-2008 -87 c-20 -13 -43 -13 -35 0 3 6 16 10 28 10 18 0 19 -2 7 -10z m88
-176 c-55 -43 -75 -64 -58 -60 53 12 479 86 494 86 41 0 6 -20 -105 -60 -164
-59 -163 -59 -167 -163 l-4 -85 -53 -37 c-50 -34 -56 -36 -105 -29 l-52 7 97
97 97 98 -138 7 c-130 7 -141 9 -166 32 l-27 26 -108 -91 c-59 -49 -110 -88
-112 -86 -2 2 4 23 13 48 23 59 115 145 204 190 69 35 217 84 255 85 11 1 -16
-26 -65 -65z m1702 -108 c0 -28 -18 -46 -46 -46 -41 0 -42 64 -1 79 27 11 47
-4 47 -33z m-181 -54 c-129 -97 -351 -192 -450 -192 l-34 0 39 26 c96 65 380
183 445 184 l24 0 -24 -18z m-1599 -59 c0 -13 -3 -13 -15 -3 -8 7 -12 16 -9
21 8 13 24 1 24 -18z m40 -159 c14 -36 13 -49 -9 -75 l-18 -23 -13 24 c-9 18
-10 32 -1 61 14 45 27 50 41 13z m700 -154 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m857 -156 c20 -6 19 -8 -15 -37 -82
-69 -76 -67 -69 -22 9 55 34 72 84 59z m853 -94 c0 -5 -13 -10 -30 -10 -16 0
-30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10z m209 -397 c1 -28 -9 -29 -39
-5 l-22 18 23 45 23 44 7 -40 c4 -22 8 -50 8 -62z m-1749 -93 c23 -23 24 -36
7 -67 l-12 -22 -62 37 c-35 20 -63 40 -63 43 0 11 45 28 78 28 19 1 40 -7 52
-19z m-174 -21 c8 -14 -18 -31 -30 -19 -9 9 0 30 14 30 5 0 12 -5 16 -11z
m384 -72 c0 -37 -47 -48 -74 -18 -17 19 -17 20 6 24 46 9 68 7 68 -6z m-200
-43 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z"
      />
      <path
        d="M34740 30284 c-91 -65 -92 -67 -42 -61 49 6 86 34 101 78 5 16 9 29
7 29 -1 0 -31 -21 -66 -46z"
      />
      <path
        d="M32110 29704 c0 -23 25 -50 33 -37 3 5 -3 20 -14 33 -18 24 -19 24
-19 4z"
      />
      <path
        d="M32950 29510 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10
-5 0 -9 -4 -9 -10z"
      />
      <path
        d="M7003 28643 c-25 -10 -12 -29 33 -51 65 -32 110 -11 84 39 -8 15 -21
19 -58 18 -26 0 -53 -3 -59 -6z"
      />
      <path
        d="M4231 28490 c-12 -17 -30 -30 -41 -30 -43 0 -60 -51 -39 -119 3 -9
-21 -22 -76 -41 -111 -37 -142 -55 -161 -92 -22 -42 -12 -92 26 -140 17 -21
38 -60 46 -87 18 -62 77 -142 190 -257 318 -326 684 -433 1192 -350 159 26
168 26 338 15 96 -6 270 -9 387 -6 233 5 329 22 492 87 105 42 133 63 161 124
31 66 33 180 5 242 -28 61 -92 111 -154 119 -29 4 -62 11 -75 16 l-22 9 26 52
c26 52 26 52 8 86 -42 78 -124 109 -366 137 -150 17 -230 32 -543 99 -465 99
-680 126 -1017 126 l-248 0 -5 -22 c-2 -13 -6 -34 -7 -48 -3 -22 -8 -18 -36
32 -46 79 -54 84 -81 48z m2184 -406 c23 -23 13 -27 -42 -19 -44 6 -46 8 -28
21 25 18 50 18 70 -2z"
      />
      <path
        d="M35990 28390 c-252 -12 -936 -88 -1123 -126 -115 -23 -236 -67 -345
-125 -53 -28 -214 -107 -357 -174 -143 -68 -271 -133 -284 -145 -22 -19 -23
-26 -19 -95 7 -102 49 -187 137 -274 153 -151 300 -199 570 -184 81 4 153 7
161 6 8 -2 95 -3 193 -3 208 -1 347 15 637 72 279 54 390 84 705 188 298 99
343 123 398 212 58 93 50 160 -32 284 -59 90 -283 324 -337 352 -36 19 -100
21 -304 12z m150 -175 c10 -12 10 -19 2 -27 -15 -15 -48 14 -38 31 10 15 21
14 36 -4z m-672 -42 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z
m-218 -32 c-53 -28 -142 -43 -206 -35 -30 3 -52 10 -47 15 18 18 123 38 203
38 l85 0 -35 -18z m-417 -12 c20 -5 37 -14 37 -20 0 -5 -19 -19 -42 -29 -45
-20 -108 -24 -188 -11 -45 7 -45 7 -20 21 32 18 129 49 155 49 11 1 37 -4 58
-10z m1295 -245 c12 -4 22 -13 22 -21 0 -11 -7 -14 -22 -10 -13 3 -40 9 -61
12 -20 4 -40 11 -43 16 -6 10 69 12 104 3z m-244 -103 c6 -9 -16 -21 -40 -21
-20 0 -17 18 4 23 28 8 31 7 36 -2z"
      />
      <path
        d="M3537 27115 c-15 -15 -59 -82 -98 -149 -40 -66 -89 -148 -110 -181
-50 -78 -69 -126 -69 -170 0 -20 -9 -53 -19 -73 -28 -56 -98 -267 -115 -347
-8 -38 -22 -131 -31 -205 -10 -74 -19 -137 -21 -139 -2 -2 -10 4 -19 13 -20
19 -74 21 -100 3 -17 -13 -17 -27 -12 -203 6 -201 21 -288 71 -409 21 -52 23
-67 15 -110 -19 -102 24 -224 89 -250 20 -9 47 -26 60 -38 32 -29 96 -117 90
-122 -3 -3 -43 18 -89 46 -146 90 -233 139 -246 139 -24 0 35 -138 99 -232 82
-120 166 -205 253 -255 39 -23 115 -75 169 -117 54 -42 131 -96 170 -121 175
-109 472 -226 661 -261 114 -20 115 -21 115 -37 0 -11 -60 -13 -322 -13 -325
0 -455 -8 -778 -45 -240 -28 -324 -44 -451 -85 -154 -50 -193 -88 -234 -228
-23 -79 -22 -163 3 -217 39 -88 174 -224 270 -273 60 -31 416 -158 482 -172
102 -21 238 -17 456 15 110 17 258 35 329 41 209 17 370 63 593 171 l122 59
84 105 c141 179 173 264 142 385 -9 34 -16 73 -16 86 0 20 -5 24 -29 24 -51 0
-84 19 -162 95 l-76 74 24 23 c13 13 79 50 145 83 76 38 129 70 142 88 19 26
19 30 5 55 -18 34 -59 39 -132 19 -28 -8 -98 -25 -155 -37 -95 -20 -104 -21
-127 -6 -14 9 -32 30 -41 47 -15 31 -15 32 18 66 49 51 85 70 168 88 128 28
248 67 270 87 17 16 20 30 19 91 -2 106 -10 133 -37 129 -30 -4 -29 28 2 65
39 47 43 64 51 193 6 120 6 126 -17 160 -13 19 -43 45 -66 57 l-43 22 -62 -40
c-73 -47 -61 -44 -260 -69 -279 -35 -470 -25 -677 36 -159 47 -324 143 -360
207 -14 26 -14 29 3 42 15 11 28 11 80 -2 395 -94 621 -118 1107 -118 349 0
381 2 466 22 101 24 254 80 254 93 0 5 -18 23 -40 40 -39 31 -42 32 -60 15
-16 -14 -28 -16 -67 -10 -70 12 -132 11 -623 -4 -245 -8 -480 -11 -522 -7 -68
7 -78 10 -78 27 0 10 5 19 12 19 17 0 607 59 656 65 27 4 42 11 42 20 0 12
-30 15 -157 17 l-158 3 63 14 c35 8 78 24 95 37 76 57 110 67 337 100 63 9
119 20 123 24 5 4 -1 38 -12 76 -11 37 -19 69 -18 70 1 1 48 -38 105 -87 57
-49 109 -89 116 -89 7 0 18 11 24 25 9 20 9 33 -3 60 -15 39 -18 40 -46 24
-27 -14 -58 -2 -70 27 -8 22 -2 33 41 87 28 34 50 65 50 69 0 20 -59 71 -113
97 -144 69 -417 106 -735 98 -181 -4 -214 -7 -260 -26 -62 -25 -125 -27 -247
-6 -49 9 -105 18 -122 21 -18 3 -33 10 -33 16 0 6 12 55 26 109 l26 97 47 12
c82 21 199 23 296 6 50 -10 182 -21 295 -26 228 -10 374 -32 577 -85 185 -49
312 -62 460 -47 123 12 258 44 378 89 162 60 185 87 185 217 0 201 -114 388
-249 412 -20 3 -54 17 -77 30 -91 52 -138 63 -326 79 -481 39 -1114 74 -1538
86 -179 4 -348 11 -375 15 -46 5 -53 3 -78 -21z m1481 -133 c2 -8 -12 -12 -47
-12 -59 0 -69 17 -13 23 48 5 55 3 60 -11z m400 -2 c12 0 22 -4 22 -9 0 -11
-82 -21 -94 -10 -14 13 5 31 28 25 11 -3 31 -6 44 -6z m-1268 -16 c0 -8 -5
-12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m1179 -70 c2 -2 -10 -4 -27 -4
-18 0 -32 5 -32 10 0 9 47 4 59 -6z m-1809 -88 c-6 -6 -15 -5 -25 4 -12 10
-13 16 -3 27 10 13 14 12 25 -3 8 -11 10 -21 3 -28z m325 -64 c0 -18 -20 -15
-23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m-485 -109 c-1 -20 -28 -55 -34
-45 -8 13 10 52 24 52 6 0 10 -3 10 -7z m260 -73 c10 0 11 -3 3 -11 -12 -12
-63 -2 -63 13 0 6 10 8 23 4 12 -3 29 -6 37 -6z m-285 -104 c-9 -52 -22 -67
-31 -32 -7 30 13 89 28 80 5 -3 6 -24 3 -48z m83 -88 c19 -19 14 -42 -13 -68
-14 -13 -27 -22 -29 -19 -6 6 16 99 24 99 3 0 11 -5 18 -12z m-141 -102 c3
-27 -2 -79 -15 -133 -11 -48 -25 -128 -32 -178 -6 -49 -16 -112 -22 -140 -10
-44 -12 -47 -19 -25 -11 38 -10 207 2 280 5 36 22 109 36 163 28 104 41 113
50 33z m1751 -296 c132 -26 183 -62 153 -108 -7 -11 -30 -7 -122 24 -123 40
-145 42 -424 32 -55 -1 -108 1 -119 5 -18 7 -18 8 -1 27 21 24 54 31 175 39
127 9 226 3 338 -19z m21 -147 c45 -29 -17 -44 -90 -21 -48 14 -49 24 -6 37
36 10 61 6 96 -16z m-918 -61 c-31 -58 -75 -63 -111 -11 l-21 29 71 0 c64 0
70 -2 61 -18z m832 -29 c9 -2 17 -9 17 -14 0 -10 -20 -11 -72 -3 -23 4 -38 12
-38 21 0 13 35 12 93 -4z m-1869 -59 c4 -31 4 -59 1 -62 -11 -11 -19 10 -23
63 -6 73 13 72 22 -1z m803 20 c3 -8 -2 -23 -11 -32 -15 -15 -16 -15 -16 0 0
9 -3 23 -6 32 -4 11 -1 16 10 16 9 0 19 -7 23 -16z m-852 -157 c30 -134 28
-168 -10 -124 -35 40 -48 98 -39 184 3 41 10 71 14 66 4 -4 20 -61 35 -126z
m1655 74 c0 -9 -71 -24 -78 -16 -2 2 -1 8 3 14 7 13 75 15 75 2z m340 -561 l0
-40 45 0 45 0 0 -38 c0 -27 -9 -51 -29 -79 -27 -38 -89 -93 -104 -93 -3 0 -9
15 -12 34 -5 23 -14 36 -28 41 -12 4 -92 9 -177 12 -116 4 -178 1 -245 -10
-49 -9 -91 -14 -93 -12 -2 2 15 16 38 31 38 25 54 29 168 35 174 11 272 48
351 133 33 35 41 33 41 -14z m-1051 -18 l102 -47 -36 -48 c-40 -53 -40 -52
-158 -33 -99 16 -109 28 -86 111 22 81 39 83 178 17z m-543 -393 c47 -29 32
-44 -16 -17 -21 13 -36 26 -34 30 7 12 13 10 50 -13z m1608 -63 c-6 -16 -24
-23 -24 -8 0 10 22 33 27 28 2 -2 1 -11 -3 -20z m-1420 -18 l61 -23 -44 -3
c-50 -4 -101 14 -101 34 0 19 13 18 84 -8z m466 -134 c0 -12 -6 -15 -22 -10
-13 3 -27 6 -31 6 -5 0 -5 5 -2 10 10 17 55 12 55 -6z m780 -716 c17 -12 30
-25 30 -30 0 -17 -66 -48 -103 -48 l-37 0 37 50 c20 28 39 50 41 50 1 0 16
-10 32 -22z"
      />
      <path
        d="M35665 27029 c-196 -12 -309 -42 -586 -154 -163 -67 -631 -269 -759
-328 l-75 -35 43 -7 c24 -4 40 -11 35 -15 -4 -4 -46 -22 -93 -40 -47 -18 -87
-35 -89 -37 -3 -2 6 -15 20 -29 13 -14 35 -49 49 -77 36 -74 60 -84 179 -69
52 7 95 11 95 10 1 -2 14 -20 29 -40 l28 -38 -59 0 c-75 0 -112 -23 -93 -58
23 -43 54 -54 169 -57 89 -2 117 -7 152 -25 61 -31 117 -24 330 45 237 75 339
100 577 141 200 34 266 40 311 28 20 -6 22 -12 22 -82 l0 -76 57 82 56 82 38
-41 c29 -31 42 -39 60 -34 120 27 156 35 171 35 10 0 26 -16 38 -40 30 -60 65
-52 160 35 45 41 67 55 90 55 19 0 30 -5 30 -14 0 -11 -73 -82 -252 -245 -24
-21 -41 -25 -135 -32 -60 -5 -227 -26 -373 -48 -146 -22 -323 -47 -395 -56
-128 -15 -269 -51 -260 -66 9 -14 174 -22 221 -10 46 12 104 7 104 -8 0 -4
-129 -47 -287 -95 -159 -48 -292 -91 -296 -96 -10 -9 61 -60 108 -78 17 -6
131 -32 255 -57 124 -24 290 -62 370 -84 210 -58 302 -72 475 -72 196 -1 237
-10 291 -65 32 -33 50 -44 73 -44 40 0 51 -22 51 -100 0 -61 0 -62 -57 -122
-72 -75 -134 -108 -203 -108 -28 0 -124 13 -213 30 -89 16 -265 48 -392 71
-362 67 -527 59 -668 -30 -73 -47 -75 -58 -28 -184 45 -121 80 -178 178 -289
112 -126 226 -215 343 -267 30 -13 111 -50 180 -82 153 -71 254 -105 382 -130
136 -26 337 -26 492 1 101 18 125 19 175 9 52 -11 62 -10 94 7 43 23 304 297
387 407 33 43 72 99 86 125 15 26 40 61 55 77 15 17 33 43 39 58 17 46 -5 145
-48 217 -36 61 -37 61 -54 41 -9 -12 -18 -26 -20 -32 -2 -5 -28 4 -58 20 -65
36 -73 38 -65 12 4 -14 0 -23 -15 -31 -29 -15 -38 -5 -16 18 9 11 23 41 30 68
8 27 24 77 36 112 22 59 22 64 7 102 l-15 40 29 21 c35 24 36 37 7 74 -19 25
-20 28 -5 37 21 11 21 44 2 78 -8 14 -14 34 -15 46 0 12 -13 48 -30 80 -21 40
-34 89 -44 154 -8 52 -12 98 -9 103 2 4 14 2 25 -4 20 -11 21 -10 14 50 -9 75
-25 124 -59 175 -30 43 -34 63 -41 196 -3 58 -13 120 -26 160 -24 71 -95 212
-155 304 l-38 59 -38 -41 -39 -41 0 63 c0 84 -17 104 -141 166 -114 58 -221
96 -327 116 -80 15 -456 17 -677 3z m12 -154 c-32 -28 -87 -33 -126 -11 -23
13 -20 14 50 24 108 14 107 14 76 -13z m273 5 c0 -13 -60 -13 -80 0 -11 7 -2
10 33 10 26 0 47 -4 47 -10z m254 -19 c6 -9 -93 -26 -102 -17 -7 8 39 25 70
25 15 1 29 -3 32 -8z m-1059 -61 c-22 -21 -85 -39 -85 -23 0 7 67 35 100 42 2
1 -4 -8 -15 -19z m-131 -45 c-10 -26 -71 -56 -126 -62 -67 -7 -57 3 40 43 91
38 93 38 86 19z m-235 -91 c-27 -29 -90 -64 -115 -64 -16 0 -16 2 1 20 18 20
110 70 129 70 5 0 -1 -12 -15 -26z m1890 -53 c22 -14 -3 -62 -60 -116 -47 -44
-69 -55 -69 -35 0 11 102 159 110 159 3 1 11 -3 19 -8z m191 -128 c0 -7 -11
-25 -26 -40 l-25 -28 5 33 c4 17 9 46 12 64 l6 32 14 -25 c7 -13 13 -29 14
-36z m-220 -402 c0 -5 -36 -32 -80 -61 -61 -40 -84 -50 -92 -42 -14 14 -20 10
82 65 96 52 90 50 90 38z m-855 -321 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10
3 6 8 10 10 10 2 0 7 -4 10 -10z m1375 -693 c0 -42 -32 -97 -56 -97 -18 0 -18
-1 13 87 28 78 43 82 43 10z m-531 -204 c50 -61 44 -87 -38 -166 -55 -53 -75
-67 -100 -67 -36 0 -41 19 -11 38 19 12 28 42 13 42 -5 0 -28 18 -52 38 l-44
38 89 57 c49 31 94 57 101 57 6 0 25 -17 42 -37z m730 -105 c-12 -29 -23 -54
-24 -56 -1 -1 -9 0 -18 3 -20 8 -30 51 -17 75 9 16 68 43 78 34 2 -1 -7 -26
-19 -56z m-106 -228 c-56 -90 -80 -117 -92 -105 -15 15 29 105 68 138 56 47
65 33 24 -33z m-848 -255 c-22 -14 -42 -20 -43 -15 -7 20 20 40 52 40 l31 -1
-40 -24z m140 -154 c-12 -12 -65 -25 -65 -17 0 8 39 24 60 25 9 1 10 -2 5 -8z"
      />
      <path
        d="M3152 26544 c-79 -96 -145 -290 -140 -409 l3 -66 87 252 c49 139 86
254 84 256 -2 2 -18 -13 -34 -33z"
      />
      <path
        d="M5452 25526 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"
      />
      <path
        d="M35610 25320 c0 -5 6 -10 14 -10 8 0 21 -3 30 -6 11 -4 16 -1 16 10
0 11 -9 16 -30 16 -16 0 -30 -4 -30 -10z"
      />
      <path
        d="M36071 23789 c-115 -13 -188 -35 -286 -83 -49 -25 -110 -50 -135 -56
-120 -31 -310 -173 -310 -233 0 -63 128 -277 196 -327 26 -19 95 -70 153 -113
189 -141 250 -162 621 -217 365 -54 508 -69 700 -76 141 -6 182 -4 285 14 154
27 195 39 195 57 0 13 -32 15 -205 15 -125 0 -205 4 -205 10 0 21 51 34 155
38 153 6 192 28 89 51 -53 12 -55 13 -39 32 10 10 37 26 61 35 49 19 115 12
324 -32 63 -13 121 -24 129 -24 8 0 1 14 -18 35 -34 39 -36 50 -20 92 9 24 7
30 -10 42 -30 19 -26 31 24 81 25 24 45 47 45 50 0 3 -20 16 -46 29 -58 31
-92 73 -100 123 -4 22 -13 42 -20 45 -8 3 -42 44 -77 92 l-64 87 -130 57
c-231 101 -473 167 -663 182 l-75 5 69 -25 c38 -14 72 -28 75 -31 10 -11 -127
5 -268 31 -143 27 -299 31 -450 14z m1525 -572 c42 -56 48 -79 30 -122 l-16
-36 48 2 c36 2 46 -1 44 -12 -7 -32 -43 -53 -114 -65 -90 -16 -133 -6 -174 38
-40 43 -98 137 -90 145 8 8 218 92 231 92 6 0 24 -19 41 -42z"
      />
      <path
        d="M37417 23153 c-14 -13 -6 -33 12 -33 36 0 53 11 40 26 -12 15 -41 19
-52 7z"
      />
      <path
        d="M3845 22662 l-170 -36 -335 2 c-385 3 -431 -3 -569 -71 l-83 -42 148
-5 c82 -3 144 -8 138 -12 -7 -4 -135 -24 -285 -43 l-273 -36 -29 -34 c-18 -22
-43 -38 -70 -46 -23 -6 -84 -35 -135 -64 -51 -29 -119 -63 -152 -75 -33 -12
-60 -26 -60 -31 0 -4 18 -14 40 -20 55 -17 76 -42 62 -73 -8 -19 -21 -25 -61
-31 -28 -4 -53 -10 -56 -15 -2 -4 34 -56 81 -116 47 -60 114 -149 148 -199
108 -158 219 -246 365 -289 93 -27 254 -39 372 -27 224 22 1580 391 1677 456
79 53 111 150 92 282 -16 114 -57 206 -153 343 -68 97 -90 118 -169 155 -162
76 -263 82 -523 27z"
      />
      <path
        d="M36215 22516 c-109 -29 -193 -58 -230 -80 l-40 -24 84 -7 c95 -9 94
-10 -39 -62 -112 -43 -163 -73 -187 -109 -16 -24 -16 -30 0 -89 9 -35 17 -97
17 -138 0 -65 3 -79 28 -113 83 -115 277 -249 442 -306 68 -23 521 -109 735
-140 133 -18 206 -22 430 -22 287 -1 322 3 460 55 48 18 55 23 39 29 -35 13
-196 38 -327 51 -70 7 -127 16 -127 21 0 14 82 47 132 54 23 3 92 -2 151 -11
112 -17 157 -15 157 9 0 8 -12 24 -26 35 -22 18 -38 20 -118 18 -72 -2 -100 2
-134 17 -48 22 -50 28 -22 73 23 38 52 55 88 52 15 -1 40 3 55 10 16 6 41 11
57 11 32 0 60 23 60 48 0 23 -99 124 -118 120 -8 -2 -30 -23 -47 -48 -37 -53
-74 -74 -115 -65 -27 6 -30 10 -31 48 -1 89 -14 135 -52 176 -38 42 -45 70
-27 115 17 46 80 30 99 -25 8 -24 15 -30 28 -24 61 26 97 18 158 -35 50 -43
90 -59 107 -42 16 16 -7 39 -98 102 -176 122 -521 250 -674 250 l-45 0 127
-69 c70 -38 143 -72 162 -75 49 -9 53 -22 14 -46 -34 -20 -72 -25 -104 -13
-14 5 -12 11 17 35 l34 29 -40 -8 c-22 -4 -95 -7 -162 -6 -114 2 -125 4 -183
33 -47 24 -73 31 -106 29 -41 -3 -47 0 -87 44 -26 28 -67 57 -103 74 -56 26
-68 27 -224 30 -112 2 -181 -2 -215 -11z m1318 -808 c-19 -19 -26 -20 -48 -10
-36 17 -22 32 28 32 l41 0 -21 -22z"
      />
      <path
        d="M3872 21365 c-62 -14 -1007 -276 -1133 -315 -119 -36 -129 -81 -24
-113 32 -9 61 -19 63 -22 13 -13 -268 -133 -451 -193 l-208 -68 23 -20 c14
-13 61 -30 118 -44 137 -32 147 -53 74 -146 l-45 -59 48 -7 c76 -11 130 -34
217 -93 96 -64 220 -109 363 -130 189 -29 347 -12 536 57 54 19 57 22 57 55 0
45 43 89 95 99 23 4 73 1 121 -8 l82 -14 27 34 c36 45 79 63 285 118 219 58
294 86 354 132 90 69 91 59 -9 291 -82 191 -86 204 -70 221 15 17 15 19 -7 25
-13 4 -47 16 -76 27 l-53 20 3 51 3 52 -60 30 c-54 27 -71 30 -165 32 -62 1
-131 -4 -168 -12z m166 -97 c-5 -13 -75 -34 -84 -26 -2 3 -2 13 2 22 8 20 88
24 82 4z m-328 -23 c0 -4 -7 -19 -15 -35 -17 -34 -62 -60 -100 -60 -52 0 -115
-23 -154 -56 -36 -32 -59 -39 -258 -85 -238 -55 -264 -56 -311 -6 l-23 24 53
22 c29 12 89 27 133 32 113 14 243 46 360 87 141 50 315 93 315 77z m170 -84
c0 -14 -10 -20 -42 -25 -24 -4 -58 -9 -75 -12 -28 -5 -33 -3 -33 13 0 17 10
22 63 30 34 6 68 11 75 12 6 0 12 -7 12 -18z m340 -76 c0 -4 -7 -19 -15 -35
-19 -36 -44 -38 -74 -6 l-23 25 38 6 c22 4 44 8 49 10 16 6 25 5 25 0z m-88
-177 l127 -79 -59 -50 c-32 -27 -62 -49 -65 -49 -3 0 -5 23 -5 50 0 53 -6 63
-69 103 -38 24 -66 59 -76 96 -8 28 -22 34 147 -71z m-557 -450 c33 -15 32
-24 -6 -37 -42 -15 -49 -14 -44 4 3 8 7 22 10 30 5 18 7 18 40 3z"
      />
      <path
        d="M36209 21184 c-19 -23 -61 -43 -118 -55 l-49 -10 1 -62 2 -62 -72
-35 c-40 -19 -82 -44 -94 -56 -30 -31 -38 -84 -27 -172 8 -64 15 -81 37 -102
17 -16 30 -41 34 -65 4 -22 10 -45 14 -50 12 -16 248 -201 314 -245 115 -79
277 -149 549 -238 358 -118 425 -133 641 -148 137 -9 174 -2 245 51 37 28 86
53 132 67 71 22 83 33 61 55 -9 9 3 19 42 39 l54 28 -55 13 c-30 7 -94 18
-142 24 -82 11 -91 10 -137 -10 -78 -33 -126 -25 -179 32 -22 24 -23 28 -12
80 6 31 21 68 32 82 l21 27 16 -48 c26 -76 48 -86 203 -96 73 -5 155 -11 181
-14 55 -7 94 6 128 42 l23 24 -30 31 c-16 17 -36 35 -46 40 -9 5 -103 22 -210
38 -239 37 -275 45 -306 68 -59 44 -52 88 20 124 24 12 45 23 47 25 2 2 -18
23 -44 46 l-47 43 55 -3 c54 -4 97 9 97 28 0 6 -13 10 -29 10 -16 0 -42 5 -56
10 -34 13 -138 94 -126 98 18 6 117 -39 193 -89 94 -62 118 -100 118 -183 0
-82 12 -106 54 -106 70 0 83 12 90 84 16 140 -21 218 -139 297 -58 40 -127 68
-400 164 -276 97 -337 116 -370 111 -24 -3 -152 13 -323 39 -309 49 -372 54
-393 29z m721 -104 c34 -18 34 -19 9 -20 -14 0 -34 9 -44 20 -23 25 -10 25 35
0z"
      />
      <path
        d="M22622 20318 c-7 -7 -12 -21 -12 -33 0 -52 48 -58 108 -14 47 34 52
27 22 -31 -21 -41 -26 -114 -11 -139 8 -12 17 -11 59 7 28 12 58 22 68 22 10
0 14 3 11 7 -4 3 0 17 9 31 8 13 14 24 12 25 -61 30 -87 51 -105 83 -12 22
-33 42 -45 46 -39 11 -103 9 -116 -4z"
      />
      <path
        d="M22472 20298 c-14 -14 -16 -47 -3 -67 7 -11 13 -11 37 1 22 10 29 20
29 43 0 25 -5 31 -25 33 -14 2 -31 -3 -38 -10z"
      />
      <path
        d="M3431 20142 c-221 -42 -277 -60 -239 -74 7 -3 57 -13 111 -23 80 -15
97 -22 97 -37 0 -11 -10 -20 -27 -24 -16 -4 -109 -29 -208 -57 -99 -27 -207
-54 -240 -59 -70 -11 -99 -27 -120 -67 -11 -23 -27 -33 -72 -46 -71 -21 -92
-31 -222 -113 -57 -35 -129 -75 -160 -87 -175 -72 -128 -131 264 -333 140 -72
281 -141 315 -152 216 -73 515 -84 782 -29 175 36 267 77 364 164 l45 40 -22
55 c-25 65 -27 135 -5 177 14 29 14 37 -5 115 -26 102 -41 131 -79 160 -29 21
-30 24 -30 111 l0 90 -91 -6 c-99 -6 -100 -6 -74 55 5 14 -2 18 -42 23 -26 3
-64 9 -83 13 l-34 8 72 59 c46 38 69 62 62 69 -22 22 -123 13 -359 -32z m299
-468 c11 -13 10 -19 -7 -36 l-21 -20 -12 22 c-22 41 12 69 40 34z m80 -123 c0
-11 25 -56 55 -100 50 -73 87 -162 74 -176 -10 -9 -159 -18 -159 -9 0 5 9 23
19 40 l19 32 -23 22 c-18 17 -40 24 -89 27 -36 3 -66 9 -66 13 0 19 68 105
108 136 52 39 62 42 62 15z m-90 -364 c0 -8 -46 -24 -120 -44 -152 -39 -166
-21 -17 24 116 35 137 38 137 20z"
      />
      <path
        d="M22398 20098 c-14 -18 -50 -71 -80 -118 -30 -47 -83 -120 -117 -163
-34 -43 -60 -81 -57 -84 11 -11 95 48 143 100 56 62 115 169 132 241 7 27 10
50 8 52 -2 3 -15 -10 -29 -28z"
      />
      <path d="M22898 20123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
      <path
        d="M36540 19890 c-168 -8 -179 -11 -365 -92 -94 -41 -100 -45 -103 -75
-3 -26 4 -40 34 -69 l37 -38 -24 -20 c-14 -12 -36 -28 -49 -37 -23 -15 -24
-17 -12 -69 42 -181 189 -338 423 -450 96 -46 195 -73 324 -89 120 -15 676
-34 743 -26 l54 7 -82 47 c-88 52 -88 61 3 61 36 0 37 1 37 36 0 29 -7 43 -37
70 -21 19 -53 47 -70 63 -21 19 -33 39 -33 55 0 32 5 32 55 1 47 -29 67 -31
101 -8 l25 16 -40 41 c-22 23 -46 57 -54 76 -13 32 -33 150 -25 150 2 0 33 -9
71 -19 84 -24 91 -8 17 38 -27 17 -50 35 -50 40 0 8 50 6 118 -3 33 -5 27 9
-25 65 -76 81 -260 158 -499 209 -115 25 -329 32 -574 20z m721 -513 c13 -50
52 -108 114 -167 25 -25 56 -60 67 -79 21 -33 21 -34 3 -47 -34 -25 -49 -18
-84 42 -21 35 -75 64 -120 64 -36 0 -36 0 -14 62 l17 48 -32 33 c-32 32 -41
72 -26 111 11 28 62 -18 75 -67z m177 71 c19 -19 15 -25 -34 -63 -59 -45 -74
-45 -74 -1 0 56 73 99 108 64z m-203 -418 c-3 -5 -13 -10 -21 -10 -8 0 -14 5
-14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z"
      />
      <path
        d="M17550 19734 c-19 -8 -35 -16 -37 -18 -7 -6 59 -152 83 -183 14 -18
57 -58 97 -90 40 -31 74 -58 77 -60 2 -2 -1 -13 -8 -23 -18 -30 -15 -48 13
-66 14 -9 55 -55 92 -103 37 -47 137 -169 223 -271 86 -102 183 -221 217 -265
70 -93 89 -113 166 -178 32 -27 67 -67 78 -89 19 -38 119 -404 119 -438 0 -8
-9 -31 -19 -50 -23 -43 -35 -42 -118 12 -55 35 -68 39 -111 36 -47 -3 -49 -2
-104 54 -46 48 -66 60 -116 74 -34 9 -71 28 -83 40 -21 23 -21 24 -4 51 10 15
27 35 38 44 27 22 16 54 -16 45 -12 -4 -45 -9 -73 -13 l-50 -5 -51 53 c-28 30
-77 87 -108 128 -70 91 -89 107 -193 168 l-83 48 3 62 3 62 -37 -6 c-53 -8
-82 14 -97 73 -35 138 -117 206 -247 206 -56 0 -61 2 -56 20 6 25 -13 38 -55
38 -31 0 -32 -1 -21 -31 6 -16 8 -32 6 -35 -3 -2 -31 7 -62 22 -43 19 -64 24
-84 18 -15 -4 -76 -8 -135 -8 l-108 -1 -52 -67 -51 -67 -113 59 -112 60 -6
-47 c-11 -76 -14 -83 -43 -83 -24 0 -30 -8 -58 -78 -34 -81 -37 -84 -98 -67
-28 8 -28 7 -13 -16 35 -52 49 -117 58 -264 12 -183 27 -259 74 -373 28 -67
36 -100 35 -143 -1 -68 -7 -53 208 -581 94 -230 173 -426 177 -438 11 -34 -19
-24 -61 22 -103 110 -112 118 -140 118 -19 0 -40 -12 -64 -35 l-36 -35 -66 67
c-36 38 -77 92 -92 120 -38 77 -57 86 -160 77 -79 -6 -88 -5 -135 20 -28 14
-57 23 -66 20 -19 -8 -151 -182 -165 -218 -16 -44 -13 -61 42 -199 99 -252
235 -491 375 -659 141 -169 328 -314 463 -358 198 -65 439 -6 575 142 97 105
108 215 41 418 -19 58 -32 108 -28 111 4 3 88 14 187 24 99 9 197 24 218 32
40 17 67 48 86 103 25 75 64 97 244 135 74 16 77 18 77 46 0 15 -11 63 -25
106 -25 80 -33 153 -15 153 5 0 53 -24 107 -53 206 -113 399 -187 595 -228
116 -25 325 -30 573 -16 165 10 226 25 278 67 38 32 103 118 94 126 -2 3 -42
11 -88 20 -124 22 -121 19 -60 73 l54 48 -17 43 c-9 23 -15 44 -13 47 3 2 23
-4 46 -13 23 -9 44 -14 46 -12 5 5 -7 60 -50 238 -17 74 -21 113 -17 177 8
138 -19 168 -165 179 l-78 7 62 -42 c88 -60 113 -92 113 -147 l0 -45 -29 20
c-43 31 -99 28 -148 -8 l-40 -29 -14 31 c-16 37 -47 45 -82 22 -23 -15 -25
-14 -30 4 -7 27 7 85 27 116 9 13 16 30 16 38 0 7 17 25 37 38 70 46 78 66 78
202 1 67 4 133 8 147 4 14 3 74 -2 134 l-9 109 -49 51 c-109 113 -133 147
-133 186 0 39 -14 57 -64 82 l-28 15 46 38 c57 47 61 84 16 150 -34 49 -242
245 -310 292 -25 17 -156 89 -292 161 -241 127 -250 131 -335 141 -49 6 -128
11 -178 11 -108 0 -134 13 -194 94 -23 31 -50 60 -60 66 -27 14 -70 12 -111
-6z m282 -408 c8 -11 -8 -46 -21 -46 -5 0 -12 12 -15 28 -4 15 -10 36 -13 47
l-6 20 24 -20 c13 -11 27 -24 31 -29z m-534 -392 c16 -18 22 -38 22 -72 l0
-46 -36 35 c-39 37 -64 78 -64 105 0 24 49 10 78 -22z m1337 -115 c-10 -15
-25 -10 -19 6 4 8 10 12 15 9 5 -3 7 -10 4 -15z m-76 -20 c31 -11 32 -14 26
-48 -4 -20 -10 -39 -13 -42 -4 -4 -36 12 -70 36 -56 38 -62 44 -45 54 24 14
62 14 102 0z m442 -577 c-5 -10 -17 -25 -26 -32 -13 -11 -18 -10 -30 6 -20 27
-19 32 13 37 54 8 53 8 43 -11z m-27 -94 c8 -40 4 -43 -32 -24 -42 22 -47 34
-20 45 38 16 45 13 52 -21z m-12 -115 c9 -10 19 -29 23 -43 4 -14 37 -55 72
-91 43 -45 60 -69 53 -74 -17 -11 -200 -6 -221 5 -23 13 -56 85 -64 142 -6 44
-4 48 22 62 39 21 96 20 115 -1z m-53 -357 c16 -10 13 -52 -4 -66 -12 -10 -22
-6 -55 20 -47 37 -50 61 -13 88 l27 20 17 -28 c10 -15 22 -31 28 -34z m431
-352 c22 -9 40 -20 40 -24 0 -12 -114 -59 -196 -80 -54 -14 -109 -20 -183 -20
-123 1 -119 9 17 39 87 19 129 35 212 81 43 24 58 25 110 4z m-1520 -141 l0
-37 -35 34 c-23 22 -35 43 -35 60 0 41 12 44 42 11 20 -22 28 -42 28 -68z
m-1621 70 c21 -5 19 -8 -30 -39 -35 -23 -65 -34 -89 -34 -51 0 -55 10 -15 37
60 40 89 48 134 36z m82 -253 c19 -11 28 -70 11 -70 -4 0 -26 18 -47 40 l-39
40 28 0 c15 0 36 -5 47 -10z m75 -100 c8 0 14 -5 14 -11 0 -15 -53 -6 -58 10
-3 10 0 12 12 8 10 -4 24 -7 32 -7z m1183 -65 c24 -7 24 -8 6 -21 -24 -18 -49
-18 -65 1 -15 18 -4 37 18 31 10 -2 28 -8 41 -11z m-623 -88 l69 -65 -28 -11
c-30 -12 -67 0 -67 20 0 6 -23 32 -51 59 -43 42 -50 52 -39 65 16 19 31 11
116 -68z m-151 23 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6
0 7 -4 4 -10z"
      />
      <path
        d="M22300 19530 c-116 -26 -163 -46 -211 -86 -23 -19 -53 -37 -68 -40
-119 -26 -201 -86 -246 -179 -19 -37 -31 -52 -41 -48 -11 4 -13 16 -8 49 3 25
2 44 -3 44 -15 0 -105 -60 -160 -106 -60 -51 -260 -295 -278 -339 -6 -16 -20
-52 -30 -80 -13 -36 -39 -71 -93 -124 -84 -84 -111 -134 -112 -204 0 -46 -3
-52 -49 -93 -83 -75 -121 -181 -121 -340 l0 -86 -34 21 -34 21 -21 -49 c-28
-65 -51 -165 -51 -222 0 -92 88 -293 178 -405 62 -77 139 -138 292 -232 120
-74 127 -77 195 -80 90 -5 418 32 520 58 101 26 156 58 232 136 34 35 66 64
72 64 12 0 43 -65 96 -203 75 -192 143 -234 216 -134 30 42 30 42 89 39 75 -5
112 16 110 62 -4 59 0 67 35 82 70 29 63 39 185 -268 62 -156 133 -323 157
-373 149 -297 330 -435 613 -467 122 -14 184 -5 222 32 15 16 28 37 28 47 0
36 55 108 103 133 25 13 50 35 56 47 10 22 9 23 -47 23 -31 0 -64 5 -72 10
-24 15 23 56 91 79 l58 19 3 -21 c2 -16 10 -22 27 -22 33 0 38 23 7 31 -30 7
-30 10 -11 44 8 14 14 48 15 75 0 59 15 85 67 116 72 43 92 69 119 155 37 122
56 146 149 186 21 9 31 22 36 47 9 45 -8 94 -30 86 -9 -4 -22 -9 -29 -12 -8
-3 -3 16 13 52 35 80 33 125 -6 164 -17 17 -37 31 -46 31 -25 0 -141 -81 -278
-195 -122 -101 -237 -171 -258 -158 -5 3 -40 52 -78 110 l-69 105 0 254 0 254
26 6 c17 4 23 11 20 21 -17 44 -15 63 17 133 19 41 53 132 76 201 40 122 63
166 126 245 28 35 30 43 28 112 -2 55 3 87 19 125 12 31 21 77 22 114 1 56 -2
65 -29 93 -39 42 -105 87 -138 96 -42 10 -72 -2 -134 -57 -32 -28 -59 -46 -61
-40 -2 6 1 41 7 78 6 37 8 69 6 71 -11 11 -36 -10 -54 -45 -13 -26 -21 -33
-25 -23 -14 40 -26 98 -26 126 0 40 -32 74 -106 113 -93 48 -124 83 -124 138
0 18 -2 33 -5 33 -21 0 -63 -44 -109 -114 -51 -78 -124 -156 -146 -156 -13 0
-13 1 11 82 27 88 18 102 -43 68 -93 -51 -139 -89 -293 -239 -88 -86 -184
-176 -214 -200 -67 -56 -91 -94 -91 -149 0 -24 -6 -55 -14 -70 -8 -15 -82 -93
-165 -174 -158 -154 -170 -162 -225 -137 -23 11 -43 -1 -270 -160 -136 -95
-251 -169 -256 -166 -6 3 -10 19 -10 35 0 18 -11 42 -30 64 -46 52 -60 104
-60 227 0 98 2 111 21 130 19 19 21 28 14 78 -13 96 -13 93 23 96 44 4 60 20
73 70 7 26 30 66 60 102 68 79 107 163 123 259 7 43 18 89 25 101 14 26 51 48
101 57 53 11 90 27 90 40 0 6 -7 22 -16 34 -18 26 -11 42 18 42 32 0 116 50
144 87 14 18 45 73 69 121 44 90 110 163 178 199 19 10 26 18 19 25 -18 18
-86 17 -172 -2z m-466 -357 c-25 -34 -35 -37 -59 -19 -19 14 -18 16 25 35 24
11 47 18 49 15 3 -2 -4 -16 -15 -31z m-344 -289 c0 -8 -5 -12 -10 -9 -6 4 -8
11 -5 16 9 14 15 11 15 -7z m65 -74 c-10 -11 -22 -17 -28 -13 -5 3 1 14 14 24
30 22 38 16 14 -11z m1330 -50 c3 -6 -1 -13 -10 -16 -19 -8 -30 0 -20 15 8 14
22 14 30 1z m-85 -86 c0 -3 -16 -30 -37 -61 -38 -57 -100 -107 -127 -101 -13
2 -4 22 39 86 52 76 58 82 90 82 19 0 35 -3 35 -6z m-1420 -179 c0 -12 -7 -28
-15 -35 -13 -11 -15 -6 -15 35 0 41 2 46 15 35 8 -7 15 -23 15 -35z m2035
-156 c9 -14 -63 -122 -75 -114 -16 10 -12 22 23 75 33 51 41 57 52 39z m-1895
-129 c0 -4 -11 -10 -25 -12 -27 -6 -32 1 -14 19 12 12 39 7 39 -7z m230 -468
c0 -14 -23 -32 -42 -32 -21 0 -23 11 -6 28 13 13 48 16 48 4z m-80 -55 c0 -18
-39 -53 -67 -62 -32 -9 -30 18 2 50 26 27 65 34 65 12z m111 -326 c29 -18 30
-20 12 -29 -12 -7 -34 -7 -66 1 -50 11 -57 20 -29 36 26 15 48 13 83 -8z
m1019 -70 c0 -34 -15 -49 -58 -57 -28 -6 -32 -4 -32 14 0 21 69 84 82 75 5 -2
8 -17 8 -32z m-1130 -11 c0 -11 -79 -34 -88 -26 -9 9 4 18 40 26 46 11 48 11
48 0z m1020 -14 c0 -2 -18 -6 -40 -8 -45 -4 -52 4 -17 19 19 9 57 1 57 -11z
m-693 -53 c-41 -80 -90 -113 -166 -113 -39 0 -131 27 -131 38 0 11 315 141
318 132 2 -5 -8 -30 -21 -57z m569 -60 c-4 -16 -17 -36 -29 -46 -51 -41 -66
-59 -73 -90 -7 -35 -19 -47 -46 -47 -14 0 -18 8 -18 38 0 43 17 80 63 133 26
31 39 37 71 38 37 1 38 0 32 -26z m1564 -745 c0 -19 -18 -43 -25 -36 -4 3 -2
16 5 27 12 24 20 27 20 9z m-339 -190 c7 -13 6 -18 -4 -18 -21 0 -37 22 -37
50 1 24 1 25 16 5 9 -11 20 -28 25 -37z m189 -7 c-15 -29 -60 -61 -84 -61 -18
0 -15 6 21 40 46 43 82 55 63 21z"
      />
      <path
        d="M21389 19385 c-30 -16 -62 -76 -57 -106 3 -20 14 -8 96 103 17 23 -1
24 -39 3z"
      />
      <path
        d="M21209 19203 c-68 -71 -143 -203 -117 -203 22 0 103 95 131 153 38
79 32 99 -14 50z"
      />
      <path
        d="M20963 18820 c-36 -21 -46 -48 -41 -106 l3 -39 44 75 c24 41 46 78
48 83 8 14 -22 7 -54 -13z"
      />
      <path
        d="M3980 18819 c-19 -4 -78 -13 -130 -18 -121 -13 -470 -122 -470 -147
0 -12 6 -15 23 -11 12 3 47 8 77 12 l55 5 -61 -29 c-33 -16 -256 -108 -495
-204 -239 -97 -454 -184 -478 -194 l-44 -19 53 -44 c48 -40 51 -45 35 -57 -10
-7 -41 -19 -70 -27 -55 -16 -101 -56 -92 -79 3 -8 50 -32 104 -53 66 -26 114
-52 148 -82 56 -48 145 -94 218 -112 69 -18 91 -49 111 -162 9 -51 28 -131 43
-176 31 -103 28 -150 -16 -249 -36 -84 -51 -151 -51 -237 1 -158 63 -309 175
-422 78 -80 142 -117 242 -140 121 -28 139 -49 193 -224 36 -118 126 -353 148
-388 19 -29 55 -48 110 -57 62 -10 269 -119 319 -167 51 -50 85 -126 85 -188
0 -25 -10 -114 -21 -199 -25 -183 -27 -372 -6 -475 20 -98 132 -427 184 -543
150 -332 308 -503 680 -736 104 -65 120 -72 176 -75 l62 -4 56 56 c30 31 60
56 67 56 6 0 9 8 6 18 -2 9 -8 40 -11 69 l-7 52 102 58 101 58 -6 45 c-11 86
-47 216 -83 299 -55 131 -419 815 -561 1054 -81 138 -170 247 -261 320 -158
127 -287 170 -396 133 l-48 -16 -43 42 c-24 23 -43 45 -43 48 0 3 36 13 81 23
100 23 158 47 219 92 l47 35 -4 87 c-5 78 -2 96 21 157 35 91 34 158 -5 342
-41 200 -62 237 -154 279 -91 40 -120 93 -131 240 -11 153 23 303 130 560 68
164 88 250 89 380 1 93 -4 125 -28 209 -35 124 -31 139 48 178 l57 28 -15 37
c-9 21 -23 46 -32 56 -16 17 -152 93 -163 91 -3 -1 -20 -4 -38 -8 l-33 -6 6
50 c17 160 18 146 -15 183 -64 74 -31 121 105 150 18 3 17 5 -7 15 -85 35
-275 52 -358 31z m-196 -54 c6 -16 -26 -55 -44 -55 -5 0 -2 16 7 35 18 38 28
43 37 20z m546 -398 c0 -13 -6 -32 -14 -43 -14 -18 -15 -18 -21 -1 -4 10 0 28
9 42 20 31 26 31 26 2z m94 -96 c3 -4 -5 -13 -18 -19 -19 -8 -25 -8 -30 3 -7
20 37 35 48 16z m-1213 -537 c28 -14 30 -19 24 -52 -4 -20 -15 -52 -25 -72
-26 -50 -130 -185 -143 -185 -6 0 -22 22 -36 50 -22 42 -26 63 -26 138 0 85 1
90 28 113 23 20 39 24 88 24 35 0 73 -7 90 -16z"
      />
      <path
        d="M36158 18644 c-35 -42 -50 -54 -75 -60 -79 -18 -103 -26 -125 -43
-21 -18 -23 -27 -23 -108 l-1 -88 -52 3 c-37 2 -52 -1 -52 -10 0 -38 44 -287
75 -430 20 -89 47 -217 60 -283 50 -250 118 -389 271 -556 95 -104 78 -166
-79 -288 -142 -109 -322 -266 -347 -301 -23 -32 -49 -135 -37 -147 4 -4 26 12
50 34 37 37 107 71 95 47 -2 -5 -17 -36 -33 -69 -26 -55 -29 -73 -37 -215 -4
-85 -12 -184 -17 -220 -6 -36 -11 -68 -11 -72 0 -5 -20 -8 -44 -8 -53 0 -86
24 -89 65 -2 23 4 32 27 43 32 16 32 11 4 226 -10 82 -12 87 -24 65 -38 -67
-54 -153 -63 -333 -6 -114 -15 -196 -24 -221 -8 -22 -29 -77 -46 -123 -17 -45
-34 -108 -37 -140 -11 -91 -17 -102 -58 -95 -28 4 -39 1 -58 -20 -13 -14 -40
-43 -62 -66 l-38 -41 67 0 68 0 -7 -41 c-9 -59 -37 -108 -115 -201 l-70 -83 6
-62 c5 -52 3 -63 -9 -63 -21 0 -37 21 -44 58 -7 36 -14 38 -52 18 -57 -30 -83
-75 -217 -381 -76 -170 -153 -345 -172 -388 -59 -134 -56 -213 14 -355 40 -82
64 -99 144 -109 27 -3 49 -9 49 -12 0 -3 -22 -19 -50 -34 -62 -35 -67 -43 -42
-70 11 -12 27 -38 35 -57 26 -64 101 -110 180 -110 44 0 93 -25 147 -76 56
-52 73 -52 95 -2 7 18 12 34 11 36 -2 1 -22 -5 -46 -13 -59 -21 -60 -20 -60
28 0 55 25 81 99 99 l55 14 -50 19 c-92 34 -235 109 -274 142 -41 35 -77 111
-66 139 5 13 12 8 36 -25 33 -44 31 -44 105 -31 67 11 100 3 135 -32 27 -26
33 -28 77 -22 46 6 51 4 98 -35 l49 -40 47 44 c51 48 75 54 139 35 32 -10 37
-8 61 20 14 17 57 90 94 161 81 156 100 181 129 177 35 -5 48 -46 34 -112 -15
-78 -1 -91 44 -41 63 72 78 101 123 239 46 141 53 180 35 191 -15 9 -12 58 5
81 8 10 15 40 15 65 0 53 7 61 97 110 59 32 64 33 80 16 22 -21 22 -25 -18
-398 -28 -267 -31 -312 -18 -319 30 -19 69 119 109 389 24 164 46 232 93 286
l33 37 38 -46 38 -47 30 28 c67 63 98 137 123 297 27 166 13 211 -121 408
-147 216 -174 291 -174 486 l0 114 35 40 c48 57 69 125 69 230 l1 87 38 7 c45
7 99 51 117 94 13 32 25 33 80 5 22 -12 28 -10 60 14 19 15 71 47 114 72 105
59 131 96 123 177 -3 31 -9 66 -12 77 -5 15 2 25 25 38 l31 19 -7 -28 c-6 -22
-4 -26 8 -21 9 3 34 11 57 18 33 10 39 15 30 26 -8 9 -23 11 -47 7 l-35 -7 27
35 c14 20 24 42 21 50 -4 10 12 30 45 58 l51 43 -23 24 c-51 55 -117 68 -173
33 l-33 -20 -4 25 c-9 45 3 74 49 115 l44 41 37 -41 c27 -30 47 -43 76 -47 38
-6 38 -6 25 19 -8 13 -68 109 -134 212 -138 217 -162 241 -242 240 -47 0 -54
3 -59 21 -3 13 5 41 19 67 23 43 24 50 12 95 -20 81 -28 186 -13 181 7 -3 121
-33 253 -68 185 -48 250 -61 287 -58 86 9 79 28 -32 93 -94 55 -92 65 18 65
82 0 155 -22 232 -69 28 -17 50 -27 50 -23 0 4 -13 41 -29 82 -19 49 -34 74
-43 71 -7 -2 -49 -6 -93 -9 -92 -5 -143 12 -178 60 -19 26 -20 29 -4 46 24 26
22 30 -18 38 -32 6 -35 9 -35 41 0 19 7 47 14 62 15 29 74 85 98 94 17 6 -55
82 -102 107 -91 47 -638 229 -839 280 -96 24 -128 38 -156 70 -30 34 -77 50
-144 50 -43 0 -64 -5 -73 -16z m1002 -584 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1180 -105 c33 -17 37 -36 16 -86
-10 -24 -16 -29 -20 -19 -18 49 -36 104 -36 111 0 12 7 11 40 -6z m540 -195
c14 -10 22 -22 18 -25 -9 -10 -70 24 -63 35 9 14 17 12 45 -10z m290 -125 c7
-9 10 -18 7 -22 -8 -7 -37 15 -37 28 0 14 16 11 30 -6z m-80 -102 c0 -17 -4
-16 -43 3 -40 21 -40 21 -33 33 9 14 76 -18 76 -36z m203 -503 c19 -27 22 -65
7 -75 -8 -5 -150 82 -150 92 0 7 32 67 42 80 9 11 62 -39 101 -97z m206 -9
l24 -7 -32 -37 c-18 -20 -36 -37 -40 -37 -12 0 -62 70 -56 80 7 11 67 12 104
1z m110 -324 c8 -11 5 -13 -19 -9 -17 2 -33 8 -36 13 -9 14 42 10 55 -4z
m-259 -175 c0 -11 -22 -32 -32 -32 -5 0 -4 9 2 20 11 20 30 28 30 12z m-228
-243 c-35 -31 -52 -37 -52 -16 0 13 72 58 80 50 3 -2 -10 -18 -28 -34z m-305
-331 c24 -22 31 -72 12 -83 -9 -6 -94 19 -108 32 -5 4 60 73 68 73 3 0 15 -10
28 -22z m-637 -273 c0 -10 -75 -145 -81 -145 -3 0 -13 16 -22 35 -20 43 -10
77 30 99 27 15 73 21 73 11z m88 -104 c20 -12 -6 -77 -64 -154 -38 -52 -50
-63 -57 -51 -13 22 -11 99 3 126 40 76 80 103 118 79z m-208 -110 c0 -20 -13
-45 -40 -75 -21 -26 -43 -53 -48 -62 -8 -15 -14 -15 -52 -2 l-43 14 88 77 c48
42 89 77 91 77 2 0 4 -13 4 -29z m495 -71 c-3 -5 -15 -10 -26 -10 -10 0 -19 5
-19 10 0 6 12 10 26 10 14 0 23 -4 19 -10z m300 -245 c0 -132 -15 -198 -43
-193 -15 3 -17 24 -20 161 l-3 158 33 -3 33 -3 0 -120z m-56 -345 c7 -36 15
-76 18 -88 6 -24 5 -24 -24 -8 -35 17 -43 33 -43 80 0 41 20 100 30 89 4 -4
13 -37 19 -73z m-1253 -150 c-3 -22 -9 -58 -12 -80 -3 -22 -23 -67 -45 -100
-54 -84 -76 -130 -84 -175 -15 -82 -45 -87 -45 -7 0 67 18 139 54 217 30 66
112 185 127 185 7 0 9 -15 5 -40z m960 -254 c-11 -45 -45 -116 -56 -116 -16 0
-12 23 15 84 25 56 53 78 41 32z m-86 -245 c0 -27 -7 -46 -25 -65 -33 -35 -55
-34 -55 4 0 28 51 100 71 100 5 0 9 -17 9 -39z m-1114 -14 c-6 -26 -34 -43
-47 -30 -5 5 1 23 13 41 23 37 45 30 34 -11z m-61 -147 c-4 -11 -24 -57 -45
-103 l-38 -82 -11 40 c-23 85 -7 127 59 154 37 14 42 13 35 -9z m215 -599 c0
-10 -6 -24 -14 -30 -11 -10 -16 -8 -21 8 -3 12 -3 25 0 31 11 17 35 11 35 -9z"
      />
      <path
        d="M20855 18618 c-10 -28 -9 -61 1 -65 10 -3 34 45 34 68 0 14 -30 10
-35 -3z"
      />
      <path
        d="M4390 18480 c-8 -5 -11 -12 -8 -16 10 -9 58 4 58 16 0 13 -30 13 -50
0z"
      />
      <path
        d="M20780 18310 c0 -8 -3 -25 -6 -37 -4 -14 -2 -23 5 -23 16 0 26 49 12
63 -8 8 -11 7 -11 -3z"
      />
      <path
        d="M18000 18285 c-11 -13 -8 -14 21 -9 47 9 60 24 22 24 -17 0 -36 -7
-43 -15z"
      />
      <path
        d="M19295 18161 c-7 -13 17 -24 40 -19 9 1 16 8 16 13 0 14 -48 19 -56
6z"
      />
      <path
        d="M37584 18139 c-7 -12 -5 -15 23 -23 30 -10 37 9 8 22 -16 8 -26 8
-31 1z"
      />
      <path d="M37558 17933 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
      <path d="M14782 15831 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />
      <path
        d="M15435 15350 c-11 -17 5 -32 21 -19 7 6 11 15 8 20 -7 12 -21 11 -29
-1z"
      />
      <path
        d="M35286 15104 c-9 -42 -15 -79 -12 -81 2 -2 13 8 25 23 14 17 21 41
21 68 0 75 -16 71 -34 -10z"
      />
      <path
        d="M24100 14353 c-160 -219 -161 -220 -146 -226 9 -4 53 48 130 153 118
162 129 180 111 186 -5 2 -48 -49 -95 -113z"
      />
      <path
        d="M16212 14395 c-21 -16 -30 -35 -14 -35 15 1 44 29 40 40 -2 6 -11 5
-26 -5z"
      />
      <path
        d="M23806 13898 c-13 -33 -12 -48 4 -48 8 0 30 53 30 71 0 21 -21 6 -34
-23z"
      />
      <path
        d="M24767 13347 c-36 -31 -37 -34 -20 -49 17 -16 20 -14 51 23 18 21 30
44 27 49 -9 15 -17 12 -58 -23z"
      />
      <path
        d="M5583 12896 c-17 -8 -44 -33 -59 -56 -25 -37 -26 -44 -16 -79 17 -55
14 -92 -8 -121 -24 -30 -25 -62 -4 -199 27 -186 81 -373 145 -506 35 -72 378
-595 487 -741 139 -188 334 -374 534 -511 100 -69 219 -123 269 -123 69 0 178
76 227 158 25 43 27 56 27 147 l0 100 40 5 c39 5 40 6 37 41 -3 32 2 41 43 76
l46 40 -71 118 c-146 242 -201 307 -415 488 -254 216 -458 437 -652 708 -184
258 -331 396 -468 438 -94 29 -126 33 -162 17z m1497 -1902 l0 -56 -37 7 c-21
4 -51 12 -67 19 l-30 12 65 37 c35 20 65 36 67 37 1 0 2 -25 2 -56z m-62 -126
c58 -9 66 -14 93 -51 17 -23 25 -43 19 -45 -5 -2 -47 4 -92 13 -89 18 -162 50
-193 85 l-20 23 65 -7 c36 -4 93 -12 128 -18z"
      />
      <path
        d="M24680 12830 c0 -45 -2 -44 65 -26 l50 13 -52 26 c-29 15 -55 27 -58
27 -3 0 -5 -18 -5 -40z"
      />
      <path
        d="M34400 12741 c0 -20 -28 -72 -88 -163 -48 -73 -100 -153 -115 -178
-15 -25 -54 -83 -87 -130 -43 -60 -68 -108 -85 -165 -29 -91 -42 -110 -107
-154 -39 -26 -102 -109 -355 -464 -235 -331 -324 -447 -377 -497 -203 -188
-292 -297 -428 -523 l-77 -129 26 -34 c22 -30 25 -40 19 -84 -9 -68 5 -71 89
-20 l68 42 74 -29 74 -28 -59 -17 c-32 -10 -67 -27 -78 -39 l-19 -22 66 7 66
7 23 -56 c12 -30 26 -58 31 -61 5 -3 26 9 46 25 21 17 76 59 123 93 47 35 103
87 124 116 l40 53 18 -46 18 -46 -66 -92 c-36 -51 -74 -108 -84 -127 -22 -43
-56 -75 -94 -89 -23 -10 -26 -14 -16 -26 18 -22 124 -19 202 5 63 19 92 39
208 144 79 71 138 106 179 106 28 0 42 8 77 45 40 43 44 45 98 45 l56 0 0 60
0 60 52 26 c98 50 187 116 231 173 24 31 98 133 164 226 67 94 161 221 211
283 85 108 96 128 235 425 257 552 295 655 283 769 -7 71 -34 130 -90 196 -27
32 -48 68 -55 99 -14 53 -45 93 -72 93 -13 0 -24 -21 -44 -80 -18 -53 -32 -80
-42 -80 -11 0 -14 7 -9 23 22 90 26 125 17 150 -14 37 -100 127 -120 127 -9 0
-41 -9 -71 -21 -49 -19 -61 -20 -105 -9 -37 9 -57 9 -79 1 -26 -10 -32 -9 -56
14 -34 32 -40 32 -40 -4z m375 -972 c4 -5 -59 -188 -76 -222 -20 -39 -82 -97
-104 -97 -12 0 -30 9 -41 21 -19 21 -18 22 56 166 l75 145 43 -5 c24 -3 45 -6
47 -8z m-164 -350 c9 -18 -6 -55 -108 -262 -66 -133 -127 -248 -136 -255 -14
-12 -24 -8 -78 34 -36 29 -55 50 -47 53 72 22 127 49 159 78 61 55 49 76 -27
46 -30 -11 -10 61 46 169 27 54 50 104 50 112 0 11 73 38 122 45 4 1 12 -8 19
-20z m-298 -196 c7 -34 -70 -205 -149 -333 -62 -99 -89 -129 -105 -113 -7 7 1
33 26 84 64 129 208 389 216 389 4 0 9 -12 12 -27z m-71 -360 c28 -26 28 -26
63 -10 19 9 35 12 35 7 0 -19 -91 -194 -135 -260 -60 -90 -154 -188 -188 -196
-23 -5 -33 0 -65 33 -34 36 -96 129 -97 145 0 3 31 31 69 61 l70 56 30 -16
c17 -9 47 -26 67 -39 l36 -23 32 33 c34 36 36 46 19 110 -10 38 -9 50 7 85 10
23 20 41 23 41 3 0 19 -12 34 -27z m-215 -82 c9 -4 6 -15 -9 -39 -24 -38 -33
-33 -44 22 -6 37 -6 38 17 31 13 -4 29 -10 36 -14z m-305 -372 l39 -32 15 22
c21 30 34 17 34 -34 0 -55 -14 -92 -48 -127 -32 -35 -39 -35 -66 -1 -36 44
-45 66 -46 104 0 35 17 99 27 99 3 0 23 -14 45 -31z m-151 -122 c-28 -28 -35
-17 -21 34 l12 44 15 -29 c14 -26 13 -30 -6 -49z m-477 -94 c-39 -28 -45 -10
-11 33 l22 29 3 -25 c3 -14 -3 -29 -14 -37z"
      />
      <path
        d="M13095 12378 c-21 -71 -21 -272 0 -353 9 -32 18 -61 20 -64 3 -2 5
102 5 232 0 131 -2 237 -5 237 -2 0 -11 -24 -20 -52z"
      />
      <path
        d="M12926 11678 c-5 -27 -5 -65 -1 -85 8 -46 46 -132 51 -118 2 6 -7 64
-19 130 -24 119 -24 120 -31 73z"
      />
      <path
        d="M33120 9915 c27 -29 37 -27 20 4 -6 12 -18 21 -27 21 -13 0 -12 -5 7
-25z"
      />
      <path
        d="M19267 5983 c-10 -10 -8 -41 3 -48 5 -3 21 -3 35 1 l26 6 -22 24
c-24 25 -31 28 -42 17z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M30730 38900 c-20 -20 -37 -67 -27 -76 7 -7 70 20 83 37 6 8 15 24
18 37 7 19 4 22 -23 22 -18 0 -40 -9 -51 -20z"
      />
      <path
        d="M22015 36720 c-27 -4 -102 -19 -165 -33 -63 -15 -185 -38 -270 -52
-263 -44 -366 -79 -505 -176 -103 -71 -170 -146 -308 -344 -187 -271 -199
-285 -242 -311 -53 -31 -75 -31 -75 -1 0 42 -31 131 -68 192 -50 83 -293 324
-412 408 -263 185 -454 241 -916 269 -302 18 -702 -8 -959 -62 -236 -49 -401
-121 -635 -277 -371 -247 -636 -466 -878 -727 -145 -156 -146 -169 -1 -35 68
63 160 144 204 179 75 59 80 62 83 40 3 -18 -33 -59 -180 -207 -182 -182 -219
-209 -234 -168 -9 23 -20 18 -34 -15 -27 -64 -96 -108 -405 -260 -396 -194
-509 -244 -540 -236 -13 3 -61 48 -106 99 -46 50 -167 175 -269 277 -139 137
-214 221 -290 325 -174 235 -338 379 -545 481 -172 84 -248 99 -495 98 l-205
0 -171 -57 c-94 -31 -243 -72 -331 -92 -241 -53 -324 -86 -391 -153 -71 -71
-198 -303 -224 -407 -6 -27 -23 -62 -37 -78 l-25 -28 -16 32 -17 31 -34 -48
c-96 -135 -140 -230 -163 -354 -9 -46 -32 -120 -52 -164 -68 -153 -86 -208
-88 -279 -3 -82 -14 -97 -46 -62 -27 29 -38 100 -40 255 0 71 -3 82 -13 65 -8
-14 -13 -114 -17 -341 -5 -299 -6 -323 -24 -343 -26 -29 -78 -27 -168 4 -105
37 -266 65 -371 65 -119 0 -549 -66 -560 -86 -17 -30 -342 -251 -378 -258 -47
-8 -97 -42 -128 -86 l-24 -35 6 53 c13 113 118 236 269 312 l83 42 -48 -5
c-138 -14 -310 -154 -420 -341 -55 -93 -60 -107 -83 -230 -48 -260 -51 -485
-9 -687 23 -109 30 -130 138 -424 89 -242 98 -275 95 -323 -9 -109 -109 -156
-328 -157 -139 0 -134 -2 -287 92 -102 62 -229 117 -348 149 -85 22 -383 74
-635 109 -63 9 -214 43 -335 75 -426 115 -639 157 -918 179 -163 14 -545 14
-713 1 -705 -56 -1086 -305 -1304 -855 -35 -88 -38 -135 -10 -180 l20 -32 -20
-46 c-27 -61 -33 -145 -18 -246 l13 -85 45 -26 c42 -24 45 -30 51 -76 4 -28
25 -90 48 -139 27 -57 45 -114 52 -159 12 -88 25 -115 72 -145 20 -13 37 -31
37 -38 0 -28 192 -388 225 -421 10 -11 17 -11 35 -2 12 6 23 10 25 8 7 -6 115
-259 115 -267 0 -5 -22 -23 -50 -41 -30 -19 -47 -37 -43 -44 52 -87 96 -147
106 -143 7 2 14 29 17 63 4 45 7 54 13 37 5 -12 44 -82 89 -155 136 -225 187
-338 228 -512 29 -120 28 -132 -17 -141 -77 -14 -124 -57 -158 -142 -26 -64
-16 -83 80 -154 47 -35 85 -66 85 -70 0 -23 -36 -12 -85 27 -61 47 -102 56
-130 28 -11 -11 -14 -26 -10 -48 10 -51 -9 -85 -61 -107 -53 -24 -49 -36 28
-75 48 -24 72 -53 66 -79 -2 -11 -20 -5 -71 26 -38 23 -70 39 -71 37 -1 -1 -8
-27 -14 -56 -16 -71 -15 -131 3 -162 19 -34 18 -40 -5 -40 -32 0 -77 -27 -104
-63 -24 -31 -92 -226 -132 -377 -9 -36 -30 -93 -47 -128 l-31 -64 13 -146 c14
-165 28 -248 65 -372 14 -46 24 -85 23 -86 -1 -1 -31 54 -67 122 -72 137 -79
137 -60 -2 15 -118 42 -214 93 -343 69 -171 120 -254 217 -352 78 -78 96 -91
220 -152 74 -36 210 -95 301 -131 91 -36 226 -96 300 -135 101 -52 185 -85
344 -136 615 -196 900 -298 1116 -401 132 -63 183 -81 333 -119 234 -60 359
-112 438 -183 22 -20 68 -77 101 -127 87 -131 178 -245 293 -370 113 -124 119
-132 129 -195 6 -41 81 -345 93 -380 3 -8 -30 22 -73 68 -82 88 -117 112 -162
112 -30 0 -47 10 -143 83 -80 61 -242 157 -397 237 -67 34 -149 84 -183 112
-44 35 -69 48 -86 46 l-25 -3 32 -43 c17 -24 102 -101 189 -172 154 -126 157
-130 156 -166 -1 -38 0 -39 161 -159 251 -189 398 -318 671 -591 270 -269 351
-338 477 -404 86 -45 156 -93 193 -132 l22 -24 -228 -197 c-126 -107 -286
-250 -356 -317 -71 -67 -156 -142 -190 -167 -68 -50 -83 -59 -631 -378 -214
-125 -410 -242 -435 -258 -39 -27 -258 -212 -739 -626 -288 -248 -570 -596
-966 -1191 -112 -168 -256 -390 -320 -495 -65 -104 -135 -217 -155 -250 -112
-181 -150 -284 -245 -667 -96 -386 -109 -473 -102 -698 6 -180 17 -250 63
-395 59 -187 74 -344 54 -573 -12 -144 -12 -148 10 -207 18 -48 24 -92 30
-220 9 -177 55 -507 101 -715 41 -188 61 -362 61 -515 -1 -97 4 -152 19 -215
24 -104 98 -342 109 -354 9 -9 17 14 22 64 7 60 26 13 65 -160 51 -228 51
-227 13 -235 -30 -7 -30 -7 -24 -63 9 -84 90 -395 123 -471 30 -70 290 -477
447 -698 155 -217 396 -514 609 -748 275 -302 571 -583 602 -571 36 14 92 17
112 6 21 -11 21 -13 7 -29 -17 -19 -22 -66 -7 -75 14 -9 42 30 36 50 -4 10 30
-19 74 -66 44 -46 82 -82 83 -80 22 30 132 150 137 150 4 0 13 -12 20 -27 14
-30 11 -33 -65 -90 l-29 -21 25 -18 c14 -10 40 -34 58 -53 32 -34 34 -35 30
-11 -2 13 -13 30 -26 36 l-23 12 34 21 35 22 85 -41 c86 -40 132 -81 143 -125
4 -17 -3 -37 -27 -70 -18 -25 -30 -46 -28 -48 27 -17 135 -62 210 -87 52 -17
207 -89 344 -160 294 -151 403 -197 705 -297 124 -41 235 -81 247 -89 36 -23
54 -58 79 -159 62 -242 174 -390 397 -524 58 -35 161 -87 229 -116 255 -109
458 -153 858 -185 661 -52 905 -120 1820 -508 129 -55 354 -143 500 -197 315
-116 378 -147 452 -223 48 -49 335 -457 427 -607 32 -53 42 -86 140 -492 108
-442 343 -1257 490 -1703 236 -710 485 -1174 822 -1525 42 -44 179 -204 305
-355 483 -582 851 -997 1164 -1311 230 -231 311 -302 625 -549 329 -260 493
-399 660 -560 82 -81 182 -168 220 -195 39 -26 103 -72 142 -102 97 -71 182
-117 273 -145 64 -19 96 -23 217 -23 78 0 144 2 147 5 5 6 -48 243 -67 294
-12 32 -58 56 -107 56 -49 0 -100 33 -100 64 0 2 28 13 63 26 34 12 66 26 70
30 4 4 -12 15 -37 24 -50 19 -86 57 -86 91 0 46 -32 124 -85 207 -30 49 -64
109 -74 135 -16 41 -25 50 -62 65 -56 22 -98 22 -120 2 -17 -15 -19 -13 -33
35 -8 27 -18 72 -22 98 -4 34 -19 65 -49 106 -77 105 -198 236 -226 246 -24 8
-29 18 -43 82 -10 39 -23 126 -31 193 -26 221 -53 279 -202 441 -148 161 -232
263 -312 380 -116 170 -148 227 -356 635 -111 217 -268 525 -349 683 l-147
288 6 77 c4 52 1 93 -8 127 l-14 50 -27 -54 -28 -54 -32 69 c-18 38 -67 139
-109 224 -42 85 -86 191 -99 235 -16 59 -32 91 -59 122 -44 49 -55 92 -40 163
19 86 17 92 -45 129 l-56 33 5 187 c3 175 16 261 37 261 4 0 16 -21 26 -47 21
-56 38 -79 66 -86 17 -5 19 -2 14 16 -3 12 -25 94 -47 182 -92 363 -142 531
-180 609 -30 63 -39 92 -39 133 0 65 44 185 99 270 61 94 157 322 162 383 1
19 -1 80 -5 135 -10 132 5 261 60 520 54 253 49 238 136 457 l71 176 -16 35
c-10 19 -17 43 -17 53 0 10 30 47 68 83 50 49 84 96 135 186 43 75 113 176
189 270 66 83 146 188 176 235 249 380 191 312 757 890 127 129 277 291 334
360 304 363 597 816 834 1287 62 123 115 223 118 223 3 0 51 -73 106 -162 56
-90 156 -242 223 -338 170 -244 218 -319 305 -475 122 -221 163 -279 910
-1290 215 -291 456 -627 535 -745 172 -259 255 -372 352 -481 292 -328 373
-476 617 -1120 l70 -187 -6 -263 c-6 -254 -5 -271 22 -459 27 -182 28 -201 16
-294 -17 -133 -53 -323 -106 -546 -51 -218 -64 -296 -71 -439 -6 -114 0 -145
61 -291 25 -59 27 -72 22 -160 -9 -150 -46 -459 -61 -509 -8 -25 -45 -131 -84
-236 -38 -104 -80 -226 -92 -270 -26 -95 -90 -232 -203 -439 -46 -83 -120
-218 -163 -301 -45 -86 -119 -205 -174 -280 -53 -71 -128 -175 -167 -230 -74
-105 -170 -204 -239 -248 -23 -14 -91 -48 -150 -75 -308 -137 -609 -347 -1089
-758 -157 -134 -197 -176 -257 -274 -61 -98 -148 -298 -148 -341 -1 -54 -33
-112 -91 -160 -118 -98 -150 -141 -77 -103 18 10 98 44 178 76 80 32 150 61
156 64 7 3 14 -11 18 -35 6 -39 43 -81 72 -81 13 0 34 57 34 92 0 23 -36 41
-71 36 -16 -3 -28 -2 -27 2 7 15 96 89 125 104 33 16 133 23 133 8 0 -4 -28
-41 -61 -80 -34 -40 -60 -74 -58 -76 2 -2 17 -8 33 -13 17 -5 39 -21 50 -36
17 -23 18 -31 8 -65 -32 -104 -4 -179 75 -207 26 -9 55 -21 63 -26 71 -42 118
-56 124 -37 2 7 9 32 15 55 9 33 18 43 43 52 17 6 42 11 56 11 16 0 53 25 111
76 161 142 151 130 151 185 0 27 -2 49 -5 49 -2 0 -29 -16 -60 -35 -30 -20
-55 -33 -55 -30 0 4 14 27 31 52 33 47 92 82 140 83 23 0 28 6 34 37 8 42 65
103 96 103 11 0 29 -15 41 -35 13 -19 26 -35 29 -35 14 0 43 85 41 119 -2 25
5 48 20 70 24 34 37 43 123 84 37 17 61 39 90 80 22 31 57 70 77 87 l37 30
-38 -87 c-39 -88 -39 -102 0 -103 8 0 30 20 49 45 19 25 37 45 41 45 12 0 29
-61 29 -106 0 -36 -7 -51 -40 -89 -22 -25 -40 -50 -40 -55 0 -5 -3 -16 -6 -25
-6 -14 3 -12 44 9 84 42 383 309 603 537 391 405 430 443 495 477 53 27 79 53
192 189 73 87 166 208 208 268 121 175 177 238 297 338 213 178 347 348 471
595 84 168 116 272 84 272 -7 0 -31 -35 -54 -78 -106 -202 -255 -412 -425
-602 -103 -114 -104 -115 -107 -82 -4 42 123 257 270 458 57 77 137 187 178
244 41 57 101 132 133 167 45 49 74 96 122 196 34 73 125 263 202 422 133 275
145 304 226 565 160 512 175 570 207 789 14 99 32 173 60 251 24 67 40 130 40
156 0 34 11 68 44 132 44 86 56 130 91 335 19 109 43 157 138 275 42 53 145
212 240 370 173 291 332 517 395 562 38 28 589 299 897 441 327 152 354 167
427 242 l68 70 61 -2 c51 -1 69 3 100 23 35 24 41 25 100 15 74 -12 174 2 229
31 39 21 29 23 -57 11 -54 -8 -64 -3 -39 18 27 23 1011 354 1121 377 147 32
160 31 175 -6 7 -17 17 -33 22 -36 5 -3 29 17 54 45 40 45 48 49 66 40 44 -24
81 -27 166 -17 77 10 94 9 130 -5 24 -10 70 -17 106 -17 57 0 74 5 169 54 58
30 129 59 159 65 174 35 222 46 303 73 49 17 156 59 237 93 l147 63 16 -24 c8
-13 22 -24 29 -24 7 0 93 38 189 84 97 47 218 101 269 122 163 65 215 115 306
291 73 142 102 178 172 213 33 17 60 35 60 40 0 16 229 248 314 318 208 171
252 211 330 306 46 55 123 139 171 186 100 98 112 119 132 215 18 87 66 183
151 303 45 63 78 99 106 115 50 27 66 57 66 124 0 57 -3 55 88 65 21 3 24 9
27 55 4 61 32 120 90 188 35 41 42 56 38 81 -3 22 5 50 27 95 56 109 293 782
335 951 30 117 233 631 246 622 5 -3 9 -24 10 -47 1 -23 4 -53 8 -67 7 -24 8
-23 36 24 48 82 76 174 82 268 5 74 2 97 -17 155 -35 102 -29 123 43 175 l27
20 24 -54 c16 -34 26 -48 30 -38 3 8 29 103 57 210 28 107 75 283 105 390 69
248 91 358 99 495 4 61 18 178 32 260 20 119 26 198 30 383 6 253 -3 395 -43
712 -19 147 -27 273 -34 510 -12 447 -31 549 -162 840 -99 221 -125 286 -260
645 -138 369 -211 527 -296 641 -116 157 -207 237 -482 424 -241 165 -334 237
-430 335 -41 42 -93 84 -116 94 -44 20 -70 51 -101 122 -15 34 -104 130 -374
401 -298 298 -385 380 -549 510 -186 149 -319 240 -496 342 -106 61 -191 139
-335 311 -64 77 -131 153 -148 169 -54 51 -46 71 77 182 258 236 334 337 502
664 66 129 201 387 299 573 198 373 226 413 398 577 276 263 520 416 766 480
147 38 203 59 397 150 85 40 238 109 340 154 331 146 494 259 756 520 173 174
442 496 471 564 8 19 19 88 25 153 15 168 7 183 -120 229 -64 23 -85 43 -157
142 l-34 47 25 72 c21 63 23 84 18 153 -8 100 -32 147 -108 205 -80 62 -82 65
-56 101 23 33 24 31 -34 108 l-40 54 23 17 23 17 -94 59 c-92 59 -223 177
-248 224 l-12 24 58 -8 57 -7 -44 52 c-24 29 -54 64 -65 78 l-21 26 54 65
c110 134 144 254 109 387 -9 34 -16 88 -16 121 0 61 47 294 75 373 9 25 30 94
46 154 43 157 64 200 124 254 l53 47 76 233 c87 266 130 424 216 805 83 368
93 437 93 652 0 152 -4 192 -21 258 -41 151 -99 240 -288 444 -134 145 -267
233 -464 306 -121 45 -497 156 -568 168 -74 12 -594 -6 -751 -26 -146 -19
-455 -74 -619 -111 -404 -90 -663 -187 -1042 -389 -96 -51 -215 -92 -294 -101
l-53 -6 -7 108 c-23 374 -38 574 -52 684 -15 125 -60 370 -70 380 -6 5 -2 -82
17 -429 19 -349 7 -602 -30 -669 -8 -15 -11 -13 -21 13 -12 30 -8 448 6 695 7
131 -15 248 -75 400 -46 117 -136 289 -154 295 -7 2 -25 6 -42 10 -19 4 -66
42 -130 105 -84 82 -122 111 -241 179 -251 145 -321 160 -735 161 -226 0 -301
-7 -394 -37 -29 -10 -84 -17 -131 -17 -89 -1 -111 9 -140 67 -18 35 -140 552
-160 675 -17 111 -42 186 -83 250 -17 26 -51 81 -75 122 -25 41 -77 111 -116
155 -40 44 -89 105 -110 135 -46 68 -187 203 -292 281 -203 150 -310 190 -678
254 -85 15 -182 33 -215 40 -210 49 -358 27 -790 -116 -485 -161 -919 -487
-1255 -944 -73 -99 -158 -213 -189 -254 -31 -41 -61 -81 -67 -89 -8 -11 -34 8
-135 100 -177 161 -240 214 -358 303 -123 93 -200 169 -290 290 -37 50 -106
142 -153 205 -145 194 -325 379 -516 532 -72 57 -132 95 -132 82 0 -3 40 -51
88 -107 108 -124 112 -130 78 -117 -49 18 -91 42 -176 100 -108 73 -191 115
-272 137 -36 9 -77 29 -97 46 -18 16 -43 32 -55 37 -11 5 -121 48 -244 96
l-222 87 -6 38 c-6 36 -8 38 -71 53 -158 40 -371 55 -498 36z m5652 -655 c45
-19 39 -26 -53 -58 l-72 -26 -6 22 c-11 40 -6 58 17 67 34 13 74 12 114 -5z
m152 -35 c14 0 32 -4 38 -8 12 -8 65 -223 57 -231 -2 -2 -60 18 -129 44 -123
47 -155 69 -155 107 0 15 8 15 87 -7 48 -13 89 -23 91 -20 2 2 -15 28 -37 58
-23 29 -41 57 -41 62 0 4 14 5 31 1 17 -3 43 -6 58 -6z m-1252 -73 c-7 -51
-21 -65 -85 -82 -29 -7 -74 -26 -100 -41 -65 -39 -431 -396 -420 -411 9 -12
-9 -30 -19 -19 -3 3 -17 -17 -30 -45 -16 -31 -50 -71 -91 -107 -119 -103 -223
-206 -280 -277 -87 -108 -253 -285 -267 -285 -7 0 -36 10 -64 22 -48 19 -52
24 -46 47 18 81 190 364 298 490 74 87 191 189 377 330 85 65 203 154 261 198
59 44 137 98 174 118 73 41 275 116 289 108 4 -3 6 -24 3 -46z m843 2 c42 -15
26 -24 -57 -34 -71 -8 -85 6 -30 30 40 18 46 18 87 4z m832 -100 c-4 -3 -28
-17 -53 -32 l-45 -26 -53 70 c-28 38 -49 74 -46 78 3 5 50 -12 105 -37 54 -26
96 -50 92 -53z m-225 -95 c13 -4 20 -10 17 -15 -9 -15 -61 -10 -74 6 -10 13
-9 15 11 15 13 0 34 -3 46 -6z m184 -109 c37 -19 108 -100 130 -148 10 -23 19
-60 19 -83 0 -37 6 -46 53 -90 29 -27 75 -65 103 -84 41 -30 56 -50 95 -130
26 -52 52 -121 59 -153 11 -49 22 -69 66 -115 29 -31 52 -57 51 -58 -1 -1 -31
-18 -65 -38 l-64 -36 -18 23 c-11 12 -31 44 -46 71 -23 41 -26 56 -21 102 l6
54 -72 36 c-86 43 -84 41 -69 56 19 19 14 90 -9 128 -12 19 -49 70 -83 114
-33 43 -79 115 -101 160 -29 58 -50 87 -75 103 -56 36 -68 47 -75 70 -4 17 0
23 22 31 30 11 52 8 94 -13z m318 -140 c17 -30 31 -57 31 -60 0 -3 -20 -5 -45
-5 -46 0 -65 16 -65 57 0 21 27 63 40 63 4 0 22 -25 39 -55z m-281 -116 c26
-16 28 -25 6 -33 -20 -8 -40 8 -61 48 l-16 30 24 -15 c13 -9 34 -22 47 -30z
m-4543 11 c53 -26 100 -74 92 -95 -6 -13 -21 -8 -96 30 -50 24 -105 54 -123
65 l-33 20 59 0 c39 0 74 -7 101 -20z m4720 -10 c13 -15 14 -22 5 -40 -14 -25
-12 -27 -38 26 -21 40 -21 42 -2 37 10 -3 26 -13 35 -23z m229 -75 c50 -69 71
-107 64 -111 -14 -9 -91 74 -117 126 -19 36 -35 90 -27 90 3 0 39 -47 80 -105z
m-16321 -108 c-35 -88 -49 -114 -56 -102 -11 20 0 90 20 130 15 30 74 98 80
92 1 -2 -18 -56 -44 -120z m11827 -57 c8 -5 12 -12 8 -15 -9 -9 -48 4 -48 16
0 12 21 11 40 -1z m-11910 -129 c0 -21 -13 -41 -26 -41 -17 0 -17 5 -4 31 11
20 30 26 30 10z m3880 -3 c0 -26 -80 -139 -111 -157 -18 -11 -51 -23 -73 -26
-41 -7 -72 -22 -181 -89 -67 -41 -126 -60 -156 -51 -16 5 6 26 118 111 76 57
162 125 191 150 30 26 72 52 95 60 55 17 117 18 117 2z m11967 -19 c8 -8 8
-20 1 -40 -12 -35 -21 -36 -38 -5 -21 41 7 75 37 45z m63 -196 c12 -16 31 -44
44 -63 18 -29 28 -35 54 -34 30 2 32 0 32 -30 0 -17 -11 -48 -25 -68 -14 -20
-25 -41 -25 -46 0 -5 36 4 81 19 44 16 84 29 89 29 7 0 40 -82 40 -100 0 -4
-13 -5 -30 -2 -45 6 -73 -4 -130 -49 -28 -22 -67 -47 -86 -55 -31 -13 -35 -13
-49 3 -82 95 -87 103 -71 103 33 0 83 -28 109 -61 15 -19 27 -29 27 -24 0 6
-16 70 -35 144 -30 115 -41 142 -77 188 -34 44 -39 56 -27 64 26 16 57 9 79
-18z m195 -23 c15 -16 15 -22 5 -35 -15 -18 -51 -20 -68 -3 -16 16 -15 23 4
42 21 21 37 20 59 -4z m-560 -100 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m599 -16 c17 -16 21 -34 9 -34 -10 1 -53 36 -53 44
0 12 29 6 44 -10z m313 -100 c19 -84 23 -94 33 -69 5 11 9 32 9 48 1 33 18 35
55 6 33 -26 33 -53 -3 -120 -15 -30 -32 -65 -36 -77 -6 -22 -5 -23 15 -12 26
14 33 8 49 -39 28 -86 2 -126 -120 -183 -68 -31 -69 -32 -69 -71 0 -31 -7 -45
-31 -68 -17 -16 -36 -29 -41 -29 -5 0 -28 16 -51 35 -22 19 -47 35 -54 35 -8
0 -31 -11 -51 -25 -20 -14 -40 -25 -43 -25 -4 0 -5 19 -1 42 5 39 3 44 -32 73
-31 26 -39 40 -43 77 -6 52 16 84 51 73 12 -3 53 -11 91 -16 94 -14 115 -28
115 -81 l0 -41 41 52 c38 48 44 51 85 51 24 0 44 4 44 8 0 11 -55 88 -69 97
-6 3 -11 25 -11 49 0 52 -22 77 -91 107 -41 18 -48 24 -34 32 9 5 25 7 36 4
18 -5 19 1 19 88 l0 94 62 -28 62 -27 13 -60z m-971 35 c9 -16 -12 -33 -31
-25 -14 5 -15 9 -5 21 15 18 26 19 36 4z m53 -115 c27 -35 28 -74 2 -74 -34 0
-67 71 -44 93 11 12 21 8 42 -19z m-436 -41 c34 -17 27 -33 -15 -33 -29 0 -88
27 -88 41 0 21 54 17 103 -8z m697 -28 c0 -8 -7 -15 -15 -15 -26 0 -18 20 13
29 1 1 2 -6 2 -14z m500 -46 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12
10 9 6 -3 10 -10 10 -16z m-437 -15 c9 -10 -4 -34 -19 -34 -8 0 -14 9 -14 20
0 19 19 27 33 14z m-7785 -78 c25 -27 73 -108 122 -206 45 -89 106 -202 136
-250 128 -208 295 -389 456 -497 152 -101 240 -134 709 -263 138 -38 261 -75
273 -84 23 -15 105 -132 128 -182 9 -19 9 -33 0 -58 -7 -18 -12 -48 -12 -67
-1 -48 -30 -105 -90 -174 -233 -268 -331 -577 -331 -1050 0 -211 39 -326 186
-549 155 -237 279 -371 439 -474 65 -43 352 -185 569 -283 113 -50 166 -91
205 -157 l27 -47 1 -295 c0 -298 4 -343 46 -565 22 -116 22 -92 2 -511 -11
-229 22 -452 105 -704 95 -285 175 -423 350 -602 367 -377 607 -527 1050 -657
76 -23 191 -66 255 -96 70 -33 143 -59 182 -66 51 -9 125 -40 315 -135 294
-146 370 -188 549 -307 176 -117 335 -235 437 -326 91 -80 222 -231 252 -289
42 -83 59 -202 76 -537 8 -158 20 -233 52 -330 29 -88 66 -135 107 -135 l26 0
0 90 c0 50 -4 90 -9 90 -5 0 -27 19 -50 41 -28 29 -46 57 -55 93 -22 80 -39
386 -23 402 10 11 55 -34 92 -92 42 -65 70 -91 106 -99 28 -6 29 -9 29 -65 0
-54 15 -90 38 -90 4 0 13 -4 20 -8 10 -7 8 -19 -8 -57 -27 -63 -21 -84 41
-150 58 -62 68 -98 45 -165 -9 -25 -16 -57 -16 -71 0 -26 -2 -27 -75 -34 -70
-6 -75 -8 -75 -30 0 -14 13 -32 35 -49 76 -58 90 -126 70 -334 -39 -397 -101
-603 -199 -663 -59 -37 -100 -42 -189 -23 -131 26 -253 16 -487 -40 -310 -76
-366 -94 -531 -172 -192 -91 -302 -127 -589 -194 -488 -113 -987 -283 -1309
-445 -253 -126 -655 -368 -841 -504 -52 -39 -210 -152 -350 -253 -333 -239
-451 -332 -578 -452 -167 -160 -250 -285 -293 -441 -27 -102 -41 -128 -102
-195 -76 -83 -131 -161 -224 -318 -47 -79 -146 -237 -220 -350 -75 -114 -164
-250 -199 -303 -51 -78 -71 -100 -110 -120 -45 -23 -48 -27 -88 -134 -51 -135
-94 -215 -147 -278 l-42 -48 -28 27 c-18 17 -56 95 -110 221 -60 141 -93 206
-122 239 -33 37 -38 50 -33 75 5 28 -13 60 -42 70 -5 2 -26 38 -47 79 -21 41
-56 95 -77 120 -48 54 -64 86 -108 209 -28 80 -43 105 -97 165 -35 39 -112
138 -171 221 -59 82 -127 174 -153 205 -25 30 -77 98 -116 150 -39 52 -87 111
-107 129 -21 20 -36 45 -36 58 0 20 4 22 30 17 25 -5 31 -3 38 17 25 68 24 72
-29 108 -27 19 -66 54 -86 79 l-36 44 -52 -6 c-58 -7 -72 2 -90 58 -11 33 -31
49 -210 166 -66 42 -166 118 -223 168 -79 68 -136 107 -235 161 -100 54 -146
85 -192 131 -66 68 -169 141 -350 250 -66 40 -163 104 -215 142 -155 114 -241
152 -384 168 -114 14 -135 21 -212 71 -103 67 -274 118 -864 258 -207 49 -404
99 -439 111 -34 11 -126 52 -204 90 -130 64 -299 120 -359 120 -18 0 -18 -2 7
-32 24 -28 25 -32 10 -41 -60 -33 -228 -12 -345 44 l-65 30 25 19 c14 11 29
20 33 20 5 0 5 5 1 11 -15 25 -97 50 -193 59 -63 6 -113 16 -132 27 -98 57
-87 54 -204 48 -94 -5 -115 -4 -145 12 -34 18 -35 19 -42 98 -4 44 -24 135
-44 202 -36 120 -36 123 -20 163 22 54 20 96 -4 136 -21 33 -21 33 -1 72 11
21 26 65 35 98 19 75 46 314 46 408 1 147 50 289 145 414 44 58 49 70 55 134
5 62 10 74 36 95 16 14 40 34 53 45 20 17 22 26 17 61 -6 40 -3 47 61 133 38
51 90 122 117 159 62 85 128 138 243 191 58 28 98 54 110 71 10 15 41 50 68
78 41 41 75 61 193 114 144 63 164 69 214 56 52 -13 95 3 155 59 32 29 73 61
92 70 46 24 328 126 347 126 9 0 33 -14 54 -30 l39 -31 45 71 c60 92 92 112
170 107 l60 -4 142 88 c337 207 457 304 523 422 18 31 39 57 47 57 7 0 38 9
69 21 97 36 230 183 301 332 16 34 16 36 -5 62 -33 44 -27 61 32 91 124 63
181 124 217 237 12 36 36 85 54 109 19 24 50 77 71 118 20 41 49 90 62 109
l26 33 -23 56 c-22 53 -23 55 -5 68 11 8 27 14 38 14 27 0 57 19 57 36 0 8
-11 37 -25 64 l-25 50 20 40 c27 54 25 79 -10 115 l-30 31 30 48 c17 27 29 50
26 53 -2 2 -21 0 -42 -6 -21 -6 -39 -9 -40 -8 -2 1 10 31 26 67 39 86 39 129
1 158 -26 19 -30 30 -46 137 -30 194 -26 211 104 469 60 122 118 239 127 261
l16 40 17 -48 16 -48 2 78 c1 48 12 115 28 173 14 52 37 140 51 195 16 58 35
110 48 124 11 13 102 84 201 159 337 253 297 209 493 553 107 187 122 219 105
219 -5 0 -17 7 -27 15 -19 14 -17 18 39 100 l59 85 -27 28 c-26 27 -27 31 -33
197 -4 139 -3 178 10 216 17 50 15 63 -28 164 -31 73 -32 90 -6 139 13 26 16
44 11 55 -21 39 -122 335 -118 346 3 6 43 35 90 65 l85 54 -38 76 c-77 153
-86 239 -36 337 21 41 106 138 106 121 0 -4 -9 -28 -20 -54 -21 -49 -21 -60 3
-51 8 3 101 117 207 254 142 182 202 268 227 323 61 137 65 143 132 175 60 29
62 31 58 65 -3 27 3 42 22 64 38 42 139 142 213 211 48 44 76 82 108 143 23
45 46 82 50 82 4 0 26 -20 48 -44z m7522 24 c17 -17 18 -24 8 -52 l-11 -32
-18 39 c-29 66 -20 86 21 45z m310 -27 c29 6 30 6 30 -32 0 -49 -18 -99 -39
-110 -24 -13 -58 4 -81 41 -20 33 -20 60 2 117 l9 24 24 -23 c18 -17 31 -21
55 -17z m-17840 -575 c0 -40 -12 -98 -20 -98 -14 0 -19 46 -9 83 11 41 29 50
29 15z m20520 -1103 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10
25 6 0 10 -11 10 -25z m34 -234 c15 -16 16 -23 5 -42 -12 -22 -12 -22 -30 12
-10 19 -18 37 -19 42 0 14 27 7 44 -12z m-24504 -173 c0 -5 -11 -1 -25 8 -14
9 -22 20 -19 26 6 10 44 -19 44 -34z m21950 -46 c0 -13 -12 -22 -22 -16 -10 6
-1 24 13 24 5 0 9 -4 9 -8z m-18942 -80 c3 -9 -3 -13 -19 -10 -12 1 -24 9 -27
16 -3 9 3 13 19 10 12 -1 24 -9 27 -16z m18767 -106 c28 -12 55 -29 61 -37 6
-8 44 -153 83 -324 77 -336 94 -380 161 -420 26 -15 51 -20 100 -19 79 1 147
30 205 89 35 35 46 59 94 205 49 151 71 200 92 200 21 0 29 -124 29 -465 l0
-364 -35 -188 c-76 -403 -77 -413 -73 -491 4 -99 31 -155 114 -239 l62 -63
-79 6 c-75 7 -79 6 -79 -13 0 -32 35 -68 80 -82 23 -7 48 -15 56 -18 11 -4 14
5 14 35 l0 39 55 -15 c52 -15 56 -14 69 3 23 32 54 133 82 264 30 145 64 224
128 301 67 80 77 124 50 222 -18 64 -12 82 33 99 15 5 36 35 60 84 21 41 42
74 48 72 19 -7 65 -92 73 -139 7 -34 1 -116 -20 -304 l-29 -258 -83 -210 c-91
-231 -124 -327 -156 -456 -11 -47 -46 -152 -77 -235 -31 -85 -60 -181 -66
-222 -13 -92 5 -314 28 -349 28 -43 79 -62 282 -100 22 -4 21 -6 -22 -96 -40
-82 -45 -99 -45 -161 0 -58 -6 -84 -39 -161 -73 -173 -111 -329 -118 -493 -5
-108 -3 -139 11 -172 30 -77 12 -150 -60 -238 -36 -44 -114 -171 -114 -186 0
-5 9 -5 20 -2 20 6 24 -2 14 -28 -7 -18 -215 191 -254 256 -14 22 -42 58 -63
79 -46 46 -57 74 -45 111 16 44 2 74 -71 150 -37 40 -87 106 -110 147 -24 41
-63 105 -87 142 -39 59 -44 75 -44 123 0 65 -20 119 -49 135 -14 7 -21 21 -21
40 0 16 -22 72 -49 123 -27 51 -52 113 -56 137 -9 57 -39 128 -68 159 -30 32
-94 61 -139 61 -51 0 -138 20 -138 31 0 5 4 9 9 9 13 0 171 159 171 172 0 5
-20 32 -44 59 -37 41 -50 68 -80 162 -29 92 -41 118 -72 147 -48 46 -73 87
-119 195 -66 157 -85 186 -177 279 -88 89 -92 95 -183 281 -92 189 -123 257
-185 409 -18 43 -47 97 -65 121 -58 76 -60 82 -28 113 16 15 37 32 46 37 17 9
16 14 -4 72 -18 55 -23 62 -39 53 -29 -15 -59 -12 -101 12 l-39 21 61 57 c61
57 61 58 58 108 l-4 50 55 -1 c48 -2 68 4 137 40 44 23 82 41 85 40 2 -1 -11
12 -30 28 -20 17 -54 48 -76 69 l-41 40 60 10 c54 10 60 13 60 36 0 19 -10 30
-42 48 -24 13 -43 27 -43 32 0 5 53 56 118 114 65 58 160 150 210 204 101 110
135 131 236 147 66 10 70 12 78 42 6 25 15 33 46 41 57 15 73 14 127 -12z
m6695 -44 c0 -12 -31 -115 -38 -127 -3 -5 -13 20 -22 55 -16 64 -16 65 4 72
31 9 56 9 56 0z m-22736 -45 c8 -13 7 -24 -4 -44 -26 -46 -40 -40 -40 17 0 42
3 51 16 48 9 -2 22 -11 28 -21z m17336 -162 c0 -102 -3 -185 -8 -185 -4 0 -18
7 -30 16 -27 19 -28 51 -7 219 8 66 15 123 15 128 0 4 7 7 15 7 13 0 15 -27
15 -185z m-4685 95 c-9 -14 -25 5 -25 30 0 12 3 12 16 -2 9 -9 13 -21 9 -28z
m10193 -40 c12 -16 22 -32 22 -35 0 -3 -57 -5 -127 -5 -146 0 -151 9 -26 46
101 30 105 29 131 -6z m-10097 -76 c13 -12 14 -21 5 -54 -5 -22 -13 -40 -16
-40 -3 1 -20 21 -38 46 -27 37 -31 48 -21 60 13 16 44 11 70 -12z m8470 -107
c-17 -49 -42 -69 -85 -65 l-36 3 61 53 c34 29 64 51 66 48 3 -3 0 -20 -6 -39z
m-20421 13 c10 -5 30 -41 45 -80 19 -50 37 -79 61 -98 25 -19 39 -42 50 -80 9
-29 25 -64 37 -78 30 -35 195 -82 312 -90 85 -6 96 -4 200 30 61 20 166 58
235 85 109 43 127 47 137 34 9 -12 -5 -31 -82 -106 -70 -70 -99 -107 -121
-155 l-29 -63 -35 7 c-76 15 -82 13 -101 -41 -9 -27 -18 -55 -18 -62 -1 -8 29
-2 84 17 47 16 85 27 85 24 0 -2 -22 -40 -49 -83 l-49 -78 44 5 44 5 0 -36 c0
-20 5 -39 11 -43 8 -4 -3 -15 -27 -27 -57 -28 -74 -47 -74 -78 0 -48 24 -53
212 -46 92 4 168 3 168 -1 0 -5 -14 -21 -31 -38 -61 -57 -118 -142 -265 -394
-82 -140 -203 -347 -269 -459 -84 -142 -136 -220 -171 -255 -67 -66 -84 -101
-84 -175 0 -33 -5 -72 -11 -88 l-10 -27 -29 49 c-23 40 -30 46 -36 32 -14 -38
25 -105 104 -175 137 -120 224 -206 233 -231 9 -23 11 -23 102 -17 50 4 103
10 116 13 l25 6 -23 -31 c-12 -17 -37 -36 -56 -43 -27 -10 -35 -19 -40 -48 -4
-23 -85 -151 -228 -361 -281 -414 -278 -411 -364 -476 -156 -120 -231 -240
-549 -874 -106 -212 -203 -398 -214 -415 l-22 -30 7 55 c20 180 18 206 -29
490 -31 179 -52 280 -62 291 -9 10 -32 21 -52 26 -34 8 -38 13 -49 58 -13 50
-53 484 -53 568 0 24 9 66 21 92 18 40 20 58 14 119 -11 120 -35 208 -74 276
-39 68 -61 118 -82 185 -16 48 -17 213 -2 239 10 18 12 18 37 -7 38 -38 53
-81 71 -194 17 -106 34 -133 84 -133 37 0 42 -5 96 -97 27 -45 53 -79 57 -75
4 4 13 42 20 85 17 114 8 142 -137 388 -69 118 -130 218 -135 223 -8 8 -91 -2
-167 -21 -23 -5 -23 -4 -23 91 l0 97 -41 39 c-40 38 -41 42 -36 84 5 32 2 47
-8 56 -24 20 -18 54 20 123 60 110 47 142 -68 166 -36 8 -57 17 -54 24 4 13
84 77 95 77 4 0 37 -27 73 -59 49 -42 78 -60 106 -64 39 -7 70 -31 92 -74 11
-22 17 -23 131 -23 l119 0 17 58 c9 31 17 86 17 122 -2 530 -1 662 8 755 14
139 7 207 -42 426 -29 131 -39 199 -39 263 l0 86 164 -109 c178 -118 228 -138
319 -128 28 4 74 18 103 32 65 32 154 110 154 136 0 10 -22 44 -50 74 -55 61
-62 92 -35 146 12 23 14 38 6 66 -6 20 -8 40 -5 45 7 11 71 10 94 -2z m15840
-24 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15 25 12 25 -4z m-15470 -111
c10 -12 9 -16 -5 -21 -19 -8 -40 9 -31 25 10 15 21 14 36 -4z m16175 -42 c4
-27 9 -57 12 -68 4 -17 2 -17 -22 6 -17 17 -28 41 -32 67 -5 38 -3 42 15 42
17 0 22 -8 27 -47z m-23935 -18 c0 -45 0 -45 -35 -45 -43 0 -64 29 -37 53 14
13 50 31 70 36 1 1 2 -19 2 -44z m7884 -27 c15 -8 16 -12 5 -19 -30 -19 -68
-10 -84 21 -8 16 -15 31 -15 34 0 5 59 -18 94 -36z m20761 18 c14 -22 14 -27
-2 -60 -21 -43 -26 -44 -41 -11 -16 34 -15 46 4 73 19 27 20 27 39 -2z m-4794
-106 c71 -89 73 -92 70 -143 -3 -81 -7 -87 -41 -76 -47 17 -55 33 -40 80 13
38 12 44 -8 78 -56 95 -87 172 -63 157 5 -3 41 -46 82 -96z m-15311 21 c-11
-22 -35 -28 -45 -11 -5 8 46 42 53 35 2 -2 -1 -13 -8 -24z m12917 -196 c25
-11 33 -19 26 -26 -11 -11 -83 17 -83 33 0 12 16 10 57 -7z m6061 -21 c11 -7
-50 -113 -168 -294 -185 -284 -190 -294 -189 -389 0 -65 2 -72 11 -47 8 21 13
25 19 15 15 -24 10 -51 -16 -85 -14 -18 -25 -36 -25 -40 0 -4 14 -29 32 -56
l31 -50 -31 -30 c-30 -30 -31 -31 -77 -19 -26 7 -49 10 -52 7 -2 -2 3 -42 12
-89 l17 -84 98 -7 c117 -8 165 1 268 53 83 41 150 66 158 58 3 -3 -25 -90 -62
-193 -37 -104 -75 -216 -84 -250 -32 -109 -51 -138 -230 -334 -247 -270 -340
-386 -440 -545 -64 -102 -163 -305 -204 -420 -20 -55 -57 -152 -81 -216 -25
-63 -45 -118 -45 -122 0 -3 12 -2 26 4 15 5 30 7 33 4 3 -4 20 -77 37 -163
l32 -157 -30 -34 c-27 -30 -30 -39 -24 -77 5 -36 2 -48 -20 -73 -14 -16 -34
-33 -44 -36 -11 -4 -34 -26 -50 -50 -19 -27 -69 -72 -129 -114 -112 -80 -181
-152 -237 -249 -48 -82 -116 -157 -166 -183 -20 -10 -81 -35 -134 -53 -120
-44 -228 -105 -303 -173 -93 -84 -292 -384 -663 -998 -255 -423 -301 -516
-381 -761 -34 -104 -50 -174 -61 -265 -18 -154 -66 -423 -107 -600 -29 -126
-31 -143 -26 -274 10 -265 109 -561 267 -795 32 -47 83 -134 115 -193 43 -78
67 -112 91 -126 23 -14 36 -31 43 -58 15 -54 32 -75 114 -140 72 -58 417 -372
417 -381 0 -2 -10 2 -22 10 -13 8 -62 36 -110 62 -100 54 -127 47 -57 -15 202
-180 472 -408 910 -769 177 -146 595 -551 799 -775 243 -266 596 -694 725
-880 193 -276 371 -592 449 -795 47 -122 87 -208 103 -221 7 -6 26 -8 43 -4
27 6 30 10 30 47 0 26 -17 79 -51 157 -90 203 -330 714 -361 769 -32 57 -229
317 -422 557 -165 205 -304 362 -478 542 -82 83 -148 154 -148 157 0 22 175
-98 329 -225 169 -139 307 -287 665 -714 261 -311 339 -411 352 -447 8 -26 38
-82 65 -124 87 -133 107 -176 139 -299 102 -389 98 -366 86 -480 -7 -62 -5
-76 18 -125 17 -38 28 -87 35 -155 6 -55 13 -120 17 -145 5 -35 2 -49 -9 -58
-13 -11 -11 -19 19 -63 44 -66 54 -105 54 -217 0 -65 4 -99 15 -115 13 -20 13
-33 -1 -107 -18 -93 -16 -264 6 -610 9 -138 9 -222 1 -330 -26 -348 -331
-1939 -407 -2125 -13 -33 -44 -140 -68 -238 -25 -98 -58 -207 -74 -242 -54
-115 -153 -187 -261 -189 -65 -1 -66 0 -107 139 -33 110 -57 144 -143 199 -77
50 -131 106 -165 174 -28 56 -82 107 -140 133 -49 21 -286 87 -484 135 -79 19
-423 90 -765 159 -342 69 -630 128 -641 131 -16 5 -15 9 13 27 28 17 32 25 29
55 l-3 35 79 23 78 24 27 -29 c44 -46 88 -55 182 -36 246 49 429 168 538 349
44 73 60 121 61 179 0 45 -3 51 -55 101 l-55 53 0 74 c0 105 -29 162 -134 264
-113 111 -124 115 -288 115 -271 -1 -579 -19 -718 -44 -74 -13 -198 -35 -275
-49 -77 -14 -194 -33 -260 -42 -265 -37 -738 -184 -1088 -339 -120 -53 -323
-140 -450 -193 -225 -93 -235 -96 -317 -99 -63 -1 -110 -10 -181 -33 -113 -36
-128 -37 -162 -11 -14 11 -40 20 -60 20 -47 0 -350 -63 -456 -95 -196 -58
-272 -55 -756 36 -248 46 -250 47 -690 226 -309 126 -422 182 -565 278 -52 35
-294 222 -537 415 -244 192 -445 350 -448 350 -3 0 7 -18 21 -40 16 -24 109
-104 238 -204 215 -168 366 -299 366 -318 0 -15 -65 -1 -115 24 -22 12 -208
122 -414 244 -307 184 -383 235 -430 283 -31 32 -103 89 -161 126 -406 262
-544 365 -652 486 -67 75 -130 112 -207 124 -54 9 -101 51 -101 90 0 46 -17
65 -58 65 -32 0 -48 9 -107 62 -109 96 -172 133 -275 158 -65 16 -111 21 -168
19 -123 -6 -212 -57 -427 -244 l-57 -49 -19 23 c-14 17 -19 39 -19 82 0 71
-20 124 -49 134 -21 6 -21 7 9 41 31 36 32 41 34 165 1 36 6 72 12 80 22 27
25 59 10 110 -9 29 -16 77 -16 108 0 53 2 56 53 105 81 77 81 79 57 118 -25
42 -25 49 4 93 13 19 27 54 31 78 11 73 25 106 91 209 71 113 84 147 84 229 0
83 49 406 69 460 42 109 104 159 330 263 313 145 314 145 942 696 485 425 651
564 775 648 34 22 152 88 263 147 209 109 254 135 248 142 -8 8 -206 -70 -322
-126 -136 -67 -192 -106 -390 -271 -203 -170 -1434 -1114 -1521 -1167 -41 -24
-78 -42 -84 -38 -13 8 142 186 271 310 58 55 167 148 244 206 142 108 257 218
312 299 41 60 153 166 229 218 34 22 113 69 175 103 63 34 220 129 349 212
539 344 644 410 684 428 28 12 87 22 175 29 269 22 463 75 696 190 77 38 185
101 240 139 203 143 301 192 467 236 175 46 504 70 618 44 74 -16 74 -16 50
-50 -12 -16 -21 -31 -19 -33 14 -9 221 -23 249 -17 33 8 139 56 315 141 267
130 410 355 431 674 4 56 3 129 -1 161 -7 61 -12 68 -95 134 -24 19 -51 54
-62 78 -24 54 -38 58 -180 48 -105 -7 -111 -6 -134 16 -13 12 -24 26 -24 29 0
4 20 38 45 74 37 55 45 76 45 112 0 25 -4 54 -10 64 -8 15 2 30 55 88 l65 70
-38 6 c-88 16 -87 11 -32 183 43 135 49 195 24 231 -15 22 -12 27 94 160 102
129 109 142 115 194 4 30 14 74 22 98 11 32 15 89 15 232 l0 190 36 58 c24 39
38 76 43 116 9 62 0 217 -18 324 -6 36 -18 139 -27 230 -28 292 -67 447 -160
633 -59 117 -88 162 -235 359 -69 93 -150 215 -182 275 -87 165 -253 339 -491
518 -101 76 -119 87 -139 78 -19 -9 -29 -4 -67 29 -59 52 -156 106 -253 140
-105 37 -128 50 -170 97 -47 52 -77 112 -115 233 -34 105 -63 155 -212 371
-107 156 -106 155 -153 257 -40 88 -113 183 -263 338 l-109 112 -48 0 c-33 0
-58 7 -77 20 -26 18 -30 19 -64 5 -42 -18 -105 -19 -161 -4 -82 23 -138 117
-115 197 22 79 6 142 -36 142 -8 0 -36 -5 -61 -11 -39 -10 -50 -9 -75 6 -29
16 -30 16 -52 -4 -12 -12 -29 -21 -38 -21 -14 0 -12 11 12 73 l28 72 -21 17
c-12 9 -43 27 -69 39 -27 12 -48 25 -48 29 0 10 72 30 108 30 51 0 58 30 36
139 -5 23 -5 23 -29 -9 l-24 -33 -12 29 c-9 23 -7 42 9 94 11 36 25 97 32 135
18 98 20 102 59 70 40 -35 46 -46 77 -152 41 -141 95 -235 194 -334 193 -193
470 -333 770 -390 180 -34 213 -45 308 -109 120 -80 214 -169 326 -309 52 -65
186 -228 298 -363 l203 -245 127 -244 c69 -134 215 -415 323 -624 109 -209
217 -410 241 -447 24 -38 135 -195 246 -350 393 -549 613 -928 776 -1333 99
-248 120 -315 132 -427 12 -113 28 -241 35 -274 3 -12 21 -28 45 -39 57 -26
92 -70 151 -188 51 -101 108 -190 138 -214 8 -7 25 -13 38 -13 33 0 77 94 77
167 1 63 -7 73 -38 44 l-23 -22 -7 32 c-10 54 -7 129 8 157 16 31 85 98 134
132 31 20 34 21 51 6 22 -20 41 -20 78 -1 15 8 54 20 86 26 71 13 113 47 163
132 20 34 55 82 78 108 48 53 64 84 142 264 119 277 124 291 124 358 l0 64 57
29 c71 37 94 68 101 136 3 37 48 160 147 408 180 448 206 525 250 738 47 230
73 295 175 437 79 109 109 173 180 380 41 120 101 260 186 438 275 572 282
592 290 792 17 438 26 581 41 612 8 18 42 58 75 88 48 45 71 58 131 76 115 33
157 49 249 96 103 53 160 100 233 197 30 39 108 130 174 201 67 72 124 141
127 155 20 77 63 131 254 325 144 146 199 208 204 231 11 49 69 94 121 94 14
0 39 -7 55 -15 77 -40 76 -40 112 25 27 50 35 57 48 47 8 -6 17 -12 18 -13z
m-3708 -59 c36 -19 37 -25 6 -54 -25 -23 -34 -22 -68 7 -16 13 -16 16 -1 38
18 28 24 29 63 9z m133 -88 c1 -35 -19 -87 -34 -87 -18 0 -20 64 -3 101 20 46
35 39 37 -14z m-15609 -224 c-34 -25 -51 -14 -19 12 19 16 31 20 34 13 2 -6
-4 -17 -15 -25z m15522 -138 c37 -76 40 -91 23 -129 -19 -40 -61 -52 -133 -37
-65 13 -107 39 -84 51 7 4 45 15 83 25 39 10 74 21 78 25 5 4 -5 40 -23 80
-24 57 -28 75 -18 82 17 10 32 -9 74 -97z m-6471 24 c4 -6 5 -12 2 -15 -7 -7
-37 7 -37 17 0 13 27 11 35 -2z m9828 -177 l-18 -27 -3 23 c-4 29 31 75 36 48
2 -10 -5 -29 -15 -44z m-24258 -5 c4 -18 5 -53 3 -77 l-3 -45 -18 26 c-10 15
-20 50 -24 78 -5 47 -4 51 15 51 16 0 23 -8 27 -33z m20652 -59 c24 -22 29
-53 13 -78 -7 -12 -12 -7 -23 20 -34 83 -32 98 10 58z m2683 -53 c0 -31 -47
-65 -90 -65 -22 0 -40 4 -40 8 0 14 33 40 70 56 45 20 60 20 60 1z m80 6 c0
-5 -6 -14 -14 -20 -10 -9 -19 -7 -37 9 l-24 19 38 1 c20 0 37 -4 37 -9z
m-23195 -232 c182 -219 193 -229 249 -229 32 0 52 -8 84 -32 23 -18 42 -35 42
-38 0 -3 -17 -4 -39 -2 -53 5 -59 -12 -14 -42 l38 -24 13 29 c6 16 15 29 18
29 3 0 45 -23 93 -52 47 -28 91 -53 96 -56 6 -3 -54 56 -132 132 -82 79 -162
166 -188 206 -48 73 -92 167 -82 176 14 14 97 -52 287 -228 167 -156 206 -188
230 -188 17 0 30 2 30 5 0 2 -21 38 -46 80 -25 42 -45 78 -43 79 11 10 169
-13 199 -28 19 -10 58 -42 86 -71 52 -53 135 -95 190 -95 20 0 29 -9 45 -44
22 -52 16 -81 -27 -115 -36 -28 -52 -27 -246 17 -161 37 -193 37 -130 0 15 -9
77 -26 139 -37 61 -12 128 -28 150 -35 l38 -14 -30 -8 c-60 -18 -186 -9 -290
21 -120 33 -286 75 -299 75 -18 0 59 -72 124 -115 117 -78 228 -118 430 -155
146 -27 172 -35 199 -64 12 -12 41 -83 66 -157 53 -158 108 -299 116 -299 3 0
13 16 22 36 10 19 22 32 27 29 18 -11 11 -50 -15 -85 -14 -19 -25 -40 -25 -47
0 -7 31 -86 69 -176 73 -173 135 -356 255 -747 41 -135 91 -291 110 -348 19
-56 35 -119 34 -140 0 -44 22 -152 32 -152 4 0 17 12 31 26 l24 26 6 -24 c4
-13 7 -35 8 -50 l1 -27 -35 20 c-33 20 -35 20 -34 2 1 -46 25 -158 34 -158 5
0 15 21 23 48 9 33 18 47 31 47 25 0 61 -84 60 -140 0 -26 8 -64 20 -92 12
-25 19 -53 15 -61 -3 -9 14 -36 45 -71 28 -31 61 -77 75 -103 30 -60 88 -224
82 -231 -10 -9 -57 29 -77 61 -10 18 -28 61 -39 97 -11 36 -27 76 -35 90 -13
23 -14 20 -15 -57 0 -59 8 -114 31 -203 38 -147 33 -159 -40 -105 l-51 37 0
-187 c0 -159 -2 -186 -14 -182 -8 3 -83 51 -166 107 -170 113 -180 119 -180
96 0 -20 3 -21 -74 14 -63 28 -66 31 -66 64 0 49 -16 86 -38 86 -13 0 -18 7
-18 22 1 14 -6 24 -17 26 -16 3 -17 1 -7 -17 35 -67 -79 35 -334 299 -199 206
-319 321 -330 317 -6 -2 -13 -17 -14 -33 -4 -30 -18 -54 -33 -54 -5 0 -29 35
-53 77 -42 73 -44 79 -30 101 17 26 33 17 -143 78 -62 21 -113 42 -113 46 0 4
14 15 30 25 35 20 36 24 12 53 -13 17 -33 24 -77 29 l-60 6 -6 49 c-4 37 -12
55 -34 73 -27 24 -28 26 -22 106 6 74 4 87 -18 136 -14 29 -38 70 -54 90 l-29
36 39 95 c21 52 38 103 38 114 1 10 -12 33 -28 51 -16 19 -36 58 -45 87 -14
48 -88 192 -244 472 -39 70 -74 117 -120 161 l-65 63 30 6 c45 10 73 41 73 81
0 19 -2 35 -4 35 -2 0 -26 -9 -51 -20 l-47 -20 5 57 c3 32 11 98 17 146 10 67
10 104 1 157 -6 38 -11 87 -11 110 0 43 27 107 55 130 9 7 28 33 42 57 24 41
26 54 29 203 4 149 2 164 -20 223 -13 35 -37 88 -54 118 -28 50 -36 75 -33
107 1 13 49 62 60 62 3 -1 74 -82 156 -181z m7223 83 c8 -6 -12 -25 -64 -61
-60 -42 -78 -51 -85 -40 -20 32 0 66 56 97 23 13 75 15 93 4z m8148 -217 c45
-27 95 -79 119 -125 l16 -30 -46 -22 c-49 -24 -53 -29 -30 -48 12 -10 27 -11
61 -3 24 6 47 7 49 3 13 -22 75 -173 72 -177 -12 -11 -148 -3 -174 11 -44 22
-90 79 -110 136 -16 46 -20 50 -50 50 -40 0 -41 8 -7 57 22 33 25 46 21 93 -3
30 -8 65 -12 78 -7 21 -6 22 21 12 16 -6 48 -22 70 -35z m7414 -75 c0 -5 -6
-10 -14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m-17000
-40 c0 -21 -34 -80 -47 -80 -9 0 -7 13 9 45 20 42 38 58 38 35z m1649 -200
c104 -66 139 -133 132 -252 -2 -40 -55 -94 -137 -139 -94 -52 -163 -114 -193
-175 -25 -49 -96 -134 -112 -134 -5 0 -9 14 -9 30 0 17 -4 30 -10 30 -5 0 -32
-28 -61 -62 -38 -46 -96 -151 -228 -417 -97 -196 -207 -405 -245 -465 -109
-175 -303 -442 -349 -480 -24 -19 -103 -64 -177 -100 -105 -51 -134 -70 -132
-84 2 -12 -15 -28 -53 -52 -171 -107 -267 -196 -344 -320 -143 -231 -254 -356
-452 -508 -48 -37 44 193 115 289 19 26 130 135 248 244 117 108 217 206 222
216 5 11 38 36 73 55 35 20 63 37 63 39 0 14 -91 -27 -109 -48 -6 -9 -37 -27
-68 -43 -38 -18 -98 -67 -185 -151 -71 -67 -132 -123 -137 -123 -5 0 -5 19 0
43 4 23 8 45 9 50 0 4 8 7 18 7 25 0 62 28 62 47 0 12 -8 9 -35 -11 -19 -15
-35 -23 -35 -18 0 27 38 108 109 236 43 78 128 232 189 343 146 267 175 309
390 566 80 95 130 188 188 352 11 32 39 72 78 115 66 70 111 135 102 148 -2 4
-36 30 -75 58 -39 27 -71 56 -71 65 0 24 57 107 109 158 27 26 113 90 192 143
l143 95 45 -14 c124 -37 268 -22 398 42 76 38 198 143 247 214 16 23 31 41 34
41 3 0 26 -13 51 -30z m-1749 -13 c0 -15 -39 -41 -48 -32 -4 3 4 15 18 25 30
23 30 23 30 7z m15320 -163 c-18 -21 -24 -10 -9 18 8 14 14 18 17 10 2 -7 -2
-19 -8 -28z m-5381 -84 c68 -40 72 -45 55 -58 -13 -9 -32 -11 -58 -7 -30 4
-103 -8 -107 -18 0 -1 -4 -39 -8 -84 -7 -70 -14 -92 -51 -157 -24 -42 -47 -73
-50 -69 -12 13 -40 243 -40 321 l0 72 33 0 c18 0 43 -7 55 -16 22 -15 22 -15
22 34 0 27 3 52 7 55 3 4 20 -2 37 -12 17 -10 64 -38 105 -61z m71 -138 c0
-10 -22 -32 -32 -32 -4 0 -8 14 -8 30 0 28 2 30 20 20 11 -6 20 -14 20 -18z
m5053 -9 c-5 -13 -7 -13 -21 0 -8 8 -11 20 -7 27 10 16 35 -7 28 -27z m-13333
-109 c0 -39 -4 -47 -32 -64 -57 -34 -61 -24 -21 49 21 38 41 67 45 64 5 -2 8
-24 8 -49z m-2500 -194 c0 -5 -9 -10 -19 -10 -11 0 -23 5 -26 10 -4 6 5 10 19
10 14 0 26 -4 26 -10z m17255 -557 c-21 -49 -25 -52 -25 -17 0 17 5 35 12 42
23 23 28 12 13 -25z m3853 -92 l96 -90 -3 -58 c-2 -67 14 -118 64 -204 19 -33
35 -62 35 -65 0 -29 -233 104 -254 145 -8 14 -17 55 -21 91 -3 36 -13 103 -21
150 -17 102 -17 120 -3 120 6 0 54 -40 107 -89z m-10482 -506 c15 -20 15 -20
39 7 l23 27 28 -47 c15 -26 33 -57 41 -68 13 -21 11 -22 -51 -39 l-64 -17 -76
63 c-42 35 -76 69 -76 75 0 8 13 10 39 7 36 -5 39 -3 45 23 8 32 4 34 52 -31z
m10758 9 c16 -16 22 -105 10 -138 -6 -17 -59 -22 -80 -8 -7 4 -15 39 -19 79
-7 69 -6 71 17 76 44 8 57 7 72 -9z m136 -49 c-14 -17 -46 -20 -55 -6 -5 9 15
16 54 20 11 1 11 -2 1 -14z m-10600 -156 c9 -16 5 -20 -30 -29 -40 -11 -80 -6
-80 9 0 12 73 49 87 44 7 -3 18 -14 23 -24z m138 -31 c36 -28 40 -34 25 -40
-43 -16 -84 -8 -115 23 -25 25 -29 33 -17 40 27 17 66 9 107 -23z m7518 -9 c9
-16 -18 -30 -33 -17 -7 6 -10 14 -7 19 7 13 31 11 40 -2z m-7950 -23 c10 -8
16 -17 13 -20 -10 -10 -61 13 -54 24 8 14 18 13 41 -4z m-18146 -40 c-28 -13
-49 7 -30 29 12 14 15 14 32 -2 19 -17 19 -18 -2 -27z m23310 -237 c-12 -39
-33 -83 -49 -102 l-29 -32 -7 36 c-10 56 11 115 58 162 52 52 58 37 27 -64z
m-23370 62 c21 -40 1 -44 -24 -6 -14 21 -14 25 -2 25 9 0 20 -9 26 -19z
m18140 -81 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20
-4 20 -10z m317 -116 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z
m-7617 -59 c11 -13 7 -15 -26 -15 -36 1 -38 2 -20 15 25 19 31 19 46 0z
m14833 -40 c-9 -19 -20 -35 -25 -35 -13 0 -9 34 8 58 23 33 36 16 17 -23z
m-1659 -28 c10 -21 20 -55 23 -77 5 -35 2 -43 -30 -75 -19 -19 -41 -35 -49
-35 -16 0 -78 115 -78 146 0 23 20 43 65 67 39 21 46 18 69 -26z m-24054 -167
c32 -27 33 -29 13 -30 -23 0 -63 32 -63 50 0 16 13 11 50 -20z m25370 -69 c0
-14 -112 -92 -124 -88 -28 9 -12 34 41 64 60 33 83 40 83 24z m-1381 -83 l29
-33 -41 -45 c-23 -25 -44 -46 -48 -48 -4 -2 -11 17 -14 41 -7 45 15 117 36
117 5 0 22 -15 38 -32z m1125 -189 c-45 -86 -211 -325 -221 -316 -7 8 29 102
68 176 44 83 125 189 167 220 15 11 28 18 30 16 2 -2 -18 -45 -44 -96z
m-18931 -60 c5 -19 7 -115 5 -214 -5 -171 -4 -182 18 -230 35 -76 43 -162 46
-496 l2 -304 -32 -138 c-47 -201 -42 -370 19 -666 19 -93 62 -179 133 -265
l24 -29 22 74 c12 40 26 84 31 97 l9 23 54 -30 53 -31 54 70 c35 46 78 88 125
120 120 83 151 132 179 285 47 251 74 310 154 336 50 16 56 22 98 100 40 74
53 77 72 16 12 -39 38 -364 46 -587 13 -364 75 -690 186 -978 39 -104 82 -151
273 -305 77 -62 148 -124 159 -138 19 -26 19 -27 -3 -123 -18 -79 -22 -116
-18 -204 13 -273 136 -422 447 -539 106 -40 177 -52 386 -68 276 -20 346 -41
588 -173 256 -140 516 -227 982 -332 344 -76 537 -131 627 -176 121 -61 803
-537 1092 -763 318 -247 834 -689 972 -832 215 -223 319 -419 480 -904 165
-497 197 -587 295 -821 50 -121 99 -240 110 -266 l19 -48 -58 -29 c-31 -16
-101 -41 -156 -55 -206 -57 -490 -168 -715 -280 -326 -164 -470 -269 -921
-671 -678 -604 -866 -733 -1510 -1033 -129 -60 -489 -232 -800 -382 -311 -150
-595 -285 -631 -301 -57 -24 -441 -115 -739 -174 -141 -29 -266 -6 -500 91
-284 117 -834 336 -995 396 -108 40 -354 110 -514 147 -217 50 -445 86 -1096
172 -664 88 -804 98 -953 71 l-72 -14 21 -34 c12 -19 18 -38 15 -42 -4 -3 -35
-13 -69 -22 -61 -15 -63 -17 -189 -154 -70 -76 -138 -159 -150 -185 -27 -54
-36 -162 -18 -215 25 -76 161 -169 360 -244 63 -24 119 -48 125 -53 5 -5 -9
-9 -36 -9 -30 0 -98 19 -195 53 -176 63 -230 89 -346 168 -97 66 -102 64 -72
-25 19 -54 79 -118 209 -222 179 -144 217 -156 515 -163 224 -6 237 -7 273
-29 60 -38 70 -79 26 -103 -11 -5 -83 -21 -162 -35 -230 -40 -451 -102 -1007
-284 -551 -179 -652 -220 -876 -353 l-91 -54 -101 94 c-179 167 -274 306 -308
451 -20 84 -14 149 21 226 45 96 38 130 -105 476 -67 162 -159 383 -205 490
-111 258 -143 389 -161 655 -6 97 -5 103 29 205 47 136 61 257 47 394 -6 55
-15 119 -20 141 -14 60 -12 249 5 350 8 50 39 286 70 525 31 239 64 477 75
529 49 231 141 446 320 746 257 430 428 653 725 951 255 256 411 387 1185 997
410 323 537 407 745 492 134 56 211 96 327 174 95 64 258 203 258 220 0 10
-74 51 -92 51 -8 0 -46 -21 -84 -46 -38 -25 -118 -69 -179 -98 -60 -29 -131
-70 -156 -91 -49 -40 -668 -334 -704 -334 -26 0 53 74 182 171 77 57 99 80
133 137 123 211 304 332 555 371 45 7 77 18 90 30 19 20 19 20 -8 20 -14 0
-57 -5 -95 -12 -86 -14 -122 -7 -122 27 0 14 8 29 20 35 32 17 141 48 195 56
75 10 108 27 150 77 27 32 47 46 72 50 65 11 79 43 45 101 -17 28 -18 43 -11
152 6 74 16 143 29 179 20 59 25 102 14 142 -6 20 -11 21 -72 17 -98 -7 -111
2 -156 108 -9 21 -34 51 -58 68 -76 55 -80 67 -73 215 4 72 9 139 12 149 4 15
1 18 -16 12 -11 -3 -30 -9 -42 -12 -21 -7 -21 -6 -17 157 l4 164 32 49 c18 28
32 54 32 58 0 5 -17 0 -38 -11 -21 -11 -67 -27 -103 -36 -48 -12 -71 -24 -90
-47 -30 -36 -49 -31 -49 13 0 30 26 62 78 96 28 19 28 22 -8 80 -26 42 -70
175 -70 210 0 21 44 15 80 -12 48 -35 64 -33 57 8 -17 94 -29 199 -24 204 4 3
35 -2 71 -11 91 -23 93 -22 100 27 4 23 15 57 26 75 21 35 74 72 103 72 14 0
17 9 17 53 0 29 5 58 12 65 8 8 51 12 128 12 127 0 173 -11 287 -68 85 -44 96
-77 91 -282 -2 -107 0 -150 8 -150 29 0 33 30 32 236 -1 219 3 247 42 259 11
3 20 15 20 25 0 10 4 21 8 24 5 3 52 -22 105 -55 l97 -59 11 27 c20 54 28 129
21 198 l-7 70 -38 6 c-20 3 -40 8 -43 11 -3 3 10 32 28 64 30 53 33 67 33 139
-1 62 -6 91 -24 130 -20 43 -23 65 -22 160 1 93 4 112 21 130 19 20 19 21 -35
113 -30 51 -55 96 -55 101 0 4 13 20 29 35 50 48 56 76 30 136 -20 45 -21 57
-10 84 52 134 63 181 57 248 -5 51 -2 78 10 108 19 48 34 47 47 -1z m17714
-19 c2 0 -16 -38 -39 -85 -23 -47 -48 -85 -55 -85 -10 0 -13 26 -13 105 l0
105 53 -20 c28 -11 53 -20 54 -20z m4591 -36 c16 -5 16 -6 -1 -15 -13 -8 -22
-7 -33 1 -18 16 -18 31 1 25 8 -3 23 -8 33 -11z m-22225 -42 c10 -11 14 -26
10 -40 -8 -33 -19 -27 -31 18 -11 43 -4 50 21 22z m14087 -37 c11 -13 7 -15
-30 -15 -37 0 -41 2 -30 15 7 8 20 15 30 15 10 0 23 -7 30 -15z m535 -15 c16
-31 22 -182 6 -164 -29 32 -55 194 -31 194 5 0 16 -13 25 -30z m-16605 -105
c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m16277 -91 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m-13333
-202 c-35 -161 -146 -467 -207 -570 l-22 -37 -3 45 c-10 132 101 473 211 645
31 48 32 49 35 22 2 -15 -4 -62 -14 -105z m16641 12 c-5 -34 -9 -43 -16 -33
-13 20 -11 63 3 77 18 18 21 7 13 -44z m3275 -222 c0 -4 -23 -30 -51 -57 l-52
-50 7 34 c3 19 12 41 18 49 20 24 78 42 78 24z m-3390 -79 c0 -13 -3 -13 -15
-3 -8 7 -15 16 -15 21 0 5 7 6 15 3 8 -4 15 -13 15 -21z m-2140 -19 c0 -8 -7
-14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z m2150 -49 c0 -16 -9 -19
-34 -9 -20 8 -11 24 15 24 10 0 19 -7 19 -15z m-2150 -103 c0 -33 -7 -43 -24
-36 -17 6 -8 64 10 64 9 0 14 -11 14 -28z m1976 1 c-10 -10 -19 5 -10 18 6 11
8 11 12 0 2 -7 1 -15 -2 -18z m-16771 -203 c-11 -45 -22 -70 -31 -70 -18 0
-18 6 6 76 30 88 32 91 37 76 3 -7 -3 -44 -12 -82z m-75 -145 c34 -18 35 -23
16 -91 -8 -27 -20 -68 -26 -91 -6 -24 -17 -43 -24 -43 -13 0 -21 40 -42 208
l-7 53 27 -11 c14 -5 40 -17 56 -25z m-180 -75 c0 -82 -15 -93 -25 -17 -3 28
-9 60 -11 70 -4 12 0 17 15 17 19 0 21 -6 21 -70z m-1149 41 c13 -11 21 -22
17 -26 -8 -8 -58 23 -58 36 0 14 16 10 41 -10z m18102 -33 c4 -12 4 -24 1 -27
-6 -7 -24 20 -24 37 0 21 17 13 23 -10z m-2393 -88 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m2260 -37 c0 -16 3 -38 6 -50 5
-19 2 -23 -15 -23 -19 0 -21 6 -21 50 0 38 4 50 15 50 10 0 15 -10 15 -27z
m-17348 -18 c-2 -14 -8 -25 -13 -25 -5 0 -9 11 -9 25 0 14 6 25 14 25 9 0 12
-8 8 -25z m15137 -61 c-13 -14 -25 -20 -27 -14 -5 15 17 40 36 40 12 0 10 -6
-9 -26z m172 -35 c46 -12 86 -23 87 -24 2 -2 -25 -40 -60 -86 l-63 -81 -6 60
c-4 42 -14 74 -32 101 -40 61 -44 59 74 30z m-17488 -38 c16 -6 37 -33 58 -71
39 -74 95 -131 155 -160 24 -12 44 -23 44 -25 0 -2 -15 -12 -32 -24 -18 -11
-27 -21 -20 -21 15 0 52 -36 52 -51 0 -6 -29 16 -65 49 -72 66 -111 88 -172
98 -46 7 -46 7 -59 142 -7 76 -5 80 39 63z m3592 -11 c3 -5 1 -10 -4 -10 -6 0
-11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-3717 -52 c12 -11 17 -58 6 -58
-4 0 -27 19 -52 41 l-44 42 38 -8 c22 -4 45 -11 52 -17z m17858 -44 c-3 -9 -6
-20 -6 -25 0 -5 -30 -38 -66 -73 l-66 -65 4 -63 c6 -74 -3 -105 -34 -117 -29
-11 -35 -2 -43 70 -6 50 -3 66 19 114 14 31 39 74 56 95 43 54 152 106 136 64z
m-14948 -6 c18 -18 14 -98 -7 -137 l-18 -34 -5 84 c-6 101 -1 118 30 87z
m14752 -23 c0 -12 -50 -36 -62 -29 -8 5 -3 12 17 20 33 14 45 17 45 9z
m-13946 -140 c16 -36 48 -88 72 -114 66 -74 74 -100 84 -294 16 -291 20 -302
100 -313 55 -7 60 -14 30 -44 -23 -23 -32 -24 -78 -11 -30 9 -32 8 -38 -17 -3
-15 -8 -33 -10 -41 -10 -32 20 -95 77 -161 65 -76 63 -97 -5 -65 -79 38 -158
156 -175 263 -5 33 -22 98 -36 144 -27 83 -62 300 -86 520 -11 108 -11 121 6
158 10 22 21 40 24 40 4 0 19 -29 35 -65z m-3604 -16 c0 -18 -18 -16 -48 3
-18 11 -30 24 -27 29 6 9 75 -20 75 -32z m360 15 l37 6 32 -71 c18 -39 30 -73
28 -75 -2 -3 -16 0 -31 5 -25 10 -56 6 -56 -8 0 -3 -9 -17 -19 -30 l-18 -23
-26 45 c-31 53 -32 57 -13 57 26 0 28 19 7 65 l-20 45 21 -11 c11 -6 37 -8 58
-5z m17138 -9 c16 -36 15 -40 -12 -53 -41 -19 -62 23 -29 60 21 24 28 23 41
-7z m4532 -15 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-21291 -81 c-15 -43 -35 -59 -76 -59 -25 0 -31 3 -26 16 5 15 104
87 110 80 2 -1 -2 -18 -8 -37z m-552 -35 c30 -17 43 -30 39 -41 -3 -8 -6 -27
-6 -41 -1 -25 -4 -23 -55 33 -38 41 -51 62 -42 67 17 11 15 11 64 -18z m17217
-19 c-7 -17 -30 -20 -39 -6 -4 8 9 15 37 20 4 0 5 -6 2 -14z m-16739 -185 c19
-83 27 -139 21 -137 -17 6 -55 66 -66 106 -16 57 -10 194 7 177 3 -3 20 -68
38 -146z m-365 55 c-16 -19 -29 -19 -52 2 -25 23 -23 32 15 48 31 13 32 12 40
-11 6 -16 5 -30 -3 -39z m17245 23 c-19 -11 -30 -5 -21 11 4 6 14 7 22 4 15
-5 14 -7 -1 -15z m-16700 -37 c-6 -21 -37 -41 -84 -56 -21 -6 -19 -1 20 49 37
47 46 54 57 42 9 -8 11 -22 7 -35z m21050 18 c-10 -15 -25 -10 -19 6 4 8 10
12 15 9 5 -3 7 -10 4 -15z m-21426 -40 c19 -7 38 -24 48 -42 l16 -31 -27 17
c-14 9 -37 24 -51 33 -14 9 -25 20 -25 25 0 11 1 11 39 -2z m3844 -44 c8 -18
-11 -31 -25 -17 -11 11 -3 32 12 32 4 0 10 -7 13 -15z m-3443 -60 c0 -10 -40
-24 -54 -19 -28 11 -17 24 19 24 19 0 35 -2 35 -5z m2973 -82 c5 -27 2 -41 -7
-32 -11 10 -17 59 -8 59 5 0 12 -12 15 -27z m-3243 -4 c0 -6 -4 -7 -10 -4 -5
3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m244 -62 c-3 -34 -10 -66
-14 -70 -4 -4 -28 -4 -54 0 l-48 8 53 67 c29 37 57 64 61 62 5 -3 6 -33 2 -67z
m-838 -69 c4 -5 2 -14 -3 -19 -12 -12 -159 124 -149 139 5 9 29 -6 77 -49 38
-34 72 -66 75 -71z m21955 28 c-36 -75 -116 -167 -180 -208 -25 -15 -49 -28
-53 -28 -5 0 51 69 124 154 73 84 135 152 136 151 2 -2 -10 -33 -27 -69z
m-21255 -192 l52 -72 -61 -67 -62 -67 -50 28 c-27 15 -52 29 -54 30 -2 2 6 16
17 31 l21 28 -32 15 c-44 21 -45 47 -7 169 l32 101 45 -62 c26 -35 70 -95 99
-134z m304 175 c10 -6 -1 -8 -32 -9 -37 0 -48 4 -48 16 0 12 7 14 33 9 17 -4
39 -11 47 -16z m162 -14 c10 -22 9 -29 -9 -46 l-20 -21 -31 40 c-17 21 -29 42
-25 46 3 3 21 6 40 6 26 0 36 -6 45 -25z m20428 -16 c0 -5 -7 -9 -15 -9 -15 0
-20 12 -9 23 8 8 24 -1 24 -14z m-20754 -80 c19 -20 29 -41 29 -61 0 -22 8
-37 28 -51 25 -20 28 -20 44 -4 23 23 3 64 -37 79 -37 12 -38 22 -5 44 24 16
27 15 65 -10 34 -22 42 -35 60 -96 14 -48 33 -84 60 -115 45 -52 50 -79 18
-112 l-21 -23 21 -23 22 -24 -21 -26 c-26 -34 -62 -35 -94 -2 -22 21 -25 33
-25 91 0 50 -5 76 -22 105 -12 22 -25 39 -28 39 -4 0 -15 -20 -25 -45 -9 -26
-21 -45 -26 -43 -9 3 -149 244 -149 256 0 20 34 52 54 52 12 0 35 -14 52 -31z
m3364 21 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16
-10z m-3050 -96 c-13 -39 -25 -43 -40 -15 -15 29 -12 63 7 91 l17 24 13 -33
c11 -26 11 -41 3 -67z m3050 -37 c0 -51 12 -73 72 -129 44 -41 50 -51 43 -75
-11 -48 -23 -49 -59 -7 -35 41 -106 83 -208 124 -70 27 -75 47 -20 97 49 46
65 51 123 39 48 -10 49 -10 49 -49z m16301 20 c-13 -13 -26 -3 -16 12 3 6 11
8 17 5 6 -4 6 -10 -1 -17z m-16580 -110 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5
6 -4 6 -10 -1 -17z m17722 -8 c-10 -17 -50 -62 -90 -100 -58 -54 -83 -71 -127
-84 -31 -9 -60 -14 -66 -10 -13 8 -13 76 -1 109 11 26 79 78 93 70 4 -3 8 -16
8 -30 0 -13 5 -24 10 -24 6 0 50 22 98 50 48 27 88 50 90 50 2 0 -5 -14 -15
-31z m-17603 -49 c13 -9 13 -11 0 -19 -16 -11 -43 5 -34 20 7 11 15 11 34 -1z
m-3222 -82 c31 -26 39 -51 24 -75 -6 -10 -23 -8 -74 9 l-66 21 20 59 20 59 22
-23 c13 -13 37 -35 54 -50z m20452 62 c23 -16 -15 -41 -49 -33 -34 8 -36 10
-21 28 14 17 48 19 70 5z m-20611 -94 c19 -11 20 -14 8 -19 -9 -3 -26 3 -39
13 -27 23 -7 27 31 6z m3561 -95 c5 -11 10 -38 10 -61 l0 -41 -30 16 c-30 15
-60 60 -60 90 0 24 66 20 80 -4z m-3140 -126 c0 -28 -17 -44 -40 -38 -15 4
-17 12 -13 41 9 65 12 70 33 42 11 -14 20 -34 20 -45z m3817 23 c14 -11 35
-68 25 -68 -21 0 -140 54 -136 62 16 26 81 29 111 6z m-4028 -13 c52 -26 81
-71 81 -123 l0 -47 -60 62 c-47 48 -60 68 -60 92 0 36 1 36 39 16z m16962 -28
c-1 -4 -1 -16 -1 -27 0 -11 -4 -20 -10 -20 -5 0 -10 14 -10 31 0 17 5 29 11
27 6 -2 10 -7 10 -11z m-791 -136 c0 -4 -16 -15 -35 -24 -41 -20 -46 -7 -9 22
24 19 44 20 44 2z m-11390 -6 c17 -9 30 -22 30 -30 0 -14 -4 -13 -36 0 -20 8
-38 15 -40 15 -2 0 -4 7 -4 15 0 19 13 19 50 0z m11990 -60 c12 -22 11 -27 -5
-45 -26 -29 -31 -25 -27 25 4 51 13 56 32 20z m-1035 -35 c-45 -11 -87 -11
-80 0 3 6 30 9 58 9 45 -1 48 -2 22 -9z m-220 -9 c81 -15 6 -31 -368 -81 -77
-10 -127 -6 -127 10 0 13 108 51 185 65 77 15 248 18 310 6z m-15124 -94 c-25
-31 -245 -127 -258 -113 -9 9 105 81 161 102 71 27 115 31 97 11z m16439 -14
c0 -9 -33 -69 -49 -86 -8 -11 -24 -23 -34 -29 -15 -7 -10 7 19 60 24 44 43 68
51 65 7 -3 13 -7 13 -10z m-16256 -82 c10 -11 15 -25 11 -30 -9 -15 -48 -14
-70 2 -17 13 -17 15 6 30 30 21 32 21 53 -2z m-119 -47 c24 6 25 4 25 -37 l0
-44 -85 26 c-47 14 -85 31 -85 38 0 6 10 19 21 27 19 13 26 13 60 -1 22 -8 50
-12 64 -9z m4039 5 c5 -4 -3 -11 -17 -14 -26 -7 -67 8 -67 25 0 10 71 1 84
-11z m192 -105 c21 -32 8 -44 -48 -44 -73 0 -112 34 -124 110 l-7 42 81 -41
c45 -23 89 -53 98 -67z m12034 71 c0 -7 -50 -59 -112 -114 l-111 -100 -74 62
c-68 55 -73 62 -57 74 21 16 58 17 84 3 15 -8 45 0 137 40 65 27 121 50 126
50 4 0 7 -7 7 -15z m-16700 -34 c0 -15 -51 -51 -62 -45 -6 4 3 17 18 30 31 25
44 29 44 15z m215 -15 c-18 -27 -22 -68 -11 -104 7 -20 5 -21 -19 -12 -52 20
-100 12 -154 -24 -41 -28 -51 -31 -51 -18 0 51 71 117 166 157 77 32 89 32 69
1z m4621 -72 c53 -31 56 -44 9 -44 -40 0 -78 31 -69 55 8 19 8 19 60 -11z
m-4186 -19 c19 -23 4 -64 -27 -71 -50 -13 -80 30 -47 67 20 23 57 25 74 4z
m4380 -17 l25 -10 -25 -13 c-34 -19 -33 -19 -54 16 l-18 31 23 -7 c13 -4 35
-12 49 -17z m-4670 2 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10
8 0 15 -4 15 -10z m4788 -49 c16 -50 16 -50 -10 -44 -31 8 -68 43 -68 65 0 14
8 18 33 18 31 0 35 -4 45 -39z m-5402 -28 c-10 -10 -19 5 -10 18 6 11 8 11 12
0 2 -7 1 -15 -2 -18z m94 -69 c0 -26 -19 -53 -49 -69 -28 -14 -29 -14 -24 8 6
23 51 77 65 77 4 0 8 -7 8 -16z m5734 -1 c11 -7 -14 -23 -35 -23 -9 0 -27 9
-40 20 l-24 21 45 -7 c25 -3 49 -9 54 -11z m-716 -38 c10 -10 -30 -55 -48 -55
-11 0 -20 -3 -20 -8 0 -10 546 -184 567 -180 39 7 25 37 -31 69 -37 20 -58 39
-62 55 -10 38 0 38 47 5 44 -33 93 -40 131 -20 32 17 33 3 5 -67 -15 -36 -25
-69 -23 -73 3 -4 98 -47 211 -96 203 -86 272 -124 231 -125 -10 0 -51 10 -90
22 -53 16 -94 21 -161 20 -93 -1 -227 28 -580 125 -157 43 -179 53 -208 99
-19 30 -38 42 -137 80 -63 25 -119 49 -124 54 -18 18 40 34 122 32 45 -1 82 0
82 2 0 3 -10 22 -22 42 l-22 37 64 -6 c35 -4 65 -9 68 -12z m247 -37 c33 -15
32 -24 -7 -37 -70 -21 -95 -22 -139 -4 l-44 17 55 18 c66 20 100 22 135 6z
m455 -108 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16
-10z m14685 -140 c15 0 35 5 46 10 13 8 26 7 43 -2 14 -7 121 -103 238 -214
117 -110 333 -309 479 -442 242 -219 309 -288 309 -321 0 -25 -28 -7 -114 77
-107 103 -186 164 -431 332 -99 68 -215 149 -257 182 -86 65 -264 227 -378
342 l-75 76 57 -20 c32 -11 69 -20 83 -20z m-5470 -10 c3 -5 -1 -10 -10 -10
-9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m-9125 -5 c0 -8 -62 -37
-67 -31 -14 14 9 36 37 36 16 0 30 -2 30 -5z m-5803 -91 c-24 -46 -163 -160
-322 -264 -82 -54 -195 -130 -250 -168 -55 -39 -130 -85 -167 -102 -37 -18
-85 -48 -107 -67 -51 -43 -60 -30 -36 55 l17 60 62 12 c103 20 161 52 260 148
50 49 141 131 201 183 105 91 112 95 149 91 35 -4 42 -1 66 28 19 24 40 35 81
45 29 6 55 11 57 9 2 -1 -3 -15 -11 -30z m14807 -45 c-18 -21 -44 -26 -44 -9
0 6 12 22 28 35 21 19 28 21 30 9 2 -9 -4 -24 -14 -35z m-7814 -470 c15 -27
11 -30 -35 -22 l-38 6 22 19 c30 23 38 23 51 -3z m193 -91 c48 -27 113 -69
145 -93 84 -64 265 -233 280 -261 l13 -25 -38 20 c-21 10 -141 90 -268 176
-230 156 -307 221 -283 236 20 13 57 0 151 -53z m1645 -215 l42 -51 -35 -12
c-34 -11 -38 -10 -80 25 -25 20 -45 40 -45 45 0 14 62 62 70 53 3 -4 25 -31
48 -60z m-9698 -52 c0 -15 -110 -107 -320 -267 -276 -211 -402 -318 -596 -505
-173 -169 -251 -236 -260 -226 -12 11 60 127 169 272 136 183 290 341 412 422
99 66 348 214 463 274 68 37 132 51 132 30z m-1160 -250 c0 -11 -31 -24 -39
-16 -3 3 1 10 9 15 19 12 30 12 30 1z m-87 -841 c-12 -21 -20 -27 -28 -19 -7
7 -2 16 15 29 33 26 34 25 13 -10z m-510 -267 l-38 -46 -3 28 c-4 33 26 65 60
65 17 0 14 -7 -19 -47z m-338 -388 c24 -23 34 -55 17 -55 -13 0 -37 23 -55 53
-16 24 -16 27 -2 27 9 0 27 -11 40 -25z m14162 -287 c-14 -35 -37 -49 -37 -22
0 18 27 54 42 54 4 0 2 -14 -5 -32z m-14867 -127 c0 -11 -41 -63 -46 -58 -6 6
29 67 39 67 4 0 7 -4 7 -9z m235 -11 c7 -24 -10 -60 -30 -60 -8 0 -27 11 -42
24 l-28 24 25 10 c14 5 32 12 40 15 25 9 28 8 35 -13z m328 -48 c26 -28 32
-76 12 -92 -22 -18 -31 -4 -38 58 -6 55 0 63 26 34z m-433 -154 c0 -27 -16
-55 -25 -46 -9 9 5 68 16 68 5 0 9 -10 9 -22z m12980 -42 c-13 -13 -35 7 -25
24 5 8 11 8 21 -1 10 -8 12 -15 4 -23z m-12585 -115 c7 -12 -12 -24 -25 -16
-11 7 -4 25 10 25 5 0 11 -4 15 -9z m-226 -118 c0 -10 -4 -27 -8 -37 -11 -28
-23 -4 -15 29 7 28 24 34 23 8z m-639 -275 c0 -17 -18 -44 -24 -37 -8 7 5 49
15 49 5 0 9 -6 9 -12z m-10 -180 c-1 -13 -7 -48 -14 -78 l-13 -55 -13 40 c-10
34 -9 47 4 78 17 41 36 49 36 15z m759 -39 c-16 -10 -23 -4 -14 10 3 6 11 8
17 5 6 -4 5 -9 -3 -15z m15251 -324 c10 -12 10 -15 -4 -15 -9 0 -16 7 -16 15
0 8 2 15 4 15 2 0 9 -7 16 -15z m-15616 -1778 c28 -99 31 -124 17 -146 -7 -11
-14 -5 -29 25 -11 21 -23 53 -26 71 -9 42 -42 83 -66 83 -18 0 -40 27 -40 49
0 7 24 11 59 11 l58 0 27 -93z m389 26 c-16 -46 -16 -47 10 -94 32 -55 33 -70
9 -92 -17 -16 -20 -13 -46 51 -42 100 -44 134 -11 161 46 35 56 28 38 -26z
m2892 -182 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m119
-64 c7 -37 12 -67 11 -67 -16 0 -77 34 -82 46 -5 13 45 99 53 91 2 -2 10 -33
18 -70z m-3351 13 c35 -41 65 -200 39 -200 -8 0 -84 51 -106 70 -9 9 23 160
35 160 4 0 18 -13 32 -30z m4527 -59 c58 -11 137 -22 175 -26 39 -4 90 -16
113 -27 38 -18 49 -19 83 -9 34 10 57 9 147 -9 59 -12 145 -35 192 -51 47 -17
145 -50 219 -75 156 -51 242 -105 234 -148 -5 -24 -27 -36 -67 -36 -10 0 -77
25 -148 55 -72 31 -171 67 -222 81 -50 14 -116 39 -146 56 -49 27 -68 31 -180
39 -131 9 -148 12 -315 58 -94 27 -223 49 -380 66 -74 8 -131 33 -168 72 l-28
30 193 -28 c106 -16 240 -37 298 -48z m-895 -18 c40 -19 43 -28 16 -43 -30
-15 -66 -12 -91 8 l-23 19 24 16 c29 21 30 21 74 0z m-135 -48 c-12 -15 -30
-12 -30 6 0 5 10 9 21 9 18 0 19 -2 9 -15z m15060 -33 c-6 -2 -18 -1 -26 2
-14 5 -14 9 -3 22 12 14 14 14 26 -2 9 -13 10 -20 3 -22z m5844 12 c19 -7 22
-34 4 -34 -7 0 -23 -3 -35 -6 -26 -7 -32 13 -11 34 14 14 20 14 42 6z m-20644
-49 c29 -31 22 -39 -29 -30 -31 5 -41 11 -41 25 0 17 12 27 36 29 6 1 21 -10
34 -24z m-3690 -92 c0 -12 -9 -27 -20 -33 -36 -19 -45 42 -13 93 8 13 32 -32
33 -60z m359 -37 c28 -45 26 -55 -4 -26 -14 13 -25 32 -25 42 0 26 5 23 29
-16z m3081 10 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m20489
-47 c31 -11 43 -39 17 -39 -18 0 -119 22 -126 27 -9 8 25 23 51 23 14 0 40 -5
58 -11z m-20575 -33 c9 -14 12 -27 7 -30 -11 -7 -53 38 -44 47 11 12 20 8 37
-17z m678 -48 c-17 -17 -47 9 -30 29 10 12 13 12 27 -1 11 -12 12 -19 3 -28z
m-4022 -2 c-13 -13 -35 7 -25 24 5 8 11 8 21 -1 10 -8 12 -15 4 -23z m23680 8
c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z
m-23760 -56 c0 -10 -4 -18 -9 -18 -10 0 -21 47 -13 55 8 7 22 -17 22 -37z
m3605 12 c19 -20 25 -34 19 -40 -13 -13 -80 42 -70 58 11 18 20 15 51 -18z
m2190 0 c0 -22 -29 -18 -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m-6172
-25 c19 -39 22 -92 7 -115 -8 -13 -10 -13 -14 0 -14 41 -36 125 -36 137 0 26
27 12 43 -22z m401 -147 c3 -13 6 -32 6 -42 0 -29 -17 -7 -25 32 -7 36 9 45
19 10z m1014 -142 c45 -49 55 -75 50 -127 -3 -33 -4 -32 -47 46 -24 44 -53 95
-64 114 -20 33 -20 33 0 23 10 -6 38 -31 61 -56z m-418 20 c0 -8 -6 -19 -14
-25 -18 -15 -27 6 -14 32 10 19 28 14 28 -7z m3233 -149 c-3 -5 -10 -4 -15 1
-6 6 -7 16 -3 22 6 9 9 9 16 -1 4 -7 5 -17 2 -22z m-3724 -23 c25 -42 25 -40
6 -48 -10 -4 -21 7 -35 33 -18 36 -18 51 1 51 4 0 16 -16 28 -36z m96 -14 c-5
-8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7 -16 3 -22z m72 -77
c-27 -27 -28 -28 -43 -9 -22 30 -17 36 28 36 l43 0 -28 -27z m24956 -41 c10
-11 14 -22 10 -25 -12 -8 -75 42 -67 54 7 11 33 -2 57 -29z m-19048 -72 c-3
-5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z
m-5818 -71 c28 -10 29 -20 4 -50 l-18 -22 -31 22 c-18 12 -49 25 -69 28 -57 9
-73 20 -73 53 l0 28 82 -25 c45 -14 92 -29 105 -34z m4020 4 c4 -10 -55 -53
-71 -53 -16 0 -3 31 21 50 27 21 44 22 50 3z m-3217 -223 l123 -135 -53 -3
c-30 -2 -64 -1 -76 2 -41 11 -82 56 -104 114 -12 31 -30 66 -40 77 -10 11 -24
39 -31 63 -11 37 -10 45 2 52 16 11 7 20 179 -170z m2910 141 c0 -16 -12 -41
-20 -41 -4 0 -5 11 -2 25 4 23 22 36 22 16z m-3110 -68 c-8 -3 -23 -10 -32
-15 -13 -6 -18 -5 -18 7 0 10 10 15 33 14 17 0 25 -3 17 -6z m-595 -138 c9
-23 15 -55 13 -70 l-3 -29 -27 40 c-16 23 -31 60 -34 89 l-7 50 21 -19 c12
-10 29 -37 37 -61z m3851 60 c-11 -8 -32 -15 -46 -15 -14 0 -35 7 -46 15 -17
13 -13 14 46 14 59 0 63 -1 46 -14z m-4352 -70 c27 -25 30 -35 19 -74 -6 -22
-9 -19 -34 30 -15 30 -28 57 -29 62 0 14 17 7 44 -18z m26094 -104 c-23 -44
-45 -81 -48 -81 -3 0 -11 15 -18 32 -12 32 -11 33 46 84 31 29 59 50 60 49 2
-2 -16 -40 -40 -84z m-163 -24 c50 -33 94 -63 99 -68 5 -4 -6 -26 -23 -48 -37
-46 -57 -50 -138 -26 -93 28 -123 72 -102 149 10 37 45 70 63 59 6 -4 52 -33
101 -66z m-20770 -89 c-4 -24 -8 -44 -9 -45 -6 -7 -86 22 -86 31 0 29 32 56
66 56 l36 0 -7 -42z m153 13 c16 -45 53 -65 112 -58 l49 6 -11 -30 c-12 -33
-52 -69 -79 -69 -20 0 -219 82 -219 90 0 6 122 88 132 89 3 1 11 -12 16 -28z
m-958 -1 c12 -8 11 -10 -7 -10 -13 0 -23 5 -23 10 0 13 11 13 30 0z m-4181
-145 c34 -47 65 -114 57 -122 -2 -2 -26 28 -53 69 -67 99 -66 98 -50 98 7 0
28 -20 46 -45z m479 16 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9
7 -2 15 -10 17 -17z m-355 -210 c21 -23 43 -62 51 -92 24 -78 21 -87 -15 -53
-32 31 -62 81 -85 146 -18 52 2 51 49 -1z m487 9 c8 -5 11 -12 7 -16 -4 -4
-15 0 -24 9 -18 18 -8 23 17 7z m-2 -264 c4 -18 -27 -68 -38 -61 -4 3 -11 22
-14 42 -4 21 -10 47 -13 57 -5 19 -4 19 28 -2 19 -11 35 -28 37 -36z m-367 13
c11 -5 30 -34 44 -64 13 -30 49 -96 80 -148 58 -97 102 -197 86 -197 -5 0 -40
33 -79 74 -82 87 -149 210 -159 294 -6 55 -4 58 28 41z m449 -190 c11 -20 11
-20 -19 -4 -36 18 -39 25 -11 25 10 0 24 -9 30 -21z m48 -50 c-19 -13 -28 -11
-28 5 0 9 7 12 21 9 16 -4 17 -8 7 -14z m2816 -390 c-3 -5 -10 -7 -15 -3 -5 3
-7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m99 -167 c45 -57 46 -60 24 -66
-85 -23 -129 26 -97 109 12 32 15 31 73 -43z m-582 -153 c37 -29 49 -49 31
-49 -16 0 -92 62 -86 71 8 14 13 12 55 -22z m-120 8 c-1 -12 -15 -9 -19 4 -3
6 1 10 8 8 6 -3 11 -8 11 -12z m436 -122 c20 -8 33 -18 30 -21 -8 -8 -77 16
-77 27 0 12 5 12 47 -6z m339 -80 c15 -11 16 -14 3 -15 -8 0 -24 7 -35 15 -15
11 -16 14 -3 15 8 0 24 -7 35 -15z m118 -74 c3 -5 -1 -14 -8 -20 -16 -13 -32
2 -21 19 8 12 22 13 29 1z m-2095 -115 c8 -10 8 -16 -3 -25 -10 -9 -16 -8 -26
3 -12 15 -6 36 9 36 5 0 14 -6 20 -14z m196 -156 c3 -5 -3 -10 -14 -10 -11 0
-23 5 -26 10 -3 6 3 10 14 10 11 0 23 -4 26 -10z m2425 -14 c0 -1 -5 -18 -11
-35 -7 -20 -15 -29 -21 -23 -11 11 7 62 22 62 5 0 10 -2 10 -4z m-2615 -26
c-21 -23 -37 -25 -68 -8 -21 12 -21 13 13 19 19 3 44 7 54 8 18 1 19 0 1 -19z
m470 -42 c75 -38 156 -91 148 -98 -4 -4 -23 -12 -42 -18 -29 -10 -37 -9 -59 8
-20 16 -34 19 -67 15 l-41 -7 -18 54 c-9 29 -20 59 -23 67 -7 17 46 6 102 -21z
m260 -88 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-64 -90 c113 0 206 -28 269 -80 l25 -21 -37 -31 -38 -31 115 -64 c87 -49 115
-70 115 -84 0 -15 -5 -19 -17 -15 -81 24 -148 35 -204 34 -59 -1 -73 3 -132
36 -49 27 -67 43 -67 57 0 47 -34 92 -100 135 -87 56 -100 72 -76 90 16 11 24
10 54 -7 24 -12 56 -19 93 -19z m174 -350 c8 -13 -11 -13 -36 1 -11 5 -17 14
-14 20 7 10 39 -4 50 -21z m520 -222 c7 -6 -2 -8 -24 -6 -21 2 -39 11 -49 25
l-14 23 38 -16 c22 -9 44 -20 49 -26z m90 -66 c0 -18 -20 -15 -23 4 -3 10 1
15 10 12 7 -3 13 -10 13 -16z m19960 -552 c-67 -25 -87 -25 -55 0 16 12 40 19
65 19 l40 -1 -50 -18z m-14305 -3365 c-20 -24 -31 -18 -18 10 8 17 15 22 21
16 7 -7 6 -15 -3 -26z m121 -375 c13 -10 19 -19 12 -20 -6 0 -17 -2 -25 -5
-20 -7 -39 10 -32 28 8 22 17 21 45 -3z m1621 -15 c0 -5 -5 -11 -11 -13 -6 -2
-11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-1608 -141 c8 -22 8 -24 -8
-24 -18 0 -29 16 -21 30 9 15 22 12 29 -6z m1185 -1906 c8 -13 20 -33 28 -45
8 -12 10 -24 5 -27 -11 -6 -74 79 -65 88 11 10 18 7 32 -16z m247 -320 c33
-35 69 -79 81 -98 24 -42 46 -106 41 -121 -2 -7 -50 51 -105 129 -91 126 -105
152 -83 152 4 0 34 -28 66 -62z m6604 -13 c-31 -33 -46 -21 -19 16 15 22 25
27 32 20 8 -8 4 -18 -13 -36z m124 -1 c20 -8 21 -34 2 -59 -12 -17 -13 -17
-19 7 -4 15 -10 33 -13 42 -6 17 4 20 30 10z m-204 -111 c-1 -14 -30 -47 -36
-41 -6 7 17 48 28 48 5 0 8 -3 8 -7z m134 -115 c8 -13 18 -35 21 -50 l7 -27
-60 32 c-66 35 -75 49 -40 59 42 11 57 8 72 -14z m-6324 -180 c43 -51 77 -94
75 -97 -6 -5 -54 26 -88 58 -29 26 -58 76 -72 121 -9 31 -10 32 85 -82z m5666
-84 c-3 -9 -6 -24 -6 -35 0 -10 -7 -19 -15 -19 -12 0 -14 7 -9 28 9 35 13 42
26 42 5 0 7 -7 4 -16z m-160 -120 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9
9 15 11 12 3 -3 3 -13 -1 -22z m554 11 c0 -14 -66 -33 -82 -24 -32 17 -19 29
32 29 28 0 50 -2 50 -5z m-690 -50 c-9 -11 -16 -13 -23 -6 -7 7 -6 15 3 26 9
11 16 13 23 6 7 -7 6 -15 -3 -26z m375 -101 c-15 -23 -49 -32 -59 -16 -2 4 12
30 32 57 l37 49 3 -32 c2 -19 -4 -44 -13 -58z m175 9 c-1 -15 -67 -83 -73 -76
-11 11 -8 58 5 71 13 13 68 17 68 5z m-5620 -77 c16 -25 30 -47 30 -49 0 -9
-39 5 -49 17 -10 13 -25 76 -17 76 3 0 19 -20 36 -44z m5269 0 c10 -11 8 -16
-8 -21 -25 -8 -41 1 -41 21 0 18 34 18 49 0z m-211 -74 c-63 -68 -62 -67 -78
-47 -11 13 -10 18 6 29 10 8 39 30 64 49 65 52 69 34 8 -31z m436 8 c-13 -13
-49 24 -39 40 5 9 14 6 28 -9 15 -14 18 -24 11 -31z m-326 -69 c-34 -28 -46
-12 -13 17 18 15 31 20 33 13 2 -6 -7 -20 -20 -30z m264 -6 c8 -9 -104 -112
-134 -124 -26 -10 -68 -5 -68 8 0 10 91 96 115 109 21 12 78 16 87 7z m-285
-96 c32 -12 64 -28 71 -37 21 -27 14 -68 -18 -100 -16 -17 -30 -33 -30 -35 0
-3 16 -11 35 -17 19 -6 35 -15 35 -19 0 -5 -24 -27 -52 -49 l-53 -41 -82 82
-83 82 0 -63 c0 -34 -2 -62 -5 -62 -3 0 -20 6 -38 13 -79 33 -73 79 21 179 91
96 105 101 199 67z m-4979 -11 c19 -19 14 -30 -8 -18 -11 6 -20 15 -20 20 0
14 14 12 28 -2z m3865 -88 c-58 -55 -895 -740 -903 -740 -24 0 -1 27 153 172
296 282 457 417 655 552 69 46 87 55 99 45 12 -11 12 -15 -4 -29z m652 -37
c-4 -16 -8 -38 -11 -50 -3 -17 -11 -23 -29 -23 -14 0 -25 3 -25 6 0 8 60 94
66 94 2 0 2 -12 -1 -27z m-50 -113 l15 -30 41 30 c23 16 46 27 50 24 11 -7 16
3 -59 -120 -51 -83 -72 -108 -117 -138 -30 -20 -59 -36 -64 -36 -15 0 -22 74
-7 85 7 6 16 26 20 46 5 28 90 166 103 169 1 0 9 -13 18 -30z m187 -130 c18
-33 37 -60 43 -60 5 0 48 9 95 20 106 24 103 24 89 -2 -40 -78 -135 -158 -210
-178 -34 -9 -37 -13 -42 -56 -5 -45 -37 -94 -63 -94 -7 0 -18 16 -24 35 -12
37 -42 60 -66 51 -19 -7 -18 4 6 59 l20 46 -45 -7 c-53 -7 -54 -4 -23 68 17
41 38 64 102 114 44 35 81 64 83 64 1 0 16 -27 35 -60z m108 36 c0 -19 -14
-28 -34 -20 -13 5 -14 9 -5 20 15 18 39 18 39 0z m-508 -72 c55 -28 56 -28
-16 -65 -51 -27 -86 -19 -86 19 0 29 24 72 41 72 5 0 33 -12 61 -26z m-122
-154 c26 -26 26 -76 1 -90 -30 -15 -60 -12 -98 11 -27 17 -32 25 -27 43 17 56
84 76 124 36z m104 -38 c12 -20 -74 -95 -91 -79 -2 3 5 23 17 46 15 31 26 41
45 41 13 0 26 -4 29 -8z m-152 -163 c-4 -4 -40 -21 -79 -38 -81 -34 -83 -32
-62 42 7 26 21 44 42 57 l32 20 37 -37 c21 -20 35 -40 30 -44z m-203 -20 l44
-51 -53 -53 c-50 -51 -163 -115 -178 -101 -3 4 2 22 11 41 9 20 17 39 17 44 0
4 -26 6 -57 3 -40 -3 -55 -1 -48 6 17 18 208 162 214 162 3 0 26 -23 50 -51z
m-219 -247 c0 -43 -61 -83 -98 -63 -48 25 -26 82 38 99 37 10 60 -4 60 -36z
m-1843 -11 c17 -11 45 -41 63 -66 31 -46 32 -47 9 -40 -58 18 -129 82 -129
118 0 13 24 8 57 -12z m1689 -14 c-48 -31 -66 -51 -74 -79 -4 -17 -17 -37 -27
-44 -17 -11 -24 -9 -50 11 l-30 24 35 37 c42 43 83 63 130 63 31 0 33 -2 16
-12z m-151 -137 c19 -7 18 -9 -9 -19 -29 -12 -96 -5 -96 9 0 19 68 25 105 10z
m-1375 -62 c0 -13 -27 -9 -40 7 -19 23 -1 39 21 19 10 -9 19 -21 19 -26z
m-372 -294 c32 -9 27 -24 -8 -24 -20 0 -30 5 -30 15 0 17 4 18 38 9z"
      />
      <path d="M28589 34504 l-24 -13 30 0 c17 -1 36 3 43 8 24 15 -22 20 -49 5z" />
      <path
        d="M19775 33649 c-4 -6 -5 -12 -2 -15 7 -7 37 7 37 17 0 13 -27 11 -35
-2z"
      />
      <path
        d="M19350 32675 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"
      />
      <path
        d="M19930 31445 c-103 -32 -161 -130 -206 -342 -34 -165 -74 -273 -189
-513 -120 -251 -137 -294 -166 -432 -29 -134 -29 -370 0 -733 12 -143 21 -285
21 -316 0 -36 -24 -152 -66 -320 -36 -145 -105 -428 -154 -629 -122 -508 -197
-722 -333 -953 -134 -226 -475 -642 -848 -1032 -255 -268 -367 -412 -440 -570
-18 -38 -50 -96 -71 -128 -138 -205 -227 -473 -247 -745 -32 -417 117 -967
355 -1319 96 -142 159 -217 344 -408 206 -212 305 -324 461 -518 223 -277 215
-270 326 -312 54 -21 150 -64 213 -95 141 -71 229 -99 435 -140 88 -17 213
-45 279 -61 161 -39 310 -56 367 -41 50 13 68 3 89 -48 11 -27 14 -28 34 -16
11 8 54 21 94 31 l73 17 -91 55 c-122 73 -107 86 40 33 62 -22 118 -40 123
-40 16 0 -15 30 -47 47 -44 22 -76 54 -76 75 0 20 26 23 78 9 31 -9 32 -9 32
23 0 37 -11 54 -57 85 -39 27 -48 51 -33 96 l11 35 61 -43 c103 -72 185 -153
214 -212 14 -30 32 -55 40 -55 28 0 63 24 73 49 16 43 21 163 8 188 -7 12 -45
45 -84 72 -40 27 -218 164 -395 303 -178 140 -390 305 -473 368 -266 202 -348
274 -567 502 -366 380 -397 418 -444 550 -51 144 -40 213 53 330 119 150 151
238 162 445 l8 142 76 153 c63 125 81 172 98 253 32 153 38 172 63 202 13 15
27 39 31 53 25 83 81 164 172 248 98 91 163 175 183 239 7 21 30 74 51 117 22
44 39 90 39 103 0 13 11 39 24 56 41 55 81 146 131 297 41 123 108 296 120
308 1 2 20 -4 40 -12 21 -9 43 -16 50 -16 17 0 110 234 140 354 32 125 52 309
65 576 17 370 21 404 105 785 36 160 70 323 76 363 7 39 15 72 19 72 17 0 39
-83 55 -210 45 -360 196 -858 350 -1160 25 -48 63 -142 85 -209 52 -160 110
-281 207 -433 95 -148 123 -193 123 -202 0 -3 -18 3 -40 14 -45 23 -50 24 -50
10 0 -5 117 -159 259 -342 l259 -333 -26 -14 c-15 -7 -28 -15 -30 -16 -6 -6
26 -60 47 -79 17 -15 30 -18 67 -12 32 4 54 1 75 -10 l30 -15 -27 -21 c-25
-20 -26 -24 -20 -86 7 -63 9 -67 80 -146 219 -244 358 -479 448 -756 35 -107
51 -141 85 -181 57 -67 67 -102 58 -207 -24 -297 -218 -714 -489 -1052 -143
-177 -482 -502 -931 -889 -238 -205 -285 -264 -285 -359 0 -40 22 -112 34
-112 7 0 27 16 46 35 19 19 37 35 41 35 4 0 13 -16 19 -36 7 -19 15 -33 18
-29 4 3 25 59 48 123 35 98 45 116 60 114 90 -16 82 -19 148 52 33 36 64 66
68 66 15 0 -108 -238 -143 -277 -40 -43 -53 -75 -47 -113 3 -21 5 -20 50 14
26 20 51 36 56 36 5 0 22 -68 38 -151 26 -141 30 -152 53 -161 33 -13 181 -13
250 1 29 6 86 11 126 11 l73 0 27 75 c15 41 33 75 40 75 16 0 52 -39 63 -70 6
-15 13 -57 17 -94 l8 -69 58 7 c116 12 69 -35 725 721 494 570 653 785 744
1005 17 40 48 101 69 134 95 151 151 286 181 440 12 58 33 144 48 192 l27 88
41 -52 41 -51 7 52 c4 28 21 113 37 187 27 118 30 147 25 235 -6 115 -46 326
-82 433 -13 39 -49 123 -80 184 -31 62 -77 164 -101 228 -25 63 -93 216 -153
340 -124 258 -182 390 -287 650 -142 353 -242 511 -514 811 -160 176 -416 472
-475 549 -157 202 -315 562 -404 915 -83 332 -98 541 -46 663 14 34 38 90 53
125 64 151 87 337 64 523 -38 305 -115 664 -142 664 -12 0 -11 12 -18 -157 -6
-139 -12 -175 -26 -161 -3 3 -35 70 -70 149 -36 79 -80 168 -98 197 -32 53
-32 53 -43 28 -6 -16 -7 -49 -3 -84 15 -119 11 -175 -17 -228 -15 -28 -25 -63
-25 -82 5 -102 -40 -282 -70 -282 -15 0 -140 20 -156 25 -8 3 -1 46 23 142 32
127 37 140 71 169 33 29 35 34 29 70 -4 21 -7 49 -6 62 0 12 1 52 2 90 2 73
-14 116 -69 181 l-29 34 18 36 c34 67 13 151 -38 151 -31 0 -40 -23 -24 -58
10 -24 9 -29 -15 -50 -33 -28 -46 -68 -85 -265 -33 -160 -40 -181 -70 -197
-24 -13 -24 -11 -9 113 12 99 10 103 -54 144 -29 18 -37 29 -32 41 30 71 30
71 8 94 l-22 21 -37 -28 c-20 -15 -45 -48 -56 -73 -18 -39 -19 -56 -13 -131
18 -202 1 -289 -114 -580 -79 -199 -153 -416 -167 -486 -6 -34 -20 -53 -31
-42 -2 3 -22 70 -44 148 -40 144 -46 199 -30 242 36 94 80 357 111 671 25 238
20 296 -31 403 -83 174 -217 264 -392 262 -40 0 -92 -7 -117 -14z m1306 -756
l-14 -34 -1 32 c-1 17 5 33 12 36 19 7 19 2 3 -34z m-361 -39 c9 -43 0 -74
-71 -248 -71 -172 -82 -236 -41 -230 19 3 22 10 25 53 2 32 17 79 41 130 l38
80 22 -43 c25 -49 24 -58 -21 -233 -24 -93 -35 -119 -52 -129 -42 -22 -60 -68
-64 -167 -5 -97 -13 -111 -46 -77 -20 20 -20 20 9 208 14 88 23 161 21 163 -2
2 -18 -40 -36 -94 -44 -135 -80 -174 -80 -88 0 75 29 193 70 284 21 47 50 127
65 176 28 95 93 245 106 245 4 0 10 -13 14 -30z m55 -140 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m430 -290 c25 -47 -35
-87 -75 -50 l-24 22 27 34 c28 35 51 34 72 -6z m-230 -42 c17 -73 11 -99 -23
-111 -32 -10 -47 -3 -29 15 7 7 12 35 12 64 0 96 22 113 40 32z m214 -161 c8
-12 5 -22 -14 -42 l-24 -26 -18 23 c-16 22 -16 24 5 46 23 25 35 24 51 -1z
m-704 -136 c0 -17 -18 -41 -31 -41 -6 0 -4 24 7 58 5 18 24 5 24 -17z m340
-41 c0 -16 -4 -30 -10 -30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10
-30z m-345 -100 c-5 -8 -11 -8 -21 1 -12 10 -12 14 -2 20 16 10 33 -5 23 -21z
m126 -17 c4 -85 -22 -223 -42 -223 -15 0 -31 74 -27 129 4 55 38 131 59 131 5
0 10 -17 10 -37z m473 -39 c28 -11 17 -24 -18 -24 -34 0 -52 11 -39 24 8 8 37
8 57 0z m-579 -150 c8 -54 2 -64 -25 -39 -22 20 -25 37 -10 66 17 30 27 22 35
-27z m-47 -230 c10 -26 12 -65 9 -120 -3 -44 -2 -97 3 -117 7 -33 6 -37 -11
-37 -11 0 -19 7 -19 18 0 9 -7 77 -15 151 -8 74 -12 138 -9 143 11 17 29 1 42
-38z m144 -154 c3 -17 1 -33 -3 -36 -13 -8 -31 35 -24 57 9 26 22 16 27 -21z
m321 12 c17 -15 24 -72 9 -72 -7 0 -52 83 -52 96 0 7 20 -5 43 -24z m-407
-360 c-11 -11 -46 56 -46 88 0 62 14 55 32 -17 9 -37 15 -69 14 -71z m133 28
c0 -24 0 -24 -14 -6 -16 21 -21 97 -7 110 7 8 20 -56 21 -104z m996 -600 c21
-24 22 -23 -32 -38 l-42 -12 50 -68 c74 -101 93 -120 122 -126 15 -3 37 -8 50
-11 42 -9 227 -175 415 -372 249 -261 354 -399 478 -623 71 -129 186 -371 180
-378 -13 -12 -83 48 -153 131 -165 196 -227 266 -240 271 -7 3 -24 1 -38 -4
l-25 -10 24 -49 c14 -27 43 -70 66 -96 51 -59 57 -84 32 -135 l-19 -40 44 0
c41 0 45 -3 59 -35 36 -87 3 -90 -139 -10 -164 92 -177 99 -177 105 0 3 32 23
70 45 38 22 70 44 70 48 0 5 -13 22 -29 38 -34 34 -81 38 -135 13 -33 -16 -36
-16 -73 7 -72 44 -107 74 -150 133 -24 32 -43 62 -43 68 0 5 15 6 34 3 l33 -7
-13 79 c-27 160 -23 147 -44 128 -29 -26 -43 -80 -35 -132 l7 -46 -39 30
c-100 80 -233 248 -281 354 -22 48 -66 224 -77 304 -4 28 -18 67 -31 87 -19
30 -23 45 -18 81 4 29 -1 70 -15 125 -29 114 -28 130 12 147 45 20 81 18 102
-5z m91 -143 c15 -20 24 -40 20 -44 -3 -4 -27 3 -51 15 -47 23 -53 37 -38 91
l6 24 18 -24 c11 -13 31 -41 45 -62z m-2436 -245 c0 -13 -12 -22 -22 -16 -10
6 -1 24 13 24 5 0 9 -4 9 -8z m-165 -411 c-16 -48 -32 -81 -40 -81 -40 0 -27
76 22 131 20 22 38 37 40 35 3 -3 -7 -41 -22 -85z m15 -77 c0 -4 -17 -38 -37
-75 -28 -53 -39 -65 -46 -54 -14 25 2 73 35 105 28 28 48 38 48 24z m1928
-356 c14 -14 16 -28 2 -28 -5 0 -14 9 -20 20 -12 22 -1 27 18 8z m1659 -520
c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-78 -19 c-24 -15 -58
-10 -69 10 -16 30 -13 31 36 14 33 -11 42 -18 33 -24z m-4283 -7 c-4 -11 -18
-28 -33 -37 -27 -18 -25 -12 11 37 22 31 31 30 22 0z m-100 -113 c-7 -17 -16
-30 -19 -30 -4 1 -12 1 -19 1 -6 0 1 14 17 30 16 17 30 30 31 30 2 0 -3 -14
-10 -31z m4777 -281 c30 -28 45 -78 23 -78 -23 0 -65 42 -72 73 -8 34 14 37
49 5z m-674 -1661 c-5 -17 -9 -33 -9 -34 0 -2 -4 -3 -10 -3 -14 0 -13 53 2 68
19 19 27 4 17 -31z m-4774 -983 c24 -26 34 -64 17 -64 -4 0 -28 20 -52 45
l-44 45 27 0 c17 0 37 -10 52 -26z m3665 -669 c-19 -44 -22 -47 -36 -33 -21
21 -10 54 24 72 15 8 28 13 30 12 1 -2 -7 -25 -18 -51z m-10 -617 c0 -10 -11
-23 -24 -29 -30 -14 -56 2 -138 89 -61 63 -65 75 -47 116 12 25 12 25 110 -66
58 -53 99 -99 99 -110z m-41 -72 c25 -40 26 -56 6 -82 -15 -18 -16 -18 -50 21
l-34 40 26 27 c15 16 28 28 29 28 1 0 12 -15 23 -34z"
      />
      <path
        d="M21945 27923 c-43 -10 -40 -24 4 -21 25 2 46 9 49 16 4 12 -15 14
-53 5z"
      />
      <path
        d="M21848 27484 c13 -9 26 -13 30 -9 8 8 -17 25 -38 25 -9 0 -6 -6 8
-16z"
      />
      <path
        d="M22322 27135 l-33 -45 77 0 77 0 -39 44 c-22 24 -41 44 -44 45 -3 0
-20 -20 -38 -44z"
      />
      <path
        d="M22489 27122 c30 -37 36 -40 39 -23 5 31 -15 58 -47 63 l-29 4 37
-44z"
      />
      <path
        d="M18066 29127 c-11 -8 -15 -19 -10 -30 7 -21 23 -18 38 6 15 24 -4 41
-28 24z"
      />
      <path d="M18135 28429 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
      <path
        d="M16050 26074 c-13 -33 -13 -34 5 -34 8 0 19 9 25 19 13 26 13 28 -5
35 -11 4 -19 -3 -25 -20z"
      />
      <path
        d="M15670 25876 c-8 -10 -6 -15 6 -20 17 -7 34 0 34 14 0 15 -28 19 -40
6z"
      />
      <path
        d="M15520 25806 c0 -10 9 -16 21 -16 24 0 21 23 -4 28 -10 2 -17 -3 -17
-12z"
      />
      <path
        d="M23747 24281 c-18 -31 -22 -87 -8 -110 13 -21 29 15 37 83 7 62 -2
72 -29 27z"
      />
      <path
        d="M18737 22053 c-9 -9 43 -63 60 -63 20 0 16 14 -15 43 -30 29 -35 31
-45 20z"
      />
      <path
        d="M19590 20762 c0 -5 11 -19 25 -32 26 -24 33 -14 15 21 -11 19 -40 27
-40 11z"
      />
      <path
        d="M27595 31560 c-44 -18 -88 -91 -64 -105 11 -7 119 91 119 107 0 10
-29 9 -55 -2z"
      />
      <path
        d="M27745 31418 c-11 -6 -21 -12 -23 -13 -1 -1 0 -9 4 -18 5 -14 9 -14
35 5 44 33 32 53 -16 26z"
      />
      <path
        d="M27430 31130 c0 -5 -25 -25 -55 -45 -30 -19 -55 -38 -55 -41 0 -11
101 4 116 17 16 13 19 79 4 79 -5 0 -10 -4 -10 -10z"
      />
      <path
        d="M27406 30691 c-6 -10 44 -34 53 -26 3 4 -3 13 -13 21 -23 16 -33 18
-40 5z"
      />
      <path
        d="M28832 27419 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17
-8 3 -12 -1 -9 -9z"
      />
      <path d="M13450 30823 c0 -32 28 -63 57 -63 20 0 15 12 -22 55 l-35 40 0 -32z" />
      <path
        d="M13317 30703 c-4 -35 6 -64 17 -52 3 3 8 24 12 47 5 34 4 42 -9 42
-10 0 -16 -13 -20 -37z"
      />
      <path
        d="M13330 28811 c0 -17 4 -17 47 -7 41 9 44 11 23 17 -47 13 -70 10 -70
-10z"
      />
      <path
        d="M32721 30929 c-17 -7 -21 -13 -14 -20 12 -12 53 5 53 21 0 12 -7 12
-39 -1z"
      />
      <path
        d="M24596 29731 c-19 -68 -15 -86 27 -107 l30 -16 -6 28 c-3 16 -9 57
-13 92 -3 34 -10 62 -14 62 -4 0 -15 -26 -24 -59z"
      />
      <path
        d="M24880 29335 c0 -30 20 -55 61 -75 l40 -21 -21 40 c-22 45 -80 85
-80 56z"
      />
      <path
        d="M29320 24510 c0 -40 4 -70 10 -70 6 0 10 30 10 70 0 40 -4 70 -10 70
-6 0 -10 -30 -10 -70z"
      />
      <path
        d="M29107 24453 c-6 -31 -1 -41 12 -27 14 14 17 47 5 51 -6 2 -14 -9
-17 -24z"
      />
      <path
        d="M29390 24436 c0 -20 5 -36 10 -36 6 0 10 13 10 29 0 17 -4 33 -10 36
-6 4 -10 -8 -10 -29z"
      />
      <path
        d="M29082 24223 c-14 -41 -6 -68 25 -88 l25 -17 -7 33 c-4 18 -13 49
-20 68 l-12 35 -11 -31z"
      />
      <path
        d="M27470 23391 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"
      />
      <path d="M25648 21663 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
      <path
        d="M25446 21644 c-134 -21 -327 -77 -313 -91 4 -4 466 91 492 101 24 10
-98 3 -179 -10z"
      />
      <path
        d="M24415 19840 c-749 -34 -1022 -79 -1275 -211 -52 -28 -135 -61 -185
-75 -191 -54 -295 -154 -360 -349 -23 -68 -27 -92 -23 -160 5 -89 19 -124 75
-186 46 -50 113 -85 200 -103 38 -8 95 -27 128 -43 54 -25 70 -28 165 -28 89
1 132 7 275 43 255 62 320 68 639 54 371 -17 605 -15 735 4 131 19 712 132
719 139 3 3 -3 26 -14 52 l-19 47 -165 48 c-91 26 -205 54 -253 63 -131 23
-232 79 -122 68 54 -6 55 -5 55 21 0 15 6 26 13 26 21 0 317 -86 375 -109 29
-12 94 -42 144 -69 l90 -47 132 -3 c72 -1 137 1 145 6 10 7 11 17 4 38 -10 30
-27 42 -98 70 l-40 15 24 20 c40 32 67 34 139 5 95 -37 162 -47 224 -36 29 6
56 14 61 19 18 18 -85 173 -174 262 -81 83 -93 91 -255 171 -247 123 -458 194
-694 233 -118 20 -412 26 -665 15z"
      />
      <path
        d="M33690 18265 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"
      />
      <path
        d="M24380 18213 c-14 -2 -90 -20 -169 -39 l-143 -36 -169 -120 c-149
-107 -169 -124 -169 -149 0 -31 45 -75 88 -85 43 -11 95 -40 121 -68 31 -33
25 -33 -119 -1 -62 14 -115 23 -119 20 -17 -18 66 -65 195 -110 284 -99 318
-113 347 -145 15 -17 27 -39 27 -49 0 -18 -7 -17 -106 16 -58 19 -128 50 -156
69 -32 22 -62 34 -83 34 -21 0 -74 21 -140 55 -121 62 -140 66 -129 33 25 -82
101 -180 177 -230 90 -58 386 -201 505 -243 380 -134 843 -242 1636 -381 326
-57 329 -57 375 -41 25 10 54 17 64 17 11 0 94 -25 186 -55 92 -30 173 -55
179 -55 7 0 12 6 12 14 0 21 48 74 84 93 24 13 61 17 166 18 108 1 144 5 179
20 l45 19 -49 16 c-44 15 -47 17 -25 23 14 3 66 8 115 12 50 3 167 17 260 30
94 13 226 29 295 35 69 6 169 15 222 20 61 6 106 6 122 0 33 -12 57 -50 63
-99 l5 -40 44 22 43 21 3 70 3 70 180 26 c99 15 203 34 231 44 56 18 87 16
283 -24 94 -19 126 -36 142 -77 8 -21 64 -42 77 -29 3 4 -2 28 -11 54 -23 64
-22 76 13 106 42 35 89 42 159 22 76 -21 134 -21 153 2 15 17 43 91 36 96 -1
2 -73 27 -159 55 l-156 52 -6 84 c-8 114 -37 169 -107 205 -53 27 -680 235
-795 264 -207 52 -281 60 -575 60 -300 1 -824 -17 -1183 -39 -378 -25 -615
-19 -852 20 -49 9 -352 80 -671 160 -593 148 -637 156 -744 138z m2955 -609
c8 -4 23 -20 31 -36 l15 -29 35 30 c44 39 87 40 157 4 46 -24 62 -27 134 -26
93 2 141 -11 209 -57 70 -47 97 -58 155 -66 70 -9 115 -27 129 -53 10 -20 8
-21 -55 -21 -63 0 -65 -1 -55 -21 16 -29 70 -49 134 -49 55 0 114 -15 103 -27
-16 -16 -155 -32 -275 -33 -116 0 -138 3 -160 19 -39 27 -97 41 -173 41 -68 0
-172 23 -349 78 l-95 30 3 56 3 56 -30 0 c-22 0 -35 -7 -47 -25 -26 -40 -48
-48 -74 -27 -21 17 -22 20 -7 48 23 44 58 72 118 93 61 22 71 24 94 15z
m-2945 -175 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
10 -16z m4004 -64 c18 -14 18 -15 -5 -23 -43 -16 -79 -23 -79 -14 0 4 7 18 16
30 17 25 41 28 68 7z m-3366 -13 c3 -9 -2 -13 -14 -10 -9 1 -19 9 -22 16 -3 9
2 13 14 10 9 -1 19 -9 22 -16z m261 -16 c8 -10 6 -19 -10 -40 -11 -14 -27 -26
-34 -26 -20 0 -45 28 -45 50 0 17 8 21 66 29 6 0 17 -5 23 -13z m681 -81 c0
-10 -10 -15 -30 -15 -20 0 -30 5 -30 15 0 10 10 15 30 15 20 0 30 -5 30 -15z
m313 -81 c47 -16 59 -30 34 -40 -16 -6 -107 33 -107 46 0 14 20 12 73 -6z
m327 -121 c0 -17 -11 -16 -59 3 -25 10 -38 20 -35 29 5 11 14 10 50 -3 24 -9
44 -22 44 -29z m-189 -71 c-5 -10 -17 -24 -25 -31 -14 -12 -14 -16 0 -43 20
-39 6 -50 -45 -36 -39 11 -131 59 -131 69 0 5 172 54 203 58 4 1 3 -7 -2 -17z
m427 -24 c50 -18 82 -35 82 -44 0 -17 -5 -17 -69 -3 -52 11 -141 61 -141 79 0
13 8 11 128 -32z m-127 -87 c37 -30 15 -45 -44 -30 -48 12 -52 24 -13 39 30
12 31 12 57 -9z m-88 -73 c32 -12 35 -24 7 -33 -23 -7 -45 7 -62 38 -9 17 -8
19 8 12 11 -4 31 -12 47 -17z"
      />
      <path
        d="M22816 17455 c-4 -11 11 -23 54 -45 92 -46 90 -42 -11 33 -30 23 -38
25 -43 12z"
      />
      <path
        d="M31425 17384 c-33 -7 -67 -13 -74 -13 -9 -1 -11 -4 -5 -10 12 -11
105 -33 304 -71 169 -32 348 -85 410 -121 l35 -20 -45 6 c-25 3 -122 24 -216
45 -273 63 -465 88 -533 70 -51 -14 -47 -22 20 -35 28 -5 50 -14 50 -20 0 -5
-46 -15 -101 -20 l-100 -11 -55 -54 c-30 -30 -57 -62 -60 -71 -10 -32 22 -88
60 -106 26 -12 34 -21 29 -32 -4 -9 -9 -34 -12 -56 -3 -36 1 -45 30 -74 65
-64 173 -127 268 -157 62 -20 95 -35 103 -49 16 -30 37 -43 87 -50 25 -4 61
-16 80 -29 19 -12 54 -30 78 -40 48 -22 54 -36 15 -36 -16 0 -34 -4 -42 -9
-32 -20 93 -132 184 -165 22 -7 64 -23 93 -35 l53 -21 15 36 c8 20 13 38 11
41 -3 2 -33 -3 -67 -12 -34 -9 -64 -14 -67 -11 -8 8 57 44 111 62 57 19 92 9
102 -31 7 -27 2 -79 -10 -106 -5 -11 -1 -19 10 -23 23 -9 30 0 13 20 -12 15
-7 16 61 10 41 -4 98 -12 126 -18 42 -9 56 -8 80 5 21 12 24 16 11 17 -55 1
-95 55 -111 148 -5 31 -4 32 22 26 15 -4 37 -11 50 -15 41 -16 52 -11 52 21 0
17 4 30 10 30 5 0 41 -20 79 -45 38 -24 115 -67 172 -95 119 -58 133 -53 74
28 l-36 50 23 16 c31 22 47 20 99 -12 54 -35 102 -52 143 -52 40 1 113 34 148
67 l28 26 172 -66 c95 -36 180 -69 189 -72 36 -14 6 44 -47 89 -49 43 -54 51
-54 89 0 39 -6 49 -67 112 -52 54 -83 98 -131 190 -124 239 -185 289 -482 394
-107 37 -233 84 -280 103 -222 92 -425 136 -730 157 -258 18 -313 19 -375 5z
m888 -303 c44 -15 67 -28 67 -38 0 -8 -6 -13 -12 -10 -114 45 -148 61 -148 69
0 6 6 8 13 6 6 -3 42 -15 80 -27z m-884 -195 c12 -13 18 -26 13 -30 -5 -3 -22
8 -37 24 -20 21 -24 30 -14 30 9 0 26 -11 38 -24z m601 -266 l25 -7 -28 -12
c-32 -12 -141 -14 -172 -2 -51 20 113 40 175 21z m355 -37 c19 -9 35 -23 35
-31 0 -12 -11 -13 -57 -8 -32 4 -67 10 -77 13 -18 5 -18 7 5 24 30 23 49 24
94 2z m730 -183 c19 -7 18 -9 -7 -20 -33 -16 -78 0 -78 26 0 12 6 14 33 8 17
-3 41 -10 52 -14z"
      />
      <path
        d="M22987 17334 c47 -62 167 -144 185 -126 7 7 -26 34 -101 85 -97 66
-107 71 -84 41z"
      />
      <path
        d="M28711 16924 c-7 -19 -10 -38 -7 -42 10 -9 33 20 40 51 10 41 -17 34
-33 -9z"
      />
      <path
        d="M27866 16855 c21 -16 64 -20 64 -6 0 10 -34 21 -64 21 -20 0 -20 0 0
-15z"
      />
      <path
        d="M28983 16863 c-7 -2 -13 -9 -13 -14 0 -14 26 -10 40 6 10 12 10 15
-1 14 -8 0 -20 -3 -26 -6z"
      />
      <path
        d="M28463 16805 c-11 -8 -23 -24 -26 -35 -4 -18 0 -16 34 11 21 17 39
33 39 35 0 9 -27 3 -47 -11z"
      />
      <path
        d="M28401 16731 c-8 -5 -10 -14 -6 -21 6 -9 10 -8 17 4 12 21 7 28 -11
17z"
      />
      <path
        d="M28680 16720 c-8 -5 -12 -12 -9 -15 8 -8 39 5 39 16 0 11 -11 11 -30
-1z"
      />
      <path
        d="M24110 16621 c0 -5 10 -11 23 -15 36 -9 42 -7 21 9 -21 16 -44 19
-44 6z"
      />
      <path
        d="M33765 16230 c3 -5 17 -10 31 -10 14 0 22 4 19 10 -3 6 -17 10 -31
10 -14 0 -22 -4 -19 -10z"
      />
      <path
        d="M33310 16211 c0 -9 24 -21 41 -21 5 0 9 7 9 15 0 9 -9 15 -25 15 -14
0 -25 -4 -25 -9z"
      />
      <path d="M32603 16143 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
      <path
        d="M15274 29279 c-12 -20 13 -32 36 -16 18 13 19 15 5 21 -23 9 -33 8
-41 -5z"
      />
      <path
        d="M15085 29130 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"
      />
      <path
        d="M34680 28029 c0 -12 5 -19 12 -17 18 6 20 25 3 31 -10 5 -15 0 -15
-14z"
      />
      <path d="M11408 24575 c-8 -8 3 -55 13 -55 5 0 9 8 9 18 0 20 -14 44 -22 37z" />
      <path
        d="M11287 24466 c-4 -10 -5 -21 -2 -24 9 -9 17 6 13 25 -3 17 -4 17 -11
-1z"
      />
      <path
        d="M11301 24310 c-12 -7 -12 -12 3 -34 20 -31 22 -32 35 -7 13 23 -16
55 -38 41z"
      />
      <path
        d="M11250 24180 c-8 -5 -12 -12 -8 -15 8 -8 58 5 58 16 0 12 -31 11 -50
-1z"
      />
      <path
        d="M11245 24031 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16
-9z"
      />
      <path
        d="M11194 23336 c-3 -7 -4 -38 -2 -67 l3 -54 38 61 c20 34 37 64 37 68
0 13 -71 6 -76 -8z"
      />
      <path
        d="M11505 22100 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"
      />
      <path
        d="M10750 21788 c-57 -44 -85 -78 -65 -78 4 0 34 14 66 31 65 35 96 71
76 87 -9 8 -30 -3 -77 -40z"
      />
      <path
        d="M17481 19728 c-30 -19 -38 -20 -85 -11 -40 9 -101 7 -281 -8 -227
-20 -503 -54 -513 -64 -10 -10 93 -5 270 11 165 16 179 16 202 0 39 -25 42
-57 12 -122 -15 -31 -25 -58 -24 -60 2 -1 27 -12 56 -23 97 -38 178 -21 218
48 10 17 21 31 25 31 4 0 17 -37 29 -82 27 -102 40 -127 87 -173 l36 -35 -121
0 c-67 0 -122 2 -122 3 0 2 9 30 20 62 11 32 16 63 12 67 -5 5 -38 -5 -75 -21
-55 -25 -69 -37 -87 -71 -11 -22 -31 -45 -43 -50 -12 -4 -92 -13 -177 -19
-376 -26 -332 -27 -368 4 -39 33 -40 43 -4 51 60 15 152 63 162 86 7 15 8 43
2 81 -11 68 -12 66 73 107 l60 28 -91 1 c-50 1 -117 -4 -150 -9 -67 -12 -144
-38 -144 -49 0 -4 11 -18 25 -31 29 -28 32 -62 9 -120 -17 -42 -17 -42 14
-101 5 -11 -119 -49 -157 -49 -20 0 -49 9 -65 19 l-29 20 71 74 c40 40 72 76
72 79 0 3 -41 -3 -92 -13 -50 -11 -94 -17 -96 -14 -3 3 10 22 29 43 82 88 86
85 -57 47 -70 -19 -129 -33 -131 -31 -9 9 55 71 101 97 28 16 62 38 76 49 31
24 21 25 -93 5 -182 -32 -393 -134 -485 -235 -42 -47 -64 -63 -97 -71 -24 -6
-51 -19 -60 -29 -23 -25 -44 -88 -45 -131 0 -31 4 -38 29 -49 37 -15 58 -37
66 -71 6 -24 3 -28 -31 -39 l-38 -13 34 -28 c18 -16 54 -39 79 -51 45 -23 53
-23 401 -25 195 -1 342 2 325 7 -16 4 -55 8 -85 9 -56 1 -140 34 -147 57 -6
19 104 42 337 70 193 24 269 28 537 30 283 2 315 4 343 21 31 18 31 18 49 -6
17 -22 21 -23 104 -16 l87 7 0 53 c0 47 4 56 31 79 36 31 106 35 168 10 37
-14 38 -17 31 -47 -4 -18 -15 -48 -25 -69 -27 -56 -17 -68 57 -68 l64 0 41 84
42 85 -35 30 c-19 17 -36 31 -37 32 -1 0 -59 4 -128 8 -98 5 -132 11 -154 25
l-29 19 27 23 c14 12 50 29 79 38 29 8 61 21 72 29 16 12 7 17 -86 45 -84 26
-107 37 -118 58 -17 32 -68 63 -128 78 l-47 13 30 15 c44 23 136 38 234 38
l85 0 58 -57 c32 -31 59 -55 61 -52 8 11 77 173 74 175 -8 8 -332 84 -359 84
-18 0 -48 -10 -67 -22z m-176 -132 c17 -13 17 -14 -10 -27 -23 -11 -31 -24
-38 -56 -7 -39 -11 -43 -37 -43 -19 0 -35 9 -49 26 -33 42 -27 67 22 91 49 25
86 28 112 9z m-1601 -652 c25 -10 19 -22 -9 -16 -14 2 -25 8 -25 13 0 10 12
11 34 3z m266 -41 c-31 -13 -40 -13 -80 0 l-45 15 80 0 80 0 -35 -15z"
      />
      <path
        d="M16994 19607 c-69 -63 -45 -98 39 -55 12 6 17 21 17 53 0 25 -2 45
-4 45 -2 0 -26 -19 -52 -43z"
      />
      <path
        d="M18097 19563 c-3 -5 -8 -22 -11 -39 -8 -38 1 -43 95 -51 51 -4 79 -1
118 13 28 10 50 23 48 28 -4 12 -170 55 -214 56 -17 0 -34 -3 -36 -7z"
      />
      <path
        d="M18431 19444 c-41 -14 -80 -23 -88 -20 -7 3 -13 -1 -13 -10 0 -13 10
-15 58 -10 31 3 87 8 125 12 72 7 90 26 41 44 -34 13 -37 13 -123 -16z"
      />
      <path
        d="M17840 19364 c-22 -47 -40 -88 -40 -90 0 -2 24 5 53 16 57 22 87 50
87 85 0 24 -35 75 -51 75 -6 0 -28 -39 -49 -86z"
      />
      <path
        d="M18050 19365 c-18 -22 -9 -26 45 -18 43 5 46 7 30 20 -25 17 -60 17
-75 -2z"
      />
      <path
        d="M18018 19126 c18 -9 63 -25 99 -36 61 -19 67 -19 99 -4 66 32 45 40
-116 49 -108 5 -113 5 -82 -9z"
      />
      <path
        d="M16785 18885 c-99 -13 -205 -27 -235 -30 -47 -6 -51 -9 -31 -16 89
-34 359 -30 451 7 35 14 60 38 60 58 0 9 -53 5 -245 -19z"
      />
      <path
        d="M10613 18473 c-4 -11 3 -24 21 -40 16 -13 30 -22 32 -20 2 2 -1 20
-7 40 -10 39 -35 50 -46 20z"
      />
      <path
        d="M10701 18360 c25 -43 44 -48 44 -10 0 17 -9 27 -33 37 l-34 14 23
-41z"
      />
      <path
        d="M17225 18056 c-17 -7 -57 -35 -89 -61 -71 -57 -125 -95 -138 -95 -5
0 5 18 22 40 17 22 29 40 25 40 -4 0 -51 -25 -105 -55 -114 -65 -152 -74 -211
-51 -23 10 -50 14 -63 10 -11 -4 -90 -47 -175 -97 -205 -120 -222 -122 -336
-36 -69 52 -168 96 -256 115 -75 16 -298 15 -949 -2 -297 -8 -454 13 -685 93
l-100 35 -155 -5 c-363 -12 -892 -121 -1298 -267 -311 -111 -515 -200 -589
-256 -191 -145 -250 -410 -154 -691 63 -183 180 -336 303 -393 110 -52 177
-61 458 -67 366 -7 675 25 1018 107 141 34 156 41 197 87 32 36 129 79 319
143 53 17 122 49 153 69 l55 38 43 -16 c39 -14 45 -14 68 2 15 9 31 26 37 37
13 24 -7 36 -82 50 -55 11 -102 35 -94 49 13 21 107 36 212 34 93 -1 121 -6
172 -27 l62 -25 14 28 c34 65 80 75 237 53 65 -9 121 -13 125 -9 4 3 14 39 24
79 12 53 20 72 31 69 8 -2 43 -10 78 -18 35 -7 72 -18 82 -23 45 -25 89 3 89
57 0 17 7 36 15 43 22 18 438 71 498 64 79 -10 55 -42 -33 -45 -19 0 -46 -5
-60 -9 -16 -5 20 -8 97 -9 l122 -1 6 34 c6 31 12 36 68 56 97 34 103 37 138
67 19 16 53 37 77 48 53 23 103 77 137 149 30 63 69 96 113 96 64 0 68 -68 6
-98 l-36 -17 36 -30 c35 -29 35 -31 25 -72 -12 -46 -7 -73 13 -72 17 1 128
137 128 157 0 8 -8 24 -17 35 -17 18 -16 22 18 85 20 37 39 78 42 92 10 41
122 157 165 171 55 17 79 44 128 142 43 84 43 87 22 87 -13 -1 -37 -7 -53 -14z
m-3459 -181 c-22 -17 -36 -19 -36 -7 0 14 12 22 34 22 21 -1 21 -1 2 -15z
m-226 -134 c0 -10 -24 -21 -46 -21 -20 0 -17 18 4 23 29 8 42 7 42 -2z m1915
-2 c43 -11 130 -58 122 -65 -4 -5 -268 64 -276 72 -9 9 116 3 154 -7z m1495
-93 c0 -2 -18 -15 -40 -30 -36 -23 -40 -24 -40 -8 0 26 20 42 52 42 15 0 28
-2 28 -4z m-1970 -61 c0 -11 -12 -15 -50 -15 -38 0 -50 4 -50 15 0 11 12 15
50 15 38 0 50 -4 50 -15z m1885 -95 c-20 -23 -45 -26 -63 -8 -21 21 -13 28 35
28 45 0 46 0 28 -20z m-1621 -9 c6 -9 -35 -24 -43 -15 -6 5 18 24 31 24 4 0 9
-4 12 -9z m-413 -20 c-6 -11 -47 -31 -103 -50 -51 -18 -119 -50 -150 -71 -40
-26 -77 -41 -120 -49 -34 -6 -64 -9 -66 -6 -2 2 4 21 15 42 36 70 121 106 318
137 124 19 117 19 106 -3z m244 -15 c44 -27 41 -30 -38 -41 -48 -6 -49 -6 -42
17 8 25 24 48 34 48 3 0 24 -11 46 -24z m-965 -71 c0 -2 -37 -34 -82 -70 -72
-58 -83 -64 -89 -48 -13 32 -10 40 32 68 55 36 139 67 139 50z m229 -91 c-6
-8 -29 -14 -51 -14 l-39 0 38 44 38 45 13 -31 c10 -23 10 -33 1 -44z m948 61
c67 -8 132 -19 145 -24 22 -9 21 -10 -22 -29 -110 -49 -409 -92 -642 -92 -161
0 -179 7 -102 42 34 15 60 19 96 16 46 -5 50 -3 76 30 24 30 39 37 108 52 100
23 187 24 341 5z m-1392 -45 c19 -7 18 -9 -8 -19 -41 -16 -87 -11 -87 9 0 17
62 24 95 10z m342 -35 c-5 -15 -56 -18 -73 -4 -13 11 -11 15 18 30 31 17 34
17 46 1 7 -9 11 -21 9 -27z m993 -174 c0 -10 -3 -33 -6 -50 l-6 -31 -72 0
c-87 0 -176 10 -176 20 0 6 225 77 253 79 4 1 7 -7 7 -18z m-1840 -296 c0 -17
-35 -33 -87 -42 -51 -9 -39 12 20 35 66 25 67 25 67 7z m-275 -95 c4 -6 -7
-10 -27 -9 -27 0 -30 2 -13 9 27 12 33 12 40 0z"
      />
      <path
        d="M17275 17879 c-9 -27 15 -46 28 -23 10 17 2 44 -12 44 -5 0 -13 -10
-16 -21z"
      />
      <path
        d="M17337 17763 l-77 -65 37 -52 c20 -28 44 -60 54 -70 18 -19 19 -19
45 38 28 61 41 157 27 192 -8 21 -12 18 -86 -43z"
      />
      <path
        d="M17082 17744 l-53 -34 43 -55 c24 -31 44 -52 45 -48 9 33 29 173 24
172 -3 0 -30 -16 -59 -35z"
      />
      <path
        d="M17223 17515 c-18 -12 -59 -103 -50 -111 3 -3 97 113 97 121 0 10
-26 5 -47 -10z"
      />
      <path
        d="M11801 17466 c-16 -19 20 -51 37 -34 17 17 15 26 -7 37 -14 8 -22 7
-30 -3z"
      />
      <path
        d="M10453 17354 c-3 -8 1 -23 10 -32 14 -13 21 -14 43 -4 l26 13 -25 19
c-30 24 -46 25 -54 4z"
      />
      <path d="M10670 17360 c-17 -11 -8 -23 14 -18 9 2 12 8 8 15 -5 8 -13 9 -22 3z" />
      <path
        d="M10336 17325 c-8 -32 11 -55 46 -55 25 0 22 15 -10 52 l-29 31 -7
-28z"
      />
      <path
        d="M17030 17330 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2
-7 -10z"
      />
      <path d="M16670 17301 c0 -20 5 -31 14 -31 9 0 12 8 8 25 -7 37 -22 41 -22 6z" />
      <path
        d="M10120 17310 c-93 -16 -155 -53 -294 -174 -92 -81 -107 -88 -245
-111 -110 -18 -223 -71 -339 -161 -93 -70 -164 -104 -222 -104 -43 0 -46 -2
-61 -39 -9 -22 -28 -47 -42 -56 -23 -15 -29 -15 -72 -1 -57 20 -79 20 -164 1
-66 -15 -67 -16 -63 -45 5 -36 -8 -43 -133 -75 -143 -36 -233 -92 -301 -190
l-21 -30 -22 43 -22 43 -47 -27 c-163 -96 -172 -104 -109 -104 l30 0 -23 -25
c-14 -14 -48 -44 -76 -66 -67 -51 -81 -83 -59 -135 40 -95 94 -135 188 -137
l60 -2 74 -97 c100 -133 142 -151 210 -94 28 24 149 88 190 100 17 5 31 -2 62
-30 23 -20 41 -40 41 -44 0 -4 -42 -24 -92 -45 -51 -21 -109 -45 -128 -53
l-35 -14 30 -24 c51 -41 105 -34 227 30 140 74 195 90 428 131 207 36 287 58
374 102 181 92 338 255 448 468 60 116 37 114 -73 -6 l-83 -91 -18 22 c-21 26
-23 84 -5 117 21 37 187 184 231 204 22 10 59 19 81 19 28 0 58 11 100 36 76
46 152 76 175 69 9 -3 36 -26 59 -50 49 -53 81 -64 116 -41 44 29 34 68 -35
138 -49 49 -65 75 -87 136 -35 95 -84 149 -144 158 -31 5 -43 13 -50 31 -12
33 -13 31 26 47 81 34 108 99 62 151 -25 30 -75 39 -147 25z m-106 -201 c27
-31 34 -81 16 -109 -7 -12 -21 -3 -73 45 -36 33 -65 63 -66 66 -2 10 42 28 71
28 18 1 35 -9 52 -30z m-175 -292 c10 -13 -3 -24 -81 -69 -110 -63 -108 -62
-108 -50 0 5 32 41 72 80 74 72 86 76 117 39z m-1339 -376 c0 -23 -79 -48 -92
-28 -14 22 -2 32 43 38 24 3 45 7 47 7 1 1 2 -6 2 -17z m1130 -101 c0 -21 -39
-87 -84 -142 -123 -151 -344 -258 -533 -258 l-83 0 0 43 c0 23 3 52 6 64 5 18
10 20 31 12 55 -21 71 -13 103 51 37 73 56 84 126 75 l54 -6 0 50 c0 49 1 51
29 51 16 0 54 12 86 26 42 20 82 28 154 34 53 4 99 8 104 9 4 0 7 -4 7 -9z
m-1486 -31 c-9 -14 -68 -39 -94 -39 -38 0 -20 16 38 32 31 9 58 17 60 17 2 1
0 -4 -4 -10z m1076 -49 c-20 -13 -43 -13 -35 0 3 6 16 10 28 10 18 0 19 -2 7
-10z m-349 -284 l51 -34 -112 -36 c-61 -20 -132 -39 -156 -43 l-45 -6 63 61
c55 53 113 90 141 92 4 0 31 -15 58 -34z"
      />
      <path
        d="M10370 17135 c-7 -9 -11 -18 -8 -20 9 -10 38 7 38 21 0 18 -14 18
-30 -1z"
      />
      <path
        d="M16030 16973 c-13 -3 -45 -13 -71 -23 -63 -25 -181 -49 -299 -60 -98
-9 -154 -25 -144 -41 9 -15 304 -10 400 6 116 20 352 95 332 106 -18 10 -184
19 -218 12z"
      />
      <path
        d="M15435 16960 c-7 -12 2 -30 16 -30 12 0 12 27 0 35 -5 3 -12 1 -16
-5z"
      />
      <path
        d="M14717 16834 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25
-28 15z"
      />
      <path
        d="M15025 16773 c-198 -44 -218 -51 -235 -82 -7 -15 3 -14 83 5 51 12
142 33 202 48 125 29 197 55 189 67 -8 13 -23 11 -239 -38z"
      />
      <path
        d="M14564 16665 c-27 -25 -33 -34 -19 -31 11 3 56 10 100 16 118 17 127
20 85 36 -19 7 -56 13 -81 13 -39 1 -51 -4 -85 -34z"
      />
      <path
        d="M10027 16423 c-10 -10 -8 -55 2 -49 5 3 11 17 13 31 5 24 -2 32 -15
18z"
      />
      <path
        d="M15680 21970 c20 -13 93 -13 85 0 -3 6 -27 10 -53 10 -34 0 -43 -3
-32 -10z"
      />
      <path
        d="M15890 21911 c0 -12 122 -33 133 -22 5 5 -19 13 -55 20 -78 13 -78
13 -78 2z"
      />
      <path
        d="M22915 36530 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10
-6 0 -7 -4 -4 -10z"
      />
      <path
        d="M35202 27773 c-8 -3 10 -22 45 -48 32 -24 59 -42 61 -40 2 1 -4 19
-12 39 -18 43 -58 64 -94 49z"
      />
      <path
        d="M8787 25154 c-11 -11 -9 -19 14 -48 22 -28 46 -27 30 2 -19 34 -37
53 -44 46z"
      />
      <path
        d="M9030 24926 c0 -27 40 -64 82 -74 23 -6 44 -9 47 -7 8 9 -52 77 -76
86 -34 13 -53 11 -53 -5z"
      />
      <path
        d="M9100 24690 c0 -37 39 -74 117 -111 88 -42 103 -46 103 -26 0 10 -44
41 -182 125 -22 13 -38 18 -38 12z"
      />
      <path
        d="M9200 24447 c0 -8 7 -18 16 -21 11 -4 14 -1 12 11 -4 21 -28 30 -28
10z"
      />
      <path
        d="M34943 15270 c-83 -116 -103 -161 -102 -235 0 -33 4 -69 8 -80 7 -17
12 -12 39 35 43 74 91 200 108 284 8 38 13 72 12 76 -2 4 -31 -32 -65 -80z"
      />
      <path
        d="M8300 11717 c0 -21 103 -181 166 -258 93 -114 205 -214 259 -232 57
-20 75 -21 75 -6 0 6 -57 70 -128 143 -70 72 -154 164 -187 205 -62 76 -185
175 -185 148z"
      />
      <path
        d="M9066 10905 c-6 -17 188 -170 204 -160 22 14 5 33 -82 95 -118 84
-115 82 -122 65z"
      />
      <path
        d="M9370 10681 c0 -12 107 -97 145 -115 17 -8 48 -17 70 -21 36 -6 30 0
-75 69 -110 73 -140 87 -140 67z"
      />
      <path
        d="M9639 10521 c32 -58 128 -104 211 -99 l55 3 -115 49 c-169 71 -162
69 -151 47z"
      />
      <path
        d="M29443 10048 c-18 -6 -33 -17 -33 -24 0 -17 5 -17 47 -2 18 7 33 18
33 25 0 15 -3 15 -47 1z"
      />
      <path
        d="M27845 9298 c-48 -25 -50 -26 -43 -34 8 -8 88 34 88 46 0 12 3 13
-45 -12z"
      />
      <path
        d="M19731 9033 c-40 -77 -33 -153 22 -220 59 -73 289 -346 409 -485 65
-75 118 -139 118 -143 0 -3 -20 -41 -45 -84 l-44 -79 24 -73 c14 -40 24 -74
22 -75 -8 -8 -112 72 -170 130 -69 69 -153 187 -171 241 -13 37 -9 118 5 109
13 -8 11 -48 -3 -62 -9 -9 -8 -12 8 -12 39 0 47 14 30 48 -21 40 -31 46 -89
46 -46 1 -51 4 -105 61 -90 95 -224 252 -260 303 -17 26 -48 83 -67 127 -45
105 -67 129 -116 122 -78 -10 -166 -98 -195 -193 -39 -130 16 -311 149 -487
53 -69 358 -434 696 -832 255 -301 426 -439 819 -665 177 -101 230 -125 325
-144 140 -29 258 -13 392 53 145 72 414 306 613 534 195 224 434 525 462 582
27 55 66 192 57 201 -2 2 -59 -66 -127 -152 l-123 -156 -21 26 -21 27 -55 -45
c-29 -25 -56 -44 -58 -41 -6 6 42 85 171 280 147 222 160 247 150 282 -10 38
-10 38 58 207 33 81 59 149 57 151 -2 1 -21 12 -43 23 -57 28 -71 49 -78 112
-8 66 -78 210 -120 246 -33 27 -81 31 -182 13 -84 -14 -120 -39 -206 -139 -40
-47 -129 -146 -199 -220 -178 -191 -351 -385 -539 -605 -89 -104 -176 -200
-194 -212 -75 -54 -148 -13 -281 156 -159 203 -456 572 -560 696 -201 241
-278 306 -404 345 -79 25 -99 25 -111 3z m730 -1083 l24 -19 -44 -1 c-29 0
-65 -11 -107 -32 -35 -18 -66 -29 -70 -25 -12 11 37 58 81 78 52 24 87 24 116
-1z m228 -53 c66 -39 232 -197 213 -204 -19 -7 -152 59 -197 98 -43 38 -115
126 -115 141 0 14 37 1 99 -35z m-88 -85 c62 -32 290 -213 283 -225 -18 -31
-50 -57 -71 -57 -22 0 -205 50 -260 71 -38 14 -55 53 -57 131 -1 67 1 73 24
85 33 17 39 16 81 -5z m69 -277 l34 -35 -32 0 c-32 0 -52 21 -52 54 0 24 13
19 50 -19z"
      />
      <path
        d="M20606 7692 c-2 -4 9 -22 26 -41 22 -24 33 -30 41 -22 8 8 3 19 -18
41 -29 31 -40 36 -49 22z"
      />
      <path
        d="M20113 8200 c-6 -16 -8 -32 -3 -35 11 -7 42 43 35 55 -11 17 -20 11
-32 -20z"
      />
      <path
        d="M17751 7721 c-6 -11 -11 -24 -11 -30 0 -20 17 -11 28 14 14 29 -2 44
-17 16z"
      />
      <path
        d="M22289 6779 c-62 -41 -137 -105 -221 -190 -221 -222 -183 -205 163
72 103 83 149 126 153 145 4 14 5 28 3 31 -3 2 -47 -24 -98 -58z"
      />
      <path
        d="M4603 6562 c-9 -5 -6 -16 9 -40 25 -40 33 -35 24 13 -7 36 -12 40
-33 27z"
      />
      <path
        d="M22512 6460 l-23 -40 88 0 88 0 -63 40 c-34 22 -64 40 -65 40 -1 0
-12 -18 -25 -40z"
      />
      <path
        d="M4681 6479 c-14 -5 -20 -13 -15 -18 14 -12 164 -81 164 -75 0 16 -33
65 -56 83 -29 23 -55 25 -93 10z"
      />
      <path
        d="M19010 6410 l0 -50 -35 0 -34 0 39 -67 c21 -37 37 -70 34 -72 -2 -3
-24 17 -48 43 -36 38 -50 47 -72 44 -25 -3 -30 -10 -45 -61 -28 -95 -42 -196
-35 -261 7 -74 47 -224 82 -314 48 -122 231 -423 347 -572 90 -115 239 -260
362 -353 66 -50 188 -145 270 -211 272 -218 408 -316 500 -361 161 -79 325
-110 538 -103 182 7 201 15 449 202 495 371 479 358 518 438 19 40 43 70 80
99 58 46 85 96 75 140 -6 28 -6 29 34 29 37 0 41 2 41 25 0 40 26 92 79 158
48 62 81 117 81 137 0 5 -13 10 -29 10 -32 0 -35 6 -47 104 l-7 59 27 -5 c31
-6 56 13 56 43 0 20 -1 20 -31 -1 -16 -12 -32 -20 -35 -17 -7 7 81 101 110
116 15 8 37 37 51 67 13 28 30 54 38 57 7 3 31 -1 52 -9 70 -24 78 -21 68 29
-15 76 -8 187 16 262 12 39 51 125 86 193 35 67 61 122 57 122 -34 -1 -176
-40 -197 -54 -14 -9 -35 -37 -45 -61 -15 -35 -26 -45 -57 -55 -93 -30 -154
-85 -302 -270 -46 -58 -103 -125 -125 -149 -39 -41 -124 -91 -156 -91 -10 0
-21 -22 -33 -61 -13 -44 -30 -74 -64 -110 -40 -43 -58 -53 -120 -71 l-73 -22
0 -43 c0 -52 -26 -83 -69 -83 -22 0 -37 -9 -55 -33 -33 -43 -103 -81 -188
-102 -71 -18 -110 -41 -220 -127 -36 -29 -99 -71 -140 -92 l-73 -40 63 71 c38
44 58 74 52 78 -12 7 -12 8 -113 -79 -46 -40 -73 -56 -84 -52 -17 7 -15 10
160 192 74 77 76 99 5 59 -23 -14 -49 -25 -56 -25 -7 0 -91 76 -185 168 -95
93 -239 224 -322 293 -258 213 -375 320 -535 488 -247 259 -355 340 -472 357
l-47 6 26 -86 c16 -53 23 -90 17 -96 -5 -5 -60 37 -136 106 l-128 115 0 -51z
m622 -362 c127 -90 162 -130 155 -175 -8 -46 2 -56 313 -332 146 -130 298
-267 339 -306 79 -75 121 -145 121 -203 l0 -33 -37 28 c-21 15 -113 100 -206
187 -189 180 -202 190 -273 211 -56 17 -77 36 -119 110 -36 62 -103 134 -222
237 -109 94 -201 205 -265 320 -74 134 -73 139 19 77 43 -29 122 -83 175 -121z
m-487 -52 l38 -59 -34 7 c-71 14 -97 54 -68 108 13 24 15 22 64 -56z m270 34
c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z
m2973 -285 c-12 -23 -16 -24 -27 -12 -10 12 -8 18 10 31 29 20 34 14 17 -19z
m-2627 -108 c-16 -16 -51 3 -82 45 -47 66 -34 74 31 17 37 -32 57 -56 51 -62z
m409 -61 c-13 -13 -45 17 -35 34 5 8 12 6 26 -6 13 -12 16 -21 9 -28z m2030
-295 c0 -18 -34 -51 -52 -51 -15 0 -8 24 14 47 26 28 38 29 38 4z m-2330 -46
c16 -19 3 -40 -16 -24 -15 13 -19 39 -5 39 5 0 14 -7 21 -15z m170 -87 c0 -13
-10 -9 -36 11 -13 10 -21 21 -17 24 6 7 52 -24 53 -35z m1827 -184 c-100 -95
-123 -107 -171 -88 -30 11 -16 29 53 67 37 21 96 60 132 87 43 33 67 45 74 38
7 -6 -22 -41 -88 -104z m-56 -267 c-13 -13 -31 0 -24 19 4 11 8 12 20 3 9 -8
10 -16 4 -22z m-432 -220 c-18 -15 -101 -58 -105 -55 -2 2 4 15 13 27 19 28
74 53 87 40 5 -5 8 -10 5 -12z"
      />
      <path d="M22275 6299 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z" />
      <path
        d="M22054 6175 c-9 -37 -1 -38 18 -4 14 24 15 29 3 29 -8 0 -17 -11 -21
-25z"
      />
      <path
        d="M22037 6099 c-3 -19 -8 -43 -12 -54 -5 -14 0 -11 19 10 30 35 30 36
13 60 -13 17 -14 15 -20 -16z"
      />
      <path
        d="M21770 5949 c-44 -39 -80 -75 -80 -80 0 -5 12 -9 28 -9 l27 -1 -39
-34 c-46 -41 -53 -42 -99 -15 l-35 21 -216 -227 c-119 -125 -225 -239 -236
-254 -14 -20 16 -4 113 60 111 73 150 106 248 211 120 127 188 182 175 141
-15 -48 29 -21 183 109 90 77 166 141 169 144 3 2 -10 0 -28 -5 -34 -10 -67
-30 -150 -91 -47 -35 -46 -32 13 42 33 42 38 59 16 59 -5 0 -44 -32 -89 -71z"
      />
      <path
        d="M18113 5952 c-10 -6 -8 -24 7 -76 19 -69 26 -83 35 -75 5 6 -16 142
-24 151 -3 4 -12 4 -18 0z"
      />
      <path
        d="M18200 5611 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"
      />
      <path
        d="M22406 5581 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5
4 -12 2 -15 -3z"
      />
      <path
        d="M22474 5549 c-10 -21 -12 -35 -6 -41 7 -7 16 0 26 20 9 17 16 36 16
42 0 21 -20 9 -36 -21z"
      />
      <path
        d="M21033 5282 c-24 -15 -43 -32 -43 -39 0 -22 14 -15 55 27 45 47 44
47 -12 12z"
      />
      <path
        d="M21005 5130 c-10 -11 -15 -23 -12 -27 8 -7 47 25 47 39 0 14 -16 9
-35 -12z"
      />
      <path d="M19875 2511 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
      <path
        d="M19960 2402 c0 -14 39 -46 47 -39 3 4 -2 16 -12 27 -19 21 -35 26
-35 12z"
      />
      <path
        d="M20060 2282 c0 -4 16 -27 35 -51 l35 -44 0 30 c0 16 -6 36 -12 45
-13 16 -58 31 -58 20z"
      />
      <path
        d="M22395 2170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"
      />
      <path
        d="M20496 1871 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5
4 -12 2 -15 -3z"
      />
      <path
        d="M20430 1740 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"
      />
      <path
        d="M20953 1564 c-106 -113 -108 -117 -125 -187 -9 -40 -15 -73 -13 -75
1 -2 24 11 51 27 38 25 59 31 103 31 44 0 74 9 141 40 l85 40 -105 -5 c-102
-6 -104 -5 -80 12 56 39 100 94 100 123 0 34 -28 110 -40 110 -5 0 -57 -52
-117 -116z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M24605 37739 c-119 -12 -326 -47 -374 -64 -54 -19 -109 -55 -190
-125 -98 -84 -114 -110 -114 -180 2 -151 120 -245 508 -400 215 -86 321 -110
493 -110 48 0 146 -10 217 -21 72 -11 211 -24 310 -29 199 -10 254 -21 529
-100 452 -130 631 -151 823 -95 68 20 164 67 183 90 8 9 15 49 18 93 4 70 1
85 -33 172 -20 52 -45 121 -55 152 -16 48 -32 69 -96 130 -42 40 -87 94 -100
120 -45 88 -119 155 -214 192 -45 18 -434 96 -570 115 -142 20 -255 23 -365
12 -103 -11 -118 -10 -240 13 -71 14 -170 30 -220 36 -105 12 -381 11 -510 -1z"
      />
      <path
        d="M12353 37360 c-39 -4 -118 -18 -175 -30 -77 -17 -126 -22 -193 -19
-179 7 -372 1 -470 -15 -222 -37 -509 -98 -558 -120 -35 -15 -81 -25 -137 -29
-47 -3 -132 -17 -190 -32 -67 -16 -152 -29 -235 -34 -149 -9 -120 3 -400 -159
-420 -243 -631 -375 -725 -457 -71 -62 -131 -89 -181 -82 -34 4 -51 -3 -159
-69 -359 -219 -493 -327 -850 -678 -157 -155 -331 -323 -387 -373 -121 -111
-218 -228 -251 -304 -19 -45 -31 -59 -64 -75 -22 -10 -49 -34 -60 -51 l-20
-33 57 0 57 0 -8 -32 c-7 -33 -241 -383 -324 -486 -30 -37 -68 -68 -115 -94
-105 -59 -110 -66 -110 -151 0 -70 -1 -74 -51 -147 -83 -122 -229 -313 -263
-345 -29 -27 -39 -30 -99 -30 -39 0 -76 -6 -88 -14 -37 -24 -405 -586 -475
-726 -90 -180 -224 -601 -304 -955 -24 -107 -56 -172 -117 -236 l-53 -56 0
-127 c0 -143 -13 -189 -85 -300 -43 -66 -49 -91 -26 -110 32 -27 68 14 120
137 22 53 65 120 135 212 96 125 103 132 97 95 -4 -22 -23 -80 -42 -128 -58
-152 -77 -256 -56 -310 7 -15 11 -14 42 12 19 17 39 30 43 31 15 0 -5 -64 -34
-111 -38 -60 -37 -82 9 -131 l39 -41 36 59 37 59 -5 -60 c-3 -33 -10 -136 -15
-230 -6 -93 -31 -332 -56 -530 -25 -198 -49 -425 -54 -505 -5 -80 -16 -190
-24 -245 -29 -191 -46 -400 -57 -679 l-10 -281 31 -210 c56 -378 150 -939 166
-987 8 -25 22 -52 30 -59 39 -33 67 -101 73 -181 l6 -80 40 -14 c56 -19 81
-52 110 -149 31 -99 55 -125 120 -125 42 0 99 32 134 74 21 25 17 80 -9 117
-19 25 -20 33 -9 40 27 17 39 8 70 -52 17 -34 34 -58 39 -53 20 21 32 100 39
252 5 99 12 167 19 174 9 9 30 9 82 1 80 -13 116 -35 271 -175 l90 -80 41 28
c55 38 114 131 179 284 52 121 57 128 149 220 135 136 181 230 195 402 4 46
16 124 27 173 25 116 35 403 19 530 -6 50 -27 167 -46 260 -40 197 -56 337
-85 710 -40 517 -83 742 -209 1090 l-50 139 -26 351 c-26 358 -41 537 -51 590
-5 27 -1 33 28 46 27 14 45 39 101 148 84 160 91 184 72 222 -24 45 -18 94 25
197 37 91 39 103 38 192 -1 119 15 161 104 257 53 59 68 83 92 154 51 146 195
452 242 514 12 16 32 51 43 77 41 95 149 186 531 444 237 159 423 276 855 537
253 153 407 237 660 362 347 171 529 275 704 403 201 146 311 248 515 477 152
171 250 264 411 390 55 43 165 143 244 222 l143 143 85 15 c47 8 136 15 197
15 64 0 162 9 231 21 66 11 199 24 296 29 255 14 407 59 724 215 201 99 275
177 275 294 0 70 -17 98 -105 175 -88 76 -141 112 -197 132 -52 18 -243 50
-382 63 -122 12 -412 12 -523 1z m-2425 -962 c-17 -25 -75 -68 -92 -68 -13 0
84 89 98 90 5 0 2 -10 -6 -22z m-388 -298 c0 -6 -17 -25 -37 -44 -53 -49 -69
-28 -18 24 35 36 55 43 55 20z m-847 -77 c-16 -43 -69 -106 -121 -145 -31 -23
-102 -85 -157 -139 -99 -95 -158 -139 -171 -126 -11 10 69 108 159 194 78 75
274 233 289 233 4 0 5 -8 1 -17z m-634 -605 c-202 -219 -459 -462 -467 -438
-2 5 11 37 29 70 58 109 207 267 346 367 65 46 161 102 177 103 4 0 -34 -46
-85 -102z m-108 -1565 c-8 -31 -43 -53 -72 -46 -16 5 -12 10 24 39 52 41 57
41 48 7z m-478 -491 c-1 -111 -55 -244 -134 -332 l-40 -45 6 50 c11 81 53 214
101 314 51 108 68 111 67 13z m-1158 -179 c-24 -53 -94 -164 -135 -211 -18
-22 -40 -23 -40 -2 0 5 39 72 86 150 68 109 90 138 100 129 11 -9 8 -21 -11
-66z m499 -1241 c9 -16 -15 -82 -29 -82 -11 0 -15 11 -15 38 0 21 3 42 7 45 9
10 31 9 37 -1z m-1078 -746 c-3 -14 -13 -26 -21 -26 -12 0 -14 7 -9 28 9 38
15 45 26 34 6 -6 8 -22 4 -36z m164 -2295 c0 -16 3 -43 6 -60 5 -24 3 -31 -10
-31 -13 0 -16 11 -16 60 0 33 4 60 10 60 6 0 10 -13 10 -29z m37 -203 c11 -13
24 -30 28 -38 9 -15 35 -182 29 -189 -4 -5 -53 26 -81 52 -13 11 -25 35 -29
53 -6 34 12 144 25 144 4 0 17 -10 28 -22z m73 -345 l-1 -98 -34 37 c-19 21
-35 46 -35 56 0 16 55 102 66 102 2 0 4 -44 4 -97z m153 -1640 c-9 -75 -18
-82 -38 -29 -19 50 -11 76 26 76 16 0 18 -6 12 -47z"
      />
      <path
        d="M28103 37135 c-31 -22 -29 -63 6 -133 42 -82 61 -104 251 -303 189
-197 187 -193 80 -193 -48 0 -78 11 -250 91 -326 153 -362 166 -465 171 -123
5 -166 -12 -335 -139 -74 -55 -141 -107 -149 -114 -11 -11 -9 -22 18 -68 42
-73 268 -301 416 -419 170 -136 248 -210 412 -395 207 -234 374 -380 613 -540
165 -110 282 -174 615 -338 306 -151 635 -340 1125 -648 370 -232 753 -500
834 -583 21 -21 50 -64 64 -94 14 -30 39 -75 57 -100 39 -55 175 -342 215
-456 40 -115 54 -139 112 -197 28 -28 63 -76 77 -106 22 -49 26 -68 24 -150
-1 -89 1 -100 39 -194 41 -103 41 -100 22 -223 -5 -37 1 -55 68 -184 61 -119
78 -145 106 -158 30 -15 33 -19 28 -52 -11 -66 -26 -244 -51 -598 -14 -195
-29 -361 -34 -370 -8 -15 -45 -118 -106 -292 -60 -172 -114 -512 -145 -910
-31 -409 -43 -506 -98 -770 -34 -168 -36 -183 -36 -390 -1 -183 2 -232 21
-330 13 -63 27 -157 33 -208 18 -162 74 -268 199 -380 73 -65 72 -64 174 -284
55 -118 97 -178 148 -214 l39 -27 76 69 c177 160 215 183 306 187 58 2 62 1
69 -23 4 -14 7 -75 8 -136 1 -117 15 -213 36 -253 12 -24 13 -23 45 37 22 41
39 62 51 62 28 0 31 -16 9 -52 -29 -48 -26 -98 8 -130 49 -45 76 -58 120 -58
55 0 95 37 111 101 30 118 77 179 137 179 23 0 24 3 24 64 0 78 18 132 65 192
19 26 39 63 45 83 12 46 108 622 155 936 l36 240 -10 300 c-11 290 -25 460
-56 670 -8 54 -20 169 -25 255 -5 85 -28 297 -51 470 -34 263 -84 827 -74 838
2 2 18 -23 36 -54 l32 -57 39 41 c45 49 48 75 11 128 -26 39 -46 98 -35 108 2
3 22 -8 43 -25 l38 -30 7 26 c13 53 -6 151 -56 293 -27 79 -50 150 -50 159 0
25 201 -246 227 -306 63 -146 96 -188 128 -161 24 20 18 43 -30 121 -69 111
-80 154 -80 299 l0 125 -56 56 c-65 64 -88 116 -125 282 -39 169 -168 611
-213 727 -66 170 -121 268 -336 590 -144 217 -211 309 -230 318 -17 8 -44 10
-70 6 -79 -11 -113 19 -287 262 -138 193 -140 196 -132 264 7 68 -9 92 -88
134 -29 16 -68 40 -86 53 -53 39 -363 490 -376 546 -6 27 -6 27 50 27 l56 0
-20 33 c-11 17 -37 41 -58 52 -28 15 -46 37 -69 82 -43 85 -131 189 -255 302
-57 53 -233 222 -390 377 -157 154 -323 311 -370 348 -103 81 -306 220 -470
320 -104 64 -126 74 -155 69 -49 -8 -101 14 -169 71 -105 90 -311 220 -766
483 -242 141 -228 135 -370 144 -79 4 -166 17 -235 34 -142 35 -271 43 -302
20z m1093 -802 c-8 -8 -64 27 -85 53 -37 47 -22 47 34 -2 31 -26 54 -49 51
-51z m384 -295 c0 -16 -26 -6 -57 22 -21 19 -30 35 -26 45 5 13 13 9 44 -21
22 -21 39 -42 39 -46z m851 -63 c152 -113 376 -341 356 -361 -13 -13 -89 41
-162 116 -44 46 -111 106 -150 135 -38 29 -79 66 -91 82 -29 39 -56 93 -47 93
5 0 47 -29 94 -65z m528 -491 c175 -96 383 -301 460 -451 31 -62 26 -69 -21
-31 -96 74 -534 518 -512 518 4 0 37 -16 73 -36z m192 -1675 c-33 -13 -71 13
-71 48 l0 24 44 -33 c34 -26 40 -34 27 -39z m474 -461 c46 -97 92 -241 101
-318 l5 -45 -36 40 c-81 89 -135 222 -135 333 0 61 4 82 17 82 2 0 24 -42 48
-92z m1180 -229 c51 -84 84 -148 80 -157 -5 -13 -8 -12 -26 4 -64 58 -176 259
-157 281 6 7 12 13 13 13 2 0 42 -64 90 -141z m-547 -1217 c4 -55 -22 -59 -37
-5 -12 41 -7 56 17 51 12 -2 18 -16 20 -46z m1067 -704 c6 -21 4 -28 -8 -28
-16 0 -32 44 -21 61 9 14 21 0 29 -33z m-175 -2328 c0 -49 -3 -60 -16 -60 -12
0 -15 6 -10 23 3 12 6 39 6 60 0 20 5 37 10 37 6 0 10 -27 10 -60z m-16 -167
c4 -9 9 -42 13 -74 4 -45 2 -64 -11 -84 -16 -25 -93 -82 -100 -74 -2 2 3 42
10 89 7 47 13 88 13 92 1 11 52 68 61 68 4 0 11 -8 14 -17z m-74 -295 c38 -52
37 -74 -5 -123 l-35 -40 0 103 c0 56 2 102 5 102 3 0 18 -19 35 -42z m87 -25
c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-237 -1638 c10 -12 9
-24 -5 -61 -20 -53 -30 -46 -37 29 -5 40 -3 47 12 47 10 0 23 -7 30 -15z"
      />
      <path
        d="M15017 36009 c-114 -10 -268 -43 -372 -80 -188 -66 -321 -74 -574
-35 -220 34 -299 39 -436 26 -255 -23 -443 -81 -899 -277 -160 -69 -502 -213
-761 -320 -772 -320 -896 -374 -931 -406 -19 -16 -34 -38 -34 -48 0 -48 -39
-98 -217 -276 -161 -161 -197 -203 -251 -291 -85 -138 -84 -135 -31 -117 23 8
44 13 47 11 6 -7 -138 -267 -152 -272 -7 -3 -61 18 -120 45 -134 63 -172 73
-267 73 -150 -1 -265 -60 -429 -217 -95 -92 -174 -154 -429 -339 -112 -81
-181 -181 -181 -262 0 -17 -18 -35 -72 -73 -62 -44 -68 -51 -45 -51 15 0 55
12 91 26 46 19 72 25 92 19 l29 -7 -50 -44 c-54 -47 -136 -94 -200 -114 -98
-31 -141 -89 -195 -261 -26 -82 -53 -146 -76 -180 -45 -65 -51 -104 -29 -210
19 -97 13 -124 -36 -175 l-30 -31 31 -13 c66 -27 71 -60 45 -301 -11 -96 -14
-181 -10 -254 8 -130 -8 -223 -55 -323 -16 -35 -34 -90 -39 -121 -17 -103 -58
-173 -121 -206 -25 -13 -26 -19 -37 -152 l-12 -138 29 -30 c45 -47 61 -98 70
-229 5 -67 14 -152 19 -191 13 -85 14 -285 2 -304 -16 -25 -41 79 -56 229 -15
156 -33 234 -35 150 -1 -63 -28 -521 -35 -611 -6 -65 -3 -82 19 -142 42 -108
33 -179 -30 -270 -19 -26 -34 -53 -34 -61 0 -25 12 -46 25 -46 30 0 37 -28 21
-77 -8 -26 -34 -118 -56 -203 -28 -104 -60 -193 -96 -271 -30 -63 -54 -121
-54 -127 0 -7 -31 -106 -69 -220 -105 -315 -125 -404 -118 -531 5 -82 10 -108
24 -122 11 -11 89 -37 189 -63 95 -25 211 -57 258 -70 47 -14 172 -44 278 -66
230 -49 371 -85 456 -116 95 -36 159 -32 288 16 210 79 262 123 376 321 42 74
163 265 268 424 258 390 356 565 479 852 116 272 230 648 261 862 18 118 7
388 -19 512 -25 115 -92 314 -129 383 -40 74 -116 152 -177 182 -37 18 -79 28
-132 33 l-78 6 -218 180 -219 180 32 26 c17 15 72 41 121 59 241 86 528 277
744 494 87 87 155 181 278 383 26 43 74 109 106 146 65 75 120 179 148 277 14
51 18 103 18 240 0 237 -3 253 -76 351 l-60 80 97 105 c54 58 117 117 141 131
l43 25 122 -98 c157 -127 338 -301 383 -370 20 -29 47 -84 61 -121 60 -161
149 -240 295 -260 52 -8 55 -10 79 -56 14 -26 29 -47 34 -47 4 0 31 18 58 40
56 44 56 44 92 30 24 -9 26 -14 26 -69 0 -119 27 -137 205 -135 263 3 402 31
920 188 392 120 504 183 918 520 139 113 154 129 227 237 107 158 156 199 236
199 52 0 105 -32 196 -118 220 -206 407 -324 594 -371 137 -35 372 -54 664
-53 354 1 369 -2 660 -151 118 -60 229 -119 245 -130 107 -74 196 -176 242
-275 22 -46 28 -52 46 -45 12 4 40 8 62 8 l40 0 0 -45 c0 -25 -4 -65 -9 -89
l-8 -45 53 43 54 43 13 -73 c7 -41 14 -87 17 -104 4 -23 31 -53 111 -125 91
-81 111 -104 136 -160 18 -41 50 -87 88 -125 32 -33 74 -85 94 -117 46 -72 74
-94 143 -108 77 -16 150 -5 236 37 64 32 96 61 288 255 l216 218 7 209 c5 169
4 211 -7 218 -7 4 -20 26 -29 48 -8 22 -43 107 -77 188 -67 159 -111 312 -111
386 0 58 -27 103 -76 127 -71 36 -68 26 -164 519 -68 353 -202 634 -405 853
-127 136 -435 405 -656 572 -220 166 -384 267 -674 415 -342 175 -488 217
-1250 360 -431 81 -541 92 -738 74z m-2697 -636 c0 -8 -92 -63 -104 -63 -3 0
-6 6 -6 13 0 7 19 23 43 34 44 23 67 28 67 16z m-590 -233 c0 -10 -91 -60
-110 -60 -24 0 -6 22 38 44 51 27 72 32 72 16z m-380 -154 c0 -21 -28 -39 -47
-31 -16 6 -16 8 6 26 27 22 41 24 41 5z m3406 -11 c54 -22 69 -67 49 -153 -20
-88 -90 -230 -155 -315 -101 -134 -174 -202 -267 -250 l-86 -45 -74 59 c-95
76 -185 128 -300 173 -51 19 -136 61 -190 91 -123 69 -203 95 -297 95 -84 0
-137 -21 -208 -81 -26 -22 -69 -49 -95 -61 -27 -11 -50 -22 -51 -24 -2 -1 6
-15 17 -31 24 -33 27 -55 17 -140 l-8 -62 49 -6 c75 -10 138 -33 179 -66 43
-34 44 -33 -66 -14 -105 18 -107 18 -114 0 -4 -11 9 -22 44 -40 28 -14 50 -27
50 -28 0 -2 -22 -75 -50 -162 -27 -87 -50 -161 -50 -163 0 -3 27 -2 60 1 33 4
60 3 60 -2 0 -24 -61 -139 -87 -163 -16 -15 -50 -34 -75 -42 l-46 -14 -36 37
c-27 28 -41 56 -57 112 -46 169 -143 309 -332 480 -62 56 -113 106 -115 110
-5 13 35 66 120 159 56 61 93 112 128 181 58 113 93 151 177 191 122 58 563
154 813 177 52 4 290 9 528 10 359 1 439 -1 468 -14z m-3552 -44 c4 -7 -34
-31 -49 -31 -14 0 -2 29 13 33 28 8 31 7 36 -2z m281 -11 c24 -27 17 -31 -40
-23 l-48 6 22 19 c30 23 44 23 66 -2z m4095 -84 c0 -19 -8 -44 -19 -57 l-18
-23 -6 35 c-4 19 -9 59 -12 89 l-6 55 30 -33 c22 -23 31 -42 31 -66z m-2900
-895 c22 -31 14 -47 -19 -36 -19 6 -44 49 -34 59 13 13 33 5 53 -23z m-2085
-80 c-17 -65 -22 -71 -31 -37 -4 17 1 36 16 61 12 20 24 34 26 33 2 -2 -3 -28
-11 -57z m4208 -48 c7 -6 -40 -43 -55 -43 -17 0 -6 28 15 38 27 13 32 14 40 5z
m-5017 -119 l28 -27 -34 -28 c-49 -41 -56 -37 -52 26 4 63 15 68 58 29z m242
-41 c-2 -23 -8 -29 -35 -31 -40 -4 -41 2 -5 33 35 32 44 31 40 -2z m561 13 c8
-10 8 -16 -3 -25 -10 -9 -16 -8 -26 3 -12 15 -6 36 9 36 5 0 14 -6 20 -14z
m1441 -297 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m-2892
-497 l54 -34 -38 -40 c-22 -22 -100 -85 -175 -139 -198 -143 -282 -244 -304
-361 -5 -27 -11 -35 -22 -31 -13 5 -15 19 -9 83 10 123 28 163 118 255 67 71
79 89 87 131 10 53 46 93 145 160 55 37 48 38 144 -24z m-13 -251 c10 -17 -38
-63 -58 -55 -17 6 -16 9 9 35 29 31 39 35 49 20z m324 -2721 c0 -36 -6 -114
-14 -175 -9 -60 -18 -162 -22 -225 -7 -139 -23 -188 -102 -307 -31 -49 -68
-117 -82 -153 l-26 -65 -129 -87 -129 -87 -12 27 c-8 18 -12 96 -13 238 0 195
2 225 30 370 17 88 46 262 64 387 19 126 37 231 40 234 8 9 166 8 221 -1 43
-7 56 -4 95 17 l45 24 17 -66 c9 -36 17 -95 17 -131z m-962 -52 c-2 -13 -4 -5
-4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m36 -468 c-16 -40 -33 -53 -33 -25
0 24 22 65 36 65 9 0 9 -9 -3 -40z m937 -248 c0 -4 -7 -26 -16 -49 -25 -64
-64 -57 -43 8 6 19 12 39 14 46 4 11 45 7 45 -5z m619 -339 c-3 -30 -26 -71
-36 -65 -14 9 9 72 27 72 5 0 10 -3 9 -7z m-1102 -109 c8 -20 -13 -42 -27 -28
-12 12 -3 44 11 44 5 0 12 -7 16 -16z m63 -344 c0 -20 -5 -30 -15 -30 -18 0
-19 14 -5 41 15 27 20 24 20 -11z m109 3 l24 -16 -23 -19 c-23 -19 -85 -25
-94 -9 -6 9 48 61 63 61 3 0 17 -7 30 -17z m61 -33 c17 -9 18 -14 8 -35 -13
-30 -28 -33 -28 -6 0 11 -3 26 -6 35 -7 19 0 20 26 6z m-374 -52 c-3 -13 -6
-29 -6 -35 0 -7 -7 -13 -15 -13 -18 0 -19 9 -5 44 12 32 35 35 26 4z m464
-173 c0 -11 -11 -15 -40 -15 -22 0 -40 4 -40 9 0 8 18 14 68 20 6 0 12 -6 12
-14z"
      />
      <path
        d="M23720 36009 c-102 -9 -742 -127 -1005 -185 -293 -66 -492 -141 -796
-304 -257 -137 -461 -273 -689 -455 -141 -113 -346 -292 -459 -400 -246 -235
-405 -539 -477 -908 -99 -513 -96 -502 -172 -545 -53 -30 -60 -44 -73 -142 -5
-44 -21 -119 -35 -166 -30 -104 -163 -426 -180 -437 -10 -6 -12 -56 -7 -217
l6 -210 216 -218 c172 -173 229 -225 278 -250 110 -58 245 -66 314 -19 18 12
54 53 79 90 26 37 69 90 96 117 28 27 55 60 61 72 6 13 21 43 32 68 14 31 53
74 125 139 58 52 107 103 110 115 2 12 10 58 16 104 7 45 15 82 17 82 3 0 28
-19 56 -42 l50 -43 -8 45 c-5 25 -9 65 -9 90 l-1 45 40 0 c22 0 50 -4 62 -8
19 -7 25 0 53 56 39 80 107 162 186 225 78 62 441 251 554 288 84 28 87 28
375 30 315 2 563 22 694 55 168 43 363 163 564 348 120 111 167 141 222 141
80 0 133 -45 233 -195 80 -120 85 -125 247 -256 259 -211 446 -336 602 -404
69 -30 484 -159 683 -214 257 -69 616 -100 694 -59 36 19 45 41 46 109 0 65 1
67 31 79 26 11 33 10 57 -7 82 -59 95 -67 99 -58 2 6 14 28 26 50 21 37 27 40
77 47 79 12 172 60 215 112 20 24 53 85 75 135 21 50 49 108 61 129 37 61 184
206 334 330 77 63 148 122 157 130 28 23 88 -19 201 -140 l100 -108 -58 -75
c-31 -41 -61 -92 -66 -112 -5 -21 -12 -99 -15 -173 -14 -279 39 -442 205 -635
29 -33 75 -100 103 -150 122 -216 254 -365 468 -527 201 -152 360 -242 532
-303 42 -15 90 -39 108 -54 l32 -26 -218 -180 -218 -180 -79 -6 c-188 -17
-298 -134 -386 -410 -61 -194 -75 -285 -75 -499 -1 -168 2 -210 22 -305 72
-336 223 -750 384 -1055 90 -170 185 -325 376 -613 94 -142 201 -311 237 -375
103 -182 168 -237 369 -312 132 -48 194 -52 289 -16 77 29 258 75 479 121 92
20 207 47 254 61 47 13 163 45 258 70 104 27 178 52 190 63 15 15 19 39 23
127 5 102 3 118 -31 246 -19 75 -52 183 -72 240 -20 57 -51 152 -69 212 -19
60 -49 138 -68 175 -38 73 -75 180 -107 311 -11 47 -30 114 -42 149 -20 63
-20 65 -2 77 55 38 57 53 10 123 -19 28 -40 67 -46 87 -16 48 -8 128 20 193
22 50 22 55 11 209 -11 154 -30 483 -32 546 -1 66 -19 -13 -29 -132 -12 -137
-16 -161 -37 -226 -11 -34 -18 -44 -24 -35 -12 19 -11 208 1 289 5 36 14 124
19 197 11 143 22 181 70 235 l31 35 -12 137 c-11 134 -12 137 -38 151 -34 18
-79 69 -96 111 -7 17 -18 61 -24 96 -5 36 -21 85 -34 109 -47 85 -61 153 -58
289 1 69 -6 216 -15 327 -19 228 -17 244 43 269 l36 15 -35 38 c-45 50 -51 82
-33 161 26 108 22 145 -23 214 -24 37 -55 107 -77 176 -20 63 -45 131 -55 151
-26 52 -89 102 -153 122 -56 18 -186 98 -220 136 -18 20 -18 20 9 27 18 4 48
-2 94 -21 37 -15 77 -25 89 -23 18 2 9 12 -48 51 -53 36 -70 54 -70 71 0 80
-67 179 -174 257 -283 208 -342 254 -437 344 -165 158 -279 216 -428 217 -95
0 -132 -10 -265 -72 -58 -27 -112 -47 -121 -44 -14 6 -159 263 -152 270 2 3
23 -3 47 -11 24 -8 44 -14 45 -13 1 2 -30 55 -70 118 -91 143 -158 221 -308
358 -125 113 -150 145 -162 202 -9 44 -38 76 -95 105 -25 13 -329 141 -675
285 -347 144 -781 326 -965 405 -465 198 -639 252 -896 276 -136 12 -215 7
-435 -27 -253 -39 -386 -31 -573 35 -112 40 -252 69 -388 81 -125 11 -140 11
-278 -1z m3055 -651 c25 -12 45 -28 45 -35 0 -7 -2 -13 -5 -13 -11 0 -105 56
-105 63 0 12 17 8 65 -15z m605 -238 c41 -21 51 -30 39 -35 -19 -7 -119 39
-119 55 0 16 22 11 80 -20z m342 -137 c23 -21 23 -33 -2 -33 -16 0 -40 26 -40
44 0 12 24 6 42 -11z m-2332 -17 c277 -40 610 -120 709 -171 69 -35 116 -85
150 -160 39 -85 76 -137 170 -239 44 -47 83 -96 87 -108 5 -18 -5 -31 -62 -81
-222 -194 -334 -347 -383 -528 -16 -54 -30 -82 -57 -110 l-36 -37 -47 15 c-59
20 -94 53 -124 117 -47 99 -47 97 22 89 34 -3 61 -2 61 3 0 5 -23 79 -50 164
-28 85 -50 156 -50 157 0 2 22 14 50 28 35 18 48 29 44 40 -7 18 1 18 -109 0
-49 -8 -91 -13 -93 -11 -2 2 12 16 31 30 39 30 100 52 170 61 l48 6 -7 85 c-6
78 -5 88 15 115 13 17 21 32 19 34 -2 2 -25 13 -52 25 -27 11 -67 37 -89 56
-63 58 -130 84 -214 84 -94 0 -170 -25 -302 -99 -60 -33 -138 -71 -175 -85
-109 -41 -216 -101 -301 -170 l-80 -63 -88 44 c-92 48 -170 120 -264 247 -123
166 -192 364 -152 437 25 48 41 49 559 45 385 -3 509 -7 600 -20z m2221 -44
l22 -19 -48 -6 c-58 -8 -68 -2 -36 24 29 23 33 24 62 1z m255 8 c18 -7 24 -14
17 -21 -12 -12 -65 9 -57 22 7 11 7 11 40 -1z m-4360 -52 c-4 -29 -9 -68 -13
-87 l-6 -35 -18 23 c-27 33 -24 83 7 120 14 17 28 31 31 31 3 0 3 -24 -1 -52z
m2892 -944 c-12 -26 -45 -41 -59 -27 -4 4 3 23 17 41 20 25 29 30 39 22 11 -9
11 -17 3 -36z m2058 -56 c9 -18 13 -40 9 -55 -8 -34 -15 -25 -30 42 -14 58 -5
64 21 13z m-4191 -70 c23 -10 36 -38 17 -38 -13 0 -52 30 -52 41 0 12 4 11 35
-3z m5035 -127 c0 -22 3 -46 6 -55 10 -25 -18 -19 -55 12 l-34 28 29 27 c40
38 54 35 54 -12z m-823 -17 c9 -23 -13 -40 -32 -24 -12 10 -13 16 -4 26 15 18
28 18 36 -2z m566 -9 c35 -31 34 -37 -5 -33 -28 2 -34 8 -36 31 -4 33 5 34 41
2z m-2003 -286 c0 -11 -27 -12 -34 0 -3 4 -3 11 0 14 8 8 34 -3 34 -14z m3023
-493 c94 -69 108 -86 118 -143 8 -40 21 -59 87 -127 94 -97 113 -138 115 -256
2 -98 -14 -117 -32 -38 -26 117 -102 206 -300 347 -70 50 -147 111 -170 135
l-43 44 43 27 c24 15 58 36 74 46 17 10 31 19 33 19 2 0 35 -24 75 -54z m-108
-276 c26 -27 27 -30 10 -36 -13 -5 -25 1 -41 21 -21 27 -23 45 -5 45 4 0 20
-13 36 -30z m-200 -2544 c57 9 213 10 222 1 3 -4 23 -120 44 -259 21 -139 49
-311 63 -381 23 -115 26 -151 26 -345 -1 -147 -5 -225 -13 -243 l-12 -27 -129
87 -129 86 -27 71 c-15 39 -48 100 -73 136 -80 112 -99 164 -108 302 -4 67
-15 177 -23 246 -20 158 -20 197 1 291 l17 76 46 -24 c39 -21 52 -24 95 -17z
m778 -671 c11 -22 13 -36 6 -41 -12 -7 -11 -8 -27 29 -24 56 -5 67 21 12z
m-906 -287 c6 -23 10 -45 7 -50 -12 -19 -31 -6 -43 30 -8 20 -16 42 -18 49 -4
11 26 24 37 15 2 -2 10 -22 17 -44z m-644 -315 c2 -10 8 -25 11 -34 5 -10 1
-19 -7 -22 -11 -4 -18 6 -25 34 -8 30 -7 39 3 39 7 0 15 -8 18 -17z m1104 -99
c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m-67 -361 c0 -7
-7 -13 -15 -13 -10 0 -15 10 -15 33 1 30 1 31 15 13 8 -11 15 -26 15 -33z
m-71 11 c17 -14 29 -30 25 -35 -9 -16 -71 -10 -94 9 l-23 19 24 16 c30 22 32
22 68 -9z m-103 -30 c-3 -9 -6 -25 -6 -37 -1 -22 -1 -22 -21 3 -20 23 -20 25
-3 37 24 18 37 16 30 -3z m374 -36 c0 -7 3 -23 6 -35 5 -17 2 -23 -10 -23 -9
0 -16 6 -16 13 0 6 -3 22 -6 35 -5 16 -2 22 10 22 9 0 16 -6 16 -12z m-427
-178 c9 0 17 -4 17 -10 0 -5 -18 -10 -40 -10 -30 0 -40 4 -40 16 0 12 6 15 23
10 12 -3 30 -6 40 -6z"
      />
      <path
        d="M5460 35471 c-221 -73 -248 -90 -455 -290 l-180 -175 -53 -116 c-171
-369 -250 -575 -377 -994 -24 -82 -31 -94 -92 -155 l-65 -66 7 -50 c16 -106
11 -138 -33 -218 -23 -42 -42 -80 -42 -84 0 -5 25 -24 56 -43 38 -23 51 -36
42 -41 -44 -24 -145 -49 -202 -49 -79 0 -146 -13 -247 -47 -136 -46 -216 -126
-239 -240 -7 -30 -10 -56 -8 -58 1 -1 42 -22 90 -46 l87 -44 -75 -98 c-121
-160 -157 -253 -153 -395 1 -42 -5 -133 -14 -202 -9 -69 -23 -197 -32 -285 -8
-89 -22 -208 -30 -265 -27 -195 -15 -444 32 -615 9 -33 38 -114 64 -180 57
-143 65 -196 44 -285 -56 -241 -58 -267 -59 -650 0 -123 30 -323 80 -532 l15
-67 -29 -43 c-74 -108 -145 -288 -212 -537 -30 -111 -33 -128 -23 -175 6 -28
27 -78 47 -110 65 -108 59 -151 -35 -269 -76 -96 -79 -122 -12 -107 l35 8 -4
-54 c-6 -68 -23 -88 -114 -130 l-71 -33 5 -133 c6 -158 -10 -307 -44 -426 -41
-140 -48 -144 -54 -27 l-5 100 -33 -83 c-39 -100 -65 -207 -57 -239 7 -29 53
-53 103 -53 24 0 45 -7 55 -17 24 -27 53 -102 71 -184 19 -84 27 -285 11 -283
-5 1 -24 5 -41 8 -28 6 -32 4 -44 -24 -17 -41 -7 -83 34 -139 18 -25 68 -107
111 -181 90 -158 135 -222 241 -345 102 -120 120 -148 159 -255 62 -170 138
-268 345 -438 63 -52 135 -114 160 -137 35 -33 309 -215 324 -215 1 0 8 25 15
56 10 43 10 66 1 105 -24 99 5 152 145 264 315 254 542 545 598 770 16 64 38
225 59 440 17 177 17 850 0 1010 -20 186 -48 357 -82 505 -46 204 -57 314 -40
413 14 90 15 118 5 193 -6 41 -1 71 26 165 29 98 33 130 34 224 0 98 -3 116
-26 166 -15 30 -51 85 -80 120 -30 35 -71 89 -91 119 -35 51 -102 111 -156
139 -23 12 -23 13 -6 33 18 19 17 22 -21 96 -22 43 -61 99 -90 128 -63 64
-180 124 -241 124 -38 0 -49 -6 -101 -54 l-59 -54 -48 9 c-31 6 -73 5 -118 -2
-38 -6 -70 -9 -71 -7 -1 1 -1 80 1 175 4 201 -14 180 167 190 l120 6 35 36
c20 21 47 68 64 111 25 62 42 87 98 142 37 36 69 72 70 80 1 7 2 114 3 238 1
181 8 280 33 503 18 152 32 294 32 314 0 20 -12 71 -26 112 -34 97 -28 158 21
248 l35 63 -40 16 -40 16 0 89 c0 49 2 89 5 89 4 0 18 -6 33 -14 l27 -14 0
113 c0 100 3 117 21 142 35 47 80 56 171 32 96 -25 137 -20 252 29 l84 36 -6
105 c-4 78 -15 140 -42 236 -80 288 -147 359 -369 390 -57 8 -116 19 -131 25
l-28 11 61 44 c63 46 240 158 369 234 125 74 154 106 232 259 88 175 193 403
214 467 38 113 77 343 77 447 0 175 16 279 55 374 23 55 41 121 50 187 19 127
35 330 26 338 -13 14 -115 29 -189 29 -65 0 -100 -8 -222 -49z m-456 -882
c-16 -24 -30 -46 -31 -48 -2 -3 -8 0 -13 5 -6 6 2 27 22 57 19 30 35 44 40 39
6 -6 -1 -26 -18 -53z m220 -67 c-4 -12 -10 -22 -15 -22 -11 0 -12 44 -2 54 12
11 23 -9 17 -32z m-604 -321 c0 -19 -23 -71 -31 -71 -11 0 -11 7 -3 49 4 17
13 31 20 31 8 0 14 -4 14 -9z m-270 -834 c0 -7 -13 -20 -29 -30 -27 -16 -29
-16 -35 -1 -4 9 -5 18 -4 19 32 25 68 31 68 12z m227 -1288 l-2 -72 49 -16 49
-17 -32 -27 c-40 -33 -51 -34 -80 -3 -27 28 -37 91 -27 160 6 36 11 46 26 46
17 0 19 -7 17 -71z m53 -409 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10
9 10 9 0 16 -4 16 -10z m-17 -85 c-3 -9 -19 -23 -35 -31 l-29 -15 3 38 c2 28
9 41 28 50 22 11 26 10 32 -7 4 -11 5 -27 1 -35z m-640 -1529 c7 -21 6 -30 -4
-37 -14 -9 -26 14 -28 54 -1 28 21 15 32 -17z m-243 -211 c0 -25 -4 -45 -10
-45 -5 0 -10 20 -10 45 0 25 5 45 10 45 6 0 10 -20 10 -45z m-285 -3200 l60
-27 -74 -34 c-40 -19 -75 -34 -77 -34 -2 0 -4 32 -4 70 0 64 2 70 18 61 9 -5
44 -21 77 -36z m715 26 c0 -12 -49 -20 -56 -10 -3 5 5 13 18 18 24 9 38 6 38
-8z m-775 -245 c41 -17 58 -48 50 -87 -7 -29 -19 -37 -30 -19 -3 5 -33 19 -65
31 -33 12 -60 24 -60 27 0 5 61 61 68 62 1 0 18 -6 37 -14z m241 -38 c12 -23
15 -47 11 -86 -7 -64 -14 -74 -33 -48 -9 13 -14 46 -14 99 0 83 8 90 36 35z
m-158 -122 c3 -13 -1 -17 -14 -14 -11 2 -20 11 -22 22 -3 13 1 17 14 14 11 -2
20 -11 22 -22z m156 -262 c3 -9 3 -20 0 -25 -9 -15 -34 10 -27 27 7 19 19 18
27 -2z"
      />
      <path
        d="M33205 35507 l-50 -12 3 -70 c4 -93 30 -307 44 -364 6 -26 19 -63 30
-83 36 -73 49 -151 58 -348 12 -278 48 -431 155 -666 130 -286 203 -421 251
-462 23 -20 122 -86 220 -145 99 -60 212 -133 253 -163 l74 -53 -27 -11 c-16
-6 -75 -17 -132 -25 -116 -16 -182 -44 -239 -100 -50 -51 -79 -114 -126 -277
-32 -111 -42 -166 -46 -249 l-6 -105 84 -36 c115 -49 156 -54 252 -29 91 24
136 15 171 -32 18 -25 21 -42 21 -142 l0 -113 27 14 c15 8 29 14 33 14 3 0 5
-40 5 -89 l0 -89 -41 -16 -41 -17 18 -27 c68 -103 77 -168 39 -292 l-29 -91
35 -307 c28 -254 34 -345 34 -527 1 -121 4 -229 8 -241 4 -11 32 -42 63 -68
46 -40 63 -65 103 -150 64 -137 71 -141 218 -149 177 -10 159 11 166 -190 3
-95 4 -173 2 -175 -2 -2 -35 1 -73 7 -45 7 -87 8 -118 2 l-48 -9 -59 54 c-52
48 -63 54 -101 54 -61 0 -175 -59 -241 -126 -33 -32 -68 -82 -91 -128 -35 -72
-36 -77 -20 -95 16 -18 15 -20 -26 -44 -54 -31 -111 -86 -146 -140 -15 -23
-51 -71 -81 -107 -117 -140 -137 -255 -80 -465 39 -145 45 -194 30 -248 -8
-30 -9 -52 -1 -77 6 -19 13 -78 16 -130 6 -101 -2 -160 -65 -460 -91 -431
-106 -1037 -40 -1614 24 -213 40 -279 95 -391 88 -180 305 -431 534 -616 148
-120 176 -170 151 -269 -9 -35 -9 -59 0 -100 6 -30 13 -56 14 -58 7 -8 283
173 325 213 25 23 98 85 162 138 220 181 279 258 344 440 35 97 51 122 165
259 113 136 152 192 239 343 35 61 83 139 107 175 44 67 52 100 31 145 -13 28
-12 28 -56 16 -29 -9 -33 -7 -38 15 -11 42 15 251 41 333 35 110 54 131 116
131 40 0 55 5 74 25 14 13 25 35 25 48 0 35 -47 193 -75 252 l-23 50 -1 -98
c-1 -57 -5 -96 -10 -92 -13 8 -48 116 -68 210 -11 57 -17 137 -18 274 l-2 193
-71 33 c-84 40 -114 77 -110 140 2 22 8 39 13 39 6 -1 22 -4 38 -8 45 -11 34
30 -33 114 -93 116 -99 158 -36 263 71 117 71 142 2 379 -51 175 -103 303
-170 418 l-45 77 14 59 c27 113 76 396 82 462 2 37 0 173 -6 302 -8 199 -14
251 -36 340 -36 141 -36 216 0 300 45 107 93 250 110 330 28 132 31 372 6 577
-55 453 -74 633 -72 701 4 146 -38 256 -153 401 -36 45 -66 87 -66 92 0 5 39
28 86 52 l86 42 -7 40 c-29 182 -188 284 -480 304 -65 5 -122 16 -163 31 l-62
23 52 35 c29 19 55 37 57 39 3 2 -15 42 -39 88 -46 90 -50 111 -35 217 l8 56
-53 49 c-57 53 -88 107 -115 198 -43 152 -130 418 -162 500 -20 50 -84 198
-143 330 l-106 240 -148 148 c-227 227 -262 251 -486 327 -109 38 -138 44
-225 46 -55 2 -122 -2 -150 -9z m859 -928 c10 -17 13 -30 6 -34 -12 -7 -72 84
-63 98 6 10 28 -14 57 -64z m-234 -55 c0 -13 -4 -24 -9 -24 -11 0 -22 40 -15
52 9 15 24 -2 24 -28z m614 -336 c8 -33 7 -58 -3 -58 -5 0 -12 11 -15 24 -3
13 -9 31 -12 40 -4 10 -1 16 9 16 8 0 17 -10 21 -22z m279 -819 c29 -16 28
-14 21 -33 -6 -15 -8 -15 -35 1 -48 28 -35 57 14 32z m-229 -1241 c3 -13 6
-51 6 -85 0 -52 -4 -66 -26 -92 -14 -17 -32 -31 -39 -31 -15 0 -65 38 -65 50
0 5 19 15 43 23 l42 14 1 64 c1 79 1 79 18 79 7 0 16 -10 20 -22z m-64 -458
c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m51
-77 c16 -43 5 -57 -31 -38 -23 12 -30 22 -30 44 0 15 3 31 6 34 11 11 45 -13
55 -40z m608 -1525 c-1 -37 -13 -58 -27 -49 -9 6 -10 15 -1 39 13 37 29 42 28
10z m237 -194 c-3 -9 -6 -29 -6 -45 0 -16 -4 -29 -10 -29 -5 0 -10 20 -10 45
0 35 4 45 16 45 11 0 14 -5 10 -16z m-400 -3206 c10 -16 -2 -19 -30 -8 -15 5
-25 14 -22 19 7 11 44 3 52 -11z m754 -48 c0 -79 9 -77 -100 -27 l-55 24 75
36 c41 19 76 36 78 36 1 1 2 -30 2 -69z m39 -209 c38 -32 35 -46 -12 -56 -17
-4 -47 -16 -65 -26 -18 -10 -35 -19 -37 -19 -11 0 -15 62 -5 80 10 18 60 49
80 49 3 0 20 -12 39 -28z m-285 -140 c-19 -45 -34 -30 -40 40 -4 45 -1 60 18
89 l22 35 7 -65 c5 -46 3 -75 -7 -99z m166 4 c0 -15 -26 -32 -36 -23 -2 3 -2
13 2 22 8 20 34 21 34 1z m-165 -265 c4 -6 1 -18 -6 -26 -14 -17 -31 0 -22 23
6 16 19 17 28 3z"
      />
      <path
        d="M6379 33730 c-12 -40 -7 -62 10 -44 5 5 14 26 19 47 15 53 -13 51
-29 -3z"
      />
      <path
        d="M32625 33728 c7 -27 19 -44 30 -47 14 -2 16 1 10 15 -4 11 -12 31
-17 47 -5 15 -16 27 -23 27 -10 0 -11 -8 0 -42z"
      />
      <path
        d="M32866 33382 c-7 -12 118 -207 257 -402 112 -157 164 -259 231 -458
77 -227 180 -481 184 -456 6 37 -48 274 -94 414 -58 177 -135 330 -232 461
-60 82 -81 119 -90 161 -12 50 -21 62 -131 172 -65 65 -121 114 -125 108z"
      />
      <path
        d="M6033 33268 c-101 -101 -113 -118 -123 -163 -12 -54 -29 -84 -123
-209 -72 -97 -141 -239 -195 -401 -43 -128 -87 -315 -97 -405 -5 -50 1 -39 65
120 39 96 96 246 126 334 64 187 113 285 208 415 70 97 276 407 276 416 0 21
-41 -11 -137 -107z"
      />
      <path
        d="M8790 33274 c0 -11 8 -14 32 -12 46 4 49 28 4 28 -26 0 -36 -4 -36
-16z"
      />
      <path
        d="M30177 33284 c-12 -12 4 -24 34 -24 19 0 29 5 29 15 0 15 -50 22 -63
9z"
      />
      <path
        d="M14420 30049 c-242 -26 -353 -48 -424 -84 -103 -54 -133 -80 -150
-133 -22 -67 -36 -165 -36 -253 0 -75 49 -369 90 -544 12 -49 25 -136 30 -192
5 -68 13 -111 24 -128 10 -14 95 -77 191 -140 192 -127 288 -208 305 -260 14
-41 7 -80 -37 -209 -40 -120 -41 -142 -7 -208 14 -28 37 -97 51 -152 13 -56
40 -154 60 -218 33 -107 38 -118 60 -118 19 0 29 -11 48 -52 21 -45 25 -68 25
-149 0 -53 -3 -130 -6 -172 l-7 -77 -55 31 c-62 36 -72 31 -72 -37 0 -52 35
-101 118 -168 94 -75 154 -151 221 -281 34 -66 92 -163 129 -215 97 -138 288
-323 462 -450 175 -126 580 -399 744 -501 145 -89 212 -149 317 -285 44 -57
119 -145 167 -196 l88 -91 74 17 75 17 19 -23 c10 -13 46 -79 78 -148 71 -149
94 -181 163 -226 39 -24 56 -43 65 -69 21 -63 82 -164 149 -243 120 -143 171
-240 193 -364 9 -52 13 -58 41 -67 69 -22 130 -23 273 -5 l146 17 23 36 c48
74 58 139 57 354 -3 280 -28 438 -117 742 -61 209 -79 288 -136 619 -89 521
-144 697 -310 997 -105 190 -225 500 -298 767 -24 87 -13 101 45 57 30 -23
188 -226 281 -360 80 -117 650 -1222 677 -1316 37 -122 115 -214 230 -268 76
-36 146 -51 240 -51 69 0 78 3 132 39 33 21 76 44 98 51 52 17 110 77 130 136
44 128 61 560 31 786 -8 62 -28 178 -44 257 -17 79 -35 210 -41 290 -27 368
-55 514 -136 720 -26 64 -86 221 -135 349 -48 128 -100 254 -115 280 -37 63
-105 133 -249 260 -212 185 -251 237 -321 425 -63 167 -166 379 -216 444 -46
61 -137 136 -218 181 -94 53 -197 91 -430 158 -216 62 -307 95 -508 183 -121
53 -298 107 -442 133 -100 19 -157 22 -440 24 -330 2 -421 9 -585 45 -166 37
-291 49 -515 47 -118 0 -253 -5 -300 -9z m188 -173 c23 -10 42 -21 42 -25 0
-3 -36 -42 -80 -86 l-79 -79 -36 17 c-33 16 -39 16 -66 2 -26 -14 -32 -14 -57
-1 -96 54 -115 59 -162 48 -25 -5 -72 -20 -105 -33 -109 -41 -149 -8 -126 104
7 34 15 66 18 70 3 5 141 7 307 5 264 -3 306 -6 344 -22z m916 -189 c-15 -26
-37 -46 -46 -41 -4 3 1 19 13 35 19 27 48 32 33 6z m436 -27 c0 -5 -11 -10
-25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z m-1865 -246
c30 -28 30 -45 2 -147 l-13 -48 -67 70 c-37 38 -67 72 -67 76 0 14 30 64 42
68 34 14 76 6 103 -19z m1383 -267 c36 -29 33 -45 -14 -77 l-46 -32 -24 23
c-31 29 -31 62 3 88 26 21 53 21 81 -2z m-1424 -108 c-3 -5 -10 -7 -15 -3 -5
3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m249 -243 c72 -30 110 -109
123 -260 l7 -79 -60 46 c-111 85 -191 192 -196 263 -2 22 3 29 23 35 40 11 68
10 103 -5z m1455 -2806 c38 -23 47 -40 38 -75 -5 -20 -13 -25 -36 -25 -37 0
-60 32 -60 82 0 44 10 47 58 18z m82 -140 l35 -19 -37 -1 c-27 0 -40 5 -48 20
-14 26 3 26 50 0z m1191 -617 c71 -89 106 -159 118 -233 7 -45 6 -52 -11 -61
-27 -15 -71 2 -167 65 l-81 54 64 37 64 38 -29 85 c-16 47 -29 87 -29 89 0 12
17 -6 71 -74z m162 -368 c23 -48 24 -54 9 -46 -28 15 -41 34 -48 69 -10 49 9
38 39 -23z"
      />
      <path
        d="M24007 30049 c-37 -5 -134 -22 -216 -39 -189 -38 -349 -50 -581 -43
-353 10 -616 -40 -925 -177 -173 -76 -238 -99 -484 -170 -242 -69 -353 -114
-459 -182 -129 -85 -183 -150 -267 -323 -40 -82 -92 -201 -115 -264 -23 -63
-47 -126 -55 -141 -35 -68 -117 -159 -245 -270 -158 -138 -235 -218 -272 -281
-14 -24 -54 -118 -88 -209 -35 -91 -95 -248 -135 -350 -109 -281 -136 -399
-155 -686 -13 -201 -21 -261 -55 -417 -14 -65 -32 -175 -40 -245 -28 -240 -13
-625 30 -770 20 -66 74 -123 137 -145 25 -8 69 -31 98 -51 49 -34 57 -36 129
-36 234 1 401 113 467 314 13 40 132 286 265 547 352 692 361 707 560 975 100
133 158 190 185 180 19 -7 18 -12 -72 -286 -86 -263 -142 -396 -242 -577 -155
-280 -220 -493 -306 -1010 -46 -273 -66 -363 -131 -591 -97 -343 -119 -490
-113 -789 l3 -199 35 -69 35 -70 130 -18 c132 -18 235 -17 292 3 28 10 32 17
43 73 24 127 81 231 202 369 51 58 118 171 137 231 10 28 26 46 67 72 68 44
111 103 168 235 25 58 56 118 69 133 l22 28 75 -17 75 -17 90 95 c49 52 119
133 155 179 90 117 182 207 270 262 382 243 641 418 860 580 125 93 318 289
402 407 37 52 97 152 133 221 63 123 69 130 190 246 138 132 160 168 144 227
-5 20 -12 36 -15 36 -2 0 -28 -14 -58 -30 l-53 -30 -6 91 c-13 180 -9 249 18
307 19 42 29 52 48 52 22 0 27 10 55 98 17 53 45 153 62 222 17 69 42 145 56
169 31 56 30 89 -4 192 -47 138 -56 183 -42 223 15 47 107 129 245 219 281
184 266 168 276 304 4 54 17 140 28 192 46 200 91 476 91 555 0 100 -25 243
-50 284 -22 35 -142 109 -210 129 -25 7 -115 22 -200 34 -174 24 -629 35 -753
18z m1074 -176 c6 -16 13 -51 16 -79 5 -44 2 -55 -17 -74 -26 -26 -68 -23
-146 12 -69 31 -140 30 -190 -3 -58 -38 -73 -41 -104 -22 -27 15 -30 15 -54 0
-14 -9 -31 -17 -38 -17 -13 0 -168 149 -168 161 0 7 74 36 110 42 14 2 150 5
303 6 l277 1 11 -27z m-1534 -200 c23 -35 -3 -37 -28 -2 -19 27 -20 31 -4 28
10 -2 24 -14 32 -26z m-427 -13 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6
11 10 25 10 14 0 25 -4 25 -10z m1919 -230 c10 -5 24 -23 31 -40 13 -31 12
-32 -56 -101 -38 -38 -69 -66 -70 -62 -1 5 -10 36 -19 70 -10 34 -15 73 -11
87 11 43 82 70 125 46z m-1405 -281 c33 -26 33 -59 2 -88 l-25 -23 -45 32
c-42 31 -44 34 -31 54 14 18 51 45 66 46 4 0 18 -9 33 -21z m1366 -97 c0 -13
-12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-149 -258 c33 -20 -25
-130 -114 -218 -39 -40 -87 -82 -104 -95 l-33 -22 0 25 c0 50 22 182 36 216
32 75 91 114 162 105 24 -3 48 -7 53 -11z m-1521 -2822 c0 -46 -24 -82 -54
-82 -60 0 -60 64 -1 97 22 12 43 23 48 23 4 0 7 -17 7 -38z m-90 -122 c-8 -15
-21 -20 -48 -20 l-37 1 35 19 c47 26 64 26 50 0z m-1195 -620 c-14 -40 -25
-79 -25 -86 0 -7 28 -29 62 -50 54 -33 59 -39 43 -48 -10 -6 -48 -31 -84 -55
-71 -47 -115 -64 -144 -55 -17 5 -19 14 -15 58 6 70 43 147 118 239 34 43 64
76 66 74 2 -2 -7 -37 -21 -77z m-177 -366 c-7 -15 -24 -34 -37 -43 l-23 -15
22 44 c12 25 28 51 34 59 17 22 21 -12 4 -45z"
      />
      <path
        d="M12900 29731 c-30 -11 -80 -27 -112 -35 -67 -19 -102 -55 -112 -115
-4 -30 -15 -47 -41 -67 -42 -32 -57 -79 -45 -149 4 -27 6 -53 4 -56 -2 -4 -19
-10 -37 -13 -58 -11 -123 -61 -182 -141 -31 -42 -67 -80 -79 -85 -12 -4 -76
-45 -142 -91 -233 -161 -234 -162 -234 -259 0 -34 -5 -71 -12 -83 -21 -41
-102 -141 -181 -225 -101 -109 -118 -153 -81 -224 l16 -31 -41 -36 c-23 -20
-81 -85 -129 -146 -55 -70 -95 -111 -109 -113 -16 -2 -29 8 -52 42 -32 47 -57
66 -85 66 -14 0 -16 -25 -16 -197 l0 -198 -85 -50 c-46 -27 -83 -52 -82 -55 1
-3 20 -38 41 -78 42 -79 64 -99 110 -104 27 -3 35 4 82 72 l52 74 -25 32 -24
32 47 16 c26 9 62 16 79 16 31 0 75 -24 75 -41 0 -5 -20 -36 -45 -70 -46 -63
-58 -116 -35 -158 7 -13 3 -27 -15 -54 -25 -36 -31 -70 -19 -102 4 -9 52 -53
108 -98 156 -125 428 -402 492 -499 l54 -82 -22 -23 c-11 -12 -36 -27 -55 -34
-41 -13 -56 -5 -169 95 -272 237 -544 390 -820 461 -55 14 -288 52 -519 85
-973 138 -1041 142 -1505 85 -382 -46 -520 -73 -745 -145 -104 -34 -313 -90
-464 -126 -588 -138 -697 -185 -1007 -434 -105 -85 -281 -227 -391 -315 -306
-247 -450 -395 -560 -575 -101 -165 -143 -216 -409 -495 -294 -308 -421 -450
-496 -555 -144 -203 -204 -363 -228 -609 -26 -274 -83 -409 -222 -536 l-61
-55 52 0 53 0 -7 -42 c-14 -84 -66 -239 -141 -425 -42 -103 -94 -235 -114
-293 -23 -63 -43 -105 -51 -105 -16 0 -17 20 -6 183 7 94 6 112 -6 112 -18 0
-15 12 -71 -278 -81 -411 -169 -1110 -196 -1557 -33 -545 -50 -1021 -40 -1159
54 -740 198 -1520 385 -2091 155 -470 288 -686 562 -915 51 -42 127 -116 168
-163 62 -71 84 -88 125 -102 l49 -16 -16 -40 -16 -40 39 -56 c61 -89 223 -252
441 -445 112 -98 203 -182 203 -186 0 -4 -71 -95 -157 -202 -86 -107 -179
-229 -206 -270 -70 -107 -157 -293 -212 -450 -70 -203 -163 -537 -170 -610 -4
-37 5 -240 19 -465 35 -542 41 -796 27 -1050 -19 -327 4 -494 110 -807 33 -97
73 -229 89 -293 19 -76 47 -155 83 -228 71 -148 202 -358 241 -385 l31 -22 3
74 c2 65 5 76 23 81 33 11 88 -4 100 -27 6 -11 14 -76 17 -144 5 -135 8 -139
65 -123 42 11 52 23 72 79 9 24 23 52 32 62 16 18 48 24 56 11 2 -5 -8 -91
-24 -193 -46 -298 -78 -589 -91 -821 -10 -184 -9 -259 6 -510 32 -556 53 -714
130 -977 44 -150 43 -147 72 -161 38 -17 118 -96 195 -192 38 -48 80 -94 94
-103 24 -16 29 -15 111 20 47 21 88 33 93 28 4 -5 45 -99 91 -209 189 -453
369 -837 433 -925 26 -35 57 -57 148 -105 128 -68 91 -28 354 -386 68 -94 170
-216 236 -284 63 -66 136 -150 162 -186 94 -135 145 -162 412 -217 189 -40
273 -65 330 -100 43 -26 139 -203 155 -285 32 -166 53 -208 133 -274 20 -17
27 -32 27 -56 0 -33 36 -95 73 -126 15 -12 17 -11 17 10 l0 22 26 -24 c21 -19
23 -25 10 -27 -9 -2 -16 -7 -16 -10 0 -4 37 -42 82 -85 54 -50 75 -76 60 -72
-14 4 -33 0 -49 -11 l-27 -17 23 -18 c26 -21 53 -24 80 -9 16 8 25 -5 71 -103
29 -62 62 -123 74 -135 26 -29 77 -56 135 -72 24 -6 79 -36 122 -67 l79 -55 0
-48 c0 -46 2 -49 48 -78 26 -17 68 -40 94 -51 63 -27 130 -90 189 -178 57 -84
89 -110 190 -157 125 -58 294 -81 419 -56 25 4 46 7 48 6 1 -2 -5 -18 -14 -36
-15 -30 -15 -35 1 -69 30 -64 101 -155 212 -272 59 -63 132 -143 162 -177 57
-66 154 -142 215 -167 87 -37 833 -235 1021 -271 90 -18 325 -24 565 -14 95 4
96 4 710 -152 836 -213 968 -238 1245 -238 274 0 422 32 695 152 134 59 240
116 240 129 0 4 -16 53 -36 109 -19 57 -33 108 -31 114 6 14 133 56 170 56 14
0 57 -11 94 -25 37 -14 111 -32 163 -40 263 -41 502 3 789 146 118 58 330 189
384 237 25 22 21 51 -8 64 -14 6 -24 15 -23 19 6 15 90 100 95 96 2 -3 10 -26
17 -52 l12 -47 32 27 c33 27 42 64 22 95 -7 12 -14 12 -43 2 -45 -16 -47 -16
-47 12 0 36 42 64 108 71 90 10 100 13 141 50 71 64 122 74 194 38 26 -13 33
-11 140 48 61 34 118 68 124 77 12 14 19 72 9 72 -2 0 -32 -13 -67 -30 -130
-61 -327 -92 -464 -73 l-59 8 77 67 c42 37 73 69 69 71 -4 3 -25 7 -47 11 -79
14 -76 9 -43 85 l30 68 98 42 98 43 -18 44 -19 44 53 36 c40 26 62 35 86 32
60 -6 59 -27 -4 -117 l-58 -82 38 18 c30 13 59 17 119 15 90 -3 103 4 150 78
13 21 40 51 60 66 71 54 70 85 -4 129 l-49 30 33 45 c79 109 97 184 71 300
-23 102 -6 146 78 195 17 9 17 18 0 158 -10 81 -21 147 -25 147 -4 0 -54 -20
-112 -45 -58 -25 -110 -45 -115 -45 -6 0 -16 32 -23 71 l-13 72 -22 -28 c-12
-15 -31 -60 -43 -100 -15 -54 -29 -80 -52 -99 -44 -37 -69 -35 -69 7 -1 87 -3
87 -124 -31 -61 -59 -144 -135 -185 -167 -79 -64 -141 -141 -385 -484 -168
-235 -185 -254 -243 -282 -41 -20 -55 -21 -142 -15 -53 3 -132 11 -176 16
-126 14 -431 12 -557 -5 -204 -27 -402 -85 -580 -171 -40 -18 -82 -34 -94 -34
-23 0 -52 24 -100 83 -18 22 -70 60 -130 93 l-99 55 -92 -4 -92 -4 20 28 c11
16 25 32 32 36 7 4 75 8 152 8 101 0 148 4 168 14 15 8 27 17 27 21 0 10 -165
46 -316 70 -319 50 -365 72 -556 256 -427 412 -708 611 -999 707 -293 97 -714
138 -959 93 -244 -44 -479 -158 -742 -359 l-88 -67 -6 28 c-3 15 -12 47 -20
72 -9 25 -24 81 -34 125 -10 44 -48 196 -84 338 -36 141 -66 271 -66 287 0 37
16 38 115 10 110 -32 289 -60 425 -67 135 -6 254 2 707 47 418 42 486 45 630
26 145 -19 307 -21 498 -4 111 9 159 9 230 -1 162 -23 198 -24 255 -7 34 10
126 20 239 27 101 5 188 12 193 15 22 14 106 1 398 -59 173 -36 365 -70 425
-76 171 -17 717 -34 850 -26 397 22 840 125 1085 251 206 107 295 192 325 314
26 105 70 204 169 385 261 476 304 577 293 694 -6 53 -4 56 143 350 111 222
159 308 194 345 123 134 170 323 103 421 -15 22 -27 46 -27 53 0 8 17 51 38
96 l38 81 -28 45 c-34 54 -35 73 -3 130 89 156 106 232 81 354 -9 44 -27 177
-41 294 -28 239 -40 299 -92 457 l-36 110 -99 64 c-127 81 -158 86 -243 40
-33 -18 -104 -54 -157 -80 -125 -61 -165 -102 -223 -226 -26 -54 -67 -124 -92
-157 -45 -57 -46 -60 -55 -159 -8 -90 -16 -116 -65 -232 -101 -236 -128 -275
-246 -355 -56 -37 -87 -82 -87 -126 0 -34 -33 -89 -88 -146 -41 -43 -40 -44
41 -21 68 19 71 16 82 -53 6 -45 6 -53 -27 -227 -11 -60 -17 -119 -14 -131 16
-64 -40 -137 -142 -184 -46 -21 -64 -24 -116 -19 -81 8 -383 -45 -441 -78 -75
-42 -380 -237 -435 -278 -86 -64 -204 -181 -308 -307 -179 -215 -289 -309
-434 -367 -35 -14 -76 -37 -92 -50 -46 -38 -97 -61 -210 -91 l-104 -29 -18 40
c-22 48 -59 84 -116 111 -34 17 -49 33 -73 78 -28 54 -29 57 -12 76 9 10 17
24 17 31 0 7 -32 77 -71 155 -68 135 -72 149 -79 232 -10 115 -22 158 -80 280
-100 213 -188 320 -350 426 -198 130 -433 238 -655 301 -110 32 -474 101 -755
144 -138 21 -425 23 -790 6 -280 -13 -360 -28 -499 -94 -47 -23 -145 -65 -217
-94 -285 -115 -497 -239 -803 -468 -165 -124 -205 -142 -262 -122 -72 25 -449
315 -449 345 0 4 39 12 88 19 177 24 337 53 363 67 48 24 69 61 69 121 0 29
-7 70 -15 90 -19 46 -19 50 5 58 11 4 34 18 50 32 27 23 30 30 30 87 0 84 -23
117 -125 180 -192 118 -409 216 -612 274 -122 35 -196 75 -413 216 -161 106
-162 107 -144 127 29 32 48 39 136 49 99 12 180 47 264 118 72 60 153 163 194
248 70 144 76 385 21 769 -44 309 -56 418 -45 422 20 7 -5 285 -46 503 -22
118 -69 375 -105 570 -36 195 -81 420 -101 500 -57 232 -201 596 -323 820 -99
181 -119 222 -167 349 -51 133 -81 176 -142 203 -64 29 -113 19 -252 -48 -98
-48 -126 -57 -186 -61 -94 -7 -144 16 -284 129 -115 93 -184 162 -223 225 -15
23 -36 48 -49 57 -44 31 -122 15 -186 -39 -53 -45 -64 -77 -73 -215 -6 -89
-15 -147 -32 -200 -54 -162 -45 -282 33 -475 57 -143 75 -223 89 -405 6 -74
17 -169 23 -210 19 -114 15 -377 -6 -485 -26 -131 -69 -258 -141 -413 -155
-337 -202 -563 -192 -928 4 -156 10 -217 26 -283 27 -107 81 -209 148 -282 84
-91 93 -119 91 -295 -3 -174 1 -188 105 -356 35 -57 93 -157 128 -223 80 -150
182 -259 297 -319 50 -26 77 -47 88 -68 9 -17 61 -108 116 -202 118 -201 168
-298 168 -325 0 -65 109 -349 215 -561 124 -246 311 -429 591 -576 126 -66
167 -93 284 -185 163 -128 296 -197 488 -254 141 -41 154 -54 200 -180 74
-205 187 -369 421 -608 96 -97 172 -180 169 -183 -4 -3 -31 -1 -61 5 -38 7
-76 7 -122 0 -123 -20 -168 4 -229 121 -51 96 -63 101 -174 69 -65 -18 -112 0
-189 73 l-62 61 38 28 c48 34 57 59 36 99 -18 35 -138 133 -223 183 -153 90
-286 125 -522 137 -222 13 -304 33 -418 106 -30 19 -77 44 -106 54 -79 30
-142 74 -235 164 -250 243 -549 684 -860 1273 l-112 211 -24 169 c-29 203 -47
288 -135 638 -38 149 -80 331 -95 407 -55 293 -149 538 -331 859 -68 120 -70
140 -21 259 43 105 82 248 98 359 16 112 6 302 -21 411 -12 47 -35 150 -51
230 -16 80 -52 229 -80 331 -96 361 -123 572 -100 802 20 196 25 473 11 585
-22 188 -70 355 -152 536 -27 60 -57 141 -65 180 -18 86 -13 228 16 396 11 63
22 138 26 166 l6 51 172 -64 c95 -36 339 -121 542 -189 341 -115 378 -126 475
-134 185 -15 246 -36 501 -168 180 -94 306 -143 469 -182 52 -13 98 -28 102
-33 3 -6 4 -34 1 -62 -5 -47 -3 -54 18 -69 l24 -17 38 81 c21 44 41 80 45 80
4 0 6 -31 4 -70 -3 -54 -1 -70 9 -70 34 0 199 21 244 31 81 17 206 60 256 86
82 44 83 48 84 368 0 314 -5 347 -71 438 -50 69 -119 108 -352 198 -114 44
-250 103 -302 130 -119 63 -510 301 -510 311 0 4 24 47 54 95 29 49 65 109 79
135 38 66 74 85 177 91 102 6 155 25 273 100 111 71 171 86 247 64 58 -17 78
-12 104 25 21 30 20 79 -4 163 -11 39 -63 241 -116 450 -136 545 -204 756
-317 981 l-45 89 135 155 c555 639 707 838 853 1111 80 150 125 215 178 259
44 36 44 36 66 -86 20 -115 37 -154 87 -209 29 -31 45 -61 58 -108 34 -125 92
-196 261 -321 153 -114 163 -110 244 103 47 123 73 233 96 414 11 87 20 164
20 171 0 18 258 118 390 151 134 34 204 68 260 125 l45 46 235 -77 c129 -42
273 -85 320 -94 108 -23 265 -23 346 -1 34 9 76 16 94 16 24 0 44 10 75 39 27
24 60 44 87 50 23 6 113 30 199 52 l157 41 -14 51 c-7 28 -11 53 -9 55 2 2 35
10 73 17 81 16 140 44 275 131 l97 64 46 -45 c60 -57 60 -61 -49 -291 -47 -98
-95 -210 -107 -249 -37 -116 -89 -204 -165 -281 -38 -39 -83 -78 -100 -86 -29
-15 -32 -15 -128 42 -163 95 -198 102 -417 75 -166 -21 -288 -46 -446 -94 -71
-22 -186 -51 -255 -66 -364 -76 -412 -98 -629 -279 -52 -43 -142 -125 -200
-181 -58 -56 -124 -116 -148 -134 -56 -44 -92 -111 -59 -111 7 0 21 -6 31 -13
16 -12 -10 -34 -244 -211 -144 -109 -263 -196 -264 -194 -2 1 -15 70 -30 152
-56 305 -77 352 -209 467 -40 34 -82 72 -95 83 -43 38 -44 4 -2 -64 22 -36 39
-70 40 -76 0 -7 -21 -35 -46 -63 -51 -57 -42 -66 35 -31 69 32 107 27 153 -19
38 -38 39 -42 38 -103 -1 -35 1 -113 6 -175 l7 -112 -32 -32 -32 -32 -84 7
c-139 11 -166 -15 -187 -178 -7 -60 -16 -96 -24 -96 -6 0 -30 7 -53 15 -40 14
-41 14 -41 -9 0 -13 -9 -68 -20 -122 -16 -73 -20 -128 -18 -209 2 -79 -3 -138
-17 -210 -26 -133 -28 -519 -3 -663 27 -155 33 -161 226 -290 227 -150 241
-156 377 -158 64 -1 136 -9 174 -18 125 -33 205 -32 451 9 578 95 782 186
1130 504 54 50 98 81 118 86 34 7 97 50 170 117 29 27 37 38 23 34 -12 -3 -31
-9 -43 -12 l-23 -7 20 22 c15 17 28 22 52 19 30 -4 33 -2 33 23 0 14 3 46 6
71 5 37 11 46 36 57 20 9 48 42 84 97 29 45 119 165 200 266 240 298 283 372
569 973 257 539 307 633 405 765 49 65 93 121 98 124 11 6 22 -9 178 -233 43
-63 110 -146 147 -183 42 -43 65 -73 61 -81 -23 -39 -123 -157 -219 -258 -161
-169 -283 -347 -406 -594 -87 -174 -143 -226 -219 -204 l-40 12 49 28 c40 23
54 39 76 84 14 31 25 60 23 65 -2 5 -43 -41 -93 -103 -98 -122 -153 -183 -320
-357 -119 -124 -167 -183 -264 -328 -61 -91 -114 -192 -106 -200 2 -2 20 6 39
18 42 25 86 78 149 176 25 39 65 93 89 121 24 27 70 83 101 125 55 73 185 204
193 195 3 -2 -18 -53 -46 -112 -28 -60 -64 -147 -81 -194 -46 -130 -84 -201
-155 -286 -82 -99 -85 -105 -94 -187 -9 -81 -26 -109 -88 -149 -47 -30 -207
-87 -217 -77 -3 3 0 39 6 81 11 75 8 107 -9 107 -5 0 -19 -38 -32 -85 -24 -92
-43 -125 -198 -335 -114 -154 -262 -329 -314 -372 -24 -19 -125 -90 -225 -158
-100 -67 -208 -145 -240 -172 -94 -81 -95 -82 -353 -75 -236 6 -452 -3 -607
-23 l-93 -13 0 -222 c0 -253 7 -232 -97 -284 -57 -28 -93 -67 -93 -99 0 -5 20
-18 45 -29 l44 -19 -30 -63 -29 -64 27 -78 c25 -71 27 -81 15 -123 -17 -66
-15 -146 8 -271 11 -60 20 -124 20 -142 0 -18 2 -33 5 -33 3 0 32 11 65 24 33
14 60 20 60 15 0 -22 -22 -49 -50 -64 -36 -19 -60 -62 -60 -109 0 -124 50
-391 141 -761 69 -278 72 -289 105 -303 43 -20 111 -91 173 -180 32 -48 91
-127 130 -177 67 -85 141 -214 141 -246 0 -8 -18 -24 -39 -35 l-40 -19 26 -60
c71 -168 154 -255 378 -394 55 -34 125 -80 155 -101 135 -98 287 -104 480 -21
193 83 248 121 176 121 -20 0 -36 2 -36 4 0 2 29 59 64 127 60 116 68 139 120
354 30 127 67 298 81 380 16 91 32 154 40 160 8 6 53 33 101 60 151 86 235
179 269 297 15 51 20 58 53 69 57 18 144 64 174 92 67 62 79 141 49 315 -17
99 -21 156 -19 285 3 191 -3 215 -89 359 -64 105 -95 173 -111 238 -19 78 -24
182 -13 250 10 63 9 69 -35 210 -25 80 -57 172 -71 205 -34 81 -100 180 -157
235 -25 24 -46 49 -46 55 0 23 100 214 191 365 120 200 190 338 224 442 33 99
82 200 138 283 184 273 733 1021 871 1185 208 248 640 694 694 717 29 12 92
16 297 18 489 4 853 67 1095 190 47 24 112 51 145 60 123 35 241 110 363 232
89 89 112 129 112 195 0 71 -17 113 -61 153 -119 107 -324 139 -475 74 -94
-40 -934 -239 -1011 -239 -46 0 -145 22 -136 30 4 4 176 99 382 210 206 111
393 213 415 227 141 88 325 260 436 408 86 115 130 187 220 363 79 155 93 212
70 281 -11 33 -10 42 6 72 27 51 23 139 -10 218 -59 145 -154 254 -289 333
-96 56 -103 63 -121 115 -24 69 -61 111 -124 140 -32 14 -64 29 -72 33 -13 5
-197 284 -199 300 -1 3 31 11 69 18 39 7 97 23 130 36 137 56 305 198 355 301
24 47 27 64 23 124 -3 61 -8 76 -42 125 -21 30 -61 73 -89 95 -110 84 -168
102 -637 201 -134 29 -254 57 -265 62 -42 23 -204 134 -280 193 -98 77 -237
220 -289 299 -22 33 -60 107 -86 165 -26 58 -80 176 -120 261 -39 85 -79 184
-87 220 -34 151 -104 384 -148 495 -194 493 -526 960 -851 1197 -37 26 -123
83 -191 126 -178 110 -273 189 -361 301 -42 53 -109 131 -150 175 l-74 78 293
160 c162 87 368 198 459 246 170 90 179 97 318 248 40 44 44 46 84 41 62 -8
96 12 142 85 46 74 55 126 32 194 -9 27 -20 83 -25 125 -16 124 -41 169 -95
169 -46 0 -75 -25 -82 -70 l-6 -38 -40 14 c-22 8 -66 14 -97 14 -60 0 -95 -14
-191 -75 -68 -44 -121 -46 -199 -10 -42 20 -52 29 -46 43 4 9 22 55 40 102 18
47 77 191 132 320 55 129 106 253 113 274 14 43 14 47 11 421 -2 169 2 309 12
401 9 89 11 163 6 200 -15 110 -124 285 -184 296 -14 3 -46 1 -71 -4 -25 -6
-48 -8 -50 -5 -3 2 6 29 20 60 26 59 54 82 91 74 21 -4 22 -2 14 22 -25 64
-75 144 -113 179 -50 46 -138 99 -238 143 -95 42 -204 50 -288 20z m742 -800
c12 -4 3 -14 -38 -38 l-55 -32 -16 35 c-14 29 -15 37 -4 43 13 9 81 4 113 -8z
m-624 -53 c8 -39 7 -48 -6 -53 -20 -7 -52 23 -52 49 0 27 30 71 40 59 4 -4 12
-29 18 -55z m-75 -417 c64 -71 67 -130 15 -275 l-22 -60 -33 100 c-20 62 -32
120 -33 155 0 57 15 119 30 119 4 0 24 -18 43 -39z m-500 -313 c-10 -18 -63
-6 -102 22 -36 26 -40 33 -31 50 10 19 14 18 74 -23 35 -23 62 -45 59 -49z
m-593 -33 c0 -19 -5 -19 -36 2 -18 11 -22 18 -13 24 17 10 49 -7 49 -26z m50
-65 c-12 -16 -26 -30 -31 -30 -5 0 -9 14 -9 30 0 27 3 30 31 30 l30 0 -21 -30z
m1000 -29 c15 -29 12 -50 -12 -89 l-22 -35 -12 34 c-7 19 -13 45 -13 60 -2 45
39 66 59 30z m-1046 -127 c85 -50 88 -61 14 -74 -40 -8 -183 2 -194 13 -7 6
78 105 91 106 6 0 45 -20 89 -45z m1006 -64 c9 -17 8 -20 -9 -20 -22 0 -35 15
-26 30 10 16 23 12 35 -10z m-1206 -88 c6 -10 -46 -162 -55 -162 -9 0 -29 49
-29 71 0 53 64 122 84 91z m626 -588 c19 -22 30 -44 28 -57 -2 -13 -34 -45
-80 -81 -86 -65 -97 -65 -125 1 -17 42 -17 80 3 179 l16 82 63 -44 c35 -24 77
-60 95 -80z m-5338 -989 c0 -13 -50 -51 -57 -43 -8 7 36 59 47 56 5 -2 10 -8
10 -13z m1939 -591 c11 -13 8 -19 -17 -39 -20 -16 -33 -20 -41 -13 -9 7 -7 17
9 39 25 33 31 35 49 13z m-159 -41 c8 -10 18 -26 21 -36 7 -20 -17 -87 -31
-87 -24 0 -41 30 -47 83 -7 56 -7 57 17 57 14 0 32 -8 40 -17z m130 -151 c-4
-50 12 -82 43 -82 11 0 9 13 -7 61 -11 33 -18 62 -15 65 4 4 103 -40 116 -52
2 -2 -4 -31 -14 -65 -12 -44 -14 -64 -6 -69 6 -3 62 -12 124 -18 63 -7 136
-19 163 -27 70 -21 95 -19 160 16 74 39 111 50 142 42 23 -5 24 -9 20 -62 -3
-34 -22 -101 -50 -171 -26 -66 -57 -169 -72 -240 -14 -69 -36 -156 -50 -195
l-24 -70 -7 54 c-7 50 -9 53 -22 35 -39 -52 -156 -309 -196 -431 -43 -133 -88
-325 -102 -445 -6 -48 -11 -58 -26 -58 -18 0 -19 13 -19 308 l0 308 -38 17
c-48 21 -57 41 -77 164 -9 54 -43 235 -76 403 -66 333 -69 377 -30 466 18 39
56 94 66 94 1 0 0 -22 -3 -48z m1728 -68 c98 -18 805 -36 925 -23 173 18 363
-33 566 -153 169 -100 865 -590 1069 -752 266 -212 323 -264 355 -325 28 -53
71 -178 184 -536 46 -143 123 -307 285 -610 177 -328 238 -409 438 -580 150
-129 240 -186 623 -397 l80 -44 13 -107 13 -108 -40 -48 c-23 -27 -41 -52 -41
-56 0 -3 12 -22 26 -40 19 -26 24 -41 20 -62 -8 -36 3 -49 89 -106 126 -83
237 -179 444 -382 237 -233 372 -385 396 -449 16 -41 16 -48 0 -103 -22 -77
-78 -183 -126 -237 -21 -24 -78 -71 -126 -105 -48 -33 -153 -110 -233 -171
-87 -67 -181 -129 -239 -157 -103 -51 -97 -50 -306 -43 -109 4 -162 -15 -236
-85 -27 -25 -52 -45 -56 -45 -4 0 -63 -9 -130 -18 l-121 -18 94 100 c119 126
155 195 190 361 14 65 26 120 27 121 1 1 73 18 160 37 234 53 310 95 362 201
35 72 44 178 20 230 -48 101 -180 196 -274 196 -71 0 -177 26 -260 64 -42 19
-107 41 -146 50 -43 10 -88 29 -118 49 -59 41 -411 406 -434 450 -20 39 -31
216 -33 512 -1 319 -20 450 -85 590 -38 82 -672 1021 -844 1250 -145 194 -367
437 -486 535 -217 177 -530 334 -760 381 -137 27 -375 42 -422 25 -33 -12
-110 -45 -122 -53 -2 -1 15 -27 38 -57 22 -30 41 -57 41 -59 0 -2 -54 -28
-120 -58 -66 -30 -120 -57 -120 -61 0 -4 20 -23 45 -44 25 -20 45 -39 45 -43
0 -3 -23 -19 -50 -34 l-50 -28 0 -92 0 -92 65 -54 c36 -30 62 -57 58 -61 -4
-4 -29 -15 -55 -24 -93 -34 -208 -168 -208 -242 0 -24 11 -41 50 -77 56 -52
62 -75 31 -136 l-20 -38 34 -33 c56 -54 69 -103 61 -227 -7 -116 0 -163 56
-392 26 -107 29 -131 20 -170 -10 -45 -9 -46 83 -231 87 -174 93 -190 92 -245
0 -78 12 -101 126 -226 219 -243 342 -336 637 -484 326 -163 507 -233 710
-276 92 -19 139 -22 375 -23 149 -1 278 -5 288 -10 29 -14 231 -271 301 -383
51 -83 52 -179 2 -288 -49 -108 -162 -183 -364 -241 -74 -21 -95 -24 -107 -14
-26 21 -17 49 24 74 46 30 53 39 45 60 -5 13 -25 16 -118 16 -86 0 -120 5
-151 19 -58 26 -92 66 -110 126 -32 110 -118 240 -217 330 -206 185 -581 393
-1036 574 -208 83 -313 145 -432 257 -72 67 -136 109 -278 184 -103 54 -141
106 -292 399 -74 144 -171 319 -214 389 -186 298 -255 416 -333 574 -113 226
-217 358 -284 358 -38 0 -50 -26 -58 -118 l-6 -73 -47 45 c-36 35 -48 53 -48
76 0 16 63 204 141 417 210 579 261 727 365 1073 52 173 97 321 99 328 6 16
43 15 145 -4z m1053 -896 l89 -33 -7 -75 c-7 -70 -17 -94 -39 -87 -14 4 -155
215 -149 221 9 9 12 9 106 -26z m-174 -285 c-10 -11 -15 -11 -30 3 -15 13 -16
20 -8 31 10 11 15 11 30 -3 15 -13 16 -20 8 -31z m234 -304 c58 -30 247 -158
247 -167 0 -16 -56 -60 -107 -86 -59 -29 -73 -49 -73 -100 l0 -36 57 0 c66 0
93 -18 93 -62 0 -44 -16 -66 -69 -92 l-48 -25 -7 -93 c-6 -83 -4 -99 15 -142
20 -46 21 -103 2 -152 -23 -59 -114 188 -154 416 -33 188 -38 560 -8 560 5 0
29 -10 52 -21z m1377 -1376 c87 -105 160 -316 182 -530 10 -98 10 -116 -3
-124 -8 -6 -61 -8 -119 -7 -126 4 -170 22 -301 120 -47 35 -136 95 -197 134
-61 38 -110 72 -109 76 2 9 498 367 509 367 3 1 21 -16 38 -36z m-4854 -300
c3 -21 12 -69 20 -106 18 -86 18 -87 -5 -87 -15 0 -22 17 -39 106 -12 59 -19
110 -16 115 13 22 33 7 40 -28z m-68 -225 c5 -19 5 -34 0 -37 -13 -5 -17 1
-24 37 -9 43 13 42 24 0z m175 -210 c-3 -18 -9 -44 -13 -57 l-6 -24 -33 29
c-26 23 -32 35 -27 51 7 22 23 30 63 32 20 1 22 -2 16 -31z m89 7 c18 -39 1
-42 -35 -7 l-32 32 28 0 c20 0 31 -7 39 -25z m-35 -143 l42 -34 -47 -40 -46
-41 -13 29 c-13 31 -17 116 -7 127 8 8 18 2 71 -41z m-287 -272 c15 -31 40
-94 54 -141 14 -47 39 -106 56 -132 33 -52 102 -101 165 -118 37 -11 160 -7
171 4 3 3 -5 51 -17 108 l-22 103 34 28 c18 15 36 25 39 20 4 -4 17 -33 30
-65 23 -58 24 -59 54 -52 36 9 45 9 108 -1 64 -9 86 -2 154 52 119 93 223 120
422 108 111 -6 112 -7 112 -32 0 -21 -241 -494 -603 -1180 -100 -191 -153
-248 -291 -317 -107 -53 -137 -55 -234 -19 -86 32 -107 53 -126 129 -21 85
-37 264 -25 286 15 28 12 59 -42 447 -28 202 -65 481 -83 620 l-33 252 25 -22
c13 -13 37 -48 52 -78z m5846 -887 c6 -33 3 -40 -26 -65 -17 -16 -34 -27 -37
-24 -8 8 38 137 47 131 5 -3 12 -22 16 -42z m-897 -782 c12 -8 13 -12 1 -26
-12 -14 -15 -14 -28 -1 -8 8 -11 19 -8 25 9 13 16 14 35 2z m-3379 -552 c0
-15 -44 -89 -52 -89 -13 0 -9 19 12 60 19 38 40 53 40 29z m1750 -607 c0 -15
-45 -50 -72 -57 -35 -9 -37 7 -3 40 23 24 75 35 75 17z m-120 -70 c0 -8 -91
-65 -96 -60 -2 2 6 18 16 36 15 25 27 32 50 32 16 0 30 -3 30 -8z m1520 -110
c0 -19 -142 -293 -190 -366 -28 -43 -94 -123 -148 -178 l-96 -99 -94 186 -93
186 21 33 c73 119 228 199 435 225 50 6 101 13 115 15 45 6 50 6 50 -2z
m-1761 -51 c-35 -44 -45 -46 -35 -7 5 22 55 61 65 51 1 -2 -12 -22 -30 -44z
m3009 -216 c25 -55 12 -88 -48 -124 -52 -31 -59 -28 -49 22 4 23 8 45 9 49 0
11 61 78 69 78 4 0 13 -11 19 -25z m-2879 -669 c17 -20 -13 -43 -34 -26 -12
10 -13 16 -4 26 6 8 15 14 19 14 4 0 13 -6 19 -14z m-200 -206 c28 -96 91
-202 199 -333 35 -43 62 -80 60 -83 -15 -14 -248 -117 -318 -140 -75 -24 -101
-27 -222 -28 -75 -1 -139 2 -143 6 -4 3 51 120 122 260 l128 253 75 62 c41 34
77 59 81 55 3 -4 11 -27 18 -52z m-4109 18 c58 -19 75 -31 156 -112 50 -50
116 -112 145 -138 59 -50 101 -121 119 -203 10 -44 32 -543 25 -556 -2 -3 -42
-22 -89 -43 l-85 -38 -78 49 c-43 28 -98 60 -123 73 -92 46 -140 111 -159 217
-6 33 -22 96 -35 140 -19 68 -21 83 -9 98 20 28 16 53 -17 124 -50 106 -50
191 2 359 19 63 19 63 51 57 18 -4 61 -16 97 -27z m5240 -737 c0 -14 -18 -23
-30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m-1126 -481 c-6 -6 -15 -6 -24 0
-13 9 -13 11 0 20 18 11 39 -5 24 -20z m-2143 -3255 c35 -73 40 -97 133 -620
142 -797 174 -1004 178 -1170 5 -177 -1 -475 -9 -475 -16 1 -199 263 -240 343
-94 181 -162 477 -213 922 -6 55 -22 150 -35 210 -36 169 -48 311 -42 480 9
204 20 240 105 325 77 78 80 77 123 -15z m3639 -830 c0 -5 -8 -15 -18 -22 -15
-9 -21 -7 -31 9 -10 17 -9 23 5 40 17 18 19 18 31 2 7 -10 13 -23 13 -29z
m-6530 -555 l25 -20 -50 -44 c-33 -29 -52 -40 -57 -32 -13 19 -9 64 9 100 17
38 22 37 73 -4z m-87 -127 c19 -33 -59 -87 -88 -63 -14 12 -13 16 11 42 26 26
67 37 77 21z m-8 -143 c10 -16 -13 -20 -39 -9 -38 18 -35 41 4 27 17 -5 32
-14 35 -18z m3425 -616 c0 -8 -4 -14 -9 -14 -11 0 -22 26 -14 34 9 9 23 -3 23
-20z m-1168 -546 c4 -7 9 -25 12 -40 8 -37 56 -82 122 -114 47 -23 55 -30 50
-49 -12 -46 -37 -95 -49 -95 -37 2 -122 28 -129 41 -7 13 -78 277 -78 292 0
10 64 -20 72 -35z m299 12 c35 -19 17 -65 -33 -83 -30 -11 -40 65 -10 82 21
13 20 13 43 1z m9099 -1295 c0 -24 -9 -45 -29 -67 -16 -18 -32 -30 -36 -27 -6
7 51 129 60 129 3 0 5 -16 5 -35z m-4310 -781 c149 -34 133 -23 164 -124 16
-49 33 -90 40 -90 6 0 43 7 83 17 67 15 74 15 116 -1 24 -9 65 -30 90 -46 46
-29 47 -31 47 -79 l0 -49 123 -105 c137 -117 248 -229 294 -297 63 -93 132
-243 139 -301 l6 -56 -77 -32 c-117 -49 -135 -50 -181 -8 -54 47 -103 70 -211
97 -50 12 -98 27 -106 33 -20 16 -57 133 -57 180 0 41 11 70 41 103 19 21 19
21 -48 52 -88 40 -162 58 -331 82 -225 31 -374 62 -425 87 l-47 23 23 38 c49
80 60 141 36 207 -10 28 -7 40 41 135 29 58 61 115 73 128 25 27 72 29 167 6z
m-640 -236 c0 -5 -9 -4 -20 2 -11 6 -18 15 -14 21 6 11 34 -7 34 -23z m59 -82
c19 -23 31 -50 31 -68 0 -26 -3 -29 -22 -23 -39 12 -68 35 -68 54 0 20 20 84
25 79 2 -2 17 -20 34 -42z m4739 -284 c2 -13 -6 -54 -17 -91 -32 -104 -110
-181 -481 -471 -236 -184 -323 -213 -645 -214 -104 -1 -211 3 -238 7 l-47 8
190 186 c112 109 215 220 250 268 106 147 224 231 374 270 115 29 451 65 571
61 34 -1 41 -5 43 -24z m-7808 -1282 c134 -67 306 -198 560 -426 69 -61 147
-134 173 -162 48 -49 48 -50 28 -64 -20 -14 -20 -16 -5 -99 20 -105 43 -144
103 -172 56 -27 95 -93 106 -179 6 -57 6 -58 -27 -78 -40 -24 -71 -25 -129 -5
-24 9 -86 22 -137 31 -157 25 -215 50 -336 143 -103 78 -323 278 -338 304 -6
12 -16 42 -23 67 -10 38 -10 50 1 65 22 29 17 48 -20 84 l-35 34 15 -48 c22
-75 19 -80 -27 -48 -35 24 -51 49 -109 168 -61 125 -74 171 -41 151 6 -4 43
-54 82 -111 40 -58 74 -103 76 -101 3 2 -16 55 -40 118 -54 137 -67 196 -67
295 l0 76 63 -5 c43 -4 82 -15 127 -38z m8067 13 c7 -20 -62 -98 -107 -120
-77 -40 -87 -32 -41 35 45 67 59 80 98 92 33 10 45 8 50 -7z m-3219 -215 c6
-6 12 -14 12 -19 0 -10 -53 -10 -90 0 l-24 7 24 17 c24 17 52 15 78 -5z
m-5171 -125 c32 -49 71 -109 86 -135 20 -34 36 -50 63 -58 l36 -12 -6 -52 c-4
-28 -9 -70 -12 -92 -5 -38 -4 -42 19 -48 39 -9 86 -54 113 -108 l25 -49 -38 6
c-21 4 -145 43 -276 87 -206 70 -241 85 -260 111 -12 16 -39 46 -59 66 -34 33
-39 44 -47 111 -5 41 -8 76 -6 78 6 7 215 -51 215 -59 0 -4 -12 -16 -27 -27
l-27 -20 33 -16 c49 -26 71 -18 98 37 16 32 30 47 43 47 11 0 20 3 20 8 0 4
-16 47 -35 96 -57 147 -45 155 42 29z m1371 -589 c103 -86 187 -161 187 -167
0 -7 -16 -21 -36 -31 -71 -38 -115 -4 -263 208 -77 111 -97 146 -81 146 3 0
89 -70 193 -156z m391 7 c15 -44 14 -61 -2 -61 -8 0 -18 20 -24 47 -17 74 2
84 26 14z m7141 -138 c0 -38 -16 -61 -30 -47 -8 8 -6 20 7 42 10 18 19 32 21
32 1 0 2 -12 2 -27z m-7185 -320 c-8 -19 -15 -37 -15 -39 0 -2 -4 -4 -10 -4
-14 0 -13 11 5 54 9 20 20 34 25 31 6 -4 4 -20 -5 -42z m7292 15 c16 -13 16
-28 2 -65 l-11 -28 -28 55 c-16 30 -29 58 -30 62 0 9 49 -9 67 -24z m-8707
-153 c11 -13 7 -15 -30 -15 -37 0 -41 2 -30 15 7 8 20 15 30 15 10 0 23 -7 30
-15z m860 -16 c0 -11 -27 -12 -34 0 -3 4 -3 11 0 14 8 8 34 -3 34 -14z m7315
-51 c-10 -31 -25 -47 -25 -26 0 24 11 48 22 48 5 0 6 -10 3 -22z m540 -18 c8
-13 -26 -59 -43 -60 -8 0 -12 3 -10 8 32 65 40 73 53 52z m-8584 -50 c20 -11
25 -35 9 -45 -5 -3 -16 8 -25 25 -17 32 -14 36 16 20z m3530 -31 c12 -23 10
-26 -42 -54 -24 -13 -29 -13 -40 1 -11 14 -8 20 21 45 42 34 46 35 61 8z
m4724 -87 c3 -12 1 -32 -5 -43 -10 -17 -14 -18 -28 -8 -66 50 -76 75 -46 118
l16 23 28 -34 c16 -18 31 -43 35 -56z m-4850 -22 c39 -15 21 -30 -36 -30 -36
0 -49 4 -49 14 0 13 16 22 47 25 6 0 24 -3 38 -9z m4790 -113 c-23 -36 -38
-47 -77 -60 -43 -14 -50 -14 -55 -1 -3 8 -4 17 -2 19 11 10 155 92 158 89 2
-2 -9 -23 -24 -47z m-5107 -14 c2 -20 -2 -32 -12 -36 -13 -5 -16 2 -16 34 0
48 23 50 28 2z m4917 -191 c-3 -3 -13 2 -22 11 -16 15 -15 18 3 38 l19 21 3
-32 c2 -17 1 -35 -3 -38z m-547 9 c10 -6 10 -10 -1 -20 -18 -14 -48 -4 -40 15
6 16 21 18 41 5z m362 -130 c0 -5 -12 -19 -27 -31 -32 -25 -45 -10 -17 21 18
20 44 26 44 10z m-145 -557 c15 -20 34 -56 41 -80 l13 -45 -30 18 c-17 10 -42
30 -54 45 -20 24 -22 33 -14 60 5 18 9 34 9 35 0 9 11 -1 35 -33z m-422 -106
c-20 -35 -53 -50 -53 -25 0 15 46 56 63 57 5 0 0 -15 -10 -32z m240 -7 l29
-18 -107 -77 c-59 -43 -109 -75 -112 -72 -3 3 -2 18 2 33 6 27 130 153 150
153 6 0 23 -9 38 -19z"
      />
      <path
        d="M11111 22710 c0 -36 7 -88 17 -116 16 -45 107 -204 117 -204 5 0
-114 349 -126 370 -5 10 -8 -8 -8 -50z"
      />
      <path
        d="M9097 5286 c-6 -14 66 -50 77 -38 12 11 5 22 -24 37 -36 19 -46 19
-53 1z"
      />
      <path
        d="M25905 29736 c-22 -7 -87 -37 -145 -66 -124 -64 -192 -126 -238 -219
-37 -74 -40 -91 -13 -84 32 8 62 -16 88 -73 14 -31 23 -58 20 -61 -3 -3 -27 0
-53 5 -87 19 -141 -24 -212 -167 -36 -73 -37 -77 -37 -190 0 -64 6 -204 13
-311 8 -131 10 -252 5 -370 -5 -96 -5 -198 -2 -227 5 -32 50 -152 119 -314 61
-144 124 -296 140 -338 15 -42 31 -84 35 -93 6 -14 -4 -23 -46 -43 -69 -32
-131 -32 -181 -2 -20 12 -58 35 -84 52 -59 36 -149 45 -217 22 l-45 -15 -6 38
c-11 71 -96 97 -134 43 -26 -39 -30 -52 -42 -147 -6 -47 -17 -100 -25 -120 -8
-19 -15 -50 -15 -68 0 -43 38 -122 83 -173 32 -37 38 -40 83 -38 71 3 100 -12
144 -78 56 -82 100 -113 325 -234 268 -144 699 -377 704 -381 2 -2 -32 -41
-75 -86 -44 -46 -106 -118 -138 -160 -92 -124 -150 -170 -461 -370 -318 -205
-606 -550 -830 -999 -114 -227 -181 -412 -249 -685 -28 -114 -52 -178 -126
-340 -50 -109 -105 -228 -121 -263 -87 -190 -248 -354 -539 -548 l-105 -70
-155 -32 c-514 -107 -597 -129 -685 -186 -118 -76 -185 -174 -185 -272 0 -77
27 -129 113 -220 117 -123 250 -199 393 -224 38 -7 70 -13 72 -15 4 -2 -142
-239 -173 -280 -13 -17 -37 -34 -52 -37 -54 -11 -125 -68 -151 -122 -13 -27
-29 -61 -35 -74 -7 -13 -49 -47 -94 -75 -113 -70 -164 -115 -219 -192 -93
-131 -129 -275 -90 -362 15 -33 16 -46 7 -73 -25 -71 -15 -109 68 -276 131
-262 241 -415 420 -585 140 -133 224 -187 651 -418 206 -111 379 -206 383
-210 5 -4 -23 -14 -62 -20 -55 -10 -86 -11 -143 -1 -170 28 -869 199 -939 230
-148 66 -353 34 -476 -73 -46 -40 -63 -82 -63 -153 0 -59 13 -86 73 -155 110
-127 274 -237 412 -276 39 -11 96 -34 127 -52 69 -38 188 -82 295 -109 208
-53 602 -94 873 -89 192 3 196 3 242 -22 51 -27 352 -333 577 -586 143 -160
445 -556 769 -1011 269 -377 284 -402 342 -568 42 -118 107 -248 223 -443 96
-160 197 -352 197 -373 0 -6 -28 -41 -63 -78 -82 -87 -135 -191 -202 -396
l-53 -165 11 -71 c14 -89 -3 -236 -37 -319 -12 -32 -49 -101 -80 -154 -95
-158 -101 -183 -98 -376 2 -131 -2 -191 -19 -295 -19 -114 -20 -137 -9 -189
17 -85 67 -135 185 -185 86 -37 90 -40 97 -76 14 -64 69 -154 126 -206 31 -28
102 -78 158 -111 91 -53 104 -64 109 -91 3 -18 12 -75 21 -127 16 -101 78
-373 119 -529 17 -61 47 -134 85 -206 33 -62 60 -115 60 -119 0 -3 -16 -6 -35
-6 -19 0 -35 -4 -35 -10 0 -13 175 -100 270 -135 158 -57 306 -41 426 48 33
24 113 76 178 117 189 118 283 218 347 370 l28 65 -40 20 c-35 17 -39 23 -34
45 14 54 73 156 136 234 36 44 89 116 118 160 68 100 111 148 166 183 24 15
49 38 55 52 15 32 121 463 154 621 34 168 59 364 53 424 -4 40 -10 54 -33 72
-55 43 -74 63 -74 78 0 16 1 16 118 -31 8 -4 12 6 12 31 0 19 9 82 20 138 22
116 25 208 9 274 -10 40 -9 53 15 123 l27 78 -26 54 c-13 30 -25 59 -25 65 0
7 18 19 41 28 37 15 40 19 34 44 -10 39 -22 50 -89 84 -103 51 -96 30 -96 283
l0 222 -92 13 c-157 20 -373 29 -595 23 -252 -7 -273 -3 -360 71 -34 28 -151
112 -260 186 -109 74 -223 160 -253 190 -74 73 -201 231 -328 409 -111 155
-119 170 -147 274 -10 37 -22 67 -27 67 -17 0 -19 -30 -8 -105 6 -42 9 -79 6
-82 -10 -10 -171 46 -218 76 -62 39 -76 64 -87 148 -11 84 -15 93 -92 186 -69
81 -112 161 -157 289 -17 48 -54 135 -82 194 -27 59 -48 109 -46 111 9 9 142
-127 199 -202 33 -44 78 -99 99 -122 21 -24 59 -76 85 -115 26 -40 60 -90 76
-110 28 -35 94 -88 110 -88 18 0 -35 98 -124 230 -77 115 -137 187 -286 346
-103 110 -228 251 -277 312 -49 61 -92 110 -94 107 -3 -3 9 -33 26 -68 26 -51
40 -68 77 -88 l46 -26 -41 -12 c-36 -11 -45 -10 -68 5 -58 36 -86 73 -144 189
-129 257 -258 444 -423 615 -55 58 -127 140 -159 182 l-58 77 74 73 c40 41
119 140 175 223 119 173 140 202 146 195 2 -3 43 -57 91 -120 99 -131 149
-225 437 -828 267 -558 315 -638 562 -944 60 -73 138 -177 174 -230 41 -61 75
-102 93 -110 31 -13 40 -40 40 -112 0 -42 0 -42 33 -38 23 3 40 -3 57 -18 l25
-21 -30 6 c-16 3 -39 9 -50 12 -11 3 18 -26 65 -66 50 -43 100 -76 125 -83 26
-8 71 -40 130 -93 359 -322 544 -404 1130 -501 240 -40 321 -41 446 -8 38 9
109 17 174 18 132 2 170 16 333 129 59 41 128 86 155 101 104 61 131 174 131
546 1 206 -3 260 -19 340 -15 71 -19 124 -17 210 3 89 -1 136 -17 207 -12 51
-21 104 -21 117 l0 25 -46 -16 c-25 -9 -49 -12 -53 -7 -4 5 -13 52 -20 104
-19 150 -48 175 -186 164 l-84 -7 -32 32 -32 32 9 102 c4 56 7 135 6 175 -3
73 -3 74 36 113 46 46 84 51 153 19 78 -35 86 -26 35 34 -25 29 -46 56 -46 59
0 4 18 37 41 73 58 96 39 105 -53 24 -97 -83 -144 -136 -173 -190 -23 -45 -59
-191 -91 -370 -9 -52 -18 -97 -19 -98 -2 -2 -121 85 -265 194 -233 177 -259
200 -244 211 10 7 24 13 31 13 31 0 -8 80 -51 105 -17 10 -85 71 -151 135 -66
63 -160 150 -210 191 -208 174 -262 198 -624 274 -69 15 -184 44 -255 66 -153
46 -275 72 -441 93 -215 28 -257 21 -425 -77 -94 -55 -95 -55 -125 -38 -53 29
-169 154 -202 217 -18 34 -49 107 -68 163 -20 56 -71 177 -115 269 -44 92 -80
178 -80 191 0 16 16 39 45 67 l46 42 107 -67 c145 -93 182 -110 264 -126 38
-7 71 -15 73 -16 2 -2 -2 -26 -8 -52 -8 -36 -8 -50 1 -56 9 -5 219 -62 345
-93 22 -5 58 -27 82 -49 31 -29 51 -39 75 -39 18 0 60 -7 94 -16 82 -22 239
-22 346 0 47 10 191 53 320 95 l235 77 46 -45 c58 -59 127 -92 269 -129 119
-31 380 -128 380 -141 0 -31 42 -330 54 -386 29 -136 117 -359 146 -375 35
-19 57 -10 150 57 167 119 241 210 274 337 11 41 27 71 55 101 54 59 59 71 87
203 14 64 26 117 28 119 1 2 21 -13 45 -33 52 -45 103 -120 171 -253 128 -249
301 -474 893 -1156 l95 -109 -38 -77 c-112 -222 -192 -464 -306 -929 -47 -190
-101 -400 -120 -467 -43 -155 -44 -185 -4 -225 23 -23 36 -29 53 -23 121 37
147 31 298 -60 116 -71 189 -95 284 -95 90 0 108 -16 203 -174 45 -75 82 -140
82 -144 0 -4 -19 -19 -42 -33 -461 -283 -509 -309 -754 -402 -281 -107 -337
-142 -392 -244 -48 -91 -56 -163 -48 -442 5 -205 9 -248 24 -273 39 -66 269
-141 504 -163 l67 -6 3 73 3 73 40 -83 c40 -83 40 -83 63 -66 20 14 23 23 20
74 -1 32 0 61 2 63 3 2 48 14 100 27 193 47 285 84 560 226 156 81 255 112
404 124 93 7 135 18 412 110 170 56 414 140 542 187 128 47 235 84 238 81 3
-3 12 -50 19 -104 8 -55 21 -142 30 -194 31 -197 16 -315 -59 -480 -63 -138
-103 -256 -132 -394 -35 -165 -43 -430 -20 -635 34 -303 9 -539 -94 -906 -21
-77 -51 -196 -65 -265 -14 -69 -40 -186 -58 -260 -28 -120 -31 -152 -32 -285
0 -88 6 -179 14 -220 12 -64 74 -262 117 -376 l18 -47 -30 -58 c-17 -33 -67
-129 -112 -214 -142 -271 -202 -444 -267 -765 -20 -99 -67 -302 -105 -451 -66
-257 -110 -475 -125 -629 -8 -79 -31 -129 -193 -425 -267 -489 -530 -875 -751
-1102 -123 -128 -191 -179 -276 -208 -30 -10 -75 -33 -99 -50 -106 -72 -208
-99 -428 -110 -209 -11 -306 -33 -453 -105 -121 -59 -264 -165 -290 -215 -21
-40 -12 -65 36 -99 l38 -28 -62 -61 c-77 -73 -124 -91 -189 -73 -111 32 -123
27 -174 -69 -61 -116 -108 -142 -227 -121 -46 8 -80 8 -121 0 -32 -6 -60 -8
-63 -5 -4 3 57 68 133 144 251 249 374 424 465 666 39 104 63 126 158 151 186
48 349 130 517 262 110 86 171 124 324 206 232 124 398 283 530 507 41 69 175
375 205 466 13 40 27 94 31 120 8 60 32 109 164 334 56 96 110 191 121 212 14
28 33 44 75 64 89 42 191 131 252 221 13 19 54 91 91 161 38 70 92 163 120
207 79 122 94 182 86 336 -8 153 0 179 93 283 110 125 155 251 172 490 24 346
-33 644 -195 1001 -40 88 -82 190 -94 226 -55 167 -81 407 -62 569 6 52 20
185 31 295 22 226 32 270 94 428 75 190 82 298 30 467 -18 60 -28 121 -33 205
-8 132 -19 165 -73 210 -93 78 -189 70 -235 -20 -40 -76 -280 -287 -381 -332
-44 -21 -65 -25 -120 -21 -56 3 -86 12 -187 61 -144 68 -193 78 -257 49 -60
-26 -89 -67 -133 -187 -20 -55 -73 -170 -118 -255 -130 -248 -165 -324 -243
-520 -125 -318 -134 -356 -280 -1135 -30 -165 -67 -361 -81 -435 -21 -111 -26
-172 -28 -340 -3 -171 -10 -247 -40 -460 -85 -598 -51 -797 175 -1020 92 -91
184 -137 297 -150 89 -10 108 -17 137 -49 18 -20 17 -21 -141 -125 -219 -144
-291 -181 -443 -227 -164 -49 -346 -128 -498 -214 -193 -110 -212 -132 -212
-239 0 -48 3 -57 30 -79 16 -14 39 -28 50 -32 24 -8 24 -12 5 -58 -34 -81 -10
-179 52 -211 27 -13 179 -42 363 -67 47 -6 87 -13 89 -15 13 -12 -66 -82 -221
-196 -219 -160 -214 -157 -261 -158 -52 0 -78 15 -257 148 -290 216 -493 334
-774 447 -72 29 -170 71 -217 94 -139 66 -219 81 -499 94 -364 17 -652 14
-790 -7 -285 -43 -635 -109 -745 -140 -157 -45 -301 -102 -453 -181 -319 -167
-429 -272 -552 -530 -64 -134 -79 -185 -89 -296 -8 -88 -12 -103 -70 -219 -34
-69 -66 -129 -72 -135 -14 -14 -11 -47 8 -67 17 -19 16 -22 -12 -76 -24 -45
-40 -63 -77 -82 -62 -34 -95 -66 -110 -107 -7 -18 -19 -33 -28 -33 -29 0 -200
50 -238 69 -20 10 -54 33 -76 50 -21 17 -44 31 -50 31 -29 0 -159 73 -228 128
-42 34 -123 117 -180 184 -210 247 -243 281 -372 378 -53 40 -361 237 -431
276 -61 33 -310 79 -420 76 -82 -2 -95 1 -152 28 -34 17 -75 46 -92 66 -27 31
-31 42 -31 97 0 34 -7 94 -15 132 -27 125 -35 202 -26 250 5 25 11 49 15 52 3
3 32 -2 64 -11 32 -9 62 -16 66 -16 5 0 -9 20 -31 43 -59 64 -75 93 -84 149
-9 57 -24 77 -89 121 -115 77 -139 111 -236 338 -51 118 -59 147 -70 242 -11
106 -12 109 -58 166 -25 33 -66 102 -90 155 -61 130 -96 162 -290 260 -192 97
-194 97 -335 3 l-96 -64 -43 -129 c-49 -150 -64 -228 -92 -489 -12 -104 -26
-206 -32 -225 -16 -51 -13 -167 7 -225 10 -27 35 -80 56 -116 22 -36 39 -75
39 -87 0 -25 -34 -97 -49 -107 -6 -3 7 -41 29 -87 22 -45 40 -88 40 -95 0 -7
-13 -39 -30 -70 -35 -64 -38 -112 -14 -194 24 -81 58 -142 116 -207 42 -47 83
-119 201 -356 133 -270 146 -301 141 -335 -3 -22 -1 -72 6 -112 13 -78 99
-257 273 -568 102 -185 138 -262 177 -388 37 -121 68 -167 158 -233 214 -157
565 -272 1027 -336 221 -31 946 -18 1190 20 50 8 218 41 375 73 216 45 294 57
321 52 20 -5 115 -13 212 -18 96 -6 204 -17 239 -26 65 -18 96 -17 248 5 68
10 119 10 255 0 177 -13 377 -9 525 10 102 14 197 9 585 -30 455 -46 570 -53
710 -46 137 6 303 32 420 66 100 29 115 27 115 -11 0 -35 -139 -605 -175 -719
-13 -41 -26 -87 -29 -102 l-6 -28 -84 65 c-185 143 -382 254 -549 310 -158 53
-266 69 -462 68 -180 0 -305 -15 -500 -59 -410 -91 -688 -269 -1212 -777 -168
-162 -226 -188 -537 -237 -151 -24 -316 -60 -316 -70 0 -4 12 -13 28 -21 19
-10 66 -14 168 -14 128 0 144 -2 164 -20 53 -47 47 -53 -53 -48 l-92 4 -100
-55 c-56 -32 -111 -71 -125 -89 -45 -58 -78 -87 -101 -87 -12 0 -70 22 -129
49 -266 122 -509 171 -843 170 -100 0 -218 -4 -262 -9 -44 -5 -125 -12 -180
-16 l-100 -6 -55 34 c-49 29 -76 62 -250 304 -257 359 -276 382 -358 448 -39
30 -122 106 -186 167 -100 96 -116 108 -116 86 0 -13 -3 -39 -6 -56 -6 -29
-10 -32 -33 -26 -43 11 -66 41 -91 119 -13 41 -33 87 -45 102 l-22 27 -11 -68
c-6 -37 -13 -70 -16 -73 -3 -3 -58 17 -121 43 -64 26 -117 46 -119 44 -1 -2
-8 -53 -15 -114 -7 -60 -15 -127 -18 -147 -5 -33 -3 -39 18 -47 35 -13 76 -61
84 -97 3 -19 0 -57 -9 -92 -9 -33 -16 -73 -16 -89 0 -53 30 -132 74 -196 l44
-64 -49 -29 c-27 -15 -52 -36 -55 -47 -7 -23 17 -61 55 -83 16 -9 38 -33 49
-53 11 -20 34 -49 50 -64 27 -26 33 -27 108 -25 60 2 88 -2 118 -15 l39 -18
-57 80 c-31 44 -56 84 -56 89 0 16 29 35 54 35 14 0 48 -15 76 -32 27 -17 53
-31 55 -31 3 0 -6 18 -20 40 -14 22 -25 42 -25 44 0 3 36 -16 80 -41 73 -41
91 -59 74 -76 -3 -3 -24 1 -46 9 -22 8 -45 14 -50 14 -9 0 -38 -60 -38 -80 0
-9 117 -68 169 -85 19 -6 21 -2 21 37 l0 42 22 -22 22 -22 -22 -23 -23 -24 30
-66 c33 -73 34 -71 -43 -82 -25 -4 -46 -10 -46 -14 0 -3 32 -36 72 -72 l73
-67 -88 -7 c-147 -12 -308 15 -435 75 -52 24 -64 27 -69 15 -15 -40 6 -61 126
-128 115 -65 122 -67 148 -54 72 36 123 26 193 -37 38 -34 52 -39 145 -51 77
-11 105 -29 105 -72 0 -27 -2 -27 -47 -11 -29 10 -36 10 -43 -2 -20 -31 -11
-68 22 -95 l32 -27 12 47 c7 26 15 49 18 52 5 6 95 -86 96 -98 0 -3 -12 -11
-26 -17 -50 -23 -29 -50 111 -145 304 -206 607 -317 862 -317 126 0 270 22
356 54 84 32 103 32 199 0 l77 -25 -39 -111 c-22 -61 -40 -114 -40 -117 0 -21
306 -165 455 -214 155 -51 241 -62 485 -62 302 0 380 15 1200 222 650 165 656
166 750 163 52 -2 183 -3 290 -4 181 0 206 3 346 32 159 34 851 220 932 250
78 30 133 70 214 156 249 265 304 326 355 404 l57 85 -16 44 c-15 41 -15 44 0
38 10 -4 60 -9 112 -12 119 -7 234 14 339 63 101 47 130 71 193 161 69 98 121
145 200 182 35 16 79 40 98 53 31 23 34 29 31 70 l-2 44 80 57 c44 31 100 61
123 68 57 15 108 42 134 71 12 12 45 73 74 134 l52 112 29 -12 c24 -8 35 -8
56 4 15 8 28 20 30 26 5 15 -45 36 -71 29 -12 -4 14 27 62 72 45 43 82 81 82
85 0 3 -7 8 -16 10 -13 2 -11 8 10 27 l26 24 0 -23 c0 -24 0 -24 31 6 39 36
59 75 59 111 0 34 6 43 47 72 48 33 79 94 102 202 11 53 25 108 31 123 5 14 6
26 1 26 -11 0 -58 -47 -88 -87 -19 -26 -54 -41 -260 -111 -247 -84 -303 -99
-303 -81 0 44 78 133 127 145 27 7 27 4 7 150 -6 41 -5 42 30 54 27 8 43 24
63 58 14 26 54 86 87 134 88 126 98 118 41 -32 -19 -50 -35 -94 -35 -96 0 -2
9 -4 20 -4 14 0 27 -15 45 -50 28 -55 45 -61 97 -34 l32 16 -27 20 c-15 11
-27 23 -27 28 0 8 210 64 216 57 3 -2 0 -37 -6 -77 -6 -40 -9 -74 -7 -76 1 -2
23 33 47 77 65 119 112 142 420 204 251 52 297 76 398 215 30 41 103 125 162
185 59 61 159 180 222 265 63 85 146 198 185 250 69 94 71 96 182 154 71 38
123 73 142 95 56 67 218 411 425 900 57 134 106 246 109 249 3 3 44 -11 91
-32 104 -45 101 -47 208 87 82 102 135 155 183 183 38 21 42 31 88 194 37 130
67 285 85 430 16 134 50 708 50 838 0 230 -33 581 -91 967 -16 110 -31 209
-32 220 -3 29 36 32 61 5 11 -12 22 -35 26 -51 9 -48 35 -78 77 -89 22 -6 43
-9 48 -6 4 3 11 61 14 130 3 69 11 134 16 145 12 22 67 37 100 26 19 -6 21
-14 21 -81 l0 -75 31 18 c30 18 133 171 211 313 53 96 104 232 132 350 14 58
47 168 74 245 106 300 129 471 111 840 -13 285 -6 509 32 1090 12 171 18 344
15 385 -6 81 -116 478 -182 656 -91 246 -195 427 -365 634 -111 136 -199 248
-199 254 0 2 89 83 198 179 218 192 386 362 446 450 l39 57 -15 39 -16 40 45
15 c34 12 75 46 179 148 74 73 183 180 242 238 170 166 269 329 375 615 223
603 388 1442 447 2270 9 123 7 228 -10 585 -32 690 -40 796 -101 1305 -51 434
-78 605 -150 953 -16 79 -29 145 -29 147 0 3 -7 5 -16 5 -14 0 -16 -10 -10
-75 3 -41 9 -109 12 -150 5 -64 4 -75 -9 -75 -9 0 -21 16 -28 38 -6 20 -49
129 -94 242 -45 113 -96 243 -113 290 -31 82 -82 262 -82 288 0 8 18 12 56 12
l56 0 -51 40 c-146 115 -215 276 -242 560 -19 208 -64 345 -165 506 -103 164
-187 263 -534 624 -284 296 -324 343 -396 462 -135 223 -274 374 -554 598 -85
69 -241 195 -346 281 -229 187 -295 235 -391 283 -168 84 -309 128 -688 216
-137 32 -302 75 -365 94 -63 20 -166 52 -229 71 -135 42 -314 73 -666 116
-237 28 -277 31 -450 25 -223 -7 -297 -15 -920 -101 -513 -72 -657 -101 -830
-172 -202 -82 -364 -184 -573 -362 -63 -53 -127 -104 -142 -114 -34 -22 -66
-16 -109 21 l-32 27 58 86 c68 100 323 359 481 488 121 99 139 125 118 175 -8
18 -19 37 -25 40 -7 5 -9 30 -5 74 l6 67 -46 63 c-25 34 -46 66 -46 72 0 5 11
17 25 26 29 19 88 16 143 -7 l34 -14 -22 -28 c-26 -33 -24 -39 36 -123 42 -59
50 -65 73 -59 14 3 36 13 47 20 27 17 108 166 95 174 -5 3 -43 26 -85 51 l-75
45 -1 198 c0 194 0 197 -21 197 -20 0 -36 -14 -92 -84 -33 -41 -55 -30 -134
72 -38 48 -94 113 -126 145 -57 57 -57 58 -42 87 36 70 12 133 -85 225 -44 42
-159 185 -179 222 -6 12 -11 52 -11 90 0 79 -6 88 -95 155 -104 77 -270 188
-282 188 -7 0 -37 34 -68 75 -59 81 -115 127 -173 145 -20 6 -42 13 -48 15 -8
2 -9 20 -2 60 11 73 -3 117 -47 150 -23 19 -34 36 -39 64 -9 59 -48 98 -114
117 -32 8 -83 24 -115 35 -65 22 -162 24 -222 5z m-406 -795 c11 -7 11 -15 1
-45 -6 -20 -13 -36 -15 -36 -2 0 -30 15 -61 34 l-57 35 39 6 c21 3 46 8 54 10
8 1 17 3 20 4 3 0 11 -3 19 -8z m571 -66 c0 -27 -32 -58 -52 -50 -15 6 -12 41
9 105 7 21 43 -25 43 -55z m93 -490 c-1 -40 -53 -238 -66 -252 -2 -2 -16 32
-31 75 -45 131 -35 208 36 267 l33 28 14 -34 c8 -19 14 -56 14 -84z m549 -198
c-24 -27 -66 -47 -97 -47 -36 0 -29 12 35 59 49 35 56 38 67 24 11 -14 10 -20
-5 -36z m507 -68 c-27 -21 -39 -24 -39 -10 0 18 31 40 47 34 13 -5 12 -9 -8
-24z m-49 -69 c0 -16 -4 -30 -9 -30 -5 0 -19 14 -31 30 l-21 30 30 0 c28 0 31
-3 31 -30z m-992 -22 c15 -15 15 -65 -1 -102 l-13 -30 -22 36 c-24 39 -27 60
-12 89 12 22 30 25 48 7z m1141 -142 c23 -26 40 -50 37 -53 -2 -3 -36 -9 -75
-14 -71 -9 -154 0 -172 18 -11 11 137 105 157 100 6 -1 30 -24 53 -51z m-1114
-57 c0 -8 -10 -15 -23 -17 -21 -3 -22 -1 -12 18 12 23 35 22 35 -1z m1229
-112 c15 -19 26 -47 26 -63 -1 -33 -24 -81 -33 -67 -15 24 -55 148 -51 155 11
17 34 7 58 -25z m-495 -628 c1 -43 -4 -64 -21 -89 -13 -19 -27 -35 -33 -37 -5
-1 -45 25 -88 58 -71 55 -78 64 -72 88 9 35 27 54 110 116 l70 53 17 -67 c9
-36 17 -91 17 -122z m5191 -934 c13 -14 21 -28 17 -31 -8 -8 -56 28 -57 44 0
19 14 14 40 -13z m-1932 -594 c16 -22 18 -32 9 -39 -8 -7 -20 -3 -40 14 -24
20 -27 27 -17 39 17 21 23 19 48 -14z m172 -33 c-1 -88 -37 -143 -67 -102 -31
43 -4 124 43 124 18 0 24 -5 24 -22z m-162 -134 c36 -43 62 -120 62 -182 0
-25 -24 -169 -54 -321 -30 -152 -64 -331 -76 -398 -26 -156 -32 -171 -75 -189
l-35 -14 0 -174 c0 -95 -3 -234 -7 -309 -5 -124 -8 -137 -25 -137 -12 0 -18 7
-18 24 0 88 -73 395 -131 556 -34 92 -139 311 -168 349 l-18 24 -8 -54 -8 -54
-24 75 c-13 41 -37 136 -53 210 -18 81 -47 179 -75 245 -53 131 -61 199 -22
209 30 7 68 -4 141 -43 65 -35 90 -37 160 -16 27 8 100 20 163 27 62 6 118 15
124 18 7 5 5 26 -6 70 -9 34 -15 63 -13 65 14 12 107 54 120 55 9 0 30 -16 46
-36z m-1536 -329 c106 -357 163 -526 359 -1067 93 -257 169 -482 169 -499 0
-24 -10 -41 -40 -71 -23 -21 -45 -38 -50 -38 -6 0 -10 25 -10 55 0 31 -7 71
-14 90 -12 29 -20 35 -44 35 -38 0 -68 -24 -143 -118 -40 -50 -82 -119 -119
-197 -74 -156 -163 -313 -304 -533 -62 -97 -139 -229 -171 -292 -148 -295
-239 -457 -282 -501 -28 -30 -90 -71 -171 -115 -93 -51 -148 -89 -210 -146
-46 -43 -102 -91 -125 -107 -64 -44 -206 -111 -382 -181 -398 -159 -820 -404
-989 -574 -87 -88 -134 -158 -172 -258 -44 -114 -55 -130 -113 -160 -49 -26
-62 -28 -166 -28 -117 0 -132 -6 -114 -41 5 -9 24 -26 44 -38 37 -22 45 -50
20 -71 -19 -16 -166 25 -281 79 -145 68 -214 167 -222 316 -3 77 -2 82 32 139
49 80 114 169 206 286 98 123 90 121 415 118 214 -1 251 1 344 21 209 45 374
108 706 272 208 103 326 180 452 291 91 81 276 285 300 332 10 18 17 58 18 96
1 61 7 76 93 246 86 171 90 184 81 218 -10 37 1 129 22 184 6 15 22 83 35 152
20 104 23 142 18 225 -8 123 3 168 55 226 l39 45 -19 34 c-30 57 -25 81 31
134 42 40 50 54 50 84 0 72 -115 204 -208 238 -26 9 -51 20 -55 24 -4 4 21 31
57 60 l66 53 0 93 0 93 -50 28 c-27 15 -50 30 -50 33 0 3 20 23 45 43 25 21
45 41 45 46 0 4 -54 31 -120 60 -74 34 -117 58 -113 65 3 6 22 33 41 59 19 26
33 49 31 50 -2 2 -33 16 -68 32 -60 28 -71 30 -165 27 -261 -7 -442 -50 -674
-162 -385 -185 -642 -436 -1049 -1020 -162 -233 -616 -902 -651 -961 -70 -115
-100 -261 -107 -506 -15 -582 -16 -596 -35 -640 -12 -27 -73 -99 -160 -190
-268 -279 -301 -305 -406 -323 -32 -6 -93 -26 -135 -46 -82 -37 -188 -64 -260
-64 -96 0 -225 -94 -271 -197 -34 -78 -13 -195 51 -280 54 -70 130 -106 326
-149 88 -20 162 -37 163 -39 2 -2 15 -57 29 -122 38 -183 67 -234 230 -404
l54 -56 -123 18 c-68 10 -126 19 -130 19 -4 0 -29 20 -54 44 -83 80 -159 100
-314 84 -86 -9 -100 -8 -154 11 -92 32 -192 94 -349 216 -80 61 -177 133 -217
160 -91 61 -141 120 -190 220 -58 119 -66 163 -43 216 28 63 173 227 384 435
204 200 324 303 461 395 90 61 90 61 84 97 -4 28 0 43 19 68 14 18 25 36 25
40 0 4 -18 29 -40 55 l-40 48 12 108 13 107 170 93 c346 190 465 273 646 453
121 120 177 201 309 445 210 387 280 542 336 745 51 185 144 438 172 472 27
32 199 179 352 299 180 143 877 632 1044 733 202 123 397 176 571 157 111 -12
837 6 920 22 39 8 88 14 109 13 l40 -1 78 -260z m-1170 -628 c0 -12 -142 -217
-150 -217 -21 1 -30 23 -36 90 l-7 75 83 32 c82 31 109 36 110 20z m106 -304
c-14 -14 -20 -14 -33 -3 -14 12 -14 15 2 32 15 17 18 17 32 3 14 -13 14 -17
-1 -32z m-202 -340 c12 -95 -3 -387 -25 -509 -34 -185 -100 -384 -136 -414
-10 -9 -16 0 -24 40 -14 67 -14 67 12 123 20 40 22 56 16 95 -4 26 -8 66 -8
89 -1 39 -4 44 -41 63 -78 40 -100 87 -64 138 13 19 24 22 75 22 l59 0 0 39
c0 47 -18 70 -85 106 -52 28 -95 63 -95 76 0 18 273 189 300 189 5 0 12 -26
16 -57z m-1149 -1485 c139 -100 253 -184 253 -187 0 -3 -44 -33 -97 -66 -54
-33 -142 -93 -197 -134 -145 -107 -187 -125 -316 -129 -58 -1 -111 1 -119 7
-12 8 -12 27 -2 128 16 148 49 278 95 379 34 72 108 184 123 184 3 0 120 -82
260 -182z m4597 -127 c3 -5 -4 -56 -16 -115 -17 -89 -24 -106 -39 -106 -23 0
-23 -7 2 123 14 70 26 107 35 107 7 0 15 -4 18 -9z m55 -253 c-5 -18 -9 -34
-9 -35 0 -2 -4 -3 -10 -3 -11 0 -13 30 -4 54 3 9 12 16 19 16 11 0 11 -8 4
-32z m-254 -209 c-40 -36 -50 -34 -36 5 8 20 17 26 41 26 l31 0 -36 -31z m119
15 c21 -21 20 -40 -4 -64 -29 -29 -47 -25 -55 13 -4 17 -9 40 -11 50 -4 13 1
17 25 17 16 0 37 -7 45 -16z m-44 -162 c-1 -26 -6 -60 -13 -75 l-12 -28 -42
36 c-24 20 -43 40 -43 45 0 12 93 79 102 74 5 -3 8 -26 8 -52z m274 -277 c-8
-60 -46 -344 -85 -630 -60 -440 -70 -526 -61 -561 16 -61 -10 -294 -41 -361
-10 -23 -76 -59 -151 -83 -91 -29 -229 31 -345 149 -74 76 -75 78 -408 718
-183 354 -333 654 -333 667 0 23 2 24 113 30 198 12 308 -16 421 -108 65 -53
89 -61 154 -52 63 10 72 10 107 1 30 -6 31 -5 60 60 l30 66 35 -29 35 -29 -23
-103 c-13 -56 -21 -104 -18 -107 11 -11 134 -15 171 -4 64 17 132 66 166 118
16 27 41 86 56 133 14 47 36 106 49 131 21 43 73 113 79 107 1 -2 -4 -52 -11
-113z m-5864 -884 c11 -37 20 -71 20 -75 0 -13 -40 15 -56 40 -14 21 -15 31
-5 64 6 22 14 40 16 40 2 0 13 -31 25 -69z m888 -774 c8 -9 6 -16 -8 -24 -14
-10 -21 -9 -31 3 -10 13 -10 17 2 25 19 12 25 11 37 -4z m3387 -587 c18 -35
21 -50 12 -50 -7 0 -23 20 -35 45 -25 52 -26 55 -12 55 5 0 21 -22 35 -50z
m-1720 -585 c47 -46 22 -62 -35 -22 -44 29 -49 47 -15 47 14 0 37 -11 50 -25z
m125 -77 c10 -18 18 -34 16 -36 -5 -5 -96 52 -96 60 0 5 14 8 30 8 23 0 35 -7
50 -32z m-1475 -93 c228 -24 398 -106 475 -231 l21 -33 -91 -180 c-49 -100
-94 -181 -99 -181 -18 0 -190 190 -240 265 -47 71 -191 349 -191 368 0 5 15 6
33 3 17 -3 59 -8 92 -11z m1669 -40 c16 -44 4 -48 -29 -10 -19 22 -34 43 -35
48 0 13 57 -21 64 -38z m-2991 -221 c25 -24 37 -53 51 -116 9 -42 -30 -33 -86
20 -31 28 -33 43 -17 91 13 38 19 38 52 5z m2866 -668 c17 -20 -13 -43 -34
-26 -12 10 -13 16 -4 26 6 8 15 14 19 14 4 0 13 -6 19 -14z m326 -267 c33 -40
269 -509 260 -518 -4 -3 -68 -6 -143 -5 -121 1 -147 4 -222 28 -71 23 -303
126 -318 141 -2 2 22 35 54 72 104 122 214 316 214 376 0 7 3 16 6 20 8 8 114
-73 149 -114z m4101 29 c21 -65 27 -104 28 -178 1 -88 -1 -100 -31 -161 -37
-73 -38 -83 -20 -119 12 -23 10 -38 -15 -125 -15 -55 -28 -110 -28 -121 0 -71
-67 -166 -145 -206 -22 -11 -79 -45 -127 -75 l-86 -55 -84 37 c-45 20 -86 40
-89 43 -10 11 9 490 22 552 16 81 65 165 123 213 27 23 90 83 140 133 81 80
99 93 156 112 66 21 95 29 117 31 7 0 24 -36 39 -81z m-5362 -700 c-3 -5 -12
-4 -20 3 -7 6 -11 15 -8 21 3 5 12 4 20 -3 7 -6 11 -15 8 -21z m1126 -458 c0
-12 -29 -20 -35 -10 -9 15 4 30 20 23 8 -3 15 -9 15 -13z m2185 -3199 c3 -11
26 -36 51 -56 24 -20 54 -55 65 -77 57 -112 50 -484 -14 -768 -9 -36 -20 -112
-27 -170 -20 -181 -79 -530 -110 -645 -54 -203 -107 -308 -253 -503 -84 -111
-85 -112 -91 -81 -3 17 -6 148 -6 291 0 323 4 354 181 1348 88 497 103 562
148 633 33 51 46 58 56 28z m-3665 -876 c9 -11 9 -18 -3 -35 -14 -20 -14 -20
-32 0 -15 16 -15 22 -5 35 16 19 24 19 40 0z m6567 -615 c10 -71 -1 -76 -57
-28 -27 24 -50 46 -50 49 0 3 17 18 37 32 36 26 37 26 51 6 8 -11 16 -38 19
-59z m77 -108 c24 -26 25 -30 11 -42 -25 -21 -85 11 -85 45 0 35 38 33 74 -3z
m-4 -105 c0 -19 -50 -41 -63 -28 -7 7 0 15 24 25 42 19 39 19 39 3z m-3460
-621 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6 10 -14z m1173 -675
l-38 -151 -37 -10 c-20 -6 -53 -11 -73 -13 -35 -2 -37 0 -56 48 -10 28 -19 53
-19 56 0 3 29 21 65 38 67 33 101 69 116 124 6 23 19 38 41 49 18 9 34 15 36
13 1 -1 -14 -70 -35 -154z m-294 129 c26 -13 32 -36 19 -65 -10 -23 -12 -23
-39 -10 -36 19 -48 54 -23 72 21 16 21 16 43 3z m-9091 -1336 l33 -66 -31 22
c-36 25 -50 49 -50 84 0 42 14 30 48 -40z m4429 -746 c11 -13 45 -71 75 -129
50 -97 54 -109 42 -130 -24 -44 -17 -111 20 -183 l33 -67 -46 -23 c-49 -25
-194 -54 -420 -86 -169 -23 -241 -41 -333 -82 l-69 -31 19 -21 c30 -33 42 -62
42 -101 0 -44 -37 -170 -53 -184 -7 -5 -55 -19 -107 -31 -109 -26 -159 -49
-214 -97 -46 -42 -63 -41 -180 8 l-77 32 6 51 c7 56 71 200 131 293 49 76 140
171 297 305 l127 110 0 44 c0 55 19 75 108 117 64 30 66 30 126 17 125 -27
111 -34 142 66 14 49 28 91 30 93 9 10 194 50 235 50 35 1 50 -4 66 -21z m508
-207 c3 -6 -4 -15 -15 -21 -20 -11 -21 -10 -10 9 12 23 16 25 25 12z m-35
-138 c0 -23 -30 -49 -65 -55 -34 -5 -31 41 7 90 l33 43 12 -28 c7 -15 12 -38
13 -50z m-4480 -244 c378 -34 522 -102 677 -321 32 -46 131 -151 244 -262
l190 -185 -48 -8 c-26 -4 -133 -8 -238 -7 -343 1 -413 28 -717 271 -242 195
-342 284 -374 335 -29 45 -60 159 -50 183 6 18 59 17 316 -6z m7682 -1324 c-4
-102 -19 -165 -68 -293 -25 -63 -43 -116 -41 -118 2 -3 39 45 81 106 42 60 81
110 86 110 22 0 14 -25 -49 -155 -59 -122 -73 -144 -109 -168 -47 -31 -47 -31
-27 42 l14 54 -35 -34 c-37 -36 -42 -54 -19 -79 13 -15 13 -23 0 -76 -14 -55
-22 -65 -107 -147 -142 -134 -305 -264 -383 -302 -45 -22 -102 -40 -160 -50
-49 -9 -123 -25 -162 -35 -91 -25 -110 -25 -151 0 -32 20 -33 21 -26 73 3 28
17 76 31 105 20 43 34 57 74 78 63 31 82 63 103 171 17 87 17 87 -4 103 -21
16 -20 18 77 111 132 125 347 311 460 395 175 131 285 186 368 181 l50 -2 -3
-70z m-8179 43 c24 -12 110 -132 97 -136 -18 -6 -80 19 -111 45 -32 27 -72 96
-62 107 7 6 49 -2 76 -16z m3220 -207 l28 -18 -38 -6 c-84 -14 -109 -2 -55 27
34 19 32 19 65 -3z m3876 -644 c-70 -102 -175 -241 -197 -260 -11 -10 -35 -20
-54 -24 -29 -4 -41 0 -67 23 l-31 29 192 159 c106 88 194 153 196 147 2 -7
-15 -40 -39 -74z m-521 -80 c-7 -26 -16 -46 -22 -44 -13 5 -12 42 4 81 17 46
30 18 18 -37z m-7137 -170 c-15 -15 -21 -6 -27 39 l-6 39 22 -34 c15 -23 18
-37 11 -44z m-98 -292 l-26 -59 -15 31 c-8 17 -12 39 -9 48 6 14 67 48 75 41
1 -2 -10 -29 -25 -61z m7273 30 c16 -39 18 -55 6 -55 -11 0 -42 77 -34 84 9
10 13 7 28 -29z m1454 -150 c11 -13 7 -15 -30 -15 -37 0 -41 2 -30 15 7 8 20
15 30 15 10 0 23 -7 30 -15z m-880 -5 c0 -13 -28 -25 -38 -16 -3 4 0 11 8 16
20 13 30 12 30 0z m-7332 -74 c4 -35 -14 -26 -20 9 -4 16 -1 24 6 22 6 -2 12
-16 14 -31z m-525 -25 c20 -35 21 -41 5 -41 -17 1 -51 48 -43 60 10 16 20 12
38 -19z m8562 -51 c-9 -17 -20 -28 -25 -25 -16 10 -12 33 8 44 29 17 33 12 17
-19z m-3489 -16 c37 -28 38 -32 25 -52 -7 -12 -13 -11 -37 2 -56 30 -57 31
-43 55 8 11 15 21 17 21 2 0 19 -12 38 -26z m-4686 -59 c0 -14 -15 -36 -37
-55 -34 -31 -37 -31 -50 -14 -19 26 -17 35 19 89 l32 48 18 -23 c10 -12 18
-33 18 -45z m4818 -41 c12 -4 22 -13 22 -20 0 -10 -14 -14 -49 -14 -55 0 -75
15 -39 29 28 12 37 12 66 5z m282 -134 c0 -31 -3 -38 -15 -34 -15 6 -21 55 -8
67 15 16 23 5 23 -33z m-4353 -186 c6 -17 -27 -26 -44 -13 -11 10 -11 14 -1
21 17 10 40 6 45 -8z m-343 -166 c-6 -10 -35 4 -53 26 -25 30 0 39 30 10 15
-15 26 -31 23 -36z m122 -563 c-15 -23 -77 -75 -88 -75 -13 0 23 93 49 126
l27 37 14 -33 c11 -27 11 -36 -2 -55z m439 -76 c4 -5 -1 -12 -11 -16 -19 -7
-54 28 -54 54 0 11 7 10 30 -7 16 -11 32 -25 35 -31z m-177 -37 c55 -52 72
-74 72 -95 0 -15 -2 -27 -5 -27 -6 0 -180 124 -200 142 -11 10 28 48 49 48 6
0 44 -30 84 -68z"
      />
      <path
        d="M30146 25364 c-10 -31 -15 -58 -13 -61 10 -9 47 18 48 35 5 81 -13
95 -35 26z"
      />
      <path
        d="M27848 22582 c-37 -108 -66 -198 -65 -199 2 -1 24 33 50 76 70 114
88 170 85 252 l-3 68 -67 -197z"
      />
      <path
        d="M13830 28936 c0 -61 9 -70 30 -31 15 28 5 75 -17 83 -10 3 -13 -12
-13 -52z"
      />
      <path
        d="M25172 28970 c-15 -25 -15 -50 2 -72 21 -28 26 -22 26 37 0 58 -7 68
-28 35z"
      />
      <path
        d="M3340 28258 c0 -7 -3 -30 -7 -52 -5 -33 -4 -38 12 -35 25 5 49 85 28
93 -23 9 -33 7 -33 -6z"
      />
      <path
        d="M35654 28255 c-9 -22 16 -85 33 -85 12 0 14 8 9 38 -3 20 -6 43 -6
50 0 17 -29 15 -36 -3z"
      />
      <path
        d="M10125 17919 c-13 -23 -14 -34 -5 -43 8 -8 16 -5 30 14 11 14 19 31
17 39 -4 27 -26 22 -42 -10z"
      />
      <path
        d="M28873 17943 c-18 -6 -16 -24 5 -51 21 -27 58 -22 43 6 -18 33 -36
50 -48 45z"
      />
      <path
        d="M17465 17026 c-68 -114 -201 -134 -340 -51 l-57 35 -72 -31 c-98 -41
-189 -111 -707 -549 -244 -207 -320 -266 -539 -420 -238 -167 -327 -237 -445
-350 -84 -79 -111 -99 -165 -119 -139 -51 -140 -53 -268 -214 -261 -331 -379
-544 -398 -717 -6 -57 -11 -69 -42 -98 -31 -28 -34 -36 -29 -68 5 -29 -1 -51
-33 -113 -30 -61 -41 -99 -54 -186 -9 -61 -19 -126 -22 -145 -3 -19 -9 -204
-13 -410 l-7 -375 -28 -80 c-29 -84 -67 -153 -121 -220 -18 -22 -40 -66 -49
-97 l-17 -58 -47 0 c-72 0 -158 -18 -244 -52 l-78 -29 0 -65 c0 -35 -3 -64 -6
-64 -3 0 -26 12 -51 26 l-45 27 -90 -89 c-134 -133 -310 -368 -361 -483 -71
-161 -64 -337 24 -562 47 -119 260 -536 321 -628 117 -174 383 -394 576 -476
94 -39 380 -126 502 -152 129 -27 400 -25 537 5 89 19 130 22 353 22 213 0
267 3 351 20 120 25 217 58 329 115 74 36 670 409 965 602 370 243 666 572
836 932 33 69 78 147 102 175 71 83 113 153 174 291 40 92 60 152 68 205 l11
75 37 3 37 3 -16 31 c-20 39 -20 107 0 146 8 16 43 68 76 113 110 154 207 369
274 610 22 77 47 106 110 124 47 14 59 23 94 73 122 175 145 302 97 543 -35
182 -47 327 -35 435 6 49 15 157 20 239 9 160 23 223 73 320 33 66 134 205
221 304 32 38 64 87 76 118 19 52 56 290 46 300 -8 9 -97 -119 -123 -177 -23
-52 -68 -110 -85 -110 -4 0 -8 97 -8 215 l0 215 -95 0 -95 0 0 61 0 62 -109
-7 -108 -8 -13 66 c-7 36 -14 66 -16 66 -11 0 -94 -101 -146 -177 -88 -129
-186 -240 -341 -387 -264 -249 -386 -420 -725 -1011 -62 -110 -139 -234 -170
-275 -83 -111 -231 -260 -367 -368 -66 -53 -169 -140 -230 -193 -126 -110
-204 -168 -398 -298 -108 -72 -149 -94 -188 -100 -27 -5 -49 -5 -49 -2 0 4 27
51 60 105 71 117 164 234 324 409 64 70 114 127 111 127 -3 0 -17 -7 -30 -16
-29 -19 -32 -7 -10 36 26 51 108 98 91 53 -10 -25 90 74 125 123 16 23 34 63
41 88 10 38 40 77 168 219 336 372 429 505 476 677 17 63 18 79 10 336 -1 50
0 52 97 167 54 64 120 135 145 158 159 138 382 465 382 558 0 40 -28 58 -183
116 l-127 48 -53 -35 c-81 -54 -133 -78 -169 -78 -38 0 -152 66 -128 73 8 3
64 15 124 27 61 12 123 28 138 36 30 16 68 68 68 95 0 19 -60 49 -98 49 -25 0
-41 -8 -121 -62 -25 -17 -81 -24 -94 -12 -3 3 18 26 46 52 29 25 53 51 55 57
2 7 -16 28 -40 47 l-43 35 -30 -51z m-363 -858 c-15 -15 -26 -4 -18 18 5 13 9
15 18 6 9 -9 9 -15 0 -24z"
      />
      <path
        d="M21491 17036 l-43 -44 42 -39 c23 -21 49 -42 56 -46 25 -14 15 -27
-22 -27 -40 0 -64 11 -114 52 -19 16 -47 28 -62 28 -42 0 -108 -30 -108 -49 0
-31 41 -82 78 -98 28 -12 172 -46 262 -63 3 0 -20 -16 -50 -35 -83 -53 -117
-48 -242 36 -31 22 -61 39 -66 39 -14 0 -220 -78 -262 -100 -65 -33 -66 -59
-2 -184 72 -140 151 -244 307 -406 78 -80 171 -183 209 -230 l68 -85 -8 -90
c-17 -181 1 -296 68 -430 63 -125 144 -227 416 -529 89 -99 162 -187 162 -196
0 -37 62 -138 120 -195 34 -33 58 -52 54 -42 -16 43 62 3 87 -45 26 -50 24
-62 -6 -42 -45 29 -27 4 73 -105 129 -140 208 -233 265 -312 47 -66 127 -198
127 -210 0 -3 -22 -2 -50 2 -81 13 -409 240 -625 434 -44 39 -125 107 -180
150 -173 136 -322 291 -422 441 -23 33 -81 132 -131 220 -165 294 -322 545
-445 709 -33 44 -151 170 -262 280 -196 194 -290 301 -364 415 -41 62 -122
160 -133 160 -5 0 -8 -7 -8 -15 0 -7 -5 -37 -11 -65 l-11 -52 -109 8 -109 7 0
-62 0 -61 -95 0 -95 0 -2 -216 -3 -216 -29 23 c-16 12 -40 48 -53 78 -24 55
-103 174 -124 186 -11 7 -7 -44 17 -189 17 -103 48 -172 108 -236 23 -25 69
-81 102 -125 140 -183 173 -270 185 -480 4 -77 13 -184 19 -238 14 -117 3
-263 -35 -455 -40 -197 -32 -286 35 -427 50 -104 107 -170 156 -180 55 -10 89
-50 114 -136 71 -246 157 -437 262 -584 36 -49 73 -106 83 -125 23 -44 23
-105 2 -147 l-17 -32 38 -3 37 -3 11 -75 c18 -126 140 -382 230 -482 45 -51
72 -96 123 -208 106 -236 341 -531 581 -732 114 -96 200 -153 705 -474 527
-335 614 -379 834 -424 84 -17 137 -20 354 -20 219 0 267 -3 348 -21 136 -29
335 -36 477 -15 119 18 428 105 551 156 214 88 468 297 595 488 79 119 298
564 341 692 32 98 39 131 43 225 7 179 -22 259 -174 475 -77 110 -183 232
-271 314 l-52 48 -45 -26 c-25 -14 -47 -26 -50 -26 -3 0 -6 29 -6 64 l0 65
-77 29 c-85 33 -173 52 -239 52 -48 0 -56 8 -73 71 -7 26 -31 68 -53 95 -57
68 -115 194 -133 287 -11 58 -15 151 -15 357 0 405 -26 636 -85 751 -25 50
-35 82 -35 117 0 53 -4 60 -42 85 -22 14 -28 25 -28 53 0 162 -140 422 -408
760 l-120 150 -74 29 c-108 42 -165 72 -182 97 -44 63 -232 212 -576 456 -102
72 -237 176 -301 229 -758 637 -763 640 -863 682 l-74 31 -57 -35 c-140 -84
-288 -58 -345 59 -12 25 -23 46 -24 46 -1 0 -21 -20 -45 -44z m459 -862 c0
-16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z"
      />
      <path
        d="M4310 16724 c-117 -27 -172 -37 -340 -60 -238 -33 -410 -110 -564
-255 -93 -86 -116 -138 -172 -384 -26 -115 -51 -214 -55 -219 -4 -4 -22 -11
-40 -15 -35 -7 -42 -16 -58 -76 -8 -28 -21 -47 -45 -62 -21 -13 -44 -42 -60
-75 -28 -59 -129 -407 -236 -813 -94 -356 -107 -399 -147 -457 -23 -32 -37
-69 -44 -106 -10 -65 -69 -760 -69 -821 0 -22 12 -76 27 -118 26 -75 27 -89
33 -358 11 -421 5 -410 330 -637 269 -188 347 -235 464 -282 49 -20 95 -41
103 -47 24 -21 13 -50 -37 -96 -27 -25 -52 -54 -55 -64 -4 -11 6 -49 22 -87
27 -65 28 -73 27 -232 0 -207 -20 -620 -36 -765 -19 -171 -16 -678 6 -860 34
-296 60 -374 156 -467 34 -32 62 -69 65 -85 11 -51 26 -70 86 -112 91 -64 309
-240 309 -249 0 -12 -44 -52 -57 -52 -25 0 -96 -64 -113 -103 -10 -23 -28
-108 -39 -189 -12 -83 -37 -200 -57 -265 -49 -162 -90 -336 -111 -473 -25
-163 -25 -689 0 -867 71 -502 159 -734 344 -903 114 -105 243 -143 407 -121
111 16 120 14 250 -45 71 -33 123 -49 187 -59 180 -26 259 -94 371 -317 148
-295 292 -473 543 -673 101 -81 134 -115 220 -230 295 -394 709 -627 1245
-701 58 -8 193 -17 300 -20 237 -6 297 -15 378 -60 126 -68 265 -68 330 0 38
39 51 71 62 163 12 90 37 142 92 185 51 40 128 119 128 132 0 5 -43 62 -95
126 l-94 118 35 27 35 27 -21 23 c-12 13 -34 26 -48 30 -61 15 -170 75 -209
114 -39 39 -63 88 -78 154 -6 31 -143 186 -232 264 -228 198 -501 326 -975
457 -171 47 -228 76 -319 160 -42 39 -170 145 -284 236 -267 211 -322 261
-336 310 -11 36 -9 45 25 115 31 66 43 105 34 105 -2 0 -25 -7 -52 -15 -45
-14 -51 -14 -78 3 l-29 19 39 26 c59 39 82 95 95 234 11 127 8 124 60 52 18
-24 18 -24 13 40 -17 241 -431 688 -688 742 -68 15 -121 41 -147 75 -11 14
-83 134 -158 266 -76 133 -149 248 -162 257 -58 39 -115 99 -138 144 -30 59
-93 234 -122 340 -21 74 -21 79 -5 110 9 17 25 48 35 67 10 19 29 85 43 145
13 61 36 137 49 170 27 65 40 72 104 60 38 -7 38 -7 17 -91 l-7 -28 32 23 c44
32 49 50 21 73 l-24 19 26 22 c21 18 37 60 86 232 86 298 94 343 96 530 1 177
-6 215 -57 279 -29 37 -30 41 -18 80 35 122 36 140 13 217 -12 41 -31 149 -42
241 -28 236 -56 306 -186 469 -37 46 -101 135 -142 198 -96 146 -173 223 -281
281 -127 68 -161 120 -161 250 0 71 25 141 56 158 10 6 52 8 96 4 55 -3 86 -1
103 8 43 23 139 233 194 424 75 260 90 391 101 861 10 389 18 493 87 1095 25
216 28 280 27 535 0 205 -5 338 -18 455 -39 368 -127 728 -222 910 -35 67
-119 181 -132 179 -4 0 -54 -12 -112 -25z m-606 -3155 c-8 -14 -34 -11 -34 4
0 8 3 17 7 20 9 9 34 -13 27 -24z m701 -665 c-34 -28 -45 -30 -45 -10 0 23 51
59 64 46 8 -8 3 -18 -19 -36z m-19 -143 c-9 -14 -33 -14 -41 -1 -4 6 -1 18 6
26 11 13 14 13 27 0 8 -8 11 -19 8 -25z m-48 -113 c17 -17 -25 -63 -45 -47
-14 12 -18 41 -6 52 10 11 38 8 51 -5z m-254 -1504 c16 -6 23 -64 10 -77 -7
-7 -63 61 -64 76 0 9 32 9 54 1z m145 -206 l41 -63 -32 -3 c-42 -5 -75 5 -82
24 -7 18 13 104 24 104 4 0 26 -28 49 -62z m-179 -135 c6 -32 14 -66 16 -75 4
-14 -1 -18 -20 -18 -26 0 -26 1 -26 75 0 41 4 75 9 75 5 0 15 -26 21 -57z
m220 -108 c13 -16 6 -35 -14 -35 -8 0 -19 7 -27 16 -10 13 -10 17 2 25 19 12
25 11 39 -6z m-880 -240 c0 -19 -4 -35 -10 -35 -5 0 -10 16 -10 35 0 19 5 35
10 35 6 0 10 -16 10 -35z m920 -171 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5
-4 9 -13 9 -21z m297 -206 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0
-40z m231 -265 c-2 -8 -6 -14 -10 -14 -5 1 -18 1 -30 1 -13 0 -29 4 -37 9 -24
15 -4 27 39 23 29 -3 40 -8 38 -19z m-114 -190 c-3 -32 -8 -59 -9 -61 -3 -4
-115 25 -121 31 -5 5 117 87 129 87 5 0 5 -26 1 -57z m-485 -570 c26 -98 30
-127 18 -135 -15 -9 -59 -10 -82 -2 -15 6 -65 92 -65 113 0 11 87 60 107 61 7
0 17 -17 22 -37z m1491 -2294 c8 -19 10 -51 5 -94 -6 -58 -9 -66 -31 -71 -14
-4 -31 -1 -39 6 -20 17 -19 88 1 162 16 55 18 58 34 43 10 -9 24 -30 30 -46z"
      />
      <path
        d="M34555 16693 c-162 -206 -281 -676 -316 -1248 -15 -242 -7 -480 26
-770 70 -627 76 -705 85 -1090 10 -401 22 -534 66 -733 47 -212 161 -499 217
-551 25 -22 32 -24 110 -19 47 3 92 1 101 -4 55 -31 76 -201 35 -285 -25 -52
-59 -81 -150 -129 -95 -50 -171 -128 -270 -276 -45 -68 -110 -157 -144 -198
-123 -152 -157 -240 -185 -469 -10 -86 -28 -195 -41 -243 l-23 -88 22 -75 c26
-89 27 -99 6 -116 -57 -47 -80 -180 -65 -377 11 -144 28 -222 106 -492 38
-130 53 -169 73 -186 l25 -21 -22 -22 c-27 -27 -24 -37 19 -70 l33 -26 -6 31
c-3 18 -9 43 -12 57 -6 23 -3 26 28 32 69 13 79 6 109 -72 15 -39 37 -116 49
-170 12 -57 35 -126 56 -165 l34 -66 -15 -60 c-22 -84 -93 -287 -122 -348 -22
-47 -85 -114 -146 -155 -13 -9 -87 -128 -165 -265 -78 -137 -152 -259 -165
-270 -32 -30 -77 -51 -123 -59 -22 -4 -76 -24 -120 -46 -253 -123 -564 -497
-583 -701 -2 -32 -3 -58 0 -58 3 0 18 18 32 41 15 22 29 39 31 37 2 -2 8 -54
14 -116 13 -144 33 -187 115 -246 19 -13 19 -14 -11 -30 -28 -14 -36 -14 -80
-1 -26 8 -49 15 -50 15 -10 0 2 -35 32 -97 19 -40 35 -82 35 -93 0 -59 -56
-113 -354 -348 -110 -87 -231 -187 -269 -225 -85 -82 -151 -115 -322 -162
-470 -129 -752 -261 -977 -458 -87 -76 -227 -236 -234 -266 -27 -128 -110
-210 -266 -260 -20 -7 -49 -22 -63 -33 l-27 -21 36 -31 36 -31 -95 -117 c-52
-65 -95 -121 -95 -125 0 -12 78 -92 128 -131 55 -43 80 -95 92 -185 15 -121
40 -163 115 -198 67 -31 182 -19 260 27 87 50 119 56 350 64 268 10 438 31
612 76 408 104 722 314 990 663 48 63 117 139 153 169 190 158 246 210 316
290 102 117 199 263 284 432 114 225 189 288 369 315 64 10 116 26 187 59 130
59 139 61 250 45 221 -30 389 54 528 265 89 134 142 283 186 519 102 555 84
1051 -54 1514 -47 158 -64 233 -81 363 -21 157 -54 221 -129 251 -33 13 -76
47 -76 61 0 9 212 180 312 252 60 42 69 53 82 99 12 41 26 61 65 95 91 77 124
174 158 457 21 183 25 707 5 880 -15 142 -37 589 -37 775 0 137 2 149 29 214
34 81 29 98 -40 159 -45 40 -54 66 -30 86 7 6 52 27 99 45 120 47 232 116 547
339 14 9 43 29 65 43 84 55 129 110 161 200 14 40 18 98 24 334 6 275 7 288
33 363 16 46 27 99 27 130 0 28 -16 231 -35 451 l-34 399 -40 61 c-47 72 -59
109 -146 446 -96 368 -212 767 -240 826 -14 28 -42 64 -63 82 -29 22 -41 41
-45 67 -7 45 -23 65 -51 65 -11 0 -27 4 -34 9 -8 4 -36 103 -63 221 -28 116
-59 233 -71 258 -30 64 -156 188 -252 247 -105 65 -264 118 -413 139 -205 28
-210 29 -440 81 -18 4 -31 -6 -68 -52z m805 -3113 c0 -19 -20 -27 -33 -13 -8
7 11 33 24 33 5 0 9 -9 9 -20z m-696 -664 c22 -18 12 -40 -12 -27 -37 21 -55
39 -48 50 8 12 23 6 60 -23z m22 -155 c-9 -14 -33 -14 -41 -1 -4 6 -1 18 6 26
11 13 14 13 27 0 8 -8 11 -19 8 -25z m62 -124 c2 -10 -2 -25 -9 -33 -10 -13
-15 -12 -35 7 -19 17 -21 25 -12 36 15 19 52 12 56 -10z m251 -1504 c-52 -68
-57 -72 -63 -57 -10 26 -7 62 7 67 6 3 25 6 41 6 26 1 27 -1 15 -16z m-120
-215 c2 -29 -28 -48 -75 -48 l-44 0 47 71 48 72 12 -39 c6 -21 12 -47 12 -56z
m131 -133 c0 -72 -1 -75 -24 -75 l-23 0 15 75 c8 41 19 75 23 75 5 0 9 -34 9
-75z m-211 -84 c12 -8 12 -12 2 -25 -15 -18 -37 -21 -46 -6 -7 11 11 40 24 40
3 0 12 -4 20 -9z m863 -246 c3 -24 0 -35 -9 -35 -8 0 -13 13 -13 35 0 19 4 35
9 35 5 0 11 -16 13 -35z m-920 -177 c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9
-9 9 -15 0 -24z m-315 -200 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28
0 -40z m-152 -267 c-6 -5 -26 -11 -45 -13 -26 -2 -35 1 -38 14 -2 13 5 17 35
20 41 3 63 -6 48 -21z m102 -172 c35 -23 62 -44 59 -46 -6 -6 -118 -35 -121
-31 -6 8 -14 118 -8 118 4 0 35 -19 70 -41z m543 -609 c0 -22 -50 -108 -65
-114 -22 -8 -67 -7 -82 2 -8 5 -6 30 9 94 l20 87 59 -30 c33 -16 59 -34 59
-39z m-1550 -2290 c15 -71 10 -130 -12 -144 -29 -18 -52 5 -61 60 -10 61 -3
104 25 139 25 32 30 26 48 -55z"
      />
      <path
        d="M9705 16433 c28 -155 45 -171 45 -42 0 79 0 79 -26 79 -25 0 -26 -2
-19 -37z"
      />
      <path
        d="M29290 16383 c0 -47 3 -83 7 -80 6 6 32 128 33 155 0 6 -9 12 -20 12
-18 0 -20 -7 -20 -87z"
      />
      <path
        d="M10954 12357 c15 -46 90 -159 126 -190 30 -26 40 -41 40 -64 0 -17
13 -47 30 -71 55 -76 50 -56 -27 109 -39 82 -64 119 -112 170 -35 35 -60 56
-57 46z"
      />
      <path
        d="M28017 12309 c-40 -42 -71 -89 -101 -153 -52 -108 -77 -166 -71 -166
15 0 65 87 65 112 0 22 11 40 45 71 41 39 144 197 128 197 -4 0 -34 -27 -66
-61z"
      />
      <path
        d="M21790 8406 c0 -3 29 -47 65 -97 36 -51 63 -94 61 -96 -2 -2 -15 1
-30 7 -37 14 -46 13 -46 -5 0 -24 27 -29 73 -15 54 17 61 24 49 47 -11 19
-172 169 -172 159z"
      />
      <path
        d="M17147 8327 c-90 -84 -98 -108 -38 -125 54 -17 69 -15 82 9 11 21 9
22 -17 16 -16 -3 -37 -9 -48 -13 -23 -9 -18 1 59 109 31 42 52 77 48 77 -4 -1
-43 -33 -86 -73z"
      />
      <path
        d="M16867 8002 c-56 -56 -66 -71 -54 -80 11 -10 27 2 81 58 58 60 74 90
48 90 -4 0 -38 -31 -75 -68z"
      />
      <path
        d="M22076 8061 c-3 -5 24 -41 60 -80 54 -59 69 -69 81 -60 12 10 3 24
-54 81 -68 69 -77 75 -87 59z"
      />
      <path
        d="M18661 5461 c-14 -47 -14 -61 -1 -61 9 0 40 62 40 80 0 4 -6 10 -14
13 -9 4 -17 -7 -25 -32z"
      />
      <path
        d="M20330 5480 c0 -17 34 -80 43 -80 9 0 1 65 -9 82 -7 11 -34 10 -34
-2z"
      />
      <path
        d="M29878 5284 c-27 -14 -34 -25 -22 -36 11 -12 83 24 77 38 -7 18 -21
17 -55 -2z"
      />
      <path d="M18393 4350 l-32 -70 31 21 c38 28 53 60 42 93 -8 24 -11 21 -41 -44z" />
      <path
        d="M20597 4391 c-8 -41 -2 -55 39 -86 l34 -26 -24 55 c-40 90 -42 93
-49 57z"
      />
      <path d="M18573 4325 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z" />
      <path
        d="M20432 4324 c1 -9 9 -19 16 -22 9 -3 13 2 10 14 -1 9 -9 19 -16 22
-9 3 -13 -2 -10 -14z"
      />
      <path
        d="M18452 4136 c-48 -45 -92 -127 -92 -171 0 -13 88 -45 123 -45 8 0 23
12 33 28 22 32 23 122 2 192 l-14 45 -52 -49z"
      />
      <path
        d="M20509 4122 c-17 -79 -15 -131 5 -169 19 -37 31 -39 105 -17 l53 15
-7 37 c-13 69 -71 153 -125 182 -17 9 -20 4 -31 -48z"
      />
      <path
        d="M18302 3884 c-37 -43 -66 -101 -55 -113 2 -3 16 5 31 16 32 24 75
103 70 129 -2 14 -13 7 -46 -32z"
      />
      <path
        d="M20684 3900 c10 -55 84 -145 103 -126 10 10 -37 93 -73 126 l-37 35
7 -35z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 4000 4000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,4000.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M14415 37216 c-366 -95 -562 -172 -799 -314 -213 -128 -339 -163
-596 -163 -285 0 -720 21 -825 39 -170 29 -424 22 -751 -21 -149 -20 -306 -45
-350 -56 -109 -28 -291 -54 -336 -48 -20 3 -51 15 -67 26 -27 20 -35 21 -72
11 -23 -7 -110 -11 -203 -10 -573 6 -713 -3 -806 -50 -19 -10 -134 -52 -255
-95 -244 -85 -265 -95 -415 -204 -297 -215 -527 -455 -737 -771 -189 -284
-337 -527 -373 -613 -101 -243 -218 -363 -420 -432 -41 -14 -83 -33 -94 -43
-22 -20 -101 -69 -361 -227 -292 -176 -359 -236 -563 -498 -298 -382 -430
-580 -600 -897 -111 -206 -193 -332 -624 -950 l-269 -385 -253 -250 c-281
-279 -350 -357 -661 -744 l-217 -271 -132 -40 c-342 -106 -412 -147 -536 -316
-43 -57 -93 -111 -127 -137 -124 -92 -134 -115 -107 -248 17 -83 17 -92 2
-137 -79 -235 -223 -636 -257 -717 -24 -55 -72 -155 -107 -222 -86 -162 -104
-218 -111 -333 -4 -76 -1 -113 16 -183 11 -49 21 -116 21 -149 0 -61 -61 -354
-161 -768 -28 -118 -57 -245 -65 -282 -17 -84 -129 -864 -146 -1018 -18 -168
-15 -623 6 -865 15 -172 16 -267 10 -680 -10 -699 1 -802 157 -1508 125 -567
362 -1548 405 -1676 61 -184 121 -294 323 -591 339 -497 484 -671 696 -831
116 -88 407 -295 545 -387 205 -138 473 -248 820 -338 427 -112 664 -247 862
-493 34 -42 154 -170 268 -286 114 -115 226 -239 250 -275 187 -281 470 -546
830 -774 149 -95 235 -138 482 -240 l203 -83 360 -71 c571 -113 704 -137 1326
-242 496 -84 606 -105 713 -140 158 -51 186 -73 196 -153 5 -30 16 -67 27 -81
10 -14 106 -85 213 -157 107 -72 208 -143 224 -158 40 -36 482 -552 559 -653
102 -132 175 -255 235 -398 76 -177 122 -377 123 -527 1 -75 -3 -90 -39 -170
-42 -91 -59 -165 -102 -448 -12 -77 -34 -187 -48 -245 -24 -95 -26 -123 -26
-290 0 -169 3 -194 27 -285 33 -121 68 -197 100 -214 19 -10 44 -10 117 -1
115 13 135 23 230 106 115 100 189 150 302 203 213 101 465 179 707 220 124
21 241 28 241 13 0 -26 -137 -203 -309 -397 -309 -349 -1065 -1075 -1106
-1062 -5 2 -22 46 -37 98 -36 125 -56 143 -147 136 -74 -5 -140 -31 -188 -71
-18 -15 -59 -76 -90 -134 -47 -86 -75 -124 -143 -192 -98 -98 -123 -141 -203
-350 -97 -254 -110 -328 -172 -985 -31 -316 -39 -363 -100 -572 -160 -541
-319 -956 -545 -1423 -136 -281 -171 -367 -191 -471 -6 -35 -21 -82 -32 -104
-22 -44 -58 -145 -52 -145 2 0 64 39 137 86 72 47 137 88 144 91 6 2 29 -8 50
-22 42 -29 46 -29 182 2 l72 17 0 37 c0 38 8 50 83 133 20 21 40 50 45 65 5
14 17 46 26 71 9 25 36 81 61 125 39 70 44 76 45 50 0 -82 -58 -222 -145 -349
-25 -37 -43 -71 -40 -76 3 -5 24 -15 45 -21 35 -11 42 -10 60 6 11 10 20 28
20 39 1 33 34 98 80 157 23 30 77 108 121 174 44 66 89 131 100 145 22 29 97
228 199 530 83 247 101 314 140 536 16 94 45 220 64 281 62 197 79 312 96 658
17 329 58 770 80 859 24 97 76 194 119 223 19 13 56 24 89 28 96 11 325 53
370 69 l42 15 0 -83 0 -83 71 -32 c133 -60 217 -73 302 -47 66 20 115 59 236
190 58 62 213 225 344 362 204 212 253 270 337 394 54 80 169 233 255 340 86
107 220 290 297 405 192 286 333 479 394 542 79 80 136 83 271 13 127 -66 230
-162 319 -297 108 -163 153 -301 135 -411 -19 -114 -82 -459 -102 -562 -38
-196 -144 -473 -316 -828 -58 -119 -148 -263 -217 -347 -22 -27 -154 -176
-291 -330 -495 -553 -805 -912 -890 -1030 -87 -122 -128 -197 -195 -360 -171
-418 -345 -706 -726 -1205 -478 -625 -610 -804 -649 -879 -59 -118 -69 -249
-27 -371 18 -52 21 -55 52 -53 44 2 78 37 96 100 9 30 26 66 38 80 l23 26 42
-77 42 -78 62 7 c34 4 69 11 77 15 9 5 26 35 39 67 48 116 122 253 199 369 89
132 329 426 363 444 27 15 79 30 79 24 0 -9 -92 -126 -161 -204 -36 -41 -103
-130 -150 -198 -46 -68 -99 -143 -116 -168 -35 -49 -57 -112 -68 -191 l-7 -48
58 56 c33 30 84 75 114 99 88 70 139 121 181 181 31 46 45 58 73 63 46 9 65
29 261 273 146 181 165 209 165 241 0 51 32 98 82 123 29 14 70 54 123 117 44
53 98 116 120 141 43 48 135 124 142 118 9 -9 -28 -75 -195 -342 -141 -225
-187 -291 -235 -336 l-60 -55 -4 -65 -4 -64 43 49 c85 98 187 201 193 194 13
-13 -28 -125 -147 -408 -209 -498 -409 -931 -520 -1131 -73 -129 -118 -183
-184 -215 -49 -24 -63 -26 -122 -21 -37 3 -123 22 -192 43 -188 56 -261 72
-358 79 -83 7 -90 6 -115 -16 -23 -20 -29 -36 -36 -99 -23 -182 26 -342 159
-518 70 -93 124 -143 195 -180 72 -37 200 -42 313 -13 45 11 108 21 141 21
229 0 484 130 801 408 58 51 134 110 170 131 81 48 162 133 222 231 77 128
182 310 245 425 33 61 87 148 120 195 127 179 213 345 278 535 51 152 119 289
215 435 46 69 92 136 103 150 l20 25 -7 -55 c-3 -30 -31 -136 -61 -234 -58
-188 -79 -291 -145 -726 -23 -146 -48 -310 -56 -365 -21 -138 -8 -210 50 -273
36 -41 52 -44 92 -23 21 11 40 19 43 18 3 -2 19 -9 34 -17 27 -13 29 -12 58
23 42 50 89 73 192 91 48 8 103 22 122 32 37 17 90 71 107 110 9 19 13 21 29
11 15 -9 22 -8 33 3 19 19 210 422 300 631 74 172 115 225 190 247 22 6 41 10
42 9 1 -1 7 -94 14 -207 18 -312 35 -477 74 -696 20 -109 39 -246 44 -304 l7
-105 45 109 c35 85 50 111 70 118 39 15 119 2 192 -31 37 -17 74 -31 82 -31 8
0 17 -6 20 -12 3 -7 3 10 0 37 l-6 51 121 23 c95 18 121 27 121 40 2 75 24
166 58 241 69 147 73 178 82 615 5 215 10 640 11 945 2 658 -10 852 -63 994
-29 79 -30 87 -37 321 -4 132 -11 248 -15 257 -11 23 -47 34 -57 17 -36 -64
-38 -60 -39 81 l0 135 23 -38 c27 -44 29 -43 73 43 41 82 45 188 10 306 -62
214 -67 267 -76 764 -10 585 -19 628 -197 989 -72 148 -107 207 -144 246 -71
75 -82 114 -78 260 2 66 4 221 4 345 0 225 0 225 -29 295 -52 128 -217 443
-286 547 -231 350 -537 553 -900 597 -200 25 -211 28 -339 93 -69 35 -179 94
-245 132 -263 151 -355 189 -540 221 -115 20 -448 20 -731 0 -115 -8 -235 -15
-265 -15 l-55 0 -47 126 -46 126 21 22 c32 31 168 99 271 135 175 62 503 131
734 156 74 8 182 26 240 40 59 13 251 50 427 80 635 110 716 126 761 147 24
12 44 25 44 28 0 3 -68 24 -151 46 l-150 39 8 60 c4 33 7 87 5 121 -2 51 1 64
18 79 18 16 20 26 15 70 l-7 51 39 12 c37 12 39 14 107 173 75 174 76 181 20
208 -26 13 -35 12 -87 -7 -52 -19 -63 -20 -91 -8 -18 7 -48 32 -67 55 l-35 42
-49 -12 c-57 -14 -116 -68 -136 -123 -15 -42 -27 -44 -94 -12 -80 37 -113 83
-186 259 -35 86 -67 157 -72 157 -4 0 -13 -11 -19 -25 -6 -14 -15 -25 -19 -25
-12 0 -89 135 -122 215 -34 79 -47 204 -28 269 6 22 9 41 7 43 -2 2 -26 6 -54
10 -50 5 -51 7 -72 55 -26 61 -20 93 20 116 17 9 29 19 27 22 -2 3 -18 37 -36
75 -145 311 -208 417 -345 584 -42 52 -96 124 -120 160 -63 98 -137 183 -224
257 -104 90 -374 281 -436 310 -61 28 -166 33 -222 9 -81 -34 -236 -182 -324
-310 -48 -70 -148 -284 -161 -346 -11 -51 -8 -165 7 -237 6 -28 9 -30 22 -20
24 20 40 67 52 158 6 47 20 105 31 130 l20 45 25 -40 c34 -57 33 -147 -6 -295
-44 -164 -48 -277 -20 -515 15 -126 21 -219 17 -275 -10 -165 18 -318 93 -511
42 -109 150 -447 150 -472 0 -31 -64 -47 -275 -68 -115 -11 -224 -18 -243 -14
-56 10 -396 296 -512 429 -92 107 -263 355 -282 411 -10 29 -15 65 -12 85 4
27 -3 50 -31 102 -41 77 -75 108 -118 108 -27 0 -19 8 76 76 59 41 109 80 113
85 7 12 -362 397 -521 542 -330 301 -678 540 -996 683 -414 186 -570 209
-1029 155 -145 -18 -314 -35 -375 -39 -116 -7 -274 0 -313 15 l-23 9 30 41
c58 81 342 248 523 307 133 45 215 59 348 59 233 1 342 -40 652 -246 292 -195
481 -293 873 -457 204 -85 380 -170 565 -272 332 -183 403 -209 575 -210 111
0 270 31 305 60 19 16 20 29 20 202 0 102 -1 223 -2 270 -1 47 2 155 6 240 10
183 1 242 -49 321 -93 148 -99 164 -99 289 -1 107 -4 120 -33 182 -47 99 -57
162 -57 355 l-1 172 33 34 c45 46 53 85 46 202 -10 152 14 185 132 185 71 0
107 -16 177 -78 74 -64 194 -127 274 -142 34 -7 66 -11 71 -9 15 6 -39 118
-77 162 -18 20 -54 48 -80 62 -61 34 -78 63 -90 163 -12 98 -36 148 -100 210
-26 26 -58 62 -70 80 l-22 32 32 28 c18 15 39 32 47 38 51 38 -156 583 -435
1140 -134 268 -136 274 -137 341 -1 69 9 123 22 122 4 0 122 -162 262 -360
140 -197 257 -358 260 -356 59 32 54 33 137 -34 102 -82 259 -242 337 -343 35
-45 96 -133 135 -196 40 -63 80 -121 89 -127 23 -19 66 -16 85 5 15 16 -4 45
-295 447 -527 729 -637 892 -883 1305 -70 118 -141 232 -156 252 -48 60 -72
124 -67 179 4 41 2 52 -19 71 -13 12 -31 47 -40 78 -33 117 -115 250 -236 384
-91 101 -301 285 -409 357 -48 32 -104 80 -125 106 -21 27 -71 79 -111 118
-77 73 -96 101 -116 169 -11 37 -20 46 -103 96 -51 30 -92 58 -93 61 0 3 24
11 53 18 82 21 83 24 10 66 -38 21 -98 65 -135 96 -221 188 -379 284 -893 538
-242 120 -602 302 -800 406 -940 490 -1050 540 -1505 683 -609 192 -938 298
-1280 414 -482 164 -706 230 -1296 382 -375 96 -430 122 -538 256 -30 37 -82
95 -114 129 -97 100 -142 204 -163 374 -10 81 -2 164 18 200 2 4 39 -24 81
-63 273 -251 623 -379 1122 -410 436 -27 566 -52 792 -156 197 -90 290 -105
479 -77 168 24 505 24 674 0 255 -38 592 -127 845 -224 365 -140 724 -331
1260 -671 135 -85 337 -212 449 -281 434 -269 673 -435 990 -689 475 -381 760
-650 1137 -1073 320 -360 361 -417 423 -592 17 -48 44 -120 60 -160 23 -56 30
-93 34 -167 9 -170 85 -397 287 -851 49 -111 96 -201 118 -228 20 -24 47 -55
59 -70 17 -21 24 -23 34 -14 10 10 -4 48 -67 190 -43 97 -81 181 -83 186 -2 4
25 -6 60 -24 59 -29 64 -30 74 -14 33 57 36 58 82 33 l42 -23 44 43 c35 35 50
43 73 40 25 -3 31 -10 45 -53 19 -56 39 -71 39 -28 0 16 9 65 21 109 12 43 31
139 44 213 17 95 32 152 55 197 l31 64 -29 137 c-17 76 -33 173 -37 215 -5 64
-15 95 -52 170 -25 51 -82 181 -127 288 -90 217 -134 299 -208 397 -40 54 -51
77 -59 127 -13 82 -42 140 -135 274 -44 62 -97 140 -119 175 -32 49 -50 67
-90 85 -62 29 -101 66 -155 146 -26 39 -62 76 -94 96 -156 103 -235 187 -323
345 -66 119 -89 139 -163 142 -33 1 -64 4 -69 7 -5 4 -4 26 4 55 l13 50 -52
12 c-83 19 -86 22 -86 87 0 79 -13 94 -84 97 -72 3 -95 19 -161 113 -27 39
-63 78 -80 88 -40 23 -45 32 -33 65 13 38 -7 70 -90 139 -36 30 -117 102 -181
160 -299 271 -456 386 -831 605 -102 60 -257 156 -345 215 -221 147 -312 200
-477 280 -205 98 -1107 434 -1418 528 -362 109 -701 173 -1240 232 l-195 22
-305 124 c-929 376 -945 383 -1055 457 -95 63 -274 206 -285 227 -21 40 147
287 223 326 17 9 54 24 83 35 69 23 123 60 229 159 92 85 151 121 231 141 39
10 63 26 118 81 79 79 131 112 310 200 127 62 132 63 210 63 100 0 161 -26
256 -110 36 -31 104 -83 151 -115 95 -64 151 -122 224 -231 50 -73 203 -251
229 -266 8 -4 135 -125 283 -269 239 -233 277 -274 348 -380 147 -218 282
-377 418 -492 149 -126 306 -219 795 -469 330 -169 303 -161 419 -129 75 21
131 5 225 -66 86 -65 151 -103 390 -227 229 -120 357 -205 493 -326 159 -142
246 -214 470 -385 232 -178 275 -206 331 -215 49 -8 53 -2 61 97 l6 67 -82
159 c-44 88 -81 172 -81 186 0 21 8 29 41 43 41 16 41 16 35 57 -11 67 -41
122 -108 195 -35 38 -94 106 -133 151 -38 46 -93 98 -121 116 -67 43 -81 75
-60 138 14 41 14 51 2 80 -7 18 -29 43 -47 56 -19 13 -64 46 -101 74 l-68 50
0 72 0 72 -30 7 c-22 5 -39 21 -64 59 -42 66 -92 106 -230 187 -252 146 -429
301 -1186 1043 -829 813 -1075 1019 -1458 1220 -164 86 -191 104 -385 254 -92
71 -145 118 -140 125 3 7 25 37 48 67 23 30 67 93 99 140 50 77 58 96 72 175
31 176 97 315 254 535 72 101 98 128 129 142 57 24 105 77 198 218 45 69 93
142 107 162 34 50 89 76 183 87 43 5 91 14 107 20 44 18 137 86 271 201 66 57
152 123 190 148 39 25 94 71 123 103 40 44 74 68 149 106 194 96 539 346 687
497 54 56 134 138 177 182 43 45 95 89 115 99 35 16 38 16 63 -2 31 -22 84
-128 141 -280 109 -293 286 -599 625 -1083 56 -80 120 -176 143 -215 50 -84
226 -461 407 -870 220 -497 242 -575 285 -1025 37 -378 98 -786 156 -1044 22
-100 24 -147 6 -145 -13 1 -86 101 -296 404 -115 165 -243 342 -285 394 -97
120 -870 912 -1067 1093 -228 209 -489 427 -604 505 -116 78 -208 105 -440
128 -198 20 -273 34 -405 79 -185 64 -394 71 -591 21 -114 -29 -273 -103 -303
-141 -46 -58 -19 -135 80 -230 675 -646 669 -641 1084 -974 285 -229 738 -633
922 -823 122 -126 169 -197 256 -385 153 -334 312 -586 532 -843 71 -84 112
-145 194 -295 58 -104 124 -222 148 -262 76 -125 678 -999 1011 -1467 263
-369 360 -491 536 -675 134 -139 185 -224 320 -522 93 -206 122 -303 230 -778
47 -206 118 -517 158 -690 l72 -315 0 -206 1 -206 56 -109 c106 -204 101 -159
103 -854 2 -333 8 -780 15 -995 14 -459 17 -684 13 -930 -3 -160 -1 -188 18
-255 11 -41 25 -106 31 -143 17 -119 67 -185 191 -249 80 -41 179 -46 225 -12
48 35 152 166 183 229 28 56 30 73 45 275 20 273 28 313 113 552 116 324 170
535 251 978 79 430 142 730 166 787 8 19 23 47 34 62 48 67 39 141 -89 685
-49 210 -96 424 -105 476 -8 52 -17 107 -20 121 -4 24 6 32 111 98 91 58 127
87 178 148 118 140 145 204 166 391 25 224 -35 425 -181 602 -34 41 -77 108
-97 148 -34 71 -35 76 -43 245 -11 234 -10 527 1 612 6 39 15 183 20 320 5
138 16 329 25 425 27 319 19 455 -30 510 -11 13 -12 29 -4 84 5 38 8 70 7 71
-56 31 -55 29 -61 137 -4 57 -2 209 4 338 9 187 8 256 -2 340 -16 127 -16 163
0 357 11 135 10 177 -8 395 -18 211 -56 546 -92 813 -15 109 -43 188 -92 258
-40 58 -42 63 -42 136 0 42 6 109 14 150 18 93 5 168 -46 261 -52 96 -61 126
-83 298 -58 460 -79 709 -86 1016 l-7 308 -69 -7 c-82 -8 -108 7 -108 60 0 39
18 56 92 88 71 31 121 83 177 182 57 101 65 158 29 213 -14 20 -29 48 -33 62
-4 13 -9 121 -10 240 -2 118 -13 330 -25 470 -18 215 -32 306 -86 585 -35 182
-73 366 -84 409 -15 59 -18 96 -14 146 5 54 2 82 -15 136 -12 38 -28 80 -37
94 -14 23 -15 17 -10 -87 5 -126 8 -121 -67 -104 -37 9 -39 8 -49 -20 -14 -40
-25 -37 -50 17 l-23 45 34 64 35 64 -12 213 c-7 117 -17 236 -23 263 -19 94
11 169 80 204 l40 21 -6 53 c-7 72 6 93 83 129 61 28 512 146 953 249 l184 43
121 -12 c244 -25 496 -78 696 -148 58 -21 191 -82 295 -136 105 -54 318 -162
475 -239 383 -190 417 -214 585 -418 171 -209 342 -365 560 -511 199 -133 309
-188 742 -370 l222 -94 6 -37 c10 -57 63 -161 151 -294 44 -66 89 -133 101
-150 24 -31 89 -206 230 -615 146 -424 164 -500 268 -1095 30 -176 77 -437
104 -580 88 -461 102 -652 131 -1690 11 -379 21 -539 41 -651 18 -103 58 -240
95 -326 31 -73 35 -90 30 -138 -4 -37 -1 -72 9 -105 8 -28 15 -51 15 -52 0 -2
-18 -6 -40 -9 -22 -4 -40 -8 -40 -10 0 -3 12 -22 26 -44 26 -39 27 -43 20
-153 -11 -177 -24 -276 -72 -519 -44 -226 -44 -230 -44 -441 l0 -213 -35 -31
c-19 -17 -35 -40 -35 -50 0 -42 -52 -169 -143 -346 -53 -103 -105 -217 -117
-253 -24 -79 -26 -176 -4 -240 20 -59 12 -91 -56 -249 -28 -63 -77 -184 -109
-267 -87 -229 -117 -286 -253 -489 -245 -365 -368 -655 -539 -1271 -40 -146
-76 -265 -80 -262 -9 5 -45 141 -65 244 -11 60 -15 116 -11 190 4 93 10 120
51 233 75 210 117 384 187 791 21 127 44 247 51 266 24 77 62 134 153 230 109
116 181 213 236 322 48 95 64 144 179 530 50 170 130 422 179 560 49 139 127
371 174 516 51 157 108 308 140 375 30 61 57 121 60 132 3 11 8 30 12 43 6 22
6 22 -64 16 -66 -6 -74 -5 -109 20 -35 24 -42 25 -88 17 -89 -17 -161 8 -233
83 l-44 45 -40 -57 c-49 -69 -353 -649 -449 -856 -38 -83 -124 -290 -192 -460
-248 -623 -380 -896 -776 -1611 -98 -176 -208 -381 -245 -455 -37 -74 -108
-208 -157 -298 -148 -267 -247 -503 -313 -747 -15 -54 -47 -145 -72 -204 -60
-144 -142 -356 -159 -411 -40 -135 -194 -903 -238 -1190 -48 -310 -101 -905
-91 -1005 9 -80 44 -241 62 -288 18 -45 33 -54 104 -62 83 -10 99 -21 99 -69
0 -53 43 -146 67 -146 10 0 42 -9 72 -21 30 -11 64 -18 76 -15 23 6 173 171
238 261 19 28 47 73 60 100 14 28 107 356 207 730 100 374 188 691 195 705 8
14 39 49 69 79 95 91 121 136 461 796 57 110 117 236 134 280 31 80 68 128
119 155 13 6 32 31 42 55 10 24 28 48 39 54 18 10 21 6 35 -43 9 -30 16 -59
16 -65 0 -6 17 0 38 13 20 14 81 39 135 56 53 17 111 43 128 58 l31 26 -7 -45
c-4 -24 -24 -105 -45 -179 -21 -74 -59 -216 -85 -315 -93 -357 -161 -537 -433
-1140 -77 -170 -152 -339 -167 -375 -90 -213 -144 -273 -266 -287 -57 -7 -79
6 -79 46 0 33 -10 48 -35 53 -27 4 -31 -26 -10 -73 26 -56 16 -96 -60 -260
-37 -79 -133 -290 -213 -469 -139 -310 -163 -382 -139 -406 18 -18 56 18 85
79 30 63 71 107 99 107 9 0 12 -7 10 -17 -3 -10 -8 -35 -12 -56 -8 -47 -190
-451 -286 -637 -67 -128 -137 -238 -318 -498 -144 -206 -203 -347 -221 -524
-6 -56 -18 -133 -26 -171 -23 -94 -17 -109 57 -141 34 -15 76 -38 93 -53 l31
-26 3 46 c3 52 10 57 37 27 15 -17 18 -35 16 -110 -2 -84 8 -130 28 -130 9 0
30 71 65 217 25 105 77 236 84 213 4 -11 -10 -117 -24 -192 -6 -28 -5 -28 44
-28 71 0 102 28 137 127 39 109 92 209 128 239 16 14 31 23 34 20 2 -2 -14
-46 -35 -97 -70 -165 -84 -250 -27 -160 19 29 51 74 73 100 119 143 176 270
202 452 13 98 35 140 81 165 32 16 32 16 25 -8 -4 -12 -12 -131 -16 -263 -7
-203 -13 -257 -34 -350 -60 -255 -279 -877 -351 -993 -47 -75 -95 -201 -95
-246 0 -39 -29 -116 -62 -164 -44 -65 -81 -208 -73 -286 7 -61 6 -62 -33 -110
-22 -26 -62 -91 -88 -144 -27 -53 -67 -117 -90 -143 -29 -33 -43 -59 -48 -90
-10 -67 -33 -132 -93 -263 -60 -133 -81 -200 -154 -491 -57 -230 -67 -258
-113 -325 -45 -65 -60 -138 -51 -244 13 -160 75 -742 86 -811 48 -301 161
-603 344 -915 82 -140 166 -307 245 -485 33 -74 82 -179 110 -235 117 -233
189 -411 315 -775 46 -132 113 -317 149 -411 96 -253 116 -335 127 -544 28
-536 47 -662 134 -889 51 -136 73 -241 95 -456 43 -428 87 -701 206 -1294 94
-467 92 -448 120 -1041 20 -394 21 -758 4 -940 -17 -189 -52 -359 -156 -770
-107 -417 -128 -471 -230 -590 -115 -133 -139 -170 -126 -191 18 -29 15 -40
-22 -79 -34 -35 -99 -130 -186 -270 -62 -101 -290 -331 -395 -399 -44 -28
-116 -65 -160 -82 -69 -27 -99 -33 -220 -40 -201 -13 -330 -27 -495 -54 -170
-29 -777 -75 -978 -75 -166 0 -292 18 -449 64 -95 27 -162 39 -333 56 -118 11
-285 32 -370 45 -85 13 -204 30 -265 37 -149 18 -799 17 -1065 -1 -294 -19
-350 -9 -595 109 -249 121 -532 297 -800 497 -150 112 -216 182 -381 403 -314
421 -469 652 -667 996 -134 235 -169 302 -227 440 -23 56 -51 112 -62 124 -21
23 -28 23 -114 5 -29 -7 -32 -5 -48 37 l-18 43 -43 -108 c-33 -82 -55 -121
-92 -161 -41 -45 -53 -68 -76 -142 -49 -167 -57 -266 -33 -432 8 -53 20 -155
26 -227 6 -71 15 -157 21 -190 28 -158 107 -337 204 -460 30 -38 89 -119 131
-179 227 -323 392 -521 533 -639 97 -81 492 -378 672 -505 133 -94 273 -163
376 -186 40 -9 184 -23 320 -31 372 -22 360 -20 533 -87 189 -73 666 -218 829
-252 263 -56 339 -61 796 -57 892 9 1088 7 1250 -14 104 -14 207 -20 335 -19
202 0 308 14 465 61 l90 27 395 1 c448 1 441 0 840 95 234 56 289 77 485 179
294 154 451 264 571 401 191 219 275 377 344 645 49 190 100 335 212 605 166
397 201 534 202 785 0 121 -5 182 -19 250 -16 72 -20 130 -20 290 0 189 2 206
29 310 16 61 32 137 35 170 l7 60 12 -40 c7 -25 12 -115 12 -244 0 -143 3
-202 10 -195 22 22 62 159 86 301 57 324 38 816 -57 1478 -40 275 -72 428
-179 850 -49 193 -107 440 -130 550 -49 233 -77 352 -205 840 -52 198 -121
482 -155 631 -82 371 -150 593 -227 748 -14 26 -54 136 -89 243 -75 227 -71
218 -258 540 -192 332 -202 353 -291 618 -42 127 -123 354 -180 505 -135 361
-271 768 -286 854 -13 75 -7 192 9 208 7 7 17 0 31 -23 l20 -33 11 30 c6 16
13 36 16 44 19 54 36 182 49 364 37 523 77 759 215 1275 39 146 80 282 92 303
12 21 45 53 75 73 65 43 106 105 134 200 11 38 31 88 44 110 26 44 115 136
115 118 0 -6 -16 -62 -35 -126 -19 -63 -35 -118 -35 -121 0 -3 8 -6 18 -6 25
0 51 30 110 126 101 163 168 315 222 500 75 256 160 450 288 656 70 111 301
580 429 868 33 74 84 203 113 285 73 209 99 272 201 492 116 252 127 278 224
543 115 314 155 409 250 610 121 257 170 390 220 605 37 156 57 225 65 225 24
0 54 -122 100 -402 65 -400 88 -505 160 -738 92 -293 113 -355 169 -480 34
-77 54 -139 61 -188 10 -73 32 -132 49 -132 5 0 19 16 31 35 12 19 25 35 29
35 14 0 81 -146 121 -264 23 -66 75 -203 115 -306 106 -264 225 -686 225 -793
0 -39 7 -71 21 -100 37 -72 67 -172 99 -335 18 -86 43 -184 55 -217 13 -33 41
-107 63 -165 21 -58 69 -160 106 -228 l67 -122 41 21 c22 11 41 19 43 17 9 -9
124 -281 175 -413 72 -188 122 -352 165 -550 45 -204 76 -261 174 -325 20 -13
27 -36 51 -170 16 -85 55 -292 89 -460 106 -536 127 -737 118 -1120 -6 -248
-21 -422 -58 -674 -34 -229 -62 -296 -188 -444 -196 -230 -358 -502 -771
-1291 -299 -571 -432 -900 -549 -1352 -85 -327 -117 -529 -126 -799 -9 -261
-22 -411 -44 -499 -22 -85 -104 -495 -152 -756 -26 -145 -32 -206 -39 -420 -5
-149 -15 -285 -24 -337 -16 -85 -24 -115 -85 -348 -69 -260 -97 -523 -88 -830
13 -446 80 -779 297 -1465 101 -320 150 -433 260 -604 89 -140 108 -182 180
-403 88 -267 195 -466 366 -678 192 -238 388 -381 611 -445 253 -74 593 -149
853 -189 218 -34 435 -57 965 -105 874 -80 958 -85 1370 -85 l380 0 365 42
c702 81 1148 148 1392 208 270 66 544 167 883 325 217 101 306 158 405 258
118 118 320 350 320 366 0 8 -1 15 -2 15 -2 0 -25 -7 -52 -15 -27 -8 -51 -14
-52 -12 -2 2 41 69 96 149 55 81 100 151 100 156 0 6 -9 31 -19 56 l-20 45
102 91 c98 87 104 91 147 89 78 -3 85 -1 104 36 29 53 105 119 182 158 46 23
83 51 109 83 112 134 135 168 152 220 11 37 29 66 51 85 26 23 41 52 68 132
53 157 71 240 85 386 13 149 7 269 -17 351 -14 47 -14 64 -4 127 17 99 8 191
-31 307 -35 106 -60 146 -90 146 -11 0 -50 -14 -86 -32 -43 -20 -70 -28 -78
-22 -106 81 -204 124 -319 139 -38 5 -48 4 -43 -7 2 -7 35 -82 72 -167 62
-143 67 -158 63 -212 -3 -43 -12 -71 -37 -111 -19 -29 -35 -69 -37 -88 -32
-324 -54 -379 -221 -547 -121 -123 -237 -260 -409 -483 -279 -361 -554 -580
-949 -755 -361 -159 -748 -239 -1275 -264 -124 -6 -326 -16 -450 -21 -124 -6
-270 -17 -325 -25 -196 -29 -317 -35 -865 -46 -601 -12 -587 -12 -1539 47
-413 25 -456 30 -551 65 -213 79 -370 211 -455 384 -42 85 -291 667 -350 820
-68 176 -139 403 -198 640 -74 293 -193 812 -206 902 -17 112 -13 433 9 703
30 364 35 611 19 840 -8 107 -14 233 -14 280 l0 85 82 170 c100 208 129 291
192 544 106 425 307 1081 393 1286 l34 80 104 52 104 51 -96 48 -96 47 -5 51
c-2 28 -1 79 4 114 11 78 -1 135 -46 224 -19 37 -35 76 -35 87 0 25 31 91 42
91 5 0 53 -23 108 -50 l100 -50 25 49 c28 55 30 53 -47 66 -36 6 -38 8 -40 45
-1 22 -2 48 -2 57 -1 12 13 21 45 30 l46 13 -33 106 c-60 189 -58 207 33 325
26 32 44 61 42 63 -2 2 -19 11 -36 20 -18 9 -33 21 -33 27 0 21 222 419 234
419 9 0 11 -17 8 -59 l-4 -59 67 -21 c37 -12 73 -21 81 -21 7 0 39 18 70 40
32 22 61 37 65 33 4 -5 42 -177 85 -383 l79 -375 48 -52 c65 -69 69 -100 27
-215 -18 -49 -35 -88 -40 -88 -12 0 -20 39 -20 100 0 30 -5 72 -11 93 -10 38
-40 77 -58 77 -5 0 -16 -30 -25 -68 -35 -156 -174 -1024 -262 -1637 -52 -363
-110 -757 -129 -875 -91 -566 -122 -1027 -112 -1675 7 -503 -11 -871 -53
-1080 -17 -88 -23 -304 -11 -400 31 -237 113 -434 229 -551 93 -93 264 -159
392 -152 49 3 56 6 64 30 12 35 9 37 -56 52 -39 8 -56 18 -65 36 -7 14 -9 28
-5 32 6 6 272 38 315 38 4 0 7 39 7 88 0 108 19 154 84 207 26 21 54 51 62 67
9 17 19 81 25 148 9 116 35 227 61 263 12 16 19 16 87 3 l74 -14 -7 103 c-9
123 0 137 49 79 24 -28 39 -62 53 -121 36 -146 120 -288 285 -479 116 -134
155 -170 204 -183 49 -14 290 -14 298 -1 3 6 32 28 64 49 53 36 58 44 70 93
15 70 32 94 79 108 20 6 42 13 49 15 9 3 3 26 -17 76 -17 40 -29 73 -28 73 25
9 45 29 52 52 4 16 15 45 24 64 l16 35 7 -30 c3 -16 7 -46 8 -65 1 -19 10 -53
19 -75 15 -32 23 -39 47 -40 23 0 30 -6 40 -35 12 -35 44 -60 78 -60 28 0 77
-28 137 -79 91 -78 123 -98 174 -110 100 -24 145 -57 311 -224 88 -89 205
-218 260 -287 55 -69 136 -162 180 -206 128 -130 394 -292 455 -279 26 6 28 8
19 38 -17 59 -13 66 28 57 47 -11 53 -7 53 36 l0 34 80 -40 c45 -22 85 -38 89
-35 17 10 32 100 30 180 -1 68 2 92 20 128 16 34 20 55 16 81 -13 68 -173 325
-460 741 -201 292 -385 593 -428 703 -20 52 -31 102 -35 152 -4 62 -10 83 -34
118 -45 65 -29 70 41 10 l63 -53 -33 -5 -34 -5 65 -37 c36 -20 75 -39 87 -42
12 -4 45 -33 73 -65 36 -43 102 -94 243 -187 213 -143 280 -173 387 -174 36 0
141 -16 234 -35 93 -19 175 -35 182 -35 9 0 14 12 14 35 0 52 22 95 77 156 71
80 104 125 117 164 l12 34 35 -23 c19 -12 46 -31 61 -41 l28 -17 -16 38 c-94
224 -350 615 -604 919 -412 494 -595 757 -802 1150 -49 93 -150 260 -223 370
-244 368 -378 605 -712 1260 -215 421 -259 499 -405 715 -159 237 -196 325
-323 751 -52 174 -105 342 -119 372 -43 99 -139 181 -233 202 -30 6 -33 10
-33 45 0 46 65 243 114 347 67 141 155 249 231 284 41 19 144 24 195 10 27 -7
19 6 236 -415 76 -148 173 -321 214 -385 178 -271 369 -454 650 -621 100 -60
147 -99 360 -297 407 -378 499 -460 643 -570 116 -88 273 -180 285 -168 2 2
-1 43 -7 92 -27 222 8 373 115 492 25 28 49 51 53 51 12 0 148 -213 189 -295
119 -241 156 -516 131 -970 -19 -334 -17 -621 5 -750 40 -238 90 -384 229
-660 52 -105 129 -269 172 -365 80 -182 280 -606 452 -960 131 -268 165 -304
327 -344 89 -22 168 -63 243 -125 33 -28 72 -55 86 -60 24 -10 27 -8 32 22 7
46 35 256 35 267 0 6 27 10 61 10 83 0 184 25 247 61 75 43 74 35 19 181 -15
38 -25 68 -23 68 1 0 27 -18 56 -40 56 -42 92 -49 122 -26 10 7 18 16 18 19 0
5 -173 146 -199 161 -11 7 -12 -1 -6 -50 l7 -59 -36 45 c-73 92 -91 137 -91
225 0 73 -3 85 -36 145 -20 36 -86 160 -147 276 -61 117 -150 275 -197 353
-253 413 -313 640 -450 1716 -25 198 -55 405 -67 460 -41 200 -117 389 -221
549 -30 46 -80 133 -111 193 -102 199 -136 223 -326 234 -69 4 -157 11 -196
14 l-71 8 -31 -57 c-18 -31 -35 -56 -39 -56 -3 0 -48 27 -100 60 -80 51 -93
57 -87 38 11 -30 7 -93 -6 -118 -28 -52 -66 8 -56 87 7 54 -13 99 -58 128 -23
15 -25 15 -50 -9 -43 -43 -58 -32 -76 59 -19 91 -64 218 -104 288 -45 80 -71
111 -405 482 -304 339 -312 352 -209 333 56 -10 496 -172 570 -209 112 -57
237 -168 333 -297 45 -61 186 -182 247 -213 26 -13 59 -21 92 -20 67 0 267 28
277 38 17 18 180 400 181 423 1 44 -82 301 -134 415 -73 159 -95 222 -134 380
-19 77 -43 173 -54 214 -39 145 -43 397 -10 606 26 165 147 558 187 605 14 17
58 53 97 79 71 48 130 101 147 133 10 19 22 21 -139 -26 -52 -15 -52 -15 -69
10 -15 24 -32 88 -33 130 0 20 7 21 116 28 63 4 127 11 142 17 15 6 50 34 77
63 39 42 58 55 90 59 62 10 129 58 172 124 21 32 55 80 75 106 21 27 38 54 38
61 0 7 -13 33 -29 58 l-30 46 77 63 c42 35 114 99 160 143 64 61 86 76 95 67
9 -9 2 -31 -32 -94 l-44 -82 49 43 c82 73 104 113 104 194 0 41 6 80 15 98 30
58 151 138 250 165 59 17 64 16 265 -15 113 -17 274 -45 357 -61 326 -62 676
-95 920 -87 144 5 157 8 448 81 379 96 723 192 798 222 143 59 260 155 379
313 141 188 327 352 497 437 29 15 93 42 140 60 214 81 337 162 526 345 200
193 446 412 590 524 72 55 252 202 400 326 381 319 462 381 532 413 94 42 527
140 878 198 208 34 251 47 360 102 62 32 102 63 176 137 53 52 115 123 138
158 58 86 149 181 257 268 208 168 373 390 494 667 28 63 95 236 149 384 55
147 109 293 121 323 34 86 57 209 80 425 12 110 25 238 30 285 6 47 10 162 10
255 0 258 -20 457 -91 915 -70 455 -69 514 5 748 106 330 159 623 203 1112 23
266 23 719 0 886 -54 384 -213 1121 -343 1586 -30 108 -70 264 -89 347 -38
170 -56 215 -109 284 -20 27 -46 68 -58 92 l-21 43 32 40 c75 95 106 220 82
333 -14 66 -88 253 -149 373 -70 141 -268 430 -458 666 -178 222 -332 353
-875 743 -182 130 -355 280 -445 383 -61 71 -74 94 -74 132 0 49 -28 125 -61
165 -17 21 -66 61 -108 89 -92 62 -252 221 -385 383 -55 66 -114 134 -132 151
-39 37 -57 94 -59 184 -1 65 -1 65 -113 241 -98 154 -111 180 -106 207 11 56
-156 399 -298 612 -204 305 -363 532 -408 583 -46 51 -243 220 -475 407 -172
139 -304 291 -387 443 -24 43 -63 106 -87 140 -67 93 -188 294 -276 457 -95
178 -130 227 -286 405 -738 838 -1512 1374 -2211 1529 -500 111 -907 75 -1271
-111 -99 -51 -118 -58 -167 -57 -52 2 -471 89 -570 119 -25 7 -84 16 -132 19
-148 10 -209 26 -531 140 -183 65 -294 87 -393 80 -64 -5 -73 -3 -124 26 -92
52 -143 71 -197 77 -43 5 -59 1 -98 -21 -26 -14 -51 -26 -56 -26 -5 0 -9 21
-9 48 l0 47 -133 28 c-163 33 -220 54 -211 76 3 9 8 21 10 27 3 6 -32 30 -78
53 -72 37 -87 41 -111 32 -15 -6 -32 -22 -39 -36 -6 -14 -14 -25 -17 -25 -16
1 -160 49 -187 63 -33 17 -32 17 -327 -13 -54 -6 -128 -17 -165 -25 -56 -13
-70 -20 -84 -45 -19 -34 -45 -42 -206 -63 -78 -11 -120 -21 -133 -33 -14 -13
-44 -19 -107 -24 -70 -5 -105 -14 -175 -44 -48 -20 -133 -49 -190 -63 -70 -17
-121 -36 -162 -62 -33 -21 -139 -76 -236 -123 -97 -47 -209 -106 -248 -132
-188 -121 -289 -155 -711 -240 -341 -68 -561 -124 -720 -183 -103 -37 -431
-193 -598 -282 -175 -94 -554 -393 -864 -682 -198 -184 -254 -281 -348 -604
-34 -115 -77 -259 -96 -320 -75 -237 -107 -494 -102 -815 3 -149 2 -157 -22
-199 -14 -25 -30 -46 -36 -48 -5 -2 -20 15 -33 37 -12 22 -35 47 -51 55 -44
23 -60 53 -60 115 0 134 -26 214 -130 395 -50 88 -86 198 -95 295 -10 111 -41
170 -119 226 -25 18 -29 28 -36 103 -5 45 -16 103 -24 129 -9 26 -17 88 -19
138 -3 109 22 70 -290 465 l-232 294 -100 66 c-55 37 -257 172 -450 300 -441
293 -581 369 -845 456 -107 35 -290 89 -405 118 -231 59 -356 100 -455 150
-36 17 -105 42 -155 54 -107 26 -179 64 -397 206 -89 59 -209 129 -265 156
-189 92 -459 167 -678 189 -186 19 -268 46 -346 117 l-40 36 -172 4 -172 4
-210 -55z m11681 -133 c19 -20 32 -38 30 -40 -2 -2 -48 9 -102 26 -89 26 -97
30 -74 39 14 6 45 11 69 11 38 1 48 -4 77 -36z m-14456 -413 c0 -5 -18 -10
-41 -10 -24 0 -38 4 -34 10 3 6 22 10 41 10 19 0 34 -4 34 -10z m975 -9 c6
-10 -82 -27 -91 -18 -4 3 -3 10 1 16 8 13 82 15 90 2z m13070 -592 c298 -17
502 -54 695 -126 180 -67 220 -95 220 -153 0 -20 -74 -354 -164 -741 -214
-916 -226 -982 -245 -1285 -7 -101 -20 -195 -40 -286 -37 -168 -86 -449 -102
-586 -7 -62 -9 -184 -7 -300 4 -170 10 -219 37 -357 28 -139 53 -211 86 -253
3 -3 119 11 209 24 l39 6 -7 -98 c-7 -105 -80 -521 -121 -689 -13 -55 -40
-151 -59 -213 -51 -160 -68 -231 -130 -537 -30 -148 -71 -337 -91 -420 -79
-338 -155 -1002 -185 -1625 -11 -236 -19 -314 -79 -756 -50 -368 -75 -601
-146 -1374 -67 -726 -81 -859 -145 -1367 -56 -439 -59 -494 -30 -578 14 -40
20 -84 20 -142 0 -94 13 -134 56 -175 38 -35 80 -52 220 -88 189 -49 276 -38
334 42 81 111 207 572 284 1033 56 337 76 577 76 910 0 244 6 300 60 560 56
273 179 1125 187 1290 4 86 12 142 29 198 38 123 53 233 69 492 17 259 27 357
80 765 33 252 55 466 55 538 0 32 -1 33 -34 27 -32 -7 -33 -6 -39 32 -37 231
-32 635 12 1078 47 473 74 809 75 950 1 116 -1 135 -22 180 -40 86 -110 145
-235 196 -99 41 -117 60 -117 123 0 70 11 84 44 63 14 -9 27 -17 30 -17 3 0
21 28 40 62 23 41 37 57 41 48 3 -9 15 -49 28 -89 l22 -75 74 242 c41 132 77
246 81 253 17 27 30 -6 30 -77 0 -56 3 -74 14 -74 7 0 16 14 19 33 207 1212
274 1793 293 2550 l7 268 36 -5 c20 -3 178 -19 351 -36 408 -39 380 -34 380
-72 0 -30 -217 -740 -401 -1308 -185 -573 -225 -754 -270 -1220 -31 -316 -26
-595 18 -875 8 -49 42 -85 82 -85 19 0 35 -8 45 -22 l15 -21 83 22 c92 24 138
51 215 124 35 33 53 58 53 74 0 31 -39 66 -88 81 -71 22 -72 34 -6 107 110
122 156 190 224 325 126 252 184 433 214 671 11 85 61 307 156 687 77 309 146
599 154 644 8 46 20 166 27 268 6 102 13 221 16 266 6 97 -2 118 -89 215 -32
36 -58 68 -58 70 0 11 186 -12 257 -31 189 -52 352 -105 403 -130 58 -30 413
-287 689 -499 188 -145 274 -206 446 -314 207 -131 313 -235 425 -417 75 -122
137 -195 274 -321 157 -145 226 -224 291 -334 67 -114 127 -187 207 -251 82
-67 442 -320 791 -556 153 -103 313 -216 355 -250 110 -89 256 -238 300 -307
21 -32 96 -181 167 -332 161 -343 234 -490 286 -576 55 -90 239 -331 313 -410
32 -35 57 -65 55 -67 -2 -3 -141 -47 -309 -100 -601 -188 -824 -277 -1334
-534 -200 -100 -461 -266 -514 -326 -17 -19 -51 -78 -76 -130 -75 -157 -130
-204 -514 -445 -308 -193 -446 -304 -661 -531 -310 -328 -472 -522 -614 -737
-151 -228 -208 -353 -222 -485 -7 -62 -10 -69 -40 -88 -42 -26 -52 -55 -58
-161 -3 -47 -10 -96 -17 -109 l-12 -23 78 8 c113 10 193 38 245 84 l44 38 32
-24 33 -25 101 88 c205 179 234 204 239 204 3 0 21 -21 39 -46 l35 -46 -20
-37 c-13 -24 -45 -53 -99 -88 -44 -29 -97 -64 -117 -78 -42 -29 -94 -92 -85
-102 13 -12 175 17 288 51 162 49 219 71 275 108 114 75 924 691 1359 1034
573 451 659 516 900 680 165 111 385 291 385 313 0 4 -23 18 -51 31 -58 27
-57 30 36 76 69 35 133 82 250 185 55 49 156 130 225 181 93 69 145 117 204
188 44 52 130 145 190 205 117 116 177 155 321 210 l70 26 29 -33 c125 -142
379 -409 431 -451 35 -29 245 -169 467 -311 471 -303 614 -402 674 -470 l45
-49 -78 -45 c-142 -82 -557 -275 -769 -358 -132 -52 -1068 -395 -1169 -429
-38 -13 -113 -28 -165 -34 -122 -14 -206 -36 -414 -111 -286 -103 -572 -181
-896 -245 -193 -37 -260 -54 -1070 -267 -543 -142 -592 -160 -770 -277 -52
-35 -185 -107 -295 -161 -342 -168 -503 -285 -980 -715 -362 -327 -407 -370
-528 -503 -254 -279 -415 -495 -719 -962 -107 -165 -190 -281 -303 -425 -175
-223 -240 -342 -463 -840 -71 -159 -147 -319 -168 -355 -34 -57 -39 -74 -42
-141 -4 -71 -2 -78 19 -93 13 -9 33 -16 45 -16 12 0 28 -7 35 -16 11 -14 2
-50 -64 -242 -86 -254 -114 -369 -123 -505 l-7 -92 37 34 c19 19 38 32 42 29
3 -4 -4 -37 -15 -74 -11 -37 -19 -70 -17 -72 2 -2 24 2 47 8 42 11 127 8 164
-6 32 -13 152 127 225 260 36 66 91 158 123 205 133 198 228 343 297 455 40
65 127 190 194 278 304 400 365 483 426 580 63 101 154 275 236 453 50 108 55
114 630 705 251 258 438 458 475 510 89 123 254 282 381 367 103 69 152 95
380 201 59 28 187 102 285 165 251 161 283 176 535 247 79 22 244 74 368 115
468 157 780 230 1215 285 255 31 582 93 1070 201 449 100 1025 270 1244 369
135 61 241 94 399 125 127 26 168 43 242 104 19 16 137 81 262 145 217 111
228 116 232 94 3 -13 12 -72 21 -133 39 -270 130 -573 239 -801 36 -75 66
-141 66 -147 0 -6 -48 -8 -137 -4 -288 12 -467 -15 -1048 -158 -438 -109 -563
-149 -755 -245 -250 -124 -421 -182 -743 -249 -336 -71 -926 -281 -1442 -513
-325 -147 -500 -205 -685 -227 -47 -6 -142 -18 -211 -26 -215 -25 -355 -69
-549 -173 l-104 -56 -131 -6 c-108 -6 -141 -11 -194 -33 -84 -34 -242 -130
-363 -220 -203 -151 -805 -726 -997 -952 -104 -123 -131 -149 -246 -242 -44
-36 -262 -237 -484 -447 -222 -209 -437 -406 -478 -436 -221 -162 -319 -260
-502 -504 -189 -253 -364 -520 -619 -948 -180 -303 -185 -312 -248 -506 l-53
-163 16 -49 c15 -48 17 -50 37 -37 12 8 71 42 131 76 61 35 115 67 121 72 7 6
40 7 77 3 100 -10 89 -20 417 376 160 194 394 435 789 812 190 182 383 369
428 417 45 47 84 84 86 81 11 -10 -42 -151 -91 -243 -86 -164 -653 -956 -694
-971 -20 -7 -40 -2 -91 21 -83 40 -122 48 -155 34 -39 -16 -51 -40 -68 -139
-15 -92 -58 -190 -133 -311 -54 -86 -99 -205 -170 -451 -33 -113 -69 -227 -81
-254 -50 -119 -44 -189 19 -216 l32 -13 -6 -96 c-4 -53 -8 -101 -10 -108 -10
-30 20 -21 51 16 55 63 85 80 189 101 140 29 175 48 282 152 89 88 95 92 134
89 50 -2 61 6 117 94 49 77 134 165 322 333 126 113 147 122 116 49 -8 -19
-145 -231 -304 -470 -292 -437 -357 -548 -445 -764 -62 -151 -96 -217 -151
-289 -43 -58 -62 -106 -80 -211 -5 -31 -5 -31 59 -48 129 -35 240 -3 381 112
40 32 150 121 245 198 194 155 308 253 419 360 41 40 104 90 140 111 206 122
434 357 784 807 161 207 192 245 416 513 238 285 336 391 454 493 55 47 170
154 256 236 315 306 583 518 1041 827 182 122 210 132 424 148 259 19 528 76
829 174 191 62 280 80 406 79 139 -1 203 -12 467 -83 319 -87 478 -155 887
-382 361 -201 629 -330 891 -430 124 -47 166 -70 153 -83 -3 -4 -82 14 -175
39 -311 85 -434 103 -798 116 -143 5 -323 17 -400 26 -183 21 -477 15 -651
-15 -231 -39 -757 -188 -877 -248 -38 -19 -159 -70 -270 -114 -110 -44 -225
-95 -254 -115 -121 -81 -188 -98 -438 -113 -194 -12 -306 -39 -466 -110 -63
-27 -199 -81 -304 -119 -232 -85 -274 -104 -395 -176 -108 -64 -170 -86 -247
-86 -77 0 -118 -21 -203 -105 -64 -63 -92 -82 -191 -129 -64 -30 -120 -61
-126 -68 -14 -17 -45 -230 -36 -239 5 -3 40 -16 79 -28 57 -16 79 -29 108 -61
20 -22 36 -46 36 -54 0 -9 18 -18 48 -24 27 -6 51 -18 57 -29 6 -10 26 -34 45
-54 38 -39 75 -53 235 -88 278 -62 443 -54 815 39 250 62 267 64 340 46 87
-22 219 -89 309 -156 85 -63 258 -240 453 -461 204 -233 331 -335 785 -631
153 -99 282 -184 286 -188 9 -7 -157 -116 -323 -213 -168 -97 -267 -145 -354
-172 -73 -23 -86 -31 -96 -56 -14 -34 -56 -72 -111 -99 -56 -29 -65 -26 -78
24 -24 98 -7 195 59 326 22 44 43 80 45 80 3 0 22 -7 42 -15 46 -19 53 -19 53
3 0 23 -55 89 -88 106 -15 7 -74 30 -132 51 -57 21 -165 68 -240 105 -74 37
-156 76 -182 85 -26 10 -50 25 -52 34 -21 67 -45 206 -40 226 5 19 -5 40 -45
94 -163 220 -408 360 -755 431 -511 105 -1036 67 -1508 -109 -288 -107 -791
-315 -943 -390 -163 -81 -324 -183 -520 -329 -60 -46 -139 -97 -175 -115 -59
-29 -74 -32 -176 -36 l-111 -3 -42 -123 c-58 -171 -80 -282 -81 -395 0 -95
-20 -166 -50 -185 -7 -4 -23 -31 -36 -59 -27 -57 -32 -140 -13 -225 7 -35 7
-66 0 -107 -11 -56 -10 -59 21 -107 63 -99 119 -113 493 -124 226 -7 351 4
509 44 119 29 161 49 299 139 103 68 174 135 477 449 69 72 163 158 208 193
166 124 369 228 567 289 82 25 104 27 240 27 174 0 233 -15 454 -108 224 -95
401 -153 688 -225 135 -34 646 -201 661 -216 12 -12 -98 -48 -159 -53 -79 -5
-418 12 -684 35 -260 23 -662 23 -855 -1 -371 -44 -879 -176 -1185 -306 -55
-23 -190 -88 -300 -144 -110 -56 -258 -128 -328 -161 -206 -94 -388 -222 -490
-343 -88 -104 -126 -180 -158 -312 -29 -123 -76 -254 -107 -300 -14 -21 -17
-51 -17 -180 0 -131 -3 -160 -18 -188 -10 -18 -66 -84 -125 -146 -132 -138
-287 -313 -377 -426 -79 -99 -101 -119 -134 -120 -14 0 -139 51 -279 112 -290
128 -276 114 -209 220 63 98 125 222 180 358 125 308 208 644 348 1405 104
568 106 704 8 855 -43 67 -108 119 -189 152 -47 19 -75 22 -185 23 -111 0
-161 -6 -339 -40 l-210 -39 -65 -54 c-36 -30 -110 -93 -163 -140 -80 -71 -101
-95 -113 -133 -10 -28 -71 -120 -161 -240 -193 -258 -200 -264 -224 -219 -9
17 7 40 117 172 71 84 135 162 143 173 15 19 15 19 -15 1 -53 -33 -148 -113
-204 -170 -52 -52 -55 -58 -54 -104 l1 -48 -48 -17 c-27 -10 -60 -31 -73 -47
-34 -40 -183 -326 -195 -375 -6 -22 -13 -70 -17 -106 l-6 -67 -67 -30 c-37
-16 -92 -37 -122 -47 -48 -14 -69 -31 -155 -119 -55 -57 -134 -149 -175 -206
-127 -174 -162 -212 -254 -274 -223 -150 -268 -250 -217 -484 10 -48 22 -148
26 -222 3 -74 10 -182 15 -239 9 -101 9 -106 -12 -128 l-22 -23 23 -48 c104
-214 296 -407 469 -472 129 -49 375 -88 777 -125 415 -38 499 -53 752 -136 86
-29 238 -75 337 -104 l181 -53 -1 -63 c-1 -45 -11 -88 -33 -145 -48 -124 -43
-120 -137 -99 -43 9 -158 42 -254 72 -367 114 -511 139 -768 130 -180 -6 -339
-31 -552 -87 -99 -26 -215 -74 -243 -100 -5 -4 36 -17 90 -29 177 -41 167 -33
88 -65 -116 -47 -204 -45 -238 5 -15 20 -2 22 -342 -73 -199 -55 -281 -89
-424 -177 -53 -32 -118 -70 -144 -85 -69 -39 -109 -77 -201 -191 -142 -176
-256 -295 -303 -318 -38 -19 -60 -44 -151 -177 -59 -85 -124 -176 -146 -203
-69 -88 -158 -107 -256 -57 -52 27 -43 44 18 35 32 -5 47 -3 53 7 14 22 10 36
-22 73 l-31 35 41 110 c60 161 65 189 52 343 -12 150 -5 244 33 447 13 74 36
214 51 310 14 96 35 229 47 295 18 100 21 160 22 360 0 168 -4 249 -12 269
-11 26 -10 32 12 55 l25 26 -35 55 c-65 103 -78 190 -44 287 26 76 25 115 -5
161 -37 56 -52 110 -70 243 -8 66 -22 136 -29 154 -8 18 -14 65 -14 103 0 88
-19 133 -111 261 -77 108 -256 411 -277 469 -7 20 -13 51 -14 67 0 17 -10 77
-21 135 -14 74 -22 163 -26 305 -5 198 -2 240 39 597 9 85 9 147 -1 303 -7
108 -10 199 -7 202 12 13 60 -32 100 -95 61 -95 63 -97 139 -82 l62 11 68 -45
c135 -90 226 -86 295 15 25 36 30 39 67 37 35 -3 40 0 43 20 5 31 -40 269 -92
490 -25 103 -56 255 -71 337 -73 406 -152 646 -336 1033 -50 104 -61 137 -53
150 6 9 42 38 80 65 98 67 152 121 175 172 16 35 34 54 88 88 116 75 177 173
220 351 34 139 56 172 145 216 52 26 161 279 187 434 24 145 3 239 -193 861
-164 521 -557 1571 -870 2325 -234 563 -294 722 -432 1145 -63 193 -162 496
-220 675 -59 179 -142 413 -185 520 -121 302 -289 697 -331 781 -79 160 -181
282 -296 354 -32 21 -80 55 -105 76 l-46 38 -6 93 c-14 212 -106 405 -264 547
-37 33 -69 59 -71 57 -1 -2 26 -77 62 -167 57 -147 62 -165 48 -176 -25 -20
-60 -15 -76 10 -8 12 -31 75 -52 140 -21 64 -42 117 -45 117 -16 0 -39 -33
-39 -54 0 -13 -3 -37 -6 -54 -6 -28 -12 -32 -57 -42 -102 -22 -122 -75 -59
-156 37 -46 38 -51 26 -79 -19 -44 -17 -156 6 -272 11 -57 19 -141 19 -192 0
-86 -2 -94 -28 -124 -16 -18 -31 -50 -35 -71 -4 -22 -15 -57 -23 -79 -15 -37
-17 -38 -30 -21 -29 40 -53 145 -53 233 0 116 -17 292 -42 431 -34 194 -35
222 -3 277 26 43 27 50 17 90 -7 24 -12 59 -12 78 0 38 36 153 54 172 6 7 40
22 76 35 l65 23 11 53 12 54 -86 -4 c-74 -3 -87 -1 -90 14 -2 11 24 31 96 69
l99 53 13 157 c12 153 11 194 -12 309 -9 45 -8 67 6 118 25 100 21 178 -17
295 l-34 102 7 390 c8 428 19 521 90 806 161 642 355 1102 585 1389 80 99 141
199 210 344 75 157 121 293 175 520 61 252 169 524 239 599 43 46 142 98 311
162 267 100 431 192 795 445 92 64 172 101 390 180 346 125 614 249 826 380
46 29 192 115 323 192 132 77 279 166 326 198 186 125 238 132 795 99z
m-12960 -183 c70 -30 80 -70 95 -411 21 -495 32 -561 220 -1370 120 -518 144
-696 159 -1191 7 -231 9 -254 30 -298 27 -54 74 -85 167 -111 55 -15 134 -65
134 -83 0 -4 -19 -22 -41 -41 l-40 -33 -69 46 c-39 25 -72 46 -74 46 -2 0 -31
-44 -64 -98 -33 -54 -81 -126 -106 -159 -26 -34 -53 -78 -62 -99 -21 -51 -20
-184 5 -347 16 -109 19 -162 14 -355 -6 -234 4 -532 37 -1157 11 -198 27 -499
35 -670 23 -447 48 -673 115 -1040 16 -88 43 -279 59 -425 17 -146 54 -424 81
-618 43 -305 53 -358 74 -395 33 -55 52 -147 60 -277 3 -58 26 -244 51 -415
24 -170 51 -383 59 -472 11 -119 26 -206 55 -320 55 -215 57 -227 76 -398 21
-183 17 -252 -19 -324 -15 -29 -29 -51 -30 -49 -5 4 -92 330 -110 408 -8 36
-27 144 -41 240 -80 537 -216 974 -527 1695 -71 165 -136 314 -143 332 -13 32
-13 32 29 57 24 14 57 41 75 61 46 53 43 96 -28 321 l-59 189 68 5 68 5 -110
250 c-60 138 -203 500 -318 805 -409 1089 -498 1307 -667 1630 -109 209 -135
247 -183 266 -52 21 -95 71 -117 136 -49 146 -322 637 -400 719 -51 53 -55 62
-87 180 -43 156 -95 279 -157 374 -46 71 -47 76 -42 128 5 46 1 63 -20 108
-15 30 -32 62 -39 71 -10 15 -8 22 15 46 36 38 34 66 -10 137 l-38 60 -5 -55
-5 -55 -27 27 c-32 31 -34 49 -13 108 20 57 19 139 -3 197 -10 27 -53 110 -95
185 -171 306 -306 582 -402 820 -53 131 -85 190 -208 376 l-94 142 29 40 c40
55 103 98 247 170 69 34 174 90 235 125 191 108 328 172 482 225 166 57 225
87 334 169 98 74 168 143 215 213 32 49 46 61 94 79 87 33 188 46 411 54 113
4 268 13 345 20 194 17 240 17 280 1z m-1099 -72 c-5 -14 -9 -15 -19 -5 -9 9
-9 14 3 21 18 11 24 6 16 -16z m1854 -1081 c6 -32 14 -68 17 -80 4 -19 1 -23
-20 -23 -24 0 -25 3 -29 73 -2 39 -2 75 0 80 9 20 22 1 32 -50z m-3396 -209
c9 -24 7 -54 -3 -54 -12 0 -16 8 -25 43 -5 20 -3 27 8 27 8 0 17 -7 20 -16z
m296 -649 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10
-11 10 -25z m-16 -225 c27 -25 61 -74 86 -123 24 -45 60 -108 82 -140 31 -46
34 -56 18 -47 -34 18 -94 82 -137 146 -52 76 -116 204 -103 204 6 0 30 -18 54
-40z m246 -133 l33 -59 -32 17 c-34 17 -51 49 -51 93 0 32 7 24 50 -51z m165
-567 c48 -85 89 -157 91 -159 2 -2 15 1 28 7 l24 11 4 -89 c3 -81 5 -91 31
-118 l29 -30 -23 -16 c-30 -21 -62 -20 -82 2 -23 26 -247 454 -247 474 0 15
49 83 55 76 2 -2 42 -73 90 -158z m9100 -189 c39 -66 71 -118 73 -116 2 2 -6
34 -17 73 -12 38 -21 89 -21 113 l0 43 61 -59 c100 -99 113 -145 54 -201 -22
-21 -33 -38 -29 -48 8 -21 -75 -369 -90 -378 -24 -15 -50 46 -105 247 -39 141
-54 238 -46 295 8 61 34 150 43 150 4 0 39 -54 77 -119z m7645 -376 c11 -30
-5 -75 -28 -75 -11 0 -13 8 -8 38 3 20 6 43 6 50 0 21 20 13 30 -13z m-1098
-109 c-10 -23 -69 -63 -78 -53 -2 2 8 39 23 82 l26 79 20 -42 c17 -34 18 -46
9 -66z m1291 32 c-10 -54 -17 -61 -37 -39 -18 19 -17 21 9 45 16 15 29 26 31
26 2 0 1 -15 -3 -32z m-1273 -168 c0 -16 -18 -30 -41 -30 -14 0 -11 22 7 41
19 21 34 16 34 -11z m1086 -76 c-3 -8 -10 -14 -16 -14 -17 0 -23 29 -9 46 11
14 13 14 22 -1 5 -9 6 -23 3 -31z m-7635 -14 c7 -22 10 -43 6 -46 -11 -11 -47
17 -53 41 -14 57 30 62 47 5z m-577 -53 c11 -49 11 -57 -2 -62 -19 -7 -18 -9
-29 57 -7 41 -7 59 1 61 16 6 16 5 30 -56z m503 -18 c12 -12 27 -34 34 -51 10
-25 9 -32 -5 -46 -15 -15 -21 -12 -83 50 -57 57 -65 69 -54 82 12 14 17 14 49
0 20 -8 47 -24 59 -35z m-267 -59 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-197 -130 c-11 -12 -15 -12 -30 3 -16 16 -15
18 8 43 l24 26 6 -29 c3 -17 0 -34 -8 -43z m325 50 c23 -21 29 -54 13 -64 -5
-4 -19 9 -30 28 -34 55 -25 74 17 36z m-6368 -100 c0 -88 0 -90 -24 -90 -35 0
-65 30 -72 70 l-6 35 31 -33 31 -32 0 70 c0 63 2 70 20 70 19 0 20 -7 20 -90z
m7316 -107 c-10 -66 -27 -114 -36 -108 -5 3 -15 23 -21 44 -9 31 -8 43 5 69
11 21 24 32 38 32 18 0 20 -4 14 -37z m-7438 -95 c32 -126 14 -124 -63 9 -18
31 -18 33 0 53 31 34 42 24 63 -62z m162 -103 c0 -9 -10 -16 -25 -16 -14 -1
-30 -2 -37 -3 -22 -2 -19 23 4 27 45 9 58 7 58 -8z m6109 -174 c-19 -90 -38
-52 -64 129 l-6 45 41 -61 c40 -60 40 -61 29 -113z m1126 102 c-10 -83 -16
-93 -31 -52 -7 19 -13 39 -13 45 -1 12 37 64 46 64 3 0 2 -25 -2 -57z m-7201
-168 c-3 -8 -12 -11 -20 -8 -15 5 -18 16 -8 26 11 10 33 -4 28 -18z m7215
-1080 l1 -40 -20 25 c-23 29 -24 40 -8 76 l13 27 6 -24 c4 -13 7 -42 8 -64z
m1 -246 c0 -18 -25 -8 -43 16 -27 37 -9 55 20 21 12 -15 23 -31 23 -37z m6237
-154 c11 -62 0 -69 -28 -19 -12 20 -14 36 -8 52 14 36 25 26 36 -33z m-6208
-127 l1 -76 -52 -18 c-29 -9 -59 -19 -65 -22 -10 -3 -13 15 -13 71 0 73 0 74
24 70 18 -4 28 2 40 21 9 14 24 38 33 54 l17 27 7 -25 c4 -14 7 -60 8 -102z
m-13191 -443 c31 -37 28 -49 -10 -41 -41 8 -82 37 -87 61 -5 28 68 13 97 -20z
m47 -75 c3 -6 3 -20 -1 -32 -6 -20 -7 -21 -20 -4 -13 18 -9 46 6 46 5 0 12 -5
15 -10z m25341 -54 c9 -36 -2 -46 -52 -46 -50 0 -55 10 -19 45 32 32 63 33 71
1z m-6901 -88 c-11 -65 -25 -70 -25 -10 0 41 3 52 16 52 13 0 15 -7 9 -42z
m-13221 -35 c3 -16 8 -54 12 -85 5 -48 4 -58 -9 -58 -20 0 -24 14 -33 99 -6
60 -4 71 9 71 9 0 18 -12 21 -27z m126 -218 c0 -21 -47 -42 -61 -28 -8 8 -3
16 17 27 37 19 44 20 44 1z m19375 5 c7 -12 -73 -66 -83 -55 -7 7 55 65 70 65
4 0 9 -4 13 -10z m-11303 -87 c22 -20 23 -46 3 -63 -12 -10 -20 -9 -40 4 -29
19 -33 51 -7 65 23 14 23 14 44 -6z m9301 -250 c10 -9 -44 -64 -55 -57 -4 3 2
18 14 34 24 31 31 34 41 23z m-17264 -50 c27 -54 33 -95 21 -128 -13 -35 -21
-26 -46 48 -14 42 -13 117 1 117 3 0 13 -17 24 -37z m24390 -194 l-33 -42 -8
28 c-12 48 32 99 61 70 12 -12 9 -20 -20 -56z m-6284 -300 c-67 -67 -166 -145
-229 -179 -34 -18 -22 -3 69 87 96 95 175 153 210 153 5 0 -17 -27 -50 -61z
m-1357 -34 c-12 -25 -28 -45 -35 -45 -17 0 -17 4 7 50 11 22 27 40 35 40 12 0
11 -9 -7 -45z m-178 -289 c0 -2 -3 -11 -6 -20 -5 -12 -10 -13 -30 -4 -33 15
-30 28 6 28 17 0 30 -2 30 -4z m-26970 -183 c0 -45 0 -46 -20 -28 -11 10 -20
23 -20 28 0 10 29 47 37 47 1 0 3 -21 3 -47z m140 -265 c0 -32 -44 -108 -63
-108 -15 0 -1 67 23 105 27 44 40 44 40 3z m160 -113 c0 -66 -6 -113 -21 -161
-27 -90 -79 -188 -92 -175 -5 5 -7 48 -5 98 4 75 12 106 54 214 27 70 52 125
57 122 4 -2 7 -46 7 -98z m-232 -93 l-3 -37 -19 37 c-16 31 -17 40 -6 53 12
15 14 15 22 0 5 -8 8 -32 6 -53z m10494 -132 c16 -45 21 -70 14 -70 -14 0 -78
125 -69 134 17 17 33 -1 55 -64z m7777 38 c6 -17 9 -34 6 -36 -8 -8 -15 2 -25
37 -11 41 5 40 19 -1z m-10333 -105 c-5 -11 -13 -10 -46 7 -22 11 -40 24 -40
27 0 4 19 24 43 44 l42 37 3 -50 c2 -28 1 -57 -2 -65z m26353 -60 c56 -64 55
-64 -30 -77 l-46 -7 -7 61 c-12 100 10 106 83 23z m-33918 -243 c-32 -455 -47
-589 -66 -610 -19 -21 -69 -27 -80 -11 -9 16 58 525 95 721 27 139 28 141 47
105 14 -26 14 -49 4 -205z m356 78 c202 -136 357 -198 653 -263 91 -20 255
-59 365 -87 177 -44 218 -51 355 -59 177 -9 279 -25 469 -74 172 -45 711 -219
751 -243 l31 -19 -38 -6 c-21 -4 -267 -29 -548 -57 -280 -28 -568 -58 -640
-68 -177 -23 -704 -23 -955 1 -96 9 -310 22 -475 28 -165 7 -301 13 -303 14
-3 3 133 887 143 933 l5 22 43 -26 c23 -15 88 -58 144 -96z m-597 -189 c-6
-134 -13 -272 -16 -306 l-6 -63 -33 0 c-38 0 -55 -20 -55 -67 0 -32 16 -58 73
-121 21 -24 35 -47 31 -51 -28 -24 -161 -129 -171 -135 -10 -5 -13 4 -13 37 0
34 8 57 33 94 l33 49 -28 43 c-41 66 -44 110 -15 278 14 82 31 184 37 228 19
137 34 177 86 225 25 23 47 40 50 38 2 -3 -1 -114 -6 -249z m34179 197 c9 -10
8 -16 -4 -26 -13 -11 -19 -11 -32 2 -16 17 -10 38 11 38 8 0 19 -6 25 -14z
m38 -171 c7 -33 15 -95 19 -137 7 -75 6 -77 -22 -106 -28 -27 -105 -61 -275
-120 -67 -23 -79 -24 -107 -13 -18 8 -32 17 -32 22 0 13 81 57 177 95 106 43
149 65 187 96 l28 24 -41 48 c-48 55 -51 82 -15 126 42 49 65 38 81 -35z m88
-505 l21 -195 -41 -13 c-83 -26 -834 -169 -1141 -217 -305 -48 -614 -56 -921
-24 -114 11 -123 14 -123 33 0 18 25 31 158 83 296 118 374 140 702 198 304
54 652 139 970 237 91 28 199 62 240 74 121 38 112 50 135 -176z m-34830 -33
c7 -113 6 -116 -39 -96 -22 10 -25 20 -31 83 -7 74 2 115 33 149 16 18 17 18
23 -6 3 -13 10 -72 14 -130z m260 -25 c-10 -53 -18 -120 -18 -149 0 -57 -8
-59 -33 -10 -40 75 -36 120 18 259 l32 83 10 -44 c7 -32 5 -67 -9 -139z
m18887 -35 c154 -175 185 -217 223 -299 30 -65 61 -187 79 -312 l5 -39 -45 44
c-144 140 -340 500 -379 697 -4 17 -3 32 2 31 4 0 56 -55 115 -122z m15628
-345 c0 -53 -2 -60 -25 -70 -34 -16 -65 -15 -150 2 -38 8 -114 18 -167 21 -92
7 -98 9 -98 29 0 52 18 59 161 66 74 4 167 7 207 8 l72 2 0 -58z m-34834 13
c6 -34 1 -55 -11 -43 -3 3 -9 22 -12 42 -8 48 14 48 23 1z m34209 -19 c18 -13
18 -14 -4 -26 -19 -10 -26 -9 -40 4 -14 15 -14 18 -1 26 20 13 23 12 45 -4z
m-33945 -66 c0 -33 -4 -60 -10 -60 -6 0 -10 27 -10 60 0 33 4 60 10 60 6 0 10
-27 10 -60z m34103 31 c17 -6 16 -8 -8 -19 -23 -11 -31 -10 -51 3 -13 8 -24
18 -24 20 0 7 63 5 83 -4z m-16884 -151 c20 -98 5 -139 -87 -247 -32 -37 -110
-131 -172 -208 -137 -169 -449 -535 -457 -535 -23 0 45 228 101 338 l35 69 50
-5 c67 -6 90 10 142 100 116 204 356 560 370 552 4 -2 12 -31 18 -64z m-16986
16 c3 -8 9 -43 13 -78 l7 -62 -54 44 c-30 24 -58 47 -62 50 -4 4 -5 19 -1 34
5 23 11 26 49 26 28 0 45 -5 48 -14z m4726 -11 c32 -15 51 -29 45 -35 -27 -26
-374 -66 -374 -43 0 11 125 80 169 93 50 15 106 10 160 -15z m-729 -105 c0 -6
-67 -30 -84 -30 -9 0 14 43 27 52 11 7 57 -11 57 -22z m851 -39 c38 -39 41
-93 4 -117 -36 -23 -38 -42 -15 -144 25 -117 100 -271 275 -567 14 -25 16 -36
8 -44 -9 -9 -52 7 -175 63 -410 190 -867 349 -1562 544 -126 35 -231 66 -234
69 -11 10 94 47 157 56 36 4 152 13 256 19 328 19 846 80 1135 134 109 20 118
19 151 -13z m-4410 -27 c201 -44 317 -77 377 -108 59 -30 297 -199 286 -203
-5 -1 -171 -52 -369 -113 l-360 -110 -23 28 c-46 54 -123 188 -138 240 -10 34
-13 68 -9 95 9 59 57 207 67 207 5 0 81 -16 169 -36z m-311 1 c0 -12 -35 -75
-41 -75 -19 0 -18 22 2 50 20 26 39 39 39 25z m9372 -24 c48 -30 72 -59 107
-128 18 -37 19 -43 6 -43 -47 0 -93 43 -148 138 -41 70 -34 77 35 33z m20592
-66 c11 -9 35 -26 53 -39 17 -13 73 -38 124 -55 120 -42 201 -83 269 -136 102
-78 125 -86 475 -144 569 -95 731 -104 990 -56 124 23 187 28 367 32 237 6
353 -2 720 -47 377 -47 517 -53 867 -41 168 6 332 14 365 17 58 6 59 6 53 -17
-3 -13 -26 -159 -53 -324 -26 -165 -53 -306 -59 -313 -22 -24 -89 -26 -156 -4
-282 91 -473 123 -1187 198 -345 36 -369 37 -470 25 -59 -6 -165 -19 -237 -27
-73 -8 -325 -18 -575 -24 -573 -12 -971 -40 -1153 -81 -113 -25 -272 -76 -462
-149 -84 -32 -206 -70 -275 -85 -315 -69 -624 -197 -949 -394 -128 -78 -537
-355 -905 -614 -93 -65 -219 -161 -279 -213 -126 -108 -148 -115 -191 -60 -30
40 -33 56 -8 56 29 1 60 23 131 93 52 50 79 69 114 79 41 11 47 16 47 39 0 14
10 41 23 60 29 43 140 135 193 160 25 11 173 142 371 326 444 415 511 476 780
711 223 195 298 274 344 364 10 20 19 40 19 44 0 3 -25 4 -55 2 -81 -7 -86 21
-25 137 22 42 40 82 40 89 0 6 -16 27 -35 46 -19 19 -35 42 -35 51 0 27 73 83
147 113 123 49 478 188 503 197 33 11 87 4 114 -16z m-11168 -37 c29 -27 40
-50 60 -122 28 -99 51 -150 99 -216 43 -60 58 -91 104 -212 21 -57 62 -161 91
-233 44 -107 54 -140 53 -190 0 -94 -47 -168 -121 -193 -28 -10 -34 -9 -39 6
-4 9 -24 37 -45 61 -46 53 -68 121 -68 214 0 50 -9 96 -30 164 -16 51 -30 100
-30 108 0 9 -21 35 -47 60 -33 30 -56 63 -73 107 l-26 63 31 49 c38 62 41 139
7 201 -16 29 -22 57 -22 102 0 36 4 63 10 63 6 0 26 -15 46 -32z m-18845 -104
c14 -13 16 -20 7 -40 -14 -30 -23 -30 -42 -2 -11 15 -12 26 -5 40 12 22 17 22
40 2z m11275 -789 c9 -21 -16 -51 -27 -33 -14 21 -10 48 6 48 9 0 18 -7 21
-15z m7815 -303 c5 -13 9 -39 9 -58 0 -19 4 -34 8 -34 5 0 55 18 111 40 57 22
106 39 108 36 2 -2 24 -136 48 -297 50 -338 118 -734 176 -1029 40 -201 41
-208 35 -360 -3 -85 -9 -173 -13 -194 l-8 -39 -153 191 c-85 106 -158 195
-164 198 -20 13 -239 470 -267 558 -16 50 -36 136 -45 191 -9 56 -43 186 -76
290 -113 357 -113 386 2 539 l72 95 74 -52 c41 -29 78 -62 83 -75z m-9064 57
c6 -6 9 -18 6 -26 -5 -12 -9 -11 -24 3 -10 9 -16 21 -13 25 7 12 18 11 31 -2z
m-9015 -37 l37 -7 -95 -63 c-94 -63 -255 -146 -305 -157 -25 -6 -27 -4 -38 52
-6 32 -18 84 -25 115 l-14 57 41 10 c45 11 336 6 399 -7z m8324 -164 c37 -45
82 -145 101 -229 14 -63 14 -71 -1 -94 -9 -14 -20 -25 -24 -25 -7 0 -133 354
-132 373 0 18 31 4 56 -25z m-1837 -187 c-3 -6 -17 -2 -37 11 -26 16 -30 23
-21 35 11 12 16 11 37 -10 13 -14 23 -30 21 -36z m-3574 5 c148 -30 193 -50
695 -315 218 -116 313 -160 406 -190 136 -44 208 -79 544 -259 l235 -127 176
-180 c226 -230 284 -298 361 -418 35 -53 114 -174 176 -267 61 -94 112 -172
112 -174 0 -3 -87 24 -192 60 -239 80 -462 144 -655 189 -82 19 -186 47 -233
64 -94 33 -187 51 -268 51 -53 0 -75 6 -422 119 -85 27 -224 63 -308 80 -84
17 -190 43 -235 60 -45 16 -122 43 -172 61 -106 38 -214 59 -307 60 -54 0 -68
3 -68 15 0 12 18 15 100 15 58 0 100 4 100 10 0 5 -25 17 -56 26 -50 14 -54
17 -37 29 52 36 112 45 311 46 142 0 189 3 167 10 -43 14 -337 27 -880 39
-284 6 -518 16 -590 25 -187 22 -1249 202 -1355 229 -102 26 -267 83 -275 95
-5 10 258 128 352 157 39 13 181 41 315 63 495 81 789 156 1104 281 155 62
248 90 459 139 198 47 238 47 440 7z m4755 -41 c0 -8 -4 -15 -9 -15 -5 0 -11
7 -15 15 -3 9 0 15 9 15 8 0 15 -7 15 -15z m2566 -63 c8 -52 -9 -72 -57 -72
l-28 0 27 75 c23 64 29 73 39 59 7 -9 15 -37 19 -62z m-1381 -179 l7 -78 -37
35 c-35 33 -36 38 -32 85 2 28 12 68 22 90 l18 40 8 -47 c4 -26 10 -82 14
-125z m-287 97 c0 -8 -6 -1 -13 15 -7 17 -10 34 -7 40 6 10 20 -27 20 -55z
m24003 -5 c188 -15 541 -53 547 -59 1 -2 -19 -76 -45 -165 -45 -155 -48 -161
-73 -161 -70 0 -300 103 -453 203 -95 62 -203 151 -218 178 -12 24 -12 24 242
4z m-24070 -68 c20 -33 20 -68 -2 -126 l-10 -26 -29 35 c-34 41 -60 109 -60
157 l0 33 40 -20 c23 -12 50 -35 61 -53z m119 -33 c0 -38 -16 -36 -29 5 -15
52 -14 57 9 36 11 -10 20 -28 20 -41z m15447 -8 c5 -5 -84 -166 -113 -203 -14
-19 -50 -50 -79 -68 -37 -24 -65 -53 -94 -100 -23 -36 -43 -65 -45 -65 -9 0
34 171 63 249 36 98 72 164 104 186 17 13 36 15 91 11 39 -4 71 -8 73 -10z
m-23641 -626 c8 0 14 -4 14 -10 0 -5 -13 -10 -30 -10 -21 0 -30 5 -30 16 0 11
5 14 16 10 9 -3 22 -6 30 -6z m25683 -274 c9 -11 7 -15 -9 -19 -11 -3 -20 -4
-20 -2 0 2 -3 10 -6 19 -8 20 19 22 35 2z m-109 -121 c0 -11 -45 -95 -50 -95
-7 0 -30 69 -30 89 0 6 17 11 40 11 22 0 40 -2 40 -5z m-280 -220 c10 -12 10
-18 0 -31 -12 -13 -15 -13 -28 0 -15 15 -10 46 6 46 5 0 15 -7 22 -15z m7185
-122 c19 -9 35 -23 35 -30 0 -16 -1 -16 -50 3 -39 16 -47 24 -33 37 9 9 7 9
48 -10z m-14146 -70 c6 -20 8 -39 6 -42 -7 -7 -13 1 -30 42 -13 32 -13 37 -1
37 9 0 19 -16 25 -37z m-17749 -113 c526 -44 962 -80 970 -80 24 0 -41 -59
-129 -117 -170 -111 -318 -226 -628 -491 -149 -126 -317 -290 -350 -341 -4 -5
-111 61 -193 118 -87 61 -230 199 -289 280 -65 88 -400 617 -417 658 -25 58
-20 66 34 59 26 -3 477 -42 1002 -86z m14370 -151 c23 -148 15 -180 -120 -514
-265 -652 -299 -717 -653 -1222 -156 -224 -195 -273 -214 -273 l-23 0 0 147
c0 189 8 209 124 292 176 127 311 299 436 556 38 77 96 183 130 235 117 181
140 241 170 439 11 76 29 169 40 207 27 94 80 225 90 219 4 -3 13 -41 20 -86z
m10080 33 c21 -17 22 -20 7 -48 -11 -21 -17 -25 -20 -14 -29 89 -28 95 13 62z
m-98 -207 c-12 -26 -44 -33 -72 -15 -12 8 -9 14 20 39 30 25 37 27 49 16 12
-11 12 -19 3 -40z m-104 -97 c19 -19 15 -26 -38 -53 -62 -31 -64 -31 -56 3 12
49 66 78 94 50z m-19300 -101 c23 -17 146 -139 273 -270 197 -202 336 -327
363 -327 4 0 -34 48 -84 107 -50 59 -87 109 -81 111 16 5 83 -33 142 -81 50
-41 139 -144 139 -161 0 -4 -21 -4 -47 0 l-48 6 29 -30 c43 -46 84 -62 142
-56 66 8 87 -14 94 -100 l5 -61 74 -49 c99 -65 212 -175 313 -304 46 -58 154
-195 241 -305 87 -109 156 -202 152 -205 -3 -3 -29 -6 -58 -6 -40 0 -92 15
-231 67 -314 120 -528 225 -930 460 -369 215 -544 274 -872 297 -82 6 -225 20
-319 30 -299 35 -481 24 -658 -40 -246 -90 -439 -253 -714 -606 -72 -93 -172
-221 -222 -284 -50 -63 -105 -141 -121 -173 l-30 -57 46 -28 c25 -15 59 -35
76 -45 27 -16 29 -20 16 -35 -8 -9 -34 -41 -58 -71 -49 -60 -51 -52 34 -103
55 -33 58 -54 18 -141 l-27 -59 -90 -29 c-50 -16 -95 -29 -101 -29 -7 0 -40
39 -75 88 -82 113 -298 329 -473 473 -77 63 -199 164 -270 225 -125 105 -131
112 -128 145 8 96 54 175 122 209 28 14 47 36 75 86 90 164 246 323 454 463
173 117 306 168 571 220 l155 30 170 -14 c94 -8 229 -21 300 -29 282 -32 438
-29 800 15 120 15 204 20 285 17 128 -5 135 -8 593 -187 165 -65 217 -81 250
-79 42 3 40 4 -133 97 -96 51 -176 94 -177 95 -2 2 11 42 27 91 34 99 38 146
14 166 -24 20 -61 98 -79 170 -9 35 -27 86 -41 114 l-23 50 25 20 c21 17 26
32 31 83 3 34 9 62 12 62 3 0 25 -15 49 -33z m300 -120 c61 -60 72 -75 72
-104 0 -45 -20 -43 -76 9 -37 34 -154 189 -154 204 0 17 97 -51 158 -109z
m18840 50 c7 -8 7 -17 -2 -30 -24 -31 -53 -15 -41 23 7 23 27 26 43 7z m6305
-73 c132 -22 248 -55 512 -144 132 -45 296 -97 364 -116 68 -18 126 -36 129
-38 2 -3 -1 -47 -6 -99 -11 -103 -32 -155 -79 -199 -43 -40 -175 -128 -192
-128 -22 0 -241 126 -392 224 -108 72 -333 238 -611 451 -76 59 -77 60 -50 67
38 10 221 0 325 -18z m-29018 -231 c-10 -25 -46 -79 -81 -121 -36 -43 -74 -97
-86 -122 -11 -25 -95 -195 -185 -378 l-165 -333 -86 47 c-249 134 -376 225
-526 377 l-109 110 23 17 c51 39 109 50 250 48 205 -3 228 7 775 330 192 113
200 117 203 94 2 -14 -4 -45 -13 -69z m16922 -25 c18 -13 33 -26 33 -29 0 -7
-67 -39 -84 -39 -18 0 -43 30 -51 63 -7 26 -6 27 31 27 23 0 51 -9 71 -22z
m-12584 -22 c13 -20 14 -26 3 -35 -9 -8 -19 -4 -37 15 -20 21 -21 27 -9 34 22
14 26 13 43 -14z m-2858 -126 c4 -6 1 -18 -6 -26 -11 -14 -13 -14 -21 0 -15
26 12 51 27 26z m210 -20 c-15 -29 -54 -48 -74 -35 -29 17 15 65 59 65 l31 0
-16 -30z m345 -13 c0 -11 -39 -37 -55 -37 -15 0 -1 22 23 34 34 19 32 19 32 3z
m10116 -72 c5 -12 -100 -195 -112 -195 -2 0 -4 17 -4 38 0 27 12 56 43 104 42
67 62 81 73 53z m234 -56 c-18 -66 -72 -178 -121 -250 -44 -66 -190 -223 -221
-240 -17 -9 -18 -6 -12 33 19 134 76 255 190 401 42 53 78 96 80 94 2 -2 -10
-38 -26 -81 -35 -89 -34 -86 -17 -86 6 0 39 41 71 90 32 50 61 89 63 86 2 -2
-1 -24 -7 -47z m15465 -214 c126 -42 339 -142 500 -235 103 -59 325 -199 325
-204 0 -2 -59 -43 -131 -92 l-130 -89 -122 -7 c-67 -4 -139 -10 -160 -14 -73
-13 -99 0 -224 114 -163 147 -219 207 -268 282 -23 36 -78 105 -121 155 l-79
90 45 -26 c25 -14 76 -43 113 -64 l68 -37 -27 58 c-31 70 -44 103 -44 111 0
17 156 -9 255 -42z m-1203 -347 c-13 -13 -65 7 -85 35 -17 22 -18 29 -7 42 12
14 18 11 57 -27 23 -23 39 -46 35 -50z m278 -48 c77 -33 82 -40 23 -40 -54 0
-103 25 -131 68 l-16 24 35 -15 c19 -8 59 -24 89 -37z m-14562 -77 c-15 -54
-42 -104 -72 -132 l-26 -24 0 37 c0 47 20 85 65 125 20 17 37 31 40 31 2 0 -1
-17 -7 -37z m15337 -3 c3 -5 1 -11 -5 -15 -13 -8 -72 23 -65 35 6 10 62 -6 70
-20z m-1875 -100 c22 -11 55 -34 75 -51 41 -37 169 -199 161 -206 -14 -14 -57
20 -166 134 -140 146 -149 163 -70 123z m-13570 -67 c0 -5 -14 -28 -30 -53
l-30 -45 0 33 c0 19 8 40 20 52 20 20 40 26 40 13z m15060 -68 c10 -12 10 -15
-3 -15 -8 0 -17 7 -21 15 -3 8 -2 15 3 15 5 0 14 -7 21 -15z m131 -328 c-10
-10 -61 16 -61 32 0 19 14 18 41 -1 19 -14 26 -25 20 -31z m-16851 -27 c0 -16
-33 -80 -42 -80 -12 0 -10 13 7 54 14 33 35 49 35 26z m466 -15 c6 -23 -9 -55
-25 -55 -4 0 -5 18 -3 40 5 45 18 53 28 15z m-276 -44 c-10 -20 -30 -29 -30
-14 0 10 33 44 37 39 3 -2 0 -14 -7 -25z m11391 -236 c-1 -15 -24 -12 -29 3
-3 9 2 13 12 10 10 -1 17 -7 17 -13z m-20850 -375 c10 -6 19 -20 19 -30 0 -18
-7 -20 -80 -20 -44 0 -80 2 -80 5 0 2 17 16 37 30 40 27 72 31 104 15z m1090
-701 c57 -19 96 -23 344 -29 215 -6 308 -13 399 -29 184 -33 323 -79 398 -132
l30 -22 -26 -14 c-22 -12 -93 -12 -444 -3 -487 12 -606 20 -842 62 -135 23
-186 43 -223 87 -34 41 -34 51 1 51 15 0 68 11 117 25 113 30 165 31 246 4z
m2622 -312 c52 -22 60 -38 46 -91 -6 -25 -14 -46 -18 -46 -4 0 -23 34 -44 75
-20 41 -32 75 -27 75 6 0 25 -6 43 -13z m14692 -119 c-30 -133 -110 -212 -228
-225 l-52 -5 35 35 c39 40 243 226 248 227 2 0 0 -14 -3 -32z m-245 -106 c0
-4 -15 -22 -33 -40 -26 -26 -36 -29 -45 -20 -10 10 -6 18 20 40 32 27 58 36
58 20z m-255 -172 c-9 -15 -35 -12 -41 5 -4 8 -1 22 6 30 11 14 15 13 27 -5 8
-11 11 -25 8 -30z m-105 -59 c0 -30 -28 -51 -67 -51 l-36 0 44 35 c51 40 59
42 59 16z m2740 -1566 c-69 -70 -77 -75 -117 -75 l-42 0 6 33 c3 17 11 38 18
45 21 22 189 91 200 81 6 -6 -18 -37 -65 -84z m224 -95 c-10 -10 -94 7 -94 19
0 3 9 21 21 40 l20 33 31 -41 c18 -25 27 -46 22 -51z m-11064 -155 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m5771 -1878
c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m131 -49 c-12 -12
-25 -18 -29 -14 -4 4 3 16 16 27 31 25 41 15 13 -13z m358 -1742 c0 -17 -14
-29 -23 -20 -8 8 3 34 14 34 5 0 9 -6 9 -14z m-298 -309 c-11 -172 -36 -312
-54 -301 -13 8 -4 298 12 364 27 116 33 133 43 115 6 -11 5 -76 -1 -178z
m-11504 -484 c9 -29 9 -39 -1 -45 -12 -7 -18 3 -31 55 -10 43 19 34 32 -10z
m11418 -39 c-10 -37 -29 -30 -21 9 4 17 10 37 13 42 9 15 15 -23 8 -51z m-6
-250 c0 -40 -33 -84 -50 -67 -10 9 -10 23 0 65 7 29 15 57 18 62 9 16 32 -27
32 -60z m-8640 36 c0 -15 -53 -59 -63 -53 -5 3 4 18 19 34 29 30 44 36 44 19z
m-2851 -430 c0 -44 -23 -146 -33 -143 -3 1 -11 3 -16 5 -9 3 11 143 26 188 8
24 24 -9 23 -50z m2467 7 c-7 -18 -30 -64 -50 -102 -20 -37 -36 -73 -36 -80 0
-29 -82 -73 -142 -75 -4 0 0 20 9 45 12 35 38 67 120 145 58 55 108 100 110
100 2 0 -3 -15 -11 -33z m-2809 -191 c-3 -8 -9 -31 -13 -50 -4 -22 -12 -36
-21 -36 -9 0 -13 6 -10 14 3 8 9 31 13 50 4 22 12 36 21 36 9 0 13 -6 10 -14z
m2553 -209 c-6 -28 -39 -106 -74 -172 -56 -106 -66 -120 -96 -128 -58 -15 -62
-6 -34 70 50 135 102 232 156 289 l53 56 3 -31 c2 -17 -2 -55 -8 -84z m9570
-44 c-2 -23 -39 -185 -44 -190 -14 -15 -14 9 1 100 11 67 20 97 30 97 7 0 13
-3 13 -7z m-12250 -170 c0 -30 -17 -50 -31 -36 -8 8 -8 16 0 32 14 26 31 27
31 4z m316 -88 c3 -19 4 -41 1 -49 -6 -15 -86 -61 -93 -54 -6 7 67 138 76 138
5 0 12 -16 16 -35z m2206 -40 c12 -27 -2 -74 -30 -97 -14 -12 -15 -8 -9 36 4
27 7 57 7 67 0 27 18 23 32 -6z m-123 -239 c-35 -58 -65 -103 -67 -101 -2 2 5
29 14 61 17 52 98 163 111 150 3 -2 -23 -52 -58 -110z m-110 -208 c-12 -29
-24 -61 -26 -70 -6 -21 -17 -23 -31 -5 -8 10 -6 22 9 48 20 32 59 79 66 79 1
0 -7 -24 -18 -52z m11573 -288 c67 -100 163 -278 154 -288 -3 -2 -21 2 -42 8
-57 19 -117 81 -148 153 -15 34 -40 87 -56 117 -23 45 -71 189 -70 210 1 14
99 -107 162 -200z m-11386 -164 c-4 -19 -24 -53 -46 -78 -35 -39 -39 -41 -40
-20 0 29 25 79 58 114 31 33 36 30 28 -16z m-2426 -33 c0 -10 -8 -12 -27 -7
-37 9 -43 13 -43 29 0 10 7 11 35 2 19 -6 35 -16 35 -24z m2293 -164 c-40 -70
-125 -149 -160 -149 -4 0 33 41 82 90 49 50 91 90 93 90 2 0 -5 -14 -15 -31z
m-460 -114 c0 -6 -7 -11 -16 -13 -12 -3 -15 1 -11 13 7 17 25 17 27 0z m557
-52 c0 -26 -1 -26 -14 -8 -14 20 -12 35 5 35 5 0 9 -12 9 -27z m-281 -73 c-13
-27 -99 -103 -107 -95 -13 12 16 72 56 116 44 49 47 50 56 30 6 -15 5 -30 -5
-51z m8335 -41 c-8 -13 -24 -1 -24 18 0 13 3 13 15 3 8 -7 12 -16 9 -21z m6
-184 c0 -51 -3 -65 -15 -65 -12 0 -15 14 -15 65 0 51 3 65 15 65 12 0 15 -14
15 -65z m-112 -217 c-3 -87 -4 -91 -17 -63 -30 68 -30 230 1 300 12 28 13 25
16 -57 1 -48 1 -129 0 -180z m-6794 201 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3
15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m-1488 -90 c9 -15 -48 -60 -60 -48 -6 5
-1 18 14 34 26 28 35 31 46 14z m1444 -54 c0 -22 -8 -41 -22 -55 -23 -21 -48
-27 -48 -11 0 5 -3 16 -6 25 -6 16 45 76 65 76 6 0 11 -16 11 -35z m-2280 -67
c0 -23 -63 -108 -79 -108 -10 0 -26 9 -37 21 -17 19 -17 23 -3 47 9 16 32 33
59 43 46 17 60 16 60 -3z m9207 -35 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7
2 -21 0 -30z m-5627 -109 c0 -9 -63 -84 -70 -84 -8 0 -33 51 -28 56 21 21 98
43 98 28z m3260 -204 c-12 -33 -26 -60 -31 -60 -5 0 -16 12 -26 26 -17 26 -17
27 26 66 23 22 45 37 48 34 3 -3 -4 -32 -17 -66z m10335 -486 c-22 -16 -41 -4
-31 21 5 13 10 14 28 4 21 -11 21 -12 3 -25z m-14145 -198 c0 -13 -8 -30 -17
-37 -15 -12 -16 -12 -10 4 4 10 7 30 7 43 0 14 4 22 10 19 6 -3 10 -17 10 -29z
m14360 -57 c0 -45 -14 -50 -54 -18 -30 24 -33 54 -9 88 l16 24 24 -27 c16 -18
23 -40 23 -67z m190 -69 c-22 -31 -40 -39 -40 -17 0 17 12 33 30 40 29 11 31
7 10 -23z m-1157 -2110 c-16 -28 -33 -39 -33 -22 0 13 31 52 41 52 6 0 2 -13
-8 -30z m-153 -168 c0 -4 -15 -25 -34 -45 -34 -37 -86 -52 -86 -24 0 20 30 58
54 67 27 11 66 12 66 2z m-537 -413 c-17 -17 -37 -1 -22 17 8 10 15 12 23 4 7
-7 7 -13 -1 -21z"
      />
      <path
        d="M24095 34746 c-38 -13 -77 -27 -86 -30 -13 -6 -16 -24 -15 -99 1 -74
-3 -98 -17 -117 -10 -14 -28 -40 -41 -58 -33 -44 -39 -105 -32 -295 3 -89 8
-322 11 -517 8 -574 37 -863 141 -1395 25 -126 58 -318 74 -425 72 -484 165
-808 354 -1235 20 -44 42 -111 51 -150 18 -86 45 -649 45 -959 l0 -220 -24 33
c-36 49 -182 326 -252 481 -114 251 -191 461 -264 723 -39 141 -119 385 -200
607 -23 63 -61 197 -85 297 -92 378 -174 631 -356 1085 -134 336 -342 888
-419 1113 -28 83 -80 262 -116 398 -36 137 -76 272 -90 300 -36 70 -113 149
-182 185 -59 31 -130 57 -137 50 -2 -2 14 -30 35 -62 38 -56 49 -89 33 -99 -5
-3 -68 19 -140 48 l-133 53 -6 -49 c-4 -27 -8 -49 -11 -49 -2 0 -18 7 -34 16
-17 8 -32 14 -34 12 -2 -2 7 -77 20 -168 25 -183 31 -264 45 -685 36 -1016
141 -1630 380 -2229 97 -244 186 -396 308 -527 38 -41 74 -84 81 -96 17 -33
197 -771 220 -905 35 -200 80 -731 70 -808 l-4 -25 -21 30 c-12 17 -125 275
-252 575 -170 402 -248 600 -297 755 -37 116 -80 242 -95 280 -83 210 -366
769 -445 880 -56 79 -367 375 -393 375 -5 0 -22 -29 -38 -65 -16 -35 -32 -65
-36 -65 -4 0 -55 25 -113 56 -58 31 -108 54 -111 51 -3 -3 -15 -39 -26 -79
-14 -53 -21 -120 -25 -244 -10 -285 21 -481 147 -954 130 -484 328 -1281 395
-1582 72 -328 115 -480 180 -639 24 -58 48 -130 54 -160 11 -63 151 -676 217
-954 25 -104 97 -378 160 -608 63 -230 141 -527 175 -660 70 -281 144 -544
190 -672 32 -89 32 -92 33 -285 1 -186 3 -199 29 -274 50 -142 168 -325 244
-377 25 -18 64 -37 87 -44 40 -12 41 -11 58 16 20 33 38 35 98 14 23 -8 46
-15 50 -15 4 0 15 18 25 40 21 46 76 100 132 129 49 25 66 44 96 112 26 60 59
91 113 109 42 14 42 16 8 197 -22 118 -26 170 -28 363 -3 207 -6 243 -40 445
-82 493 -104 656 -146 1130 -23 266 -48 424 -130 840 -132 670 -201 1048 -193
1056 5 5 37 -15 75 -46 36 -30 73 -55 82 -55 31 0 90 73 114 142 13 36 34 76
48 89 20 19 27 21 49 11 40 -18 41 -39 4 -110 -29 -57 -34 -77 -34 -136 l0
-70 40 -9 c77 -17 172 -2 303 47 68 26 171 58 229 72 57 13 120 31 138 38 42
18 122 90 142 129 8 16 16 27 18 25 9 -9 66 -529 80 -736 33 -472 121 -801
321 -1193 l65 -128 95 60 c52 33 101 62 108 65 10 4 16 -8 20 -40 4 -25 11
-46 15 -46 17 0 75 172 127 373 29 114 73 270 98 346 54 164 80 302 73 388 -3
47 0 71 15 104 36 81 40 126 33 338 l-7 203 -34 37 c-48 53 -52 73 -52 217 1
148 -8 179 -55 199 -54 22 -59 36 -67 168 -8 121 -24 208 -55 300 -12 35 -17
93 -19 220 -2 151 -6 183 -28 257 -37 122 -39 143 -23 197 8 26 15 68 15 93
-1 47 -37 151 -56 157 -6 2 -19 -17 -28 -42 -10 -25 -22 -45 -27 -45 -5 0 -9
66 -8 153 1 143 2 150 15 117 8 -19 19 -44 24 -55 7 -13 9 69 6 250 -6 309
-23 452 -81 673 -37 139 -40 194 -14 243 8 17 13 18 25 8 39 -32 66 -122 79
-259 4 -36 13 -90 22 -120 13 -47 17 -52 25 -35 6 14 -5 127 -37 360 -55 408
-100 677 -155 925 -37 171 -66 287 -210 844 -29 110 -103 414 -165 675 -62
262 -121 509 -131 549 l-18 74 -86 -7 c-71 -5 -94 -3 -144 14 -79 28 -171 27
-261 -3z m1074 -2926 c-18 -12 -48 6 -55 34 -3 14 2 38 12 57 l17 34 20 -59
c16 -47 17 -60 6 -66z m-3299 -119 c19 -27 35 -55 35 -62 0 -24 -25 -20 -55 9
-22 23 -30 40 -30 66 0 20 3 36 8 36 4 0 23 -22 42 -49z m3397 -1968 c-3 -10
-5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-947 -491 c0 -15 -22 -60 -58
-116 -18 -30 -26 -36 -33 -25 -12 19 -11 90 2 167 l11 64 39 -38 c22 -21 39
-44 39 -52z m-437 -331 c14 -6 29 -23 36 -42 14 -41 14 -46 -3 -52 -10 -4 -25
11 -45 42 -42 65 -40 72 12 52z"
      />
      <path
        d="M30007 34268 c-12 -13 -69 -90 -126 -173 -71 -102 -133 -178 -195
-239 -84 -82 -91 -86 -94 -63 -3 24 15 63 72 162 13 22 27 51 30 65 6 20 -2
16 -39 -25 -65 -71 -112 -154 -200 -354 -41 -95 -136 -291 -210 -435 -73 -143
-208 -427 -300 -631 -380 -842 -458 -1000 -656 -1320 -145 -236 -355 -598
-414 -715 -18 -36 -56 -121 -85 -190 -67 -160 -115 -257 -261 -521 -147 -265
-256 -506 -310 -686 -22 -75 -63 -188 -91 -252 -155 -362 -244 -731 -307
-1272 -29 -242 -30 -231 18 -185 l38 37 7 -47 c18 -132 0 -312 -44 -444 -15
-47 -26 -87 -24 -89 2 -2 29 12 61 31 66 39 65 36 139 276 l48 153 36 -2 c46
-4 56 3 64 45 8 40 41 86 63 86 13 0 14 -9 9 -50 -4 -28 -16 -79 -26 -113 -23
-73 -25 -116 -6 -142 13 -18 14 -18 42 2 112 81 173 210 377 803 174 507 243
675 422 1030 118 233 224 414 380 650 257 389 395 650 592 1125 122 291 158
354 391 669 313 425 453 688 533 1005 37 144 38 193 4 227 l-25 25 40 99 c22
55 60 141 85 190 88 176 145 337 250 707 80 285 102 400 83 440 -19 41 -71 63
-148 63 -64 0 -67 1 -105 40 -46 47 -84 52 -118 18z m-449 -584 c-16 -38 -15
-37 -27 -29 -7 4 -3 17 11 35 26 36 33 33 16 -6z m-483 -2041 c-9 -36 -22 -50
-35 -37 -14 14 8 64 28 64 10 0 12 -7 7 -27z m-65 -155 c0 -18 -134 -237 -142
-232 -14 9 -9 94 8 127 36 72 134 149 134 105z m-1046 -1154 c8 -20 -1 -34
-20 -34 -8 0 -14 8 -14 18 0 33 23 44 34 16z m-144 -294 c11 -7 12 -14 4 -27
-6 -10 -37 -64 -68 -120 -32 -57 -64 -103 -72 -103 -8 0 -14 5 -14 12 0 22
113 248 124 248 6 0 18 -5 26 -10z m40 -656 c0 -37 -5 -51 -30 -76 -38 -37
-45 -33 -25 15 8 21 22 53 29 72 8 19 17 35 20 35 4 0 6 -21 6 -46z m-480
-265 c-51 -134 -94 -246 -97 -249 -3 -3 -2 15 2 40 13 89 47 205 81 279 37 81
96 183 101 177 2 -2 -37 -113 -87 -247z m-135 -399 c7 -12 -132 -439 -147
-455 -7 -7 -9 17 -5 70 4 62 14 105 47 190 23 61 49 131 59 157 15 46 33 60
46 38z m320 -107 c-8 -32 -13 -39 -21 -30 -10 9 5 57 17 57 7 0 9 -10 4 -27z
m-514 -586 c-11 -29 -30 -34 -62 -17 -27 15 -24 25 14 49 26 16 34 18 45 7 9
-9 10 -19 3 -39z m293 -142 c3 -9 -1 -18 -10 -22 -19 -7 -28 2 -21 22 8 19 23
19 31 0z m-344 -19 c0 -18 -6 -22 -47 -31 -40 -8 -47 15 -10 33 32 15 57 14
57 -2z m-102 -268 c-4 -41 -28 -50 -28 -10 0 16 3 32 7 35 15 16 24 5 21 -25z"
      />
      <path
        d="M28005 32410 c-17 -33 -19 -50 -6 -50 12 0 51 62 45 72 -10 15 -24 8
-39 -22z"
      />
      <path
        d="M32110 32069 c-252 -28 -577 -132 -865 -276 -404 -203 -792 -502
-1138 -878 -66 -71 -254 -291 -418 -487 -346 -413 -404 -481 -585 -678 -236
-258 -387 -452 -768 -984 -114 -158 -234 -334 -267 -390 -96 -160 -202 -385
-275 -586 -87 -237 -217 -493 -504 -995 -243 -425 -392 -706 -468 -885 -75
-175 -305 -777 -356 -933 -136 -411 -243 -858 -296 -1227 -38 -272 -51 -337
-165 -825 -157 -673 -177 -797 -178 -1100 0 -249 18 -382 153 -1125 32 -179
64 -354 70 -390 16 -95 44 -145 96 -176 l44 -26 0 -108 0 -108 32 -27 31 -27
22 61 c12 33 42 87 66 118 l43 58 17 -100 c9 -55 23 -114 31 -130 9 -17 21
-56 27 -87 13 -62 45 -103 86 -110 20 -4 25 -1 25 16 0 11 5 61 10 110 8 77
14 97 43 140 84 123 89 151 92 446 2 199 6 253 19 284 11 26 16 69 16 130 0
60 4 96 13 104 7 8 14 56 18 125 5 102 9 120 43 197 104 234 110 250 114 348
6 116 -11 407 -44 762 -22 229 -25 310 -21 485 5 207 13 274 55 455 28 117 49
261 62 415 21 258 31 323 75 490 80 309 172 604 343 1105 226 659 229 667 270
712 50 55 73 58 122 14 56 -50 89 -49 162 9 56 44 176 179 235 266 17 24 63
98 103 164 141 231 416 567 999 1220 330 369 488 604 597 885 76 199 270 489
523 782 56 65 211 232 345 370 220 229 308 314 632 606 55 50 154 148 220 218
345 371 472 472 741 585 221 92 302 140 422 247 l55 48 -39 70 c-49 89 -141
233 -168 262 -24 26 -81 30 -110 7 -36 -26 -78 3 -159 111 -5 7 1 23 17 42 14
16 25 33 25 36 0 3 -21 6 -47 6 -78 0 -81 8 -40 83 29 54 33 66 21 71 -21 8
-132 5 -224 -5z m-4940 -7257 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9
-4 9 -8z m-1233 -3629 c-2 -16 -4 -5 -4 22 0 28 2 40 4 28 2 -13 2 -35 0 -50z
m795 -306 c8 -16 6 -18 -14 -15 -18 2 -24 10 -26 38 -2 34 -2 34 14 15 8 -11
20 -28 26 -38z"
      />
      <path
        d="M24320 26776 c-13 -25 -25 -46 -26 -46 -4 0 -232 52 -245 56 -13 4
-18 -12 -28 -98 -7 -57 -21 -141 -33 -188 -41 -172 -41 -269 -3 -985 31 -583
36 -697 45 -1177 15 -761 58 -1290 194 -2369 54 -420 70 -623 91 -1104 27
-622 39 -747 87 -895 24 -74 70 -171 102 -215 52 -70 42 -98 -73 -222 -229
-248 -391 -499 -496 -771 -19 -50 -71 -217 -116 -369 -177 -612 -422 -1418
-458 -1510 -104 -264 -127 -365 -92 -420 31 -50 62 -52 118 -9 l48 36 95 -101
95 -101 40 18 c24 10 66 17 105 17 75 1 121 -19 196 -84 26 -22 58 -45 71 -50
34 -13 94 -11 119 4 44 24 294 295 318 343 47 93 44 192 -8 306 -39 85 -40 99
-13 174 103 285 133 382 190 615 36 145 77 320 92 389 67 317 189 570 493
1027 138 209 151 239 134 320 -6 31 -14 58 -16 61 -3 2 -17 -3 -30 -12 -14 -9
-29 -16 -34 -16 -5 0 -3 36 6 81 14 80 14 82 -9 132 -13 28 -43 73 -66 100
-24 26 -43 54 -43 62 0 8 35 53 78 100 90 100 146 188 169 265 14 50 15 107 4
605 -6 303 -11 615 -11 695 0 186 -113 1369 -210 2190 -50 421 -75 683 -95
972 -28 406 -60 606 -175 1067 -76 309 -82 407 -38 585 32 132 11 194 -111
319 -116 121 -282 220 -401 242 -34 6 -36 4 -60 -39z m903 -4511 c32 -172 52
-314 72 -510 21 -207 66 -807 61 -812 -8 -8 -66 157 -82 230 -8 39 -21 162
-29 272 -8 110 -21 261 -29 335 -9 74 -18 221 -21 325 -3 105 -8 222 -10 260
-7 102 8 62 38 -100z m-105 -93 c3 -56 1 -62 -16 -62 -16 0 -20 9 -25 58 -5
64 -2 74 21 70 13 -3 18 -17 20 -66z m32 -246 c0 -35 3 -78 6 -95 5 -24 3 -31
-9 -31 -11 0 -16 18 -21 78 -4 42 -10 85 -12 95 -4 12 0 17 15 17 19 0 21 -6
21 -64z m32 -1428 c54 -12 98 -25 98 -28 0 -4 -27 -29 -61 -55 -51 -42 -59
-53 -54 -73 21 -85 14 -101 -57 -120 -38 -10 -38 -10 -39 21 0 18 -4 87 -7
155 -6 104 -5 122 8 122 8 0 59 -10 112 -22z m26 -1065 c-1 -5 -25 -41 -52
-81 l-50 -73 -17 33 c-15 27 -26 98 -16 98 1 0 20 7 42 15 43 15 99 20 93 8z
m-658 -84 c0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 9 6 12 15 9 8 -4 15 -10 15
-15z"
      />
      <path
        d="M11825 35730 c-22 -5 -53 -13 -69 -19 -25 -10 -28 -15 -23 -41 4 -16
-1 -62 -9 -102 -22 -101 -14 -333 20 -553 14 -93 35 -289 46 -435 41 -535 85
-840 170 -1190 53 -219 80 -394 80 -518 0 -201 36 -295 180 -477 39 -49 72
-130 85 -210 6 -36 63 -225 70 -233 2 -2 27 4 56 12 29 9 71 16 93 16 54 0
137 40 164 78 16 22 22 45 22 87 0 83 15 122 81 211 32 43 61 93 65 111 4 19
14 35 25 38 43 14 59 53 59 141 0 46 -4 84 -9 84 -18 0 -61 -62 -72 -103 -12
-47 -35 -77 -57 -77 -34 0 4 410 48 526 l11 27 30 -41 c17 -23 32 -42 34 -42
1 0 -8 51 -20 113 -13 61 -28 164 -35 227 -53 524 -162 1032 -300 1405 -115
310 -347 851 -379 886 -47 52 -270 100 -366 79z m875 -3259 c0 -3 -9 -17 -19
-30 -12 -16 -24 -22 -35 -18 -16 6 -16 9 4 37 13 18 26 27 35 23 8 -3 15 -8
15 -12z"
      />
      <path
        d="M12996 32358 c-3 -13 -6 -40 -6 -60 0 -44 29 -69 82 -72 31 -1 32 0
43 62 13 72 13 72 -71 86 -38 7 -42 5 -48 -16z"
      />
      <path
        d="M12843 32270 c-8 -38 -7 -50 3 -54 8 -3 16 12 23 45 14 62 14 59 -3
59 -8 0 -17 -19 -23 -50z"
      />
      <path
        d="M13076 28053 c-27 -60 -19 -92 31 -127 24 -16 44 -28 47 -25 2 2 -11
46 -28 98 l-32 93 -18 -39z"
      />
      <path
        d="M13164 27725 c-10 -26 4 -35 38 -23 29 10 30 12 13 25 -24 17 -44 17
-51 -2z"
      />
      <path
        d="M21666 23445 c-15 -11 -17 -18 -9 -26 13 -13 66 17 57 32 -8 13 -26
11 -48 -6z"
      />
      <path
        d="M7253 22195 c-3 -9 -3 -18 0 -21 9 -9 170 -43 179 -38 26 16 1 33
-77 53 -101 25 -94 25 -102 6z"
      />
      <path
        d="M7743 21974 c-3 -8 -1 -20 6 -27 14 -14 47 15 37 32 -10 16 -36 13
-43 -5z"
      />
      <path
        d="M7230 18524 c0 -13 7 -15 31 -10 17 3 52 7 78 7 l46 2 -45 8 c-76 13
-110 11 -110 -7z"
      />
      <path
        d="M26945 36971 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4
-15 -9z"
      />
      <path
        d="M15662 34556 c-64 -21 -110 -56 -178 -138 -131 -156 -210 -282 -258
-409 -14 -37 -52 -178 -85 -315 -132 -552 -142 -740 -75 -1474 23 -248 27
-349 28 -635 1 -372 2 -366 -74 -810 -21 -126 -49 -304 -61 -395 -15 -120 -45
-263 -111 -525 -117 -472 -141 -597 -178 -920 -17 -148 -42 -330 -56 -403 -75
-407 -76 -817 -4 -1272 11 -69 25 -140 31 -157 15 -47 90 -109 149 -123 83
-20 124 -3 233 99 80 75 93 111 101 286 10 224 33 312 106 404 45 56 53 116
46 335 -4 105 -9 222 -12 260 -8 99 10 137 118 253 110 119 128 149 178 299
107 317 131 457 155 904 15 278 34 520 95 1195 33 365 70 818 70 851 0 16 12
28 45 44 85 41 89 59 184 695 33 220 66 443 73 495 12 86 15 95 36 98 43 6 47
-29 28 -241 -9 -106 -23 -242 -31 -302 -30 -225 -30 -217 -10 -214 10 2 24 20
31 41 20 59 134 877 134 966 0 57 -22 201 -75 492 -41 226 -75 416 -75 421 0
5 -36 27 -80 49 l-80 40 -6 -25 -6 -25 -69 37 c-54 29 -69 42 -71 62 -6 53
-154 87 -246 57z m287 -185 c-14 -4 -16 -1 -23 39 l-7 35 20 -35 c11 -19 15
-37 10 -39z m95 -374 l28 -49 -23 7 c-44 13 -76 74 -52 99 8 8 13 2 47 -57z
m211 -408 c14 -44 25 -92 25 -107 0 -25 -30 -95 -51 -116 -5 -6 -9 54 -9 147
0 86 2 157 5 157 2 0 16 -36 30 -81z m-188 -361 c-2 -13 -4 -3 -4 22 0 25 2
35 4 23 2 -13 2 -33 0 -45z m-53 -384 c-4 -20 -12 -59 -19 -88 l-13 -51 -1 88
c-1 81 0 87 19 87 19 0 20 -4 14 -36z m-59 -444 c-7 -107 -14 -197 -15 -200
-1 -3 -6 9 -10 25 -11 44 -11 223 0 275 11 46 31 103 35 98 2 -2 -3 -91 -10
-198z"
      />
      <path
        d="M17712 34486 c-171 -92 -255 -194 -456 -551 -136 -241 -343 -654
-428 -855 -116 -275 -267 -706 -473 -1350 -114 -356 -180 -673 -196 -935 -6
-93 -28 -345 -49 -560 -55 -543 -62 -658 -58 -915 3 -253 7 -268 75 -291 65
-21 214 3 318 52 72 33 123 81 169 158 19 31 67 85 106 119 86 77 119 133 165
278 21 65 57 146 86 195 89 151 105 219 134 569 23 283 50 443 99 598 52 162
70 261 91 481 19 204 18 201 333 1001 156 397 192 502 252 739 48 192 84 294
258 728 l129 321 -81 7 -81 6 -50 54 c-45 49 -244 185 -270 185 -5 0 -38 -16
-73 -34z m382 -377 c-16 -15 -28 -18 -48 -13 -33 8 -24 19 24 32 44 11 49 7
24 -19z"
      />
      <path
        d="M20105 33620 c-6 -10 24 -70 35 -70 16 0 18 15 5 41 -18 33 -31 43
-40 29z"
      />
      <path
        d="M18665 31866 c-24 -10 -48 -45 -120 -175 -49 -88 -92 -161 -96 -161
-3 0 -26 7 -49 15 -72 25 -89 21 -148 -41 -121 -129 -311 -406 -442 -644 -152
-277 -382 -842 -505 -1241 -60 -194 -112 -333 -258 -679 -92 -219 -171 -429
-277 -735 -31 -90 -83 -210 -130 -300 -178 -343 -242 -559 -360 -1227 -100
-563 -166 -886 -189 -921 l-21 -32 0 60 c0 33 7 119 15 190 23 201 20 293 -15
447 -16 73 -46 237 -66 363 -20 127 -37 231 -38 233 -2 1 -29 -9 -61 -24 -42
-18 -68 -24 -91 -19 -51 9 -109 -20 -141 -71 -15 -24 -32 -44 -37 -44 -4 0
-35 23 -68 50 l-60 50 56 25 c31 14 82 36 113 50 48 22 55 28 42 39 -8 7 -63
23 -124 35 -60 12 -119 24 -130 26 -16 5 -24 -4 -44 -49 -13 -31 -27 -88 -31
-128 -17 -151 -60 -288 -140 -448 -63 -127 -70 -150 -140 -470 -33 -151 -99
-430 -145 -620 -111 -456 -132 -559 -149 -736 -39 -383 -73 -1532 -47 -1560 5
-5 18 -47 29 -94 19 -79 21 -133 29 -760 10 -811 7 -1049 -12 -1235 -8 -77
-15 -198 -15 -270 -1 -293 -17 -359 -188 -790 -151 -379 -196 -549 -196 -740
1 -251 71 -417 278 -660 48 -55 102 -131 123 -170 20 -38 69 -121 110 -183 88
-133 118 -193 178 -345 80 -205 144 -441 225 -832 48 -231 87 -359 140 -465
60 -119 57 -117 190 -128 127 -11 132 -9 182 61 l20 27 -27 46 c-15 25 -25 47
-23 48 1 1 23 8 48 15 90 26 276 172 325 255 41 70 58 165 59 321 1 127 -2
152 -32 267 -68 265 -186 575 -299 788 -130 246 -274 440 -485 656 -176 181
-176 179 -96 282 73 96 90 139 163 418 92 357 96 380 102 674 7 351 -14 467
-111 610 -25 36 -52 82 -61 103 -21 50 -19 157 5 313 33 218 60 500 101 1069
9 123 18 181 39 250 15 50 80 254 144 455 63 201 131 422 150 492 69 251 121
553 131 763 12 226 21 255 84 255 25 0 31 -7 55 -59 14 -32 26 -66 26 -75 0
-33 30 -74 61 -85 45 -16 145 -14 213 4 147 39 283 148 345 278 39 82 106 342
171 662 92 451 145 628 269 888 26 56 55 125 64 152 40 126 48 281 21 443 -23
139 -15 162 95 276 94 98 132 150 176 235 42 84 51 117 116 406 89 400 98 467
109 850 5 182 14 365 19 408 14 103 52 191 144 329 67 101 86 139 147 306 38
105 100 289 136 409 69 229 109 322 191 444 28 41 61 97 75 124 40 80 256 746
288 892 18 78 31 278 21 306 -19 51 -100 78 -156 53z m59 -181 l-27 -68 -29
32 c-31 35 -35 53 -14 70 14 12 89 40 94 36 2 -1 -9 -33 -24 -70z m-699 -795
c3 -6 -1 -13 -10 -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z"
      />
      <path
        d="M14185 30730 c4 -52 8 -130 9 -175 1 -52 10 -103 24 -145 43 -127 45
-188 6 -173 -21 8 -18 -47 6 -98 13 -30 20 -66 20 -114 0 -39 4 -106 10 -150
36 -293 53 -525 59 -829 4 -235 9 -330 18 -333 18 -6 31 50 58 262 14 110 32
250 40 310 23 172 29 375 16 510 -9 90 -37 218 -111 510 -96 382 -119 460
-148 500 -12 18 -13 8 -7 -75z"
      />
      <path
        d="M14296 27914 c-9 -24 -7 -84 3 -84 5 0 11 23 13 50 3 49 -4 65 -16
34z"
      />
      <path
        d="M12854 23189 c-3 -6 1 -18 10 -27 14 -14 17 -14 36 3 16 14 17 20 8
27 -18 11 -46 10 -54 -3z"
      />
      <path
        d="M12280 21416 c-10 -13 -1 -28 50 -91 35 -41 66 -75 70 -75 4 0 13 6
19 14 9 11 2 27 -36 77 -59 77 -85 96 -103 75z"
      />
      <path
        d="M12500 21097 c0 -3 23 -25 52 -49 l52 -43 -25 45 c-18 34 -31 46 -52
48 -15 2 -27 1 -27 -1z"
      />
      <path
        d="M12600 20987 c0 -25 25 -47 60 -53 l35 -5 -40 35 c-43 38 -55 43 -55
23z"
      />
      <path
        d="M20716 19732 c-6 -10 33 -77 40 -69 6 5 -22 77 -30 77 -3 0 -7 -4
-10 -8z"
      />
      <path
        d="M11455 16750 c-13 -14 -15 -24 -8 -31 8 -8 15 -4 27 12 28 41 14 55
-19 19z"
      />
      <path
        d="M14264 16735 c-9 -23 10 -40 25 -22 9 11 8 17 -4 27 -12 10 -16 9
-21 -5z"
      />
      <path
        d="M22770 12324 c-14 -35 -12 -51 4 -57 9 -4 16 8 23 39 8 36 8 44 -4
44 -7 0 -18 -12 -23 -26z"
      />
      <path
        d="M22431 11389 c-19 -8 -21 -12 -11 -24 7 -9 13 -14 14 -13 1 2 10 13
20 26 20 24 15 26 -23 11z"
      />
      <path
        d="M11840 10494 c-6 -14 -10 -93 -10 -176 0 -213 -67 -920 -105 -1112
-65 -325 -73 -351 -135 -411 -46 -45 -51 -77 -24 -173 l16 -59 -36 -83 c-31
-70 -116 -329 -116 -353 0 -4 9 -2 20 5 39 24 40 -5 2 -69 -38 -66 -52 -77
-52 -42 0 27 -8 23 -56 -30 -42 -48 -44 -46 35 -25 21 6 23 4 16 -17 -8 -30
-37 -88 -108 -214 -134 -241 -153 -271 -229 -347 -78 -80 -79 -94 -3 -59 53
24 81 54 156 164 35 51 86 119 115 152 217 250 321 489 388 890 81 483 196
1777 172 1939 -8 52 -30 62 -46 20z"
      />
      <path
        d="M11065 7720 c-12 -24 -13 -30 -2 -30 19 0 37 21 37 42 0 28 -18 21
-35 -12z"
      />
      <path
        d="M10996 7636 c-27 -25 -36 -42 -36 -65 l0 -32 30 21 c36 26 60 58 60
82 0 30 -18 29 -54 -6z"
      />
      <path
        d="M11067 7562 c-28 -29 -34 -47 -18 -56 5 -3 21 10 36 30 35 45 20 66
-18 26z"
      />
      <path
        d="M10405 7240 c31 -33 64 -38 112 -18 l28 11 -50 12 c-27 6 -66 15 -85
18 l-34 7 29 -30z"
      />
      <path d="M16442 5871 c-8 -23 -7 -31 4 -35 10 -4 14 3 14 29 0 43 -5 44 -18 6z" />
      <path
        d="M22870 5254 c0 -3 14 -25 31 -50 35 -51 89 -91 137 -100 l32 -6 0 54
c0 65 -14 82 -80 97 -49 11 -120 14 -120 5z"
      />
      <path
        d="M27110 4715 c0 -11 11 -15 40 -15 29 0 40 4 40 15 0 11 -11 15 -40
15 -29 0 -40 -4 -40 -15z"
      />
      <path
        d="M27034 4605 c-45 -19 -48 -30 -13 -59 56 -47 117 -28 91 29 -16 36
-42 45 -78 30z"
      />
      <path
        d="M29307 4355 c-46 -48 -72 -84 -83 -117 -8 -27 -14 -53 -12 -58 5 -17
96 47 118 82 24 39 53 133 47 151 -2 7 -33 -18 -70 -58z"
      />
    </g>
  </svg>,
  <svg
    className="logo"
    viewBox="0 0 2000 2000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(0.000000,1920.000000) scale(0.100000,-0.100000)"
      fill="#002A3A"
      stroke="none"
    >
      <path
        d="M4992 17908 c-7 -7 -27 -18 -45 -24 -30 -10 -32 -15 -38 -65 -10 -93
-25 -124 -69 -144 -28 -13 -43 -28 -54 -54 -8 -21 -21 -43 -30 -50 -8 -7 -18
-29 -22 -50 -3 -20 -26 -79 -50 -131 -37 -80 -46 -114 -59 -213 -8 -65 -23
-137 -31 -160 -37 -97 -100 -286 -229 -682 -74 -231 -160 -494 -190 -585 -106
-326 -215 -831 -250 -1155 -8 -77 -25 -210 -39 -295 -32 -202 -50 -337 -80
-600 -23 -200 -41 -285 -67 -316 -14 -17 -129 -426 -174 -618 -51 -222 -86
-452 -92 -609 -5 -118 -8 -138 -21 -135 -11 2 -28 42 -53 118 -20 63 -58 165
-83 225 -48 112 -207 543 -298 805 -69 198 -260 644 -403 938 -111 228 -120
242 -145 242 -33 0 -41 9 -65 75 -10 28 -39 86 -65 130 -26 44 -62 109 -80
145 -18 36 -65 117 -106 181 -40 64 -94 158 -120 208 -54 103 -126 189 -188
226 -24 14 -54 41 -68 60 -23 33 -99 95 -116 95 -5 0 -17 10 -28 21 -10 12
-39 35 -64 52 -25 17 -86 61 -136 99 -51 37 -96 68 -101 68 -5 0 -27 13 -49
29 -42 30 -94 51 -129 51 -12 0 -42 9 -67 20 -67 29 -290 91 -309 85 -9 -3
-34 -6 -55 -7 -22 0 -50 -9 -63 -20 -13 -10 -29 -18 -36 -18 -20 0 -103 -68
-110 -90 -4 -13 1 -30 14 -46 25 -31 27 -57 6 -74 -13 -10 -12 -17 7 -53 11
-23 31 -54 43 -69 38 -46 54 -138 43 -259 -10 -120 -5 -412 11 -579 10 -105
16 -138 41 -240 4 -19 14 -69 20 -110 7 -41 39 -155 71 -252 32 -97 59 -190
59 -205 0 -39 25 -171 54 -283 14 -52 37 -149 51 -215 15 -66 35 -160 46 -210
34 -149 48 -280 50 -452 1 -114 7 -187 19 -241 10 -42 20 -103 24 -135 6 -55
5 -58 -14 -52 -13 4 -22 1 -26 -9 -11 -29 21 -586 56 -986 l10 -115 -54 -165
c-29 -91 -68 -230 -86 -310 -40 -184 -67 -264 -141 -422 -32 -69 -66 -154 -74
-187 -9 -33 -29 -89 -44 -123 -15 -35 -33 -89 -40 -120 -7 -31 -26 -75 -42
-98 -23 -31 -32 -56 -35 -97 -2 -30 -18 -94 -35 -141 -53 -148 -75 -241 -100
-427 -6 -44 -19 -111 -29 -150 -10 -38 -21 -95 -24 -125 -25 -269 -28 -331
-23 -499 3 -111 11 -210 20 -246 24 -99 55 -264 67 -360 22 -183 161 -729 207
-818 8 -17 29 -69 44 -116 21 -62 26 -88 18 -97 -16 -19 -4 -77 46 -234 24
-74 51 -162 60 -195 42 -152 179 -447 325 -701 115 -200 140 -238 220 -340 32
-40 65 -96 78 -131 13 -34 38 -83 58 -110 19 -26 50 -81 70 -121 19 -39 39
-72 44 -72 6 0 81 -96 169 -213 168 -225 243 -305 332 -356 30 -17 63 -39 72
-48 33 -35 67 -81 67 -92 0 -5 10 -16 23 -22 12 -6 40 -30 63 -53 47 -48 114
-98 193 -143 100 -58 274 -140 346 -163 39 -13 111 -42 162 -65 50 -24 122
-53 159 -65 53 -18 79 -34 120 -75 84 -85 172 -125 344 -154 36 -7 88 -16 115
-20 28 -5 79 -14 115 -20 36 -6 162 -28 280 -48 261 -45 466 -54 645 -28 61 9
153 20 206 25 53 4 106 13 117 19 11 6 48 11 82 11 73 0 181 21 281 56 67 23
141 39 284 64 28 5 93 23 145 40 51 16 100 30 107 30 11 0 12 -32 9 -157 -4
-129 -1 -173 14 -243 25 -117 72 -245 136 -371 179 -358 400 -649 634 -834 41
-32 95 -78 120 -101 25 -23 88 -66 140 -94 52 -28 109 -64 125 -79 17 -16 40
-32 52 -35 12 -4 52 -30 90 -57 37 -28 94 -64 127 -81 72 -37 239 -77 366 -88
52 -5 126 -14 164 -20 44 -8 126 -11 230 -8 136 5 173 9 238 31 117 37 263
122 312 180 23 27 85 80 138 118 95 68 148 125 148 161 0 9 -4 19 -8 22 -5 3
-21 41 -36 86 -24 68 -27 91 -23 158 3 42 10 209 17 370 10 280 10 297 -10
409 -24 135 -55 219 -90 245 -24 18 -28 18 -75 2 -28 -9 -62 -25 -77 -35 -14
-11 -33 -19 -41 -19 -8 0 -30 -14 -48 -31 -18 -17 -54 -41 -79 -54 -25 -13
-77 -52 -115 -86 -75 -68 -235 -176 -275 -186 -14 -3 -31 -10 -37 -15 -9 -7
-40 3 -100 32 -49 23 -109 51 -135 61 -89 35 -187 89 -310 170 -68 45 -179
109 -248 144 -155 78 -224 124 -390 260 -142 118 -224 200 -255 259 l-20 38
28 22 c15 12 27 29 27 39 0 20 31 36 123 67 37 12 88 37 113 56 24 19 51 34
58 34 8 0 17 4 21 9 3 6 54 37 113 69 115 64 356 211 513 312 120 77 222 177
352 345 56 72 157 189 226 260 227 236 448 509 527 651 56 99 79 173 118 366
20 95 47 206 61 247 19 57 25 95 25 162 0 96 29 257 59 325 11 24 33 60 50 81
51 65 75 167 76 318 0 117 -2 133 -21 160 -58 81 -81 173 -54 215 8 12 13 11
33 -9 l25 -23 11 34 c38 120 81 346 106 553 9 72 24 182 35 245 39 237 70 464
104 770 44 386 67 560 77 580 11 22 25 -40 63 -295 19 -127 39 -251 44 -277 8
-38 6 -55 -9 -88 -16 -36 -17 -52 -9 -108 5 -37 14 -128 19 -202 6 -74 17
-168 26 -208 8 -40 19 -114 25 -165 6 -51 21 -132 34 -182 14 -49 27 -110 31
-135 3 -25 10 -74 15 -110 18 -119 29 -269 31 -408 1 -75 5 -146 9 -157 5 -11
14 -67 20 -125 6 -58 18 -134 26 -170 8 -36 18 -104 24 -152 5 -48 13 -96 18
-105 14 -28 50 -192 57 -258 3 -33 10 -76 14 -95 5 -19 14 -60 21 -90 6 -30
17 -71 25 -90 26 -66 85 -267 100 -341 18 -87 60 -221 82 -264 8 -16 26 -52
40 -80 13 -27 30 -61 38 -75 8 -14 23 -45 35 -70 66 -144 180 -319 303 -465
100 -118 390 -404 450 -442 76 -50 161 -117 247 -197 113 -105 258 -212 377
-279 123 -69 161 -81 234 -74 58 5 58 5 129 -50 38 -30 70 -59 70 -64 0 -25
-48 -100 -99 -154 -31 -33 -87 -96 -125 -140 -95 -111 -192 -195 -321 -280
-60 -40 -134 -91 -162 -114 -175 -138 -398 -246 -635 -307 -88 -23 -108 -25
-154 -16 -64 13 -140 51 -209 103 -32 24 -70 42 -97 47 -33 7 -62 23 -109 63
-125 106 -234 149 -324 129 -55 -12 -96 -32 -115 -55 -10 -12 -11 -24 -3 -49
9 -29 6 -45 -19 -108 -76 -185 -93 -303 -85 -576 6 -219 16 -271 68 -358 53
-88 124 -154 307 -283 43 -29 81 -61 86 -71 4 -10 31 -26 60 -35 28 -9 59 -24
68 -32 38 -34 169 -63 338 -75 162 -11 168 -11 298 16 136 27 447 117 492 142
14 7 63 28 109 45 158 60 370 226 634 499 162 168 221 239 260 315 14 28 45
73 69 101 28 32 83 129 148 260 57 115 119 228 136 251 17 23 37 65 44 93 7
29 25 67 41 85 16 18 39 54 50 80 l21 48 77 -6 c96 -8 277 -46 462 -97 391
-109 449 -120 651 -120 156 0 286 18 508 70 91 21 228 48 305 60 222 33 472
102 640 175 82 36 192 65 247 65 134 0 356 61 604 166 232 99 406 214 545 360
67 72 105 126 153 222 20 41 54 92 75 115 20 23 80 94 133 157 52 63 121 142
152 175 32 33 75 85 96 115 21 30 61 82 89 115 113 133 241 294 285 357 47 68
162 336 221 518 15 47 49 126 75 175 26 50 67 149 90 220 24 72 58 161 77 198
24 49 37 91 43 144 7 62 13 81 40 114 31 39 33 45 28 139 0 19 27 127 61 240
157 517 177 660 168 1165 -7 363 -18 499 -48 606 -23 82 -32 132 -49 264 -12
94 -82 330 -133 450 -16 39 -49 122 -73 185 -23 63 -59 153 -78 200 -20 48
-37 109 -40 141 -2 31 -11 70 -19 85 -8 16 -17 51 -21 77 -3 27 -22 85 -42
130 -20 45 -40 107 -45 137 -4 30 -14 107 -23 170 -24 172 -33 222 -44 250 -5
14 -15 111 -21 215 -24 392 -42 612 -57 665 -14 47 -14 93 -4 375 13 369 52
930 75 1090 35 240 113 531 230 855 36 100 102 311 145 467 91 323 171 576
206 644 21 42 56 78 115 120 21 14 22 19 13 70 -8 49 -12 54 -36 57 -35 4 -36
25 -3 46 20 13 25 25 25 59 0 51 -46 131 -81 142 -13 4 -31 17 -40 29 -17 20
-18 20 -65 3 -27 -9 -61 -17 -75 -17 -15 0 -40 -7 -55 -15 -16 -8 -37 -15 -46
-15 -9 0 -54 -12 -100 -26 -46 -14 -119 -37 -163 -51 -44 -13 -129 -46 -190
-73 -60 -26 -171 -74 -245 -105 -151 -64 -155 -66 -299 -183 -295 -242 -539
-566 -755 -1006 -115 -234 -504 -1154 -652 -1543 -142 -374 -215 -642 -284
-1050 -17 -98 -38 -204 -46 -236 -8 -32 -19 -104 -24 -160 -10 -109 -37 -224
-94 -401 -20 -60 -36 -123 -37 -140 0 -17 -4 -40 -9 -51 -22 -55 -28 98 -17
460 1 33 -11 150 -27 260 -31 225 -34 343 -10 504 8 58 17 126 19 151 14 177
27 306 36 372 7 45 8 111 4 155 -13 143 -75 505 -94 542 -22 44 -46 179 -38
218 6 27 -2 120 -24 308 -18 148 -86 506 -139 725 -52 214 -143 665 -155 760
-3 30 -24 109 -46 175 -37 114 -98 365 -175 725 -34 158 -237 918 -254 950 -4
8 -22 68 -39 134 -17 65 -47 157 -66 205 -60 145 -106 292 -115 371 -8 61 -14
79 -35 98 -29 24 -65 30 -65 9 0 -36 -40 -35 -136 3 -29 11 -59 -10 -86 -63
-20 -39 -22 -63 -29 -297 -5 -178 -12 -276 -24 -325 -9 -38 -20 -104 -25 -145
-24 -185 -38 -246 -64 -280 -22 -27 -37 -72 -66 -194 -34 -145 -111 -373 -145
-430 -13 -23 -35 -83 -49 -133 -13 -51 -31 -102 -40 -115 -9 -12 -16 -37 -16
-55 0 -32 -49 -145 -87 -200 -11 -15 -33 -57 -50 -93 -35 -76 -142 -253 -263
-435 -47 -71 -104 -159 -125 -195 -21 -36 -68 -108 -105 -160 -68 -96 -241
-435 -319 -624 -25 -60 -58 -119 -84 -150 -24 -28 -65 -82 -91 -120 -27 -38
-103 -137 -170 -221 -67 -83 -142 -182 -168 -218 -27 -37 -51 -67 -55 -67 -21
0 -6 1128 17 1250 6 30 17 152 26 270 38 544 42 581 62 588 6 2 16 -6 20 -19
13 -33 22 -11 32 79 5 43 16 119 26 168 14 78 16 122 8 349 -9 269 -10 470 -3
710 4 124 1 156 -25 280 -24 120 -28 156 -23 247 6 140 3 154 -45 184 -22 14
-44 38 -50 55 -10 29 -40 40 -53 19 -13 -21 -42 -9 -56 23 -14 31 -16 32 -74
32 -54 0 -65 -4 -102 -35 -35 -29 -43 -43 -48 -82 -5 -35 -13 -51 -32 -64 -48
-31 -116 -98 -144 -142 -15 -24 -47 -56 -71 -71 -24 -15 -68 -53 -98 -83 -30
-31 -86 -81 -125 -113 -128 -104 -402 -366 -441 -422 -22 -30 -61 -84 -87
-121 -97 -133 -139 -204 -173 -285 -18 -45 -34 -88 -34 -94 0 -7 -4 -13 -10
-13 -5 0 -10 -8 -10 -17 0 -10 -16 -28 -35 -41 -20 -13 -62 -63 -99 -120 -36
-54 -86 -119 -111 -143 -28 -28 -46 -55 -47 -70 -1 -13 -9 -33 -19 -44 -9 -11
-33 -51 -52 -90 -20 -38 -74 -122 -122 -185 -118 -159 -245 -410 -336 -668
-38 -108 -69 -209 -69 -224 0 -37 -31 -148 -41 -148 -8 0 -29 63 -29 86 0 24
-113 391 -131 424 -10 19 -32 70 -50 113 -35 87 -102 193 -177 281 -28 33 -91
133 -143 230 -50 94 -118 214 -150 266 -32 52 -72 124 -90 160 -49 100 -180
295 -390 582 -240 328 -441 588 -505 652 -104 105 -237 190 -539 346 -176 90
-234 106 -280 76 -14 -9 -48 -20 -75 -23 -49 -6 -50 -6 -50 -41 0 -22 8 -44
20 -57 21 -22 25 -75 35 -443 2 -89 9 -171 15 -183 7 -13 8 -25 2 -32 -12 -15
-27 -652 -23 -977 2 -167 9 -298 22 -410 11 -91 24 -259 28 -375 5 -115 13
-222 20 -238 28 -71 21 -284 -14 -412 -22 -78 -34 -230 -37 -472 -3 -201 -4
-220 -19 -207 -8 8 -24 14 -35 14 -13 1 -42 39 -100 133 -45 73 -92 152 -104
177 -13 25 -31 51 -41 58 -11 7 -38 41 -61 75 -23 34 -67 88 -97 120 -53 56
-55 61 -62 127 -10 93 -36 146 -109 225 -34 36 -68 80 -75 98 -8 18 -68 115
-134 217 -66 102 -150 231 -187 287 -74 115 -228 448 -360 781 -46 118 -104
253 -128 300 -54 109 -66 145 -172 482 -186 599 -224 774 -224 1043 0 132 -13
173 -60 183 -70 16 -93 28 -112 60 -21 36 -36 42 -56 22z m6869 -1694 c-3
-183 -1 -222 11 -235 15 -15 13 -39 -6 -51 -8 -5 -8 -10 0 -18 12 -12 7 -57
-7 -65 -4 -3 -5 -27 -2 -53 4 -37 2 -51 -11 -61 -11 -10 -16 -30 -16 -74 0
-33 -6 -103 -14 -156 -27 -185 -36 -291 -37 -453 0 -90 -5 -201 -11 -248 -6
-47 -11 -114 -12 -150 0 -36 -5 -71 -10 -78 -10 -17 1 -730 16 -947 6 -88 12
-189 13 -225 1 -36 7 -90 14 -120 9 -45 8 -57 -4 -70 -13 -15 -13 -21 2 -50
21 -43 24 -250 4 -330 -8 -30 -18 -72 -23 -94 -5 -21 -13 -35 -17 -30 -12 13
-76 213 -100 313 -12 47 -29 103 -40 125 -22 47 -53 143 -76 231 -23 92 -65
458 -76 665 -21 420 -10 624 57 1078 40 269 41 278 94 472 22 80 62 255 90
390 40 196 49 256 44 298 l-7 54 54 54 c29 30 58 53 64 51 6 -3 9 -79 6 -223z
m-6841 81 c56 -191 105 -314 213 -530 111 -224 192 -402 258 -568 61 -154 179
-373 300 -558 117 -178 120 -186 94 -274 -23 -75 -45 -284 -45 -430 0 -290
-43 -764 -95 -1040 -18 -99 -36 -201 -39 -226 -9 -60 -51 -148 -197 -406 -66
-116 -125 -229 -131 -249 -9 -33 -12 -36 -22 -22 -11 15 -100 526 -126 723 -6
44 -17 103 -24 130 -8 28 -17 93 -20 145 -4 52 -9 113 -11 135 -3 22 -10 76
-15 120 -12 97 -33 519 -32 632 1 63 -2 82 -13 87 -8 3 -15 18 -15 33 0 41
-36 302 -71 504 -28 167 -55 472 -46 521 3 22 4 22 11 3 4 -11 12 -29 17 -40
15 -31 3 226 -14 330 -24 141 -58 1028 -42 1092 10 43 29 11 65 -112z m2437
-34 c-13 -5 -15 1 -17 44 -4 78 3 89 14 25 6 -36 8 -67 3 -69z m6849 -102
c-14 -16 -33 -53 -43 -82 -16 -47 -18 -106 -19 -667 0 -338 -4 -631 -7 -650
-4 -20 -12 -94 -17 -164 -5 -71 -21 -204 -34 -295 -14 -91 -32 -251 -40 -356
-9 -104 -33 -325 -55 -490 -44 -337 -56 -447 -71 -685 -8 -135 -40 -906 -40
-987 0 -54 -32 -13 -103 135 -42 87 -90 191 -107 232 -17 41 -69 148 -115 238
-106 205 -143 298 -196 492 -49 182 -57 226 -49 260 9 34 1 276 -10 320 -8 31
-26 174 -34 270 -2 19 -7 73 -11 120 -4 47 -9 114 -10 150 -2 36 -6 121 -10
190 -9 143 -1 172 73 272 22 29 70 107 107 173 38 66 80 134 96 150 15 17 66
91 114 165 48 74 109 162 134 195 154 197 225 348 327 689 44 149 46 162 37
214 -9 51 -8 60 13 93 12 20 38 67 56 105 l33 69 3 -63 c3 -55 0 -67 -22 -93z
m-6792 -74 c9 -60 37 -195 63 -300 25 -104 67 -280 94 -390 62 -260 78 -364
93 -610 2 -33 7 -96 10 -140 4 -44 13 -180 21 -302 13 -195 13 -228 0 -270
-21 -67 -111 -516 -129 -643 -23 -159 -37 -334 -34 -436 1 -77 -1 -95 -19
-120 -11 -16 -26 -47 -33 -69 -7 -22 -19 -55 -27 -73 -12 -25 -13 -35 -3 -47
9 -11 4 -28 -26 -85 -33 -61 -40 -68 -53 -56 -12 13 -11 38 5 193 12 117 18
260 18 418 1 215 3 244 20 274 16 30 20 71 28 285 10 251 7 398 -11 661 -10
157 -20 235 -31 250 -4 6 -6 111 -5 235 2 147 -1 261 -10 330 -8 58 -17 148
-20 200 -3 52 -9 124 -12 160 -7 61 -6 416 0 575 6 141 37 120 61 -40z m-2590
-283 c33 -266 46 -385 46 -427 0 -27 7 -103 16 -168 13 -87 14 -121 6 -129 -8
-8 -13 -8 -16 3 -6 17 -76 596 -83 686 -6 65 1 98 16 89 5 -3 12 -27 15 -54z
m6187 -284 c-7 -51 -21 -127 -31 -168 -11 -41 -22 -109 -25 -150 -4 -41 -17
-138 -30 -215 -44 -259 -113 -789 -124 -948 -8 -113 -15 -166 -28 -190 -9 -17
-20 -60 -24 -93 -7 -69 -93 -267 -158 -364 -21 -30 -57 -91 -81 -135 -114
-212 -355 -568 -379 -563 -14 3 -16 31 -16 223 0 340 3 369 40 445 53 106 83
206 110 365 14 83 54 267 89 410 89 360 107 441 110 489 4 60 15 86 52 130 18
21 39 55 45 74 6 20 28 61 49 93 20 32 43 79 50 105 8 29 25 58 41 72 15 12
36 44 45 70 10 26 32 72 50 102 17 30 35 66 39 79 10 32 45 91 55 91 4 0 17
19 28 43 11 24 27 49 36 56 9 8 16 18 16 24 0 13 42 59 48 52 3 -2 -1 -46 -7
-97z m-2942 -48 c82 -124 172 -296 221 -422 91 -231 233 -658 240 -718 4 -36
18 -87 32 -115 18 -35 27 -74 31 -130 6 -73 68 -359 148 -685 94 -381 159
-671 159 -711 0 -62 -24 -85 -51 -49 -11 14 -29 36 -42 50 -12 14 -27 36 -34
50 -7 14 -21 39 -32 55 -10 17 -79 152 -154 300 -100 199 -138 286 -146 332
-11 59 -114 429 -186 663 -61 202 -125 526 -160 815 -9 72 -38 243 -65 380
-51 255 -56 295 -42 295 5 0 41 -50 81 -110z m-6630 -425 c67 -84 293 -432
345 -529 20 -38 40 -74 45 -80 5 -6 12 -22 16 -36 4 -14 19 -42 31 -62 13 -21
24 -44 24 -51 0 -8 10 -27 22 -42 26 -33 120 -223 138 -280 7 -22 20 -51 29
-65 9 -14 23 -43 30 -65 7 -22 23 -62 36 -90 12 -27 36 -79 53 -115 33 -72
138 -372 171 -492 12 -42 42 -120 67 -173 25 -53 50 -121 54 -153 13 -82 57
-287 70 -327 6 -19 12 -72 13 -117 2 -79 -2 -133 -23 -333 -5 -49 -12 -121
-14 -160 -8 -114 -24 -233 -35 -253 -11 -20 -29 -104 -61 -276 -6 -32 -15 -66
-19 -75 -5 -9 -19 -61 -31 -116 -11 -55 -48 -208 -80 -340 -33 -132 -64 -264
-70 -293 -5 -30 -16 -68 -24 -85 -7 -18 -25 -86 -40 -152 -31 -143 -187 -616
-201 -612 -5 2 -16 31 -23 65 -14 65 -26 77 -36 40 -10 -39 -7 -92 9 -146 13
-44 14 -58 2 -97 -7 -26 -17 -46 -22 -47 -6 0 -22 35 -38 78 -15 43 -34 89
-43 102 -14 21 -14 28 1 70 18 51 58 403 59 519 1 43 6 77 14 84 15 16 16 45
1 55 -6 3 -8 17 -4 31 4 18 1 29 -11 38 -16 11 -17 27 -7 201 6 104 10 207 10
229 -2 93 24 569 30 576 5 4 17 9 28 11 18 3 20 12 22 78 1 41 7 145 13 230 6
85 12 191 15 235 2 44 9 127 15 185 8 78 8 142 0 250 -6 80 -9 181 -5 225 7
95 3 130 -68 570 -30 187 -60 378 -66 425 -6 47 -22 119 -35 160 -14 41 -29
119 -35 172 -5 53 -17 111 -26 128 -9 18 -40 100 -70 183 -147 421 -223 591
-354 797 -15 24 -15 24 3 10 11 -8 45 -47 75 -85z m3030 -80 c23 -375 31 -509
41 -710 6 -115 17 -284 25 -375 31 -340 22 -288 176 -1080 22 -113 59 -290 83
-395 24 -104 56 -273 71 -375 15 -102 34 -203 42 -225 13 -38 13 -41 -23 -110
-20 -38 -50 -104 -65 -145 l-27 -75 -15 35 c-8 19 -20 67 -26 105 -6 39 -20
97 -31 129 -11 33 -20 74 -20 91 0 18 -7 52 -15 75 -8 23 -15 55 -15 70 0 15
-7 44 -15 63 -8 20 -15 52 -15 70 0 19 -9 55 -21 81 -11 25 -29 95 -40 154
-11 59 -26 130 -34 157 -7 28 -19 97 -25 154 -6 57 -23 140 -36 185 -42 139
-86 431 -114 756 -17 200 -24 777 -11 920 26 288 81 634 97 617 2 -1 7 -79 13
-172z m10155 -80 c61 -199 121 -488 107 -513 -6 -11 -12 1 -21 44 -6 32 -16
68 -20 79 -14 33 -11 -187 5 -350 30 -304 17 -754 -23 -831 -14 -28 -48 -193
-86 -430 -65 -394 -183 -833 -285 -1059 -29 -63 -43 -76 -56 -55 -10 17 14
235 45 395 16 83 31 195 35 250 3 55 12 127 19 160 40 171 46 205 46 263 0
102 58 563 85 681 23 102 25 127 30 502 2 217 9 477 15 579 6 102 13 250 16
330 6 157 11 188 25 155 4 -11 33 -101 63 -200z m2956 -290 c0 -2 -14 -27 -30
-55 -42 -72 -239 -666 -246 -744 -8 -81 -33 -156 -62 -181 -15 -13 -27 -41
-36 -80 -7 -33 -20 -89 -29 -125 -8 -36 -23 -105 -32 -155 -9 -49 -34 -136
-55 -192 -21 -55 -41 -120 -45 -144 -4 -24 -18 -59 -30 -79 -13 -19 -30 -61
-39 -92 -10 -35 -24 -62 -36 -68 -25 -13 -26 -54 -2 -72 9 -7 19 -22 21 -33 8
-36 2 -398 -7 -467 -8 -63 -7 -67 12 -70 12 -2 20 -10 18 -17 -2 -8 -7 -60
-12 -115 -5 -56 -14 -146 -19 -201 -6 -55 -11 -210 -11 -345 0 -270 12 -354
54 -400 19 -19 26 -44 34 -110 6 -47 12 -96 15 -110 17 -86 19 -155 7 -273
-11 -115 -10 -144 4 -224 21 -122 22 -211 3 -308 -9 -44 -21 -163 -27 -265
-17 -283 -21 -333 -30 -324 -5 5 -16 50 -25 99 -35 193 -157 716 -184 791 -16
44 -34 111 -40 149 -7 39 -36 145 -66 236 -71 216 -101 343 -120 503 -8 72
-20 158 -26 191 -6 33 -12 161 -14 285 -2 173 -7 233 -18 260 -54 126 -58 244
-13 370 108 298 152 453 211 738 35 171 61 257 105 343 13 24 42 91 65 149 22
58 116 238 208 402 92 163 182 331 202 373 19 42 56 109 82 148 70 106 213
248 213 212z m440 -57 c0 -37 -105 -394 -215 -728 -86 -264 -103 -353 -139
-750 -70 -758 -86 -959 -86 -1070 0 -122 -17 -235 -62 -420 -50 -207 -50 -208
-44 -380 4 -91 9 -207 12 -258 8 -110 -7 -144 -24 -57 -9 43 -9 67 0 101 11
41 10 48 -20 106 -44 88 -55 150 -44 245 9 77 8 79 -22 133 -30 53 -31 59 -29
165 1 61 9 162 18 225 9 63 20 200 25 304 9 198 22 326 46 461 19 111 18 138
-11 196 -22 42 -24 55 -16 87 5 20 15 75 21 122 17 121 59 278 128 475 195
553 216 605 273 669 29 33 129 237 129 263 0 23 41 123 51 123 5 0 9 -6 9 -12z
m-16535 -1153 c43 -157 56 -261 65 -525 5 -140 16 -336 25 -435 18 -204 22
-776 6 -846 -5 -24 -17 -114 -26 -199 -16 -155 -63 -492 -70 -498 -15 -15 -29
78 -46 301 -11 144 -29 336 -40 427 -11 91 -22 183 -24 205 -2 22 -9 86 -16
143 -9 77 -9 117 0 159 6 31 11 99 12 152 0 53 4 128 10 166 20 151 31 516 20
694 -6 96 -9 176 -6 179 11 11 17 -10 25 -78 4 -39 11 -74 14 -77 10 -10 7
143 -5 263 -7 62 -8 121 -4 131 8 20 15 0 60 -162z m4774 39 c2 -177 -33 -387
-100 -607 -22 -70 -53 -183 -70 -250 -46 -183 -127 -445 -159 -510 -16 -32
-81 -136 -144 -231 -64 -96 -130 -205 -147 -242 -17 -38 -35 -77 -40 -87 -6
-11 -17 -52 -25 -93 -14 -78 -41 -141 -69 -164 -35 -29 -38 -49 -11 -85 14
-19 26 -46 26 -60 0 -15 -25 -71 -55 -125 -73 -129 -109 -211 -156 -353 -48
-142 -65 -256 -81 -531 -14 -235 -6 -217 -105 -231 -66 -9 -79 8 -58 78 8 26
19 102 25 169 6 68 18 164 26 213 9 50 19 171 24 270 10 216 30 346 75 480 19
55 41 120 49 145 9 25 18 57 21 72 6 31 109 163 279 358 65 74 122 144 127
155 5 11 37 56 70 100 81 108 152 256 184 385 50 198 54 215 69 320 18 132 27
171 66 295 62 199 70 231 81 325 5 52 16 111 23 130 7 19 24 68 37 108 27 85
37 76 38 -34z m6490 -115 c6 -70 20 -152 31 -183 12 -34 28 -132 41 -244 33
-307 64 -405 227 -724 54 -106 101 -209 104 -228 11 -69 113 -308 225 -529
199 -390 289 -643 388 -1088 37 -167 54 -347 55 -560 0 -77 -3 -103 -13 -103
-9 0 -11 12 -9 41 6 57 -26 273 -49 339 -78 220 -157 461 -200 607 -77 270
-152 445 -255 606 -54 83 -102 178 -184 365 -34 78 -94 198 -133 265 -147 256
-217 432 -267 675 -17 81 -39 179 -49 217 -11 39 -22 104 -25 145 -4 41 -17
125 -29 185 l-23 110 41 93 c45 103 93 173 105 151 4 -8 12 -71 19 -140z
m-4769 -119 c0 -9 9 -33 20 -55 25 -49 40 -169 33 -257 -6 -73 32 -731 47
-816 10 -57 5 -74 -61 -203 -16 -31 -56 -159 -89 -285 -33 -126 -69 -256 -80
-289 -11 -33 -29 -105 -41 -159 -42 -196 -112 -366 -202 -486 l-41 -54 -17
104 c-22 136 -69 350 -114 518 l-35 133 42 187 c22 103 51 208 64 234 69 137
143 361 214 648 44 176 96 325 135 379 31 44 45 95 70 256 16 104 38 187 46
174 5 -8 9 -21 9 -29z m3145 -535 c97 -238 272 -639 325 -745 15 -30 30 -79
33 -109 4 -29 14 -65 23 -78 25 -39 14 -233 -30 -518 -20 -132 -50 -352 -65
-488 -16 -137 -33 -253 -38 -258 -14 -14 -27 6 -48 71 -10 30 -24 68 -32 84
-8 16 -16 59 -18 95 -3 50 -20 112 -70 256 -37 105 -89 257 -117 340 -27 82
-52 152 -56 155 -12 8 -44 160 -63 300 -12 88 -5 514 10 576 6 22 15 150 21
285 9 218 16 288 25 277 1 -2 47 -111 100 -243z m967 -123 c10 -75 27 -145 48
-197 37 -93 31 -80 152 -350 52 -115 107 -241 121 -280 15 -38 59 -128 97
-200 38 -71 97 -182 130 -245 33 -63 96 -162 139 -220 150 -202 221 -350 339
-710 72 -220 148 -506 154 -576 3 -34 0 -39 -19 -42 -14 -2 -34 9 -57 30 -38
36 -235 341 -312 482 -27 49 -74 119 -106 155 -106 124 -168 218 -264 402
-131 249 -127 238 -274 679 -150 452 -184 591 -178 740 4 92 3 95 -29 149 -18
30 -33 69 -33 85 0 17 -4 45 -9 62 -7 24 -4 42 9 72 18 40 53 82 68 82 4 0 14
-53 24 -118z m-5162 -139 c0 -34 -59 -287 -94 -403 -43 -142 -95 -278 -164
-425 -35 -77 -92 -207 -126 -290 -34 -82 -89 -202 -122 -265 -34 -63 -92 -173
-129 -244 -38 -71 -89 -180 -115 -241 -69 -166 -101 -210 -350 -479 -110 -119
-140 -146 -162 -146 -16 0 -28 2 -28 4 0 2 13 32 30 67 21 47 33 95 44 179 19
140 106 444 175 610 27 63 57 141 67 173 15 47 31 71 84 125 37 37 91 98 121
137 30 38 98 124 152 190 73 89 111 146 146 220 26 55 67 131 90 169 24 38 60
113 81 166 36 94 152 304 232 423 42 62 68 74 68 30z m1557 -375 c82 -128 176
-255 276 -375 45 -54 77 -102 77 -115 1 -13 12 -36 26 -53 24 -29 25 -29 39
-11 20 27 28 15 17 -26 -11 -39 1 -78 23 -78 16 0 18 -7 19 -115 1 -52 7 -81
24 -113 12 -23 22 -55 22 -70 0 -15 7 -52 15 -82 8 -30 22 -98 31 -150 9 -52
25 -120 35 -150 10 -30 19 -68 19 -85 0 -16 7 -39 16 -50 23 -31 16 -185 -18
-350 -15 -77 -37 -216 -48 -310 -12 -93 -36 -278 -55 -410 -18 -132 -37 -268
-40 -303 -13 -122 -32 -64 -76 233 -16 106 -17 198 -5 355 9 128 4 149 -55
227 -33 44 -52 125 -94 403 -9 58 -32 166 -52 240 -49 182 -60 244 -73 390 -8
88 -20 151 -41 211 -16 48 -29 91 -29 98 0 6 -15 32 -34 58 -18 27 -57 89 -85
139 l-51 91 11 134 c5 74 14 154 20 177 5 24 7 66 3 92 -6 46 0 100 11 100 3
0 35 -46 72 -102z m1964 -136 c-20 -274 -21 -293 -36 -402 -30 -214 -36 -300
-36 -517 1 -127 -4 -234 -9 -249 -6 -15 -22 -40 -35 -56 -14 -16 -31 -53 -39
-81 -8 -29 -33 -118 -55 -198 -58 -204 -82 -316 -151 -694 -34 -181 -65 -340
-70 -353 -13 -30 -26 -21 -34 22 -4 20 -24 58 -46 85 -34 43 -42 64 -61 153
-25 123 -37 299 -21 331 19 39 55 433 51 557 -8 286 4 401 81 762 l48 228 139
187 c77 104 171 240 208 302 38 63 71 109 73 102 2 -7 -1 -88 -7 -179z m-6271
-467 c48 -58 232 -511 270 -665 10 -41 29 -118 43 -171 14 -53 33 -166 42
-250 21 -198 59 -403 91 -502 15 -43 22 -82 18 -86 -5 -4 -45 -20 -89 -36
-160 -55 -335 -159 -451 -268 -36 -33 -67 -57 -70 -55 -3 3 -10 36 -16 74 -9
50 -9 84 0 136 19 112 16 277 -7 329 -37 85 -52 210 -60 514 -4 160 -10 295
-13 301 -4 6 9 64 27 130 19 65 37 142 41 169 4 28 28 97 54 155 26 58 57 135
69 173 24 70 30 77 51 52z m10664 -330 c4 -110 13 -328 20 -485 23 -508 13
-884 -29 -1061 -18 -77 -21 -350 -4 -398 13 -38 4 -115 -17 -135 -20 -20 -41
-10 -61 28 -24 47 -101 135 -134 151 -37 19 -52 48 -59 110 -4 43 -10 52 -40
72 -19 12 -51 34 -70 48 -28 21 -41 25 -72 20 -25 -5 -48 -2 -69 9 -57 28 -97
15 -59 -19 25 -22 25 -35 2 -35 -38 0 -46 35 -50 254 -3 139 0 238 8 300 8 50
14 145 14 211 1 118 2 121 32 161 17 23 36 67 43 97 6 30 20 63 31 73 26 26
117 257 145 368 26 106 65 205 94 242 12 15 21 32 21 38 0 23 85 96 111 96 25
0 99 46 114 71 16 25 23 -22 29 -216z m-13788 -765 c4 -61 3 -81 -5 -70 -6 8
-13 35 -16 60 -4 25 -10 55 -15 67 -6 14 -5 34 2 55 l12 33 8 -30 c5 -17 11
-68 14 -115z m5572 -72 c0 -40 13 -124 36 -228 20 -91 50 -228 66 -305 31
-145 43 -285 25 -292 -6 -2 -60 35 -120 83 -188 146 -441 308 -513 326 -35 10
-14 40 55 79 107 59 197 136 318 267 66 72 123 131 127 132 4 0 7 -28 6 -62z
m-5619 -113 c6 -33 15 -107 21 -165 33 -362 83 -681 120 -765 16 -35 25 -85
32 -170 9 -120 50 -280 84 -333 13 -20 82 -322 99 -432 4 -25 20 -101 37 -170
17 -69 32 -143 35 -165 13 -120 115 -836 124 -870 6 -22 21 -74 34 -115 14
-41 36 -131 50 -200 15 -69 40 -178 56 -242 37 -147 36 -174 -3 -255 -27 -56
-32 -77 -32 -142 0 -64 6 -90 37 -168 20 -51 37 -98 37 -104 0 -6 19 -50 41
-98 23 -47 48 -107 56 -134 26 -86 49 -131 118 -221 37 -49 104 -150 148 -225
110 -186 376 -539 606 -802 22 -26 37 -49 34 -53 -23 -23 -88 10 -170 85 -22
21 -112 85 -200 143 -145 97 -166 108 -253 130 -68 18 -101 31 -116 49 -11 13
-49 91 -84 173 -34 82 -87 198 -116 258 -30 59 -54 114 -54 122 0 8 -16 63
-35 122 -46 142 -59 208 -90 456 -30 243 -35 269 -77 395 -26 80 -30 103 -25
157 5 50 2 78 -14 126 -14 40 -24 109 -30 188 -9 146 -67 409 -123 565 -28 76
-36 115 -36 166 0 62 -92 615 -124 744 -8 33 -36 190 -61 349 -36 222 -61 339
-106 504 -68 247 -90 305 -166 454 l-55 107 66 267 c61 242 100 355 117 336 4
-4 11 -34 18 -67z m10036 -60 c14 -46 68 -175 121 -287 l97 -203 -2 -315 c-2
-273 1 -350 23 -580 15 -146 33 -304 41 -352 7 -48 26 -208 40 -355 14 -148
35 -326 46 -398 11 -71 22 -148 25 -170 3 -22 12 -62 19 -88 8 -26 12 -54 9
-62 -3 -7 -1 -22 4 -32 6 -10 15 -61 21 -113 27 -232 59 -395 123 -620 35
-123 55 -221 69 -330 23 -177 64 -391 115 -594 87 -346 181 -673 235 -819 l59
-158 -54 -56 -54 -56 -43 74 c-216 368 -302 536 -325 634 -9 39 -41 131 -71
205 -145 357 -152 375 -132 368 8 -3 37 -54 64 -114 52 -113 104 -208 110
-202 2 2 -36 123 -84 268 -49 146 -108 336 -132 422 -24 87 -48 158 -52 158
-5 0 -6 -27 -2 -60 7 -64 -4 -81 -24 -32 -6 15 -24 88 -40 162 -17 74 -46 185
-66 245 -21 67 -41 157 -50 230 -8 66 -17 130 -19 143 -8 53 14 0 44 -103 31
-106 56 -171 45 -115 -76 389 -90 480 -105 675 -5 72 -19 211 -32 310 -21 163
-23 221 -23 610 0 237 2 549 5 695 3 211 1 293 -12 400 -26 202 -40 395 -45
585 -3 158 -2 168 12 142 9 -15 27 -65 40 -112z m1266 5 c40 -58 129 -176 197
-263 142 -180 141 -170 27 -207 -38 -13 -108 -40 -154 -61 -46 -22 -89 -39
-96 -39 -9 0 -14 27 -17 96 -3 84 -6 97 -25 112 -33 26 -47 147 -38 338 6 128
10 154 20 143 7 -8 46 -61 86 -119z m-9667 -92 c14 -125 55 -345 81 -433 17
-57 27 -114 27 -154 -1 -98 33 -305 72 -448 23 -85 38 -170 45 -253 6 -69 13
-147 16 -175 3 -27 9 -90 13 -140 5 -49 14 -101 19 -115 17 -41 26 -120 14
-120 -6 0 -53 42 -105 93 -52 50 -129 119 -171 152 -42 33 -150 133 -240 222
-90 89 -174 164 -186 168 -20 7 -21 11 -15 74 4 36 21 104 38 151 51 143 65
208 81 385 15 152 45 309 76 388 7 19 18 27 35 27 31 0 55 30 117 148 28 50
56 92 63 92 9 0 16 -21 20 -62z m15148 -130 c42 -197 83 -356 98 -381 25 -41
25 -61 -1 -96 -11 -15 -40 -83 -64 -152 -24 -68 -55 -151 -69 -184 -14 -33
-26 -75 -26 -94 0 -18 -18 -74 -41 -125 -107 -237 -156 -475 -164 -796 -2 -85
-9 -189 -15 -230 -6 -41 -15 -201 -20 -355 -14 -421 -53 -721 -129 -1005 -22
-80 -51 -197 -66 -262 -44 -199 -105 -370 -243 -683 -39 -88 -95 -234 -125
-325 -67 -203 -102 -292 -166 -417 -62 -123 -94 -163 -206 -254 -49 -40 -114
-94 -143 -119 -29 -26 -92 -66 -139 -90 -121 -60 -273 -121 -273 -109 0 5 49
74 108 152 59 78 113 156 120 172 7 17 26 39 42 50 42 28 74 75 174 260 74
135 141 290 171 395 10 34 34 60 57 60 16 0 17 -6 12 -42 -4 -32 -3 -39 5 -28
6 8 11 25 11 38 0 12 27 104 60 205 74 228 110 358 110 403 0 19 4 42 9 52 11
21 58 223 72 306 5 33 16 67 24 77 35 38 102 376 180 909 20 135 48 299 61
365 14 66 36 172 49 235 13 63 36 216 50 340 21 178 31 229 46 248 10 14 19
32 19 42 0 9 11 27 25 40 37 35 131 333 229 731 91 370 111 465 121 574 8 89
13 115 24 115 5 0 11 -10 13 -22z m-9868 -90 c6 -47 21 -841 18 -927 -1 -29
-6 -59 -11 -65 -19 -23 -42 -150 -36 -198 9 -61 -7 -147 -56 -298 -27 -85 -66
-300 -84 -463 -9 -82 -25 -196 -35 -255 -26 -146 -160 -688 -215 -869 -57
-190 -81 -295 -95 -413 -6 -52 -17 -122 -25 -155 -7 -33 -21 -101 -29 -152
-23 -133 -58 -229 -141 -388 -40 -77 -103 -209 -141 -294 -37 -84 -85 -181
-107 -215 -44 -68 -142 -190 -177 -221 l-23 -20 6 55 c3 30 35 143 71 251 36
108 66 207 66 220 0 14 15 56 33 94 34 72 164 451 192 561 8 34 15 75 15 92 0
16 7 35 15 42 8 7 15 20 15 29 0 9 4 21 10 27 5 5 14 41 19 79 6 39 29 118 51
177 47 127 74 235 95 384 9 60 31 190 50 289 19 99 37 203 40 230 4 28 15 91
25 140 16 80 18 137 17 533 -1 243 1 443 6 445 7 2 30 119 62 317 10 58 26
125 37 150 11 25 35 106 53 180 51 206 99 341 174 495 38 77 72 150 76 163 10
35 23 26 29 -20z m8447 -139 c18 -13 19 -18 8 -42 -10 -21 -10 -35 1 -66 7
-21 10 -54 7 -73 -6 -38 92 -347 141 -445 17 -32 37 -84 46 -114 20 -67 48
-267 40 -280 -3 -5 -42 -28 -87 -51 -45 -22 -113 -62 -152 -88 -85 -58 -250
-196 -293 -244 -39 -45 -53 -46 -42 -3 5 17 14 79 20 137 6 58 18 130 26 160
9 30 22 77 30 103 7 27 23 130 34 230 28 242 54 402 85 517 27 102 69 266 81
315 l7 30 14 -35 c8 -19 23 -42 34 -51z m-5843 -241 c8 -8 8 -17 0 -31 -10
-20 -8 -107 10 -297 7 -78 7 -182 -4 -681 -7 -306 2 -502 32 -689 8 -52 17
-111 20 -130 3 -19 7 -46 9 -60 6 -32 11 -80 25 -205 28 -260 85 -649 121
-835 27 -137 124 -537 151 -620 39 -121 106 -240 189 -336 44 -51 59 -81 114
-222 38 -96 236 -509 276 -573 l23 -36 -22 -28 c-34 -43 -29 -81 18 -131 22
-24 40 -48 40 -54 0 -6 -14 -19 -32 -30 -24 -15 -35 -17 -46 -8 -23 19 -52 85
-59 133 -4 32 -12 49 -30 60 -14 10 -22 22 -19 31 6 14 -27 38 -38 27 -3 -3
-6 -15 -6 -25 0 -32 -19 -19 -56 40 -32 49 -78 110 -152 198 -13 15 -17 33
-14 57 3 26 -1 38 -16 49 -12 8 -34 50 -51 94 -16 43 -71 174 -121 289 -51
116 -102 240 -115 276 -12 36 -39 97 -59 134 -36 69 -47 99 -150 440 -102 337
-134 483 -156 715 -13 138 -29 238 -70 425 -56 253 -90 435 -90 478 0 44 22
129 60 232 12 33 24 85 27 115 3 30 9 60 13 65 7 9 18 68 26 135 1 14 7 90 13
168 6 79 14 149 18 155 3 6 10 92 14 191 11 237 13 258 43 427 13 79 28 171
32 204 l8 60 6 -98 c4 -53 12 -103 18 -109z m-4607 -24 c69 -21 236 -87 314
-125 33 -16 65 -29 72 -29 24 0 218 -156 346 -277 115 -109 133 -131 155 -186
l25 -62 -12 -160 c-6 -88 -13 -205 -16 -260 -4 -119 -55 -546 -91 -765 -14
-85 -36 -227 -50 -315 -44 -288 -54 -332 -100 -477 -25 -79 -79 -231 -120
-339 -40 -108 -83 -231 -94 -275 -49 -183 -166 -506 -236 -651 -40 -84 -76
-153 -81 -153 -4 0 -13 4 -18 8 -15 10 -225 300 -225 309 0 22 -63 159 -85
185 -14 16 -28 40 -31 53 -3 13 -16 30 -27 38 -21 14 -68 105 -117 227 -12 30
-26 62 -31 70 -5 8 -31 58 -58 110 -27 52 -81 154 -121 225 -63 114 -180 395
-180 431 0 8 14 24 31 36 l31 23 -5 112 c-5 104 2 170 28 259 4 14 1 26 -10
35 -24 20 -43 40 -71 76 -22 29 -24 38 -19 93 6 72 32 139 85 230 22 36 65
125 94 198 143 345 228 464 366 510 35 12 78 22 94 22 34 0 112 22 147 41 13
6 43 31 68 54 25 24 64 51 86 61 31 15 41 25 43 48 3 27 2 28 -26 23 -34 -7
-65 16 -37 27 27 10 16 24 -22 29 -69 10 -103 19 -103 27 0 12 23 28 53 35 83
21 12 80 -158 129 -190 55 -317 53 -501 -9 -128 -42 -221 -90 -290 -147 -120
-100 -344 -403 -344 -467 0 -13 -8 -33 -17 -43 -16 -18 -21 -43 -39 -195 -4
-30 -11 -53 -18 -52 -20 1 -54 54 -75 119 -25 76 -81 180 -127 237 l-34 41 44
89 c24 48 58 123 76 165 40 98 66 137 107 159 18 10 124 90 236 177 285 222
328 244 510 262 56 6 129 17 162 25 78 19 336 12 416 -11z m7582 -197 c47 -20
202 -52 403 -83 18 -2 43 -13 55 -23 13 -10 44 -21 68 -24 47 -6 90 -31 106
-63 6 -11 20 -31 33 -44 12 -13 22 -31 22 -40 0 -10 15 -37 33 -61 50 -65 59
-87 126 -296 48 -146 71 -204 102 -248 21 -31 39 -61 39 -68 0 -7 -14 -28 -31
-46 l-31 -34 -42 89 c-23 49 -48 105 -56 124 -7 19 -40 85 -74 145 -54 98 -75
124 -186 233 -92 90 -135 125 -162 133 -20 5 -40 14 -43 19 -4 6 -25 20 -48
31 -40 21 -50 21 -337 16 -165 -3 -315 -11 -340 -17 -25 -6 -79 -31 -120 -54
-41 -24 -101 -54 -134 -67 -57 -23 -106 -67 -106 -95 0 -19 -96 -64 -137 -64
-30 0 -33 -3 -49 -54 -14 -46 -15 -59 -5 -85 15 -35 58 -70 133 -108 47 -24
63 -27 140 -25 86 1 89 1 186 -48 140 -70 242 -146 391 -293 113 -112 134
-137 169 -210 22 -45 45 -102 52 -127 6 -25 34 -120 62 -213 l51 -168 -28 -71
c-51 -131 -75 -260 -55 -307 10 -25 8 -34 -12 -67 -14 -22 -28 -47 -31 -58 -4
-10 -23 -39 -43 -65 -44 -56 -164 -245 -401 -626 -99 -159 -216 -344 -259
-410 -44 -67 -122 -193 -172 -282 -51 -88 -99 -163 -106 -166 -8 -3 -19 0 -24
6 -19 23 -52 88 -52 101 0 7 -7 19 -15 26 -8 7 -15 22 -15 34 0 11 -14 46 -31
76 -17 30 -44 87 -60 125 -26 59 -120 259 -150 317 -5 10 -9 27 -9 39 0 11
-11 54 -25 95 -14 41 -25 88 -25 104 0 16 -4 31 -9 34 -5 4 -12 30 -16 59 -10
68 -44 198 -56 213 -6 7 -9 32 -8 56 2 58 -56 406 -82 493 -14 46 -23 106 -25
174 -2 57 -10 150 -18 205 -8 56 -18 153 -21 216 -4 63 -11 146 -16 183 -8 50
-7 80 2 115 10 41 9 56 -6 102 -25 81 -33 340 -12 383 9 18 23 57 31 88 35
128 233 369 395 478 87 60 281 150 344 162 71 12 500 46 615 48 58 0 105 -4
125 -12z m-10198 -327 c13 -19 40 -89 59 -155 19 -66 50 -163 69 -215 132
-359 288 -742 362 -890 24 -47 89 -165 145 -261 57 -96 177 -318 267 -494 287
-557 411 -772 582 -1014 44 -62 78 -117 75 -122 -9 -15 -60 18 -98 63 -20 24
-72 90 -115 147 -59 77 -93 111 -131 133 -29 17 -60 45 -75 69 -14 22 -31 41
-38 43 -7 2 -31 9 -54 15 -23 6 -56 23 -73 38 -37 31 -156 265 -196 386 -15
45 -60 161 -101 257 -42 96 -92 227 -113 290 -21 63 -54 149 -73 190 -45 97
-98 256 -137 411 -29 117 -37 146 -76 272 -25 77 -81 176 -130 227 -36 38 -46
58 -63 125 -31 121 -110 328 -132 345 -25 20 -57 121 -64 207 l-6 68 46 -50
c25 -27 57 -66 70 -85z m5080 13 c10 -37 25 -76 33 -85 13 -16 13 -41 2 -205
-8 -116 -14 -392 -14 -723 -1 -494 -3 -538 -20 -580 -10 -25 -33 -100 -51
-166 -19 -67 -50 -161 -69 -208 -19 -48 -40 -112 -46 -144 -7 -31 -27 -104
-46 -162 -18 -58 -40 -150 -48 -205 -9 -55 -25 -127 -36 -160 -11 -33 -32
-103 -45 -155 -23 -89 -175 -473 -196 -495 -5 -5 -9 -20 -9 -33 0 -13 -5 -33
-12 -45 -30 -58 -408 -477 -430 -477 -4 0 9 33 27 74 19 40 40 95 46 122 6 27
15 56 20 64 5 8 21 42 36 75 48 107 235 470 248 480 7 6 16 21 20 35 4 14 37
99 72 190 50 128 84 240 149 499 121 481 138 585 136 796 -1 94 -7 186 -14
205 -13 41 -13 172 0 330 8 96 15 130 46 206 32 78 36 100 34 160 -3 70 26
252 62 394 11 42 24 120 30 175 6 55 13 111 16 125 l6 25 18 -22 c9 -12 26
-53 35 -90z m-7655 -113 c6 -58 20 -143 30 -190 10 -47 23 -123 30 -170 6 -47
26 -150 45 -230 19 -80 43 -197 55 -260 11 -63 43 -191 70 -285 120 -416 160
-564 160 -589 0 -14 4 -34 10 -44 12 -21 49 -212 60 -306 4 -39 15 -123 23
-186 21 -153 35 -284 31 -289 -2 -2 -11 12 -19 32 -10 25 -28 43 -54 57 -22
10 -48 30 -58 44 -42 53 -216 672 -274 971 -16 88 -41 194 -54 235 -77 250
-100 461 -99 922 1 356 6 440 24 408 4 -8 13 -62 20 -120z m4770 -57 c-141
-114 -260 -273 -374 -498 -113 -221 -166 -263 -132 -103 60 281 254 534 471
615 82 30 87 28 35 -14z m12711 -47 c8 -43 7 -61 -2 -72 -9 -11 -9 -17 1 -29
32 -39 39 -442 10 -634 -11 -74 -17 -137 -15 -142 3 -4 1 -10 -4 -13 -5 -3 -9
-28 -9 -55 -1 -121 -36 -357 -62 -416 -5 -11 -11 -42 -14 -70 -3 -27 -7 -63
-10 -80 -3 -16 -10 -95 -16 -175 -11 -155 -15 -176 -36 -200 -7 -9 -52 -94
-99 -189 -58 -115 -90 -170 -97 -163 -6 6 -4 31 6 73 25 101 35 205 46 474 14
330 25 458 55 650 14 88 30 219 36 290 12 151 20 206 48 334 12 52 21 108 21
125 0 17 7 40 15 50 8 11 15 36 15 55 0 46 14 98 52 189 17 42 34 70 39 65 5
-5 14 -35 20 -67z m-4731 -163 c84 -28 155 -76 246 -167 71 -72 99 -108 132
-175 23 -46 42 -90 42 -98 0 -8 -24 15 -54 50 -30 35 -86 92 -123 127 -70 64
-274 222 -331 255 -18 11 -32 23 -32 28 0 10 58 0 120 -20z m2015 -140 c-24
-110 -65 -217 -203 -538 -154 -358 -178 -432 -212 -642 -14 -85 -63 -191 -144
-312 -31 -46 -56 -86 -56 -88 0 -1 -15 -24 -33 -50 -19 -27 -49 -75 -67 -108
-46 -84 -118 -202 -151 -251 -15 -22 -42 -83 -59 -135 -60 -180 -109 -246
-405 -548 -300 -305 -368 -387 -540 -651 -129 -198 -222 -296 -365 -384 -52
-33 -133 -85 -180 -116 -93 -62 -149 -94 -156 -87 -2 2 16 31 42 63 25 32 101
136 169 229 91 125 156 202 247 291 75 75 140 149 168 193 109 174 145 224
250 356 62 77 159 188 215 246 107 109 163 189 408 579 61 96 160 252 222 345
86 130 161 265 318 575 113 223 232 470 265 550 122 295 263 579 281 564 2 -2
-5 -38 -14 -81z m-13257 -159 c39 -44 93 -98 119 -120 26 -21 50 -41 53 -44 3
-3 13 -9 22 -15 21 -13 136 -94 210 -148 31 -23 128 -100 215 -172 87 -71 184
-147 216 -168 32 -22 59 -48 62 -60 7 -30 22 -226 44 -557 6 -104 15 -160 30
-199 35 -94 41 -186 37 -646 -2 -242 0 -465 5 -495 4 -30 10 -104 13 -163 5
-99 4 -111 -18 -155 -28 -58 -73 -192 -82 -248 -8 -52 -18 -68 -38 -61 -18 7
-308 368 -391 487 -31 44 -76 97 -100 117 -48 42 -50 46 -177 423 -41 122 -99
268 -143 361 -54 112 -76 170 -80 209 -3 29 -14 86 -24 125 -11 39 -29 134
-41 211 -12 76 -28 175 -36 219 -47 263 -81 743 -54 760 4 3 11 33 14 67 4 35
16 105 27 157 10 51 19 114 19 140 0 46 6 64 20 59 4 -2 39 -39 78 -84z
m14532 -176 c0 -51 -9 -149 -21 -218 -11 -69 -29 -187 -39 -263 -21 -147 -33
-200 -85 -372 -18 -60 -54 -204 -79 -320 -153 -705 -206 -931 -264 -1109 -32
-99 -46 -159 -47 -203 -1 -66 -27 -129 -57 -139 -28 -9 -182 -178 -213 -235
-27 -51 -260 -354 -291 -380 -9 -7 -18 -8 -24 -3 -4 5 -11 95 -14 199 -3 105
-11 217 -17 250 -5 33 -14 184 -19 335 -4 151 -14 331 -20 399 -11 111 -10
146 9 340 41 401 61 545 96 691 19 80 50 217 70 305 53 243 43 222 137 291
126 93 271 187 508 331 287 174 321 193 348 193 21 0 22 -3 22 -92z m-13070
-1540 c48 -84 256 -498 279 -556 11 -28 33 -71 48 -94 14 -24 42 -74 61 -113
48 -97 203 -363 272 -465 89 -132 176 -237 274 -330 148 -140 305 -298 420
-423 186 -202 330 -310 592 -442 77 -38 141 -71 143 -72 19 -11 -48 -42 -191
-88 -251 -81 -221 -83 -310 27 -86 105 -547 564 -653 650 -38 31 -96 85 -127
121 -64 72 -179 164 -218 173 -41 10 -70 44 -115 134 -24 47 -69 121 -100 165
-79 111 -243 316 -269 337 -18 15 -22 31 -27 125 -4 80 -13 127 -32 183 -55
155 -109 538 -98 703 2 31 20 18 51 -35z m11360 -884 c-11 -393 -17 -457 -46
-468 -12 -5 -15 -20 -12 -83 4 -90 9 -85 -137 -130 -188 -57 -622 -295 -850
-466 -129 -96 -198 -170 -315 -340 -52 -76 -127 -184 -167 -240 -40 -56 -80
-112 -88 -124 -20 -29 -69 -30 -173 -3 -116 29 -188 56 -260 96 -60 34 -64 38
-47 50 10 8 31 14 46 14 39 0 191 79 298 155 51 36 94 65 97 65 3 0 69 34 147
75 77 41 147 75 154 75 8 0 24 10 36 23 12 12 34 29 49 36 56 29 638 664 638
697 0 8 7 14 14 14 28 0 176 240 176 285 0 6 11 22 25 37 14 15 25 32 25 37 0
6 6 14 14 18 7 4 55 75 105 157 119 192 271 419 277 413 3 -2 0 -179 -6 -393z
m-10120 -4 c12 -12 64 -87 116 -168 110 -169 162 -241 256 -355 37 -45 95
-129 130 -186 35 -58 93 -145 131 -193 37 -49 67 -93 67 -98 0 -26 -34 -3
-101 68 -91 95 -161 191 -195 268 -17 39 -48 82 -92 127 -59 59 -71 67 -104
67 -37 0 -39 2 -66 63 -16 34 -55 100 -86 146 -80 116 -106 181 -71 181 20 0
19 13 -5 52 -11 18 -20 36 -20 41 0 13 20 7 40 -13z m-1038 -1007 c46 -43 112
-103 147 -133 35 -30 134 -133 220 -230 197 -222 353 -378 459 -460 71 -54 80
-65 65 -74 -10 -5 -108 -10 -223 -10 l-205 0 -125 37 c-69 21 -149 52 -178 68
-30 17 -82 39 -115 49 -34 11 -76 32 -94 47 -30 27 -32 35 -53 173 -31 200
-39 476 -16 553 9 31 20 57 25 57 5 0 46 -35 93 -77z m7642 9 c4 -10 16 -29
27 -41 24 -26 20 -36 -11 -26 -26 8 -54 50 -46 70 7 21 23 19 30 -3z m3466
-109 c-2 -52 -41 -202 -90 -343 -33 -96 -67 -197 -74 -224 -18 -63 -50 -91
-127 -111 -35 -9 -118 -32 -184 -52 -110 -33 -129 -36 -235 -34 -157 2 -158 2
-118 54 18 23 60 74 93 112 34 39 82 103 107 142 75 115 173 210 329 318 77
53 164 108 192 121 50 23 107 32 107 17z m-12242 -182 c3 -8 -1 -12 -9 -9 -7
2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m3316 -187 c23 -9 19 -31
-6 -38 -30 -7 -45 8 -28 29 14 17 13 17 34 9z m518 -1106 c29 -22 118 -105
198 -183 271 -265 507 -438 747 -551 32 -15 60 -30 63 -34 3 -4 16 -10 30 -14
33 -10 72 -27 90 -42 27 -22 229 -84 323 -99 64 -10 168 -15 348 -15 254 0
279 -3 279 -35 0 -6 -12 -18 -27 -27 -16 -9 -54 -39 -86 -67 -102 -89 -94 -87
-417 -95 -254 -7 -290 -6 -335 9 -56 19 -161 70 -275 132 -41 22 -99 46 -127
53 -33 7 -61 22 -75 37 -57 63 -59 64 -81 58 -12 -4 -35 -16 -50 -27 l-28 -19
-88 89 c-132 134 -183 199 -316 402 -155 236 -191 297 -216 373 -11 34 -25 70
-30 79 -17 31 16 21 73 -24z m5568 -150 c-33 -68 -60 -129 -60 -136 0 -37
-369 -370 -560 -504 -106 -74 -413 -225 -500 -245 -36 -8 -112 -28 -170 -45
-115 -33 -206 -37 -370 -17 -104 12 -144 22 -211 54 -74 34 -152 136 -98 128
8 -1 50 -12 94 -24 68 -19 107 -22 265 -23 l185 0 116 41 c64 22 192 73 286
113 202 85 277 137 478 330 143 138 252 225 378 301 45 28 91 61 102 74 15 18
96 68 122 74 1 1 -25 -54 -57 -121z"
      />
      <path
        d="M2051 10185 c-15 -102 -20 -187 -13 -220 8 -36 10 -29 16 65 5 58 6
119 3 135 -2 17 -5 26 -6 20z"
      />
      <path d="M16282 4331 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z" />
      <path d="M12232 15185 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
    </g>
  </svg>
];

export default function Logo() {
  return logos[Math.floor(Math.random() * 8)];
}
