import React from "react";
import Logo from "./components/Logo";
import MenuItem from "./components/MenuItem";
import { Provider } from "./Context";
import Data from "./data.json";
import "./styles.css";

export default function App() {
  return (
    <Provider>
      <div className="menu">
        <Logo />
        <MenuItem data={Data.starters} />
        <MenuItem data={Data.mains} />
        <MenuItem data={Data.desserts} />
        <MenuItem data={Data.drinks} />
        <MenuItem data={Data.beers} />
        <MenuItem data={Data.cocktails} />
        

        <div className="menu-footer">
          <div className="menu-footer-left">
            <h1>
              <a href="https://www.instagram.com/amana.escalante/" target="_blank" rel="noopener">IG:@AMANA.ESCALANTE</a>
            </h1>
          </div>
          <div className="menu-footer-center">
            <h1>
              IMPUESTOS INCLUIDOS
            </h1>
          </div>
          <div className="menu-footer-right">
            <h1>
              <a href="https://goo.gl/maps/vQYf8uG6uuPSUyY8A" target="_blank" rel="noopener" >BARRIO ESCALANTE</a>
            </h1>
          </div>
        </div>
      </div>
    </Provider>
  );
}
